import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push as pushState, replace as replaceState } from 'connected-react-router';
import Cookies from 'js-cookie';
import i18n from 'i18n.js';

/**
 * CROS国际站用的iframe
 */
class CrosIframe extends Component {
  componentWillMount() {
  }

  componentDidMount() {
    const { actions } = this.props;
    // 从CROS父页面取query string里的url来跳转
    // const jumpTo = window.top.location.hash;
    const jumpTo = undefined;  // 由于跨域限制，不能这样取CROS那边的URL，后续用其他方式
    if (jumpTo) {
      actions.pushState(jumpTo);
    } else {
      const usp = new URLSearchParams(window.location.search);
      const crosAppId = usp.get('gcloud_id');
      const crosPlatformId = usp.get('platform') | 0;
      const lang = usp.get('lang');
      const crashsightPid = (() => {
        switch (crosPlatformId) {
          case 0: return 10;
          case 2: return 1;
          default: return null;
        }
      })();

      if (lang) {
        Cookies.set('languageSet', lang);
        const i18nLanguage = lang.includes('zh') ? 'zh' : 'en';
        i18n.changeLanguage(i18nLanguage);
      }
      actions.pushState(`/crash-reporting/dashboard/cros${crosAppId}p${crashsightPid}?pid=${crashsightPid}`);
    }
  }

  componentWillReceiveProps(nextProps) {
  }

  render() {
    return (
      <div></div>
    );
  }
}

export default connect((state, router) => {
  return {
  };
}, (dispatch) => ({
  actions: bindActionCreators({
    replaceState,
    pushState,
  }, dispatch),
}))(CrosIframe);
