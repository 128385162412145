import React from 'react';
import { PLATFORM_ID } from 'utils/constants';
import { AndroidFilled, AppleFilled, WindowsFilled, WechatOutlined } from '@ant-design/icons';

import {
  SiPlaystation,
  SiXbox,
  SiNintendoswitch,
  SiLinux,
  SiHarmonyos,
} from 'react-icons/si';
import { isLinux, isPc } from 'utils/platform';

import IconAndroid from 'svg/v2/newcs_platform_icon_android.svg';
import IconIos from 'svg/v2/newcs_platform_icon_ios.svg';
import IconMac from 'svg/v2/newcs_platform_icon_macos.svg';
import IconWindows from 'svg/v2/newcs_platform_icon_pc.svg';
import IconSwitch from 'svg/v2/newcs_platform_icon_switch.svg';
import IconXbox from 'svg/v2/newcs_platform_icon_xbox.svg';
import IconPs from 'svg/v2/newcs_platform_icon_ps.svg';
import IconLinux from 'svg/v2/newcs_platform_icon_lunix.svg';
import IconHarmonyos from 'svg/v2/newcs_platform_icon_hmos.svg';
import IconWeChatApplet from 'svg/v2/newcs_platform_icon_miniapp.svg';

const siStyle = { marginTop:'6px' };

const pidToPlatformIcon = {
  [PLATFORM_ID.ANDROID]: <IconAndroid viewBox="0 0 18 18"/>,
  [PLATFORM_ID.IOS]: <IconIos viewBox="0 0 18 18" />,
  [PLATFORM_ID.MAC]: <IconMac style={{ color: '#807997' }}/>,
  [PLATFORM_ID.PC]: <IconWindows viewBox="0 0 18 18" />,
  [PLATFORM_ID.LINUX]: <IconLinux viewBox="0 0 18 18" />,
  [PLATFORM_ID.PS4]: <IconPs />,
  [PLATFORM_ID.XBOX]: <IconXbox viewBox="0 0 18 18" />,
  [PLATFORM_ID.SWITCH]: <IconSwitch style={{color:' #807997'}} />,
  [PLATFORM_ID.WECHAT_MINI_PROGRAM]: <IconWeChatApplet viewBox="0 0 18 18" />,
  [PLATFORM_ID.HARMONY]: <IconHarmonyos viewBox="0 0 18 18" />,
};

export const PlatformIconUtil = Object.freeze({
  getIcon(appId, platformId) {
    // 适配线上部分PC项目实际上是linux的情况，需要额外用appId判断
    if (isPc(platformId, appId)) {
      return pidToPlatformIcon[PLATFORM_ID.PC];
    } else if (isLinux(platformId, appId)) {
      return pidToPlatformIcon[PLATFORM_ID.LINUX];
    } else {
      return pidToPlatformIcon[platformId];
    }
  },
});
