import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { SwapOutlined } from '@ant-design/icons';
import MinidumpSwitch from 'svg/v2/newcs_dashboard_crashanalysis_issuedetail_minidump_switch_normal.svg';

import { CS_STYLES } from 'utils/constants/style-constants';
import style from './CsSwapSelect.scss'

const CsSwapSelect = ({
  options: nullableOptions,
  disabled,
  value,
  onClick,
  onChange,
  type: nullableType,
}) => {
  const options = nullableOptions || [];
  const type = nullableType ?? 'default'

  const currentMatchedOptionIndex = useMemo(() => {
    return options.findIndex(x => x.value === value);
  }, [options, value]);

  const currentMatchedOption = options[currentMatchedOptionIndex];

  const defaultDom = <div
    style={{ display: 'inline-flex', userSelect: 'none', cursor: 'pointer', alignItems: 'center', gap: '4px' }}
    onClick={(e) => {
      e.stopPropagation();
      onClick && onClick();
      if (disabled) {
        return;
      }
      if (!onChange || options.length === 0) {
        return;
      }
      const newIndex = (currentMatchedOptionIndex + 1) % options.length;
      onChange(options[newIndex]?.value);
    }}
  >
    <div>{ currentMatchedOption?.label || currentMatchedOption?.value }</div>
    <span >{<MinidumpSwitch style={{ color: CS_STYLES.SUB_TEXT_COLOR }}/>}</span>
  </div>;

  const primaryDom = <div
    className={style.primary_box}
    style={{ height: type === 'small' ? 24 : 32 }}
    onClick={(e) => {
      e.stopPropagation();
      onClick && onClick();
      if (disabled) {
        return;
      }
      if (!onChange || options.length === 0) {
        return;
      }
      const newIndex = (currentMatchedOptionIndex + 1) % options.length;
      onChange(options[newIndex]?.value);
    }}
  >
    <div>{currentMatchedOption?.label || currentMatchedOption?.value}</div>
    <span className={style.MinidumpSwitch}>{<MinidumpSwitch style={{ color: CS_STYLES.PRIMARY_COLOR }} />}</span>
  </div>;

  const domMap = {
    'default': defaultDom,
    'primary': primaryDom,
    'small': primaryDom,
  }

  return domMap[type];
};

export default CsSwapSelect;
