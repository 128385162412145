import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Cookies from 'js-cookie';

import antdZh from 'antd/lib/locale/zh_CN';
import antdEn from 'antd/lib/locale/en_GB';

import enTrans from './locales/en.json';
import zhTrans from './locales/zh.json';
// translation catalog
const resources = {
  en: {
    translation: enTrans,
  },
  zh: {
    translation: zhTrans,
  },
};

const i18nLanguage = (() => {
  const usp = new URLSearchParams(window.location.search);
  // 截图服务的截图请求，通过screenshotLang参数获取语言，如果没有则默认中文
  const isScreenshotVisit = !!(usp.get('screenshotSecret'));
  if (isScreenshotVisit) {
    const screenshotLang = usp.get('screenshotLang');
    return (!screenshotLang || screenshotLang.includes('zh')) ? 'zh' : 'en';
  }

  // wenqing: 设置语言先根据localstorage中的设定，如果没有就则根据浏览器设定语言
  const userSetLanguage = Cookies.get('languageSet');
  const userBrowserLanguage = navigator.languages && navigator.languages.length > 0
    ? navigator.languages[0]
    : navigator.language;
  const userLang = userSetLanguage || userBrowserLanguage;
  return userLang.includes('zh') ? 'zh' : 'en';
})();

export const antdLocale = i18nLanguage === 'zh' ? antdZh : antdEn;

i18n
  // load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-xhr-backend
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    lng: i18nLanguage,
    debug: true,
    resources,

    ns: ['translation'],
    defaultNS: 'translation',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
