import RestHelper from './RestHelper';

const userSummaryRest = RestHelper.publish({
  getOperateSummary: {
    get: {
      method: 'get',
      uri: '/appId/{appId}/pid/{pid}/operateSummary',
    },
  },
  getOperateTrend: {
    get: {
      method: 'get',
      uri: '/appId/{appId}/pid/{pid}/version/{version}/channel/{channel}/startHour/{startHour}/endHour/{endHour}/operateTrend',
    },
  },
  getOperateRealtimeAppendTrend: {
    get: {
      method: 'get',
      uri: '/appId/{appId}/pid/{pid}/version/{version}/channel/{channel}/startHour/{startHour}/endHour/{endHour}/getOperateRealtimeAppendTrend',
    },
  },
  getOperateRank: {
    /**
     * type     应用版本 version , 还是渠道channel
     * userType 用户维度：新增用户 newUser ， 使用用户 useUser
     */
    get: {
      method: 'get',
      uri: '/appId/{appId}/pid/{pid}/date/{date}/type/{type}/userType/{userType}/limit/{limit}/topVersion',
    },
  },
});

export default userSummaryRest;
