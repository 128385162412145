import React from 'react';
import {  Select } from 'antd';
import { isNullish } from 'utils/nullish';

export const RAM_GIB_LIST = [1, 2, 3, 4, 6, 8, 16];

const RAM_OPTIONS = RAM_GIB_LIST.map(x => ({ label: `${x} GiB`, value: x }));

const GIGA = 1024 * 1024 * 1024;

const RamSelect = ({
  value,
  onChange,
}) => {
  function getInnerValueFromValue(value) {
    const { lte, gte } = value || {};
    if (isNullish(lte) && isNullish(gte)) {
      return null;
    }

    const lteIndex = RAM_GIB_LIST.indexOf(lte / GIGA);
    const gteIndex = RAM_GIB_LIST.indexOf(gte / GIGA);
    let innerValue = 'Invalid RAM';
    if (gteIndex - lteIndex === 1) {
      innerValue = RAM_GIB_LIST[gteIndex];
    }
    return innerValue;
  }

  const innerValue = getInnerValueFromValue(value);

  return <Select
    style={{ width: '100%' }}
    allowClear={true}
    options={RAM_OPTIONS}
    value={innerValue}
    onChange={v => {
      if (!v) {
        onChange(null);
        return;
      }

      const gte = v * GIGA;
      const gteIndex = RAM_GIB_LIST.indexOf(v);
      const lte = gteIndex > 0
        ? RAM_GIB_LIST[gteIndex - 1] * GIGA
        : 0;
      onChange({ gte, lte });
    }}
  />
};

export default RamSelect;
