import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ze } from 'utils/zhEn';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import throttle from 'lodash/function/throttle';
import RestHelper from 'utils/RestHelper';
import { updateSummarySelectOptions } from 'reducers/summary/summaryActions';

const SubModuleIdSelect = ({
  value,
  onChange = () => {},
}) => {
  const dispatch = useDispatch();
  const currentApp = useSelector(reduxState => reduxState.app.get('current').toJS());
  const selectOptions = useSelector(reduxState => reduxState.summary.get('selectOptions'));
  const { subModuleIdOptions } = selectOptions.toJS();
  const { appId, platformId } = currentApp;

  async function fetchSubModuleIdOptions() {
    const rsp = await RestHelper.post('/redir/api/statResource/getSubModuleIds', {
      appId,
      platformId,
    });
    const subModuleIdList = rsp.json.data;
    const subModuleIdOptions = [
      { value: '-1', label: ze('全部发行渠道', 'All Distribution Channels') },
      ...(subModuleIdList || [])
        .filter(x => x !== '-1' && x !== '-')
        .sort()
        .map(x => ({ value: x, label: x })),
    ];
    dispatch(updateSummarySelectOptions({ subModuleIdOptions }));
  }

  function throttledFetchSubModuleIdOptions() {
    throttle(
      () => fetchSubModuleIdOptions(),
      60000,
      { trailing: false },
    );
  }

  const optionsWithDefault = subModuleIdOptions.length > 0
    ? subModuleIdOptions
    : [{ value: '-1', label: ze('全部发行渠道', 'All Distribution Channels') }];

  const dom = <Select
    showSearch
    allowClear
    placeholder={ze('全部发行渠道', 'All Distribution Channels')}
    style={{ width: '160px', marginLeft: '16px' }}
    value={value}
    options={optionsWithDefault}
    onDropdownVisibleChange={(open) => {
      if (!open) {
        return;
      }
      if (subModuleIdOptions.length > 0) {
        throttledFetchSubModuleIdOptions();
      } else {
        fetchSubModuleIdOptions();
      }
    }}
    onSelect={(v) => {
      onChange(v);
    }}
    onClear={() => {
      onChange('-1');
    }}
  />;

  return dom;
};

export default SubModuleIdSelect;
