import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  Button,
  Input,
  InputNumber,
  Modal,
  Form,
  Spin,
  Row,
  Col,
  Divider,
  Checkbox,
  Space,
  message,
  Popover,
  Table,
} from 'antd';
import { connect } from 'react-redux';
import { isNotNullish } from 'utils/nullish';
import { useZhEn } from 'utils/react-hooks/useZhEn';
import { useTranslation } from 'react-i18next';
import { getIssueDetailUrlPrefix } from 'utils/helper';
import { red } from '@ant-design/colors';
import { useImmer } from 'use-immer';
import { CrashUtil } from 'utils/api/crash';
import { isMobile } from 'utils/platform';
import { DocsUtil } from 'utils/docs-util';
import { CS_STYLES } from 'utils/constants/style-constants';
import WrappedTipsIcon from 'components/antd-extension/WrappedTipsIcon.jsx';

function makeDefaultIssueSendReportToWorkWeixinSettings() {
  return {
    selectedFields: ['issueId', 'crashId', 'crashVersion', 'userId', 'crashTime', 'uploadTime'],
    webhookUrl: '',
  };
}

const IssueSendReportToWorkWeixinModal = ({
  visible,
  onClose,
  crashDoc,
  reduxState,
}) => {
  const { t } = useTranslation();
  const { ze } = useZhEn();
  const { appId, platformId } = reduxState.app.get('current').toJS();
  const crashMap = (crashDoc || {}).crashMap || {};
  const refinedCrashMap = CrashUtil.makeRefinedCrashMap(crashMap);
  const esMap = (crashDoc || {}).esMap || {};

  const fieldOptions = [
    'issueId',
    'crashId',
    'expUid',
    'crashVersion',
    'userId',
    ...(isMobile(platformId) ? ['deviceId'] : []),
    'osVersion',
    'crashTime',
    'uploadTime',
    ...(isMobile(platformId) ? ['expName'] : []),
    'expMessage',
  ];

  const [loading, setLoading] = useState(false);
  const [settings, updateSettings] = useImmer(makeDefaultIssueSendReportToWorkWeixinSettings());
  const [isEditingFields, setIsEditingFields] = useState(false);

  const { selectedFields, webhookUrl } = settings;

  const reportWebUrl = `${window.location.origin}/${getIssueDetailUrlPrefix(esMap.crashExceptionType)}/${appId}/${crashMap.issueId}/report-id/${crashMap.crashId}?pid=${platformId}`;
  const fieldValueToSendContent = {
    issueId: esMap.crashIssueId,
    crashId: crashMap.crashId,
    crashVersion: crashMap.productVersion,
    userId: crashMap.userId,
    deviceId: crashMap.deviceId,
    expUid: esMap.expUid,
    crashTime: refinedCrashMap.crashTimeFormatted,
    uploadTime: refinedCrashMap.uploadTimeFormatted,
    osVersion: crashMap.osVer,
    expName: crashMap.expName,
    expMessage: crashMap.expMessage,
  };

  const fieldsTextContent = selectedFields.map(field => {
    const label = t(`REPORTDETAIL.${field}`);
    return `${label}: ${fieldValueToSendContent[field] || '-'}`;
  }).join('\n');

  const sendContent = [
    `# ${ze('来自CrashSight分享的上报', 'Report Shared From CrashSight')}`,
    `[${ze('点击打开上报页面', 'Click To Open Report Page')}](${reportWebUrl})`,
    fieldsTextContent,
  ].join('\n');

  useEffect(() => {
    if (!appId || !visible) {
      return;
    }
    (async () => {
      setLoading(true);
      setIsEditingFields(false);
      await fetchWebAppSettings();
      setLoading(false);
    })();
  }, [appId, visible]);

  async function fetchWebAppSettings() {
    const rsp = await RestHelper.mustPost('/api/app/getWebAppSettings', { appId });
    const webAppSettings = rsp.json.data || {};
    let { issueSendReportToWorkWeixin: settings } = webAppSettings;
    if (!settings) {
      settings = makeDefaultIssueSendReportToWorkWeixinSettings();
    }
    settings.selectedFields = (settings.selectedFields || []).filter(x => fieldOptions.includes(x));
    updateSettings(settings);
  }

  async function onClickSaveSettings() {
    setLoading(true);
    await RestHelper.mustPost('/api/app/upsertWebAppSettings', {
      appId,
      settings: {
        issueSendReportToWorkWeixin: settings,
      },
    });
    setLoading(false);
    setIsEditingFields(false);
  }

  async function sendReportToWorkWeixin() {
    setLoading(true);
    const rsp = await RestHelper.mustPost('/api/app/sendReportToWebhookUrl', {
      appId,
      webhookUrl,
      content: sendContent,
    });
    message.success(ze('上报信息分享成功', 'Report has been successfully sent.'));
    onClose();
    reportEvent({
      action: EVENT_ACTIONS.CLICK,
      tp1: '分享第三方',
    });
  }

  const editingFieldsDom = <div>
    <Checkbox.Group
      value={selectedFields}
      onChange={(v) => { updateSettings(draft => ({ ...draft, selectedFields: v })) }}
    >
      { fieldOptions.map((x, i) => <div key={i} style={{ marginBottom: '8px' }}>
        <Checkbox value={x}>{ t(`REPORTDETAIL.${x}`) }</Checkbox>
      </div>) }
    </Checkbox.Group>
    <div style={{ marginTop: '12px' }}>
      <div style={{ color: CS_STYLES.SUB_TEXT_COLOR }}>{ ze('通知机器人Webhook地址（支持企微/钉钉/飞书/Slack）', 'Notification Bot Webhook URL (Supports WeCom/Dingding/Feishu/Slack)') }</div>
      <div style={{ marginTop: '8px' }}><Input
        style={{ width: '100%' }}
        value={webhookUrl}
        onChange={(e) => {
          const v = e.target.value.trim();
          updateSettings(draft => ({ ...draft, webhookUrl: v }));
        }}
      /></div>
    </div>
  </div>;

  const tableData = selectedFields.map(field => {
    const key = t(`REPORTDETAIL.${field}`);
    const value = fieldValueToSendContent[field] || '-';
    return { key, value };
  });

  const tableColumns = [{
    title: ze('字段', 'Field'),
    dataIndex: 'key',
  }, {
    title: ze('值', 'Value'),
    dataIndex: 'value',
  }];

  const displayTableContentDom = <Table
    size='small'
    columns={tableColumns}
    dataSource={tableData}
    pagination={false}
  />;

  const editButton = <div>
    <Button
      style={{ borderColor: CS_STYLES.PRIMARY_COLOR, color: CS_STYLES.PRIMARY_COLOR }}
      disabled={loading}
      onClick={() => setIsEditingFields(true)}
    >{ t('LASTREPORT.配置分享字段和地址') }</Button>
  </div>;

  const sendContentDom = <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
    <div style={{ whiteSpace: 'pre-wrap' }}>{ displayTableContentDom }</div>
    { webhookUrl && <div>
      <div style={{ color: CS_STYLES.SUB_TEXT_COLOR }}>{ ze('通知机器人Webhook地址', 'Notification Bot Webhook URL') }:</div>
      <div>{ webhookUrl }</div>
      { editButton }
    </div> }
    { !webhookUrl && <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <div style={{ color: red.primary, display: 'flex', alignItems: 'center', gap: '4px' }}>
        <div>{ze('未配置通知机器人Webhook地址', 'Notification bot webhook URL is not set')}</div>
        <Popover content={(
          <div>
            <div>{ ze('支持企微/钉钉/飞书/Slack。', 'Supports WeCom/Dingding/Feishu/Slack.') }</div>
            <div><a href={DocsUtil.makeDocsUrl('webDocuments/notify')} target='_blank' rel="noreferrer noopener">{ t("REPORTCONFIG.通知机器人查看文档帮助语") }</a></div>
          </div>
        )} trigger="hover" overlayStyle={{ zIndex: 10000 }}>
          <WrappedTipsIcon />
        </Popover>
      </div>
      { editButton }
    </div> }
  </div>;

  const modalTitle = isEditingFields ? t('LASTREPORT.配置分享字段和地址') : t('LASTREPORT.分享此上报至企业微信/钉钉/飞书/Slack');

  const sendAvailable = selectedFields.length > 0 && webhookUrl;

  return <Modal
    title={modalTitle}
    width='800px'
    visible={visible}
    maskClosable={false}
    onCancel={() => onClose()}
    footer={<Row gutter={20}>
      { !isEditingFields && <Col><Button
        disabled={loading}
        onClick={() => onClose()}
      >{ ze('取消', 'Cancel') }</Button></Col> }
      { !isEditingFields && <Col><Button
        disabled={loading || !sendAvailable}
        type='primary'
        onClick={() => sendReportToWorkWeixin()}
      >{ ze('确认分享', 'Confirm Send') }</Button></Col> }
      { isEditingFields && <Col><Button
        disabled={loading}
        onClick={() => { setIsEditingFields(false) }}
      >{ ze('使用配置（不保存）', 'Use This Config (Do Not Save)') }</Button></Col> }
      { isEditingFields && <Col><Button
        disabled={loading}
        type='primary'
        onClick={() => { onClickSaveSettings() }}
      >{ ze('保存并使用配置', 'Save And Use This Config') }</Button></Col> }
    </Row>}
  >
    <Spin spinning={loading}>
      { isEditingFields && editingFieldsDom }
      { !isEditingFields && sendContentDom }
    </Spin>
  </Modal>
};

IssueSendReportToWorkWeixinModal.propTypes = {
};

const mapStateToProps = state => ({
  reduxState: state,
});

export default connect(mapStateToProps)(IssueSendReportToWorkWeixinModal);
