import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Map as iMap, List as iList } from 'immutable';
import { TagItem } from 'components/commons';
import { getVersionRangeString } from 'utils/helper';
import style from './style.scss';
import uniqBy from 'lodash.uniqby';
import { message, Tag, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { ClockCircleOutlined } from '@ant-design/icons';
import { isNotNullish } from 'utils/nullish';
import moment from 'moment';
import { TimeUtil } from 'utils/time-util';
import { ze } from 'utils/zhEn';
import WrappedTipsIcon from 'components/antd-extension/WrappedTipsIcon.jsx';
import { mergeTypeTagName } from 'utils/constants/merge-issue-type';
import NewIssueIn24HoursIcon from 'svg/v2/newcs_dashboard_overview_newissuein24hours5.svg';
import NewIssueIn48HoursIcon from 'svg/v2/newcs_dashboard_overview_newissuein48hours5.svg';
import NewIssueIn72HoursIcon from 'svg/v2/newcs_dashboard_overview_newissuein72hours5.svg';

const RECENTLY_NEW_ISSUE_MARK_COLORS = ['#6669FE', '#3FB3FF', '#6E93E5'];
const RECENTLY_NEW_ISSUE_TAG_ICON = [
  <NewIssueIn24HoursIcon style={{margin: '4px 4px 0 0'}} />,
  <NewIssueIn48HoursIcon style={{margin: '4px 4px 0 0'}} />,
  <NewIssueIn72HoursIcon style={{margin: '4px 4px 0 0'}} />,
]

const TagList = ({
  tags, addTag, delTag, issueId, path, demoApp, issueVersions, appId, pid, disableTags,
  firstUploadTimestamp,
  latestUploadTimestamp,
  firstCrashVersion,
  mergeIssueType,
}) => {
  const { t } = useTranslation();

  const allowAddTag = isNotNullish(addTag);

  const pureVersions = Array.isArray(issueVersions)
    ? issueVersions.map(x => x.version)
    : [];

  const issueVersionStr = getVersionRangeString(pureVersions);
  const firstUpload = moment(TimeUtil.fixChaosMillis(firstUploadTimestamp)).format('YYYY-MM-DD HH:mm:ss');
  const lastUpload = moment(TimeUtil.fixChaosMillis(latestUploadTimestamp)).format('YYYY-MM-DD HH:mm:ss');
  const timeRange = getVersionRangeString([firstUpload, lastUpload]);
  const timeRangeState = firstUploadTimestamp && latestUploadTimestamp;
  // 兼容immutableJS的结构
  const vanillaTags = iList.isList(tags) ? tags.toJS() : tags;
  // 移除重复的tag
  const dedupeTags = uniqBy(vanillaTags, tag => tag.tagId);

  const [nowMillis, setNowMillis] = useState(+new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setNowMillis(+new Date());
    }, 60000 + Math.random() * 10000);
    return () => {
      clearInterval(intervalId);
    };
  });

  function onAddTag(issueId, value, path) {
    const duplicateTagInfo = dedupeTags.find(x => x.tagName === value);
    if (duplicateTagInfo) {
      message.error(ze('不能重复添加同名的标签', 'Cannot add duplicate tag.'));
      return;
    }
    addTag(issueId, value, path);
  }

  function renderTag(tag) {
    let tagId = '';
    tag.size ? { tagId } = tag.toJS() : tagId = tag.tagId;
    return (
      <TagItem
        key={tagId}
        {...{
          tag, issueId, path, demoApp, appId, pid,
        }}
        handleDelTag={delTag}
        handleAddTag={onAddTag} />
    );
  }

  const daysElapsed = (nowMillis - firstUploadTimestamp) / 86400 / 1000;
  const ceilDays = Math.max(1, Math.ceil(daysElapsed));

  // const recentlyNewIssueMarkDom = firstUploadTimestamp
  //   && ceilDays <= 3 && <Tooltip title={t('BASEPROBLEM.n小时内新增问题', { count: ceilDays * 24 })}><Tag
  //     className={style.antdTag}
  //     color={RECENTLY_NEW_ISSUE_MARK_COLORS[ceilDays - 1]}
  //   >{ `+${ceilDays * 24 }`}</Tag></Tooltip>;

  const recentlyNewIssueMarkDom = firstUploadTimestamp
    && ceilDays <= 3 && <Tooltip title={t('BASEPROBLEM.n小时内新增问题', { count: ceilDays * 24 })}>{  RECENTLY_NEW_ISSUE_TAG_ICON[ceilDays - 1] }</Tooltip>;

  const mergeIssueTagDom = mergeIssueType && mergeTypeTagName(mergeIssueType) && <Tag
    className={style.antdTag}
    color={'#55adff'}
  >{mergeTypeTagName(mergeIssueType)}</Tag>;

  const isFirstCrashVersionInVersionList = pureVersions.includes(firstCrashVersion);

  const firstCrashVersionDeletedTooltip = !isFirstCrashVersionInVersionList && <Tooltip
    title={ze('此问题的首次上报版本由于长期没有上报数据，版本数据已被清除。因此版本列表中无法看到此版本，也无法通过包含版本搜索到问题。', 'The first version reporting this issue has not reported events for a long time, and its version data has been cleared. Therefore, this version cannot be seen in the version list, nor can the issue be found through a version related search.')}
  ><WrappedTipsIcon /></Tooltip>;

  const firstCrashVersionDom = firstCrashVersion && <div className={style.firstCrashVersion}>
    <Tag
      className={style.antdTag}
      color='orange'
    ><div style={{display:'flex',alignItems:'center',flexDirection:'row',gap:'4px'}}>
      {firstCrashVersionDeletedTooltip} { t('BASEPROBLEM.首次上报版本') }: { firstCrashVersion }
    </div>
    </Tag>
  </div>;
  return (
    <div className={style.tag_list}>
      {
        issueVersionStr && (
          <div className={style.issueVersions}>
            <Tooltip
              overlayStyle={{ maxWidth: '30vw' }}
              title={<div>{ze('问题上报的版本区间: ', 'Version range for reported issue: ')}{issueVersionStr}</div>}
            >{issueVersionStr}</Tooltip>

          </div>
        )
      }
      {timeRangeState && <div className={style.issueVersions}>
        <Tooltip
          overlayStyle={{ maxWidth: '30vw' }}
          title={<div>{ze('问题上报的时间区间: ', 'Time range for reported issue: ')}{timeRange}</div>}
        >
          <ClockCircleOutlined style={{marginRight: '4px'}}/>{timeRange}
        </Tooltip>
      </div>}
      {firstCrashVersionDom}
      {recentlyNewIssueMarkDom}
      { mergeIssueTagDom }
      {!disableTags && dedupeTags && dedupeTags.map(tag => renderTag(tag))}
      {!demoApp && !disableTags && allowAddTag && renderTag(iMap({ tagName: 'ADD_ISSUE_TAG' }))}
    </div>
  );
};


TagList.propTypes = {
  tags: PropTypes.instanceOf(iList).isRequired,
  addTag: PropTypes.func.isRequired,
  delTag: PropTypes.func.isRequired,
  issueId: PropTypes.string.isRequired,
  path: PropTypes.any,
  demoApp: PropTypes.any,
  issueVersions: PropTypes.any,
  ftName: PropTypes.any,
  appId: PropTypes.any,
  pid: PropTypes.any,
  disableTags: PropTypes.bool,
  mergeIssueType: PropTypes.any,
};

TagList.defaultProps = {
  tags: iList(),
};

export default TagList;
