import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DateRange, Calendar } from 'react-date-range';
import moment from 'moment-timezone';
import { TimeSelect } from 'components/commons';
import { getLabelOrValue } from 'utils/constants';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { zhCN, enUS } from 'react-date-range/dist/locale';
import { isZh } from 'utils/zhEn';
import { TimeZoneUtil } from 'utils/time-zone-util';
import classnames from 'classnames';
import scss from './style.scss';
import {
  selectCurrentAppDatePickerAvailableMomentRange,
} from 'utils/selectors/selectors';
import { connect } from 'react-redux';
import { CS_STYLES } from 'utils/constants/style-constants';

const TAG = '[TimePicker]::';

const SINGLE_DATE_REGEX = /^(\d{4}-\d{2}-\d{2})$/;
const DATE_RANGE_REGEX = /^(\d{4}-\d{2}-\d{2}).+?(\d{4}-\d{2}-\d{2})$/;

function calcDateRangeValue(defaultSelect) {
  const defaultSelectString = String(defaultSelect || '').trim();

  const singleDateMatchResult = defaultSelectString.match(SINGLE_DATE_REGEX);
  if (singleDateMatchResult) {
    const date = moment(singleDateMatchResult[1]).toDate();
    return [{ startDate: date, endDate: date, key: 'selection' }];
  }
  const rangeMatchResult = defaultSelectString.match(DATE_RANGE_REGEX);
  if (rangeMatchResult) {
    const startDate = moment(rangeMatchResult[1]).toDate();
    const endDate = moment(rangeMatchResult[2]).toDate();
    return [{ startDate, endDate, key: 'selection' }];
  }
  // react-date-range要使用当前app时区构造0点的date
  const now = new Date(Date.parse(moment().format('YYYY-MM-DD')));
  return [{ startDate: now, endDate: now, key: 'selection' }];
}

@connect((state) => {
  return {
    selectCurrentAppDatePickerAvailableMomentRange: () => selectCurrentAppDatePickerAvailableMomentRange(state),
  };
}, (dispatch) => ({ dispatch }))
export default class TimePicker extends Component {
  constructor(props) {
    super(props);
    const { defaultSelect } = props;
    this.state = {
      prevProps: {},
      calendar: false,
      checkTimes: 0,
      value: defaultSelect || '所有上报时间',
      open: false,

      dateRangeValue: [],
      calendarValue: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    // 设置defaultSelect是改变默认下拉框里面的值
    /*( if (!!nextProps.defaultSelect && nextProps.defaultSelect !== this.props.defaultSelect) {
      this.setState({ value: nextProps.defaultSelect });
    } */
    // 设置defaultDate是改变Calendar组件里面的值
    if (!!nextProps.defaultDate && !nextProps.defaultDate.isSame(this.props.defaultDate)) {
      this.setState({ value: nextProps.defaultDate.format('YYYY-MM-DD').toString() });
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { prevProps } = state;
    if (props === prevProps) {
      return null;
    }

    const patchState = { prevProps: props };
    if (props.defaultSelect !== prevProps.defaultSelect) {
      patchState.dateRangeValue = calcDateRangeValue(props.defaultSelect);
      patchState.value = props.defaultSelect;
    }
    if (props.defaultDate !== prevProps.defaultDate) {
      if (typeof (props.defaultDate || {}).format === 'function') {
        patchState.calendarValue = props.defaultDate.format('YYYY-MM-DD');
      }
    }
    return patchState;
  }

  initDate() {
    const { defaultDate } = this.props;
    if (defaultDate && !defaultDate.format) {
      throw new Error('参数 defaultDate 须要用moment来生成！！');
    }
    defaultDate && this.setState({ value: defaultDate.format('YYYY-MM-DD').toString() });
  }

  toggleOpen() {
    // this.props.idKey && console.log(`${TAG} toggleOpen: origin open=${this.state.open}`);
    if (this.state.open) {
      this.props.idKey && console.log(`${TAG} toggleOpen setState open:false`);
      this.setState({ open: false, calendar: false }, this.unbindCloseMenuIfClickedOutside.bind(this));
    } else {
      this.setState({ open: true }, this.bindCloseMenuIfClickedOutside.bind(this));
    }
  }

  // return true 表示点击发生在目标外
  clickedOutsideElement(element, event) {
    let eventTarget = (event.target) ? event.target : event.srcElement;
    while (eventTarget != null) {
      if (eventTarget === element) return false;
      eventTarget = eventTarget.offsetParent;
    }
    return true;
  }

  closeMenuIfClickedOutside(event) {
    if (!this.state.open) {
      return;
    }
    const { select } = this;

    const eventOccuredOutsideSelect = this.clickedOutsideElement(select, event);

    // Hide dropdown menu if click occurred outside of menu
    if (eventOccuredOutsideSelect) {
      this.setState({ open: false, calendar: false }, this.unbindCloseMenuIfClickedOutside);
    }
  }

  bindCloseMenuIfClickedOutside() {
    document.addEventListener('click', this.closeMenuIfClickedOutside.bind(this));
  }

  unbindCloseMenuIfClickedOutside() {
    document.removeEventListener('click', this.closeMenuIfClickedOutside);
  }

  handleShowCalender() {
    this.setState({
      calendar: !this.state.calendar,
    });
  }

  changeAfterUpdateValue(value) {
    let date = '';
    let startDate = '';
    let endDate = '';
    let theValue = value;
    // 如果value 是个对象，自定义选择的结果，
    if (typeof theValue === 'object') {
      if (!this.props.singleDate) {
        endDate = theValue.endDate.format('YYYY-MM-DD').toString();
        startDate = theValue.startDate.format('YYYY-MM-DD').toString();
      } else {
        startDate = theValue.format('YYYY-MM-DD').toString();
      }

      date = 'custom';

      if (endDate) {
        theValue = startDate === endDate ? startDate : `${startDate} ~ ${endDate}`;
      } else {
        theValue = startDate;
      }
      this.props.onChange({
        date, startDate, endDate,
      });
    } else if (theValue === '所有上报时间') {
      this.props.onChange({
        date, startDate, endDate,
      });
    } else if (theValue !== '自定义时间段') {
      date = getLabelOrValue(value);
      this.props.onChange({
        date, startDate, endDate,
      });
    }
    return theValue;
  }

  // 选中日期
  updateValue(value) {
    this.setState({ value: this.changeAfterUpdateValue(value) });
  }

  onCalendarChange(date) {
    // react-date-range用了本地时区，不能直接用moment的默认时区
    const dateMoment = TimeZoneUtil.makeMomentConstructorWithUtcOffset(-date.getTimezoneOffset())(date);
    const patchState = {
      calendarValue: date,
      calendar: false,
      open: false,
      value: this.changeAfterUpdateValue(dateMoment),
    };
    this.setState(patchState);
  }

  onDateRangeChange(v) {
    const { startDate, endDate } = v.selection;
    // 是否已经选完起点/终点。第一次点击是选起点，再点一次是确定终点
    const pickRangeFinished = this.state.checkTimes % 2 === 1
      || startDate.getTime() !== endDate.getTime();

    // react-date-range用了本地时区，不能直接用moment的默认时区
    const startMoment = TimeZoneUtil.makeMomentConstructorWithUtcOffset(-startDate.getTimezoneOffset())(startDate);
    const endMoment = TimeZoneUtil.makeMomentConstructorWithUtcOffset(-endDate.getTimezoneOffset())(endDate);

    const patchState = {};
    patchState.dateRangeValue = [v.selection];
    patchState.checkTimes = (this.state.checkTimes + 1) % 2;
    if (pickRangeFinished) {
      patchState.calendar = false;
      patchState.open = false;
      patchState.value = this.changeAfterUpdateValue({ startDate: startMoment, endDate: endMoment });
      patchState.checkTimes = 0;
    }
    this.setState(patchState);
  }

  render() {
    const {
      value,
      open,
      dateRangeValue,
      calendarValue,
    } = this.state;
    const {
      singleDate, idKey, defaultDate, options, maxDate, minDate, style, width,
      forcedLabel,
      popoverOnLeft,
      selectCurrentAppDatePickerAvailableMomentRange,
    } = this.props;

    const [
      datePickerMinAvailableMoment,
      datePickerMaxAvailableMoment
    ] = selectCurrentAppDatePickerAvailableMomentRange();
    // react-date-range要使用当前app时区构造0点的date
    const minAvailableDate = new Date(Date.parse(datePickerMinAvailableMoment.format('YYYY-MM-DD')));
    const maxAvailableDate = new Date(Date.parse(datePickerMaxAvailableMoment.format('YYYY-MM-DD')));

    return (
      <div className={classnames('Select', scss.legacyTimePicker)} ref={(target) => { this.select = target; }} style={{ width: width || '' }}>
        <TimeSelect
          type="时间"
          options={options || ['所有上报时间', '最近1小时', '最近1天', '最近2天', '最近7天', '最近15天', '最近30天', '自定义时间段']}
          callback={{ key: '自定义时间段', cb: () => this.handleShowCalender() }}
          toggleOpen={() => this.toggleOpen()}
          updateValue={(val) => this.updateValue(val)}
          {...{
            value, open: this.state.open, singleDate, style, idKey,
            forcedLabel,
          }} />

        <div className={(this.state.calendar || singleDate) && open ? `timepicker${singleDate ? ' left0' : ''}` : 'timepicker dn'}
          style={popoverOnLeft ? { right: '100%', left: 'inherit' } : undefined}
        >
          {
            singleDate
              ? (
                <Calendar
                  locale={isZh() ? zhCN : enUS}
                  weekStartsOn={1}
                  minDate={minAvailableDate}
                  maxDate={maxAvailableDate}
                  onChange={v => this.onCalendarChange(v)}
                  date={calendarValue}
                  rangeColors={[CS_STYLES.PRIMARY_COLOR, CS_STYLES.PRIMARY_COLOR]}
                  color={CS_STYLES.PRIMARY_COLOR}
                />
              )
              : (
                <DateRange
                  locale={isZh() ? zhCN : enUS}
                  weekStartsOn={1}
                  minDate={minAvailableDate}
                  maxDate={maxAvailableDate}
                  showDateDisplay={false}
                  onChange={v => this.onDateRangeChange(v)}
                  ranges={dateRangeValue}
                  rangeColors={[CS_STYLES.PRIMARY_COLOR, CS_STYLES.PRIMARY_COLOR]}
                  color={CS_STYLES.PRIMARY_COLOR}
                />
              )
          }
        </div>
      </div>
    );
  }
}


// singleDate 指定日期组件是单选，还是选择一个时间范围
// defaultDate （可选）默认显示的日期，moment对象
TimePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  singleDate: PropTypes.bool,
  defaultDate: PropTypes.object,
  defaultSelect: PropTypes.string,
  maxDate: PropTypes.object,
  style: PropTypes.object,
  idKey: PropTypes.any,
  options: PropTypes.any,
  minDate: PropTypes.any,
  width: PropTypes.any,
  forcedLabel: PropTypes.string, // 强制覆盖默认的回显label
  popoverOnLeft: PropTypes.bool,
};

TimePicker.defaultProps = {
  maxDate: moment(),
};
