import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _style from './style.scss';


const YEAR_OPTIONS = [];
for (let i = new Date().getFullYear(); i >= 1897; i--) {
  YEAR_OPTIONS.push(i);
}

const MONTH_OPTIONS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const DAY_OPTIONS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
const SMALL_DAY_OPTIONS = DAY_OPTIONS.slice(0, 30);

function getDayOptions(year, month) {
  if (month === 2) {
    return DAY_OPTIONS.slice(0, (((year % 4 === 0 && year % 400 !== 0) || year % 400 === 0) ? 29 : 28));
  } else if ([1, 3, 5, 7, 8, 10, 12].indexOf(month) !== -1) {
    return DAY_OPTIONS;
  } else {
    return SMALL_DAY_OPTIONS;
  }
}

export default class DatePicker extends Component {
  constructor(props) {
    super(props);
    let initDate = [];
    if (props.date) {
      initDate = props.date.split('-');
    }
    this.state = {
      year: {
        options: YEAR_OPTIONS,
        open: false,
        value: initDate.length >= 3 ? initDate[0] : '',
      },
      month: {
        options: MONTH_OPTIONS,
        open: false,
        value: initDate.length >= 3 ? initDate[1] : '',
      },
      day: {
        options: DAY_OPTIONS,
        open: false,
        value: initDate.length >= 3 ? initDate[2] : '',
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    let initDate = [];
    if (nextProps.date) {
      initDate = nextProps.date.split('-');
    }
    this.setState({
      year: {
        options: YEAR_OPTIONS,
        open: false,
        value: initDate[0],
      },
      month: {
        options: MONTH_OPTIONS,
        open: false,
        value: initDate[1],
      },
      day: {
        options: DAY_OPTIONS,
        open: false,
        value: initDate[2],
      },
    }, this.unbindCloseMenuIfClickedOutside);
  }

  componentDidUpdate() {
    const { year, month, day } = this.state;
    if (year.value && month.value && day.value) {
      this.props.onChange(`${year.value}-${month.value}-${day.value}`);
    }
  }

  bindCloseMenuIfClickedOutside() {
    document.addEventListener('click', this.closeMenuIfClickedOutside.bind(this));
  }

  unbindCloseMenuIfClickedOutside() {
    document.removeEventListener('click', this.closeMenuIfClickedOutside);
  }

  closeMenuIfClickedOutside(event) {
    const { dayInput } = this;
    const { monthInput } = this;
    const { yearInput } = this;

    const eventOccuredOutsideSelect = this.clickedOutsideElement([dayInput, monthInput, yearInput], event);
    // Hide dropdown menu if click occurred outside of menu
    if (eventOccuredOutsideSelect) {
      this.setState({
        year: {
          options: YEAR_OPTIONS,
          open: false,
          value: this.state.year.value,
        },
        month: {
          options: MONTH_OPTIONS,
          open: false,
          value: this.state.month.value,
        },
        day: {
          options: DAY_OPTIONS,
          open: false,
          value: this.state.day.value,
        },
      }, this.unbindCloseMenuIfClickedOutside);
    }
  }

  // return true 表示点击发生在目标外
  clickedOutsideElement(elements, event) {
    let eventTarget = (event.target) ? event.target : event.srcElement;
    let result = true;
    function check(ele) {
      if (eventTarget === ele) {
        result = false;
      }
      return ele;
    }
    while (eventTarget != null) {
      elements.map(check);
      if (!result) {
        return result;
      }
      eventTarget = eventTarget.offsetParent;
    }
    return true;
  }

  /**
   * 点击输入框
   */
  handleClickInput(e) {
    e.stopPropagation();
    const name = e.target.getAttribute('name');
    if (!this.state[name]) { return false; }
    const [year, month, day] = ['year', 'month', 'day'].map((key) => {
      const item = this.state[key];
      return {
        ...item,
        open: key === name ? !item.open : false,
      };
    });

    this.setState({
      year,
      month,
      day,
    });
    this.bindCloseMenuIfClickedOutside();

    return true;
  }

  handleSelectItem(type, event) {
  // event.stopPropagation();
  // event.preventDefault();

    const value = event.target.getAttribute('value') || event.target.parentElement.getAttribute('value');
    // console.log(`handleSelectItem: type=${type} value=${value}`, event.target)
    if (!value || !this.state[type]) {
      return false;
    }

    const [newYear, newMonth, newDay] = ['year', 'month', 'day'].map((key) => {
      const item = this.state[key];
      return {
        options: item.options,
        open: false,
        value: type === key ? parseInt(value) : item.value,
      };
    });

    let finalNewDay = newDay;
    if (newYear.value && newMonth.value && type !== 'day') {
      finalNewDay = {
        ...newDay,
        options: getDayOptions(newYear.value, newMonth.value),
      };
    }

    this.setState({
      year: newYear,
      month: newMonth,
      day: finalNewDay,
    });

    return true;
  }


  /**
 *  输入框输入日期
 */
  handleBlurInput(type) {
    const { options } = this.state[type];
    let { value } = this.state[type];
    const first = options[0];
    const last = options[options.length - 1];

    if (!value) return false;
    if (typeof value === 'string' && !value.match(/^\d+$/i)) {
      value = options[0];
    } else {
      value = parseInt(value);
    }

    switch (type) {
      case 'year':
        value = value <= last || value > first ? first : value;
        break;
      case 'month':
      case 'day':
        value = value < first || value > last ? first : value;
        break;
      default:
        return false;
    }
    // this.setState({
    //   [type]: {
    //     options,
    //     value,
    //     open: false
    //   }
    // });
    return true;
  }

  handleChangeInput(type, event) {
    console.log(`handleChangeInput: type=${type} value=${event.target.value}`);
    event.stopPropagation();
    const current = this.state[type];
    const { value } = event.target;

    this.setState({
      [type]: {
        ...current,
        value,
      },
    });
  }

  render() {
    const { year, month, day } = this.state;
    return (
      <div className={_style.date_picker}>
        <div className={_style.item_picker}>
          <div className={_style.input_box}>
            <input
              ref={(target) => { this.yearInput = target; }}
              type="text"
              name="year"
              value={year.value || ''}
              onClick={(event) => { this.handleClickInput(event); }}
              onChange={(event) => { this.handleChangeInput('year', event); }}
              onBlur={() => { this.handleBlurInput('year'); }} />
            <span>{ this.props.yText }</span>
          </div>
          <ul className={((year.open && _style.open) || '')} onClick={(event) => { this.handleSelectItem('year', event); }}>
            { year.options.map((theYear) => (
              <li key={`year-${theYear}`} value={theYear}>
                {theYear}
              </li>
            )) }
          </ul>
        </div>
        <div className={_style.item_picker}>
          <div className={_style.input_box}>
            <input
              ref={(target) => { this.monthInput = target; }}
              type="text"
              name="month"
              value={month.value}
              onClick={(event) => { this.handleClickInput(event); }}
              onChange={(event) => { this.handleChangeInput('month', event); }}
              onBlur={() => { this.handleBlurInput('month'); }} />
            <span>{ this.props.mText }</span>
          </div>
          <ul className={((month.open && _style.open) || '')} onClick={(event) => { this.handleSelectItem('month', event); }}>
            {month.options.map((theMonth) => (
              <li key={`month-${theMonth}`} value={theMonth}>
                {theMonth}
              </li>
            )) }
          </ul>
        </div>
        <div className={_style.item_picker}>
          <div className={_style.input_box}>
            <input
              ref={(target) => { this.dayInput = target; }}
              type="text"
              name="day"
              value={day.value}
              onClick={(event) => { this.handleClickInput(event); }}
              onChange={(event) => { this.handleChangeInput('day', event); }}
              onBlur={() => { this.handleBlurInput('day'); }} />
            <span>{ this.props.dText }</span>
          </div>
          <ul className={(day.open ? _style.open : '')} onClick={(event) => { this.handleSelectItem('day', event); }}>
            {day.options.map((theDay) => (
              <li key={`day-${theDay}`} value={theDay}>
                {theDay}
              </li>
            )) }
          </ul>
        </div>
      </div>
    );
  }
}

DatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,

};

DatePicker.defaultProps = {
  yText: '年',
  mText: '月',
  dText: '日',
}
