import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import app from './app/appReducer';
import error from './error/errorReducer';
import issue from './issue/issueReducer';
import user from './user';
import crash from './crash';
import seniorSearch from './seniorSearch';
import summary from './summary/summaryReducer';
import global from './global/globalReducer';
import tagState from './tag';
import symbol from './symbol';
import symbolTable from './symbolTable/reducer';
import userSummary from './userSummary/userSummaryReducer';
import userVersion from './userVersion/reducer';
import userChannel from './userChannel/reducer';
import userdata from './userdata/reducer';
import product from './product/reducer';
import auth from './auth/reducer';
import ftAnalyse from './ftAnalyse/reducer';
import solution from './solution/reducer';
import sdkSummary from './sdkSummary/reducer';
import sdkCrashVersion from './sdkCrashVersion/reducer';
import sdkApp from './sdkApp/reducer';
import keyword from './keyWord/reducer';

export default (history) => combineReducers({
  router: connectRouter(history),
  app,
  error,
  issue,
  user,
  crash,
  seniorSearch,
  summary,
  global,
  tagState,
  symbol,
  symbolTable,
  userSummary,
  userVersion,
  userChannel,
  userdata,
  product,
  auth,
  ftAnalyse,
  solution,
  sdkSummary,
  sdkCrashVersion,
  sdkApp,
  keyword,
});
