/**
 * 判断当前页面是否在iframe中
 * @returns 当前页面是否在iframe中
 */
export function getIsIframe() {
  try {
    return window.self !== window.top; // 如果跨域会发生异常，所以需要try catch
  } catch (e) {
    return true;
  }
}
