export const VersatileKeywordSearchMethod = Object.freeze({
  IS_EMPTY: 'IS_EMPTY',
  IS_NOT_EMPTY: 'IS_NOT_EMPTY',
  EQUALS: 'EQUALS',
  NOT_EQUALS: 'NOT_EQUALS',
  CONTAINS: 'CONTAINS',
  NOT_CONTAINS: 'NOT_CONTAINS',
  IN: 'IN',
  NOT_IN: 'NOT_IN',
  WILDCARD: 'WILDCARD',
  NOT_WILDCARD: 'NOT_WILDCARD',
  TERMS_WILDCARD: 'TERMS_WILDCARD',
  NOT_TERMS_WILDCARD: 'NOT_TERMS_WILDCARD',
});
