import { fromJS } from 'immutable';
import { push, replace } from 'connected-react-router';
import { createAction } from 'redux-actions';
import crashRest from 'utils/CrashRest';
import UserRest from 'utils/UserRest';
import globalRest from 'utils/globalRest';
import {
  transdate, ravenPostError,
} from 'utils/helper';
import {
  SHOW_LOADING,
  HIDE_LOADING,
  ACTIVE_POP_ALERT,
  UNACTIVE_POP_ALERT,
  CHECK_IS_DEMO,
  SET_ERROR,
  RESET_ERROR,
  ACTIVE_UPLOAD_POP_ALERT,
  INACTIVE_UPLOAD_POP_ALERT,
  CHANGE_UPLOAD_POP_ALERT_INFO,
  ACTIVE_APPLY_POP_ALERT,
  UNACTIVE_APPLY_POP_ALERT,
} from 'store/actions';
import { injectAppInfo } from 'utils/constants';
import JsonUtil from 'utils/json-util';
import { ReduxActionType } from 'reducers/redux-action-type';

export function setPartialLocalStorage(state) {
  return (dispatch) => {
    dispatch({
      type: 'SET_PARTIAL_LOCAL_STORAGE',
      payload: state,
    });
    return Promise.resolve('OK');
  }
}

export function resetAppRelatedStateWhenAppChanged({ appId, platformId } = {}) {
  return async (dispatch) => {
    return dispatch({
      type: ReduxActionType.resetAppRelatedStateWhenAppChanged,
      payload: {appId, platformId},
    });
  }
}

export function showLoading() {
  return (dispatch) => {
    dispatch({
      type: SHOW_LOADING,
    });
    return Promise.resolve('OK');
  };
}

export function hideLoading() {
  return (dispatch) => {
    dispatch({
      type: HIDE_LOADING,
    });
    return Promise.resolve('OK');
  };
}

export const checkIsDemo = createAction(CHECK_IS_DEMO);

export function hasError() {
  return (dispatch, getState) => {
    const globalState = getState();
    return Promise.resolve(globalState.global && globalState.global.get('error') && globalState.global.get('error').get('hasError'));
  };
}

/**
 * 设置错误状态
 *
 * @param status
 * @param message
 * @returns {function()}
 */
export function setError(status, message) {
  document.title = 'CrashSight - 发生错误';
  ravenPostError(new Error(`[自定义错误]status: ${status}, message: ${message}`));
  return {
    type: SET_ERROR,
    payload: fromJS({ hasError: true, status, message }),
  };
}

export function resetError() {
  return {
    type: RESET_ERROR,
  };
}

/**
 * 更新token
 */
export function refreshToken() {
  return {
    rest: UserRest.token.refresh,
  };
}
/**
 *  激活弹出窗
 *  @param data {issueId, processor, members, status}
 */
export const activePopAlert = createAction(ACTIVE_POP_ALERT);

/**
 * 关闭弹出窗
 */
export const unactivePopAlert = createAction(UNACTIVE_POP_ALERT);
/**
 *  激活申请产品激活弹出框
 *  @param data {issueId, processor, members, status}
 */
export const activeApplyPopAlert = createAction(ACTIVE_APPLY_POP_ALERT);

/**
 * 关闭申请产品激活弹出框
 */
export const unactiveApplyPopAlert = createAction(UNACTIVE_APPLY_POP_ALERT);

/**
 * 打开上报符号表弹框
 */
export const activeUploadPopAlert = createAction(ACTIVE_UPLOAD_POP_ALERT);
/**
 * 关闭上好符号表弹框
 */
export const inactiveUploadPopAlert = createAction(INACTIVE_UPLOAD_POP_ALERT);

/**
 * 修改上传符号表提示信息
 */
export const changeUploadPopAlertInfo = createAction(CHANGE_UPLOAD_POP_ALERT_INFO);

export function listAlarmConfig() {
  return (dispatch, getState) => {
    const currentApp = getState().app.get('current');
    return dispatch({
      rest: crashRest.alarmConfig.list,
      data: { appId: currentApp.get('appId'), platformId: currentApp.get('pid') },
    });
  };
}

export function createAlarmConfig(reportConfig) {
  const refinedReportConfig = JsonUtil.deepReplaceEmptyObjectWithNull(reportConfig);
  return (dispatch, getState) => {
    const currentApp = getState().app.get('current');
    return dispatch({
      rest: crashRest.alarmConfig.create,
      data: Object.assign({}, refinedReportConfig, { appId: currentApp.get('appId'), platformId: currentApp.get('pid') }),
    });
  };
}

export function updateAlarmConfig(reportConfig) {
  const refinedReportConfig = JsonUtil.deepReplaceEmptyObjectWithNull(reportConfig);
  return (dispatch, getState) => {
    const currentApp = getState().app.get('current');
    return dispatch({
      rest: crashRest.alarmConfig.update,
      data: Object.assign({}, refinedReportConfig, { appId: currentApp.get('appId'), platformId: currentApp.get('pid') }),
    });
  };
}

export function deleteAlarmConfig(id) {
  return (dispatch, getState) => {
    const currentApp = getState().app.get('current');
    return dispatch({
      rest: crashRest.alarmConfig.delete,
      data: {
        appId: currentApp.get('appId'),
        id,
      },
    });
  };
}

/**
 * 事件统计
 */
export function report({
  appId, pid, moduleId, actionId,
}) {
  return (dispatch, getState) => {
    return true;
  };
}

/**
 * 手动上报接口
 */
export function reportFast(appId, pid, dataPtag) {
  return (dispatch) => {
    if (appId && pid && dataPtag && dataPtag.split('-').length === 2) {
      const [moduleId, actionId] = dataPtag.split('-');
      return dispatch(report({
        appId, pid, moduleId, actionId,
      }));
    }
    return Promise.resolve('OK');
  };
}

function isOld(time) {
  if (!time) {
    return false;
  }
  const registerTime = transdate(time);
  const now = new Date().getTime();
  if (!registerTime || registerTime < 0) {
    return 'unknow';
  }
  return (now - registerTime) > (3600 * 24);
}

/**
 * 重置搜索条件
 */
export const resetSearchParams = createAction('RESET_SEARCH_PARAMS');

/**
 * 修改leftmenu 状态
 */
export const changeLeftMenuState = createAction('CHANGE_LEFTMENU_STATE');

export function pushState(...args) {
  if (typeof args[0] === 'function') {
    return injectAppInfo(({ appId, pid }) => {
      const pathname = args[0](appId, pid);
      return push(pathname);
    });
  } else {
    return (dispatch) => {
      return dispatch(push.apply(null, Array.prototype.slice.call(args)));
    };
  }
}

export function replaceState(...args) {
  if (typeof args[0] === 'function') {
    return injectAppInfo(({ appId, pid }) => {
      return replace(args[0](appId, pid));
    });
  } else {
    return (dispatch) => {
      return dispatch(replace.apply(null, Array.prototype.slice.call(args)));
    };
  }
}

export function getDepartmentList() {
  return (dispatch) => {
    return dispatch({
      rest: globalRest.department.list,
      data: {
        _: new Date().getTime(),
      },
    });
  };
}

export function getDepartmentItem(itemId) {
  return (dispatch) => {
    return dispatch({
      rest: globalRest.department.item,
      data: {
        itemId,
      },
    });
  };
}

// 设置分页参数
export const setPageRows = createAction('SET_PAGE_ROWS');

// 获取公告内容
export function getNotice() {
  return (dispatch) => {
    return dispatch({
      rest: globalRest.notice.info,
    });
  };
}
