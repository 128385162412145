import { List, fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import {
  getTop5, getLastSeries, getSelectOption, getFtArr,
} from 'utils/helper';
import isArray from 'lodash/lang/isArray';
import {
  CHANGE_KEYWORD_SEARCH_PARAM,
  KEYWORDTREND_GET_SUCC,
  GETVERSIONS_GET_SUCC,
  KEYWORDINFO_GET_SUCC,
  KEYWORDINFO_POST_SUCC,
  KEYWORDINFO_PUT_SUCC,
  KEYWORDINFO_DELETE_SUCC,
  UPDATE_FTLIST_SIZE,
  UPDATE_STATE_STATUS,
  UPDATE_PAEG_NUM,
} from 'store/actions';
import { initialState } from './initialState';
export default handleActions({
  [CHANGE_KEYWORD_SEARCH_PARAM]: (state, action) => {
    const { type, value } = action.payload;
    const paramVal = /version|date|ftConfig|ft|pageType|chartType/.test(type)
      ? 'chartSearchParams'
      : 'listSearchParams';

    // 当ft 改变时，ftConfig 需要联动修改
    if (type === 'ft') {
      return state.update(paramVal,
        (params) => params.set(type, value).set('ftConfig', 'diy'));
    } else if (type === 'ftConfig' && value === 'top5') {
      const top5 = state.get('top5');
      return state.update(paramVal, (params) => params.set(type, value)
        .set('ft', top5));
    } else if (type === 'pageType') {
      // 需要同时同步listSearchParams 的version,date
      const { version, date } = state.get('chartSearchParams').toJS();

      return state.update(paramVal, (params) => params.set(type, value))
        .update('listSearchParams', (params) => params.set('detailVersion', version)
          .update('detailDate', (_date) => {
            return _date.set('startDate', date.endDate);
          }));
    } else if (type === 'detailDate') {
      return state.update(paramVal, (params) => params.set(type, fromJS(value)));
    }

    return state.update(paramVal, (params) => params.set(type, value));
  },
  [UPDATE_FTLIST_SIZE]: (state, action) => {
    return state.set('ftListLength', action.payload);
  },
  [UPDATE_STATE_STATUS]: (state, action) => {
    const { type, value } = action.payload;
    return state.set(type, value);
  },
  [UPDATE_PAEG_NUM]: (state, action) => state.set('totalPages', Math.floor(state.get('ftList').length / action.payload) + (state.get('ftList').length % action.payload === 0 ? 0 : 1)),
  [KEYWORDTREND_GET_SUCC]: (state, action) => {
    const rows = state.get('listSearchParams').get('rows');
    let datas = action.response || {};
    const { actionType } = action.params;
    const lastSeries = getLastSeries(datas);
    const opt = getSelectOption(lastSeries).toArray();
    const ftArr = getFtArr(lastSeries && lastSeries.size > 0 ? lastSeries.toJS() : []);

    if (isArray(datas) && datas.length === 0) {
      datas = {};
    }
    console.log('actionType ===> ', actionType);
    if (actionType === 'list') {
      // 单独请求详情
      return state.set('ftList', ftArr)
        .set('ftListLength', ftArr.length)
        .set('totalPages', Math.floor(ftArr.length / rows) + (ftArr.length % rows === 0 ? 0 : 1));
    } else {
      // 请求图表的时候,ftList取最近的一天的数据
      const top5 = getTop5('crashNum', lastSeries.toJS(), {
        withoutAll: true,
        _symbol: ',',
      });
      if (actionType === 'all') {
        return state.set('ftSeries', datas)
          .set('ftList', ftArr)
          .set('ftListLength', ftArr.length)
          .set('totalPages', Math.floor(ftArr.length / rows) + (ftArr.length % rows === 0 ? 0 : 1))
          .update('selectOptions', (options) => options.set('ft', opt))
          .update('chartSearchParams', (param) => param.set('ft', top5))
          .set('top5', top5)
          .set('firstLoad', false);
      } else {
        return state.set('ftSeries', datas)
          .update('selectOptions', (options) => options.set('ft', opt))
          .update('chartSearchParams', (param) => param.set('ft', top5))
          .set('top5', top5);
      }
    }
  },
  [GETVERSIONS_GET_SUCC]: (state, action) => {
    const { versions = [] } = action.response;
    return state.update('selectOptions', (options) => options.merge({
      version: new List(versions).map((item) => {
        return ({
          label: (parseInt(item.enable) === 0 ? `${item.version} 已关闭` : item.version),
          value: encodeURIComponent(item.version),
        });
      }).unshift({
        label: '全版本',
        value: '-1',
      }),
    }));
  },

  [KEYWORDINFO_GET_SUCC]: (state, action) => {
    const data = action.response;
    return state.set('keywordInfo', fromJS(data).filter((item) => item.size > 0));
  },

  [KEYWORDINFO_POST_SUCC]: (state, action) => {
    const { response } = action;
    if (response && response.keyword && response.name) {
      return state.update('keywordInfo', (items) => items.unshift(fromJS(response)));
    }
    return state;
  },

  [KEYWORDINFO_PUT_SUCC]: (state, action) => {
    const { params, response } = action;
    if (response && params && params.id) {
      const index = state.get('keywordInfo').findIndex((item) => item.get('id') === params.id);

      return state.update('keywordInfo', (arr) => arr.update(index, (item) => item.merge(params)));
    }
    return state;
  },

  [KEYWORDINFO_DELETE_SUCC]: (state, action) => {
    const { params, response } = action;
    if (response) {
      const index = state.get('keywordInfo').findIndex((item) => item.get('id') === params.id);
      return state.update('keywordInfo', (arr) => arr.delete(index));
    }

    return state;
  },
}, initialState);
