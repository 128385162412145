import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Tab, DataDetail, DataTable, DataHead, AiAnalysis, ReportDetail, UserCustomDataTable, StackDetail, SymbolTable, PageTrace,FDInformation
} from 'components/exception/issue';
import { SearchBox, Checkbox, PureRender } from 'components/commons';
import { getIssueDetailUrlPrefix, makeDetailObjectFromStackLine } from 'utils/helper';
import { logOps, levelOps, PLATFORM_ID, levelOpsPc } from 'utils/constants';
import { indexOf } from 'lodash/array';
import _style from './style.scss';
import {
  Tabs,
  Tooltip,
  Modal,
  Spin,
  Select,
  Button,
  notification,
  message,
  Collapse,
  Input,
  Row,
  Col,
  Radio,
  Space, Divider, Popover, Popconfirm, Tag,
} from 'antd';
import axios from 'axios';
import cookie from "../../../../utils/cookie";
import { withTranslation } from 'react-i18next';
import RestHelper from 'utils/RestHelper';
import { connect } from 'react-redux';
import { selectHasSpecifiedPermissionInCurrentApp } from 'utils/selectors/selectors';
import { orange, red } from '@ant-design/colors';
import { CopyOutlined, InfoCircleOutlined, DownloadOutlined  } from '@ant-design/icons';
import {
  isAndroidOrHarmony,
  isMobile,
  isPcOrLinux,
  isPcOrGameConsole,
  isGameConsole,
  PlatformUtil,
  isPlaystation, isAndroid, isSwitch, isMac, isIos,
} from 'utils/platform';
import { PcStackSourceEnum } from 'utils/constants/pc-stack-source-enum';
import { isNotNullish, isNullish } from 'utils/nullish';
import Axios from 'axios';
import MD5 from 'md5.js';
import { UserTypeEnum } from 'utils/constants/user-type-enum';
import { bindActionCreators } from 'redux';
import * as issueActions from 'reducers/issue/issueActions';
import * as globalActions from 'reducers/global/globalActions';
import { EXCEPTION_TYPE_INT_ANDROID_JAVA } from 'utils/constants/exception-type-int';
import IssueSendReportToWorkWeixinModal from 'components/exception/issue/IssueSendReportToWorkWeixinModal';
import { dataComplianceConfirm } from 'utils/dataComplianceConfirm';
import { ze } from 'utils/zhEn';
import { WebAppSettings } from 'utils/web-app-settings';
import { upsertWebAppSettings } from 'reducers/app/appActions';
import { DataDetailUtil } from 'components/exception/issue/DataDetail';
import { ExceptionCategoryUtil, ExceptionCategory } from 'utils/exception-category';
import { ErrorBoundary } from 'react-error-boundary';
import SameDeviceRelatedReports from 'components/exception/issue/SameDeviceRelatedReports';
import CrashAttachmentShowcase from 'components/exception/issue/LastReport/CrashAttachmentShowcase';
import ModuleList from 'components/exception/issue/ModuleList/ModuleList';
import RegisterInfo from 'components/exception/issue/RegisterInfo/RegisterInfo';
import { sleep } from 'utils/sleep';
import reportEvent, { EVENT_ACTIONS } from 'utils/reportEvent';
import CsTabs from 'components/antd-extension/CsTabs';
import CsCollapseButton from 'components/commons/CsCollapseButton/CsCollapseButton';
import { RxCopy, RxShare1 } from "react-icons/rx";
import { RiSendPlaneLine, RiLayoutGridFill } from "react-icons/ri";
import { PiCaretDoubleDownBold, PiCaretDoubleUpBold } from 'react-icons/pi';
import CsDownloadButton from 'components/commons/CsDownloadButton/CsDownloadButton';
import CsSwapSelect from 'components/commons/CsSwapSelect/CsSwapSelect';
import classnames from 'classnames';
import CopyreportIcon from 'svg/v2/newcs_dashboard_crashanalysis_issuedetail_copyreport_icon_normal.svg';
import SharereportIcon from 'svg/v2/newcs_dashboard_crashanalysis_issuedetail_sharereport_icon_normal.svg';
import SendreportIcon from 'svg/v2/newcs_dashboard_crashanalysis_issuedetail_sendreport_icon_normal.svg';
import ViewmoreIcon from 'svg/v2/newcs_dashboard_crashanalysis_issuedetail_viewmore_icon_normal.svg';
import SearchIcon from 'svg/v2/newcs_dashboard_crashanalysis_issuedetail_tracelog_filt_search_icon.svg';
import StackSymbolicateModal from './StackSymbolicateModal';
import { CrashAttachmentUtil } from 'utils/crash-attachment-util';
import { ServerAppSettings } from 'utils/server-app-settings';
import WrappedTipsIcon from 'components/antd-extension/WrappedTipsIcon.jsx';
import { ImNewTab } from 'react-icons/im';
import { RetraceHistoryUtil } from 'components/exception/issue/DataHead/RetraceHistoryModal';
import moment from 'moment';
import { CrashModuleUtil } from 'utils/crash-module-util';


function getToken(tokenType) {
  // 根据tokenType切换返回的token信息。
  if (tokenType === 'node') return cookie.get('csrfToken');
  // php的token处理。
  const skey = cookie.get('token-skey');
  let hash = 5381;
  if (skey) {
    for (let i = 0, len = skey.length; i < len; ++i) {
      hash += (hash << 5 & 0x7fffffff) + skey.charAt(i).charCodeAt();
    }
    return hash & 0x7fffffff;
  }
};

const MINIDUMP_STATUS = {
  PROCESS: 'PROCESS', // 还原中
  SUCCESS: 'SUCCESS', // 还原成功
  FAIL: 'FAIL', // 还原失败
};

const OTHER_THREAD_FILTER_TYPE_CONTAINS = 'OTHER_THREAD_FILTER_TYPE_CONTAINS';
const OTHER_THREAD_FILTER_TYPE_REGEX = 'OTHER_THREAD_FILTER_TYPE_REGEX';

const OtherThreadFilterTypeOptions = [
  { label: ze('包含', 'Contains'), value: OTHER_THREAD_FILTER_TYPE_CONTAINS },
  { label: ze('正则表达式匹配', 'Regexp Match'), value: OTHER_THREAD_FILTER_TYPE_REGEX }
];

@connect((state) => {
  return {
    reduxState: state,
    getHasSpecifiedPermission: (permission) => selectHasSpecifiedPermissionInCurrentApp(state, permission),
  };
}, (dispatch) => ({
  actions: bindActionCreators({
    ...globalActions,
    ...issueActions,
    upsertWebAppSettings,
  }, dispatch),
  dispatch,
}))
@withTranslation()
@PureRender
export default class LastReport extends React.Component {
  constructor(props) {
    super(props);
    this.childRef = React.createRef();
    this.isComponentUnmounted = false;
    this.state = {
      retraceLoading: false,

      componentHasError: false,
      obfuscationFileInfo: {},
      cosDownloadOrigin: '',
      isSdkLogFileChecked: false,
      hasSdkLogFile: false,
      isSdkLogFileDownloading: false,

      isSdkAttachFileChecked: false,
      hasSdkAttachFile: false,
      isSdkAttachFileDownloading: false,

      isGenerateShareLinkModalVisible: false,
      shareLinkExpireInDays: 7,
      shareLinkUserRole: UserTypeEnum.COMPLIANCE_MEMBER,

      isIssueSendReportToWorkWeixinModalVisible: false,

      symbolInfoCrashId: '', // symbolInfo和ignoreSymbolInfo对应的crashId，用于拉取前判断是否已经拉取过
      symbolInfoLoading: false,
      symbolInfoList: [],
      ignoreSymbolInfoList: [],

      isSetQuickViewCustomKeyModalVisible: false,
      editingQuickViewCustomKeyList: [],

      isLoadingOtherThread: false,

      otherThreadFilterType: OTHER_THREAD_FILTER_TYPE_CONTAINS,
      otherThreadFilterInputValue: '',
      otherThreadIndexToIsCollapsed: {},

      otherThreadFetchedCrashIdKeyMap: {}, // 临时用这个记录一下哪些crash id已经拉取过更多线程信息

      aiAnalysisType: false,//AI分析框是否展示
      isChildMethodExecuting: false,//AI分析按钮状态

      isReportDetailCollapsed: false,
      LogSymbolicateModalVisible: false,

      retraceStatusQueryResult: null,
    };
  }

  componentDidMount() {
    this.onEffectCrashIdChange();
    this.onEffectCrashIdOrTabKeyChange();
    this.onEffectObfuscationFile();
    this.onEffectFetchHasSdkLog();
    this.onEffectFetchHasSdkAttach();
    this.onEffectFetchPcSoInfo();
    this.onEffectFetchPcRegisterInfo();
    this.onEffectFetchRetracedAnrTrace();
  }

  componentWillUnmount() {
    this.isComponentUnmounted = true;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.onEffectCrashIdChange(prevProps);
    this.onEffectCrashIdOrTabKeyChange(prevProps);
    this.onEffectObfuscationFile(prevProps);
    this.onEffectFetchHasSdkLog(prevProps);
    this.onEffectFetchHasSdkAttach(prevProps);
    this.onEffectFetchPcSoInfo(prevProps);
    this.onEffectFetchPcRegisterInfo(prevProps);
    this.onEffectFetchRetracedAnrTrace(prevProps);
  }

  getServerAppSettings() {
    const { appId, reduxState } = this.props;
    return reduxState.app.get('appIdToServerAppSettings')[appId] || {};
  }

  getIsEnablePcLogTrace() {
    const serverAppSettings = this.getServerAppSettings();
    return serverAppSettings[ServerAppSettings.keys.enablePcLogTrace];
  }

  getIsEnableErrorRetrace() {
    const serverAppSettings = this.getServerAppSettings();
    return serverAppSettings[ServerAppSettings.keys.enableErrorRetrace];
  }

  getIsDemoApp() {
    const { reduxState } = this.props;
    return reduxState.app.get('current').get('demoApp');
  }

  getIsFromShareLink() {
    const { shareId } = this.props;
    return !!shareId;
  }

  getCrashIdFromProps(props) {
    if (!props) {
      return null;
    }
    const { crashHash } = props;
    if (!crashHash) {
      return null;
    }
    return crashHash.replace(/:/g, '');
  }

  getCrashIdFromCrashDoc(props) {
    const { issue } = props;
    if (!issue) {
      return undefined;
    }
    const { crashDoc } = issue.toJS();
    const { crashMap } = (crashDoc || {});
    const { crashId } = crashMap || {};
    return crashId;
  }

  onEffectCrashIdChange(prevProps = {}) {
    const crashId = this.getCrashIdFromCrashDoc(this.props);
    const prevCrashId = this.getCrashIdFromCrashDoc(prevProps);
    if (!crashId || crashId === prevCrashId) {
      return;
    }
    this.setState({
      retraceStatusQueryResult: null,
    });
    // 打开问题详情页面，加载重还原状态（用于显示转圈）
    if (!this.getIsFromShareLink()) {
      this.pollingRetraceStatus(crashId, false);
    }
  }

  onEffectCrashIdOrTabKeyChange(prevProps = {}) {
    const crashId = this.getCrashIdFromCrashDoc(this.props);
    const prevCrashId = this.getCrashIdFromCrashDoc(prevProps);
    const tabKey = this.props.issue && this.props.issue.get('tab2');
    const prevTabKey = prevProps.issue && prevProps.issue.get('tab2');

    if (crashId === prevCrashId && tabKey === prevTabKey) {
      return;
    }
    if (!crashId) {
      return;
    }

    const { appId, pid, actions, issue, crashHash, issueId, shareId, exceptionType } = this.props;

    const { attachList } = issue.toJS();
    if (indexOf(['LASTREPORT.附件下载', 'LASTREPORT.trackData', 'LASTREPORT.trackLog', 'LASTREPORT.anrMsg', 'LASTREPORT.anrTrace', 'LASTREPORT.otherInfo','LASTREPORT.崩溃线程寄存器信息','LASTREPORT.加载模块信息', 'LASTREPORT.FD信息', 'LASTREPORT.consoleInfo', 'LASTREPORT.memoryLog'], tabKey) !== -1) {
      if (PlatformUtil.hasCrashAttachment(pid, exceptionType, issue) && !attachList && issueId && crashHash) {
        actions.showLoading();
        if (this.getIsFromShareLink()) {
          actions.getCrashAttachmentFromShareLink(appId, pid, shareId)
            .then(actions.hideLoading)
            .catch(actions.hideLoading);
        } else {
          actions.getCrashAttachment(issueId, crashHash)
            .then(actions.hideLoading)
            .catch(actions.hideLoading);
        }
      }
    }
    if (isAndroidOrHarmony(pid) || tabKey === 'LASTREPORT.symbol') {
      this.cachedFetchSymbolInfoAndIgnoreSymbolInfo();
    }
  }

  onEffectObfuscationFile(prevProps = {}) {
    const { appId } = this.props;
    const { appId: prevAppId } = prevProps;
    const { issue } = this.props;
    const { issue: prevIssue } = prevProps;
    const crashMap = ((issue ? issue.toJS().crashDoc : {}) || {}).crashMap;
    const prevCrashMap = ((prevIssue ? prevIssue.toJS().crashDoc : {}) || {}).crashMap;
    const { productVersion: version } = (crashMap || {});
    const { productVersion: prevVersion } = (prevCrashMap || {});
    if (appId === prevAppId && version === prevVersion) {
      return;
    }
    if (!appId || !version) {
      return;
    }
    if (!(process.env.REACT_APP_OBFUSCATION_FILE_AVAILABLE_APPS || []).includes(appId)) {
      return;
    }
    this.fetchObfuscationFile();
  }

  onEffectFetchHasSdkLog(prevProps = {}) {
    const { appId } = this.props;
    const crashId = this.getCrashIdFromCrashDoc(this.props);
    const prevCrashId = this.getCrashIdFromCrashDoc(prevProps);
    if (!appId || !crashId) {
      return;
    }
    if (crashId === prevCrashId) {
      return;
    }

    if (!this.state.isSdkLogFileChecked) {
      (async () => {
        await this.checkIsSdkLogExist();
        this.setState({isSdkLogFileChecked: true});
      })();
    }
  }

  onEffectFetchHasSdkAttach(prevProps = {}) {
    const { appId } = this.props;
    const crashId = this.getCrashIdFromCrashDoc(this.props);
    const prevCrashId = this.getCrashIdFromCrashDoc(prevProps);
    if (!appId || !crashId) {
      return;
    }
    if (crashId === prevCrashId) {
      return;
    }

    if (!this.state.isSdkAttachFileChecked) {
      (async () => {
        await this.checkIsSdkAttachExist();
        this.setState({isSdkAttachFileChecked: true});
      })();
    }
  }

  onEffectFetchRetracedAnrTrace(prevProps = {}) {
    const { appId, pid, actions, shareId } = this.props;
    const crashId = this.getCrashIdFromCrashDoc(this.props);
    const prevCrashId = this.getCrashIdFromCrashDoc(prevProps);
    if (!appId || !crashId || crashId === prevCrashId) {
      return;
    }
    actions.fetchCurrentCrashRetracedAnrTrace(shareId);
  }

  onEffectFetchPcSoInfo(prevProps = {}) {
    const { appId, pid, actions, shareId } = this.props;
    const crashId = this.getCrashIdFromCrashDoc(this.props);
    const prevCrashId = this.getCrashIdFromCrashDoc(prevProps);
    if (!appId || !crashId) {
      return;
    }

    const nowTab2 = this.props.issue.get('tab2');
    const oldTab2 = prevProps.issue && prevProps.issue.get('tab2');

    if (isPcOrLinux(pid) || isPlaystation(pid)) { // PC/linux进来页面就拉取模块信息
      if (crashId === prevCrashId) {
        return;
      }
    } else if (isMobile(pid)) { // 移动端的附件现在增加了minidump栈内存，需要在点附件下载tab的时候拉取
      if (nowTab2 !== 'LASTREPORT.附件下载' || (nowTab2 === oldTab2 && crashId === prevCrashId)) {
        return;
      }
    } else {
      return;
    }

    actions.fetchCurrentCrashPcSoInfo(shareId);
  }

  onEffectFetchPcRegisterInfo(prevProps = {}) {
    const { appId, pid, actions, shareId } = this.props;
    if (!isPcOrLinux(pid) && !isPlaystation(pid)) {
      return;
    }
    const crashId = this.getCrashIdFromCrashDoc(this.props);
    const prevCrashId = this.getCrashIdFromCrashDoc(prevProps);
    if (!appId || !crashId) {
      return;
    }

    const nowTab2 = this.props.issue.get('tab2');
    const oldTab2 = prevProps.issue && prevProps.issue.get('tab2');
    if (nowTab2 !== 'LASTREPORT.崩溃线程寄存器信息' || (nowTab2 === oldTab2 && crashId === prevCrashId)) {
      return;
    }

    actions.fetchCurrentCrashPcRegisterInfo(shareId);
  }

  static getDerivedStateFromError(error) {
    return { componentHasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('LastReport componentDidCatch: ', error, errorInfo);
  }

  // 获取混淆表（LGAME需求）
  async fetchObfuscationFile() {
    const { appId, issue } = this.props;
    const { crashDoc } = issue.toJS();
    const { productVersion: version } = (crashDoc || {}).crashMap;
    const rsp = await RestHelper.mustPost('/customized/getObfuscationSymbolFile', {
      appId,
      version,
    });
    this.setState({ obfuscationFileInfo: rsp.json.data });
  }

  // 获取页面追踪数据
  getPageTraceContent() {
    const { issue } = this.props;
    const { attachList } = issue.toJS();

    if (!attachList) {
      return '';
    }
    let contents = '';
    attachList.forEach((item) => {
      if (item.fileName === 'martianlog.txt') {
        contents += item.content;
      }
    });
    return contents;
  }

  makeStackLineDetailObjectList(stackContent) {
    const { pid } = this.props;
    const stackLines = stackContent.split('\n');
    return stackLines.map((stackLine, i) => {
      return makeDetailObjectFromStackLine(stackLine, 'crash', pid);
    }).filter(x => x.line);
  };

  getIosExtraStackThreads() {
    const { issue } = this.props;
    const { appDetailCrashInfo } = issue.toJS();
    if (!appDetailCrashInfo) {
      return null;
    }
    const extraStackThreadNames = (appDetailCrashInfo.stackName || '').split(';')
      .filter(x => x)
      .sort((a, b) => a.localeCompare(b));
    if (extraStackThreadNames.length === 0) {
      return null;
    }

    return extraStackThreadNames.map(threadName => {
      const attachment = appDetailCrashInfo.attachList.find(x => x.fileName === threadName && Number(x.fileType) === 2);
      const attachmentContent = (attachment || {}).content;
      if (!attachmentContent) {
        return { name: threadName, isPresent: false, stackLineDetailObject: {} };
      }
      const stackLineDetailObject = this.makeStackLineDetailObjectList(attachment.content);
      return { name: threadName, isPresent: true, stackLineDetailObject };
    });
  }



  handleChangeTab(key) {
    const {
      dispatch,
    } = this.props;
    dispatch({
      type: 'CHANGE_TAB',
      value: key,
      tab: '2',
    });
  }

  async asyncMakeSdkLogRequestParams() {
    const { issue, appId, pid } = this.props;
    const { crashDoc } = issue.toJS();
    const crashMap = crashDoc.crashMap || {};
    const { sdkVersion } = crashMap;
    const filename = CrashAttachmentUtil.getSdkLogFilename(pid, sdkVersion);
    return CrashAttachmentUtil.makeRequestParamsForSdkUploadedFile(filename, appId, pid, crashMap);
  }

  async asyncMakeSdkAttachRequestParams() {
    const { issue, appId, pid } = this.props;
    const { crashDoc } = issue.toJS();
    const crashMap = crashDoc.crashMap || {};
    const { sdkVersion } = crashMap;
    const filename = CrashAttachmentUtil.getSdkAttachFilename(pid, sdkVersion);
    return CrashAttachmentUtil.makeRequestParamsForSdkUploadedFile(filename, appId, pid, crashMap);
  }

  async checkIsSdkLogExist() {
    const params = await this.asyncMakeSdkLogRequestParams();
    if (isNullish(params)) {
      this.setState({ hasSdkLogFile: false });
    }
    try {
      const rsp = await Axios.head(...params);
      if (rsp.status === 200) {
        this.setState({ hasSdkLogFile: true });
      } else {
        this.setState({ hasSdkLogFile: false });
      }
    } catch (e) {
      this.setState({ hasSdkLogFile: false });
    }
  }

  async checkIsSdkAttachExist() {
    const params = await this.asyncMakeSdkAttachRequestParams();
    if (isNullish(params)) {
      this.setState({ hasSdkAttachFile: false });
    }
    try {
      const rsp = await Axios.head(...params);
      if (rsp.status === 200) {
        this.setState({ hasSdkAttachFile: true });
      } else {
        this.setState({ hasSdkAttachFile: false });
      }
    } catch (e) {
      this.setState({ hasSdkAttachFile: false });
    }
  }

  async downloadSdkLog(filename) {
    const { issue } = this.props;
    const { crashDoc } = issue.toJS();
    const esMap = crashDoc.esMap || {};
    const { expUid } = esMap;

    this.setState({ isSdkLogFileDownloading: true });
    message.info(ze('下载中，请稍候', 'Downloading.'));
    try {
      const params = await this.asyncMakeSdkLogRequestParams();
      const rsp = await Axios.get(...params);
      if (rsp.status >= 400) {
        const errorMessage = `Download failed, request status = ${rsp.status}`;
        notification.error(errorMessage);
        throw Error(errorMessage);
      }
      const url = window.URL.createObjectURL(new Blob([rsp.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${expUid}_${filename}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } finally {
      this.setState({ isSdkLogFileDownloading: false });
    }
  }

  async downloadSdkAttach(filename) {
    const { issue } = this.props;
    const { crashDoc } = issue.toJS();
    const esMap = crashDoc.esMap || {};
    const { expUid } = esMap;

    this.setState({ isSdkAttachFileDownloading: true });
    message.info(ze('下载中，请稍候', 'Downloading.'));
    try {
      const params = await this.asyncMakeSdkAttachRequestParams();
      const rsp = await Axios.get(...params);
      if (rsp.status >= 400) {
        const errorMessage = `Download failed, request status = ${rsp.status}`;
        notification.error(errorMessage);
        throw Error(errorMessage);
      }
      const url = window.URL.createObjectURL(new Blob([rsp.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${expUid}_${filename}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } finally {
      this.setState({ isSdkLogFileDownloading: false });
    }
  }

  handleLogTypeSelectChange(value) {
    const { changeLog, issueId } = this.props;
    changeLog(value, issueId);
  }

  handleLogLevelSelectChange(value) {
    const { changeLevel, issueId } = this.props;
    changeLevel(value, issueId);
  }

  handleCheckboxClick(checked) {
    const { clickCheckbox, issueId } = this.props;
    clickCheckbox(checked, issueId);
  }

  handleKeywordChange(value) {
    const { changeLogKey, issueId } = this.props;
    changeLogKey(value, issueId);
  }

  handleSymbolicateClick() {
    this.setState({ LogSymbolicateModalVisible: true });
  }

  handleDownloadClick(issue,hasNoSystemLog,pid) {
    const {
      sysLogs, userLogs, logType,
    } = issue.toJS();
    let log = [];
    if (logType === 'userLog') {
      log = userLogs;
    } else if (logType === 'sysLog' && sysLogs && !hasNoSystemLog) {
      log = sysLogs;
    }
    const sequences = log.join('\n');
    const blob = new Blob([sequences], { type: 'text/plain;charset=utf-8' });

    // 使用file-saver库保存Blob对象为.txt文件
    saveAs(blob, logType === 'userLog' ? 'CustomLog.txt' : 'SystemLog.txt');
    reportEvent({
      action: EVENT_ACTIONS.CLICK,
      tp1: '跟踪日志-数据下载',
    });
  }

  // 功能：还原和解析功能以及重还原功能。
  async handleStackTypeClick(stackType) {
    // 重还原的接口在这里添加，添加重还原功能。发起重还原请求。
    const { dispatch, issueId, appId, issue, crashHash } = this.props;
    const {exceptionName, crashDoc } = issue.toJS();
    const crashMap = crashDoc.crashMap || {};

    if (stackType === 'retraceAgain') {
      this.handleReloadCrash('retraceAgain');
      // TODO: 确认重还原接口的逻辑，重新发起请求，添加loading状态。
      // actions.showLoading();
      // actions.getCrashAttachment(issueId, crashHash).then(actions.hideLoading).catch(actions.hideLoading);
    } else if (stackType === 'retraceMinidump') {
      this.handleReloadCrash('retraceMinidump');
    } else if (stackType === 'aiParsing') {
      this.setState({aiAnalysisType:true},async() => {
        if(this.childRef.current){
          this.setState({ isChildMethodExecuting: true });
          await this.childRef.current.childMethod();
          this.setState({ isChildMethodExecuting: false });
        }
      })
    } else {
      dispatch({
        type: 'STACK_TYPE_CHANGE',
        issueId,
        stackType,
      });
    }
  }

  /**
   * 切换其他线程项的显示/隐藏
   */
  handleToggleOtherThreadItem(index) {
    this.setState({
      otherThreadIndexToIsCollapsed: {
        ...this.state.otherThreadIndexToIsCollapsed,
        [index]: !this.state.otherThreadIndexToIsCollapsed[index],
      },
    });
  }

  /*
   收起，打开其他线程,会在这一步开始去请求其它线程的数据信息;如果attachList已经存在，则显示其它线程的请求已经发起过了，这一步就不再去做请求了。
   */
  async handleOtherThreadClick() {
    const {
      dispatch, issue, actions, crashHash, issueId,
      shareId,
      pid,
    } = this.props;
    const { isShowOtherThread, attachList } = issue.toJS();
    // 默认先关闭
    dispatch({
      type: 'IS_SHOW_OTHER_THREAD',
      isShow: !isShowOtherThread,
    });

    if (!issueId || !crashHash) {
      return;
    }

    this.setState({
      otherThreadFetchedCrashIdKeyMap: {
        ...this.state.otherThreadFetchedCrashIdKeyMap,
        [crashHash]: true,
      },
    });

    const needFetch = isPcOrLinux(pid)
      ? !isShowOtherThread && !this.state.otherThreadFetchedCrashIdKeyMap[crashHash]
      : !isShowOtherThread && !attachList;

    if (needFetch) {
      await this.fetchOtherThreads();
    }
  }

  async fetchOtherThreads() {
    const {
      appId,
      dispatch, issue, actions, crashHash, issueId,
      shareId,
      pid,
    } = this.props;

    try {
      this.setState({ isLoadingOtherThread: true });
      actions.showLoading();
      if (PlatformUtil.isMobileOrMobileLike(pid)) {
        if (this.getIsFromShareLink()) {
          await actions.getCrashAttachmentFromShareLink(appId, pid, shareId);
        } else {
          await actions.getCrashAttachment(issueId, crashHash);
        }
      } else if (isPcOrLinux(pid)) {
        await this.fetchPcOrLinuxOtherThreads();
      }
    } finally {
      this.setState({ isLoadingOtherThread: false });
      actions.hideLoading();
    }
  }

  async fetchPcOrLinuxOtherThreads() {
    const { dispatch, issue, appId, pid: platformId, shareId } = this.props;
    const { crashDoc } = issue.toJS();
    const esMap = crashDoc.esMap || {};
    let { otherBreakpadCosKey } = esMap;
    if (!otherBreakpadCosKey) {
      message.info(ze('当前上报没有其他线程信息。', 'This report has no other threads info.'));
      return;
    }
    if (!otherBreakpadCosKey.endsWith('.dmp.gz')) {
      otherBreakpadCosKey = otherBreakpadCosKey + '.dmp.gz';
    }
    const rsp = await RestHelper.post('/api/crash/decompressInflateFile', {
      appId,
      platformId,
      shareId,
      cosKey: otherBreakpadCosKey,
      compressFormat: 'GZIP',
    });
    const { hasFile, contentBase64 } = rsp.json.data;
    if (hasFile) {
      const uint8Array = Uint8Array.from(window.atob(contentBase64), c => c.charCodeAt(0));
      const otherThreadsText = new TextDecoder().decode(uint8Array);
      dispatch({
        type: 'PARSE_AND_SAVE_PC_OTHER_THREADS',
        payload: {
          otherThreadsText,
        },
      });
    } else {
      message.info(ze('当前上报没有其他线程信息。', 'This report has no other threads info.'));
    }
  }

  // 新的重还原请求接口
  async handleReloadCrash(type) {
    const {
      appId, pid: platformId, issueId, crashHash, user,
      exceptionType,
      actions,
    } = this.props;

    const crashId = this.getCrashIdFromProps(this.props);

    const isRetraceMinidump = type === 'retraceMinidump';
    let retraceType = undefined;
    if (isRetraceMinidump) {
      retraceType = exceptionType === 'crash' || exceptionType === 'crashes'
        ? 'MINIDUMP_CRASH'
        : 'MINIDUMP_ERROR';
    }

    this.setState({ retraceLoading: true });
    const rsp = await RestHelper.mustPost('/api/issue/reRetraceCrash', {
      appId,
      platformId,
      issueId,
      crashDataType: 'ReloadCrash',
      crashHash: crashId,
      requestLocalUserId: user.get('newUserId'), // 用于帮助定位重还原问题，增加重还原的请求人
      requestUserName: user.get('nickname'),
      retraceType,
    });

    this.pollingRetraceStatus(crashId, true);
    this.reloadCrashDataAfterPollingRetraceStatus();
  }

  async pollingRetraceStatus(crashId, delayFirstPolling) {
    if (!crashId) {
      return;
    }
    const {
      appId, pid: platformId, issueId, crashHash, user,
    } = this.props;

    const POLLING_INTERVAL_MS = 5000;
    const MAX_TRY = 300;
    let tryCount = 0;
    let retraceStatus = 0;
    for (; tryCount < MAX_TRY; tryCount += 1) {
      if (delayFirstPolling) {
        await sleep(POLLING_INTERVAL_MS);
      }

      // 如果切换了查看的上报，或者关闭了LastReport（例如更多上报点击了其他的上报），那么停止轮询
      if (this.isComponentUnmounted) {
        return;
      }
      const currentPageCrashId = this.getCrashIdFromProps(this.props);
      if (currentPageCrashId !== crashId) {
        return;
      }

      retraceStatus = await this.handleReloadCrashStatus();
      if (isNullish(retraceStatus) || retraceStatus <= 0) {
        return;
      }

      if (!delayFirstPolling) {
        await sleep(POLLING_INTERVAL_MS);
      }
    }

    // 超过重试次数，报错并return
    if (tryCount === MAX_TRY) {
      notification.error({
        message: ze('重还原请求超时', 'Re-symbolication Timeout'),
        description: ze('请稍后重试，或联系客服反馈问题。', 'Please try again later or contact customer service for assistance.'),
        duration: 30,
      });
      throw Error('Re-symbolication Timeout');
    }

    // 小于0代表出现服务器端错误，报错并return
    if (retraceStatus < 0) {
      notification.error({
        message: ze('重还原失败', 'Re-symbolication Failed'),
        description: ze('服务器发生错误。请稍后重试，或联系客服反馈问题。', 'A server error occurred. Please try again later or contact customer service for assistance.'),
        duration: 30,
      });
      throw Error('Re-symbolication Failed');
    }
  }

  // 重还原完成，刷新最新异常堆栈和其他线程的堆栈
  async reloadCrashDataAfterPollingRetraceStatus() {
    const {
      appId, pid: platformId, issueId, crashHash, user,
      exceptionType,
      actions,
    } = this.props;
    const crashId = this.getCrashIdFromProps(this.props);
    await actions.getCrashDoc(issueId, crashId);
    await this.fetchOtherThreads();
    const { crashDoc } = this.props.issue.toJS();
    const { esMap, crashMap } = crashDoc || {};
    const {
      retraceResultInfo,
      minidumpParseStatus,
      minidumpParseFailReason,
      appSoNameList,
    } = esMap || {};

    const isRetraceMinidump = type === 'retraceMinidump';
    let retraceType = undefined;
    if (isRetraceMinidump) {
      retraceType = exceptionType === 'crash' || exceptionType === 'crashes'
        ? 'MINIDUMP_CRASH'
        : 'MINIDUMP_ERROR';
    }

    if (!isRetraceMinidump) { // 非minidump
      const { systemSoNameList } = crashMap || {};
      const lowerSystemSoNameList = (systemSoNameList || []).map(x => (x || '').toLowerCase());
      const lowerAppSoNameList = (appSoNameList || []).map(x => (x || '').toLowerCase());

      const failedSoNames = Object.entries(retraceResultInfo || {})
        .filter(([k, v]) => !CrashModuleUtil.getIsSystemModule(k, lowerSystemSoNameList, lowerAppSoNameList) && v)
        .map(([k, v]) => k);

      if (failedSoNames.length === 0) {
        notification.success({
          message: ze('重还原已执行，堆栈内容已刷新', 'Re-symbolication done, stack trace have been updated.'),
          duration: 10,
        });
      } else {
        notification.warning({
          message: ze('重还原已执行，堆栈内容已刷新。', 'Re-symbolication done, stack trace have been updated.'),
          description: <div>
            <div>{ze('以下模块还原失败：', 'The following modules failed to re-symbolicate.')}</div>
            {failedSoNames.map((x, i) => <div key={i}>{x}</div>)}
          </div>,
          duration: 30,
        });
      }
    } else { // minidump
      if (minidumpParseStatus !== MINIDUMP_STATUS.FAIL) {
        notification.success({
          message: ze('重还原已执行，堆栈内容已刷新', 'Re-symbolication done, stack trace have been updated.'),
          duration: 10,
        });
      } else {
        notification.warning({
          message: ze('重还原失败', 'Re-symbolication failed'),
          description: ze(`错误码：${minidumpParseFailReason}`, `Error code = ${minidumpParseFailReason}`),
          duration: 30,
        });
      }
    }
  }

  // 查询重还原状态
  async handleReloadCrashStatus() {
    const {
      issue, appId, pid,issueId
    } = this.props;
    const crashId = this.getCrashIdFromCrashDoc(this.props);
    if (!crashId) {
      return;
    }
    const rsp = await RestHelper.post('/redir/api/issue/queryRetraceStatus', {
      appId,
      issueId,
      crashId,
    });

    const data = rsp.json.data;
    const retraceStatusQueryResult = {
      ...data,
      lastRetraceStartTime: data.lastRetraceStartTime && moment(data.lastRetraceStartTime).format('YYYY-MM-DD HH:mm:ss'),
      lastRetraceEndTime: data.lastRetraceEndTime && moment(data.lastRetraceEndTime).format('YYYY-MM-DD HH:mm:ss'),
    };
    const { retraceStatus } = retraceStatusQueryResult;

    this.setState({
      retraceLoading: RetraceHistoryUtil.getIsRetraceProcessing(retraceStatus),
      retraceStatusQueryResult,
    });
    return retraceStatus;
  }

  async cachedFetchSymbolInfoAndIgnoreSymbolInfo(forceFetch = false) {
    if (this.getIsFromShareLink()) {
      return;
    }
    const {
      appId, pid, issue,
    } = this.props;
    const { attachList, crashDoc, pcModuleInfoList } = issue.toJS();
    const { crashMap, esMap } = (crashDoc || {});
    const { crashId } = crashMap;
    if (crashId === this.state.symbolInfoCrashId && !forceFetch) {
      return;
    }

    const version = crashMap.productVersion || 'NO_VERSION';
    let uuids = [];
    if (isMobile(pid) && crashMap.appUUID) {
      uuids = [crashMap.appUUID];
    }
    const {
      appSoNameList,
    } = esMap || {};
    if (PlatformUtil.isIosOrMac(pid)) {
      // iOS额外增加UnityFramework模块。Mac额外增加appSoNameList中的模块。
      const soInfoLines = DataDetailUtil.makeSoInfoTextLines(pid, attachList);
      const extraModuleNames = isIos(pid)
        ? ['UnityFramework']
        : appSoNameList || [];
      const extraModuleLines = soInfoLines.filter(x => extraModuleNames.some(name => x.includes(name)));
      extraModuleLines.forEach((extraLine) => {
        // 格式是： UnityFramework: arm64;e9a1f943db113cccab834b045eb91aae;0x0000000115d88000 这样
        const extraUuid = (extraLine.split(';')[1] || '').toUpperCase();
        uuids.push(extraUuid);
      })
    }

    this.setState({
      symbolInfoLoading: true,
    });
    const uuidQuery = uuids.filter(x => x).join(',') || '-1';
    const [symbolRsp, ignoreRsp] = await Promise.all([
      RestHelper.post(`/redir/api/symbol/queryIssueSymbolInfo`, {
        appId,
        version,
        uuid: uuidQuery,
      }),
      RestHelper.get(`/getIgnoreSymbol/appId/${appId}/platformId/${pid}`),
    ]);
    this.setState({
      symbolInfoCrashId: crashId,
      symbolInfoList: symbolRsp.json.data,
      ignoreSymbolInfoList: ignoreRsp.json.ret.data,
      symbolInfoLoading: false,
    });
  }


  handleCloseClick() {
    const { closeCrashItem } = this.props;
    // //默认先关闭
    // dispatch({
    //  type: 'IS_SHOW_CRASHITEM',
    //  value: '-1'
    // });
    closeCrashItem();
  }

  /**
   * 从crashDoc/detailMap/attachName 字符串里面判断是否有某个字段
   * @param attachName {string}
   * @return {object}
   */
  hasAttachFile(attachName, filename) {
    return typeof attachName === 'string' && attachName.indexOf(filename) > -1;
  }

  async copyUrlToClipboard(text) {
    const { t } = this.props;
    try {
      await navigator.clipboard.writeText(text);
      message.success(t('LASTREPORT.已复制链接至剪贴板'));
    } catch (e) {
      notification.error({ message: 'Failed to copy text to clipboard.' });
    }
    reportEvent({
      action: EVENT_ACTIONS.CLICK,
      tp1: '复制链接',
    });
  }

  async generateReportShareLink() {
    const { appId, pid: platformId, issueId, issue } = this.props;
    const { crashDoc } = issue.toJS();
    const { crashId } = crashDoc.crashMap || {};
    const { shareLinkExpireInDays, shareLinkUserRole } = this.state;

    const rsp = await RestHelper.mustPost('/api/issue/generateReportShareLink', {
      appId,
      platformId,
      issueId,
      crashId,
      expireInSeconds: shareLinkExpireInDays * 86400,
      equivalentUserRole: shareLinkUserRole,
    });
    const { shareId } = rsp.json.data;
    const shareLink = `${window.location.origin}/shared-report/${shareId}`;
    Modal.success({
      width: '800px',
      content: <div>
        <div>{ze('已生成分享链接：', 'The sharing link has been generated:')}</div>
        <div style={{ marginTop: '8px' }}>
          <Input.Group compact>
            <Input
              style={{ width: '680px' }}
              readOnly
              value={shareLink}
            />
            <Button
              icon={<CopyOutlined/>}
              onClick={() => this.copyUrlToClipboard(shareLink)}
            />
          </Input.Group>
        </div>
      </div>,
    });


  }

  renderTop(exceptionType, appId, pid, issueId, crashId, crashDataType) {
    const {
      isReport,
      isIssueRegroup,
      getHasSpecifiedPermission,
      reduxState,
    } = this.props;
    const isInTencentLocalNetwork = reduxState.user.get('isInTencentLocalNetwork');
    const allowGenerateShareLink = getHasSpecifiedPermission('VIEW_DETAIL');

    if (isIssueRegroup) {
      return null;
    }

    const issueDetailUrlPrefix = getIssueDetailUrlPrefix(exceptionType);
    const { isLastReport, t } = this.props;
    const notLastReportNotice = t("LASTREPORT.notLastReportNotice")
      .split(/<a>(.*?)<\/a>/)
      .map((x, i) => i % 2
        ? <Link to={`/${issueDetailUrlPrefix}/${appId}/${issueId}?pid=${pid}`}>{x}</Link>
        : <span>{x}</span>);

    const reportPageUrl = `${window.location.origin}/${issueDetailUrlPrefix}/${appId}/${issueId}/report-id/${crashId}?pid=${pid}`;

    return (
      <div className={_style.title}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          { !isReport && isLastReport && <span className={_style.lastReportPrefix}>{ t("LASTREPORT.title") }</span> }
          <span className={_style.crashId}>
            { t("LASTREPORT.postId") }
            {crashId}
          </span>
          { !this.getIsFromShareLink() && <Button
            onClick={() => { this.copyUrlToClipboard(reportPageUrl) }}
            type='link'
            style={{ marginLeft: '40px', padding: 0 }}
          ><div
            className={_style.IconHover}
            style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
            <CopyreportIcon />
            <div>{ t('LASTREPORT.复制本条异常上报的链接') }</div>
          </div></Button> }
          { !this.getIsDemoApp() && !this.getIsFromShareLink() && allowGenerateShareLink && <Button
            onClick={() => {
              this.setState({ isGenerateShareLinkModalVisible: true });
            }}
            type='link'
            style={{ marginLeft: '24px', padding: 0 }}
          ><div
            className={_style.IconHover}
            style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
            <SharereportIcon />
            <div>{ t('LASTREPORT.生成匿名访问链接') }</div>
          </div></Button> }
          { !this.getIsDemoApp() && !this.getIsFromShareLink() && isInTencentLocalNetwork && <Button
            onClick={async() => {
              await dataComplianceConfirm();
              this.setState({ isIssueSendReportToWorkWeixinModalVisible: true } );
            }}
            type='link'
            style={{ marginLeft: '24px', padding: 0 }}
          ><div
            className={_style.IconHover}
            style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
            <SendreportIcon/>
            <div>{ t('LASTREPORT.分享此上报至企业微信/钉钉/飞书/Slack') }</div>
          </div></Button>}

          { !this.getIsDemoApp() && !this.getIsFromShareLink() && isReport && <Button
            type='link'
            style={{ marginLeft: '24px', padding: 0 }}
          ><a href={reportPageUrl} target="_blank" rel="noopener noreferrer"><div
            className={_style.IconHover}
            style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
            <ImNewTab />
            <div>{ ze('在新页面打开', 'Open in New Tab') }</div>
          </div></a></Button> }

          <div style={{ flexGrow: 1 }} />
          { !isReport && <Link to={`/${issueDetailUrlPrefix}/${appId}/${issueId}/report?pid=${pid}&crashDataType=${crashDataType}`}>
            <div
              className={_style.IconHover}
              style={{ display: 'flex', alignItems: 'center', gap: '2px', marginRight:'10px' }}>
              <ViewmoreIcon/>
              <span className={`${_style.link}`}>{ t("LASTREPORT.checkMore") }</span>
            </div>
          </Link> }
          { !isReport && <CsCollapseButton
            style={{ marginLeft: '20px' }}
            isCollapsed={this.state.isReportDetailCollapsed}
            onClick={() => this.setState({ isReportDetailCollapsed: !this.state.isReportDetailCollapsed })}
          /> }
        </div>
        { !isReport && !isLastReport && <div className={_style.notLastReportNoticeBlock}>{ notLastReportNotice }</div> }
      </div>
    );
  }

  renderStackTraceTypeSwapSelect() {
    const { actions, t, shareId } = this.props;
    let { crashDoc, isShowingMinidumpStack, minidumpStackInfo } = this.props.issue.toJS();
    const esMap = (crashDoc || {}).esMap || {};
    const {
      crashId,
      hasMinidump,
      minidumpParseStatus,
      minidumpParseFailReason,
    } = esMap;
    if (!hasMinidump) {
      return null;
    }

    const isMinidumpOptionAvailable = minidumpParseStatus === MINIDUMP_STATUS.SUCCESS;

    const clientStackTraceOption = { value: 0, label: t('LASTREPORT.服务器回溯堆栈') };
    const serverStackTraceOption = { value: 1, label: t('LASTREPORT.客户端回溯堆栈') };

    const swapSelect = <CsSwapSelect
      options={[clientStackTraceOption, serverStackTraceOption]}
      value={Number(!!isShowingMinidumpStack)}
      onChange={async (v) => {
        if (v && !isMinidumpOptionAvailable) {
          return;
        }
        actions.patchCurrentIssueInfo({ isShowingMinidumpStack: v });
        if (v) {
          actions.fetchCurrentCrashMinidumpStack(shareId);
          actions.fetchCurrentCrashPcRegisterInfo(shareId);
          await actions.fetchCurrentCrashPcSoInfo(shareId);
          // minidump的匹配符号表信息需要额外拉取一下
          this.cachedFetchSymbolInfoAndIgnoreSymbolInfo(true);
        }
      }}
    />;

    let res = swapSelect;
    if (minidumpParseStatus === MINIDUMP_STATUS.PROCESS) {
      res = <Tooltip
        title={ze('正在处理服务器回溯堆栈。', 'The minidump re-symbolication is processing.')}
      >{ swapSelect }</Tooltip>;
    } else if (minidumpParseStatus === MINIDUMP_STATUS.FAIL) {
      res = <Tooltip
        title={ze(`服务器回溯堆栈失败，失败原因：${minidumpParseFailReason}`, `Minidump re-symbolication failed, error code: ${minidumpParseFailReason}`)}
      >{ swapSelect }</Tooltip>;
    } else if (!isMinidumpOptionAvailable) {
      res = <Popconfirm
        title={ <div style={{ width: '300px' }}>{ ze('此上报尚未执行过服务器侧堆栈还原。是否要以Minidump方式在服务器侧还原堆栈？还原后即可查看服务器回溯堆栈相关信息。', 'The report has not yet been symbolicated server side stack trace. Do you want to symbolicate server side stack trace in the form of a minidump? After the symbolication, you will be able to view the relevant information of the serverside stack trace.') }</div> }
        onConfirm={() => this.handleStackTypeClick('retraceMinidump')}
      >{ swapSelect }</Popconfirm>
    }

    return res;
  }

  /**
   * 渲染出错堆栈Tab的内容
   */
  renderErrorStackTab() {
    const {
      issue, appId, pid, exceptionType, user, stackLineDetailObjectList, isIssueRegroup, actions, shareId, t
    } = this.props;
    const {
      obfuscationFileInfo,
    } = this.state;

    let {
      exceptionName, keyStack, stackType, isShowOtherThread, crashDoc,
      issueExceptionType,
      isShowingMinidumpStack,
    } = issue.toJS();

    let exceptionMessage = issue.get('exceptionMessage');
    let threadName = '';
    let retraceState = 0;
    let hasPcLinuxBreakpadStack = false;
    if (crashDoc && crashDoc.crashMap) {
      threadName = crashDoc.crashMap.threadName;
      exceptionName = crashDoc.crashMap.expName || exceptionName;
      exceptionMessage = crashDoc.crashMap.expMessage;
      retraceState = crashDoc.crashMap.retraceStatus;
      hasPcLinuxBreakpadStack = !!crashDoc.crashMap.linuxBreakpadStack;
    }

    const stackTraceTypeSwapSelect = this.renderStackTraceTypeSwapSelect();

    // 只有移动端、PC（仅崩溃）有其他线程
    const hasOtherThreadButton = !isIssueRegroup && (PlatformUtil.isMobileOrMobileLike(pid) || (isPcOrLinux(pid) && ExceptionCategoryUtil.isCrash(exceptionType)));

    let threadNameLabel = `${t('REPORTDETAIL.异常线程')}: `;
    const containerCode = (
       <div className={_style.data_container}>
        <div>
          <div className={`${_style.data_item}`}>
            <DataHead
              isMainThread={true}
              retraceLoading={this.state.retraceLoading}
              retraceStatusQueryResult={this.state.retraceStatusQueryResult}
              handleStackTypeClick={(e) => this.handleStackTypeClick(e)}
              stackTraceTypeSwapSelect={stackTraceTypeSwapSelect}
              style={_style}
              {...{
                exceptionName,
                keyStack,
                exceptionMessage,
                stackType,
                pid,
                threadName,
                issue,
                exceptionType,
                user,
                threadNameLabel,
                isIssueRegroup,
                obfuscationFileInfo,
                actions,
                shareId,
                symbolInfoLoading: this.state.symbolInfoLoading,
                isChildMethodExecuting: this.state.isChildMethodExecuting,
              }} />
              {this.state.aiAnalysisType &&
              <AiAnalysis
                ref={this.childRef}
                {...{
                  appId,
                  issue
                }}
              />}
            { isPcOrLinux(pid) && hasPcLinuxBreakpadStack && <div style={{ fontSize: '16px' }}>WinDbg Stack:</div> }
            <DataDetail
              {...{
                isShowingMinidumpStack,
                issue,
                appId,
                pid,
                exceptionType,
                dataType: 'stack',
                style: _style,
                stackLineDetailObjectList,
                obfuscationFileInfo,
                symbolInfoLoading: this.state.symbolInfoLoading,
                symbolInfoList: this.state.symbolInfoList,
                ignoreSymbolInfoList: this.state.ignoreSymbolInfoList,
              }}
            />
            { isPcOrLinux(pid) && hasPcLinuxBreakpadStack && <div style={{ fontSize: '16px' }}>Breakpad Stack:</div> }
            { isPcOrLinux(pid) && hasPcLinuxBreakpadStack && <DataDetail
              {...{
                issue,
                appId,
                pid,
                exceptionType,
                dataType: 'stack',
                style: _style,
                stackLineDetailObjectList,
                obfuscationFileInfo,
                pcStackSource: PcStackSourceEnum.LINUX_BREAKPAD,
              }}
            /> }
          </div>
        </div>
        <Spin spinning={this.state.isLoadingOtherThread}>
          {
          // 只有移动端、PC（仅崩溃）有其他线程
          hasOtherThreadButton && (
            <div className={_style.otherThreadButtonArea}>
              {isShowOtherThread
                ? <a className={classnames(_style.otherThreadButton, _style.expanded)} onClick={() => this.handleOtherThreadClick()}><PiCaretDoubleUpBold/><div>{ this.props.t("ERRORSTACKTAB.collapse") }</div></a>
                : <a className={classnames(_style.otherThreadButton, _style.collapsed)} onClick={() => this.handleOtherThreadClick()}><PiCaretDoubleDownBold/><div>{ this.props.t("ERRORSTACKTAB.expand") }</div></a>
              }
            </div>
          )
        }
          {isShowOtherThread && !ExceptionCategoryUtil.isError(exceptionType) ? this.renderOtherThread(issue, pid, threadName) : null}
          {isShowOtherThread && ExceptionCategoryUtil.isError(exceptionType) ? this.additionalStackInformation(issue, pid, threadName) : null}
        </Spin>
      </div>
    );

    return (
      <div>
        {containerCode}
      </div>
    )
  }

  /**
   * 渲染 其他信息, 控制台信息， anr其他信息
   */
  renderOtherMsg(dataType, supportStackTraceTypeRadioGroup) {
    const { issue, appId, pid, exceptionType } = this.props;
    return <div>
      <div style={{ display: 'flex', justifyContent: 'end' }}>{ this.renderStackTraceTypeSwapSelect() }</div>
      <div className={_style.data_container} style={{ marginTop: '12px' }}>
        <div className={`${_style.data_item} marginbottom20`}>
          <DataDetail
            {...{ issue, appId, pid, exceptionType, dataType, style: _style }}
          />
        </div>
      </div>
    </div>;
  }

  renderModuleList() {
    const { issue, appId, pid, exceptionType } = this.props;
    return <div>
      <div style={{ display: 'flex', justifyContent: 'end' }}>{ this.renderStackTraceTypeSwapSelect() }</div>
      <div className={_style.data_container} style={{ marginTop: '12px' }}>
        <div className={`${_style.data_item} marginbottom20`}>
          <ModuleList
            pid={pid}
            issue={issue}
          />
        </div>
      </div>
    </div>;
  }

  renderRegisterInfo() {
    const { issue, appId, pid, exceptionType } = this.props;
    return <div>
      <div style={{ display: 'flex', justifyContent: 'end' }}>{ this.renderStackTraceTypeSwapSelect() }</div>
      <div className={_style.data_container} style={{ marginTop: '12px' }}>
        <div className={`${_style.data_item} marginbottom20`}>
          <ErrorBoundary
            fallback={<div style={{ color: 'red', fontSize: '14px' }}>Error occurred during rendering register info.</div>}
          >
            <RegisterInfo
              pid={pid}
              issue={issue}
            />
          </ErrorBoundary>
        </div>
      </div>
    </div>;
  }

  /*
    渲染FD信息
  */
  renderFDTab() {
    const { issue, appId, pid, exceptionType } = this.props;
    return (
      <div className={_style.data_container}>
        <div className={`${_style.data_item} marginbottom20`}>
        <ErrorBoundary fallback={<div style={{ color: 'red',fontSize:'14px',textAlign:'center' }}>
          {ze('渲染出错，请联系管理员','Render error, please contact administrator.')}
        </div>}>
          <FDInformation
            {...{ issue, appId, pid, exceptionType }}
          />
        </ErrorBoundary>
        </div>
      </div>
    );
  }


  /**
   * 渲染符号表Tab的内容
   */
  renderSymbolTab() {
    const { style, issue, pid } = this.props;
    const { symbolInfoList, ignoreSymbolInfoList, symbolInfoLoading } = this.state;
    return <Spin spinning={symbolInfoLoading}><div style={{ position: 'relative' }}>
      <div style={{ position: 'absolute', right: 0, top: '-4px' }}>{ this.renderStackTraceTypeSwapSelect() }</div>
      <SymbolTable
        style={style}
        issue={issue}
        pid={pid}
        symbolInfo={symbolInfoList}
        ignoreSymbolInfo={ignoreSymbolInfoList}
        fetchSymbolInfoAndIgnoreSymbolInfo={() => this.cachedFetchSymbolInfoAndIgnoreSymbolInfo(true)}
        goSymbolTag={(...params) => this.handleChangeTab(...params)}
      />
    </div></Spin>;
  }

  /*
   渲染出错堆栈，跟踪数据，跟踪日志tab
   */

  renderDataTab() {
    const { issue, pid } = this.props;
    const showDivider = issue.get('tab2') && issue.get('tab2') !== 'LASTREPORT.errorStack';
    return <div style={{ padding: '0 8px' }}>
      { showDivider && <Divider style={{ margin: '16px 0' }}/> }
      { this.renderDataTabContent() }
    </div>;
  }

  renderDataTabContent() {
    const { issue, pid } = this.props;
    switch (issue.get('tab2')) {
      case 'LASTREPORT.附件下载':
        return this.renderCrashAttachmentShowcase();
      case 'LASTREPORT.trackData':
        return this.renderTrackDataTab();
      case 'LASTREPORT.trackLog':
        return this.renderTrackLogTab();
      case 'LASTREPORT.symbol':
        return this.renderSymbolTab();
      case 'LASTREPORT.anrMsg':
        return this.renderOtherMsg('anrMessage');
      case 'LASTREPORT.anrTrace':
        return this.renderOtherMsg('anrTrace');
      case 'LASTREPORT.崩溃线程寄存器信息': {
        return this.renderRegisterInfo();
      }
      case 'LASTREPORT.加载模块信息': {
        return this.renderModuleList();
      }
      case 'LASTREPORT.FD信息': {
        return this.renderFDTab();
      }
      case 'LASTREPORT.consoleInfo':
        return this.renderOtherMsg('consolelog');
      case 'LASTREPORT.memoryLog':
        return this.renderOtherMsg('meminfo');
      case 'LASTREPORT.otherInfo': {
        const dataType = parseInt(pid) === PLATFORM_ID.ANDROID ? 'crashInfos' : 'otherMsg';
        return this.renderOtherMsg(dataType);
      }
      case 'LASTREPORT.sameDeviceRelatedReports': {
        return this.renderSameDeviceRelatedReports();
      }
      case 'LASTREPORT.errorStack':
      default:
        return this.renderErrorStackTab();
    }
    return null;
  }

  getTagContentForLogType(pid, logType) {
    if (logType === 'userLog') {
      return 'PrintLog';
    } else if (isAndroid(pid)) {
      return 'Logcat';
    } else if (PlatformUtil.isIosOrMac(pid)) {
      return 'NSLog';
    } else {
      return ze('不支持', 'Not Available');
    }
  }

  /**
   * 渲染跟踪日志Tab的内容
   */
  renderTrackLogTab() {
    const { issue, appId, pid, exceptionType, t } = this.props;
    const { ideChecked, logLevel, logType } = issue.toJS();
    const requirePermissionLogOps = ['userLog'];
    const hasPermission = this.hasViewPersonalDataPermission();
    const hasNoSystemLog = !PlatformUtil.isSystemLogEnabled(pid);
    const hasLogLevel = PlatformUtil.isLogLevelEnabled(pid, logType);
    // 不支持System Log的平台也显示这个tab，只是增加文案提示
    const filterLogOps = logOps.map(({ label, value }) => {
      const disabled = !hasPermission && requirePermissionLogOps.includes(value);
      const tagContent = this.getTagContentForLogType(pid, value);

      let labelDom;
      if (disabled) {
        labelDom = `${label} (${t('common.无权限')})`;
      } else {
        labelDom = <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <div>{ label }</div>
          { tagContent && <Tag color='geekblue'>{ tagContent }</Tag> }
        </div>
      }

      return {
        label: labelDom,
        value,
        disabled,
      };
    });
    return (
      <div className={_style.data_container}>
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <Select
            style={{ width: '200px' }}
            options={filterLogOps}
            value={logType}
            onChange={(v) => this.handleLogTypeSelectChange(v)}
          />
          { hasLogLevel && <Select
            style={{ width: '200px' }}
            options={isPcOrLinux(pid) ? levelOpsPc : levelOps}
            value={logLevel}
            onChange={(v) => this.handleLogLevelSelectChange(v)}
          /> }
          <Input
            style={{ width: '400px' }}
            prefix={<SearchIcon />}
            onChange={(e) => this.handleKeywordChange(e.target.value)}
          />
          {this.getIsEnablePcLogTrace() && <Button
            onClick={() => this.handleSymbolicateClick()}
          >
            {ze('还原', 'Symbolicate')}
          </Button>}
          <div style={{ flex: 1 }}/>
          <CsDownloadButton
            onClick={() => this.handleDownloadClick(issue,hasNoSystemLog,pid)}
          />
        </div>
        <div className={_style.data_item}>
          <DataDetail
            {...{
              issue,
              appId,
              pid,
              exceptionType,
              dataType: 'log',
              style: _style,
              logType,
            }}
          />
        </div>
      </div>
    );
  }

  renderCrashAttachmentShowcase() {
    return <div className={_style.data_container}>
      <CrashAttachmentShowcase
        hasSdkLogFile={this.state.hasSdkLogFile}
        hasSdkAttachFile={this.state.hasSdkAttachFile}
        onClickDownloadSdkLogFile={(filename) => this.downloadSdkLog(filename)}
        onClickDownloadSdkAttachFile={(filename) => this.downloadSdkAttach(filename)}
      />
    </div>;
  }

  /**
   * 渲染跟踪数据Tab的内容
   */
  renderTrackDataTab() {
    const {
      issue, appId, pid, style, exceptionType,
      t,
      shareId,
      shareLinkEquivalentUserPermissions,
      reduxState,
    } = this.props;
    const { crashDoc } = issue.toJS();
    const crashMap = (crashDoc || {}).crashMap || {};
    const { deviceId, bucketPath } = crashMap;
    const mobile = PlatformUtil.isMobileOrMobileLike(pid);
    const contents = this.getPageTraceContent();

    return (
      <div>
        { !isGameConsole(pid) && <div className={_style.data_container}>
          <p className={style.subtitle}>{ this.props.t("TRACKDATATAB.baseData") }</p>
          <div className={_style.data_item}>
            <DataTable
              style={_style}
              {...{ appId, pid, issue, exceptionType, shareId, shareLinkEquivalentUserPermissions }}
            />
          </div>
        </div> }

        <div className={_style.data_container}>
          <div className={style.subtitle}><Space>
            <div>{ t("TRACKDATATAB.customData") }</div>
          </Space></div>
        </div>


        <div className={_style.data_container}>
        <p className={style.subtitle}>{ '' }</p>
          <div className={_style.data_item}>

            <UserCustomDataTable issue={issue} pid={pid} style={_style} />
          </div>
        </div>

        { mobile && contents && (
          <div className={_style.data_container}>
            <p className={style.subtitle}>{ this.props.t("TRACKDATATAB.pageTrack") }</p>

            <div className={_style.data_item}>
              <PageTrace contents={contents} />
            </div>
          </div>
        )}
      </div>
    );
  }

  /*
    出错堆栈展示额外堆栈信息
  */
  additionalStackInformation(){
    const { pid, style, issue } = this.props;
    const iosExtraStackThreads = this.getIosExtraStackThreads() || [];
    return ( <div style={{margin:'10px 0'}}>
      <p className={style.subtitle}>{ this.props.t("TRACKDATATAB.extraStack") }</p>
      <div className={_style.data_item}>
        <Collapse>{
          iosExtraStackThreads.map(({ name, isPresent, stackLineDetailObject }, i) => {
            const panelHeader = `Thread # ${name}`;
            if (!isPresent) {
              return <Collapse.Panel key={i} header={panelHeader}>
                <div>Attachment is empty.</div>
              </Collapse.Panel>;
            }
            return <Collapse.Panel key={i} header={panelHeader}>
              <DataDetail
                issue={issue}
                pid={pid}
                dataType='stack'
                style={_style}
                exceptionType='crash'
                isRetracedStack={false}
                stackLineDetailObjectList={stackLineDetailObject}
              />
            </Collapse.Panel>;
          })
        }</Collapse>
      </div>
    </div>)
  }

  /*
   渲染其他线程
   */
  renderOtherThread(currentIssue, pid, reportThreadName) {
    const {
      user,
      isIssueRegroup,
      actions,
      shareId,
      exceptionType,
      t,
    } = this.props;
    const { otherThread, minidumpStackInfo, isShowingMinidumpStack } = currentIssue.toJS();
    const { minidumpOtherThreadInfoList } = minidumpStackInfo || {};
    const currentShowingThreadInfoList = isShowingMinidumpStack
      ? minidumpOtherThreadInfoList
      : otherThread;

    let threads = [];
    if (Array.isArray(currentShowingThreadInfoList)) {
      threads = currentShowingThreadInfoList.map((thread, index) => {
        const isCollapsed = !!this.state.otherThreadIndexToIsCollapsed[index];
        let threadNameLabel = `${t('REPORTDETAIL.线程名')}:`
        const thisThreadName = thread.fileName;
        // 过滤掉和异常线程名字一样的其他线程。 iOS有bug，异常线程名字后面多了空格所以trim掉
        if (reportThreadName && thisThreadName && String(reportThreadName).trim() === String(thisThreadName).trim()) {
          return null;
        }

        return (<div>
          <div className={`${_style.data_item} marginbottom20 ${isCollapsed ? 'unactive' : ''}`} key={`otherStack-${index}`}>
            <DataHead
              isMainThread={false}
              isCollapsed={isCollapsed}
              style={_style}
              threadName={thisThreadName}
              threadNameLabel={threadNameLabel}
              onClick={(e) => this.handleToggleOtherThreadItem(index)}
              pid={pid}
              stackContent={thread.content}
              isIssueRegroup={isIssueRegroup}
              actions={actions}
              user={user}
              exceptionType={exceptionType}
              shareId={shareId}
              handleStackTypeClick={(e) => this.handleStackTypeClick(e)}
              hideReretraceButton={index > 0}
            />
            <StackDetail
              issue={currentIssue}
              stackContent={thread.content}
              isNeedHighLight
              pid={pid}
              style={_style} />
          </div>
        </div>);
      }).filter(x => x);
    }
    return <div>
      { threads.length > 0 && <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        <div>{ ze('按指定堆栈关键字折叠线程：', 'Collapse threads with stack keyword: ') }</div>
        <Select
          style={{ width: '160px' }}
          value={this.state.otherThreadFilterType}
          options={OtherThreadFilterTypeOptions}
          onChange={v => this.setState({ otherThreadFilterType: v })}
        />
        <Input
          style={{ width: '240px' }}
          value={this.state.otherThreadFilterInputValue}
          onChange={e => this.setState({ otherThreadFilterInputValue: e.target.value })}
        />
        <Button
          onClick={() => this.onClickCollapseOtherThreadsByFilter(currentIssue)}
        >{ ze('执行', 'Run') }</Button>
        <Button
          onClick={() => this.onClickExpandAllOtherThreads()}
        >{ ze('展开全部线程', 'Expand All Threads') }</Button>
      </div> }
      { threads }
    </div>;
  }

  onClickCollapseOtherThreadsByFilter(currentIssue) {
    const keyword = (this.state.otherThreadFilterInputValue || '').trim();
    if (!keyword) {
      return;
    }

    const { otherThread, minidumpStackInfo, isShowingMinidumpStack } = currentIssue.toJS();
    const { minidumpOtherThreadInfoList } = minidumpStackInfo || {};
    const currentShowingThreadInfoList = isShowingMinidumpStack
      ? minidumpOtherThreadInfoList
      : otherThread;

    const newIndexToIsCollapsed = {};

    if (this.state.otherThreadFilterType === OTHER_THREAD_FILTER_TYPE_CONTAINS) {
      const lowerKeyword = keyword.toLowerCase();
      currentShowingThreadInfoList.forEach((thread, index) => {
        newIndexToIsCollapsed[index] = (thread.content || '').toLowerCase().includes(lowerKeyword);
      });
    } else if (this.state.otherThreadFilterType === OTHER_THREAD_FILTER_TYPE_REGEX) {
      try {
        const regexp = new RegExp(keyword, 'i');
        currentShowingThreadInfoList.forEach((thread, index) => {
          newIndexToIsCollapsed[index] = regexp.test(thread.content || '');
        });
      } catch (e) {
        message.error(ze('无效的正则表达式。', 'Invalid regexp.'));
        return;
      }
    }

    this.setState({
      otherThreadIndexToIsCollapsed: {
        ...this.state.otherThreadIndexToIsCollapsed,
        ...newIndexToIsCollapsed,
      },
    });
  }

  onClickExpandAllOtherThreads() {
    this.setState({
      otherThreadIndexToIsCollapsed: {},
    });
  }

  makeNoPermissionTabLabel(labelText) {
    const { t } = this.props;
    return <div>
      <Tooltip
        title={<div>
          <div>{t('common.当前用户角色缺少以下权限')}</div>
          <div>VIEW_PERSONAL_DATA</div>
        </div>}
      >{ labelText }<WrappedTipsIcon /></Tooltip></div>;
  }

  renderTabs(appId, pid, exceptionType, issue, crashDoc) {
    const { isIssueRegroup, t, reduxState } = this.props;
    const accountId = reduxState.app.get('current').get('accountId');
    const isNetmarble = accountId === '10050690181558001'; // 临时对netmarble账户下的所有项目，禁止FD信息tab

    const crashMap = (crashDoc || {}).crashMap || {};
    const { deviceId, bucketPath } = crashMap;
    const esMap = (crashDoc || {}).esMap || {};
    let { moduleInfoCosKey, registerInfoCosKey, otherBreakpadCosKey } = esMap;

    const attachName = crashDoc && crashDoc.detailMap && crashDoc.detailMap.attachName;

    const isCategorySupportModuleTab = ExceptionCategoryUtil.isCrash(exceptionType)
      || (ExceptionCategoryUtil.isError(exceptionType) && this.getIsEnableErrorRetrace());

    let tabs = [{
      condition: true,
      value: 'LASTREPORT.errorStack',
    }, {
      condition: PlatformUtil.hasCrashAttachment(pid, exceptionType, issue) || isPcOrLinux(pid),
      value: 'LASTREPORT.trackData',
    }, {
      condition: !isGameConsole(pid),
      value: 'LASTREPORT.trackLog',
    }, {
      condition: !isSwitch(pid),
      value: 'LASTREPORT.附件下载',
    }, {
      condition: !this.getIsFromShareLink() && !isSwitch(pid),
      value: 'LASTREPORT.symbol',
    }, {
      condition: ExceptionCategoryUtil.unify(exceptionType) === ExceptionCategory.CRASH
        && (PlatformUtil.isMobileOrMobileLike(pid) || !!registerInfoCosKey),
      value: 'LASTREPORT.崩溃线程寄存器信息',
    }, {
      condition: isCategorySupportModuleTab
        && (PlatformUtil.isMobileOrMobileLike(pid) || !!moduleInfoCosKey),
      value: 'LASTREPORT.加载模块信息',
    }, {
      condition: !isNetmarble && isAndroidOrHarmony(pid) && (exceptionType === 'crash' || exceptionType === 'crashes'),
      value: 'LASTREPORT.FD信息',
    }, {
      condition: exceptionType === 'anr' || exceptionType === 'blocks',
      value: 'LASTREPORT.anrMsg',
    }, {
      condition: exceptionType === 'anr' || exceptionType === 'blocks',
      value: 'LASTREPORT.anrTrace',
    }, {
      condition: !this.getIsFromShareLink(),
      value: 'LASTREPORT.sameDeviceRelatedReports',
    }].filter(x => x.condition);

    const requirePermissionTabs = ['LASTREPORT.trackData'];
    const hasPermission = this.hasViewPersonalDataPermission();
    const tabDetailList = tabs.map(x => ({
      key: x.value,
      noPermission: !hasPermission && requirePermissionTabs.includes(x),
    }));
    return (
      tabDetailList.length > 0 && <CsTabs
        bigSize={true}
        activeKey={issue.get('tab2') || tabDetailList[0].key}
        onChange={key => this.handleChangeTab(key)}
      >{
        tabDetailList.map(({ key, noPermission }) => <Tabs.TabPane
          tab={noPermission
            ? this.makeNoPermissionTabLabel(t(key))
            : t(key)
          }
          disabled={noPermission}
          key={key}
        />)
      }</CsTabs>
    );
  }

  renderSameDeviceRelatedReports() {
    const {
      issue, appId, pid, issueId, exceptionType, isReport, isIssueRegroup, style, actions, path, crashDataType, t,
    } = this.props;

    const { crashDoc } = issue ? issue.toJS() : {};
    const crashMap = (crashDoc || {}).crashMap || {};
    const { deviceId, userId, crashId } = crashMap;

    return <div className={_style.data_container} style={{ fontSize: '14px' }}><SameDeviceRelatedReports
      currentReportIssueId={issueId}
      currentReportCrashId={crashId}
      currentReportUserId={userId}
      currentReportDeviceId={deviceId}
      currentReportExceptionType={exceptionType}
    /></div>;
  }

  hasViewPersonalDataPermission() {
    const { getHasSpecifiedPermission, shareLinkEquivalentUserPermissions } = this.props;
    return getHasSpecifiedPermission('VIEW_PERSONAL_DATA')
      || (this.getIsFromShareLink() && (shareLinkEquivalentUserPermissions || []).includes('VIEW_PERSONAL_DATA'));
  }

  hasAdminPermission() {
    const { getHasSpecifiedPermission, shareLinkEquivalentUserPermissions } = this.props;
    return getHasSpecifiedPermission('EDIT')
      || (this.getIsFromShareLink() && (shareLinkEquivalentUserPermissions || []).includes('EDIT'));
  }

  renderGenerateShareLinkModal() {
    const { t } = this.props;
    const {
      isGenerateShareLinkModalVisible,
      shareLinkExpireInDays,
      shareLinkUserRole,
    } = this.state;
    const expireInDaysRadioOptions = [3, 7, 15].map(x => <Radio value={x} key={x}>{ t('timePeriod.天', { day: x }) }</Radio>);
    const userRoleOptions = [
      { value: UserTypeEnum.STATS_VIEWER, label: t('LASTREPORT.与role一致', { role: t('MEMBERMANAGE.statsViewer') }) },
      this.hasViewPersonalDataPermission() && { value: UserTypeEnum.MEMBER, label: t('LASTREPORT.与role一致', { role: t('MEMBERMANAGE.normal') }) },
      !this.hasViewPersonalDataPermission() && { value: UserTypeEnum.COMPLIANCE_MEMBER, label: t('LASTREPORT.与role一致', { role: t('MEMBERMANAGE.complianceMember') }) },
    ].filter(x => x)
      .map(x => <Radio value={x.value} key={x.value}>{ x.label }</Radio>);

    return <Modal
      title={ t('LASTREPORT.生成匿名访问链接') }
      width={800}
      visible={isGenerateShareLinkModalVisible}
      onCancel={() => {
        this.setState({ isGenerateShareLinkModalVisible: false });
      }}
      okText={ t('LASTREPORT.生成匿名访问链接') }
      onOk={() => {
        this.setState({ isGenerateShareLinkModalVisible: false });
        this.generateReportShareLink();
        reportEvent({
          action: EVENT_ACTIONS.CLICK,
          tp1: '生成匿名链接',
        });
      }}
    >
      <div>{ t('LASTREPORT.generateShareLinkDesc') }</div>
      <Row style={{ marginTop: '24px' }} gutter={24}>
        <Col>{ t('LASTREPORT.链接有效期') }</Col>
        <Col>
          <Radio.Group
            value={shareLinkExpireInDays}
            onChange={e => this.setState({ shareLinkExpireInDays: e.target.value })}
          >
            { expireInDaysRadioOptions }
          </Radio.Group>
        </Col>
      </Row>
      {/*<Row style={{ marginTop: '24px' }} gutter={24}>
        <Col>
          <span>{ t('LASTREPORT.可查看信息范围') }</span>
          <span style={{ marginLeft: '8px' }}><Tooltip title={ t('LASTREPORT.可查看信息范围desc') }><InfoCircleOutlined style={{ color: '#999' }}/></Tooltip></span>
        </Col>
        <Col>
          <Radio.Group
            value={shareLinkUserRole}
            onChange={e => this.setState({ shareLinkUserRole: e.target.value })}
          >
            { userRoleOptions }
          </Radio.Group>
        </Col>
      </Row>*/}
    </Modal>;
  }

  renderIssueSendReportToWorkWeixinModal() {
    const { issue } = this.props;
    const { crashDoc } = issue ? issue.toJS() : {};

    return <IssueSendReportToWorkWeixinModal
      visible={this.state.isIssueSendReportToWorkWeixinModalVisible}
      onClose={() => this.setState({ isIssueSendReportToWorkWeixinModalVisible: false })}
      crashDoc={crashDoc}
    />;
  }

  renderSetQuickViewCustomKeyModal() {
    const { issue: currentIssue, appId, actions } = this.props;

    const options = (currentIssue.get('attachCustomKvList') || [])
      .map(x => ({ label: x.keyWithType, value: x.keyWithType }))
      .sort((a, b) => (a.value || '').localeCompare(b.value || ''));

    return <Modal
      title={ze('设置快捷展示字段', 'Set Quick View Fields')}
      visible={this.state.isSetQuickViewCustomKeyModalVisible}
      maskClosable={false}
      onCancel={() => this.setState({ isSetQuickViewCustomKeyModalVisible: false })}
      onOk={() => {
        this.setState({ isSetQuickViewCustomKeyModalVisible: false });
        actions.upsertWebAppSettings(appId, {
          [WebAppSettings.keys.QuickViewCustomKeyList]: this.state.editingQuickViewCustomKeyList,
        });
      }}
    >
      <div>
        <div>{ ze('设置了快捷展示的自定义字段将会直接展示在上报详情区域，无需进入跟踪数据查看。', 'Custom fields that are set up for quick view will be displayed directly in the report details area without having to go into the tracking data to view them.') }</div>
        <Divider/>
        <Select
          style={{ width: '100%' }}
          mode='multiple'
          options={options}
          value={this.state.editingQuickViewCustomKeyList}
          onChange={v => { this.setState({ editingQuickViewCustomKeyList: v }); }}
        />
      </div>
    </Modal>;
  }

  renderLogSymbolicateModal() {
    if (this.state.LogSymbolicateModalVisible) {
      return <StackSymbolicateModal
        modalProps={{
          visible: this.state.LogSymbolicateModalVisible,
          onOk: () => { this.setState({ LogSymbolicateModalVisible: false }) },
          onCancel: () => { this.setState({ LogSymbolicateModalVisible: false }) },
        }}
      />
    }
  }

  render() {
    if (this.state.componentHasError) {
      return <div>组件渲染时发生错误，请联系管理员</div>;
    }

    const {
      issue, appId, pid, issueId, exceptionType, isReport, isIssueRegroup, style, actions, path, crashDataType, t,
      shareId,
      shareLinkEquivalentUserPermissions,
    } = this.props;

    const isReportDetailCollapsed = this.state.isReportDetailCollapsed;

    const { crashDoc } = issue && issue.toJS();
    const { crashId } = crashDoc && crashDoc.crashMap || {};
    return (
      <div
        style={{ padding: '16px 24px' }}
        className={`${_style.issue_container} ${style.issue_container}`}
      >
        {
          this.renderTop(exceptionType, appId, pid, issueId, crashId, crashDataType)
        }
        { !isReportDetailCollapsed && <>
          <Divider style={{ margin: '14px 0 24px' }} />
          <ReportDetail
            style={{ padding: '0 16px' }}
            {...{
              appId, issue, pid, actions, path, exceptionType,
              shareId,
              shareLinkEquivalentUserPermissions,
            }} />

          { crashId && <Divider style={{ margin: '16px 0 ' }} /> }

          {
            crashId && this.renderTabs(appId, pid, exceptionType, issue, crashDoc)
          }

          {
            crashId && this.renderDataTab()
          }
          { this.renderGenerateShareLinkModal() }
          { this.renderIssueSendReportToWorkWeixinModal() }
          { this.renderSetQuickViewCustomKeyModal() }
          { this.renderLogSymbolicateModal() }
        </> }
      </div>
    );
  }
}

LastReport.propTypes = {
  style: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  issue: PropTypes.object,
  actions: PropTypes.object,
  crashHash: PropTypes.string,
  issueId: PropTypes.string,
  user: PropTypes.object,
  pid: PropTypes.string,
  appId: PropTypes.string,
  exceptionType: PropTypes.string,
  path: PropTypes.string,
  changeLog: PropTypes.func,
  changeLevel: PropTypes.func,
  clickCheckbox: PropTypes.func,
  changeLogKey: PropTypes.func,
  closeCrashItem: PropTypes.func,
  isReport: PropTypes.bool,  // true: 上报记录列表查看某个上报的界面  false: 问题详情下的最近一次上报/指定id上报界面
  // 表示是否是堆栈重分类界面嵌入的lastReport，这个界面嵌入的lastReport有些功能是不提供的，同时额外提供指定堆栈功能
  isIssueRegroup: PropTypes.bool,
  // 如果传了stackLineDetailObjectList，那么就直接用这个作为堆栈信息，不去从issue取数据了
  // 这个prop是给堆栈重分类用的
  stackLineDetailObjectList: PropTypes.array,
  getHasSpecifiedPermission: PropTypes.func,
  isLastReport: PropTypes.bool,
  shareId: PropTypes.string,  // 分享链接的id，如果不为空表示是通过分享链接查看的
  shareLinkEquivalentUserPermissions: PropTypes.array, // 分享链接的相关查看权限
};
