export const WebAppSettings = Object.freeze({
  keys: Object.freeze({
    /** @deprecated CS 1.0配置tapd提单的时候显示的自定义字段，已废弃。2.0用ServerAppSetting的tapdFieldList代替 */
    tapdEnabledBugCustomFields: 'tapdEnabledBugCustomFields',
    tapdBugTitleTemplate: 'tapdBugTitleTemplate',
    QuickViewCustomKeyList: 'QuickViewCustomKeyList',
    userIdDescriptionConfigCsvText: 'userIdDescriptionConfigCsvText',
  }),

  getQuickViewCustomKeyList(settings) {
    return (settings || {})[this.keys.QuickViewCustomKeyList]
      || [];
  },
});
