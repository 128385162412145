import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  FORM_ERROR_MSG,
} from 'utils/constants';
import { isEmptyObject } from 'utils/helper';
import { checkNickName } from 'utils/Validate';
import style from './style.scss';
import { withTranslation } from 'react-i18next';
import { CopyOutlined } from '@ant-design/icons';
import CopyreportIcon from 'svg/v2/newcs_dashboard_crashanalysis_issuedetail_copyreport_icon_normal.svg';
import BasicinfoIcon from 'svg/v2/newcs_dashboard_personalinfo_basicinfo_icon.svg';
import ConfigurationIconNormal from 'svg/v2/newcs_dashboard_left_configuration_icon_normal.svg';
import { message, Divider } from 'antd';
import { ze, isZh } from 'utils/zhEn';
import Cookies from 'js-cookie';
import { CsSelect } from 'components/antd-extension/CsSelect';
import LaugugeCh from 'svg/v2/newcs_dashboard_lauguge_ch.svg';
import LaugugeEn from 'svg/v2/newcs_dashboard_lauguge_en.svg';
import { CS_STYLES } from 'utils/constants/style-constants';
import {ThemeColor, ThemeColorUtil} from 'utils/theme-color-util';

@withTranslation()
export default class UserInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nickNameIsOk: true,
      isSaveSucc: false,
      languageSetValue: null,
      themeColorValue: null,
    };
    this.handleSaveClick = this.handleSaveClick.bind(this);
  }

  componentDidMount() {
    const { actions, currentUser, userParams } = this.props;
    const { userPreferences } = !isEmptyObject(userParams) ? userParams.toJS() : {};
    const tmpCurrentUser = currentUser;
    if (!tmpCurrentUser.nickname) {
      if (tmpCurrentUser.qqNickName) {
        tmpCurrentUser.nickname = tmpCurrentUser.qqNickName;
      } else {
        tmpCurrentUser.nickname = tmpCurrentUser.userId;
      }
    }
    actions.resetUserParam(tmpCurrentUser);
    this.setState({
      languageSetValue: isZh() ? 'zh' : 'en',
      themeColorValue: (userPreferences || {}).themeColor || ThemeColor.ORANGE,
    });
  }

  everyThingIsOk() {
    return this.state.nickNameIsOk;
  }

  // 验证各项内容是否合法
  validate(name, str) {
    if (name === 'nickname') { // 昵称不能为空
      this.setState({
        nickNameIsOk: checkNickName(str),
      });
    }
  }

  // onchange事件
  handleOnChange(itemName) {
    const that = this;
    return (event) => {
      const { actions } = that.props;
      actions.changeUserParamOptions({ name: itemName, value: event.target.value });
      that.validate(itemName, event.target.value);
      actions.isUpdateFlag(false);
    };
  }

  async copyToClipboard(text) {
    try {
      await navigator.clipboard.writeText(text);
    } catch (e) {
      console.log('error',e)
    }
  }

  // 提交保存
  handleSaveClick() {
    const { actions } = this.props;
    const { languageSetValue, themeColorValue } = this.state;
    Cookies.set('languageSet', languageSetValue);
    ThemeColorUtil.setThemeColor(themeColorValue);
    actions.changeUserParamOptions({ name: 'userPreferences', value: {themeColor: themeColorValue} })
    this.everyThingIsOk() && actions.updateUserInfo().then(() => {this.setState({ isSaveSucc: true },window.location.reload())});
  }

  render() {
    const { userParams, currentUser, actions } = this.props;

    const {
      nickname,
      userId,
      newUserId,
      userPreferences,
    } = !isEmptyObject(userParams) ? userParams.toJS() : {};

    const isSuperAdmin = currentUser.isSuper;

    const colorSelectOptions = [
      {
        value:ThemeColor.ORANGE,
        label:<div style={{display: 'flex', alignItems: 'center', gap: 4}}>
          <div className={style.themeColorOrangeOptionIcon} />
          <div>{ze('橙色','Orange')}</div>
        </div>
      },{
        value:ThemeColor.BLUE,
        label:<div style={{display: 'flex', alignItems: 'center', gap: 4}}>
          <div className={style.themeColorBlueOptionIcon} />
          <div>{ze('蓝色','Blue')}</div>
        </div>
      },
    ];

    const langSelectOptions = [
      {
        value: 'zh',
        label: <div style={{display: 'flex', alignItems: 'center', gap: 4}}>
          <LaugugeCh />
          <div>{'中文'}</div>
        </div>,
      },{
        value: 'en',
        label: <div style={{display: 'flex', alignItems: 'center', gap: 4}}>
        <LaugugeEn />
        <div>{'English'}</div>
      </div>, },
    ];
    return (
      <div className={style.userInfo_box}>
        <div className={style.userInfo_list}>
          <div className={style.sub_title}>
            <BasicinfoIcon width="14" height="14" viewBox="0 0 14 14" />
            <div>{ this.props.t('ACCOUNT.baseInfo') }</div>
          </div>
          <ul className={style.sub_body}>

            <li className={this.state.nickNameIsOk ? ' ' : style.error}>
              <div className={style.list_item_name}>
                { this.props.t('ACCOUNT.nickName') }
                <strong className={style.need_ico}>*</strong>
              </div>
              <div className={style.list_item_content}>
                <input
                  type="text"
                  className="inputMod"
                  value={userParams.get('nickname') || nickname}
                  onChange={this.handleOnChange('nickname')} />
                { !this.state.nickNameIsOk
                  && <span className={style.error_info}>{FORM_ERROR_MSG.nickname_info}</span>}
              </div>
            </li>
            <li>
              <div className={style.list_item_name}>{this.props.t('ACCOUNT.userId')}</div>
              <div className={style.list_item_content}>
                {newUserId}
                <CopyreportIcon style={{marginLeft:'4px',cursor: 'pointer'}} onClick={() => this.copyToClipboard(newUserId)}/>
              </div>
            </li>
            {!!isSuperAdmin && <li>
              <div className={style.list_item_name}>{this.props.t('ACCOUNT.wetestUserId')}</div>
              <div className={style.list_item_content}>
                {userId}
                <CopyreportIcon style={{marginLeft:'4px',cursor: 'pointer'}} onClick={() => this.copyToClipboard(userId)}/>
              </div>
            </li>}
          </ul>
          <Divider />
          <div className={style.sub_title}>
            <ConfigurationIconNormal width="14" height="14" viewBox="0 0 20 20" />
            <div>{ ze('设置','Settings') }</div>
          </div>
          <ul>
            <li>
              <div className={style.list_item_name}>{ze('主题色','Theme Color')}</div>
              <div className={style.list_item_content} style={{ gap:'10px' }}>
                <CsSelect
                  style={{width:'160px'}}
                  options={colorSelectOptions}
                  value={this.state.themeColorValue}
                  onChange= {(v) => {
                    this.setState({ themeColorValue: v })
                  }}
                />
              </div>
            </li>
            <li>
              <div className={style.list_item_name}>{ze('语言设置','Language')}</div>
              <div className={style.list_item_content}>
                <CsSelect
                  style={{width:'160px'}}
                  value={this.state.languageSetValue}
                  options={langSelectOptions}
                  onChange={(v) => {
                    this.setState({ languageSetValue: v })
                  }}
                />
              </div>
            </li>
          </ul>
          <div className={`btn ${this.everyThingIsOk() ? 'btn_blue' : 'btn_gray'}`} onClick={this.handleSaveClick}>{ this.props.t('ACCOUNT.save') }</div>
          {this.state.isSaveSucc && <span className={style.save_succ}>{ this.props.t('ACCOUNT.saveSuccess') }</span>}
        </div>
      </div>
    );
  }
}

UserInfo.propTypes = {
  currentUser: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  userParams: PropTypes.object.isRequired,
};
