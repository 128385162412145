import { Map, List } from 'immutable';
import { DEFAULT_EXCEPTION_TYPE_LIST } from 'utils/constants';
import i18n from 'i18n.js';

const initialSelectOptions = new Map({
  version: {
    options: [
      { label: i18n.t('versionOptions.全版本'), value: 'all' },
    ],
  },
  date: {
    options: [
      { label: '最近1小时', value: 'last_1_hour' },
      { label: '最近一天', value: 'last_1_day' },
      { label: '最近2天', value: 'last_2_day' },
      { label: '最近7天', value: 'last_7_day' },
      { label: '最近2周', value: 'last_2_week' },
      { label: '最近1月', value: 'last_1_month' },
    ],
  },
  exceptionTypeList: {
    options: [
      { label: '所有类型', value: DEFAULT_EXCEPTION_TYPE_LIST },
      { label: 'Java崩溃', value: 'JavaCrash' },
      { label: 'Native崩溃', value: 'Native' },
      { label: 'App崩溃', value: 'IosCrash' },
      { label: 'Extension崩溃', value: 'ExtensionCrash' },
    ],
  },
  status: {
    options: [
      { label: i18n.t('statusOptions.所有状态'), value: 'all' },
      { label: i18n.t('statusOptions.未处理'), value: '0' },
      { label: i18n.t('statusOptions.已处理'), value: '1' },
      { label: i18n.t('statusOptions.处理中'), value: '2' },
    ],
  },
  processor: {
    options: [],
  },
  tagList: {
    options: [],
  },
  bundleIdList: {
    options: [],
  },
  channelIdList: {
    options: [],
  },
  channelList: {
    options: [],
  },
});

export const initialSearchParams = new Map({
  sortField: 'uploadTime',
  sortOrder: 'desc', // asc
  start: 0,
  version: 'all',
  date: 'all',
  status: '',
  processor: '',
  tagList: '',
  search: '', // 搜索的填写的内容
  exceptionTypeList: '',
  exceptionCategoryList: '',  // 逗号分隔的内容
});

export const initialState = new Map({
  selectOptions: initialSelectOptions,
  searchParams: initialSearchParams,
  loading: true,
  issueList: new List(),
  numFound: 0,
  queryInaccurateReason: null,
  current: new Map(),
  issueListStatusCode: 0,
  issueListTrendLoading: false,
  issueIdToDailyTrendList: {},
  issueIdToHourlyTrendList: {},
  issueIdToDailyTrendListLastFetchMillis: {},
  issueIdToHourlyTrendListLastFetchMillis: {},
  expandedCrashListTableRowKey: null,
});
