import React, { useState, useEffect, useMemo, useContext } from 'react';
import { Button, DatePicker, Input, InputNumber, Select, Space } from 'antd';
import { isNullish } from 'utils/nullish';
import { QueryType } from 'components/commons/IssueCrashFilter/IssueCrashFilterExUtil';
import { ze } from 'utils/zhEn';
import moment from 'moment';
import { SwapOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { selectCurrentAppDatePickerAvailableMomentRange } from 'utils/selectors/selectors';

const QUERY_TYPE_OPTIONS = [
  { label: ze('相对时间', 'Relative'), value: QueryType.RANGE_RELATIVE_DATETIME },
  { label: ze('绝对时间', 'Absolute'), value: QueryType.RANGE },
];

const QUERY_TYPE_VALUE_TO_LABEL = Object.assign(Object.create(null), ...QUERY_TYPE_OPTIONS.map(x => ({ [x.value]: x.label })));

const AbsoluteAndRelativeDateRange = ({
  value,
  onChange = () => {},
  isReadOnly,
}) => {
  const {
    queryType,
    gte,
    lte,
  } = value || {};

  const [
    datePickerMinAvailableMoment,
    datePickerMaxAvailableMoment
  ] = useSelector(selectCurrentAppDatePickerAvailableMomentRange);

  const queryTypeWithDefault = queryType || QueryType.RANGE_RELATIVE_DATETIME;

  return <div style={{ display: 'flex' }}>
    <Button
      style={{ flexShrink: 0, width: '120px', paddingLeft: '12px', paddingRight: '10px' }}
      onClick={() => {
        if (isReadOnly) {
          return;
        }
        const v = queryTypeWithDefault === QueryType.RANGE
          ? QueryType.RANGE_RELATIVE_DATETIME
          : QueryType.RANGE;
        onChange({ queryType: v });
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div>{ QUERY_TYPE_VALUE_TO_LABEL[queryTypeWithDefault] || queryTypeWithDefault }</div>
        <SwapOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }}/>
      </div>
    </Button>
    { queryTypeWithDefault === QueryType.RANGE_RELATIVE_DATETIME && <Select
      style={{ flexGrow: 1 }}
      listHeight={400}
      allowClear={true}
      options={[
        { label: ze('最近1小时', 'Last 1 Hour'), value: 3600000 },
        { label: ze('最近3小时', 'Last 3 Hour'), value: 3600000 * 3 },
        { label: ze('最近12小时', 'Last 12 Hour'), value: 3600000 * 12 },
        { label: ze('最近1天', 'Last 1 Day'), value: 3600000 * 24 },
        { label: ze('最近2天', 'Last 2 Days'), value: 3600000 * 24 * 2 },
        { label: ze('最近3天', 'Last 3 Days'), value: 3600000 * 24 * 3 },
        { label: ze('最近7天', 'Last 7 Days'), value: 3600000 * 24 * 7 },
        { label: ze('最近15天', 'Last 15 Days'), value: 3600000 * 24 * 15 },
        { label: ze('最近30天', 'Last 30 Days'), value: 3600000 * 24 * 30 },
        { label: ze('最近90天', 'Last 90 Days'), value: 3600000 * 24 * 90 },
      ]}
      value={ gte }
      onChange={(v) => {
        if (isReadOnly) {
          return;
        }
        onChange({ queryType: QueryType.RANGE_RELATIVE_DATETIME, gte: v });
      }}
    /> }
    { queryTypeWithDefault === QueryType.RANGE && <DatePicker.RangePicker
      style={{ flexGrow: 1 }}
      disabledDate={moment => moment && (moment.isBefore(datePickerMinAvailableMoment) || moment.isAfter(datePickerMaxAvailableMoment))}
      allowEmpty={[true, true]}
      showTime
      value={[gte ? moment(gte) : null,  lte ? moment(lte) : null]}
      ranges={{
        [ze('最近1小时', 'Last 1 Hour')]: [moment().subtract(1, 'hours'), null],
        [ze('最近24小时', 'Last 24 Hours')]: [moment().subtract(1, 'days'), null],
        [ze('最近72小时', 'Last 72 Hours')]: [moment().subtract(3, 'days'), null],
        [ze('最近7天', 'Last 7 Days')]: [moment().subtract(7, 'days'), null],
        [ze('今天', 'Today')]: [moment().startOf('day'), moment().add(1, 'days').startOf('day').subtract(1, 'seconds')],
        [ze('昨天', 'Yesterday')]: [moment().subtract(1, 'days').startOf('day'), moment().startOf('day').subtract(1, 'seconds')],
      }}
      onChange={(dates) => {
        const [gteMoment, lteMoment] = dates || [null, null];
        onChange({
          queryType: QueryType.RANGE,
          gte: gteMoment ? gteMoment.valueOf() : null,
          lte: lteMoment ? lteMoment.valueOf() : null,
        });
      }}
    />}
  </div>
}

export default AbsoluteAndRelativeDateRange;
