import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createLogger from 'redux-logger';
import { routerMiddleware } from 'connected-react-router';
// import DevTools from 'containers/DevTools'
import api from 'store/middlewares/api';
import createRootReducer from 'reducers';

export default function configureStore(initialState, history, debug = false) {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  let finalCreateStore;
  if (debug) {
    finalCreateStore = composeEnhancers(
      applyMiddleware(thunk, routerMiddleware(history), api, createLogger({
        duration: true,
        colors: {
          titile: '#ff0000',
        },
        stateTransformer: (state) => {
          const _state = {};
          Object.keys(state).forEach((item) => {
            _state[item] = state[item].toJS ? state[item].toJS() : state[item];
          });
          return _state;
        },
      }))
      // DevTools.instrument()
    )(createStore);
  } else {
    finalCreateStore = composeEnhancers(
      applyMiddleware(thunk, routerMiddleware(history), api)
    )(createStore);
  }

  const store = finalCreateStore(createRootReducer(history), initialState);
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('reducers', () => {
      const nextRootReducers = require('reducers');
      // https://github.com/supasate/connected-react-router/issues/54
      // store.replaceReducer(nextRootReducers);
      store.replaceReducer(connectRouter(history)(nextRootReducer));
    });
  }

  return store;
}
