
/**
 * Created by vscode.
 * editor: joanaxu
 * Date: 12/6/2018
 * Time: 2:46 PM
 * 处理redux中dispatch的请求；
 * 当rest属性存在，则触发请求。
 * 当rest属性不存在，则触发原始的actionType
 */


/**
 * 意义：根据不同的状态码返回相应的响应。
 * 1 首先最外层根据fetch的response判断http状态码，从最外层http状态码开始分析
 * 2 进入每一个状态码之后，对相应状态码下后端的响应逻辑做出分析。具体的状态码逻辑参考以下。
 *     // common
    public static $SUCCESS = 100000;
    public static $UNKNOWN_ERROR = 100001;
    public static $TEMPORARILY_UNAVAILABLE = 100002;
    public static $UNAUTHORIZED_ERROR = 100003;
    public static $PARAMETER_ERROR = 100004;
    public static $NO_MATCH_INFO_ERROR = 100005;
    public static $ILLEGAL_TOKEN = 100006;
    public static $NO_AUTHENTICATION_INFO = 100007;
    public static $REQUEST_TOO_FREQUENTLY = 100011;

    public static $BACK_END_TIMEOUT_ERROR = 501001;
    public static $BACK_END_ERROR = 501002;
    public static $BACK_END_UNAUTHORIZED_ERROR = 501002;
    public static $BACK_END_PARAMETER_ERROR = 501002;
    public static $BACK_END_EXCEPTIONAL_RESPONSE_ERROR = 501003;
    public static $PORTAL_TIMEOUT_ERROR = 501004;

    public static $WORKSPACE_ID_ERROR = 501100;
    public static $TAPD_RESPONSE_ERROR = 501101;
    public static $TAPD_SUCC_API_ERROR = 501102;
    public static $TAPD_SUCC_QUERY_ERROR = 501103;
    public static $TAPD_USER_PERMISSION_ERROR = 501104;
* 3 json是服务端的响应。
* 4 因为是双portal的架构，所以对于原始的php响应这里要兼容node的响应。
* 5 node的响应格式：{"code":0,"msg":"ok","data":{"size":300},"header":{}}
* 6 php的响应格式：成功的情况： {status: 200, msg: "success", ret: []} 或者 {msg: "success", ret: {code: 1}}
* 7 php和node状态码返回的主要不同在于成功的状态码。node成功状态码是0；php是200或者10000；
 */
import { FETCH_FAILURE } from 'store/actions';
import LogicError from 'utils/LogicError';
import { show } from 'utils/EventEmitterHelper';
import { ravenPostError } from 'utils/helper';
import * as globalActions from 'reducers/global/globalActions';

export default () => (next) => (action) => {
  if (typeof action.rest === 'undefined' || typeof action.rest !== 'function') {
    // rest不存在，dispatch直接调用原始dispatch功能;
    return next(action);
  }
  const { rest, data } = action;
  const { types } = rest;
  console.log('rest 的 types ========>',rest, data, types);
  function actionWith(originData) {
    const finalAction = Object.assign({}, action, originData);
    delete finalAction.rest;
    return finalAction;
  }
  // 首先发起requestType的请求。
  const [requestType, successType, failureType, createdType, acceptedType] = types;
  next(actionWith({ type: requestType }));

  const params = Object.assign({}, data);
  return new Promise((resolve, reject) => {
    rest(data).then(({ json, response }) => {
      console.log('json =====> ', json);
      console.log('response=======> ', response);
      switch (response.status) {
        case 200:
          const code = (json && json.code) || (json && json.ret && json.ret.code) || (json.code);
          console.log('code ======> ', code);
          let customError = null;
          if (typeof code === 'number') {
            switch (code) {
              case 0:
              case 200:
              case 100000: {
                next({
                  type: successType,
                  response: json.data || json.ret,
                  json,
                  params,
                });
                return resolve(json.data || json.ret);
              }
              case 100003: {
                globalActions.hideLoading()(next);
                customError = new LogicError({
                  status: code,
                  statusText: json.msg,
                  errorCode: code,
                  response: json && json.data || json.ret,
                  message: `fetch failure!failureType: ${failureType}, code=${code}, statusText=${json.msg}`,
                });
                return reject(customError);
              }
              case 100006:
              case 100007: {
                globalActions.hideLoading()(next);
                next({
                  type: FETCH_FAILURE,
                  failureType: 'ERR_TOKEN_EXPIRED',
                  error: 'user token expired',
                });
                customError = new Error(`fetch failure! status=${json.status}, message=${json.msg} failureType: ERR_TOKEN_EXPIRED`);
                return reject(customError);
              }
              case 100011: {
                globalActions.hideLoading()(next);
                next(globalActions.setError(429, '请求频率超过限制，请稍后再试'));
                return reject(new Error(`fetch failure! status=${json.status}, message=${json.msg}`));
              }
              case 501100:
              case 501104: {
                return resolve(json);
              }
              case 501004: {
                globalActions.hideLoading()(next);
                // php portal timeout
                ravenPostError(new Error(`[自定义错误]: ${failureType}, 系统繁忙，请稍后再试`));
                show('系统繁忙，请稍后再试', 'error');
                return resolve(json);
              }
              default: {
                globalActions.hideLoading()(next);
                next({
                  type: failureType,
                  status: code,
                  response: json && json.data || json.ret,
                  json,
                  message: (json && json.msg),
                  error: (json && json.msg) || code,
                  params,
                });
                next({
                  type: FETCH_FAILURE,
                  failureType,
                  status: code,
                  response: json && json.data || json.ret,
                  json,
                  message: (json && json.msg),
                  error: (json && json.msg) || code,
                });
                customError = new LogicError({
                  status: code,
                  statusText: json && json.msg,
                  response: json && json.data || json.ret,
                  message: `fetch failure! code=${code}, statusText=${json && json.msg}, failureType: ${failureType}, response: ${JSON.stringify(json && json.data || json.ret || {})}`,
                });
                return reject(customError);
              }
            }
          } else {
            if (json.ret && json.ret.respStructure === 'COMMON_RESP' && json.ret.reponseCode < 0) {
              globalActions.hideLoading()(next);
              next({
                type: FETCH_FAILURE,
                failureType: 'ERR_UNEXPECTED_RESP',
                error: json.ret.reponseDesc || json.ret.reponseCode,
              });
              customError = new LogicError({
                status: 500,
                response: json && json.ret,
                atusText: 'An unexpected issue occurred!',
                message: `An unexpected issue occurred! responseCode=${json.ret.reponseCode}, responseDesc=${json.ret.reponseDesc}, failureType: ${failureType}, response: ${JSON.stringify(json && json.ret || json.data || {})}`,
              });
              return reject(customError);
            }
            // handle json.ret === null
            if (!json.ret && json.msg) {
              // todo report this exception to portal
            }
            next({
              type: successType,
              response: json.data || json.ret,
              json,
              params,
            });
            return resolve(json.data || json.ret);
          }
        case 201:
          next({
            type: createdType,
            response: [],
            params,
          });
          return resolve([]);
        case 202:
          next({
            type: acceptedType,
            response: [],
            params,
          });
          return resolve([]);
        case 401:
          globalActions.hideLoading()(next);
          return reject(new Error(`HTTP status 401, message=${json.msg}`));

        case 403:
          globalActions.hideLoading()(next);
          return reject(new Error(`fetch failure! status=${json.status}, message=${json.msg}`));
        case 418:
          return reject(new Error(`fetch failure! status=${json.status}, message=登录态失效，请重新登录`));
        case 504:
          customError = new Error(`fetch failure!failureType: ${failureType} status=${json.status}, message=${json.msg}`);
          globalActions.hideLoading()(next);
          ravenPostError(new Error(`[自定义错误]: ${failureType}, 系统繁忙，请稍后再试`));
          show('系统繁忙，请稍后再试', 'error');
          next({
            type: successType,
          });
          return reject(customError);
        default:
          globalActions.hideLoading()(next);
          customError = new LogicError({
            status: response.status,
            statusText: json && json.msg,
            response: json && json.ret,
            message: `fetch failure!failureType: ${failureType}, status=${json && json.status}, statusText=${json && json.msg}, response: ${JSON.stringify(json && json.ret || json.data || {})}`,
          });
          next({
            type: failureType,
            status: (json && json.status),
            response: json && json.ret,
            message: (json && json.msg),
            error: (json && json.msg) || (json && json.status),
            params,
          });
          next({
            type: FETCH_FAILURE,
            failureType,
            status: (json && json.status),
            response: json && json.ret,
            message: (json && json.msg),
            error: (json && json.msg) || (json && json.status),
          });
          return reject(customError);
      }
    },
    (error) => {
      globalActions.hideLoading()(next);
      next({
        type: failureType,
        error: error.message,
        params,
      });
      next({
        type: FETCH_FAILURE,
        failureType,
        error: error.message,
      });
      return reject(error);
    });
  });
};
