import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import _style from './MenuItem.scss';
import { Popover, Tooltip } from 'antd';
import { CS_STYLES } from 'utils/constants/style-constants';
import Arrow from 'svg/v2/newcs_dashboard_left_arrow.svg';
import containerStyle from './CrashSightLeftMenu.scss';
import { useHover } from 'ahooks';

export const MenuItem = ({
  showCollapsed, menuOption, pathname, selectedState,
  getMenuItemClassName, handleScrollToTop
}) => {

  const {children = [], pathPrefixes = []} = menuOption;

  const initialState = pathPrefixes.some((prefix) => pathname.includes(prefix));
  const [hoverKey, setHoverKey] = useState('');
  const [isShowChildren, setIsShowChildren] = useState(initialState);

  const ref = useRef(null);
  // const isHovering = useHover(ref);

  const svgIcon = ((initialState || menuOption.label === hoverKey) && menuOption.svgSelect) ? menuOption.svgSelect : menuOption.svg;
  function getChildLabel() {
    let label = menuOption.labelAbridge
    if (children.length) {
      // 选中子集展示子集的label
      const childItem = children.find(childrenItem => {
        return childrenItem.pathPrefixes.some((prefix) => pathname.includes(prefix));
      })
      if (childItem) {
        label = childItem.labelAbridge;
      }
    }
    return <div style={{fontSize: '12px',textAlign:'center', lineHeight: '12px', marginTop: '3px'}}>
      { label }
    </div>;
  }
  // 菜单item内部
  const inner = showCollapsed
    ? <div
      className={containerStyle.collapsedMenuItemWrapper}
      onMouseEnter={() => {
        setHoverKey(menuOption.label)
      }}
      onMouseLeave={() => {
        setHoverKey('')
      }}
    >
        <div
          style={{display: 'grid',placeItems: 'center'}}
        >{ svgIcon }</div>
        {getChildLabel()}
      </div>
    : <div className={containerStyle.menuItemWrapper}
        onClick={() => {
          setIsShowChildren((prev) => !prev);
        }}
        onMouseEnter={() => {
          setHoverKey(menuOption.label)
        }}
        onMouseLeave={() => {
          setHoverKey('')
        }}
        >
        <div className={containerStyle.menuItemIconWrapper}>{ svgIcon }</div>
        <div style={{
          marginLeft: '8px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          lineHeight: '48px'}}>
          { menuOption.label }
        </div>
        {
          (initialState) ? <div style={{position:'absolute',right:'10px'}}>
              <Arrow />
            </div> : null
        }
    </div>;

  function getCollapsedSubMenuStyle(option) {
    const { pathPrefixes, children = [] } = option;
    return pathPrefixes.some(x => (pathname || '').includes(x))
      ? { color: CS_STYLES.PRIMARY_COLOR, lineHeight: '32px', display: 'block', width: '120px', textDecoration: 'none', fontWeight: 'bold'}
      : { color: '#333333', lineHeight: '32px', display: 'block', width: '120px', textDecoration: 'none'};
  }
  function getCollapsedSubMenuClass(option) {
    const { pathPrefixes, children = [] } = option;
    return pathPrefixes.some(x => (pathname || '').includes(x))
      ? `${containerStyle.collapsedSubmenuItem} ${containerStyle.collapsedSubmenuItemSelect}`
      : containerStyle.collapsedSubmenuItem;
  }
  if (showCollapsed) {
    const isPopoverVisible = isShowChildren && !!children.length;
    const collapsedMenuNode = children.length
      ? <div ref={ref} className={getMenuItemClassName(menuOption)}
        style={{ justifyContent: 'center',display:'flex',padding:'0', marginTop: '0', marginBottom: 8}}
      >{ inner }</div>
      : <Link
        key={menuOption.label}
        className={getMenuItemClassName(menuOption)}
        to={menuOption.linkTo}
        onClick={handleScrollToTop}
        style={{ justifyContent: 'center',display:'flex',padding:'0', marginTop: '0'}}
      >
        { inner }
      </Link>;
    const collapsedSubMenuNodes = children.length ?
      children.map(item => <Link
        key={item.label}
        //style={getCollapsedSubMenuStyle(item)}
        style={{textDecoration: 'none'}}
        className={getCollapsedSubMenuClass(item)}
        to={item.linkTo}
        onClick={handleScrollToTop}
      >
        <div>{item.label}</div>
      </Link>)
      : null;
    if (!children.length) {
      return <Tooltip placement="right" title={menuOption.label}>
        {collapsedMenuNode}
      </Tooltip>
    }
    // const content = children.length ? collapsedSubMenuNodes : menuOption.label;
    const content = collapsedSubMenuNodes;
    return <Popover key={menuOption.label} placement="right" content={content}
      overlayClassName={containerStyle.menuPopover}
      overlayStyle={{ position: 'fixed' }}
      onOpenChange={(isOpen) => {
        setHoverKey(isOpen ? menuOption.label : '')
      }}
    >
      {collapsedMenuNode}
    </Popover>;
  }

  // 子菜单
  const subMenuNodes = children.length ?
    children.map(item => <Link
      key={item.label}
      className={getMenuItemClassName(item)}
      to={item.linkTo}
      onClick={handleScrollToTop}
    >
      <div className={containerStyle.subMenuItemWrapper}>{item.label}</div>
    </Link>)
  : null;
  const menuNode = children.length
    ? <div
      ref={ref}
      key={menuOption.label}
      className={getMenuItemClassName(menuOption)}
      style={{ marginBottom: isShowChildren ? '0px' : '8px'}}
      >
        {inner}
        { isShowChildren && <div style={{padding: '4px 0px'}}>
          { subMenuNodes }
        </div>}
      </div>
    : <Link
      key={menuOption.label}
      className={getMenuItemClassName(menuOption)}
      to={menuOption.linkTo}
      onClick={handleScrollToTop}
    >
      { inner }
    </Link>;

  return <>
    {menuNode}
  </>;
}
