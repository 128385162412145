import React, {useState, useRef, useEffect} from 'react';
import { Select } from 'antd';
import { useHover } from 'ahooks';
import _style from './CsSelect.scss';

export const CsSelect = (props = {}) => {
  const selectRef = useRef(null);
  const isHovering = useHover(selectRef);
  const [isHoverSelectFocused, setIsHoverSelectFocused] = useState(false);

  // 是否多选
  const isMultiSelect = ['multiple', 'tags'].includes(props?.mode);

  // 传入的部分属性
  const originStyle = props?.style || {};
  const originOnFocus = props?.onFocus;
  const originOnBlur = props?.onBlur;

  const maxTagPlaceholder = (nodes = []) => `+${nodes.length}`

  const hoverSelectOnFocus = () => {
    setIsHoverSelectFocused(true);
    typeof originOnFocus === 'function' && originOnFocus();
  }
  const hoverSelectOnBlur = () => {
    setIsHoverSelectFocused(false);
    typeof originOnBlur === 'function' && originOnBlur();
  }

  const commonProps = isMultiSelect ? {...props, maxTagCount: 'responsive', maxTagPlaceholder} : {...props};
  // hover Select需要重写属性
  const hoverProps = isMultiSelect ? {...props, maxTagCount: undefined, onFocus: hoverSelectOnFocus, onBlur: hoverSelectOnBlur } : {...props};

  return <div className={_style.cs_select} ref={selectRef} style={{...originStyle, display: 'inline-block', position: 'relative' }}>
    <Select {...commonProps} style={{width: '100%',  zIndex: '10'}}>
    </Select>
    {
      isMultiSelect && (isHoverSelectFocused || isHovering) &&
        <Select {...hoverProps} style={{ position: 'absolute', top: '0px', left: '0px', width: '100%',zIndex: '11' }} >
        </Select>
    }
  </div>;
}