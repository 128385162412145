import React, {useState}from 'react';
import { Link } from 'react-router-dom';
import code from 'images/code.png';
import { isEmptyObject } from 'utils/helper';
import style from './style.scss';
import { Translation } from 'react-i18next'
import { Modal, Form, Input } from 'antd';
import { ze } from 'utils/zhEn';
import AccountbindingIcon from 'svg/v2/newcs_dashboard_personalinfo_accountbinding_icon.svg';

const AccountBind = ({ currentUser,actions }) => {
  const {
    email, userId, wechat, newUserId, phone, validated,
  } = !isEmptyObject(currentUser) ? currentUser : {};
  const [form] = Form.useForm();
  const [ isModalOpen, setIsModalOpen ] = useState(false);

  const handleSave = async() => {
    try {
      await form.validateFields();
      const data = form.getFieldValue();
      actions.changeUserParamOptions({ name: 'email', value: data.email });
      actions.updateUserInfo().then(actions.replaceState(`/user/${currentUser.userId}?tag=account`));
      setIsModalOpen(false);
    } catch (error) {
      console.log('error',error)
    }
  }


  return (
    <Translation>
      {
        (t, {i18n}) =>
        <div className={`${style.account_box} ${style.hidden}`}>
          <div className={style.account_header}>
          {<AccountbindingIcon width="14" height="14" viewBox="0 0 14 14" />}
            <div>
              {t('ACCOUNT.bind')}
            </div>
          </div>
      <ul className={style.account_box_ul}>
        <li className={style.account_box_li}>
          <div className={style.accout_name}>{ t('ACCOUNT.mail') }</div>
          <div className={style.accout_item}>
            <dl className={style.account_box_dl}>
              <dt className={style.account_box_dt}>{email || t('ACCOUNT.mail_nobinding')}</dt>
              <dd className={`${style.desc} ${style.account_box_dd}`}>{ t('ACCOUNT.mail_tip') }</dd>
            </dl>
          </div>
          <div className={style.action_btn} onClick={() => {setIsModalOpen(true)}}>
          <a>{email ? t('ACCOUNT.mail_edit') : t('ACCOUNT.mail_add')}</a>
            {/* <Link to={`/mail/${newUserId}`}>{email ? t('ACCOUNT.mail_edit') : t('ACCOUNT.mail_add')}</Link> */}
          </div>
        </li>
      </ul>
      <Modal
        width={480}
        title={t('ACCOUNT.mail_editTitle')}
        open={isModalOpen}
        onOk={() => handleSave()}
        onCancel={() => {setIsModalOpen(false)}}>
        <div>
          <div>{t("ACCOUNT.mail_editTip2")}</div>
          <div style={{paddingTop:'19px'}}>
            <Form
              form={form}
              layout="vertical"
            >
              <Form.Item
                name='email'
                rules={[
                  {
                    required: true,
                    message: ze('邮箱不能为空','Email cannot be empty'),
                  },
                  {
                    pattern:
                      /^[a-z0-9]+([\._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+\.){1,63}[a-z0-9]+$/,
                    message: ze('邮箱格式错误','Invalid email address format'),
                  },
                ]}
              >
                <Input placeholder={t('ACCOUNT.mail_editPlaceholder')} style={{color:'rgba(57, 49, 87, 0.6)'}}/>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
      }
    </Translation>
  );
};

export default AccountBind;
