import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  Modal,
  Spin,
  Divider,
  Table, Button, message, notification, Checkbox,
  Row, Col,
} from 'antd';
import { connect, useDispatch } from 'react-redux';
import { isNotNullish, isNullish } from 'utils/nullish';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n.js';
import { ze } from 'utils/zhEn';
import moment from 'moment';
import { upsertServerAppSettings, upsertWebAppSettings } from 'reducers/app/appActions';
import { isMobile } from 'utils/platform';
import { WebAppSettings } from 'utils/web-app-settings';
import { ServerAppSettings } from 'utils/server-app-settings';
import { CS_STYLES } from 'utils/constants/style-constants';
import DeleteIcon from 'svg/v2/newcs_dashboard_projectinformation_projectdelete_icon.svg';

const BUILT_IN_FIELD_INFO_LIST = [
  { value: 'severity', label: i18n.t('tapd.严重程度') },
  { value: 'reporter', label: i18n.t('tapd.创建人'), mustRequired: true },
  { value: 'versionReport', label: i18n.t('tapd.发现版本') },
  { value: 'iterationId', label: i18n.t('tapd.迭代') },
  { value: 'module', label: i18n.t('tapd.模块') },
  { value: 'releaseId', label: i18n.t('tapd.发布计划') },
  { value: 'priority', label: i18n.t('tapd.优先级') },
  { value: 'currentOwner', label: i18n.t('tapd.处理人') },
];

const KEY_TO_BUILT_IN_FIELD_INFO = Object.assign(Object.create(null), ...BUILT_IN_FIELD_INFO_LIST.map(x => ({ [x.value]: x })));

export const TapdEditBugCustomFieldsUtil = (() => {
  return Object.freeze({
    getBuiltInFieldInfoList() {
      return BUILT_IN_FIELD_INFO_LIST;
    },

    getBuiltInFieldInfoByKey(fieldKey) {
      return KEY_TO_BUILT_IN_FIELD_INFO[fieldKey];
    },

    checkIsCustomField(fieldKey) {
      return (fieldKey || '').startsWith('custom');
    },

    getSettingKey() {
      return WebAppSettings.keys.tapdEnabledBugCustomFields;
    },

    /**
     * @param {{ field: string, required: boolean }[]} tapdFieldList 来自ServerAppSettings的tapdFieldList配置项
     * @param {{ customField: string }[]} tapdEnabledBugCustomFields 来自WebAppSettings的tapdEnabledBugCustomFields配置项，仅用作CS 1.0兼容老数据使用，后续废弃
     * @returns {{ field: string, required: boolean }[]}
     * */
    getEnabledFieldList(tapdFieldList, tapdEnabledBugCustomFields) {
      if (tapdFieldList && tapdFieldList.length > 0) {
        return tapdFieldList;
      }
      const defaultBuiltInFieldList = BUILT_IN_FIELD_INFO_LIST.map(x => ({ field: x.value, required: !!x.mustRequired }));
      const customFieldList = tapdEnabledBugCustomFields.map(x => ({ field: x.customField, required: false }));
      return [
        ...defaultBuiltInFieldList,
        ...customFieldList,
      ];
    },
  });
})();

const TapdEditBugCustomFieldsModal = ({
  reduxState,
  visible,
  bugCustomFields,
  onCancel = () => {},
  onSave = () => {},
}) => {
  bugCustomFields = bugCustomFields || [];

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { appId, platformId } = reduxState.app.get('current').toJS();
  const settings = reduxState.app.get('appIdToWebAppSettings')[appId];
  const selectedFieldsFromSettings = (settings || {})[TapdEditBugCustomFieldsUtil.getSettingKey()] || [];
  const serverAppSettings = reduxState.app.get('appIdToServerAppSettings')[appId] || {};
  const tapdFieldList = serverAppSettings[ServerAppSettings.keys.tapdFieldList];

  const [loading, setLoading] = useState(false);

  const [selectedBuiltInFieldKeys, setSelectedBuiltInFieldKeys] = useState([]);
  const [selectedCustomFieldKeys, setSelectedCustomFieldKeys] = useState([]);
  const allSelectedFieldKeys = [...selectedBuiltInFieldKeys, ...selectedCustomFieldKeys];
  const [requiredFieldKeyDict, setRequiredFieldKeyDict] = useState({});

  const customFieldOptions = bugCustomFields.map(x => ({
    value: x.customField,
    label: x.name,
  }));

  const allFieldOptions = [...BUILT_IN_FIELD_INFO_LIST, ...customFieldOptions];
  const allOrderedSelectedFieldKeys = allFieldOptions
    .filter(x => allSelectedFieldKeys.includes(x.value))
    .map(x => x.value);

  useEffect(() => {
    if (!visible) {
      return;
    }
    const enabledFieldList = TapdEditBugCustomFieldsUtil.getEnabledFieldList(tapdFieldList, selectedFieldsFromSettings);
    const enabledBuiltInFieldList = enabledFieldList.filter(x => !TapdEditBugCustomFieldsUtil.checkIsCustomField(x.field));
    const enabledCustomFieldList = enabledFieldList.filter(x => TapdEditBugCustomFieldsUtil.checkIsCustomField(x.field));

    const builtInFieldKeys = BUILT_IN_FIELD_INFO_LIST
      .filter(x => x.mustRequired || enabledBuiltInFieldList.some(y => y.field === x.value))
      .map(x => x.value);
    setSelectedBuiltInFieldKeys(builtInFieldKeys);
    setSelectedCustomFieldKeys(enabledCustomFieldList.map(x => x.field));
    setRequiredFieldKeyDict(Object.assign(Object.create(null), ...enabledFieldList.filter(x => x.required).map(x => ({ [x.field]: true }))));
  }, [visible]);

  function onSubmit() {
    setLoading(true);
    const newTapdFieldList = allOrderedSelectedFieldKeys.map(x => {
      const nullableBuiltInFieldInfo = TapdEditBugCustomFieldsUtil.getBuiltInFieldInfoByKey(x);
      const mustRequired = nullableBuiltInFieldInfo?.mustRequired;
      return {
        field: x,
        required: mustRequired || !!requiredFieldKeyDict[x],
      };
    });

    dispatch(upsertServerAppSettings(appId, {
      [ServerAppSettings.keys.tapdFieldList]: newTapdFieldList,
    }));

    setLoading(false);
    onSave();
  }

  return <Modal
    title={ze('设置TAPD字段', 'Set TAPD Fields')}
    width='860px'
    bodyStyle={{ minHeight: '200px' }}
    visible={visible}
    maskClosable={false}
    onCancel={() => onCancel()}
    okText={ze('保存', 'Save')}
    okButtonProps={{ disabled: loading }}
    onOk={() => onSubmit()}
  >
    <Spin spinning={loading}><div style={{ display: 'flex' }}>
      <div style={{ flexGrow: 1 }}>
        <div style={{marginBottom: '20px'}}>{ ze('项目级设置。勾选的字段将可以在提交缺陷界面中填写。', 'This is app setting. Choose the fields you want to use.') }</div>
        <div style={{ marginBottom: '12px', fontWeight: 700 }}>{ ze('通用字段', 'Built-In Fields') }</div>
        <div>
          <Checkbox.Group
            style={{ width: '100%' }}
            value={selectedBuiltInFieldKeys}
            onChange={v => setSelectedBuiltInFieldKeys(v)}
          ><Row> {
            BUILT_IN_FIELD_INFO_LIST.map(item => <Col span={8} key={item.value} style={{marginBottom: '16px'}}>
              <Checkbox
                disabled={item.mustRequired}
                value={item.value}
              >{item.label}</Checkbox>
            </Col>)
          } </Row></Checkbox.Group>
        </div>
        <div style={{ marginTop: '12px', marginBottom: '12px', fontWeight: 700 }}>{ ze('自定义字段', 'Custom Fields') }</div>
        <div>
          <Checkbox.Group
            style={{ width: '100%' }}
            value={selectedCustomFieldKeys}
            onChange={v => setSelectedCustomFieldKeys(v)}
          >
            <Row>
              {
                customFieldOptions.map(item => <Col span={8} key={item.value} style={{marginBottom: '16px'}}>
                  <Checkbox value={item.value}>{item.label}</Checkbox>
                </Col>)
              }
            </Row>
          </Checkbox.Group>
        </div>
      </div>
      <Divider type='vertical' style={{ height: 'auto' }}/>
      <div style={{ width: '200px', flexShrink: 0 }}>
        <div style={{ color: CS_STYLES.SUB_TEXT_COLOR }}>{ ze('当前选定的字段', 'Selected Fields') }</div>
        <div style={{ marginTop: '12px', display: 'flex', flexDirection: 'column', gap: '8px' }}>{
          allOrderedSelectedFieldKeys.map(x => {
            const isCustomField = TapdEditBugCustomFieldsUtil.checkIsCustomField(x);
            const nullableBuiltInFieldInfo = TapdEditBugCustomFieldsUtil.getBuiltInFieldInfoByKey(x);
            const mustRequired = nullableBuiltInFieldInfo?.mustRequired;
            const required = mustRequired || !!requiredFieldKeyDict[x];

            const label = isCustomField
              ? customFieldOptions.find(y => y.value === x)?.label || x
              : BUILT_IN_FIELD_INFO_LIST.find(y => y.value === x)?.label || x;
            return <div key={x} style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ flexGrow: 1 }}>{ label }</div>
              <Checkbox
                checked={required}
                disabled={mustRequired}
                onChange={(e) => setRequiredFieldKeyDict({ ...requiredFieldKeyDict, [x]: e.target.checked })}
              >{ ze('必填', 'Required') }</Checkbox>
              <DeleteIcon
                style={{ color: CS_STYLES.SUB_TEXT_COLOR, cursor: 'pointer', visibility: mustRequired ? 'hidden' : 'visible' }}
                onClick={() => {
                  if (isCustomField) {
                    setSelectedCustomFieldKeys(selectedCustomFieldKeys.filter(y => y !== x));
                  } else {
                    setSelectedBuiltInFieldKeys(selectedBuiltInFieldKeys.filter(y => y !== x));
                  }
                }}
              />
            </div>;
          })
        }</div>
      </div>
    </div></Spin>
  </Modal>;
};

TapdEditBugCustomFieldsModal.propTypes = {
};

const mapStateToProps = state => ({
  reduxState: state,
});

export default connect(mapStateToProps)(TapdEditBugCustomFieldsModal);
