import { createAction } from 'redux-actions';
import { getStartEndDateFromStr } from 'utils/StringUtils';
import { PAGE_TYPE_CONVERT } from 'utils/constants';
import keywordRest from './KeyWordRest';

export function getKeywordTrend(actionType) {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    const { chartSearchParams, listSearchParams } = getState().keyword.toJS();

    // keyword 中获取相关的搜索参数
    const { version, date, pageType } = chartSearchParams;
    const { detailVersion, detailDate } = listSearchParams;
    const tempVerison = (actionType === 'list' ? detailVersion : version);
    const dateObj = (actionType === 'list' ? getStartEndDateFromStr(detailDate.date, detailDate.startDate, detailDate.endDate, { format: 'millseconds' }) : getStartEndDateFromStr(date.date, date.startDate, date.endDate, { format: 'millseconds' }));
    return dispatch({
      rest: keywordRest.keywordTrend.get,
      data: {
        appId,
        pid,
        type: PAGE_TYPE_CONVERT[pageType],
        version: tempVerison,
        startDate: dateObj.startDate,
        endDate: actionType === 'list' ? dateObj.startDate : dateObj.endDate,
        actionType,
      },
    });
  };
}

export const changeSearchParam = createAction('CHANGE_KEYWORD_SEARCH_PARAM');

export function getKeywordInfo() {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    return dispatch({
      rest: keywordRest.keywordInfo.get,
      data: {
        appId,
        pid,
      },
    });
  };
}

export function deleteKeywordInfo(id) {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    return dispatch({
      rest: keywordRest.keywordInfo.delete,
      data: {
        appId,
        pid,
        id,
      },
    });
  };
}


/**
  private String appId;
  private int platformId;
  private Long id;
  private String keyword;
  private String name;
  private Integer enable = -1;
 */
export function modifyKeywordInfo({
  keyword, name, enable, id, remark,
}) {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    return dispatch({
      rest: keywordRest.keywordInfo.put,
      data: {
        appId, pid, id, keyword, name, enable, remark,
      },
    });
  };
}

export function addKeywordInfo({
  keyword, name, enable, remark,
}) {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    return dispatch({
      rest: keywordRest.keywordInfo.post,
      data: {
        appId, pid, keyword, name, enable, remark,
      },
    });
  };
}
