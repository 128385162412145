import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Redirect } from 'react-router';

/* eslint-disable react/prop-types */
export default ({ match }) => (
  <Switch>
    <Route path={`${match.url}/apps/:appId`} render={(props) => <Redirect to={`/crash-reporting/dashboard/${props.match.params.appId}${props.location.search}`}/>} />
    <Route path={`${match.url}`} render={(props) => <Redirect to={`/crash-reporting/dashboard/${props.match.params.appId}${props.location.search}`}/>} />
  </Switch>
);
