import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import style from './style.scss';
import { Table } from 'antd';
import { ze } from 'utils/zhEn';


class PageTrace extends Component {
  renderLi() {
    const { contents } = this.props;
    let lines = [];
    if (contents) {
      lines = contents.trim().split('\n');
    }
    const columns = [
      {
        title: ze('时间','Time'),
        dataIndex: 'time',
        key: 'time',
        width:'15%',
      },
      {
        title: ze('页面','Page'),
        dataIndex: 'page',
        key: 'page',
        width:'65%',
      },
      {
        title: ze('事件','Event'),
        dataIndex: 'event',
        key: 'event',
        width:'20%',
      }
    ];
    let parseLines = [];
    try {
      parseLines = lines.map(log => {
        const parts = log.split(/\s+/).filter(Boolean);
        const time = parts.slice(0, parts.length - 2).join(' ').replace(/\.\w+$/, "");
        const [ page, event ] = parts.slice(-2);
        return {time, page, event};
      });
    } catch (error) {
      console.error(error);
      parseLines = lines.map(log => {
        return {time:log};
      });
    }
    return <Table columns={columns} dataSource={parseLines} size="small" />;
  }

  render() {
    return (
      <div>
        {this.renderLi()}
      </div>
    );
  }
}

PageTrace.propTypes = {
  contents: PropTypes.string,
};

// 设置props
export default connect((state) => {
  return {
    currentApp: state.app.get('current'),
    currentUser: state.user.get('current'),
    issue: state.issue.get('current'),
  };
}, (dispatch) => ({
  actions: bindActionCreators({

  }, dispatch),
  dispatch,
}))(PageTrace);
