import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import { isEmptyObject } from 'utils/helper';
import { SOLUTION_ARR } from 'utils/constants';
import {
  CHANGE_CURTAB,
  UPDATE_STATE_PARAMS,
  SOLUTION_INFO_SUCC,
  CASE_SOLUTION_SUCC,
} from 'store/actions';
import { initialState } from './initialState';

export default handleActions({

  [CHANGE_CURTAB]: (state, action) => state.set('currentTab', action.payload),
  [UPDATE_STATE_PARAMS]: (state, action) => {
    const { type, value } = action.payload;
    return state.set(type, value);
  },
  [SOLUTION_INFO_SUCC]: (state, action) => {
    const { datas = {}, reponseCode = 0 } = action.response.data;
    const { matchCase = {}, myCase = {} } = datas;
    const { caseId = -1, approveStat = 0 } = matchCase;
    const flag = (isEmptyObject(matchCase) && isEmptyObject(myCase)) || parseInt(caseId) === 0;// 判断是否没有解决方案,要是有的话,默认显示系统的方案
    const curTab = isEmptyObject(matchCase) ? SOLUTION_ARR[0] : SOLUTION_ARR[SOLUTION_ARR.length - 1];//
    return state.set('matchCase', fromJS(matchCase))
      .set('myCase', fromJS(myCase))
      .set('noSolution', flag)
      .set('currentTab', flag ? '' : curTab)
      .set('hasZan', parseInt(approveStat) === 1)
      .set('statusCode', parseInt(reponseCode) === 0 ? 200 : -1);
  },
  CASE_APPROVE_SUCC: (state, action) => {
    const { data = {} } = action.response;
    const { datas = {} } = data;
    const { status } = datas;
    return state.set('isStar', parseInt(status) === 1)
      .set('hasZan', parseInt(status) === 1)
      .updateIn(['matchCase', 'solutionBean', 'approveNum'], (approveNum) => { return parseInt(status) === 1 ? approveNum + 1 : (parseInt(status) === 2 ? approveNum - 1 : approveNum); });
  },
  [CASE_SOLUTION_SUCC]: (state, action) => {
    const { data = {} } = action.response;
    const { datas = {} } = data;
    const { result } = datas;
    return state.set('solutionString', '')
      .updateIn(['myCase', 'solutionBean', 'solution'], (solution) => { return result ? solution : ''; });
  },
}, initialState);
