import React, { useState, useEffect, useMemo } from 'react';
import { Space, Table, Tag, Switch, Divider, Button, Modal, Form, Input, Select, Checkbox, message, Cell, Link, Popover } from 'antd';
import { MinusCircleOutlined, PlusOutlined,PlusCircleOutlined } from '@ant-design/icons';
import style from './DashboardFilters.scss';
import { connect, useSelector } from 'react-redux';
import RestHelper from "utils/RestHelper";
import PropTypes from 'prop-types';
import { isMobile } from 'utils/platform';
import { isZh, ze } from 'utils/zhEn';
import { SEARCH_EXCEPTION_TYPE_TO_OPTIONS } from 'utils/constants';
import { selectHasSpecifiedPermissionInCurrentApp } from 'utils/selectors/selectors';
import { RolePermission } from 'utils/constants/role-permission';
import IssueCrashFilterEx from 'components/commons/IssueCrashFilter/IssueCrashFilterEx';
import { IssueCrashFilterExUtil } from 'components/commons/IssueCrashFilter/IssueCrashFilterExUtil';
import { ExceptionCategory, ExceptionCategoryUtil } from 'utils/exception-category';
import OperateEditIcon from 'svg/v2/newcs_dashboard_setting_alertsetting_customalert_operate_edit_icon_normal.svg';
import ProjectdeleteIcon from 'svg/v2/newcs_dashboard_projectinformation_projectdelete_icon.svg';
import NoticeIcon from 'svg/v2/newcs_dashboard_notice_icon.svg';
import { CS_STYLES } from 'utils/constants/style-constants';

const DashboardFilters = (props) => {
  const { reduxState } = props;
  const currentApp = reduxState.app.get('current').toJS();
  const isSuperAdmin = reduxState.user.get('current').get('isSuper');
  const hasAdminPermission = selectHasSpecifiedPermissionInCurrentApp(reduxState, RolePermission.EDIT);
  const { appId, appName, platformId } = currentApp;
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const [configOptions, setConfigOptions] = useState([]);
  const [featureOptions, setFeatureOptions] = useState([]);
  const [marketStrategyData, setMarketStrategyData] = useState([]);
  const [currentData, setCurrentData] = useState();
  const [filterConditionDropdown, setFilterConditionDropdown] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    if(appId) {
      getListFeatureConfigs();
      getListDashboardFilters();
    }
  },[appId])
  const columns = [
    {
      title: ze('名称','Name'),
      // dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <div>
          <div>{record.name}</div>
          {(record.config.exceptionCategoryList || []).map((x,i) => {
            const options = EXCEPTION_CATEGORIES_OPTIONS.find(item => item.value === x)
            return <Tag
              style={{ padding: '0 2px', marginRight: '4px', marginBottom:'4px' }}
              key={i}
              color={EXCEPTION_CATEGORY_VALUE_TO_TAG_COLOR[x]}
            >{ options.label }</Tag>
          })}
        </div>
      ),
    },
    {
      title: ze('过滤规则', 'Filter rules'),
      dataIndex: 'config',
      key: 'config',
      render: (_, record) => (
        <div>
          <div>{record.config.mustSatisfyAllTags ? ze('满足以下所有特征过滤','Meet all of the following feature filters') : ze('满足以下任意特征过滤','Meet any of the following feature filters')}</div>
          <div>
            {(record.config.featureTags || []).map(item => {
                const featureOption = featureOptions.find(each => each.key === item);
                if (featureOption) {
                  const rawSearchGroup = featureOption.content.rawSearchGroup || [];
                  const multipleOrSearchConditionGroups = rawSearchGroup.map(rawSearch => IssueCrashFilterExUtil.makeSearchConditionGroupFromSearchParamsDict(rawSearch));
                  const searchConditionGroupDom = multipleOrSearchConditionGroups.map(searchConditionGroup => <div style={{ marginBottom: '8px' }}>
                    <IssueCrashFilterEx
                      isFeatureConfig={true}
                      hideIssueFields={true}
                      isReadOnly={true}
                      platformId={platformId}
                      filterOptions={{
                        exceptionTypeList: (SEARCH_EXCEPTION_TYPE_TO_OPTIONS[platformId] || []).filter(x => x.value),
                      }}
                      searchConditionGroup={searchConditionGroup}
                    />
                  </div>);
                  const popoverContent = <div
                    style={{ width: 'calc(100vw - 500px)' }}
                  >{ searchConditionGroupDom }</div>;
                  return <div>
                    <Popover
                      trigger='hover'
                      placement='top'
                      content={popoverContent}
                    ><Button type='link'>{ featureOption.name }</Button>
                    </Popover>
                  </div>;
                } else {
                  return <div><Button type='link'>{ item }{ze('(已删除)', '(Deleted)')}</Button></div>;
                }
              }
            )}
          </div>
        </div>
      )
    },
    {
      title: ze('操作人','Operators'),
      dataIndex: 'lastEditorName',
      key: 'lastEditorName',
    },
    {
      title: ze('操作时间','Operation time'),
      dataIndex: 'lastEditTime',
      key: 'lastEditTime',
    },
    {
      title: ze('备注','Remark'),
      dataIndex: 'comment',
      key: 'comment',
    },
    {
      title: ze('操作','Operations'),
      key: 'operation',
      render: (_, record) => (
        <div>
          <Space size={12}>
            <Switch disabled = {!hasAdminPermission} defaultChecked={record.enabled} onClick={(value) =>updateDashboard(record,'toggle')}/>
            <Button type="text" disabled = {!hasAdminPermission} className={style.renameIcon} onClick={() => updateDashboard(record,'content')}>
              {<OperateEditIcon />}
            </Button>
            <Divider type='vertical' style={{ margin: '0px' }}></Divider>
            <Button type="text" disabled = {!hasAdminPermission} className={style.renameIcon} onClick={() => deleteDashboardFilters({filterIdList:[record.filterId]})}>
              {<ProjectdeleteIcon />}
            </Button>
          </Space>
        </div>
      )
    },
  ];
  const EXCEPTION_CATEGORIES_OPTIONS = [
    { label: ze('崩溃','Crash'), value: ExceptionCategory.CRASH },
    { label: ze('卡顿','ANR'), value: ExceptionCategory.ANR },
    { label: ze('错误','Error'), value: ExceptionCategory.ERROR },
  ];
  const EXCEPTION_CATEGORY_VALUE_TO_TAG_COLOR = {
    CRASH: 'blue',
    ANR: 'green',
    ERROR: 'orange',
  };
  const [form] = Form.useForm();
  const onFinish = (values) => {
    console.log('Received values of form:', values);
  };
  const showModal = () => {
    setIsFilterDialogOpen(true);
  }
  // 关闭弹窗
  const closeFilterDialogFlag = () => {
    setIsFilterDialogOpen(false);
    form.resetFields();
    setCurrentData();
  }
  const closeFilterDialogFlagOk = async() => {
    await form.validateFields();
    const {comment,exceptionCategoryList,featureTags,mustSatisfyAllTags,name} = form.getFieldsValue();
    const formData = {
      name,
      comment,
      config: {
        exceptionCategoryList,
        featureTags,
        mustSatisfyAllTags,
      },
      ...currentData ? {filterId: currentData.filterId} : {}
    }
    if(currentData){
      updateDashboardFilter(formData);
    } else {
      createDashboardFilter(formData);
    }
    setIsFilterDialogOpen(false);
    form.resetFields();
    setCurrentData();
  }
  const matchedOptionList = (value) => {
    const data = value.split(',');
    const labels = (data || []).map(item => {
      const matchedOption = EXCEPTION_CATEGORIES_OPTIONS.find(option => option.value === item);
      return matchedOption? matchedOption.label : '';
    });
    return {richLabel: labels.join(','),exceptionCategories:data};
  }
  // 获取下拉框的数据
  const getListFeatureConfigs = async() => {
    const res = await RestHelper.mustPost('/api/featureAnalysis/listFeatureConfigs', { appId });
    const data = res.json.data;
    const mappedData = data
           .filter(item => item.realtimeTag)
           .map(item => {

            return {
              label: item.name,
              value: item.key,
              ...matchedOptionList(item.exceptionCategories),
            };
          });
    setFeatureOptions(data);
    setConfigOptions(mappedData);
    setFilterConditionDropdown(mappedData);
  }
  const updateDashboard = (value,type) => {
    setCurrentData(value);
    const {comment, name, config, enabled, filterId} = value;
    const {exceptionCategoryList, featureTags, mustSatisfyAllTags} = config;
    if(type === 'content') {
      form.setFieldsValue({
        exceptionCategoryList,
        featureTags,
        mustSatisfyAllTags,
        comment,
        name
      });
      showModal();
    }
    if(type === 'toggle') {
      updateDashboardFilter({
        exceptionCategoryList,
        featureTags,
        mustSatisfyAllTags,
        comment,
        name,
        filterId,
        enabled: !enabled});
    }
  }
  // 新增
  const createDashboardFilter = async(props) => {
    const res = await RestHelper.mustPost('/redir/api/dashboardFilter/createDashboardFilter', { appId,...props });
    getListDashboardFilters();
    setLoading(false);
  }
  // 更改
  const updateDashboardFilter = async(props) => {
    const res = await RestHelper.mustPost('/redir/api/dashboardFilter/updateDashboardFilter', { appId,...props });
    getListDashboardFilters();
    setLoading(false);
  }
  // 获取数据
  const getListDashboardFilters = async(props) => {
    const res = await RestHelper.mustPost('/redir/api/dashboardFilter/listDashboardFilters', { appId });
    setMarketStrategyData(res.json.data);
    setLoading(false);
  }
  // 删除数据
  const deleteDashboardFilters = async(props) => {
    const res = await RestHelper.mustPost('/redir/api/dashboardFilter/deleteDashboardFilters', { appId, ...props });
    getListDashboardFilters();
  }
  const handleCheckboxChange = (checkedValues) => {
    const newConfigOptions = configOptions.filter(item => item.exceptionCategories.some(every => checkedValues.includes(every)));
    setFilterConditionDropdown(newConfigOptions);
    form.resetFields(['featureTags']);
    setLoading(false);
  };
  return <div className={style.marketStrategy} spinning = {loading}>
    <div className={style.title}>
      <div>{ze('开启过滤策略后，概览、趋势页大盘统计数据将不包含过滤标签特征数据，但高级搜索和问题分析中可以查询过滤特征标签数据','After enabling the filtering strategy, the statistical data on the overview and trend pages will not include the filtered feature tag data. However, the filtered feature tag data can be queried in advanced search and issue analysis.')}</div>
      <div>
        <Button type="primary" disabled = {!hasAdminPermission} onClick={() => showModal()}>{ze('新增过滤规则','Add filter rules')}</Button>
      </div>
    </div>
    <div className={style.reminder}>
      <NoticeIcon />
      {ze('配置过滤策略前，请先','Before configuring filtering rules, please')}
      <a target="_blank" href={`/crash-reporting/feature-config/${appId}?pid=${platformId}`} style={{margin:'0 5px'}}>{ze('创建特征标签','create feature tags')}</a>
      {ze('通过特征进行过滤规则配置。','first and configure filtering rules based on features.')}
    </div>
    <Table columns={columns} dataSource={marketStrategyData} />
    <Modal title={ze('过滤规则','Filter rules')} open={isFilterDialogOpen} width={1000} onOk={closeFilterDialogFlagOk} onCancel={closeFilterDialogFlag}>
      <div>
        <Form form={form} onFinish={onFinish}>
          <div style={{display:'flex',gap:'10px'}}>
            <div
              style={{width:'20px',height:'20px',border:`3px solid ${CS_STYLES.PRIMARY_COLOR}`,borderRadius:'50%',color: CS_STYLES.PRIMARY_COLOR,display:'flex',alignItems:'center',justifyContent:'center'}}>1</div>
            <div>{ze('基础设置','Basic Settings')}</div>
          </div>
          <div style={{marginLeft:'10px'}}>{ze('过滤规则名称','Filter Rule Name')}</div>
          <Form.Item
            name='name'
            rules={[
              {
                required: true,
                message: ze('不能为空','Cannot be empty'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <div style={{display:'flex',gap:'10px', marginLeft:'10px', lineHeight:'31px'}}>
            <div>{ze('过滤异常类别','Filter Exception Categories')}</div>
            <Form.Item
              name='exceptionCategoryList'
              rules={[
                {
                  required: true,
                  message: ze('不能为空','Cannot be empty'),
                },
              ]}
            >
              <Checkbox.Group
                options={EXCEPTION_CATEGORIES_OPTIONS}
                onChange={handleCheckboxChange}
              />
            </Form.Item>
          </div>
          <div style={{marginLeft:'10px'}}>{ze('备注','comment')}</div>
          <Form.Item name='comment'>
            <Input />
          </Form.Item>
          <div style={{display:'flex',gap:'10px'}}>
            <div
              style={{width:'20px',height:'20px',border:`3px solid ${CS_STYLES.PRIMARY_COLOR}`,textAlign:'center',borderRadius:'50%',color: CS_STYLES.PRIMARY_COLOR,display:'flex',alignItems:'center',justifyContent:'center'}}>2</div>
            <div>{ze('过滤条件','Filter conditions')}</div>
          </div>

            <div style={{display:'flex',flexWrap:'wrap',justifyContent:'flex-start',alignItems:'flex-start',lineHeight:'32px'}}>
                {ze('满足以下','Filter when the following')}
                <Form.Item
                  name='mustSatisfyAllTags'
                  rules={[
                    {
                      required: true,
                      message: ze('不能为空','Cannot be empty'),
                    },
                  ]}
                >
                  <Select
                    style={{
                      flex: '1 1 auto',
                      width:'200px',
                      margin:'0 10px',
                    }}
                    options={[
                      { label: ze('任意', 'ANY'), value: false },
                      { label: ze('所有', 'ALL'), value: true },
                    ]}
                  />
                </Form.Item>
                {ze('特征时过滤，开启过滤策略后，概览、趋势页大盘统计数据将不包含过滤特征标签数据','feature tags are met. After the filtering strategy is enabled, the overview and trend page market statistics will not include the filtered tag data.')}
                <a href={`/crash-reporting/feature-config/${appId}?pid=${platformId}`} target="_blank" style={{marginLeft:'10px'}}>{ze('创建特征标签','create feature tags')}</a>
            </div>
          <Form.Item
            name='featureTags'
            rules={[
              {
                required: true,
                message: ze('不能为空','Cannot be empty'),
              },
            ]}
          >
            <Select mode="multiple" allowClear optionLabelProp='label'>
              {(filterConditionDropdown || []).map(item => <Option value={item.value} label={item.label}>{item.label} ({item.richLabel})</Option>)}
            </Select>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  </div>
}

DashboardFilters.propTypes = {
  reduxState: PropTypes.object,
  actions: PropTypes.object,
};

const mapStateToProps = state => ({
  reduxState: state,
});

export default connect(mapStateToProps)(DashboardFilters);
