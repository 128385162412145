import { isNotNullish, isNullish } from 'utils/nullish';
import { notification } from 'antd';
import { isPcOrLinux } from 'utils/platform';
import moment from 'moment';

export const AdvancedSearchUtil = Object.freeze({
  // 对齐和扩展es里issue的信息
  enrichIssueEsMap(pid, esMap) {
    if (isNullish(esMap)) {
      return {};
    }
    if (isNullish(pid)) {
      notification.error({ message: 'enrichIssueEsMap pid is null!' });
      return esMap;
    }
    let firstUploadTimeFormatted = '-';
    const { firstTimestamp, firstTime, issueErrorType, faultIp } = esMap;
    if (isPcOrLinux(pid)) {
      firstUploadTimeFormatted = moment(1000 * firstTimestamp).format('YYYY-MM-DD HH:mm:ss');
    } else {
      firstUploadTimeFormatted = firstTime;
    }
    const expName = isPcOrLinux(pid) ? faultIp : issueErrorType;

    return {
      firstUploadTimeFormatted,
      expName,
      ...esMap,
    };
  }
});