import React, { useState, useEffect } from 'react';
import { Tree, Progress, Tooltip   } from 'antd';
import { StarOutlined } from '@ant-design/icons';

const FlamethrowerTree = ({ data, currentDepth }) => {
  const [expandedKeys, setExpandedKeys] = useState([]);

  //默认展示前几层数据
  useEffect(() => {
    const generateExpandedKeys = (nodes, level) => {
      let keys = [];

      const traverse = (node, currentLevel) => {
        if (currentLevel === level) return;

        keys.push(node.name + node.value + node.offset + currentLevel);

        if (node.children && node.children.length > 0) {
          for (let i = 0; i < node.children.length; i++) {
            traverse(node.children[i], currentLevel + 1);
          }
        }
      };

      for (let i = 0; i < nodes.length; i++) {
        traverse(nodes[i], 1);
      }

      return keys;
    };

    if (data && data.children && data.children.length > 0) {
      const keys = generateExpandedKeys(data.children, data.forkStartLevel);
      setExpandedKeys(keys);
    }
  }, [data]);


  const handleExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
  };

  const highlightText = (text) => {
    const regex = /[(（]([^)）]+)[)）]/g; // 匹配中英文括号中的文本
    const highlightedText = text.replace(regex, '<span style="color: blue;">($1)</span>');
    return { original: text, highlighted: highlightedText };
  }

  const addColonAndNumberInsideParentheses = (inputString, number) => {
    const regex = /(\([^)]*\))$/; // 匹配最后一个括号里的内容
    const match = inputString.match(regex);

    if (match) {
      const lastParentheses = match[1];
      const updatedParentheses = lastParentheses.replace(/\)$/, `：${number})`); // 在最后一个括号里添加冒号和数字
      const result = inputString.replace(regex, updatedParentheses);

      return result;
    } else {
      return inputString; // 如果没有找到括号，则返回原始字符串
    }
  };

  const formatProgress = (percentage) => {
    if (percentage === 100) {
      return '100%';
    } else if (percentage > 99) {
      return '>99%';
    } else if (percentage >= 1 && percentage <= 99) {
      return `${Math.round(percentage)}%`;
    } else if (percentage < 1) {
      return '<1%';
    }
  };

  const renderTreeNodes = (data, level) =>
    data.map(node => {
      const key = node.name + node.value + node.offset + level; // 使用节点的名称作为唯一的 key
      const textData = highlightText(node.name);
      const shouldMarkStar = level <= currentDepth; // 是否需要标注五角星
      const title = (
        <Tooltip
          overlayStyle={{ maxWidth: '1000px' }}
          title={<div>{addColonAndNumberInsideParentheses(node.name,node.lineNumbers)}</div>}>
          <div style={{ display: 'flex' }}>
            <span style={{ paddingRight: '10px' }}> {shouldMarkStar && <StarOutlined />}#{level}</span>
            <Progress
              type="circle"
              width={30}
              percent={node.percentage}
              format={() => (
                <span style={{ color: 'black' }}>{formatProgress(node.percentage)}</span>
              )}
              style={{ paddingRight: '10px' }}
              strokeColor='blue'
            />
            <div dangerouslySetInnerHTML={{ __html: textData.highlighted }}></div>
          </div>
        </Tooltip>
      );
      if (node.children && node.children.length > 0) {
        return (
          <Tree.TreeNode key={key} title={title}>
            {renderTreeNodes(node.children, level + 1)}
          </Tree.TreeNode>
        );
      }
      return <Tree.TreeNode key={key} title={title} />;
    });

  return (
    <div>
      <Tree
        showLine
        defaultExpandAll
        expandedKeys={expandedKeys}
        onExpand={handleExpand}
      >
        {renderTreeNodes(data.children, 1)}
      </Tree>
    </div>
  );
};

export default FlamethrowerTree;
