import i18n from 'i18n.js';
import { ze, isZh } from 'utils/zhEn';
import { isAndroidOrHarmony, isIos, isMobile, isPcOrLinux } from 'utils/platform';
import isString from 'lodash/lang/isString';
import { DocsUtil } from 'utils/docs-util';
import { isNullish } from './nullish';
import { VersionUtil } from 'utils/version-util';
import MD5 from 'md5.js';
import { getBackendInjectEnvs } from 'utils/backend-inject-envs';

export const CrashAttachmentFileType = Object.freeze({
  OTHER_THREAD: 2,
});

const MOBILE_SDK_LOG_FILENAME = 'LogObserver.log.zip';
const PC_SDK_LOG_FILENAME = 'CustomizedLogFile.gz';
const PC_SDK_LOG_FILENAME_NEW_FORMAT = 'CustomizedLogFile.zip';
const SDK_ATTACK_FILENAME = 'CustomizedAttachFile.zip'; // SDK新增的直传cos的其他类附件（和log类附件区分）

const MINIDUMP_STACK_MEMORY_FILENAME = 'MinidumpStackMemory.txt';

const CUSTOM_LOG_COMMON_FILE_INFO = {
  alias: 'PrintLog.log',
  label: i18n.t('REPORTDETAIL.自定义日志来自接口'),
  dataSourceType: 'API',
  dataSourceDesc: 'PrintLog',
  noteMobileMax: ze('日志大小不超过30KB。','The log size should not exceed 30KB.'),
  notePcMax: ze('单条不超过4KB，总共不超过30KB。','The log size should not exceed 30KB.'),
  link: {
    android: isZh() ? DocsUtil.makeDocsUrl('/sdkDocuments/mobile-sdk/#55-自定义日志'): DocsUtil.makeDocsUrl('/sdkDocuments/mobile-sdk/#55-custom-log'),
    ios: isZh() ? DocsUtil.makeDocsUrl('/sdkDocuments/mobile-sdk/#38-自定义上报日志'): DocsUtil.makeDocsUrl('/sdkDocuments/mobile-sdk/#38-custom-reporting-log'),
    pc: isZh() ? DocsUtil.makeDocsUrl("/sdkDocuments/pc-sdk/#54-自定义接口日志"): DocsUtil.makeDocsUrl("/sdkDocuments/pc-sdk/#54-custom-interface-log") ,
    unity: isZh() ? DocsUtil.makeDocsUrl("/sdkDocuments/unity-sdk/#314-添加自定义日志"): DocsUtil.makeDocsUrl("/sdkDocuments/unity-sdk/#314--add-custom-log") ,
    unreal: isZh() ? DocsUtil.makeDocsUrl("/sdkDocuments/unreal-sdk/#313-自定义日志"): DocsUtil.makeDocsUrl("/sdkDocuments/unreal-sdk/#313-custom-log") ,
  },
};

const knownAttachmentFileInfoList = [{
  value: 'extraMessage.txt',
  alias: 'ExtraMessage.txt',
  dataSourceType: 'API',
  dataSourceDesc: 'OnCrashExtraMessageNotify',
  extraInfo:{
    tagMobileMax:'1MB',
    tagPcMax:'1MB',
    noteMobileMax:ze('KV长度之和不超过1MB，KV数不超过500对。','The sum of KV lengths should not exceed 1MB, and the number of KVs should not exceed 500.'),
    notePcMax:ze('KV长度之和不超过1MB，KV数不超过500对。','The sum of KV lengths should not exceed 1MB, and the number of KVs should not exceed 500.'),
  },
  extraMsg:{
    tagMobileMax:'128KB',
    tagPcMax:'128KB',
    noteMobileMax:ze('大小不超过128KB。','The size should not exceed 128KB.'),
    notePcMax:ze('大小不超过128KB。','The size should not exceed 128KB.'),
  },
  link:{
    android:isZh() ? DocsUtil.makeDocsUrl('/sdkDocuments/mobile-sdk/#56-设置回调'):  DocsUtil.makeDocsUrl('/sdkDocuments/mobile-sdk/#56-set-callback') ,
    ios:isZh() ? DocsUtil.makeDocsUrl('/sdkDocuments/mobile-sdk/#39-设置异常回调'): DocsUtil.makeDocsUrl('/sdkDocuments/mobile-sdk/#39-set-exception-callback'),
    unity: isZh() ? DocsUtil.makeDocsUrl("/sdkDocuments/unity-sdk/#316-设置回调"): DocsUtil.makeDocsUrl("/sdkDocuments/unity-sdk/#316-set-callback") ,
    unreal: isZh() ? DocsUtil.makeDocsUrl("/sdkDocuments/unreal-sdk/#314-设置回调"): DocsUtil.makeDocsUrl("/sdkDocuments/unreal-sdk/#314-set-callback") ,
  }
}, {
  value: 'extraData', //同userExtraByteData
  alias: 'ExtraData',
  dataSourceType: 'API',
  dataSourceDesc: 'OnCrashExtraDataNotify',
  noteAndroidMax: ze('附加数据长度不超过128KB。','The length of the additional data should not exceed 128KB.'),
  link: {
    android: isZh() ? DocsUtil.makeDocsUrl('/sdkDocuments/mobile-sdk/#56-设置回调'):  DocsUtil.makeDocsUrl('/sdkDocuments/mobile-sdk/#56-set-callback'),
    unity: isZh() ? DocsUtil.makeDocsUrl("/sdkDocuments/unity-sdk/#316-设置回调"): DocsUtil.makeDocsUrl("/sdkDocuments/unity-sdk/#316-set-callback") ,
    unreal: isZh() ? DocsUtil.makeDocsUrl("/sdkDocuments/unreal-sdk/#314-设置回调"): DocsUtil.makeDocsUrl("/sdkDocuments/unreal-sdk/#314-set-callback") ,
  }
}, {
  value: 'trace.zip',
  alias: 'Trace.zip',
  dataSourceDesc: ze('SDK采集的dump文件','The dump file captured by SDK'),
}, {
  value: 'crash_attach.log',//同extraMessage.txt, PC
  alias: 'CrashAttach.log',
  dataSourceType: 'API',
  dataSourceDesc: 'OnCrashExtraMessageNotify',
  extraInfo:{
    tagMobileMax:'1MB',
    tagPcMax:'1MB',
    noteMobileMax:ze('KV长度之和不超过1MB，KV数不超过500对。','The sum of KV lengths should not exceed 1MB, and the number of KVs should not exceed 500.'),
    notePcMax:ze('KV长度之和不超过1MB，KV数不超过500对。','The sum of KV lengths should not exceed 1MB, and the number of KVs should not exceed 500.'),
  },
  extraMsg:{
    tagMobileMax:'128KB',
    tagPcMax:'128KB',
    noteMobileMax:ze('大小不超过128KB。','The size should not exceed 128KB.'),
    notePcMax:ze('大小不超过128KB。','The size should not exceed 128KB.'),
  },
  link:{
    android:isZh() ? DocsUtil.makeDocsUrl('/sdkDocuments/mobile-sdk/#56-设置回调'):  DocsUtil.makeDocsUrl('/sdkDocuments/mobile-sdk/#56-set-callback') ,
    ios:isZh() ? DocsUtil.makeDocsUrl('/sdkDocuments/mobile-sdk/#39-设置异常回调'): DocsUtil.makeDocsUrl('/sdkDocuments/mobile-sdk/#39-set-exception-callback'),
    unity: isZh() ? DocsUtil.makeDocsUrl("/sdkDocuments/unity-sdk/#316-设置回调"): DocsUtil.makeDocsUrl("/sdkDocuments/unity-sdk/#316-set-callback") ,
    unreal: isZh() ? DocsUtil.makeDocsUrl("/sdkDocuments/unreal-sdk/#314-设置回调"): DocsUtil.makeDocsUrl("/sdkDocuments/unreal-sdk/#314-set-callback") ,
  }
}, {
  value: 'userExtraByteData',
  dataSourceType: 'API',
  dataSourceDesc: 'OnCrashExtraDataNotify',
  noteAndroidMax: ze('附加数据长度不超过128KB。','The length of the additional data should not exceed 128KB.'),
  link: {
    android: isZh() ? DocsUtil.makeDocsUrl('/sdkDocuments/mobile-sdk/#56-设置回调'):  DocsUtil.makeDocsUrl('/sdkDocuments/mobile-sdk/#56-set-callback'),
    unity: isZh() ? DocsUtil.makeDocsUrl("/sdkDocuments/unity-sdk/#316-设置回调"): DocsUtil.makeDocsUrl("/sdkDocuments/unity-sdk/#316-set-callback") ,
    unreal: isZh() ? DocsUtil.makeDocsUrl("/sdkDocuments/unreal-sdk/#314-设置回调"): DocsUtil.makeDocsUrl("/sdkDocuments/unreal-sdk/#314-set-callback") ,
  }
}, {
  value: 'valueMapOthers.txt',  // userKV
  alias: 'CustomizedData.txt',
  label: ze('自定义KV', 'Custom Key-Values'),
  dataSourceType: 'API',
  dataSourceDesc: 'AddSceneData / SetUserValue',
  tagMobileMax: '128KB',
  tagPcMax: '128KB',
  noteMobileMax:ze('KV长度之和不超过128KB，KV数不超过10000对，单个KV长度不超过100。','The sum of KV lengths should not exceed 128KB, the number of KVs should not exceed 10,000, and the length of the single KV should not exceed 100.'),
  notePcMax:ze('KV长度之和小于128KB。','The sum of KV lengths should should not exceed 128KB.'),
  link: {
    android: isZh() ? DocsUtil.makeDocsUrl('/sdkDocuments/mobile-sdk/#54-添加自定义数据'): DocsUtil.makeDocsUrl('/sdkDocuments/mobile-sdk/#54-add-custom-data'),
    ios: isZh() ? DocsUtil.makeDocsUrl('/sdkDocuments/mobile-sdk/#34-设置关键数据随崩溃信息上报'): DocsUtil.makeDocsUrl('/sdkDocuments/mobile-sdk/#34-set-key-data-reported-with-crash-info') ,
    pc: isZh() ? DocsUtil.makeDocsUrl("/sdkDocuments/pc-sdk#55-自定义kv"): DocsUtil.makeDocsUrl("/sdkDocuments/pc-sdk/#55-custom-kv") ,
    unity: isZh() ? DocsUtil.makeDocsUrl("/sdkDocuments/unity-sdk/#312-添加自定义数据"): DocsUtil.makeDocsUrl("/sdkDocuments/unity-sdk/#312-add-custom-data") ,
    unreal: isZh() ? DocsUtil.makeDocsUrl("/sdkDocuments/unreal-sdk/#312-添加自定义数据"): DocsUtil.makeDocsUrl("/sdkDocuments/unreal-sdk/#312-add-custom-data") ,
  },
}, {
  value: 'anrMessage.txt',
  alias: 'AnrMessage.txt',
  dataSourceDesc: ze('SDK采集的anr信息',' Anr message captured by SDK'),
}, {
  ...CUSTOM_LOG_COMMON_FILE_INFO,
  value: 'crashSightLog.log', // PC的自定义日志
  isBase64Encoded: true,
}, {
  ...CUSTOM_LOG_COMMON_FILE_INFO,
  value: 'crashsightlog.zip', // 安卓的自定义日志
  isBase64Encoded: false,
}, {
  ...CUSTOM_LOG_COMMON_FILE_INFO,
  value: 'crashsightlog.txt', // iOS的自定义日志
  isBase64Encoded: false,
}, {
  value: 'logFile.log', // PC的附件，最早的SDK后台上报版本。新版本SDK改成了SDK直传方式（CustomizedLogFile.zip和CustomizedLogFile.gz）
  alias: 'CustomizedLogFile.log',
  isBase64Encoded: true,
  label: i18n.t('REPORTDETAIL.自定义日志来自文件'),
  dataSourceType: 'API',
  dataSourceDesc: 'SetCustomLogDir',
  noteMobileMax:ze('压缩前不超过20MB，压缩后不超过10MB（上传过程中会对数据进行压缩），可通过云控和接口更改日志附件大小。',' The uncompressed size should not exceed 20MB, and the compressed size should not exceed 10MB (data will be compressed during the upload process). The size of log attachments can be changed through strategy and interface.'),
  notePcMax:ze('压缩前不超过5MB。','The uncompressed size should not exceed 5MB.'),
  link: {
    pc: isZh() ? DocsUtil.makeDocsUrl("/sdkDocuments/pc-sdk/#510-指定日志目录"): DocsUtil.makeDocsUrl("/sdkDocuments/pc-sdk/#510-specify-the-log-directory") ,
    unity: isZh() ? DocsUtil.makeDocsUrl("/sdkDocuments/unity-sdk/#317-设置上传日志回调"): DocsUtil.makeDocsUrl("/sdkDocuments/unity-sdk/#317-set-log-uploading-callback") ,
    unreal: isZh() ? DocsUtil.makeDocsUrl("/sdkDocuments/unreal-sdk/#316-设置日志上传回调"): DocsUtil.makeDocsUrl("/sdkDocuments/unreal-sdk/#316-set-log-uploading-callback") ,
  }
}, {
  value: 'custom_attachment.bin', // PS5用的附件文件
  alias: 'CustomAttachment.bin',
  dataSourceType: 'API',
  dataSourceDesc: 'SetLogPath',
  noteMax: ze('附件大小不超过1MB','The attachment file size should not exceed 1MB.'),
  link: {
    unity: isZh() ? DocsUtil.makeDocsUrl("/sdkDocuments/unity-sdk-new/#317-设置上传日志路径"): DocsUtil.makeDocsUrl("/unity-sdk-new/#317-set-upload-log-path") ,
    unreal: isZh() ? DocsUtil.makeDocsUrl("/sdkDocuments/unreal-sdk-new/#317-设置上传日志路径"): DocsUtil.makeDocsUrl("/unreal-sdk-new/#317-set-upload-log-path") ,
  }
}, {
  value: 'stackMemoryInfo.txt', // iOS栈信息
  alias: 'StackMemoryInfo.txt',
  dataSourceDesc: ze('SDK自动采集上报栈内存信息', 'The SDK automatically collects and reports stack memory information')
}, {
  value: MOBILE_SDK_LOG_FILENAME, // 这个是SDK直接上传的log,mobile附件
  dataSourceType: 'API',
  dataSourceDesc: 'SetCrashLogObserver',
  tagMobileMax: '20MB',
  tagPcMax: '5MB',
  tagMobileMaxAfter: '10MB',
  noteMobileMax: ze('压缩前不超过20MB，压缩后不超过10MB（上传过程中会对数据进行压缩），可通过云控和接口更改日志附件大小。',' The uncompressed size should not exceed 20MB, and the compressed size should not exceed 10MB (data will be compressed during the upload process). The size of log attachments can be changed through strategy and interface.'),
  notePcMax: ze('压缩前不超过20MB，压缩后不超过10MB','The uncompressed size should not exceed 20MB, and the compressed size should not exceed 10MB.'),
}, {
  value: PC_SDK_LOG_FILENAME, // SDK直接上传的log,pc附件
  isBase64Encoded: true,
  label: i18n.t('REPORTDETAIL.自定义日志来自文件'),
  dataSourceType: 'API',
  dataSourceDesc: 'SetCustomLogDir',
  noteMobileMax: ze('压缩前不超过20MB，压缩后不超过10MB（上传过程中会对数据进行压缩），可通过云控和接口更改日志附件大小。',' The uncompressed size should not exceed 20MB, and the compressed size should not exceed 10MB (data will be compressed during the upload process). The size of log attachments can be changed through strategy and interface.'),
  notePcMax: ze('压缩前不超过20MB，压缩后不超过10MB','The uncompressed size should not exceed 20MB, and the compressed size should not exceed 10MB.'),
  link: {
    pc: isZh() ? DocsUtil.makeDocsUrl("/sdkDocuments/pc-sdk/#510-指定日志目录"): DocsUtil.makeDocsUrl("/sdkDocuments/pc-sdk/#510-specify-the-log-directory") ,
    unity: isZh() ? DocsUtil.makeDocsUrl("/sdkDocuments/unity-sdk/#317-设置上传日志回调"): DocsUtil.makeDocsUrl("/sdkDocuments/unity-sdk/#317-set-log-uploading-callback") ,
    unreal: isZh() ? DocsUtil.makeDocsUrl("/sdkDocuments/unreal-sdk/#316-设置日志上传回调"): DocsUtil.makeDocsUrl("/sdkDocuments/unreal-sdk/#316-set-log-uploading-callback") ,
  }
}, {
  value: PC_SDK_LOG_FILENAME_NEW_FORMAT, // SDK直接上传的log,pc附件（新的zip格式）
  isBase64Encoded: true,
  label: i18n.t('REPORTDETAIL.自定义日志来自文件'),
  dataSourceType: 'API',
  dataSourceDesc: 'SetCustomLogDir',
  noteMobileMax: ze('压缩前不超过20MB，压缩后不超过10MB（上传过程中会对数据进行压缩），可通过云控和接口更改日志附件大小。',' The uncompressed size should not exceed 20MB, and the compressed size should not exceed 10MB (data will be compressed during the upload process). The size of log attachments can be changed through strategy and interface.'),
  notePcMax: ze('压缩前不超过20MB，压缩后不超过10MB','The uncompressed size should not exceed 20MB, and the compressed size should not exceed 10MB.'),
  link: {
    pc: isZh() ? DocsUtil.makeDocsUrl("/sdkDocuments/pc-sdk/#510-指定日志路径"): DocsUtil.makeDocsUrl("/sdkDocuments/pc-sdk/#510-specify-the-log-directory") ,
    unity: isZh() ? DocsUtil.makeDocsUrl("/sdkDocuments/unity-sdk/#44-设置自定义日志路径"): DocsUtil.makeDocsUrl("/sdkDocuments/unity-sdk/#44-set-custom-log-path") ,
    unreal: isZh() ? DocsUtil.makeDocsUrl("/sdkDocuments/unreal-sdk/#44-设置自定义日志路径"): DocsUtil.makeDocsUrl("/sdkDocuments/unreal-sdk/#44-set-custom-log-path") ,
  },
}, {
  value: SDK_ATTACK_FILENAME, // SDK新增的直传cos的其他类附件（和log类附件区分）
  isBase64Encoded: true,
  dataSourceType: 'API',
  dataSourceDesc: 'SetCustomAttachDir',
  noteMobileMax: ze('压缩前不超过20MB，压缩后不超过10MB（上传过程中会对数据进行压缩），可通过云控和接口更改日志附件大小。',' The uncompressed size should not exceed 20MB, and the compressed size should not exceed 10MB (data will be compressed during the upload process). The size of log attachments can be changed through strategy and interface.'),
  notePcMax: ze('压缩前不超过20MB，压缩后不超过10MB','The uncompressed size should not exceed 20MB, and the compressed size should not exceed 10MB.'),
  link: {
    pc: isZh() ? DocsUtil.makeDocsUrl("/sdkDocuments/pc-sdk/#526-指定附件路径"): DocsUtil.makeDocsUrl("/sdkDocuments/pc-sdk/#526-specify-the-attachment-directory") ,
    unity: isZh() ? DocsUtil.makeDocsUrl("/sdkDocuments/unity-sdk/#47-设置自定义附件路径"): DocsUtil.makeDocsUrl("/sdkDocuments/unity-sdk/#47-set-custom-attachment-path") ,
    unreal: isZh() ? DocsUtil.makeDocsUrl("/sdkDocuments/unreal-sdk/#415-设置自定义附件路径"): DocsUtil.makeDocsUrl("/sdkDocuments/unreal-sdk/#415-set-custom-attachment-path") ,
  },
}, {
  value: MINIDUMP_STACK_MEMORY_FILENAME,
  alias: MINIDUMP_STACK_MEMORY_FILENAME,
  dataSourceDesc: ze('Minidump栈内存信息', 'Minidump Stack Memory Info'),
}, {
  value: 'Minidump.dmp.gz', // minidump文件
  alias: 'Minidump.dmp.gz',
  dataSourceDesc: ze('崩溃时自动采集的minidump文件', 'The SDK automatically collects and reports Minidump file on crash'),
}, {
  value: 'PsAttachments.zip', // ps5的附件
  alias: 'PsAttachments.zip',
  dataSourceDesc: ze('SIE Recap Server', 'SIE Recap Server'),
}];

export const getHelpLink = (subAppType,pid,fileName) => {
  let helpLink = null;
  const fileInfo = CrashAttachmentUtil.getInfoByFilename(fileName);
  if(fileInfo && fileInfo.link){
    // 先根据引擎获取
    if(subAppType){
      if(subAppType === 'UNITY'){
        helpLink = fileInfo.link.unity;
      }else if(subAppType === 'UE4' || subAppType === 'UE5'){
        helpLink = fileInfo.link.unreal;
      }
    }
    // 再根据平台获取
    if(isNullish(helpLink)){
      if(isAndroidOrHarmony(pid)){
        helpLink = fileInfo.link.android;
      }else if(isIos(pid)){
        helpLink = fileInfo.link.ios;
      }else if(isPcOrLinux(pid)){
        helpLink = fileInfo.link.pc;
      }
    }
  }
  return helpLink;
}

const knownAttachmentFilenameToInfo = Object.assign({}, ...knownAttachmentFileInfoList.map(x => ({ [x.value]: x })));

/** @type {string[]} */
const knownAttachmentFilenames = knownAttachmentFileInfoList.map(x => x.value);

export const CrashAttachmentUtil = Object.freeze({
  MINIDUMP_STACK_MEMORY_FILENAME,

  getSdkLogFilename(pid, sdkVersion) {
    if (isMobile(pid)) {
      return MOBILE_SDK_LOG_FILENAME;
    } else {
      return this.checkIsZipFormatPcSdkLogFile(sdkVersion) ? PC_SDK_LOG_FILENAME_NEW_FORMAT : PC_SDK_LOG_FILENAME;
    }
  },

  getSdkAttachFilename(pid, sdkVersion) {
    return SDK_ATTACK_FILENAME;
  },

  /** 是否是新的PC客户端直传附件格式。2.2.4版本开始从.gz改成了.zip。 */
  checkIsZipFormatPcSdkLogFile(sdkVersion) {
    return VersionUtil.compareCsSdkVersion(sdkVersion, '2.2.4') >= 0;
  },

  /** 对于SDK直传的文件，生成下载用的axios get参数 */
  makeRequestParamsForSdkUploadedFile(filename, appId, pid, crashMap) {
    const { expUid, productVersion: crashVersion, sdkVersion } = crashMap || {};
    if (!expUid || !crashVersion) {
      console.warn('asyncMakeRequestParamsForSdkUploadedFile, no expUid or crashVersion provided!');
      return null;
    }

    let isAttachNotLog;
    if (this.getSdkLogFilename(pid, sdkVersion) === filename) {
      isAttachNotLog = false;
    } else if (this.getSdkAttachFilename(pid, sdkVersion) === filename) {
      isAttachNotLog = true;
    } else {
      throw Error(`asyncMakeRequestParamsForSdkUploadedFile, invalid filename = ${filename}!`);
    }

    const expUidWithoutHyphenLowerCase = expUid.replace(/-/g, '').toLowerCase();
    const expUidPrefix = expUidWithoutHyphenLowerCase.slice(0, 16);

    // TODO：Android SDK 4.2.9版本的MD5 Hex计算有bug，Hex的前导0被全部丢掉了
    const versionMd5Prefix = (sdkVersion || '').startsWith('4.2.9') && isAndroidOrHarmony(pid)
      ? new MD5().update(crashVersion).digest('hex').replace(/^0+/g, '').slice(0, 8)
      : new MD5().update(crashVersion).digest('hex').slice(0, 8);

    const encryptKey = expUidWithoutHyphenLowerCase;
    const encryptKeyMd5Hex = new MD5().update(encryptKey).digest('hex');

    function hexToBytes(hex) {
      let bytes = [];
      for (let c = 0; c < hex.length; c += 2) {
        bytes.push(parseInt(hex.substr(c, 2), 16));
      }
      return bytes;
    }
    function bytesToBase64(bytes) {
      return window.btoa(String.fromCharCode(...bytes));
    }

    const urlOrigin = getBackendInjectEnvs().cosSdkLogsDownloadOrigin;
    const isAmazonAws = urlOrigin.includes('amazonaws.com');
    const headerStorage = isAmazonAws ? 'amz' : 'cos';

    const filenameSuffix = isAttachNotLog ? '-attach' : '';

    let url;
    if (isMobile(pid)) {
      url = `${urlOrigin}/${expUidPrefix}/${appId}/${versionMd5Prefix}${filenameSuffix}.zip`;
    } else {
      url = CrashAttachmentUtil.checkIsZipFormatPcSdkLogFile(sdkVersion)
        ? `${urlOrigin}/${expUidPrefix}-${appId}-${versionMd5Prefix}${filenameSuffix}.zip`
        : `${urlOrigin}/${expUidPrefix}-${appId}-${versionMd5Prefix}${filenameSuffix}.gz`;
    }

    const config = {
      responseType: 'arraybuffer',
      headers: {
        [`x-${headerStorage}-server-side-encryption-customer-algorithm`]: 'AES256',
        [`x-${headerStorage}-server-side-encryption-customer-key`]: window.btoa(encryptKey),
        [`x-${headerStorage}-server-side-encryption-customer-key-MD5`]: bytesToBase64(hexToBytes(encryptKeyMd5Hex)),
      },
    };
    return [url, config];
  },

  getKnownAttachmentFileInfoList() {
    return knownAttachmentFileInfoList;
  },

  getKnownAttachmentFilenames() {
    return knownAttachmentFilenames;
  },

  getInfoByFilename(filename) {
    return knownAttachmentFilenameToInfo[filename];
  },

  getFileAliasByFilename(filename) {
    return knownAttachmentFilenameToInfo[filename]?.alias ?? filename;
  },

  refineFileContent(pid, filename, content) {
    if (!isMobile(pid) || filename !== 'valueMapOthers.txt') {
      return content;
    }
    if (!isString(content)) {
      return content;
    }
    // 移动端的valueMapOthers展示的时候，要把分号分割改成换行分割，然后去掉C03之类前缀
    return (content || '')
      .split(';')
      .filter(x => !isMobile(pid) || /^(C03|C06)/.test(x)) // 移动端只保留C03, C06开头的，其他全部过滤
      .map(x => x.replace(/^(C03_|C04_|C06)/, ''))
      .join('\n');
  },
});
