import { useTranslation } from 'react-i18next';

export function useZhEn() {
  const { i18n } = useTranslation();

  const ze = (zh, en) => {
    const isZh = i18n.language.includes('zh');
    return isZh ? zh : en;
  };

  return {
    ze,
  };
}
