import authRest from 'utils/authRest';

// 授权
export function accredit(authConfig) {
  return (dispatch, getState) => {
    const currentApp = getState().app.get('current');
    return dispatch({
      rest: authRest.auth.accredit,
      data: Object.assign({}, authConfig, { appId: currentApp.get('appId') }),
    });
  };
}

// 取消授权
export function unaccredit(appId, pid, thirdpartyId) {
  return (dispatch) => {
    return dispatch({
      rest: authRest.auth.unaccredit,
      data: { appId, pid, thirdpartyId },
    });
  };
}

// 获取授权产品的基本信息
export function getAuthAppInfo(appId, pid) {
  return (dispatch) => {
    return dispatch({
      rest: authRest.auth.info,
      data: { appId, pid },
    });
  };
}
