import { Modal,Button } from 'antd';
import { ze } from 'utils/zhEn';
import { isNotNullish } from 'utils/nullish';
import moment from 'moment';
import React, { useState, createContext, useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';
import NoticeIcon from 'svg/v2/newcs_dashboard_notice_icon.svg';
const SKIP_CONFIRM_EXPIRE_KEY = 'CsSkipComplianceConfirmExpireMillis';

export const dataComplianceConfirm = function () {
  const zh = '  数据可能包含用户隐私数据内容，仅提供给有权限访问的授权人，请您谨慎处理，避免造成不必要的传播风险。（确认后30天内不再提示）';
  const en = "  The data may contain user privacy data content and will only be provided to authorized persons with access rights, so please handle with caution to avoid unnecessary risks of dissemination. (No more prompts within 30 days after confirmation)";

  const storageValue = localStorage.getItem(SKIP_CONFIRM_EXPIRE_KEY);
  const skipConfirmExpireMillis = storageValue && Number(storageValue);
  if (isNotNullish(skipConfirmExpireMillis) && skipConfirmExpireMillis > +new Date()) {
    return Promise.resolve();
  }

  return new Promise((resolve, reject) => {

    const ModalComponent = () => {
      const [visible, setVisible] = useState(true);

      const handleOk = () => {
        localStorage.setItem(SKIP_CONFIRM_EXPIRE_KEY, String(moment().add(30, 'days').valueOf()));
        setVisible(false);
        resolve();
      };

      const handleCancel = () => {
        setVisible(false);
        reject();
      };
      return (
        <Modal
          visible={visible}
          width="675px"
          title={ze('数据下载分享风险提示', 'Data Download Sharing Risk Alert')}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="cancel" onClick={handleCancel}>
              {ze('取消','Cancel')}
            </Button>,
            <Button key="ok" type="primary" onClick={handleOk}>
              {ze('确认','OK')}
            </Button>,
          ]}
        >
          <div style={{display:'flex',alignItems:'flex-start',gap:'4px'}}>
            <div style={{transform: 'rotate(180deg)',marginTop:'-2px'}}><NoticeIcon /></div>
            <div>{ze(zh, en)}</div>
          </div>

        </Modal>
      );
    };
    const div = document.createElement('div');
    document.body.appendChild(div);
    ReactDOM.render(<ModalComponent />, div);

    // Modal.confirm({
    //   width:'50%',
    //   title: ze('数据下载分享风险提示','Data Download Sharing Risk Alert'),
    //   content: ze(zh,en),
    //   onOk () {
    //     localStorage.setItem(SKIP_CONFIRM_EXPIRE_KEY, String(moment().add(30, 'days').valueOf()));
    //     resolve();
    //   },
    // });
  });
}