import React, { useState, useEffect, useMemo } from 'react';
import { useImmer } from 'use-immer';
import PropTypes from 'prop-types';
import RestHelper from 'utils/RestHelper';
import {
  Form,
  Modal,
  Input,
  Select,
  Row,
  Col,
  Spin,
  Button,
  notification,
  AutoComplete,
  Divider,
  Radio,
  Checkbox,
  Space, Tooltip,
} from 'antd';
import { connect, useSelector } from 'react-redux';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { red } from '@ant-design/colors';
import isString from 'lodash/lang/isString';
import { isNotNullish, isNullish } from 'utils/nullish';
import { useCrashsightLocalStorage } from 'utils/useCrashSightLocalStorage';
import uniq from 'lodash/array/uniq';
import { getIssueDetailUrlPrefix } from 'utils/helper';
import { UserTypeEnum } from 'utils/constants/user-type-enum';
import { TapdTitleTemplateUtil } from 'components/exception/issue/TapdTitleTemplateEditor';
import { ze } from 'utils/zhEn';
import cloneDeep from 'lodash/lang/cloneDeep';
import scss from './TapdModal.scss';
import TapdEditBugCustomFieldsModal, { TapdEditBugCustomFieldsUtil } from 'components/exception/issue/TapdEditBugCustomFieldsModal';
import { useTranslation } from 'react-i18next';
import TapdModelEditTitleTemplateModel from 'components/exception/issue/TapdModelEditTitleTemplateModel';
import isArray from 'lodash/lang/isArray';
import { EnvUtil } from 'utils/env-util';
import { Base64 } from 'utils/base64';
import { CrashUtil } from 'utils/api/crash';
import { getReportCategoryByExceptionTypeInt } from 'utils/constants/exception-type-int';
import { selectHasSpecifiedPermissionInCurrentApp } from 'utils/selectors/selectors';
import { RolePermission } from 'utils/constants/role-permission';
import { CrashAttachmentUtil } from 'utils/crash-attachment-util';
import { CsSelect } from 'components/antd-extension/CsSelect';
import { ServerAppSettings } from 'utils/server-app-settings';

const { TextArea } = Input;

const TAPD_SEVERITY_LIST = [
  { value: 'fatal', label: ze('致命', 'Fatal') },
  { value: 'serious', label: ze('严重', 'Serious') },
  { value: 'normal', label: ze('一般', 'Normal') },
  { value: 'prompt', label: ze('提示', 'Prompt') },
  { value: 'advice', label: ze('建议', 'Advice') },
];

const JIRA_PRIORITY_LIST = [
  { value: '1', label: ze('最高', 'Highest') },
  { value: '2', label: ze('高', 'High') },
  { value: '3', label: ze('中等', 'Medium') },
  { value: '4', label: ze('低', 'Low') },
  { value: '5', label: ze('最低', 'Lowest') },
];

const TAPD_PRIORITY_LIST = [
  { value: 'urgent', label: ze('紧急', 'Urgent') },
  { value: 'high', label: ze('高', 'High') },
  { value: 'medium', label: ze('中', 'Medium') },
  { value: 'low', label: ze('低', 'Low') },
  { value: 'insignificant', label: ze('无关紧要', 'Insignificant') },
];

const MAX_TITLE_LENGTH = 200;

const TAB_CREATE_BUG = 'TAB_CREATE_BUG';
const TAB_BIND_BUG = 'TAB_BIND_BUG';

/**
 * 向一个array-like queue的头部添加一个元素（string）。
 * 返回新的queue。原来的queue内容不受影响。
 * 如果queue长度超过指定上限，则从末尾弹出多余元素
 * @param {string[]} queue
 * @param {string} pushValue
 * @param {number} queueLengthLimit
 * @param {boolean} removeDuplicate
 */
function leftPushValueToLimitedLengthQueue(queue, pushValue, queueLengthLimit, removeDuplicate) {
  let res = [pushValue, ...queue];
  if (removeDuplicate) {
    res = uniq(res);
  }
  if (res.length > queueLengthLimit) {
    res = res.filter((_, i) => i < queueLengthLimit);
  }
  return res;
}


const TapdModal = (props) => {
  const { t } = useTranslation();
  const { reduxState, issueInfoList, modalProps } = props;
  const modalVisible = modalProps.visible;
  const currentApp = reduxState.app.get('current').toJS();
  const { appId, platformId } = currentApp;
  const currentUser = reduxState.user.get('current').toJS();
  const { nickname: userNickname } = currentUser;

  const hasViewPersonalDataPermission = useSelector(state => selectHasSpecifiedPermissionInCurrentApp(state, RolePermission.VIEW_PERSONAL_DATA));
  const hasAdminPermission = useSelector(state => selectHasSpecifiedPermissionInCurrentApp(state, RolePermission.EDIT));

  const settings = reduxState.app.get('appIdToWebAppSettings')[appId];
  const titleTemplateFromSettings = (settings || {})[TapdTitleTemplateUtil.getSettingKey()]
    || TapdTitleTemplateUtil.makeDefaultValue(platformId);

  const bugTrackingSimplifiedInfo = useMemo(() => reduxState.app.get('appIdToBugTrackingSimplifiedInfo')?.[appId],
    [reduxState.app, appId]);
  const { tapdWorkspaceId, jiraProjectId } = bugTrackingSimplifiedInfo || {};
  const workspaceId = tapdWorkspaceId;
  const workspaceIdLoading = isNullish(bugTrackingSimplifiedInfo);

  const enabledBugCustomFieldsFromSettings = (settings || {})[TapdEditBugCustomFieldsUtil.getSettingKey()] || [];

  const serverAppSettings = reduxState.app.get('appIdToServerAppSettings')[appId] || {};
  const tapdFieldList = serverAppSettings[ServerAppSettings.keys.tapdFieldList];
  const enabledFieldList = TapdEditBugCustomFieldsUtil.getEnabledFieldList(tapdFieldList, enabledBugCustomFieldsFromSettings);
  const enabledBuiltInFieldList = enabledFieldList.filter(x => !TapdEditBugCustomFieldsUtil.checkIsCustomField(x.field));
  const enabledCustomFieldList = enabledFieldList.filter(x => TapdEditBugCustomFieldsUtil.checkIsCustomField(x.field));
  const enabledBuiltInFieldKeyToInfo = Object.assign(Object.create(null), ...enabledBuiltInFieldList.map(x => ({ [x.field]: x })));

  // 默认bug创建人，直接取昵称
  const defaultReporter = userNickname;

  const isMultipleIssues = issueInfoList && issueInfoList.length > 1;

  const redirectToTapdConfigPage = () => {
    window.open(`/crash-reporting/preferences/tapd/${appId}?pid=${platformId}`, '_blank');
  };

  const [jiraIdLoading, setJiraIdLoading] = useState(true);
  const [bondedJiraProject, setBondedJiraProject] = useState(null)
  const [jiraUserList, setJiraUserList] = useState([]);
  const [jiraTypesList, setJiraTypesList] = useState([])
  const [usersLoading, setUsersLoading] = useState(true)
  const [typesLoading, setTypesLoading] = useState(true)

  const [tabType, setTabType] = useState(TAB_CREATE_BUG);

  const [issueInfo, updateIssueInfo] = useImmer({});

  const [form, updateForm] = useImmer({});

  const [formUpdateTrigger, setFormUpdateTrigger] = useState(0);

  const [formSubmitTrigger, setFormSubmitTrigger] = useState(0);

  const [loading, setLoading] = useState(false);

  const [formInstance] = Form.useForm();

  const [iterationList, setIterationList] = useState([]);

  const [versionList, setVersionList] = useState([]);

  const [releaseList, setReleaseList] = useState([]);

  const [tapdModuleList, setTapdModuleList] = useState([]);

  const [workspaceUserOptions, setWorkspaceUserOptions] = useState([]);

  const [bugCustomFields, setBugCustomFields] = useState([]);

  const [attachmentFilenameOptions, setAttachmentFilenameOptions] = useState([]);
  const [attachmentOptionsLoaded, setAttachmentOptionsLoaded] = useState(false);
  const [attachmentOptionsLoading, setAttachmentOptionsLoading] = useState(false);

  const [tapdTitleTemplateModalVisible, setTapdTitleTemplateModalVisible] = useState(false);
  const [tapdEditBugCustomFieldsModalVisible, setTapdEditBugCustomFieldsModalVisible] = useState(false);

  const [storage, updateStorage] = useCrashsightLocalStorage();

  // 提单人和处理人，需要在浏览器端记忆最近5次确认表单时填写的人员
  const { tapdReporterHistory } = storage;
  const reporterOptions = tapdReporterHistory.map(x => ({ name: x, value: x }));
  const MAX_REPORTER_HISTORY_LENGTH = 5;
  const pushReporter = (v) => {
    updateStorage((draft) => {
      draft.tapdReporterHistory = leftPushValueToLimitedLengthQueue(
        draft.tapdReporterHistory,
        v,
        MAX_REPORTER_HISTORY_LENGTH,
        true,
      );
    });
  };

  function makeInitialPartialForm(issueInfo, isMultipleIssues) {
    return {
      ...(!isMultipleIssues && makeAutoCompletedTapdBugInfoFromIssueInfo(issueInfo)),
      ...(isMultipleIssues && { versionReport: null }),
      // severity: 'serious',
      reporter: defaultReporter,
      // priority: null,
      // iterationId: null,

      includeAttachments: false,
      attachmentFilenameList: [],
    };
  }

  // 返回根据issueinfo自动构造的tapd bug info的部分
  function makeAutoCompletedTapdBugInfoFromIssueInfo(issueInfo) {
    const { exceptionName, keyStack, issueId, lastestUploadTime, imeiCount, count, issueExceptionType } = issueInfo;
    const detailInfo = issueInfo.detailInfo || {};
    const {
      retraceCrashDetail,
      crashId,
    } = detailInfo;

    const issueDetailUrlPrefix = getIssueDetailUrlPrefix(getReportCategoryByExceptionTypeInt(issueExceptionType));
    const crashIdUrlSuffix = crashId ? `/report-id/${crashId}` : '';
    const issueUrl = `${window.location.origin}/${issueDetailUrlPrefix}/${appId}/${issueId}${crashIdUrlSuffix}?pid=${platformId}`;

    const crashStackLines = `<pre>${String(retraceCrashDetail || '')}</pre>`;

    const description =
      `Issue ID: ${issueId}
<br><br>
${ze('异常详情地址', 'Web URL')}: <a href='${issueUrl}'>${issueUrl}</a>
<br><br>
${ze('最近上报时间', 'Last Reported Time')}: ${lastestUploadTime}
<br><br>
${t('REPORTDETAIL.异常进程')}#${t('REPORTDETAIL.异常线程')}: ${detailInfo.processName || ''}#${detailInfo.threadName || ''}
<br><br>
${t('REPORTDETAIL.expMessage')}：${detailInfo.expMessage || ''}
<br><br>
${t('issueCrashFilterKey.crashDetail')}:
<br>${crashStackLines}
<br><br>
${t('issueCrashFilterKey.crashTime')}: ${detailInfo.crashTimeFormatted}<br>
${t('issueCrashFilterKey.uploadTime')}: ${detailInfo.uploadTimeFormatted}<br>
${t('REPORTDETAIL.apkName')}: ${detailInfo.bundleId}<br>
${t('REPORTDETAIL.version')}: ${detailInfo.productVersion}<br>
${t('REPORTDETAIL.hardware')}: ${detailInfo.hardware}<br>
${t('REPORTDETAIL.osVersion')}: ${detailInfo.osVersion}<br>
${t('CRASHDETAIL.totalReports')}：${count}<br>
${t('CRASHDETAIL.totalDevices')}：${imeiCount}<br>
`;

    const enrichedIssueInfo = { ...issueInfo, platformId };
    return {
      title: TapdTitleTemplateUtil.makeTitleFromTemplate(titleTemplateFromSettings, enrichedIssueInfo),
      description,
    };
  }

  function makeFinalTapdBugForSubmit(issueInfo, form, isMultipleIssues, extraDescription) {
    const tapdBugIdForUpdate = (issueInfo.bugs && issueInfo.bugs[0] && issueInfo.bugs[0].id) || null;
    let tapdBug;
    if (!isMultipleIssues) {
      tapdBug = {
        status: 'new',
        ...(tapdBugIdForUpdate && { id: tapdBugIdForUpdate }),
        ...form,
      };
    } else {
      tapdBug = {
        status: 'new',
        ...(tapdBugIdForUpdate && { id: tapdBugIdForUpdate }),
        ...form,
        ...makeAutoCompletedTapdBugInfoFromIssueInfo(issueInfo),
      };
    }
    if (extraDescription) {
      tapdBug = {
        ...tapdBug,
        description: tapdBug.description + extraDescription,
      };
    }

    // title和description用base64编码
    tapdBug = {
      ...tapdBug,
      title: undefined,
      description: undefined,
      titleBase64: Base64.encodeAsString(tapdBug.title || ''),
      descriptionBase64: Base64.encodeAsString(tapdBug.description || ''),
    };
    return tapdBug;
  }

  // 拉取jiraId
  async function fetchJiraId() {
    setJiraIdLoading(true)
    const rsp = await RestHelper.mustPost('/redir/query/jira/getBindedJiraProject', { appId, platformId });
    const data = rsp.json.data;
    setBondedJiraProject(data);
    setJiraIdLoading(false);
  }

  function makeSingleFieldOptionsFromTapdFieldsInfoResult(data, field) {
    if (!data || !data[field]) {
      return [];
    }
    const optionMap = data[field].options || {};
    return Object.keys(optionMap).map(key => ({ value: key, label: optionMap[key] }));
  }

  async function fetchTapdFieldsInfoAndSetState() {
    const rsp = await RestHelper.post('/redir/query/data/queryTapdFieldsInfo', {
      appId,
      platformId,
    });
    if (!rsp.json.data) {
      return;
    }
    const data = rsp.json.data;
    setIterationList(makeSingleFieldOptionsFromTapdFieldsInfoResult(data, 'iteration_id'));
    setReleaseList(makeSingleFieldOptionsFromTapdFieldsInfoResult(data, 'release_id'));
    setVersionList(makeSingleFieldOptionsFromTapdFieldsInfoResult(data, 'version_report'));
    setTapdModuleList(makeSingleFieldOptionsFromTapdFieldsInfoResult(data, 'module'));
  }

  // 拉取TAPD项目人员列表并setState
  async function queryWorkspaceUsers() {
    const rsp = await RestHelper.post('/redir/query/data/queryWorkspaceUsers', {
      appId,
      platformId,
    });
    isArray(rsp.json.data) && setWorkspaceUserOptions(rsp.json.data.map(x => ({
      value: x.user,
      label: x.user,
    })));
  }

  async function queryBugCustomFields() {
    const rsp = await RestHelper.mustPost('/redir/query/data/queryBugCustomFields', {
      appId,
      platformId,
    });
    setBugCustomFields(rsp.json.data);
  }

  const [resetFormTrigger, setResetFormTrigger] = useState(0);
  useEffect(() => {
    if (!resetFormTrigger) {
      return;
    }
    formInstance.resetFields();
  }, [resetFormTrigger]);
  function onSaveBugCustomFields() {
    setTapdEditBugCustomFieldsModalVisible(false);
    const initialForm = makeInitialPartialForm(issueInfo, isMultipleIssues); // 保存设定后，重置表单（不保留用户填写字段）
    updateForm(initialForm);
    setResetFormTrigger(resetFormTrigger + 1);
  }

  async function fetchAttachments() {
    const { detailInfo } = issueInfo;
    if (!detailInfo) {
      notification.error({ message: '附件信息获取失败。无法获取Crash ID' });
      return;
    }
    const { crashId } = detailInfo;
    setAttachmentOptionsLoading(true);
    const rsp = await RestHelper.get(`/appDetailCrash/appId/${appId}/platformId/${platformId}/crashHash/${crashId}`);
    const filenameWhitelist = [
      ...CrashAttachmentUtil.getKnownAttachmentFilenames(),
      'userExtraByteData',
      'crash_attach.log',
    ];
    const uniqueFilenames = uniq((rsp.json.ret.attachList || [])
      .map(x => x.fileName)
      .filter(x => filenameWhitelist.includes(x)));
    const filenameOptions = uniqueFilenames.map(x => ({ label: CrashAttachmentUtil.getFileAliasByFilename(x), value: x }));
    setAttachmentFilenameOptions(filenameOptions);
    formInstance.setFieldsValue({ attachmentFilenameList: filenameOptions.map((options) => options.value) });
    updateForm(draft => ({ ...draft, attachmentFilenameList: filenameOptions.map((options) => options.value) }));
    setAttachmentOptionsLoading(false);
    setAttachmentOptionsLoaded(true);
  }

  // 拉取迭代信息
  useEffect(() => {
    if (!modalVisible) {
      return;
    }
    if(!bondedJiraProject){
      fetchJiraId();
    }
    if (tapdWorkspaceId) {
      fetchTapdFieldsInfoAndSetState();
    }
    queryWorkspaceUsers();
    queryBugCustomFields();
  }, [modalVisible]);

  // 打开modal的时候，设置内部用的issue信息，并设置form触发器
  useEffect(() => {
    (async () => {
      if (!modalVisible) {
        return;
      }
      if (!issueInfoList || !issueInfoList.length) {
        notification.error({ message: '未拉取到bug info' });
        console.error('打开tapdmodal的时候未设置issueInfoList');
      }

      let newIssueInfo = issueInfoList[0];
      updateIssueInfo((draft) => {
        Object.assign(draft, newIssueInfo);
      });
      // 如果在issue列表那边提bug，issueInfoList里面是没有detailInfo（最近一次crash的信息）的，需要拉一下
      if (!isMultipleIssues && !newIssueInfo.detailInfo) {
        setLoading(true);
        const issueId = newIssueInfo.issueId;
        const rsp = await RestHelper.mustPost('/api/crash/fetchLastCrashDocInIssue', { appId, platformId, issueId });
        updateIssueInfo((draft) => {
          draft.detailInfo = CrashUtil.makeRefinedCrashMap(rsp.json.data.crashMap);
        });
        setLoading(false);
      }
      setFormUpdateTrigger(formUpdateTrigger + 1);
    })();
  }, [modalVisible]);

  // 关闭modal的时候，重置部分state
  useEffect(() => {
    if (modalVisible) {
      return;
    }
    setTabType(TAB_CREATE_BUG);
    setAttachmentFilenameOptions([]);
    setAttachmentOptionsLoaded(false);
  }, [modalVisible]);

  useEffect(() => {
    if (!formUpdateTrigger) { // skip first render
      return;
    }

    // 只替换部分字段，保留部分填写过的字段
    updateForm((draft) => {
      const newDraft = {
        ...draft,
        ...makeInitialPartialForm(issueInfo, isMultipleIssues),
      };
      // draft里面的object类型属性被proxy代理了，直接给setFieldsValue会抛异常，需要deepClone一下去掉proxy
      formInstance.setFieldsValue(cloneDeep(newDraft));
      return newDraft;
    });
  }, [formUpdateTrigger]);

  // 表单“是否包含附件”切换到true的时候，拉取附件列表
  useEffect(() => {
    if (!form.includeAttachments || attachmentOptionsLoaded) {
      return;
    }
    fetchAttachments();
  }, [form.includeAttachments]);

  // 当标题模板修改的时候，修改form的标题
  useEffect(() => {
    const enrichedIssueInfo = { ...issueInfo, platformId };
    updateForm((draft) => {
      const newDraft = {
        ...draft,
        title: TapdTitleTemplateUtil.makeTitleFromTemplate(titleTemplateFromSettings, enrichedIssueInfo),
      };
      // draft里面的object类型属性被proxy代理了，直接给setFieldsValue会抛异常，需要deepClone一下去掉proxy
      formInstance.setFieldsValue(cloneDeep(newDraft));
      return newDraft;
    });
  }, [titleTemplateFromSettings]);

  function wrapBuiltInFormItem({ field, label, customFormItemName, slot }) {
    const fieldInfo = enabledBuiltInFieldKeyToInfo[field];
    const required = !!fieldInfo?.required;
    return !!fieldInfo && <Form.Item
      label={label}
      name={customFormItemName || field}
      rules={required ? [{ required: true }] : undefined}
    >
      { slot }
    </Form.Item>;
  }

  const formTapdFieldsComponent = <div>
    { wrapBuiltInFormItem({
      field: 'severity',
      label: t('tapd.严重程度'),
      slot: <Select options={TAPD_SEVERITY_LIST}/>,
    }) }
    { wrapBuiltInFormItem({
      field: 'reporter',
      label: t('tapd.创建人'),
      slot: <AutoComplete options={reporterOptions} />
    }) }
    { wrapBuiltInFormItem({
      field: 'versionReport',
      label: t('tapd.发现版本'),
      slot: <AutoComplete options={versionList} allowClear={true}/>
    }) }
    { wrapBuiltInFormItem({
      field: 'iterationId',
      label: t('tapd.迭代'),
      slot: <Select
        showSearch
        options={iterationList}
        filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase()) }
      />
    }) }
    { wrapBuiltInFormItem({
      field: 'module',
      label: t('tapd.模块'),
      slot: <Select
        showSearch
        options={tapdModuleList}
        filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase()) }
      />
    }) }
    { wrapBuiltInFormItem({
      field: 'releaseId',
      label: t('tapd.发布计划'),
      slot: <Select
        showSearch
        options={releaseList}
        filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase()) }
      />
    }) }
    { wrapBuiltInFormItem({
      field: 'priority',
      label: t('tapd.优先级'),
      slot: <Select allowClear={true} options={TAPD_PRIORITY_LIST}/>
    }) }
    { wrapBuiltInFormItem({
      field: 'currentOwner',
      label: t('tapd.处理人'),
      customFormItemName: 'rawValueCurrentOwner',
      slot: <Select
        mode='multiple'
        options={workspaceUserOptions}
        tokenSeparators={[';']}
        allowClear={true}
        showSearch
        dropdownRender={(originNode) => {
          return <div>
            {originNode}
            <div
              style={{ padding: '5px 12px', color: '#AAA', borderTop: '1px solid #AAA' }}
            >{ ze('选项只列出TAPD项目里有权限的人员。', 'Only members who have joined the TAPD project are listed.') }</div>
          </div>
        }}
      />
    }) }
    { enabledCustomFieldList.map(item => {
      const { field, required } = item;
      const foundCustomField = (bugCustomFields || []).find(x => x.customField === field);
      const label = foundCustomField?.name || field;
      let fieldComponent;
      const parsedOptions = foundCustomField && foundCustomField.options ? foundCustomField.options.split('|').map((option) => ({ value: option, label: option })) : [];

      if (foundCustomField && foundCustomField.type === 'checkbox') {
        fieldComponent = (
          <CsSelect
            mode="multiple"
            style={{ width: '100%', fontSize: '14px' }}
            allowClear
            options={parsedOptions}
          />
        );
      } else if (foundCustomField && (foundCustomField.type === 'radio' || foundCustomField.type === 'select')) {
        fieldComponent = (
          <CsSelect
            style={{ width: '100%', fontSize: '14px' }}
            allowClear
            options={parsedOptions}
          />
        );
      } else {
        fieldComponent = <Input />;
      }

      return <Form.Item
        key={field}
        label={label}
        name={field}
        rules={required ? [{ required: true }] : undefined}
      >
        {fieldComponent}
      </Form.Item>
    })}
    <div>
      <Tooltip
        mouseEnterDelay={0.3}
        title={!hasAdminPermission ? ze('仅项目管理员可操作', 'Only project admins can set fields.') : undefined}
      ><Button
        type='link'
        disabled={!hasAdminPermission}
        onClick={() => {
          setTapdEditBugCustomFieldsModalVisible(true);
        }}
      >{ ze('设置TAPD字段', 'Set TAPD Fields') }{ !hasAdminPermission && ze('（无权限）', '(No Permission)') }</Button>
      </Tooltip>
    </div>
  </div>;

  const formJiraFieldsComponent = <div>
    <Form.Item
      label={ze('Jira项目', 'Jira Project')}
      name='JiraProject'
    >
      {bondedJiraProject && <a href={bondedJiraProject.baseUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>{`${bondedJiraProject.name} (id:${bondedJiraProject.jiraProjectId}, key:${bondedJiraProject.jiraProjectKey})`}</a>}
    </Form.Item>
    <Form.Item
      label={ze('优先级', 'Priority')}
      name='priority'
      // rules={[{ required: true }]}
    >
      <Select allowClear={true} options={JIRA_PRIORITY_LIST}/>
    </Form.Item>
    <Form.Item
      label={ze('报告人', 'Reporter')}
      name='reporterId'
      rules={[{ required: true }]}
    >
      <Select
        options={jiraUserList}
        disabled={usersLoading}
        loading={usersLoading}
        onFocus={() => {
          if(jiraUserList.length === 0)
            notification.error({message: ze('请在Jira项目中导入人员。', 'Please import people in Jira project。')})
        }} />
    </Form.Item>
    <Form.Item
      label={ze('经办人', 'Operator')}
      name='assigneeId'
      rules={[{ required: true }]}
    >
      <Select
        options={jiraUserList}
        disabled={usersLoading}
        loading={usersLoading}
        onFocus={() => {
          if(jiraUserList.length === 0)
            notification.error({message: ze('请在Jira项目中导入人员。', 'Please import people in Jira project。')})
        }} />
    </Form.Item>
    <Form.Item
      label={ze('问题类型', 'Issue Type')}
      name='issueType'
      rules={[{ required: true }]}
    >
      <Select
        options={jiraTypesList}
        disabled={typesLoading}
        loading={typesLoading}
        onFocus={() => {
          if(jiraTypesList.length === 0)
            notification.error({message: ze('请在Jira项目中设置“问题”或“缺陷”问题类型。', 'Please set the "Issue" type in your Jira project.')})
        }} />
    </Form.Item>
  </div>;

  const formTapdComponent = (
    tabType === TAB_CREATE_BUG && <div className={scss.formContainer}><Form
      layout='vertical'
      form={formInstance}
      initialValues={form}
      onValuesChange={(changedValues, allValues) => {
        updateForm((draft) => {
          Object.assign(draft, changedValues);
        });
      }}
      onFinish={() => {
        if (tabType === TAB_CREATE_BUG) {
          onSubmitBugFormAfterValidate();
        }
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ flex: 'auto' }}>
          {!isMultipleIssues && <Form.Item
            label={t('tapd.缺陷标题')}
            help={(() => {
              const titleLength = form.title ? form.title.length : 0;
              return titleLength > MAX_TITLE_LENGTH
                ? <div style={{ color: red.primary }}>{ze(
                  `标题已超过字符长度限制（当前 ${titleLength}，限制 ${MAX_TITLE_LENGTH} ），超出部分将被截断`,
                  `The title has exceeded the character length limit (currently ${titleLength}, limit ${MAX_TITLE_LENGTH}), and the excess part will be truncated.`
                )}</div>
                : null;
            })()}
          >
            <Row gutter={0} >
              <Col ><Form.Item
                name='title'
                noStyle
                rules={[{ required: true }]}
              >
                <Input style={{width: '470px'}} />
              </Form.Item></Col>
              <Col><Button
                type='link'
                style={{marginLeft: '10px', width: 'fit-content'}}
                onClick={() => setTapdTitleTemplateModalVisible(true)}
              >{ ze('设置标题模板', 'Edit Title Template') }</Button></Col>
            </Row>
          </Form.Item>}

          {!isMultipleIssues && <Form.Item
            label={t('tapd.缺陷详情')}
            name='description'
            rules={[{ required: true }]}
          >
            <TextArea style={{width: '600px', height: '360px'}} />
          </Form.Item>}
          {!isMultipleIssues && <div style={{margin: '24px 0px'}}>
            <span style={{marginRight: '10px'}}>{t('tapd.包含附件下载链接')}</span>
            <Form.Item
              name='includeAttachments'
              noStyle={true}
            >
            <Radio.Group>
              <Radio value={false}>{t('tapd.不包含')}</Radio>
              <Radio value={true}>{t('tapd.包含')}</Radio>
            </Radio.Group>
          </Form.Item></div>}
          {!isMultipleIssues && form.includeAttachments &&
            <Spin spinning={attachmentOptionsLoading}>
              <span style={{marginRight: '10px'}}>{t('tapd.选择要包含的附件')}</span>
              <Form.Item name='attachmentFilenameList' noStyle={true}>
                <Checkbox.Group options={attachmentFilenameOptions}/>
              </Form.Item>
            </Spin>}

          {isMultipleIssues && <Form.Item
            label={t('tapd.缺陷标题')}
          >
            <Row gutter={0}>
              <Col ><Form.Item
                noStyle
                rules={[{ required: true }]}
              >
                <Input style={{width: '470px'}} disabled={true} />
              </Form.Item></Col>
              <Col><Button
                type='link'
                disabled={true}
                style={{marginLeft: '10px', width: 'fit-content'}}
              >{ ze('设置标题模板', 'Edit Title Template') }</Button></Col>
            </Row>
          </Form.Item>}
          {isMultipleIssues && <Form.Item
            label={t('tapd.缺陷详情')}
            rules={[{ required: true }]}
          >
            <TextArea style={{width: '600px', height: '360px'}} disabled={true}/>
          </Form.Item>}
          {isMultipleIssues && <div style={{margin: '24px 0px'}}>
            <span style={{marginRight: '10px'}}>{t('tapd.包含附件下载链接')}</span>
            <Form.Item
              name='includeAttachments'
              noStyle={true}
            >
            <Radio.Group disabled={true}>
              <Radio value={false}>{t('tapd.不包含')}</Radio>
              <Radio value={true}>{t('tapd.包含')}</Radio>
            </Radio.Group>
          </Form.Item></div>}
          {isMultipleIssues && form.includeAttachments &&
            <Spin spinning={attachmentOptionsLoading} disabled={true}>
              <span style={{marginRight: '10px'}}>{t('tapd.选择要包含的附件')}</span>
              <Form.Item name='attachmentFilenameList' noStyle={true}>
                <Checkbox.Group options={attachmentFilenameOptions}/>
              </Form.Item>
            </Spin>}
        </div>
        <Divider type='vertical'  style={{ height: 'auto', margin: '0 20px' }}/>
        <div style={{ width: '240px' }}>
          { formTapdFieldsComponent }
        </div>
      </div>
    </Form></div>
  );

  const formJiraComponent = (
    tabType === TAB_CREATE_BUG && <div className={scss.formContainer}><Form
      layout='vertical'
      form={formInstance}
      initialValues={form}
      onValuesChange={(changedValues, allValues) => {
        updateForm((draft) => {
          Object.assign(draft, changedValues);
        });
      }}
      onFinish={() => {
        if (tabType === TAB_CREATE_BUG) {
          onSubmitJiraBugForm();
        }
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ flex: 'auto' }}>
          {!isMultipleIssues && <Form.Item
            label={t('tapd.缺陷标题')}
            help={(() => {
              const titleLength = form.title ? form.title.length : 0;
              return titleLength > MAX_TITLE_LENGTH
                ? <div style={{ color: red.primary }}>{ze(
                  `标题已超过字符长度限制（当前 ${titleLength}，限制 ${MAX_TITLE_LENGTH} ），超出部分将被截断`,
                  `The title has exceeded the character length limit (currently ${titleLength}, limit ${MAX_TITLE_LENGTH}), and the excess part will be truncated.`
                )}</div>
                : null;
            })()}
          >
            <Row gutter={0}>
              <Col ><Form.Item
                name='title'
                noStyle
                rules={[{ required: true }]}
              >
                <Input style={{width: '470px'}} />
              </Form.Item></Col>
              <Col><Button
                type='link'
                style={{marginLeft: '10px', width: 'fit-content'}}
                onClick={() => setTapdTitleTemplateModalVisible(true)}
              >{ ze('设置标题模板', 'Edit Title Template') }</Button></Col>
            </Row>
          </Form.Item>}

          {!isMultipleIssues && <Form.Item
            label={t('tapd.缺陷详情')}
            name='description'
            rules={[{ required: true }]}
          >
            <TextArea style={{width: '600px', height: '360px'}} />
          </Form.Item>}
          {!isMultipleIssues && <div style={{margin: '24px 0px'}}>
            <span style={{marginRight: '10px'}}>{t('tapd.包含附件下载链接')}</span>
            <Form.Item
              name='includeAttachments'
              noStyle={true}
            >
            <Radio.Group>
              <Radio value={false}>{t('tapd.不包含')}</Radio>
              <Radio value={true}>{t('tapd.包含')}</Radio>
            </Radio.Group>
          </Form.Item></div>}
          {!isMultipleIssues && form.includeAttachments &&
            <Spin spinning={attachmentOptionsLoading}>
              <span style={{marginRight: '10px'}}>{t('tapd.选择要包含的附件')}</span>
              <Form.Item name='attachmentFilenameList' noStyle={true}>
                <Checkbox.Group options={attachmentFilenameOptions}/>
              </Form.Item>
            </Spin>}
        </div>
        <Divider type='vertical' style={{ height: 'auto', margin: '0 20px' }}/>
        <div style={{ width: '240px' }}>
          { formJiraFieldsComponent }
        </div>
      </div>
    </Form></div>
  );

  // 在创建bug前，对表单数据做处理（比如超长的标题截断，字段转换为接口可以接受的内容等）
  function refineCreateBugFormDataForSubmit() {
    const updateFields = {};
    if (form.title && form.title.length > MAX_TITLE_LENGTH) {
      updateFields.title = form.title.substring(0, MAX_TITLE_LENGTH);
    }
    if ((form.rawValueCurrentOwner || []).length > 0) {
      updateFields.currentOwner = form.rawValueCurrentOwner.join(';');
    }

    if (Object.keys(updateFields).length > 0) {
      updateForm(draft => ({ ...draft, ...updateFields }));
    }
  }

  // 点击表单提交的回调。（创建bug和绑定bug公共回调）
  // 需要做一些表单内容预处理
  const onClickSubmitForm = () => {
    if (tabType === TAB_CREATE_BUG) {
      refineCreateBugFormDataForSubmit();
    }
    setFormSubmitTrigger(formSubmitTrigger + 1);
  };

  // 表单提交trigger effect。（创建bug和绑定bug公共）
  useEffect(() => {
    if (!formSubmitTrigger) {
      return;
    }
    if (tabType === TAB_CREATE_BUG) {
      formInstance.setFieldsValue(form);
    }
    formInstance.submit();
  }, [formSubmitTrigger]);

  async function onSubmitBugFormAfterValidate() {
    if (tabType !== TAB_CREATE_BUG) {
      return;
    }

    if (form.reporter) {
      pushReporter(form.reporter);
    }

    setLoading(true);
    // 带有附件，需要先把附件接口转为share link，然后追加到提单正文里去
    let extraDescription = '';
    try {
      if (form.includeAttachments && form.attachmentFilenameList.length > 0) {
        const { issueId } = issueInfo;
        const detailInfo = issueInfo.detailInfo;
        const { crashId } = detailInfo;
        const equivalentUserRole = hasViewPersonalDataPermission ? UserTypeEnum.MEMBER : UserTypeEnum.STATS_VIEWER;
        const rsp = await RestHelper.post('/api/issue/generateReportShareLink', {
          appId,
          platformId,
          issueId,
          crashId,
          expireInSeconds: 365 * 86400, // 一年有效期
          equivalentUserRole,
        });
        const { shareId } = rsp.json.data;
        const downloadLinks = form.attachmentFilenameList.map(name => {
          const nameRemoveDot = name.replace(/\./g, 'dot');
          const url = `${window.location.protocol}//${window.location.host}/showAttachment/shareId/${shareId}/fileName/${nameRemoveDot}`;
          return `<a href='${url}' download='${CrashAttachmentUtil.getFileAliasByFilename(name)}'>${CrashAttachmentUtil.getFileAliasByFilename(name)}</a><br>`;
        }).join('');
        extraDescription = `<br><br>查看附件（链接有效期1年）：<br>${downloadLinks}`;
      }
    } catch (e) {
      notification.error({ message: '生成附件分享链接失败，请联系系统管理员。' });
      throw e;
    }

    if (!isMultipleIssues) {
      const rsp = await RestHelper.post('/api/issue/upsertBugs', {
        appId,
        platformId,
        issueList: [{
          issueHash: issueInfo.issueId,
          bugInfoList: [makeFinalTapdBugForSubmit(issueInfo, form, isMultipleIssues, extraDescription)],
        }],
      });
      const isSuccess = rsp.json.data[0];
      if (isSuccess) {
        notification.success({ message: ze('缺陷创建成功！', 'Create successfully!') });
      } else {
        notification.error({ message: ze('缺陷创建失败！', 'Create failed!') });
      }
    } else {
      const failedIssueIdList = [];
      for (const info of issueInfoList) {
        const issueId = info.issueId;
        let rsp = await RestHelper.mustPost('/api/crash/fetchLastCrashDocInIssue', { appId, platformId, issueId });
        const detailInfo = CrashUtil.makeRefinedCrashMap(rsp.json.data.crashMap);
        rsp = await RestHelper.post('/api/issue/upsertBugs', {
          appId,
          platformId,
          issueList: [{
            issueHash: info.issueId,
            bugInfoList: [makeFinalTapdBugForSubmit({...info, detailInfo}, form, isMultipleIssues, extraDescription)],
          }],
        });
        const isSuccess = rsp.json.data[0];
        if (!isSuccess) {
          failedIssueIdList.push(issueId);
        }
      }
      if (failedIssueIdList.length === 0) {
        notification.success({ message: ze('缺陷创建成功！', 'Create successfully!') });
      } else {
        notification.error({ message: ze(`缺陷 ${failedIssueIdList.join(', ')} 创建失败。`, `${failedIssueIdList.join(', ')} create failed!`) });
      }
    }
    setLoading(false);
    modalProps.onOk && modalProps.onOk();
  };
  async function onSubmitJiraBugForm() {
    if (tabType !== TAB_CREATE_BUG) {
      return;
    }
    if (form.reporter) {
      pushReporter(form.reporter);
    }
    setLoading(true);
    // 带有附件，需要先把附件接口转为share link，然后追加到提单正文里去
    let extraDescription = '';
    try {
      if (form.includeAttachments && form.attachmentFilenameList.length > 0) {
        const { issueId } = issueInfo;
        const detailInfo = issueInfo.detailInfo;
        const { crashId } = detailInfo;
        const equivalentUserRole = hasViewPersonalDataPermission ? UserTypeEnum.MEMBER : UserTypeEnum.STATS_VIEWER;
        const rsp = await RestHelper.post('/api/issue/generateReportShareLink', {
          appId,
          platformId,
          issueId,
          crashId,
          expireInSeconds: 365 * 86400, // 一年有效期
          equivalentUserRole,
        });
        const { shareId } = rsp.json.data;
        const downloadLinks = form.attachmentFilenameList.map(name => {
          const nameRemoveDot = name.replace(/\./g, 'dot');
          const url = `${window.location.protocol}//${window.location.host}/showAttachment/shareId/${shareId}/fileName/${nameRemoveDot}`;
          return `<a href='${url}'>${name}</a><br>`;
        }).join('');
        extraDescription = `<br><br>查看附件（链接有效期1年）：<br>${downloadLinks}`;
      }
    } catch (e) {
      notification.error({ message: '生成附件分享链接失败，请联系系统管理员。' });
      throw e;
    }
    let jiraBug = {
      reporterId: form.reporterId,
      assigneeId: form.assigneeId,
      project: bondedJiraProject.name,
      summary: form.title,
      issueType: form.issueType,
      description: form.description,
      priority: form.priority
    }
    if (extraDescription) {
      jiraBug = {
        ...jiraBug,
        description: jiraBug.description + extraDescription,
      };
    }
    if (!isMultipleIssues) {
      const rsp = await RestHelper.post('/redir/query/jira/upsertJiraBugs', {
        appId,
        platformId,
        issueList: [{
          issueHash: issueInfo.issueId,
          bugInfoList: [jiraBug]
        }],
      });
      const isSuccess = rsp.json.data[0].result;
      if (isSuccess) {
        notification.success({ message: ze('缺陷创建成功！', 'Create successfully!')});
      } else {
        notification.error({ message: ze('缺陷创建失败！', 'Create failed!') + rsp.json.data[0].msg });
      }
    } else {
      notification.error({ message: ze('暂不支持批量创建Jira缺陷', 'Batch creation of Jira issues is not currently supported.') });
    }
    setLoading(false);
    modalProps.onOk && modalProps.onOk();
  }

  // 从tapd缺陷页面地址中读取出bug id。 如果获取不到，返回null
  const getBugIdFromUrl = (url) => {
    if (!isString(url)) {
      return null;
    }
    // 目前已知的TAPD的缺陷页面url有以下形式
    // 1.  /tapd_fe/{项目ID}/bug/list?dialog_preview_id=bug_{缺陷ID}
    // 2.  /{项目name}/bugtrace/bugs/view?bug_id={缺陷ID}
    // 3.  /{项目name}/bugtrace/bugs/view/{缺陷ID}
    // 4.  /tapd_fe/{项目ID}/bug/detail/{缺陷ID}
    const matchResult = url.match(/tapd.+(?:bug_|bug_id=|view\/|bug\/detail\/)(\d+)/);
    if (!matchResult) {
      return null;
    }
    return matchResult[1];
  };

  const onSubmitBindJiraBugForm = async () => {
    if (tabType !== TAB_BIND_BUG) {
      return;
    }
    setLoading(true);
    const rsp = await RestHelper.post('/redir/query/jira/bindJiraBug', {
      appId,
      platformId,
      issueHash: issueInfo.issueId,
      jiraBugKey: form.jiraBugKey
    });
    const isSuccess = rsp.json.data;
    if (isSuccess) {
      notification.success({ message: '缺陷绑定成功。' });
    } else {
      notification.error({ message: '缺陷绑定失败。' });
    }
    setLoading(false);
    modalProps.onOk && modalProps.onOk();
  };

  const onSubmitBindBugFormAfterValidate = async () => {
    if (tabType !== TAB_BIND_BUG) {
      return;
    }
    setLoading(true);
    const bugId = getBugIdFromUrl(form.url);
    const rsp = await RestHelper.post('/redir/query/data/bindBug', {
      appId,
      platformId,
      workspaceId,
      bugId,
      issueHash: issueInfo.issueId,
    });
    const isSuccess = rsp.json.data;
    if (isSuccess) {
      notification.success({ message: '缺陷绑定成功。' });
    } else {
      notification.error({ message: '缺陷绑定失败。' });
    }
    setLoading(false);
    modalProps.onOk && modalProps.onOk();
  };

  const bindTapdBugComponent = (
    workspaceId && tabType === TAB_BIND_BUG && <Form
      layout='vertical'
      form={formInstance}
      initialValues={{ url: '' }}
      onValuesChange={(changedValues) => {
        updateForm((draft) => {
          Object.assign(draft, changedValues);
        });
      }}
      onFinish={() => {
        if (tabType === TAB_BIND_BUG) {
          onSubmitBindBugFormAfterValidate();
        }
      }}
    >
      <Form.Item
        label={ze('TAPD缺陷页面地址', 'TAPD Issue Web Page URL')}
        name='url'
        rules={[{
          required: true,
          type: 'url',
        }, {
          validator: (_, value) => {
            if (isNotNullish(getBugIdFromUrl(value))) {
              return Promise.resolve();
            }
            return Promise.reject(Error('不是合法的TAPD缺陷地址'));
          },
        }]}
      >
        <Input/>
      </Form.Item>
    </Form>
  );

  const bindJiraBugComponent = (
    bondedJiraProject && tabType === TAB_BIND_BUG && <Form
      form={formInstance}
      initialValues={{ url: '' }}
      onValuesChange={(changedValues) => {
        updateForm((draft) => {
          Object.assign(draft, changedValues);
        });
      }}
      onFinish={() => {
        if (tabType === TAB_BIND_BUG) {
          onSubmitBindJiraBugForm();
        }
      }}
    >
      <Form.Item
        label={ze(`JiraBugKey(形如：${bondedJiraProject.jiraProjectKey}-1)`, `JiraBugKey(like：${bondedJiraProject.jiraProjectKey}-1)`)}
        name='jiraBugKey'
        rules={[{
          required: true,
        }, {
          validator: (_, value) => {
            const pattern = new RegExp('^' + bondedJiraProject.jiraProjectKey);
            if (pattern.test(value)) {
              return Promise.resolve();
            }
            return Promise.reject(Error('不是合法的JiraBugKey'));
          },
        }]}
      >
        <Input/>
      </Form.Item>
    </Form>
  );

  let finalModalProps;
  if (workspaceIdLoading || jiraIdLoading) {
    finalModalProps = {
      ...modalProps,
      title: t('tapd.缺陷管理平台绑定状态加载中'),
      okButtonProps: {
        disabled: true,
      },
    };
  } else if (workspaceId) {
    finalModalProps = {
      ...modalProps,
      title: isMultipleIssues
        ? ze(`批量提交${issueInfoList.length}个缺陷到TAPD`, `Bulk Submit ${issueInfoList.length} TAPD Issues`)
        : <Space>
          <div>{t('tapd.提交缺陷到TAPD')}</div>
          {!isMultipleIssues &&  <Select
                style={{ width: '200px' }}
                options={[
                  { label: t('tapd.创建新TAPD缺陷'), value: TAB_CREATE_BUG },
                  { label: t('tapd.关联已有TAPD缺陷'), value: TAB_BIND_BUG },
                ]}
                value={tabType}
                onChange={(value) => {
                  setTabType(value)
                }}
              />}
        </Space>,
      width: '960px',
      maskClosable: false,
      onOk: () => onClickSubmitForm(),
    };
  } else if (bondedJiraProject) {
    finalModalProps = {
      ...modalProps,
      title: isMultipleIssues
        ? `批量提交${issueInfoList.length}个缺陷到Jira`
        : <Space>
          <div>{ze('提交缺陷到Jira', 'Submit issues to Jira')}</div>
          {!isMultipleIssues && <Radio.Group
            defaultValue={tabType}
            value={tabType}
            options={[
              { label: ze('创建新的Jira缺陷', 'Create New Issue'), value: TAB_CREATE_BUG },
              { label: ze('关联已有Jira缺陷', 'Bind Existed Issue'), value: TAB_BIND_BUG },
            ]}
            optionType='button'
            onChange={(v) => setTabType(v.target.value)}
          />}
        </Space>,
      width: '960px',
      maskClosable: false,
      onOk: () => onClickSubmitForm(),
    };
  } else {
    finalModalProps = {
      ...modalProps,
      title: ze('绑定缺陷管理系统', 'Integrate with an bug tracking system'),
      onOk: () => redirectToTapdConfigPage(),
    };
  }
  useEffect(() => {
    if(bondedJiraProject && bondedJiraProject.jiraProjectId){
      getAssignableUsers();
      getJiraTypes();
    }
  }, [bondedJiraProject])

  async function getAssignableUsers() {
    setUsersLoading(true);
    const rsp = await RestHelper.mustPost('/redir/query/jira/getAssignableUsers', { appId, platformId });
    if(rsp.json.data){
      const jiraUserList = rsp.json.data.map(item => {
        return{
          value: item.accountId,
          label: item.displayName
        }
      })
      setJiraUserList(jiraUserList);
    }
    else{
      notification.error({message: ze('Jira项目人员列表获取失败。', 'Failed to get user list for Jira project.')})
    }
    setUsersLoading(false);
  }

  async function getJiraTypes() {
    setTypesLoading(true);
    const rsp = await RestHelper.mustPost('/redir/query/jira/getJiraTypes', { appId, platformId });
    if(rsp.json.data){
      const jiraTypesList = rsp.json.data.map(item => {
        return{
          value: item.id,
          label: item.name
        }
      })
      setJiraTypesList(jiraTypesList);
    }
    else{
      notification.error({message: ze('Jira项目问题类型获取失败。', 'Failed to get issue type for Jira project')})
    }
    setTypesLoading(false);
  }

  return (
    <Modal {...finalModalProps} className={scss.tapdStyle}>
      { workspaceIdLoading && jiraIdLoading && <Spin spinning={workspaceIdLoading && jiraIdLoading} style={{ width: '100%' }}/> }
      {/*Tapd*/}
      { !workspaceIdLoading && workspaceId && <Spin spinning={loading}>
        {/* !loading,!jiraIdLoading 是为了防止model宽度在计算的过程中内容已经渲染，导致内容超出model盒子 */}
        {tabType === TAB_CREATE_BUG && !loading && !jiraIdLoading && formTapdComponent}
        {tabType === TAB_BIND_BUG && bindTapdBugComponent}
      </Spin>}
      {/*Jira*/}
      { !jiraIdLoading && bondedJiraProject && <Spin spinning={loading}>
        {/* !loading,!jiraIdLoading 是为了防止model宽度在计算的过程中内容已经渲染，导致内容超出model盒子 */}
        {tabType === TAB_CREATE_BUG && !loading && !jiraIdLoading && formJiraComponent}
        {tabType === TAB_BIND_BUG && bindJiraBugComponent}
      </Spin>}
      { !workspaceIdLoading && !workspaceId && !jiraIdLoading && !bondedJiraProject && <div>
        <div>{ ze('项目尚未绑定缺陷管理系统，是否要前往“缺陷管理平台配置”页进行配置', 'This project has not been integrated with any bug tracking system, Would you like to go to the "Integrations" page to set it up?') }</div>
      </div>}
      <TapdModelEditTitleTemplateModel
        visible={tapdTitleTemplateModalVisible}
        issueInfo={issueInfo}
        onCancel={() => {
          setTapdTitleTemplateModalVisible(false);
        }}
        onSave={() => {
          setTapdTitleTemplateModalVisible(false);
        }}
      />
      <TapdEditBugCustomFieldsModal
        visible={tapdEditBugCustomFieldsModalVisible}
        bugCustomFields={bugCustomFields}
        onCancel={() => setTapdEditBugCustomFieldsModalVisible(false)}
        onSave={() => onSaveBugCustomFields()}
      />
    </Modal>
  );
};

TapdModal.propTypes = {
  issueInfoList: PropTypes.Array,
  modalProps: PropTypes.object,
  reduxState: PropTypes.object,
};

const mapStateToProps = state => ({
  reduxState: state,
});

export default connect(mapStateToProps)(TapdModal);
