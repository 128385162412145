import RestHelper from './RestHelper';

const ftRest = RestHelper.publish({
  ftTrend: {// 获取ft趋势
    get: {
      method: 'get',
      uri: '/ftTrend/appId/{appId}/platformId/{platformId}/startDate/{startDate}/endDate/{endDate}/type/{type}/version/{version}',
    },
  },
  ftInfo: {

    // 获取ft的信息
    get: {
      method: 'get',
      uri: '/apps/{appId}/ftInfo',
    },

    // 增加ft信息
    add: {
      method: 'post',
      uri: '/apps/{appId}/addFtInfo',
    },

    // 编辑Ft信息
    edit: {
      method: 'post',
      uri: '/apps/{appId}/editFtInfo',
    },

    // 删除Ft信息
    delete: {
      method: 'post',
      uri: '/apps/{appId}/deleteFtInfo',
    },

    // 添加ft下的插件信息
    addFtPluginInfo: {
      method: 'post',
      uri: '/apps/{appId}/addFtPluginInfo',
    },

    // 修改ft下的插件信息
    editFtPluginInfo: {
      method: 'post',
      uri: '/apps/{appId}/editFtPluginInfo',
    },

    // 删除ft下的插件信息
    deleteFtPluginInfo: {
      method: 'post',
      uri: '/apps/{appId}/deleteFtPluginInfo',
    },
  },
});

export default ftRest;
