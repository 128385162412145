import { Map, fromJS } from 'immutable';
const initialSelectOptions = new Map({

});

const initialSearchParams = new Map({

});

export const initialState = fromJS({
  selectOptions: initialSelectOptions,
  searchParams: initialSearchParams,
  editOk: false,
  currentTab: '',
  isClose: true,
  isShowTab: false,
  noSolution: true,
  hasZan: false,
  editting: false, // 是否要编辑解决方案
  matchCase: {},
  myCase: {},
  statusCode: 0,
  solutionString: '',
  isStar: false, // 是否可以点赞
});
