import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ReactECharts from 'echarts-for-react';
import { Button, Checkbox, Col, Modal, Row, Select, Space, Spin, Table, Tabs, Tooltip, message } from 'antd';
import {connect, useDispatch, useSelector} from 'react-redux';
import { ze } from 'utils/zhEn';
import _style from './MergedIssueList.scss'
import { DemoUtil } from 'utils/demo-util';
import { useTranslation } from 'react-i18next';
import { IssueListTrendTypeEnum } from 'utils/constants';
import reportEvent, { EVENT_ACTIONS,  getPageTitle } from 'utils/reportEvent';
import { CrashUtil } from 'utils/api/crash';
import IssueTrendBarChart from 'components/exception/BaseProblemPage/IssueItem/IssueTrendBarChart';
import RestHelper from 'utils/RestHelper';
import { TimeUtil } from 'utils/time-util';
import moment from 'moment';
import { formatNum } from 'utils/helper';
import { bindActionCreators } from 'redux';
import { fetchMultipleIssueTrend } from 'reducers/issue/issueActions';
import NoJumpNextPagination from 'components/antd-extension/NoJumpNextPagination';
import { selectHasSpecifiedPermissionInCurrentApp } from 'utils/selectors/selectors';
import { ApiErrorCode } from 'utils/api-error-code';
import ExceptionsIcon from 'svg/v2/newcs_dashboard_overview_dailytopissue_list_yichangname_icon.svg';
import IssueIcon from 'svg/v2/newcs_dashboard_overview_dailytopissue_list_issueid_icon.svg';
import { CS_STYLES } from 'utils/constants/style-constants';

const MergedIssueList = ({
  platformId, appId, actions, reduxState
}) => {
  const issueIdToDailyTrendList = reduxState.issue.get('issueIdToDailyTrendList') || {};
  const issueIdToHourlyTrendList = reduxState.issue.get('issueIdToHourlyTrendList') || {};
  const { t, i18n } = useTranslation();
  const issueObj = reduxState.issue.toJS();
  const { esMap } = (issueObj?.current) ?? {};

  const hasEditPermission = useSelector(state => selectHasSpecifiedPermissionInCurrentApp(state, 'EDIT'));

  const [tableData, setTableData] = useState([]);
  const [totalTableData, setTotalTableData] = useState([]);
  const [issueListTrendType, setIssueListTrendType] = useState(IssueListTrendTypeEnum.DAILY);
  const [checkedIssueIdList, setCheckedIssueIdList] = useState([]);
  const [tableConfig, setTableConfig] = useState({
    currentPage: 1,
    pageSize: 20,
    sortKey: '',
    sortOrder: '',
  })
  const [modalVisible, setModalVisible] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchMergeIssueList();
  }, []);

  useEffect(() => {
    changeTableData();
  }, [totalTableData, tableConfig])

  const fetchMergeIssueList = async () => {
    setLoading(true);
    const rsp = await RestHelper.post('/redir/query/merge/getMergedIssueList', {
      appId,
      platformId,
      mergeIssueId: esMap?.issueId,
    });
    const mergedIssueList = rsp.json.data ?? []
    setTotalTableData(mergedIssueList);
    const issueIds = mergedIssueList.map(x => x.issueId);
    if (issueIds.length > 0) {
      actions.fetchMultipleIssueTrend([...issueIds, esMap?.issueId], issueListTrendType);
    }
    setLoading(false);
  }

  const changeTableData = () => {
    let nowData = [...totalTableData];
    // 排序
    if (tableConfig.sortKey && tableConfig.sortOrder) {
      nowData = nowData.sort((a, b) => (a[tableConfig.sortKey] ?? 0) > (b[tableConfig.sortKey] ?? 0) ? (tableConfig.sortOrder == 'ascend' ? 1 : -1) : (tableConfig.sortOrder == 'ascend' ? -1 : 1));
    }
    // 分页
    nowData = nowData.slice((tableConfig.currentPage - 1) * tableConfig.pageSize, tableConfig.currentPage * tableConfig.pageSize - 1);
    setTableData(nowData);
  }

  const columns = [
    {
      title: <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Checkbox
          checked={checkedIssueIdList.length === tableData.length && tableData.length !== 0}
          onChange={(e) => {
            if (e.target.checked) {
              setCheckedIssueIdList(tableData.map(info => info.issueId))
            } else {
              setCheckedIssueIdList([]);
            }
          }}>
        </Checkbox>
      </span>,
      dataIndex: 'check',
      width: 42,
      render: (_, record) => {
        return <Checkbox
          checked={checkedIssueIdList.includes(record.issueId)}
          onChange={(e) => {
            if (e.target.checked) {
              setCheckedIssueIdList([...checkedIssueIdList, record.issueId])
            } else {
              setCheckedIssueIdList(checkedIssueIdList.filter(id => id !== record.issueId));
            }
          }}
        ></Checkbox>
      },
      disabled: !hasEditPermission,
      align: 'center'
    },
    {
      title: <span >
        { checkedIssueIdList.length > 0 && <Button className={_style.checked_fun_btn} style={{ marginRight: '20px' }} onClick={() => { setModalVisible(true) }}>{ze('解除合并', 'Unmerge Issues') }</Button> }
        { checkedIssueIdList.length === 0 && (
          <span>
            { t("BASEPROBLEM.problem") }
            {` (${tableData.length || '0'})`}
          </span>
        )}
      </span>,
      align: 'left',
      render: (_, record) => {
        const lastMatchedReport = record.lastMatchedReport
        const hasLastMatchedReport = (lastMatchedReport || {}).exists || false;
        const lastMatchedReportRefinedCrashMap = CrashUtil.makeRefinedCrashMap((lastMatchedReport || {}).crashMap);
        const {
          expMessage: lastReportExpMessage,
          firstDefinitiveStackLine,
        } = lastMatchedReportRefinedCrashMap;
        const displayExpMessage = hasLastMatchedReport ? lastReportExpMessage : record.exceptionMessage;
        const displayKeyStack = hasLastMatchedReport ? firstDefinitiveStackLine : record.keyStack;

        const dom =<div>
          <div className={_style.error_title} style={{display: 'flex', alignItems: 'center', gap: 4}}>
            {<IssueIcon />}
            {<span style={{marginRight: 10}}>{record.issueId}</span>}
            {<ExceptionsIcon />}
            {record.exceptionName}
          </div>
          <div className={_style.error_info}>
            { !hasLastMatchedReport && <div style={{ fontSize: '12px', color: CS_STYLES.SUB_TEXT_COLOR, marginRight: '4px' }}>{ ze('未找到最近一条上报，将显示首次上报信息。', 'Last report not found, showing first report instead.') }</div> }
            { displayExpMessage && <div><span style={{ fontSize: '12px', color: CS_STYLES.SUB_TEXT_COLOR, marginRight: '4px' }}>{ t('issueCrashFilterKey.crashExpMessage') }</span><span>{ displayExpMessage }</span></div> }
            <div className={_style.less_info}>
              <p className={_style.key_stack}>
                <span style={{ fontSize: '12px', color: CS_STYLES.SUB_TEXT_COLOR, marginRight: '4px' }}>{
                  <Tooltip
                    title={ze('用于问题分类的首行关键堆栈。','The top of the stack trace used for issue grouping.')}
                  >{ t('REPORTDETAIL.首行关键堆栈') }</Tooltip>
                }</span>
                <span>{ displayKeyStack || '' }</span>
              </p>
            </div>
          </div>
        </div>
        return dom;
      }
    },
    {
      title: <span style={{ float: 'right', width: '200px', fontSize: '13px', display:"flex" }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ lineHeight: '26px', marginRight: 4 }}>{ze('最近上报趋势', 'Report Trend')}</div>
          <Select
            style={{ width: 90, fontSize: '14px',textAlign: 'left' }}
            options={[
              {label: ze('24小时', '24h'), value: IssueListTrendTypeEnum.HOURLY},
              {label: ze('14天', '14d'), value: IssueListTrendTypeEnum.DAILY},
              {label: ze('隐藏', 'Hide'), value: IssueListTrendTypeEnum.NONE},
            ]}
            value={issueListTrendType}
            onChange={(v) => {
              setIssueListTrendType(v)
              if (v !== IssueListTrendTypeEnum.NONE) {
                actions.fetchMultipleIssueTrend([...tableData.map(x => x.issueId), esMap?.issueId], v);
              }
              reportEvent({
                action: EVENT_ACTIONS.CLICK,
                tp1: `${getPageTitle()}-最近上报趋势`,
                tp6: v,
              })
            }}
          />
        </div>
      </span>,
      align: 'center',
      width: 200,
      render: (_, record) => {
        if (issueListTrendType === IssueListTrendTypeEnum.NONE) {
          return ''
        }
        return <IssueTrendBarChart
          trendType={issueListTrendType}
          dailyTrendList={issueIdToDailyTrendList[record.issueId]}
          hourlyTrendList={issueIdToHourlyTrendList[record.issueId]}
        />
      }
    },
    {
      title: <div style={{ maxWidth: 125, whiteSpace: 'pre' }}>
        { t("BASEPROBLEM.post") }
      </div>,
      key: 'latestUploadTimestamp',
      align: 'center',
      sorter: true,
      width: 140,
      render: (_, record) => {
        return moment(TimeUtil.fixChaosMillis(record.latestUploadTimestamp)).format('YYYY-MM-DD HH:mm:ss')
      }
    },
    {
      title: <span style={{ whiteSpace: 'pre' }}>
        { t("CRASHDETAIL.totalReports") }
      </span>,
      key: 'count',
      align: 'center',
      sorter: true,
      width: 110,
      render: (_, record) => {
        return record.count >= 0 ? formatNum(record.count) : 0;
      }
    },
    {
      title: <span style={{ maxWidth: 85, whiteSpace: 'pre' }}>
        { t("SENIORSEARCH.deviceMatchCount") }
      </span>,
      align: 'center',
      key: 'imeiCount',
      sorter: true,
      width: 100,
      render: (_, record) => {
        return record.imeiCount >= 0 ? formatNum(record.imeiCount) : 0;
      }
    }
  ].filter(x => !x.disabled);

  const onTableChange = (pagination, filters, sorter, extra) => {
    setTableConfig({
      ...tableConfig,
      sortKey: sorter.columnKey,
      sortOrder: sorter.order,
    })
  }

  const onPageSizeChange = (current, size) => {
    setTableConfig({ ...tableConfig, pageSize: size})
  }

  const onModalOkClick = async () => {
    setModalLoading(true);
    try {
      const rsp = await RestHelper.post('/redir/query/merge/unMergeIssue', {
        appId,
        platformId,
        mergeIssueId: esMap?.issueId,
        unMergeissueIdList: checkedIssueIdList,
      }, { silentErrorCodes: [ApiErrorCode.IllegalArgument] });
      if (rsp?.json?.ret === 200) {
        fetchMergeIssueList();
        setCheckedIssueIdList([]);
        setModalLoading(false);
        setModalVisible(false);
      } else {
        message.error(rsp?.json?.message);
        setModalLoading(false);
      }
    } catch (e) {
      message.error(e.message);
      setModalLoading(false);
    }
  }

  const unMergeIssueModalDom = <Modal width={500}
    title={ze('问题解除合并', 'Unmerge Issues')}
    visible={modalVisible}
    onOk={() => onModalOkClick()}
    onCancel={() => setModalVisible(false)}
    confirmLoading={modalLoading}
    closable={!modalLoading}
    cancelButtonProps={{ disabled: modalLoading }}
    maskClosable={!modalLoading}
  >
    <div>
      { ze('是否将选中的问题从原合并问题由移除', 'Should the selected issues be removed from the original merged issues? ') }
    </div>
  </Modal>

  return (
    <Spin spinning={loading}>
      <div>
        <Table
          style={{ width: '100%' }}
          size={'small'}
          columns={columns}
          dataSource={tableData}
          pagination={false}
          onChange={(pagination, filters, sorter, extra) => onTableChange(pagination, filters, sorter, extra)}
        />
        <div style={{display:'flex', margin:'10px', width:'calc(100% - 20px)'}}>
          <div style={{flex:1}}></div>
          <NoJumpNextPagination
            current={tableConfig.currentPage}
            pageSize={tableConfig.pageSize}
            total={totalTableData.length}
            onChange={(page, pageSize) => { setTableConfig({...tableConfig, currentPage: page}) }}
            onShowSizeChange={(current, size) => onPageSizeChange(current, size)}
            // showSizeChanger={true}
          />
        </div>
      </div>
      { unMergeIssueModalDom }
    </Spin>
  );
};

MergedIssueList.propTypes = {
  reduxState: PropTypes.object,
  appId: PropTypes.string,
  platformId: PropTypes.string,
};

const mapStateToProps = state => ({
  reduxState: state,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    fetchMultipleIssueTrend,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MergedIssueList);
