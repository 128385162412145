import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getExceptionType } from 'utils/helper';
import { PureRender } from 'components/commons';
import { autobind } from 'core-decorators';
// import the route
import CrashReportingRoute from 'route/crash-reporting';
// import ExceptionRoute from 'route/exception';

/**
 * 异常上报
 * TODO
 */
@connect((state, router) => {
  return {
  matchUrl: router.match.url,
  currentApp: state.app.get('current'),
  pathname: router.location.pathname,
  location: router.location,
  leftMenuState: state.global.get('leftMenuState'),
  };
  })
@PureRender
@autobind
export default class ExceptionPage extends Component {
  static contextTypes = {
    actions: PropTypes.object,
    appList: PropTypes.object,
  }

  static propTypes = {
    pathname: PropTypes.string,
    children: PropTypes.node,
    currentApp: PropTypes.object,
    leftMenuState: PropTypes.bool,
  }

  render() {
    const {
      matchUrl, currentApp, pathname, leftMenuState, location,
    } = this.props;
    const leftMenuClickTrigger = location.state && location.state.leftMenuClickTrigger;
    const { actions, appList } = this.context;
    return (
      <div className="exception_page">
        {CrashReportingRoute({
          match: { url: matchUrl },
          leftMenuClickTrigger,
        })}
      </div>
    );
  }
}
