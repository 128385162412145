import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TimePicker } from 'components/commons';
import { getDateOption } from 'utils/StringUtils';
import moment from 'moment';
import { Translation, useTranslation } from 'react-i18next';
import { Checkbox, Col, Row, Select, Space, Tooltip } from 'antd';
import { isNotNullish } from 'utils/nullish';
import CountrySelect from 'components/exception/ProductSummaryPage/CountrySelect';
import VersionHybridSelect from 'components/commons/VersionHybridSelect';
import { getTreeSelectVersionOptions } from 'utils/getTreeSelectVersionOptions';
import { CsvUtil } from 'utils/csv-util';
import { orange } from '@ant-design/colors';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { VersionUtil } from 'utils/version-util';
import { ze } from 'utils/zhEn';
import DuibiIconNormal from 'svg/v2/newcs_dashboard_duibi_icon_normal.svg';
import _style from './style.scss';
import VmTypeSelect from './VmTypeSelect';
import * as PlatformUtils from 'utils/platform';
import UserSceneTagSelect from 'components/exception/ProductSummaryPage/UserSceneTagSelect';

const SummaryOptions = ({
  style,
  handleChangeSearchParam,
  searchParams,
  selectOptions,
  isRegionalDashboard,
  enableUserSceneTag,
  disableCompare,
  isErrorType,
  hasErrorReportDiscardStrategy,
  isTrendDataBeforeGrayStrategyDrop,
  hasIntegratedApps,
  vmTypeListValue,
  routerPid,
  setIsTrendDataBeforeGrayStrategyDrop,
  onVmFilterSelectChange,
}) => {
  const { t } = useTranslation();

  const {
    trendVersion,
    trendDate,
    integratedAppOptions,
    integratedAppIdToVersionTree,
  } = selectOptions.toJS();

  const {
    isIntegratedAppTrend,
    integratedAppId,
    date1,
    startDate1,
    endDate1,
    date2,
    startDate2,
    endDate2,
  } = searchParams.toJS();
  // 后处理日期数据
  function refineDate(date, startDate, endDate) {
    const option = getDateOption(date);
    if (option === '自定义时间段') {
      if (startDate === endDate) {
        return moment(startDate).format('YYYY-MM-DD');
      } else {
        return `${moment(startDate).format('YYYY-MM-DD')} ~ ${moment(endDate).format('YYYY-MM-DD')}`;
      }
    } else {
      return option;
    }
  }

  function preprocessDate({ date, startDate, endDate }) {
    if (date === 'custom' && !endDate) { // 单日的时候，endDate是null
      endDate = startDate;
    }
    return { date, startDate, endDate };
  }

  const compareEnabled = searchParams.get('compareVersion') !== 'no_compare';

  const selectedCountryList = searchParams.get('trendCountry') && searchParams.get('trendCountry') !== '-1'
    ? searchParams.get('trendCountry').split(',')
    : [];
  const selectedCompareCountryList = searchParams.get('compareCountry') && searchParams.get('compareCountry') !== '-1'
    ? searchParams.get('compareCountry').split(',')
    : [];
  const selectUserSceneTagList = (searchParams.get('trendUserSceneTag') || '').split(',').filter(x => x);

  const compareMethodValue = Number(searchParams.get('isRealTime'));
  const isRealTime = !!compareMethodValue;

  const compareMethodOptions = [
    { label: t('EXCP_OVERVIEW.小时级对比'), value: 1 },
    { label: t('EXCP_OVERVIEW.日级对比'), value: 0 },
  ];

  const timePickerOptions = compareEnabled
    ? trendDate.options.filter(x => x !== '今天')
    : trendDate.options;

  const versionTree = isIntegratedAppTrend
    ? integratedAppIdToVersionTree[integratedAppId] || []
    : trendVersion.versionTree;


  const trendVersionListValue = searchParams.get('trendVersion') !== '-1'
    ? CsvUtil.singleLineParse(searchParams.get('trendVersion'))
    : [];

  console.log('searchParams>>>', trendVersionListValue, versionTree);

  const compareVersionListValue = (searchParams.get('compareVersion') !== '-1' && searchParams.get('compareVersion') !== 'no_compare')
    ? CsvUtil.singleLineParse(searchParams.get('compareVersion'))
    : [];

  const isMultipleVersions = VersionUtil.isMultipleVersions(trendVersionListValue) || VersionUtil.isMultipleVersions(compareVersionListValue);

  return <div style={{ fontSize: '14px', display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginTop: '20px', position: 'relative' }}>
    <Row
      gutter={[10, 6]}
      align='top'
    >
      {/* 基础筛选部分 */}
      { (hasIntegratedApps || (isErrorType && hasErrorReportDiscardStrategy) || PlatformUtils.isAndroidOrHarmony(routerPid) || (!disableCompare && compareEnabled))
        && <Col>
        <Row gutter={[10, 6]} align='middle'>
        {
            hasIntegratedApps
            && <Col>
              <Select
                style={{ width: '320px', fontSize: '14px' }}
                value={isIntegratedAppTrend}
                options={[
                  { label: ze('按组件版本', 'By SDK Version'), value: false },
                  { label: ze('按接入项目和版本', 'By Integrated App And Version'), value: true },
                ]}
                onChange={value => handleChangeSearchParam('isIntegratedAppTrend', value)}
              />
            </Col>
          }
          {
            isErrorType && hasErrorReportDiscardStrategy &&
            <Col>
              <Select
                style={{ width: '160px', fontSize: '14px' }}
                value={Number(isTrendDataBeforeGrayStrategyDrop)}
                options={[
                  { label: ze('丢弃后数据', 'After Discarded Reports'), value: 0 },
                  { label: ze('丢弃前数据', 'Before Discarded Reports'), value: 1 },
                ]}
                onChange={(value) => setIsTrendDataBeforeGrayStrategyDrop(value)}
              />
            </Col>
          }
          {
            PlatformUtils.isAndroidOrHarmony(routerPid) && <Col>
              <VmTypeSelect
                value={vmTypeListValue}
                onChange={(v) => onVmFilterSelectChange(v)}
              />
            </Col>
          }
          {
            !disableCompare && compareEnabled && <Col>
              <Select
                style={{ width: '150px', fontSize: '14px' }}
                value={compareMethodValue}
                options={compareMethodOptions}
                onChange={v => handleChangeSearchParam('isRealTime', v)}
              />
            </Col>
          }
        </Row>
      </Col>}

      {/* 对比选择部分 */}
      <Col>
        <Row gutter={[10, 6]} align='middle'>
          { isIntegratedAppTrend && <Col>
            <Select
              style={{ width: '320px', fontSize: '14px' }}
              value={integratedAppId}
              options={integratedAppOptions}
              optionFilterProp='label'
              showSearch={true}
              onChange={v => handleChangeSearchParam('integratedAppId', v)}
              placeholder='项目名'
            />
          </Col>}
          <Col>
            <VersionHybridSelect
              style={{ width: '320px', fontSize: '14px' }}
              value={trendVersionListValue}
              treeData={versionTree}
              onChange={v => handleChangeSearchParam('trendVersion', CsvUtil.singleLineStringify(v) || '-1')}
            />
          </Col>
          <Col style={{ width: '220px' }}>
            <TimePicker
              onChange={(value) => {
                handleChangeSearchParam('trendDate', preprocessDate(value));
              }}
              options={timePickerOptions}
              singleDate={compareEnabled && isRealTime}
              defaultSelect={refineDate(date1, startDate1, endDate1)}
            />
          </Col>
          { enableUserSceneTag && <Col>
            <UserSceneTagSelect
              isMultiple={true}
              style={{ width: '320px' }}
              value={selectUserSceneTagList}
              onChangeWhenDropdownClosed={v => handleChangeSearchParam('trendUserSceneTag', v)}
            />
          </Col> }
          { isRegionalDashboard && <Col>
            <CountrySelect
              isMultiple={true}
              style={{ width: '320px', fontSize: '14px' }}
              value={selectedCountryList}
              onChangeWhenDropdownClosed={v => handleChangeSearchParam('trendCountry', v)}
            />
          </Col> }
        </Row>
        { !disableCompare && compareEnabled && <Row gutter={10} align='middle' style={{ marginTop: '8px' }}>
          { isIntegratedAppTrend && <Col>
            <Tooltip title='对比项目必须一致' placement='bottom'>
              <Select
                disabled={true}
                style={{ width: '320px', fontSize: '14px' }}
                value={integratedAppId}
                options={integratedAppOptions}
                showSearch={true}
                onChange={v => handleChangeSearchParam('integratedAppId', v)}
                placeholder='项目名'
              />
            </Tooltip>
          </Col> }
          <Col>
            <VersionHybridSelect
              style={{ width: '320px', fontSize: '14px' }}
              value={compareVersionListValue}
              treeData={versionTree}
              onChange={v => handleChangeSearchParam('compareVersion', CsvUtil.singleLineStringify(v) || '-1')}
            />
          </Col>
          <Col style={{ width: '220px' }}>
            <TimePicker
              onChange={(value) => {
                handleChangeSearchParam('compareDate', preprocessDate(value));
              }}
              options={timePickerOptions}
              defaultSelect={refineDate(date2, startDate2, endDate2)}
              singleDate={compareEnabled && isRealTime}
            />
          </Col>
          { isRegionalDashboard && <Col>
            <CountrySelect
              style={{ width: '320px', fontSize: '14px' }}
              isMultiple={true}
              value={selectedCompareCountryList}
              onChangeWhenDropdownClosed={v => handleChangeSearchParam('compareCountry', v)}
            />
          </Col>}
        </Row> }
      </Col>
    </Row>

    { isMultipleVersions && <Row style={{ marginTop: '20px', width: '100%', color: orange.primary, fontSize: '14px' }}>
      <Space style={{ height: '14px', lineHeight: '14px' }}>
        <ExclamationCircleOutlined/>
        <div>{ t('EXCP_OVERVIEW.multipleVersionsWarning')}</div>
      </Space>
    </Row> }
    {
      !disableCompare
        ? <Tooltip title={compareEnabled ? t('EXCP_OVERVIEW.关闭对比') : t('EXCP_OVERVIEW.开启对比')} placement='left'>
          <div className={_style.compareBtn}
            onClick={() => {
              handleChangeSearchParam('compareVersion',  compareEnabled ? 'no_compare' : '-1');
            }}>
            <DuibiIconNormal />
          </div>
        </Tooltip>
        : null
    }
  </div>;
};

SummaryOptions.propTypes = {
  style: PropTypes.object,
  handleChangeSearchParam: PropTypes.func,
  searchParams: PropTypes.object,
  selectOptions: PropTypes.object,
  isRegionalDashboard: PropTypes.bool, // 是否是国家维度的大盘统计
  disableCompare: PropTypes.bool,
};

export default SummaryOptions;
