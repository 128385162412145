import React from 'react';
import style from './style.scss';

const CopyRight = () => {
  const currentYear = new Date().getFullYear();
  return (
    !window.IS_CROS_IFRAME ? (
      <footer className={style.copyright}>
        <p>Copyright © 1998 - {currentYear} Tencent. All Rights Reserved. </p>
      </footer>
    ) : null
  );
};

export default CopyRight;
