import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Modal, Space, Spin, Table, Tag, Popover, Tooltip, Divider } from 'antd';
import { ze } from 'utils/zhEn';
import { dataComplianceConfirm } from 'utils/dataComplianceConfirm';
import { downloadAsFile, downloadWithUrl } from 'utils/file-utils';
import { CrashAttachmentUtil, getHelpLink } from 'utils/crash-attachment-util';
import { useTranslation } from 'react-i18next';
import { isPcOrLinux,isMobile, isAndroidOrHarmony, isIos, isPlaystation } from 'utils/platform';
import { isNullish } from 'utils/nullish';
import { getReportCategoryByExceptionTypeInt } from 'utils/constants/exception-type-int';
import { ExceptionCategory, ExceptionCategoryUtil } from 'utils/exception-category';
import scss from './CrashAttachmentShowcase.scss';
import AiOutlineFileSearch from 'svg/v2/newcs_dashboard_crashanalysis_issuedetail_downloadattachents_view_normal.svg';
import DownloadIcon from 'svg/v2/newcs_dashboard_download_icon_normal.svg';
import { CS_STYLES } from 'utils/constants/style-constants';
import WrappedTipsIcon from 'components/antd-extension/WrappedTipsIcon.jsx';
import { getBackendInjectEnvs } from 'utils/backend-inject-envs';

const CrashAttachmentShowcase = ({
  reduxState,
  hasSdkLogFile,
  hasSdkAttachFile,
  onClickDownloadSdkLogFile,
  onClickDownloadSdkAttachFile,
}) => {
  const { t } = useTranslation();
  const currentIssue = reduxState.issue.get('current').toJS();
  const currentApp = reduxState.app.get('current').toJS();
  const { platformId: pid, subAppType} = currentApp;
  let {
    crashDoc,
    issueExceptionType,
    attachList: mixedAttachList,
    pcStackMemoryLines: minidumpStackMemoryLines,
  } = currentIssue;
  minidumpStackMemoryLines = minidumpStackMemoryLines || [];
  const { crashMap, esMap } = crashDoc || {};
  const {
    expUid,
    sdkVersion,
    dumpId,
  } = crashMap || {};

  const knownAttachmentFilenames = CrashAttachmentUtil.getKnownAttachmentFilenames();

  const isCrash = ExceptionCategoryUtil.unify(getReportCategoryByExceptionTypeInt(issueExceptionType)) === ExceptionCategory.CRASH

  const sdkAttachList = [
    hasSdkLogFile && { fileName: CrashAttachmentUtil.getSdkLogFilename(pid, sdkVersion), content: '' },
    hasSdkAttachFile && { fileName: CrashAttachmentUtil.getSdkAttachFilename(pid, sdkVersion), content: '' },
  ].filter(x => x);

  const pcStackMemoryAttachList = minidumpStackMemoryLines.length > 0
    ? [{ fileName: CrashAttachmentUtil.MINIDUMP_STACK_MEMORY_FILENAME, content: minidumpStackMemoryLines.join('\n') }]
    : [];

  const serverKnownAttachList = isPcOrLinux(pid)
    ? (mixedAttachList || [])  // 新版PC附件全部展示
    : (mixedAttachList || []).filter(x => knownAttachmentFilenames.includes(x.fileName));

  const dumpDownloadUrl = (isPcOrLinux(pid) && isCrash && dumpId)
    ? `${getBackendInjectEnvs().cosDownloadOrigin}/${dumpId}.dmp.gz`
    : '';

  const dumpFileList = dumpDownloadUrl ? [{fileName: 'Minidump.dmp.gz', downloadUrl: dumpDownloadUrl}] :[];
  const psAttachmentsFileList = isPlaystation(pid) && isCrash && esMap.psAttachmentsDownloadUrl ? [{fileName: 'PsAttachments.zip', downloadUrl: esMap.psAttachmentsDownloadUrl}] :[];

  const knownAttachList = [...serverKnownAttachList, ...sdkAttachList, ...pcStackMemoryAttachList, ...dumpFileList, ...psAttachmentsFileList];

  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [viewModalFilename, setViewModalFilename] = useState('');
  const [viewModalContent, setViewModalContent] = useState('');

  async function viewFileContent(filename, content) {
    const fileInfo = CrashAttachmentUtil.getInfoByFilename(filename);
    const { isBase64Encoded, alias } = (fileInfo || {});
    let fileContent = isBase64Encoded
      ? new TextDecoder().decode(Uint8Array.from(window.atob(content), c => c.charCodeAt(0)))
      : content;
    fileContent = CrashAttachmentUtil.refineFileContent(pid, filename, fileContent);
    setViewModalFilename(alias || filename);
    setViewModalContent(fileContent);
    setViewModalVisible(true);
  }

  async function download(filename, content) {
    await dataComplianceConfirm();
    if (filename === CrashAttachmentUtil.getSdkLogFilename(pid, sdkVersion)) {
      onClickDownloadSdkLogFile?.(filename);
      return;
    } else if (filename === CrashAttachmentUtil.getSdkAttachFilename(pid, sdkVersion)) {
      onClickDownloadSdkAttachFile?.(filename);
      return;
    }

    const fileInfo = CrashAttachmentUtil.getInfoByFilename(filename);
    const { isBase64Encoded, alias } = (fileInfo || {});
    let downloadContent = isBase64Encoded
      ? Uint8Array.from(window.atob(content), c => c.charCodeAt(0))
      : content;
    downloadContent = CrashAttachmentUtil.refineFileContent(pid, filename, downloadContent);
    const downloadFilename = `${expUid}_${alias || filename}`;
    downloadAsFile(downloadFilename, downloadContent);
  }

  function openAsNewTextTab(filename, content) {
    const fileInfo = CrashAttachmentUtil.getInfoByFilename(filename);
    const { alias, isBase64Encoded } = (fileInfo || {});
    const downloadContent = isBase64Encoded
      ? Uint8Array.from(window.atob(content), c => c.charCodeAt(0))
      : content;
    const newTab = window.open('', '_blank');
    const dom = newTab.document.createElement('pre');
    const divider = '-'.repeat(64);
    dom.innerText = `Client Report ID: ${expUid}\nFile: ${alias || filename}\n\n${divider}\n\n${downloadContent}`;
    if (!newTab.document.body) {
      newTab.document.body = newTab.document.createElement('body');
    }
    newTab.document.body.appendChild(dom);
  }

  const disableViewText = viewModalContent.length > 3 * 1024 * 1024; // 超过3M的附件不支持预览，只能下载

  if (knownAttachList.length === 0) {
    return <div style={{ fontSize: '14px', marginTop: '10px' }}>
      { ze('没有可下载的附件', 'No Downloadable Attachments') }
    </div>;
  }


  const getAttachMaxSizeNote = (fileInfo,record) => {
    let maxSizeNote;
    if(fileInfo.dataSourceDesc === 'SetCrashLogObserver' || fileInfo.dataSourceDesc === 'SetCustomLogDir'){//日志附件
      maxSizeNote = isMobile(pid) ? fileInfo.noteMobileMax : fileInfo.notePcMax;
    }else if(fileInfo.dataSourceDesc === 'OnCrashExtraMessageNotify'){//存在extramsg和extrainfo两种类型
      if(record.content && record.content.startsWith('extmsg')){//extramsg
        maxSizeNote = isMobile(pid) ? fileInfo.extraMsg.noteMobileMax : fileInfo.extraMsg.notePcMax;
      }else{//extrainfo
        maxSizeNote = isMobile(pid) ? fileInfo.extraInfo?.noteMobileMax : fileInfo.extraInfo?.notePcMax;
      }
    }else if(fileInfo.dataSourceDesc === 'OnCrashExtraDataNotify'){//OnCrashExtraDataNotify
      maxSizeNote = isAndroidOrHarmony(pid) ? fileInfo.noteAndroidMax : null;
    }else if(fileInfo.dataSourceDesc === 'SetLogPath'){//PS5,SetLogPath
      maxSizeNote = fileInfo.noteMax;
    }else{//其他情况
      maxSizeNote = isMobile(pid) ? fileInfo.noteMobileMax : fileInfo.notePcMax;
    }
    return maxSizeNote;
  }

  /** 根据特殊情况，过滤一些不展示的attach */
  const knownAttachListFilter = (knownAttach) => {
    // stackMemoryInfo.txt 不在iOS错误中展示
    if (knownAttach.fileName === "stackMemoryInfo.txt") {
      if (isIos(pid) && ExceptionCategoryUtil.unify(getReportCategoryByExceptionTypeInt(issueExceptionType)) === ExceptionCategory.ERROR) {
        return false;
      }
    }
    // 直接隐藏stackText
    else if (knownAttach.fileName === "stackText") {
      return false;
    }
    return true;
  }

  const viewModal = <Modal
    title={viewModalFilename}
    visible={viewModalVisible}
    maskClosable={true}
    width='80%'
    onCancel={() => setViewModalVisible(false)}
    footer={[
      <Button onClick={() => setViewModalVisible(false)}>{t('common.close')}</Button>,
      !disableViewText && <Button type='primary' onClick={() => openAsNewTextTab(viewModalFilename, viewModalContent)}>{ze('在新页面查看', 'View In A New Tab')}</Button>,
    ]}
  >
    <pre
      style={{
        background: 'none',
        minHeight: '200px',
        maxHeight: 'calc(100vh - 360px)',
        overflowY: 'auto',
      }}
    >{disableViewText ? t('REPORTDETAIL.日志太大提示') : viewModalContent}</pre>
  </Modal>;

  const columns = [
    {
      title: ze('文件名','File'),
      dataIndex: 'fileName',
      key: 'fileName',
      render: (_, record) => {
        const { fileName } = record;
        const fileInfo = CrashAttachmentUtil.getInfoByFilename(fileName);
        const attacthMaxSizeNote = fileInfo && getAttachMaxSizeNote(fileInfo,record)
        return  <div style={{display:'flex',alignItems:'center',gap:'2px'}}>
          {(fileInfo || {}).alias || fileName}
          {attacthMaxSizeNote ? <Tooltip title={attacthMaxSizeNote} trigger="hover" overlayStyle={{ zIndex: 10000 }}>
            <WrappedTipsIcon/>
          </Tooltip>: null}
        </div>
      },
    },
    {
      title: ze('数据来源','Data Source'),
      key: 'dataSource',
      render: (_, record) => {
        const { fileName, content } = record;
        const fileInfo = CrashAttachmentUtil.getInfoByFilename(fileName);
        const { dataSourceType, dataSourceDesc } = fileInfo || {};
        const link = getHelpLink(subAppType,pid,fileName);
        return <Space size={0}>
          { dataSourceType && <Tag color='geekblue'>{ dataSourceType }</Tag> }
          { link ? <a href={link} target="_blank">{ dataSourceDesc || '-' }</a> : <div>{ dataSourceDesc || '-' }</div> }
        </Space>;
      },
    },
    {
      title: ze('操作','Operations'),
      dataIndex: 'fileName',
      key: 'fileName',
      render: (_, record) => {
        const { fileName, content, downloadUrl } = record;
        const allowView = !!content;
        return <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          { allowView && <Button
            className={scss.iconButton}
            style={{ padding: 0 }}
            size='small'
            type='link'
            onClick={() => viewFileContent(fileName, content)}
          ><AiOutlineFileSearch /></Button> }
          { allowView && <Divider type="vertical"/> }
          <Button
            className={scss.iconButton}
            style={{ padding: 0 }}
            size='small'
            type='link'
            onClick={() => {
                if (downloadUrl) {
                  downloadWithUrl(fileName, downloadUrl)
                } else {
                  download(fileName, content)
                }
              }
            }
          ><DownloadIcon/></Button>
        </div>;
      }
    },
  ];

  return <div
    className={scss.crashAttachmentShowcase}
    style={{ fontSize: '14px', marginTop: '10px' }}
  >
    <Table
      size='small'
      columns={columns}
      dataSource={knownAttachList.filter((data) => knownAttachListFilter(data))}
    />
    { viewModal }
  </div>;
};

const mapStateToProps = state => ({
  reduxState: state,
});

CrashAttachmentShowcase.propTypes = {
  reduxState: PropTypes.object.isRequired,
  hasSdkLogFile: PropTypes.bool,
};

export default connect(mapStateToProps)(CrashAttachmentShowcase);
