import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { checkEmail } from 'utils/Validate';
import style from './style.scss';
import { withTranslation } from 'react-i18next'

@withTranslation()
export default class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: props.currentUser.email,
      isEmailOk: true,
      isSaveSucc: false,
    };
  }

  handleSave() {
    const { actions, currentUser } = this.props;
    if (this.state.isEmailOk) {
      actions.changeUserParamOptions({ name: 'email', value: this.state.email });
      actions.updateUserInfo().then(actions.replaceState(`/user/${currentUser.userId}?tag=account`));
    }
  }

  handleEmailChange(e) {
    const value = e.target.value.trim();
    this.setState({ email: value, isEmailOk: checkEmail(value) });
  }

  render() {
    return (
      <div className={style.mail_content}>
        <p>
          { this.props.t("ACCOUNT.mail_editTip2")}
        </p>
        {this.state.isEmailOk && <div className={style.error_info}>
          {this.props.t('ACCOUNT.mail_error')}
        </div>}
        <div className={this.state.isEmailOk ? '' : style.error}>
          <input type="text" className="inputMod" placeholder={this.props.t('ACCOUNT.mail_editPlaceholder')} value={this.state.email} onChange={(e) => { this.handleEmailChange(e); }} />
        </div>
        {/* <div className="btn btn_blue" onClick={() => { this.handleSave(); }}>{ this.props.t("ACCOUNT.mail_editSave")}</div> */}
        {this.state.isSaveSucc && <span className={style.save_succ}>{ this.props.t("ACCOUNT.mail_editSaveSuccess")}</span>}
      </div>
    );
  }
}

Welcome.propTypes = {
  currentUser: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};
