import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ze } from 'utils/zhEn';
import { Select, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import throttle from 'lodash/function/throttle';
import RestHelper from 'utils/RestHelper';
import { updateSummarySelectOptions } from 'reducers/summary/summaryActions';
import { DashboardConst } from 'utils/constants/dashboard-const';
import { CsSelect } from 'components/antd-extension/CsSelect';
import { selectCurrentAppCustomUserSceneTagLabel } from 'utils/selectors/selectors';

const UserSceneTagSelect = ({
  value,
  isMultiple,
  style,
  onChangeWhenDropdownClosed = () => {},
}) => {
  const dispatch = useDispatch();
  const currentApp = useSelector(reduxState => reduxState.app.get('current').toJS());
  const selectOptions = useSelector(reduxState => reduxState.summary.get('selectOptions'));
  const { userSceneTagOptions } = selectOptions.toJS();
  const { appId, platformId } = currentApp;

  const customUserSceneTagLabel = useSelector(selectCurrentAppCustomUserSceneTagLabel);

  const [loading, setLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [intermediateValue, setIntermediateValue] = useState(null);

  useEffect(() => {
    setIntermediateValue(value);
  }, [value]);

  async function fetchOptions() {
    setLoading(true);
    const rsp = await RestHelper.post('/redir/api/statResource/getUserSceneTagList', {
      appId,
      platformId,
    });
    const userSceneTagList = rsp.json.data;
    const userSceneTagOptions = [
      ...(userSceneTagList || [])
        .filter(x => x !== DashboardConst.USER_SCENE_TAG_ALL && x !== DashboardConst.USER_SCENE_TAG_TOTAL_OF_ALL_TAGS)
        .sort()
        .map(x => ({ value: x, label: x })),
    ];
    dispatch(updateSummarySelectOptions({ userSceneTagOptions }));
    setLoading(false);
  }

  function throttledFetchOptions() {
    throttle(
      () => fetchOptions(),
      60000,
      { trailing: false },
    );
  }

  const placeHolder = ze(`全${customUserSceneTagLabel || '场景'}`, `All ${customUserSceneTagLabel || 'Scenes'}`);

  const dom = <CsSelect
    showSearch={true}
    allowClear={false}
    placeholder={placeHolder}
    style={{ width: '160px', ...style }}
    {...(isMultiple ? { mode: 'multiple' } : {})}
    value={intermediateValue}
    options={userSceneTagOptions}
    showArrow={true}
    dropdownRender={(originNode) => {
      return <Spin
        spinning={loading}
      >{ originNode }</Spin>;
    }}
    onChange={(v) => {
      if (dropdownOpen) {
        setIntermediateValue(v);
      } else {
        onChangeWhenDropdownClosed(v);
      }
    }}
    onDropdownVisibleChange={(open) => {
      setDropdownOpen(open);
      if (open) {
        if (userSceneTagOptions.length > 0) {
          throttledFetchOptions();
        } else {
          fetchOptions();
        }
        setIntermediateValue(value);
      } else if (value !== intermediateValue) {
        onChangeWhenDropdownClosed(intermediateValue);
      }
    }}
  />;

  return dom;
};

export default UserSceneTagSelect;
