export const CrashModuleUtil = Object.freeze({
  /**
   * @param soName {string}
   * @param lowerSystemSoNameList {string[]}
   * @param lowerAppSoNameList {string[]}
   * */
  getIsSystemModule(soName, lowerSystemSoNameList, lowerAppSoNameList) {
    const lowerSoName = (soName || '').toLowerCase();
    if (lowerAppSoNameList && lowerAppSoNameList.length > 0) {
      return !lowerAppSoNameList.includes(lowerSoName);
    } else {
      return lowerSystemSoNameList.includes(lowerSoName);
    }
  },
});
