import React from 'react';
// import Alarm from '-!babel-loader!svg-react-loader!svg/alarm.svg?name=Alarm';
import Alarm from '../../../svg/alarm.svg';
import style from './style.scss';

const EmptyAlarm = () => {
  return (
    <div className={style.wrapper}>
      <Alarm />
    </div>
  );
};

export default EmptyAlarm;
