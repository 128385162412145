import React, { useState, useEffect, useMemo } from 'react';
import RestHelper from 'utils/RestHelper';
import { Modal } from 'antd';
import CountryGroupConfigCore from 'containers/CountryGroupConfigCore';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { fetchCountryGroupConfig, setCountryGroupConfigModalVisible } from 'reducers/summary/summaryActions';
import { connect } from 'react-redux';
import { ze } from 'utils/zhEn';

const CountryGroupConfigModal = (props) => {
  const { reduxState, actions } = props;
  const visible = reduxState.summary.get('countryGroupConfigModalVisible');
  return <Modal
    title={ze('自定义地区配置', 'Config Custom Areas')}
    visible={visible}
    width='860px'
    footer={null}
    maskClosable={false}
    onCancel={() => {
      actions.setCountryGroupConfigModalVisible(false);
      actions.fetchCountryGroupConfig();
    }}
  >
    <CountryGroupConfigCore/>
  </Modal>;
};

const mapStateToProps = state => ({
  reduxState: state,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    setCountryGroupConfigModalVisible,
    fetchCountryGroupConfig,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CountryGroupConfigModal);
