import EventEmitter from 'wolfy87-eventemitter';

const ee = new EventEmitter();

export const addListener = (eventName, handlers) => {
  ee.addListener(eventName, handlers);
};

export const trigger = (eventName, ...args) => {
  ee.emitEvent(eventName, args);
};

// alias to trigger
export const emit = trigger;

export const removeListener = (eventName, handlers) => {
  ee.removeListener(eventName, handlers);
};

export const autoDismiss = 4; // 默认消失时间

export const showSuccMsg = (msg, defaultAutoDismiss = 4) => {
  return trigger('notify.show', {
    message: msg,
    type: 'success',
    autoDismiss: defaultAutoDismiss,
  });
};

export function showErrorMsg(msg, defaultAutoDismiss = 4) {
  return trigger('notify.show', {
    message: msg,
    type: 'error',
    autoDismiss: defaultAutoDismiss,
  });
}

export function show(message, type) {
  trigger('notify.show', {
    message,
    type,
    autoDismiss,
    dismissible: type === 'error' || type === 'warning',
  });
}
