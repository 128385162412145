import React, {
  useState,
  useEffect,
  useMemo,
  useDebugValue,
  useRef
} from 'react';
import { bindActionCreators } from 'redux';
import { useImmer } from 'use-immer';
import { connect } from 'react-redux';
import * as appActions from 'reducers/app/appActions';
import * as productActions from 'reducers/product/actions';
import * as globalActions from 'reducers/global/globalActions';
import * as issueActions from 'reducers/issue/issueActions';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  DatePicker,
  Input,
  Menu,
  message,
  notification,
  Radio,
  Row,
  Select,
  Spin,
  Table,
} from 'antd';
import RestHelper from "utils/RestHelper";
import ReactECharts from 'echarts-for-react';
import moment from "moment";
import {Link} from "react-router-dom";
import IssueCrashFilter, {
  IssueCrashFilterUtil
} from 'components/commons/IssueCrashFilter/IssueCrashFilter';
import {
  getReportCategoryByExceptionTypeInt
} from "utils/constants/exception-type-int";
import { isNotNullish } from 'utils/nullish';
import LastReport from 'components/exception/issue/LastReport/LastReport';
import reportRecordScss from './ReportRecord/style.scss';
import { isIos, isMobile } from 'utils/platform';
import {
  convertIosModelToProductName,
} from 'utils/model-convert/ios-model-convert';
import { useZhEn } from 'utils/react-hooks/useZhEn';
import { formatCrashPercentageString, formatEnNumber } from 'utils/format/format-number';
import IssueCrashFilterEx from 'components/commons/IssueCrashFilter/IssueCrashFilterEx';
import {
  FieldName,
  IssueCrashFilterExUtil,
  LogicalOperator,
  QueryType,
} from 'components/commons/IssueCrashFilter/IssueCrashFilterExUtil';
import { SearchConditionGroupUtil } from 'components/commons/IssueCrashFilter/SearchConditionGroupUtil';
import { COLOR_LIST } from 'utils/constants/chart-options';
import { CrashUtil } from 'utils/api/crash';

const { RangePicker } = DatePicker;

const RULE_CATEGORY_GLOBAL = 'GLOBAL';
const RULE_CATEGORY_CUSTOM = 'CUSTOM';

const EXCEPTION_CATEGORIES_OPTIONS = [
  { label: '崩溃', value: 'CRASH' },
  { label: '卡顿', value: 'ANR' },
  { label: '错误', value: 'ERROR' },
];

function tryDecodeUriComponent(s) {
  try {
    return decodeURIComponent(s);
  } catch (e) {
    return s;
  }
}

const IssueFeatureAnalysis = (props) => {
  const { reduxState, actions, dispatch, path } = props;
  const currentApp = reduxState.app.get('current').toJS();
  const { appId, pid: platformId } = currentApp;
  const { t } = useTranslation();
  const { ze } = useZhEn();

  const issueObj = reduxState.issue.toJS();
  const versionOptions = issueObj.selectOptions.version.options.filter(x => x.value && x.value !== 'all');
  const { issueId, issueExceptionType } = issueObj.current;

  const localStorageDict = reduxState.global.get('localStorage').toJS();
  const {
    issueIdToFeatureAnalysisPreferences,
  } = localStorageDict;

  const [state, updateState] = useImmer({
    filterData: [
      { name: 'version', value: [] },
      { name: 'uploadTime', value: { date: 'last_7_day', endDate: '', startDate: ''} },
    ],
    searchConditionGroup: {
      conditions: [
        { field: FieldName.crashUploadTime, queryType: QueryType.RANGE_RELATIVE_DATETIME, gte: 7 * 86400 * 1000 },
        { field: FieldName.version },
      ],
    },
    featureOptions: [],
    selectedFeatureKeys: [],
    chartLoading: false,
    chartFeatureOptions: [],
    commonMatchedCount: 0,
    selectedFeatureCountList: [],
    clickedChartIndex: null,
    specifiedFeatureCrashList: [],
    tableLoading: false,
    tableTotal: 0,
    tablePageSize: 10,
    tablePageNum: 1,
    tableSortField: null,
    tableIsDesc: false,
    expandedTableRowKey: '',
    expandedCrashId: '',
  });
  const {
    searchConditionGroup,
    featureOptions,
    selectedFeatureKeys,
    chartLoading,
    chartFeatureOptions,
    commonMatchedCount,
    selectedFeatureCountList,
    clickedChartIndex,
    specifiedFeatureCrashList,
    tableLoading,
    tableTotal,
    tablePageSize,
    tablePageNum,
    tableSortField,
    tableIsDesc,
    expandedTableRowKey,
    expandedCrashId,
  } = state;

  const [fetchTrigger, setFetchTrigger] = useState(0);
  const [querySpecifiedFeatureTrigger, setQuerySpecifiedFeatureTrigger] = useState(0);

  const specifiedFeatureKey = isNotNullish(clickedChartIndex)
    ? (chartFeatureOptions[clickedChartIndex] || {}).key
    : '';
  const specifiedFeatureName = isNotNullish(clickedChartIndex)
    ? (chartFeatureOptions[clickedChartIndex] || {}).name
    : '';

  const reportCategory = getReportCategoryByExceptionTypeInt(issueExceptionType);

  const exceptionCategory = {
    crashes: 'CRASH',
    blocks: 'ANR',
    errors: 'ERROR',
  }[reportCategory];

  async function fetchFeatureOptions() {
    const [rspCustom, rspGlobal] = await Promise.all([
      RestHelper.mustPost(
        '/api/featureAnalysis/listFeatureConfigs', { appId }),
      RestHelper.mustPost(
        '/api/feature-analysis/getGlobalFeatureConfig', {
          appId,
        }),
    ]);

    const customFeatureOptions = rspCustom.json.data
      .map(x => ({
        label: x.name,
        value: x.key,
        ...x,
        exceptionCategoryList: x.exceptionCategories.split(','),
        category: RULE_CATEGORY_CUSTOM,
        contentJson: x.content || {},
      }));
    const globalFeatureOptions = rspGlobal.json.data
      .map(x => ({
        label: x.name,
        value: x.key,
        ...x,
        exceptionCategoryList: x.exceptionCategories.split(','),
        category: RULE_CATEGORY_GLOBAL,
        contentJson: JSON.parse(x.content),
      }))
      .filter(x => x.enabled);
    const featureOptions = [...customFeatureOptions, ...globalFeatureOptions]
      .filter(x => x.exceptionCategoryList.includes(exceptionCategory));
    const availableKeys = featureOptions.map(x => x.value);
    updateState(draft => {
      draft.featureOptions = featureOptions;
      draft.selectedFeatureKeys = draft.selectedFeatureKeys.filter(x => availableKeys.includes(x));
    });
  }

  function saveSearchConditionGroupToStorage() {
    actions.setPartialLocalStorage({
      issueIdToFeatureAnalysisPreferences: {
        ...issueIdToFeatureAnalysisPreferences,
        [issueId]: {
          searchConditionGroup,
          selectedFeatureKeys,
        },
      },
    });
  }

  async function queryMultipleFeatureForIssue() {
    const featureKeyList = selectedFeatureKeys.length > 0
      ? selectedFeatureKeys
      : featureOptions.map(x => x.key);
    if (featureKeyList.length > 50) {
      message.error('需要分析的特征数不能超过 50 个');
      return;
    }
    updateState(draft => {
      draft.clickedChartIndex = null;
      draft.chartLoading = true;
    });
    const rsp = await RestHelper.mustPost('/redir/query/data/queryMultipleFeaturesOnIssue', {
      appId,
      platformId,
      issueId,
      searchConditionGroup,
      featureKeyList,
    });
    const { topLevelMatchedCount, featureKeyToMatchedCount } = rsp.json.data;
    const selectedFeatureOptions = featureKeyList
      .map(k => featureOptions.find(o => o.key === k))
      .filter(x => x);
    const featureMatchedCountList = selectedFeatureOptions.map(o => featureKeyToMatchedCount[o.key] || 0);

    updateState(draft => {
      draft.chartFeatureOptions = selectedFeatureOptions;
      draft.commonMatchedCount = topLevelMatchedCount;
      draft.selectedFeatureCountList = featureMatchedCountList;
      draft.chartLoading = false;
    });
  }

  async function querySpecifiedFeatureCrashList() {
    updateState(draft => {
      draft.tableLoading = true;
    });
    const specifiedFeature = featureOptions.find(x => x.key === specifiedFeatureKey);
    if (!specifiedFeature) {
      message.error(`key: ${specifiedFeatureKey} 不存在`);
      return;
    }

    const rows = tablePageSize;
    const start = (tablePageNum - 1) * tablePageSize;
    const { rawSearchGroup } = specifiedFeature.contentJson;

    let searchScg;
    if (specifiedFeature.realtimeTag) {
      const mustFeatureCondition = { field: FieldName.featureTag, queryType: QueryType.TERM, term: specifiedFeature.key };
      searchScg = SearchConditionGroupUtil.addMustCondition(searchConditionGroup, mustFeatureCondition);
    } else {
      const multipleOrSearchConditionGroups = rawSearchGroup.map(rawSearch => IssueCrashFilterExUtil.makeSearchConditionGroupFromSearchParamsDict(rawSearch));
      searchScg = {
        subgroups: [
          searchConditionGroup,
          {
            operator: LogicalOperator.OR,
            subgroups: multipleOrSearchConditionGroups,
          },
        ],
      };
    }

    const rsp = await RestHelper.post('/api/crash/queryCrashList', {
      ...(isNotNullish(tableSortField) && { sortField: tableSortField, desc: tableIsDesc }),
      appId,
      platformId,
      issueId,
      start,
      rows,
      searchConditionGroup: searchScg,
    })

    const { crashIdList, crashDatas, numFound } = rsp.json.ret;
    const crashList = crashIdList.map(x => crashDatas[x])
      .map(x => ({
        ...x,
        key: x.crashId,
      }));
    updateState(draft => {
      draft.tableLoading = false;
      draft.specifiedFeatureCrashList = crashList;
      draft.tableTotal = numFound;
    });
  }

  async function queryExpandedCrashDetail() {
    const { getCrashDoc, getCrashAttachment } = actions;

    const tasks = [getCrashDoc(issueId, expandedCrashId), getCrashAttachment(issueId, expandedCrashId)];
    Promise.all(tasks);
  }

  useEffect(() => { // 初始化
    if (!appId || !issueId) {
      return;
    }

    // 读取storage存档
    const featureAnalysisPreferences = issueIdToFeatureAnalysisPreferences[issueId] || {};
    const {
      filterData,
      searchConditionGroup,
      selectedFeatureKeys,
    } = featureAnalysisPreferences;
    if (filterData) { // 适配旧版使用了filterData的搜索， TODO: 预计2023-11-01之后可以删除这个兼容逻辑
      const conditions = IssueCrashFilterUtil.makeSearchConditionGroupConditionsFromFilterData(filterData);
      const searchConditionGroup = { conditions };
      updateState(draft => ({ ...draft, searchConditionGroup }));
    }
    if (searchConditionGroup) {
      updateState(draft => ({ ...draft, searchConditionGroup }));
    }
    if (selectedFeatureKeys) {
      updateState(draft => ({ ...draft, selectedFeatureKeys }));
    }

    (async () => {
      await fetchFeatureOptions();
    })();
  }, [appId, issueId]);

  function onChartRefChange(element) {
    if (!element) {
      return;
    }
    const chartInstance = element.getEchartsInstance();
    chartInstance.getZr().on('click', params => {
      const pointInPixel = [params.offsetX, params.offsetY];
      // console.log('gomo pointInPixel', pointInPixel);
      const pointInGrid = chartInstance.convertFromPixel('grid', pointInPixel);
      // console.log('gomo pointInGrid', pointInGrid);
      const specifiedFeatureIndex = pointInGrid[1];
      // 如果点击柱状图的bar以外的区域（比如坐标轴），这个值会是超范围的，忽略
      if (specifiedFeatureIndex < 0 || specifiedFeatureIndex >= chartFeatureOptions.length) {
        return;
      }
      updateState(draft => {
        draft.clickedChartIndex = specifiedFeatureIndex;
      });
    });
  }

  useEffect(() => {
    if (!fetchTrigger) {
      return;
    }
    queryMultipleFeatureForIssue();
    saveSearchConditionGroupToStorage();
  }, [fetchTrigger]);

  useEffect(() => {
    if (!specifiedFeatureKey) {
      return;
    }
    querySpecifiedFeatureCrashList();
  }, [specifiedFeatureKey, querySpecifiedFeatureTrigger]);

  useEffect(() => {
    updateState(draft => {
      draft.tablePageNum = 1;
    });
  },[tablePageSize])

  useEffect(() => {
    if (!expandedCrashId) {
      return;
    }
    queryExpandedCrashDetail();
  }, [expandedCrashId]);

  const chartFeatureNameList = chartFeatureOptions.map(x => x.name);

  const chartSeries = [{
    name: ze('匹配次数','Number of matches'),
    type: 'bar',
    stack: 'total',
    label: {
      show: false,
    },
    emphasis: {
      focus: 'series',
    },
    data: selectedFeatureCountList,
  }];

  const chartOptions = {
    color: COLOR_LIST,
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      valueFormatter(v) {
        const percentage = formatCrashPercentageString(v, commonMatchedCount, 0);
        return `${formatEnNumber(v)} （总上报${formatEnNumber(commonMatchedCount)} 占比${percentage}%）`;
      },
    },
    legend: {},
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'value',
      max: commonMatchedCount,
      interval: commonMatchedCount / 4,
    },
    yAxis: {
      type: 'category',
      data: chartFeatureNameList,
    },
    series: chartSeries,
  };

  const chartDom = !!fetchTrigger && <ReactECharts
    option={chartOptions}
    ref={element => onChartRefChange(element) }
    notMerge={true}
    style={{ width: '100%' }}
  />;

  const crashListTableColumns = [{
    dataIndex: 'crashId',
    title: t('REPORTRECORD.postId'),
  }, {
    dataIndex: 'productVersion',
    title: t('REPORTRECORD.version'),
  }, {
    dataIndex: 'crashTime',
    title: t('REPORTRECORD.发生时间'),
    sorter: true,
  }, {
    dataIndex: 'uploadTime',
    title: t('REPORTRECORD.postTime'),
    sorter: true,
  }, {
    disabled: !isMobile(platformId),
    dataIndex: 'model',
    title: t('REPORTRECORD.hardware'),
    render: (_, record) => {
      const { model, modelOriginalName } = record;
      return CrashUtil.formatDeviceName(modelOriginalName, model);
    },
    width: '200px',
  }, {
    dataIndex: 'osVer',
    title: t('REPORTRECORD.osVersion'),
    render: (text) => {
      if (!isIos(platformId)) {
        return text;
      }
      return tryDecodeUriComponent(text);
    },
  }].filter(x => !x.disabled);

  async function onTableChange(pagination, filters, sorter, { action }) {
    if (action === 'paginate') {
      const { current, pageSize } = pagination;
      updateState(draft => {
        draft.tablePageNum = current;
        draft.tablePageSize = pageSize;
      });
    } else if (action === 'sort') {
      const { field, order } = sorter;
      const desc = order === 'descend';
      updateState(draft => {
        draft.tableSortField = isNotNullish(order) ? field : null;
        draft.tableIsDesc = desc;
      });
    }
    setQuerySpecifiedFeatureTrigger(querySpecifiedFeatureTrigger + 1);
  }

  const specifiedFeatureCrashListDom = specifiedFeatureKey && <Table
    columns={crashListTableColumns}
    dataSource={specifiedFeatureCrashList}
    pagination={{
      defaultPageSize: tablePageSize,
      pageSize: tablePageSize,
      defaultCurrent: tablePageNum,
      current: tablePageNum,
      total: tableTotal,
      showTotal: () => t('common.共n条', { count: tableTotal }),
      showSizeChanger: true,
      pageSizeOptions: [10, 20, 50],
    }}
    expandable={{
      expandRowByClick: true,
      expandedRowKeys: expandedTableRowKey ? [expandedTableRowKey] : [],
      expandedRowRender: () => {
        const { changeLog, changeLevel, clickCheckbox, changeLogKey } = actions;
        const { user } = reduxState;
        return <div>
          <LastReport
            {...{
              appId,
              pid: platformId,
              issueId,
              issue: reduxState.issue.get('current'),
              dispatch,
              exceptionType: reportCategory,
              changeLog,
              changeLevel,
              clickCheckbox,
              changeLogKey,
              isReport: true,
              style: reportRecordScss,
              isDemoApp: false,
              crashHash: expandedCrashId,
              path,
              user,
              closeCrashItem: () => {
                updateState(draft => {
                  draft.expandedTableRowKey = '';
                })
              },
            }}
          />
        </div>;
      },
      onExpandedRowsChange: (expandedRows) => {
        const oldExpandedKey = expandedTableRowKey;
        const newExpandedKey = expandedRows.find(x => x !== oldExpandedKey) || '';
        updateState(draft => {
          draft.expandedTableRowKey = newExpandedKey;
        });

        const { crashId } = specifiedFeatureCrashList.find(x => x.key === newExpandedKey) || {};
        updateState(draft => {
          draft.expandedCrashId = crashId;
        });
      },
    }}
    onChange={(...args) => onTableChange(...args)}
  />;

  return <div>
    <div>
      <IssueCrashFilterEx
        hideIssueFields={true}
        hideSubmitButton={true}
        fixedFieldList={[FieldName.crashUploadTime]}
        fieldBlacklist={['crashId', 'issueId', 'bundleId', 'osVersion', 'deviceIdList', 'expUid', 'crashId', 'issueId', 'contectInfo', 'exceptionTypeList', 'customKey']}
        platformId={platformId}
        filterOptions={{
          version: versionOptions,
        }}
        searchConditionGroup={searchConditionGroup}
        onChange={({ searchConditionGroup }) => {
          updateState(draft => ({ ...draft, searchConditionGroup }));
        }}
      />
    </div>
    <div style={{ marginTop: '8px', display: 'flex' }}>
      <div style={{ flexGrow: '1' }}>
        <Select
          style={{ width: '100%'}}
          mode='multiple'
          maxTagCount='responsive'
          showSearch={true}
          filterOption={ (input, option) => (option.label || '').toLowerCase().includes((input).toLowerCase()) }
          options={featureOptions}
          value={selectedFeatureKeys}
          placeholder={ ze('全部特征规则','All feature rules') }
          onChange={v => {
            updateState(draft => {
              draft.selectedFeatureKeys = v;
            });
          }}
        />
      </div>
      <div style={{ marginLeft: '8px' }}>
        <Button
          type='primary'
          onClick={() => setFetchTrigger(fetchTrigger + 1)}
        >{ze('查询','Search')}</Button>
      </div>
    </div>
    <div style={{ marginTop: '8px' }}>
      <Spin spinning={chartLoading}>{chartDom}</Spin>
    </div>
    <div style={{ marginTop: '8px' }}>
      <Spin spinning={tableLoading}>
        { !!fetchTrigger && <div
          style={{ margin: '8px' }}
        >{ specifiedFeatureKey ? `${ze('特征','Features')} ${specifiedFeatureName} ${ze('的匹配上报列表：','The list of matches reported:')}` : ze('点击柱状图中特征，可查看该特征的匹配上报列表。','Click on a feature in the bar chart to see the list of  corresponding crashes') }</div>}
        <div>{specifiedFeatureCrashListDom}</div>
      </Spin>
    </div>
  </div>;
}

IssueFeatureAnalysis.propTypes = {
  reduxState: PropTypes.object,
  actions: PropTypes.object,
  dispatch: PropTypes.object,
  path: PropTypes.string,
};

const mapStateToProps = state => ({
  reduxState: state,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...productActions,
    ...appActions,
    ...globalActions,
    ...issueActions,
  }, dispatch),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(IssueFeatureAnalysis);
