import { handleActions } from 'redux-actions';
import { fromJS, Record } from 'immutable';
import issueRest from 'utils/IssueRest';


const Tag = new Record({
  tagId: null,
  tagName: null,
  systemTag: false,
  tagType: 0, // 0手动, 1自动
  tagCount: 0,
});


const initialState = fromJS({
  tags: [],
  systemTag: [],
});

/**
 * 加载当前app下的所有系统标签和非系统标签
 */
export function getTags() {
  return (dispatch, getState) => {
    const currentApp = getState().app.get('current');
    return dispatch({
      rest: issueRest.tags.get,
      data: { appId: currentApp.get('appId'), pid: currentApp.get('pid') },
    });
  };
}

/**
 * 更新标签
 */
export function updateTag(tagId, tagName) {
  return (dispatch, getState) => {
    const currentApp = getState().app.get('current');
    return dispatch({
      rest: issueRest.tag.update,
      data: {
        appId: currentApp.get('appId'),
        platformId: currentApp.get('pid'),
        issueId: -1,
        tagId,
        tagName,
      },
    });
  };
}

export default handleActions({

  TAG_ADD_SUCC: (state, action) => {
    const { tagInfoList } = action.response;
    const systemTag = state.get('systemTag');
    if (tagInfoList.length) {
      return state.update('tags', (tags) => tags.clear().concat(systemTag).concat(tagInfoList.map((item) => new Tag(item))));
    }
    return state;
  },
  /**
   * 标签管理中加载tags成功
   */
  TAGS_GET_SUCC: (state, action) => {
    const { systemTagArr = [], unsystemTagArr = [] } = action.response;
    return state.update('tags', (tags) => tags.clear().concat((systemTagArr && systemTagArr.length > 0) ? systemTagArr.map((tag) => new Tag(tag).set('systemTag', true)) : []).concat((unsystemTagArr && unsystemTagArr.length > 0) ? unsystemTagArr.map((tag) => new Tag(tag)) : []))
      .update('systemTag', (systemTag) => systemTag.clear().concat((systemTagArr && systemTagArr.length > 0) ? systemTagArr.map((tag) => new Tag(tag).set('systemTag', true)) : []));
  },

  TAG_DEL_SUCC: (state, action) => {
    if (action.params.issueId === -1) { // 此时才是标签管理中删除标签操作
      return state.update('tags', (tags) => tags.filter((tag) => tag.tagId !== action.params.tagId));
    }
    return state;
  },
}, initialState);
