import { List } from 'immutable';
import { handleActions } from 'redux-actions';
import { getNumFound } from 'utils/helper';
import {
  SDK_VERSIONS_SUCC,
  SUMMARY_INFO_SUCC,
  TOP_VERSIONS_SUCC,
  SDK_LIST_SUCC,
} from 'store/actions';
import { initialState } from './initialState';

export default handleActions({
  UPDATE_SEARCH_PARAMS: (state, action) => state.update('searchParams', (params) => {
    const { type, value } = action.payload;
    return params.set(type, value);
  }),
  UPDATE_PAEG_NUM: (state, action) => state.set('totalPages', Math.floor(getNumFound(state.get('sdkListArr')) / action.payload) + (getNumFound(state.get('sdkListArr')) % action.payload === 0 ? 0 : 1)),
  [SUMMARY_INFO_SUCC]: (state, action) => {
    const { day = [], mouth = [], yesterday = '-' } = action.response;
    return state.set('yesterday', yesterday).set('summaryDayArr', day).set('summaryMouthArr', mouth);
  },
  [SDK_LIST_SUCC]: (state, action) => { // 要区分是图表还是列表
    const rows = state.get('searchParams').get('rows');
    const { type } = action.params;
    const { data = {}, code = 200 } = action.response;
    // if data is null, here will trigger an exception
    const { datas = {} } = data || {};
    const len = getNumFound(datas);
    return type === 'chart' ? state.set('dataList', datas) : state.set('sdkListArr', datas)
      .set('numFound', len)
      .set('totalPages', Math.floor(len / rows) + (len % rows === 0 ? 0 : 1))
      .set('listHttpStatus', code);
  },
  SDK_LIST_FAIL: (state, action) => { // 加载失败的时候
    const { type } = action.params;
    const { status = 500 } = action.response;
    return type === 'chart' ? state.set('chartHttpStatus', status).set('dataList', {}) : state.set('listHttpStatus', status).set('sdkListArr', {}).set('numFound', 0).set('totalPages', 0);
  },
  [SDK_VERSIONS_SUCC]: (state, action) => {
    const { data = {} } = action.response;
    const { datas = [] } = data;
    return state.update('selectOptions', (options) => options.merge({
      version: {
        options: new List(datas).map((item) => ({
          label: item,
          value: encodeURIComponent(item),
        })).unshift({
          label: '全版本',
          value: '-1',
        }),
      },
    }));
  },
  [TOP_VERSIONS_SUCC]: (state, action) => {
    const { versions = [], datas = {} } = action.response;
    return state.set('top5Version', versions)
      .set('top5DataList', datas)
      .set('dataList', datas)
      .set('topVersionStatus', 200);
  },
  TOP_VERSIONS_FAIL: (state, action) => {
    const { status = 500 } = action.response;
    return state.set('top5Version', [])
      .set('topVersionStatus', status)
      .set('top5DataList', {})
      .set('dataList', {});
  },
}, initialState);
