import { message } from 'antd';
import { isNotNullish } from 'utils/nullish';

const NON_STRING_FIELD_SET = new Set([
  'modelList',
  'tagListMustNot',
  'appSoNameWhitelist',
  'appSoNameWhitelistMustNot',
  'needDeviceMatchCount',
  'queryUngroupedCrashList',
  'legacyMixedSearch',
  'searchConditionGroup',
]);

const IssueCrashSearchUtil = Object.freeze({
  urlQueryObjToSearchParamsObj(obj) {
    if (!obj) {
      return obj;
    }
    let jsonInvalidFields = [];
    const mappedKvPairs = Object.entries(obj)
      .map(([k, v]) => {
        let newValue = v;
        if (NON_STRING_FIELD_SET.has(k)) {
          try {
            newValue = JSON.parse(v);
          } catch (e) {
            jsonInvalidFields.push(k);
            return null;
          }
        }
        return { [k]: newValue };
      })
      .filter(x => x);

    if (jsonInvalidFields.length > 0) {
      message.error(`无效的地址栏查询参数: ${ jsonInvalidFields.join(', ') }`);
    }

    return Object.assign({}, ...mappedKvPairs);
  },

  searchParamsObjToUrlQueryObj(obj) {
    if (!obj) {
      return obj;
    }
    const mappedKvPairs = Object.entries(obj)
      .map(([k, v]) => {
        let newValue = v;
        if (NON_STRING_FIELD_SET.has(k) && isNotNullish(v)) {
          newValue = JSON.stringify(v);
        }
        return { [k]: newValue };
      });

    return Object.assign({}, ...mappedKvPairs);
  },
});

export default IssueCrashSearchUtil;
