import RestHelper from './RestHelper';
export default RestHelper.publish({
  crashes: {
    list: {
      method: 'post',
      uri: '/api/crash/queryCrashList',
    },
  },

  /**
   * 告警配置
   */
  alarmConfig: {
    list: {
      method: 'post',
      uri: '/api/app/listAlarmConfig',
    },

    update: {
      method: 'post',
      uri: '/api/app/updateAlarmConfig',
    },

    create: {
      method: 'post',
      uri: '/api/app/createAlarmConfig',
    },

    delete: {
      method: 'post',
      uri: '/redir/api/appConfig/deleteAlarmConfig',
    }
  },

  /**
   * 解决方案
   */
  getSolutionInfo: {
    get: {
      method: 'get',
      uri: '/getSolutionInfo/appId/{appId}/platformId/{pid}/issueId/{issueId}/crashHash/{crashHash}',
    },
  },

  commitComment: {
    post: {
      method: 'post',
      uri: '/commitComment/appId/{appId}/platformId/{pid}/tempId/{tempId}/caseId/{caseId}/star/{star}',
    },
  },

  /**
   * utest
   */
  utest: {
    get: {
      method: 'get',
      uri: '/utestDevices/appId/{appId}/platformId/{platformId}',
    },
  },
});
