import React from 'react';
import moment from 'moment';
import _style from './style.scss';
import { Divider, Tooltip } from 'antd';
import HourlyIcon from 'svg/v2/newcs_dashboard_each1hour_icon.svg';
import FiveMinuteIcon from 'svg/v2/newcs_dashboard_each5min_icon.svg';
import { ze } from 'utils/zhEn';

const UPDATE_TIME_MAP = {
  fiveMinute: {
    icon: <FiveMinuteIcon />,
    text: ze('(5分钟/次)','(every 5 minutes)'),
  },
  hour: {
    icon: <HourlyIcon />,
    text: ze('(1小时/次)','(every 1 hour)'),
  },
  day: {
    icon: null,
    text: ze('(1天/次)','(every 1 day)'),
  }
}

// 更新时间tag 组件
export const UpdateTimeTag = (prop) => {
  const {
    updateTime,
    type = 'hour',
    style = {}
  } = prop;
  const {icon, text} = UPDATE_TIME_MAP[type];
  const formattedTime = updateTime ? moment(updateTime).format('HH:mm:ss') : '';
  return updateTime ? (<Tooltip title={`${ze('数据更新时间 ', 'Last Updated')}${text} : ${formattedTime}`} placement='left' overlayStyle={{maxWidth: '400px'}}>
    <div className={_style.updateTimeTag} style={{...style}} onClick={(e) => e.stopPropagation()}>
      {
        icon && <>
          <div className={_style.updateTimeTagImg}>{icon}</div>
          <Divider type="vertical" style={{margin: '0px', top: '0px', backgroundColor: '#C5C8DA'}} />
        </>
      }
      <span className={_style.updateTimeTagText}>{formattedTime}</span>
    </div></Tooltip>) : null;
}