import { Map, fromJS } from 'immutable';
import moment from 'moment';

const yesterdayTime = moment().subtract(1, 'days').format('YYYY-MM-DD');
const limitNum = 5;

const initialSelectOptions = new Map({
  version: {
    options: [],
  },
  customVersion: {
    options: [
      { label: '昨天Top5 SDK版本', value: 'top5' },
      { label: '自定义SDK版本', value: 'custom' },
    ],
  },
});

const initialSearchParams = new Map({
  start: 0,
  rows: 10,
  version: null, // 没有默认值
  isOutOfDate: false,
  customVersion: 'top5',
  chartDate: { date: 'last_7_day', startDate: '', endDate: '' },
  date: { date: 'last_1_day', startDate: '', endDate: '' },
  // summaryListSearchTime : new Date(yesterdayTime + " 00:00:00").getTime()
});

export const initialState = fromJS({
  selectOptions: initialSelectOptions,
  searchParams: initialSearchParams,
  limit: limitNum,
  yesterday: yesterdayTime,
  summaryDayArr: [],
  summaryMouthArr: [],
  numFound: 0,
  totalPages: 0,
  listHttpStatus: 200,
  chartHttpStatus: 200,
  topVersionStatus: 200,
  top5Version: [],
  top5DataList: {},
  currentTab: '使用 APP 数',
  dataList: {}, // 自定义版本获取的数据
  sdkListArr: {}, // 运营概览的sdk版本详情列表
});
