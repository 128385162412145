import React, { useState, useEffect, useMemo, useContext } from 'react';
import { Input, InputNumber, Select } from 'antd';
import { isNullish } from 'utils/nullish';
import { ze } from 'utils/zhEn';

const SECOND_RATIO = 1000;
const MINUTE_RATIO = SECOND_RATIO * 60;
const HOUR_RATIO = MINUTE_RATIO * 60;

const DurationRangeInput = (props) => {
  const { value } = props;
  const [minMillis, maxMillis] = value || [null, null];
  const onChange = props.onChange || (() => {});

  const [minTimeRatio, setMinTimeRatio] = useState(MINUTE_RATIO);
  const [maxTimeRatio, setMaxTimeRatio] = useState(MINUTE_RATIO);

  const minMillisInputValue = isNullish(minMillis) ? null : minMillis / minTimeRatio;
  const maxMillisInputValue = isNullish(maxMillis) ? null : maxMillis / maxTimeRatio;

  const timeUnitOptions = [
    { label: ze('秒', 'Sec'), value: SECOND_RATIO },
    { label: ze('分', 'Min'), value: MINUTE_RATIO },
    { label: ze('小时', 'Hour'), value: HOUR_RATIO },
  ];

  return <Input.Group compact style={{ display: 'flex' }}>
    <InputNumber
      style={{ flexGrow: 1 }}
      min={0}
      value={minMillisInputValue}
      onChange={(v) => {
        onChange([isNullish(v) ? null : v * minTimeRatio, maxMillis]);
      }}
    />
    <Select
      style={{ width: '100px' }}
      options={timeUnitOptions}
      value={minTimeRatio}
      onChange={v => setMinTimeRatio(v)}
    />
    <Input style={{ width: '30px', backgroundColor: 'rgba(0,0,0,0)', textAlign: 'center', pointerEvents: 'none' }} placeholder="-" disabled />
    <InputNumber
      style={{ flexGrow: 1 }}
      min={0}
      value={maxMillisInputValue}
      onChange={(v) => {
        onChange([minMillis, isNullish(v) ? null : v * maxTimeRatio]);
      }}
    />
    <Select
      style={{ width: '100px' }}
      options={timeUnitOptions}
      value={maxTimeRatio}
      onChange={v => setMaxTimeRatio(v)}
    />
  </Input.Group>;
}

export default DurationRangeInput;
