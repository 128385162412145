import { EnvUtil } from 'utils/env-util';
import { isZh } from 'utils/zhEn';

export const DocsUtil = Object.freeze({
  makeDocsUrl(relativePath) {
    let origin = EnvUtil.isChinaEnv() ? 'https://crashsight.qq.com' : 'https://crashsight.wetest.net';
    if (window.location.hostname === 'test.crashsight.qq.com') {
      origin = 'https://test.crashsight.qq.com';
    } else if (window.location.hostname === 'crashsight.woa.com') {
      origin = 'https://crashsight.woa.com';
    }
    const path = isZh() ? '/documents/zh/crashsight' : '/documents/en/crashsight';
    relativePath = String(relativePath || '/');
    if (!relativePath.startsWith('/')) {
      relativePath = '/' + relativePath;
    }
    return `${origin}${path}${relativePath}`;
  },
});
