import { createAction } from 'redux-actions';
import { getStartEndDateFromStr } from 'utils/StringUtils';
import userChannelRest from 'utils/userChannelRest';
import globalRest from 'utils/globalRest';

export const updateSearchParams = createAction('UPDATE_CHANNEL_PARAMS');// 更新搜索参数

export const updatePageNum = createAction('UPDATE_PAEG_NUM');// 更新页数

export const changeCurTab = createAction('CHANGE_CURTAB');// 更新tab


export function getOptions() {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    dispatch({
      rest: globalRest.getNewSelector.get,
      data: {
        appId,
        pid,
        types: 'channel,version',
      },
    });
  };
}


export function getChannelTrend() {
  return (dispatch, getState) => {
    const { userChannel, app } = getState();
    const { appId, pid } = app.get('current').toJS();
    const { version, date } = userChannel.get('searchParams').toJS();
    const dateObj = getStartEndDateFromStr(date.date, date.startDate, date.endDate);
    if (appId && pid && version && dateObj && dateObj.startDate && dateObj.endDate) {
      return dispatch({
        rest: userChannelRest.getChannelTrend.get,
        data: {
          appId,
          pid,
          version: decodeURIComponent(version),
          startDate: dateObj.startDate,
          endDate: dateObj.endDate,
        },
      });
    }
    return null;
  };
}


export function getRetentTrend(channel) {
  return (dispatch, getState) => {
    const { userChannel, app } = getState();
    const { appId, pid } = app.get('current').toJS();
    const { version, date } = userChannel.get('searchParams').toJS();
    const dateObj = getStartEndDateFromStr(date.date, date.startDate, date.endDate);
    if (appId && pid && version && channel && dateObj && dateObj.startDate && dateObj.endDate) {
      return dispatch({
        rest: userChannelRest.getRetentTrend.get,
        data: {
          appId,
          pid,
          version: decodeURIComponent(version),
          channel: decodeURIComponent(channel),
          startDate: dateObj.startDate,
          endDate: dateObj.endDate,
          dateScope: 'day',
        },
      });
    }
    return null;
  };
}

/**
 * 精准渠道分析
 * @param appId
 * @param pid
 * @param startDate
 * @param endDate
 * @param dateType
 * @param channel?
 */
export function getChannelData() {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    const { date, dateType, channel } = getState().userChannel.get('channelSearchParams').toJS();
    const dateObj = getStartEndDateFromStr(date.date, date.startDate, date.endDate);

    // fixme
    const data = {
      appId,
      pid,
      startDate: dateObj.startDate,
      endDate: dateObj.endDate,
      dateType,
    };

    if (channel && channel !== '-1') {
      data.channelId = channel;
    }
    return dispatch({
      rest: userChannelRest.channelData.get,
      data,
    });
  };
}

/**
 * 重置数据
 */
export const resetData = createAction('RESET_CHANNELDATA');

// 更新渠道搜索参数
export const updateChannelParams = createAction('UPDATE_CHANNEL_PARAMS2');

// 选中某个数据点
export const selectPoint = createAction('SAVE_POINT');
