/**
 * Created by ellis on 23/11/2016.
 */
import { Map as iMap } from 'immutable';
import i18n from 'i18n.js';

const initialSelectOptions = iMap({
  versions: {
    options: [
      { label: '全版本', value: '-1' },
    ],
  },
  type: {
    options: [
      { label: i18n.t('SYMBOLTABLE.全部'), value: '' },
      { label: i18n.t('SYMBOLTABLE.未配置'), value: '0' },
      { label: i18n.t('SYMBOLTABLE.已配置'), value: '-1,1,2' },
    ],
  },
});

export const initialState = iMap({
  selectOptions: initialSelectOptions,
  empty: true,
  selectedType: 3,
  list: iMap({
    total: 1000,
    symbols: [{
      version: '1.0',
      list: [{
        uuid: '123',
        version: '1.0',
        crashUser: 100,
        symbolFileName: 'test.zip',
        status: 0,
        uploadTime: '2016-11-25 11:11:10',
      }],
    }],
  }),
});
