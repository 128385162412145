import uniq from 'lodash/array/uniq';
import { isNullish } from 'utils/nullish';
import { EnvUtil } from 'utils/env-util';
import i18n from 'i18n.js';

export const CountryUtil = Object.freeze({
  ALL_COUNTRY: '-1',

  COUNTRY_GROUP_PREFIX: 'GRP_',

  isDeprecated(country) {
    // 先不标记废弃的国家地区选项
    return false;
  },

  listToQueryString(countryList) {
    return (countryList || []).length > 0
      ? countryList.join(',')
      : this.ALL_COUNTRY;
  },

  /**
   * @param queryCountry {string}
   * @param countryGroupConfigList {{ id: number, countryGroup: string, countries: string[] }[]}
   */
  queryStringToList(queryCountry, countryGroupConfigList = null) {
    if (!queryCountry || queryCountry === this.ALL_COUNTRY) {
      return [];
    }
    const hybridCountryList = queryCountry.split(',');
    if (!countryGroupConfigList) {
      return hybridCountryList;
    }
    const countryGroupWithPrefixList = hybridCountryList.filter(x => x.startsWith(this.COUNTRY_GROUP_PREFIX));
    const pureCountryList = hybridCountryList.filter(x => !x.startsWith(this.COUNTRY_GROUP_PREFIX));
    const countryGroupCountryList = countryGroupWithPrefixList.map(countryGroupWithPrefix => {
      const id = Number(countryGroupWithPrefix.replace(this.COUNTRY_GROUP_PREFIX, ''));
      const config = countryGroupConfigList.find(x => x.id === id);
      return config ? config.countries : [];
    }).flat();
    return uniq([...pureCountryList, ...countryGroupCountryList]);
  },

  /**
   * @param countryGroupConfigList {{ id: number, countryGroup: string, countries: string[] }[]}
   * @param countryList {string[]}
   * @returns {string[]}
   */
  convertPrefixedGroupIdToName(countryGroupConfigList, countryList) {
    if (isNullish(countryList)) {
      return countryList;
    }
    return countryList.map((country) => {
      const config = countryGroupConfigList.find(x => country === this.COUNTRY_GROUP_PREFIX + x.id);
      return config ? config.countryGroup : countryI18n(country);
    });
  },

  /**
   * 将实时/离线的折线图数据的自定义地区ID转换成名字
   */
  convertTrendCountryGroupIdToName(reduxSummaryState, trendList) {
    if (isNullish(trendList)) {
      return trendList;
    }
    const countryGroupConfigList = reduxSummaryState.get('countryGroupConfigList').toJS();
    const prefixedGroupIdToName = countryGroupConfigList.reduce((acc, x) => {
      const prefixedGroupId = this.COUNTRY_GROUP_PREFIX + x.id;
      acc[prefixedGroupId] = x.countryGroup;
      return acc;
    }, Object.create(null));
    return trendList.map(x => ({
      ...x,
      country: prefixedGroupIdToName[x.country] || x.country,
    }));
  },
});

const CountryZhEnList = Object.freeze([
  {
    "code": "CN",
    "zh": "中国大陆",
    "en": "Chinese Mainland"
  },
  {
    "code": "HK",
    "zh": "中国香港",
    "en": "Hong Kong, China"
  },
  {
    "code": "MO",
    "zh": "中国澳门",
    "en": "Macau, China"
  },
  {
    "code": "TW",
    "zh": "中国台湾",
    "en": "Taiwan, China"
  },
  {
    "code": "IN",
    "zh": "印度",
    "en": "India"
  },
  {
    "code": "JP",
    "zh": "日本",
    "en": "Japan"
  },
  {
    "code": "BR",
    "zh": "巴西",
    "en": "Brazil"
  },
  {
    "code": "US",
    "zh": "美国",
    "en": "USA"
  },
  {
    "code": "MX",
    "zh": "墨西哥",
    "en": "Mexico"
  },
  {
    "code": "KR",
    "zh": "韩国",
    "en": "Korea"
  },
  {
    "code": "CA",
    "zh": "加拿大",
    "en": "Canada"
  },
  {
    "code": "PH",
    "zh": "菲律宾",
    "en": "Philippines"
  },
  {
    "code": "ID",
    "zh": "印度尼西亚",
    "en": "Indonesia"
  },
  {
    "code": "SG",
    "zh": "新加坡",
    "en": "Singapore"
  },
  {
    "code": "PE",
    "zh": "秘鲁",
    "en": "Peru"
  },
  {
    "code": "TH",
    "zh": "泰国",
    "en": "Thailand"
  },
  {
    "code": "ES",
    "zh": "西班牙",
    "en": "Spain"
  },
  {
    "code": "TR",
    "zh": "土耳其",
    "en": "Turkey"
  },
  {
    "code": "CL",
    "zh": "智利",
    "en": "Chile"
  },
  {
    "code": "AR",
    "zh": "阿根廷",
    "en": "Argentina"
  },
  {
    "code": "MY",
    "zh": "马来西亚",
    "en": "Malaysia"
  },
  {
    "code": "FR",
    "zh": "法国",
    "en": "France"
  },
  {
    "code": "DE",
    "zh": "德国",
    "en": "Germany"
  },
  {
    "code": "IT",
    "zh": "意大利",
    "en": "Italy"
  },
  {
    "code": "CO",
    "zh": "哥伦比亚",
    "en": "Colombia"
  },
  {
    "code": "VN",
    "zh": "越南",
    "en": "Vietnam"
  },
  {
    "code": "GB",
    "zh": "英国",
    "en": "UK"
  },
  {
    "code": "AU",
    "zh": "澳大利亚",
    "en": "Australia"
  },
  {
    "code": "RU",
    "zh": "俄罗斯",
    "en": "Russian Federation"
  },
  {
    "code": "PL",
    "zh": "波兰",
    "en": "Poland"
  },
  {
    "code": "BD",
    "zh": "孟加拉",
    "en": "Bangladesh"
  },
  {
    "code": "MM",
    "zh": "缅甸",
    "en": "Myanmar"
  },
  {
    "code": "EC",
    "zh": "厄瓜多尔",
    "en": "Ecuador"
  },
  {
    "code": "VE",
    "zh": "委内瑞拉",
    "en": "Venezuela"
  },
  {
    "code": "CZ",
    "zh": "捷克",
    "en": "Czech Republic"
  },
  {
    "code": "NL",
    "zh": "荷兰",
    "en": "Netherlands"
  },
  {
    "code": "PT",
    "zh": "葡萄牙",
    "en": "Portugal"
  },
  {
    "code": "BO",
    "zh": "玻利维亚",
    "en": "Bolivia"
  },
  {
    "code": "EG",
    "zh": "埃及",
    "en": "Egypt"
  },
  {
    "code": "IL",
    "zh": "以色列",
    "en": "Israel"
  },
  {
    "code": "BE",
    "zh": "比利时",
    "en": "Belgium"
  },
  {
    "code": "IQ",
    "zh": "伊拉克",
    "en": "Iraq"
  },
  {
    "code": "RO",
    "zh": "罗马尼亚",
    "en": "Romania"
  },
  {
    "code": "KH",
    "zh": "柬埔寨",
    "en": "Cambodia"
  },
  {
    "code": "GR",
    "zh": "希腊",
    "en": "Greece"
  },
  {
    "code": "PK",
    "zh": "巴基斯坦",
    "en": "Pakistan"
  },
  {
    "code": "SE",
    "zh": "瑞典",
    "en": "Sweden"
  },
  {
    "code": "ZA",
    "zh": "南非",
    "en": "South Africa"
  },
  {
    "code": "PA",
    "zh": "巴拿马",
    "en": "Panama"
  },
  {
    "code": "GT",
    "zh": "危地马拉",
    "en": "Guatemala"
  },
  {
    "code": "UA",
    "zh": "乌克兰",
    "en": "Ukraine"
  },
  {
    "code": "DK",
    "zh": "丹麦",
    "en": "Denmark"
  },
  {
    "code": "NP",
    "zh": "尼泊尔",
    "en": "Nepal"
  },
  {
    "code": "AT",
    "zh": "奥地利",
    "en": "Austria"
  },
  {
    "code": "AE",
    "zh": "阿联酋",
    "en": "United Arab Emirates"
  },
  {
    "code": "NO",
    "zh": "挪威",
    "en": "Norway"
  },
  {
    "code": "NZ",
    "zh": "新西兰",
    "en": "New Zealand"
  },
  {
    "code": "SV",
    "zh": "萨尔瓦多",
    "en": "El Salvador"
  },
  {
    "code": "CH",
    "zh": "瑞士",
    "en": "Switzerland"
  },
  {
    "code": "FI",
    "zh": "芬兰",
    "en": "Finland"
  },
  {
    "code": "SA",
    "zh": "沙特阿拉伯",
    "en": "Saudi Arabia"
  },
  {
    "code": "SK",
    "zh": "斯洛伐克",
    "en": "Slovakia"
  },
  {
    "code": "CR",
    "zh": "哥斯达黎加",
    "en": "Costa Rica"
  },
  {
    "code": "DZ",
    "zh": "阿尔及利亚",
    "en": "Algeria"
  },
  {
    "code": "MA",
    "zh": "摩洛哥",
    "en": "Morocco"
  },
  {
    "code": "HU",
    "zh": "匈牙利",
    "en": "Hungary"
  },
  {
    "code": "UY",
    "zh": "乌拉圭",
    "en": "Uruguay"
  },
  {
    "code": "LA",
    "zh": "老挝",
    "en": "Laos"
  },
  {
    "code": "IE",
    "zh": "爱尔兰",
    "en": "Ireland"
  },
  {
    "code": "EU",
    "zh": "欧洲",
    "en": "Europe"
  },
  {
    "code": "DO",
    "zh": "多米尼加共和国",
    "en": "Dominican Republic"
  },
  {
    "code": "HR",
    "zh": "克罗地亚",
    "en": "Croatia"
  },
  {
    "code": "PR",
    "zh": "波多黎各",
    "en": "Puerto Rico"
  },
  {
    "code": "BY",
    "zh": "白俄罗斯",
    "en": "Belarus"
  },
  {
    "code": "JO",
    "zh": "约旦",
    "en": "Jordan"
  },
  {
    "code": "NI",
    "zh": "尼加拉瓜",
    "en": "Nicaragua"
  },
  {
    "code": "PY",
    "zh": "巴拉圭",
    "en": "Paraguay"
  },
  {
    "code": "HN",
    "zh": "洪都拉斯",
    "en": "Honduras"
  },
  {
    "code": "AZ",
    "zh": "阿塞拜疆",
    "en": "Azerbaijan"
  },
  {
    "code": "KZ",
    "zh": "哈萨克斯坦",
    "en": "Kazakhstan"
  },
  {
    "code": "RS",
    "zh": "塞尔维亚",
    "en": "Serbia"
  },
  {
    "code": "BG",
    "zh": "保加利亚",
    "en": "Bulgaria"
  },
  {
    "code": "TN",
    "zh": "突尼斯",
    "en": "Tunisia"
  },
  {
    "code": "IR",
    "zh": "伊朗",
    "en": "Iran"
  },
  {
    "code": "KW",
    "zh": "科威特",
    "en": "Kuwait"
  },
  {
    "code": "CY",
    "zh": "塞浦路斯",
    "en": "Cyprus"
  },
  {
    "code": "UZ",
    "zh": "乌兹别克斯坦",
    "en": "Uzbekistan"
  },
  {
    "code": "RE",
    "zh": "留尼汪",
    "en": "Réunion"
  },
  {
    "code": "BN",
    "zh": "文莱",
    "en": "Brunei"
  },
  {
    "code": "GE",
    "zh": "格鲁吉亚",
    "en": "Georgia"
  },
  {
    "code": "LB",
    "zh": "黎巴嫩",
    "en": "Lebanon"
  },
  {
    "code": "LT",
    "zh": "立陶宛",
    "en": "Lithuania"
  },
  {
    "code": "MD",
    "zh": "摩尔多瓦",
    "en": "Moldova"
  },
  {
    "code": "QA",
    "zh": "卡塔尔",
    "en": "Qatar"
  },
  {
    "code": "NG",
    "zh": "尼日利亚",
    "en": "Nigeria"
  },
  {
    "code": "SI",
    "zh": "斯洛文尼亚",
    "en": "Slovenia"
  },
  {
    "code": "OM",
    "zh": "阿曼",
    "en": "Oman"
  },
  {
    "code": "LK",
    "zh": "斯里兰卡",
    "en": "Sri Lanka"
  },
  {
    "code": "LU",
    "zh": "卢森堡",
    "en": "Luxembourg"
  },
  {
    "code": "LV",
    "zh": "拉脱维亚",
    "en": "Latvia"
  },
  {
    "code": "TT",
    "zh": "特立尼达和多巴哥",
    "en": "Trinidad and Tobago"
  },
  {
    "code": "CU",
    "zh": "古巴",
    "en": "Cuba"
  },
  {
    "code": "JM",
    "zh": "牙买加",
    "en": "Jamaica"
  },
  {
    "code": "KG",
    "zh": "吉尔吉斯斯坦",
    "en": "Kyrgyzstan"
  },
  {
    "code": "MK",
    "zh": "马其顿",
    "en": "Macedonia"
  },
  {
    "code": "GP",
    "zh": "瓜德罗普",
    "en": "Guadeloupe"
  },
  {
    "code": "MT",
    "zh": "马耳他",
    "en": "Malta"
  },
  {
    "code": "EE",
    "zh": "爱沙尼亚",
    "en": "Estonia"
  },
  {
    "code": "GU",
    "zh": "关岛",
    "en": "Guam"
  },
  {
    "code": "AL",
    "zh": "阿尔巴尼亚",
    "en": "Albania"
  },
  {
    "code": "MG",
    "zh": "马达加斯加",
    "en": "Madagascar"
  },
  {
    "code": "MQ",
    "zh": "马提尼克",
    "en": "Martinique"
  },
  {
    "code": "KE",
    "zh": "肯尼亚",
    "en": "Kenya"
  },
  {
    "code": "PF",
    "zh": "法属波利尼西亚",
    "en": "French Polynesia"
  },
  {
    "code": "SY",
    "zh": "叙利亚",
    "en": "Syrian Arab Republic"
  },
  {
    "code": "SD",
    "zh": "苏丹",
    "en": "Sudan"
  },
  {
    "code": "CI",
    "zh": "科特迪瓦",
    "en": "Cote d'Ivoire"
  },
  {
    "code": "MN",
    "zh": "蒙古",
    "en": "Mongolia"
  },
  {
    "code": "BA",
    "zh": "波黑",
    "en": "Bosnia and Herzegovina"
  },
  {
    "code": "BH",
    "zh": "巴林",
    "en": "Bahrain"
  },
  {
    "code": "NC",
    "zh": "新喀里多尼亚",
    "en": "New Caledonia"
  },
  {
    "code": "BB",
    "zh": "巴巴多斯",
    "en": "Barbados"
  },
  {
    "code": "GY",
    "zh": "圭亚那",
    "en": "Guyana"
  },
  {
    "code": "MV",
    "zh": "马尔代夫",
    "en": "Maldives"
  },
  {
    "code": "PS",
    "zh": "巴勒斯坦",
    "en": "Palestine"
  },
  {
    "code": "MU",
    "zh": "毛里求斯",
    "en": "Mauritius"
  },
  {
    "code": "AM",
    "zh": "亚美尼亚",
    "en": "Armenia"
  },
  {
    "code": "GH",
    "zh": "加纳",
    "en": "Ghana"
  },
  {
    "code": "AO",
    "zh": "安哥拉",
    "en": "Angola"
  },
  {
    "code": "SN",
    "zh": "塞内加尔",
    "en": "Senegal"
  },
  {
    "code": "CM",
    "zh": "喀麦隆",
    "en": "Cameroon"
  },
  {
    "code": "GF",
    "zh": "法属圭亚那",
    "en": "French Guiana"
  },
  {
    "code": "LY",
    "zh": "利比亚",
    "en": "Libya"
  },
  {
    "code": "IS",
    "zh": "冰岛",
    "en": "Iceland"
  },
  {
    "code": "SR",
    "zh": "苏里南",
    "en": "Suriname"
  },
  {
    "code": "BS",
    "zh": "巴哈马",
    "en": "Bahamas"
  },
  {
    "code": "YE",
    "zh": "也门",
    "en": "Yemen"
  },
  {
    "code": "HT",
    "zh": "海地",
    "en": "Haiti"
  },
  {
    "code": "BT",
    "zh": "不丹",
    "en": "Bhutan"
  },
  {
    "code": "ME",
    "zh": "黑山",
    "en": "Montenegro"
  },
  {
    "code": "TJ",
    "zh": "塔吉克斯坦",
    "en": "Tajikistan"
  },
  {
    "code": "AW",
    "zh": "阿鲁巴",
    "en": "Aruba"
  },
  {
    "code": "XK",
    "zh": "科索沃",
    "en": "Kosovo"
  },
  {
    "code": "MZ",
    "zh": "莫桑比克",
    "en": "Mozambique"
  },
  {
    "code": "CD",
    "zh": "刚果（金）",
    "en": "Democratic Republic of the Congo"
  },
  {
    "code": "LC",
    "zh": "圣卢西亚",
    "en": "Saint Lucia"
  },
  {
    "code": "BW",
    "zh": "博茨瓦纳",
    "en": "Botswana"
  },
  {
    "code": "GA",
    "zh": "加蓬",
    "en": "Gabon"
  },
  {
    "code": "ET",
    "zh": "埃塞俄比亚",
    "en": "Ethiopia"
  },
  {
    "code": "BF",
    "zh": "布基纳法索",
    "en": "Burkina Faso"
  },
  {
    "code": "UG",
    "zh": "乌干达",
    "en": "Uganda"
  },
  {
    "code": "SO",
    "zh": "索马里",
    "en": "Somalia"
  },
  {
    "code": "TG",
    "zh": "多哥",
    "en": "Togo"
  },
  {
    "code": "BJ",
    "zh": "贝宁",
    "en": "Benin"
  },
  {
    "code": "CW",
    "zh": "库拉索",
    "en": "Curaçao"
  },
  {
    "code": "AD",
    "zh": "安道尔",
    "en": "Andorra"
  },
  {
    "code": "FJ",
    "zh": "斐济",
    "en": "Fiji"
  },
  {
    "code": "BZ",
    "zh": "伯利兹",
    "en": "Belize"
  },
  {
    "code": "ML",
    "zh": "马里",
    "en": "Mali"
  },
  {
    "code": "GI",
    "zh": "直布罗陀",
    "en": "Gibraltar"
  },
  {
    "code": "VC",
    "zh": "圣文森特和格林纳丁斯",
    "en": "Saint Vincent and the Grenadines"
  },
  {
    "code": "JE",
    "zh": "泽西岛",
    "en": "Jersey"
  },
  {
    "code": "GG",
    "zh": "根西岛",
    "en": "Guernsey"
  },
  {
    "code": "TZ",
    "zh": "坦桑尼亚",
    "en": "Tanzania"
  },
  {
    "code": "ZM",
    "zh": "赞比亚",
    "en": "Zambia"
  },
  {
    "code": "ZW",
    "zh": "津巴布韦",
    "en": "Zimbabwe"
  },
  {
    "code": "VI",
    "zh": "美属维尔京群岛",
    "en": "U.S. Virgin Islands"
  },
  {
    "code": "CG",
    "zh": "刚果（布）",
    "en": "Republic of the Congo"
  },
  {
    "code": "BM",
    "zh": "百慕大",
    "en": "Bermuda"
  },
  {
    "code": "SL",
    "zh": "塞拉利昂",
    "en": "Sierra Leone"
  },
  {
    "code": "NA",
    "zh": "纳米比亚",
    "en": "Namibia"
  },
  {
    "code": "GD",
    "zh": "格林纳达",
    "en": "Grenada"
  },
  {
    "code": "FO",
    "zh": "法罗群岛",
    "en": "Faroe Islands"
  },
  {
    "code": "SC",
    "zh": "塞舌尔",
    "en": "Seychelles"
  },
  {
    "code": "CV",
    "zh": "佛得角",
    "en": "Cape Verde"
  },
  {
    "code": "IM",
    "zh": "马恩岛",
    "en": "Isle of Man"
  },
  {
    "code": "MW",
    "zh": "马拉维",
    "en": "Malawi"
  },
  {
    "code": "TC",
    "zh": "特克斯和凯科斯群岛",
    "en": "Turks and Caicos Islands"
  },
  {
    "code": "GQ",
    "zh": "赤道几内亚",
    "en": "Equatorial Guinea"
  },
  {
    "code": "KY",
    "zh": "开曼群岛",
    "en": "Cayman Islands"
  },
  {
    "code": "TL",
    "zh": "东帝汶",
    "en": "Timor-Leste"
  },
  {
    "code": "GN",
    "zh": "几内亚",
    "en": "Guinea"
  },
  {
    "code": "LI",
    "zh": "列支敦士登",
    "en": "Liechtenstein"
  },
  {
    "code": "SM",
    "zh": "圣马力诺",
    "en": "San Marino"
  },
  {
    "code": "NE",
    "zh": "尼日尔",
    "en": "Niger"
  },
  {
    "code": "AF",
    "zh": "阿富汗",
    "en": "Afghanistan"
  },
  {
    "code": "MF",
    "zh": "圣马丁",
    "en": "St. Martin"
  },
  {
    "code": "GM",
    "zh": "冈比亚",
    "en": "Gambia"
  },
  {
    "code": "DM",
    "zh": "多米尼克",
    "en": "Dominica"
  },
  {
    "code": "AG",
    "zh": "安提瓜和巴布达",
    "en": "Antigua and Barbuda"
  },
  {
    "code": "BI",
    "zh": "布隆迪",
    "en": "Burundi"
  },
  {
    "code": "ST",
    "zh": "圣多美和普林西比",
    "en": "Sao Tome and Principe"
  },
  {
    "code": "PW",
    "zh": "帕劳",
    "en": "Palau"
  },
  {
    "code": "PG",
    "zh": "巴布亚新几内亚",
    "en": "Papua New Guinea"
  },
  {
    "code": "AI",
    "zh": "安圭拉",
    "en": "Anguilla"
  },
  {
    "code": "RW",
    "zh": "卢旺达",
    "en": "Rwanda"
  },
  {
    "code": "GL",
    "zh": "格陵兰",
    "en": "Greenland"
  },
  {
    "code": "MP",
    "zh": "北马里亚纳群岛",
    "en": "Northern Mariana Islands"
  },
  {
    "code": "FM",
    "zh": "密克罗尼西亚",
    "en": "Micronesia"
  },
  {
    "code": "XN",
    "zh": "北美地区",
    "en": "North America"
  },
  {
    "code": "LR",
    "zh": "利比里亚",
    "en": "Liberia"
  },
  {
    "code": "SX",
    "zh": "荷属圣马丁",
    "en": "Sint Maarten (Dutch part)"
  },
  {
    "code": "MC",
    "zh": "摩纳哥",
    "en": "Monaco"
  },
  {
    "code": "MR",
    "zh": "毛里塔尼亚",
    "en": "Mauritania"
  },
  {
    "code": "VG",
    "zh": "英属维尔京群岛",
    "en": "British Virgin Islands"
  },
  {
    "code": "SZ",
    "zh": "斯威士兰",
    "en": "Swaziland"
  },
  {
    "code": "MH",
    "zh": "马绍尔群岛",
    "en": "Marshall Islands"
  },
  {
    "code": "LS",
    "zh": "莱索托",
    "en": "Lesotho"
  },
  {
    "code": "AX",
    "zh": "奥兰群岛",
    "en": "Åland Islands"
  },
  {
    "code": "SS",
    "zh": "南苏丹",
    "en": "South Sudan"
  },
  {
    "code": "KN",
    "zh": "圣基茨和尼维斯",
    "en": "Saint Kitts and Nevis"
  },
  {
    "code": "KM",
    "zh": "科摩罗",
    "en": "Comoros"
  },
  {
    "code": "VU",
    "zh": "瓦努阿图",
    "en": "Vanuatu"
  },
  {
    "code": "NR",
    "zh": "瑙鲁",
    "en": "Nauru"
  },
  {
    "code": "AS",
    "zh": "美属萨摩亚",
    "en": "American Samoa"
  },
  {
    "code": "TO",
    "zh": "汤加",
    "en": "Tonga"
  },
  {
    "code": "DJ",
    "zh": "吉布提",
    "en": "Djibouti"
  },
  {
    "code": "NU",
    "zh": "纽埃",
    "en": "Niue"
  },
  {
    "code": "TM",
    "zh": "土库曼斯坦",
    "en": "Turkmenistan"
  },
  {
    "code": "WS",
    "zh": "萨摩亚",
    "en": "Samoa"
  },
  {
    "code": "SB",
    "zh": "所罗门群岛",
    "en": "Solomon Islands"
  },
  {
    "code": "CF",
    "zh": "中非共和国",
    "en": "Central African Republic"
  },
  {
    "code": "TD",
    "zh": "乍得",
    "en": "Chad"
  },
  {
    "code": "GW",
    "zh": "几内亚比绍",
    "en": "Guinea-Bissau"
  },
  {
    "code": "PM",
    "zh": "圣皮埃尔和密克隆群岛",
    "en": "Saint Pierre and Miquelon"
  },
  {
    "code": "WF",
    "zh": "瓦利斯和富图纳群岛",
    "en": "Wallis and Futuna"
  },
  {
    "code": "CK",
    "zh": "库克群岛",
    "en": "Cook Islands"
  },
  {
    "code": "IO",
    "zh": "英属印度洋领地",
    "en": "British Indian Ocean Territory"
  },
  {
    "code": "KI",
    "zh": "基里巴斯",
    "en": "Kiribati"
  },
  {
    "code": "TK",
    "zh": "托克劳群岛",
    "en": "Tokelau"
  },
  {
    "code": "TV",
    "zh": "图瓦卢",
    "en": "Tuvalu"
  },
  {
    "code": "FK",
    "zh": "福克兰群岛",
    "en": "Falkland Islands"
  },
  {
    "code": "YT",
    "zh": "马约特",
    "en": "Mayotte"
  },
  {
    "code": "AN",
    "zh": "安的列斯",
    "en": "Antilles"
  },
  {
    "code": "KP",
    "zh": "朝鲜",
    "en": "North Korea"
  },
  {
    "code": "CN",
    "zh": "中国",
    "en": "China"
  }
]);

export const COUNTRY_LIST = Object.freeze(CountryZhEnList.map(x => x.zh));

export const CountryZhToEnDict = Object.freeze(CountryZhEnList.reduce((acc, x) => {
  acc[x.zh] = x.en;
  return acc;
}, Object.create(null)));

export const CountryZhToFlagNameDiDict = Object.freeze(CountryZhEnList.reduce((acc, x) => {
  if (x.zh === "北美地区") {
    acc[x.zh] = "";
  } else {
    acc[x.zh] = ["TW", "HK", "MO"].includes(x.code) ? "cn" : x.code.toLowerCase();
  }
  return acc;
}, Object.create(null)));

export const CountryEnToZhDict = Object.freeze(CountryZhEnList.reduce((acc, x) => {
  acc[x.en] = x.zh;
  return acc;
}, Object.create(null)));

export function countryI18n(zh) {
  return i18n.language.startsWith('zh')
    ? zh
    : CountryZhToEnDict[zh] || zh;
}

export function countryFlagUrl(zh) {
  return CountryZhToFlagNameDiDict[zh] ? `https://${EnvUtil.getCdnHost()}/country-or-region-flags/${CountryZhToFlagNameDiDict[zh]}.svg` : "";
}


