import { useSelector } from 'react-redux';
import { TimeZoneUtil } from 'utils/time-zone-util';

export function useConvertTimeToAppTimeZone() {
  const utcOffset = useSelector(state => state.app.get('current').get('utcOffset'));

  return (defaultTimeZoneLocalDateTime) => {
    if (!defaultTimeZoneLocalDateTime) {
      return defaultTimeZoneLocalDateTime;
    }
    const timeStr = TimeZoneUtil.convertDefaultTimeZoneLocalDateTimeToUtcOffsetMoment(
      defaultTimeZoneLocalDateTime, utcOffset).format('YYYY-MM-DD HH:mm:ss');
    const offsetStr = TimeZoneUtil.makeUtcOffsetLabel(utcOffset);
    return offsetStr ? `${timeStr} (${offsetStr})` : timeStr;
  }
}
