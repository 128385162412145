import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import style from './style.scss';
import { withTranslation } from 'react-i18next'
import { isNotNullish } from 'utils/nullish';
import ListTagClose from 'svg/v2/newcs_dashboard_list_tag_close.svg';

/**
 * 标签项视图
 */
@withTranslation()
export default class TagItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
    };
  }

  componentDidUpdate() {
    this.state.editing && this.input && this.input.focus();
  }

  get allowDeleteTag() {
    const { handleDelTag } = this.props;
    return isNotNullish(handleDelTag);
  }

  handleDelTag(event) {
    const { demoApp } = this.props;
    event.stopPropagation();
    const { issueId, tag, path } = this.props;
    (!demoApp && this.props.handleDelTag) && this.props.handleDelTag(issueId, tag.get ? tag.get('tagId') : tag.tagId, path);
  }

  handleChangeEditState(event) {
    event.stopPropagation();
    if (this.state.editing) return;
    this.setState({
      editing: true,
    });
  }

  handleAddTag() {
    const { demoApp } = this.props;
    const { input } = this;
    const trimmedValue = (input.value || '').trim();
    if (!trimmedValue) {
      this.setState({
        editing: false,
      });
      input.value = '';
      return;
    }
    (!demoApp && this.props.handleAddTag) && this.props.handleAddTag(this.props.issueId, trimmedValue, this.props.path);
    // reset input
    input.value = '';
    this.setState({
      editing: false,
    });
  }

  handleKeyUp(event) {
    // 回车键按下，让input 进入blur态，进行提交判定
    if (parseInt(event.which) !== 13) {
      return;
    }
    this.input.blur();
  }

  handleChange() {
    const { input } = this;
    input.value.length > 100 && (input.value = input.value.slice(0, 100));
  }

  render() {
    const { tag, appId, pid } = this.props;
    const { tagType, tagId, tagName } = tag.toJS ? tag.toJS() : tag;
    const classes = classnames(style.tag, {
      [style.deletable]: parseInt(tagType) === 0 && this.allowDeleteTag,
      [style.editable]: !tagId,
      [style.editing]: this.state.editing,
    });

    return (
      <div
        className={classes}
        onClick={!tagId ? ((event) => this.handleChangeEditState(event)) : undefined}>
        {!!tagName && tagName !== 'ADD_ISSUE_TAG'
          ? <Link
              to={`/crash-reporting/advanced-search/${appId}?pid=${pid}&start=0&tagList=${tagId}`}
              target="_blank"
              rel="noopener noreferrer"
            >{tagName}</Link>
          : <span>{ this.props.t("BASEPROBLEM.addTag") }</span>}
        {!tagId
          && (
            <input
              type="text"
              ref={(target) => { this.input = target; }}
              onBlur={() => this.handleAddTag()}
              onKeyUp={(event) => this.handleKeyUp(event)}
              onChange={() => this.handleChange()} />
          )
        }
        {parseInt(tagType) === 0 && this.allowDeleteTag && <div className={style.icon_close} onClick={(event) => this.handleDelTag(event)}>{<ListTagClose />}</div>}
      </div>
    );
  }
}

TagItem.propTypes = {
  tag: PropTypes.object.isRequired,
  handleDelTag: PropTypes.func,
  handleAddTag: PropTypes.func.isRequired,
  issueId: PropTypes.string.isRequired,
  path: PropTypes.string,
  demoApp: PropTypes.bool,
  appId: PropTypes.any,
  pid: PropTypes.any,
};
