export const EXCEPTION_TYPE_INT_ANDROID_JAVA = 0;
export const EXCEPTION_TYPE_INT_ANDROID_NATIVE = 1;

/**
 * 将issueExceptionType / crashExceptionType 转为异常类型字串
 * @param exceptionTypeInt
 * @returns {'crashes' | 'blocks' | 'errors'}
 */
export function getReportCategoryByExceptionTypeInt(exceptionTypeInt) {
  exceptionTypeInt = Number(exceptionTypeInt);
  if ([0, 1, 5].includes(exceptionTypeInt)) {
    return 'crashes';
  }
  if (exceptionTypeInt === 3) {
    return 'blocks';
  }
  return 'errors';
}
