import { fromJS } from 'immutable';
import moment from 'moment';
const defaultOption = {
  // 下拉框的选项配置
  selectOptions: {
    version: [],
    date: [
      '最近7天',
      '自定义时间段',
    ],
    ftConfig: [
      { label: 'Top 5 关键词', value: 'top5' },
      { label: '自定义关键词', value: 'diy' },
    ],
    ft: [],
  },

  // 选中的搜索条件
  chartSearchParams: {
    version: '-1',
    date: { date: 'last_7_day', startDate: '', endDate: '' },
    ftConfig: 'top5',
    ft: '',
    pageType: '崩溃',
    chartType: '',
  },
  listSearchParams: {
    // Ft 崩溃详情
    detailVersion: '-1',
    detailDate: { date: 'custom', startDate: moment().subtract(1, 'days').format('YYYYMMDD'), endDate: '' },
    detailSearch: '',
    start: 0,
    rows: 10,
  },
  ftSeries: [], // 图表的
  ftList: [], // 详情的,
  ftListLength: 0,
  totalPages: 0,
  statusCode: 200,
  firstLoad: true,
  keywordInfo: [],
};

export const initialState = fromJS(defaultOption);
