import { Map, fromJS } from 'immutable';
import { DATE_OPTIONS } from 'utils/constants';
const initialSelectOptions = new Map({
  version: {
    options: [],
  },
  channelId: {
    options: [],
  },
  customChannel: {
    options: [
      { label: '昨天Top5渠道', value: 'top5' },
      { label: '自定义渠道', value: 'custom' },
    ],
  },
  date: DATE_OPTIONS,
});

// 搜索的参数
const initialSearchParams = new Map({
  start: 0,
  rows: 10,
  version: '-1', // 版本
  channel: '-1', // 渠道
  customChannel: 'top5',
  date: { date: 'last_7_day', startDate: '', endDate: '' },
});

// 渠道精准分析 下拉选择项和搜索条件
const channelSelectOptions = fromJS({
  channel: {
    options: [
      { value: '-1', label: '全渠道' },
    ],
  },
});

const channelSearchParams = new Map({
  start: 0,
  rows: 10,
  channel: '-1',
  dateType: 'daily',
  date: { date: 'last_30_day', startDate: '', endDate: '' },
  selectedPoint: '',
  selectedData: [],
});

// 初始化state
export const initialState = new Map({
  selectOptions: initialSelectOptions,
  searchParams: initialSearchParams,
  channelSelectOptions,
  channelSearchParams,
  currentTab: '新增用户',
  dataList: {},
  totalAddUserAmount: 0,
  numFound: 0,
  totalPages: 0,
  top5Channel: '',
  channelData: [],
});
