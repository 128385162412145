import { isNullish } from 'utils/nullish';

const JsonUtil = Object.freeze({
  /**
   * 将json格式的数据内的empty object全部（深遍历）替换成null。 传入的json数据不做修改，返回替换后的json数据。<br>
   * 这个主要是为了解决PHP容易把empty object当成empty array传给Java后台报错的问题
   */
  deepReplaceEmptyObjectWithNull(json) {
    if (isNullish(json)) {
      return json;
    }
    if (Array.isArray(json)) {
      return json.map(x => this.deepReplaceEmptyObjectWithNull(x));
    }
    if (typeof json === 'object') {
      if (Object.keys(json).length === 0) {  // empty object 转换为 null
        return null;
      }
      return Object.fromEntries(Object.entries(json).map(([k, v]) => [k, this.deepReplaceEmptyObjectWithNull(v)]));
    }
    return json;
  },
});

export default JsonUtil;
