import React, { useEffect, useState } from 'react';
import { Divider, Select } from 'antd';
import { isNotNullish, isNullish } from 'utils/nullish';
import { ze } from 'utils/zhEn';
import uniqBy from 'lodash.uniqby';

const SEARCH_VALUE_HISTORY_LEN_MAX = 100;

const AndroidDeviceSelect = ({
  appId,
  value,
  onChange,
}) => {
  const [isComposition, setIsComposition] = useState(false);
  const [lastSearchInputBeforeCompositionEnd, setLastSearchInputBeforeCompositionEnd] = useState('');
  const [searchValueHistoryList, setSearchValueHistoryList] = useState([]);
  const [cachedModelToDeviceName, setCachedModelToDeviceName] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [options, setOptions] = useState([]);
  const [deviceNameNotMatchedDict, setDeviceNameNotMatchedDict] = useState({});

  useEffect(() => {
    if (!appId || !Array.isArray(value)) {
      return;
    }
    const uncachedModels = value.filter(x => !deviceNameNotMatchedDict[x] && isNullish(cachedModelToDeviceName[x]));
    const noDeviceNameModels = value.filter(x => deviceNameNotMatchedDict[x]);
    fetchOptionsByExactModelList(uncachedModels, noDeviceNameModels);
  }, [appId, value]);

  async function fetchOptionsByExactModelList(modelList, noDeviceNameModels) {
    let fetchedOptions = [];
    if (modelList.length > 0) {
      const rsp = await RestHelper.mustPost('/redir/api/queryModel/queryModelOptions', {
        appId,
        modelList,
      });
      let fetchedModelToDeviceName = Object.create(null);
      rsp.json.data.options.forEach(x => {
        const { model, deviceName } = x;
        fetchedModelToDeviceName[model] = deviceName;
      });
      const modelToModel = Object.assign({}, ...modelList.map(x => ({ [x]: x })));
      const mergedModelToDeviceName = {
        ...modelToModel,
        ...cachedModelToDeviceName,
        ...fetchedModelToDeviceName,
      };
      setCachedModelToDeviceName(mergedModelToDeviceName);
      fetchedOptions = makeOptions(mergedModelToDeviceName);
    }

    const newOptions = uniqBy([
      ...options,
      ...fetchedOptions,
      ...noDeviceNameModels.map(x => ({ label: x, value: x })),
    ], x => x.value);
    setOptions(newOptions);
  }

  async function fetchOptions(searchValue) {
    searchValue = (searchValue || '').toLowerCase();
    setLastSearchInputBeforeCompositionEnd('');
    if (searchValueHistoryList.some(v => searchValue.includes(v))) {
      return;
    }
    const newSearchValueHistoryList = [...searchValueHistoryList, searchValue];
    if (newSearchValueHistoryList.length > SEARCH_VALUE_HISTORY_LEN_MAX) {
      newSearchValueHistoryList.shift();
    }
    setSearchValueHistoryList(newSearchValueHistoryList);

    const rsp = await RestHelper.mustPost('/redir/api/queryModel/queryModelOptions', {
      appId,
      searchContent: searchValue,
    });
    let fetchedModelToDeviceName = Object.create(null);
    rsp.json.data.options.forEach(x => {
      const { model, deviceName } = x;
      fetchedModelToDeviceName[model] = deviceName;
    });
    const mergedModelToDeviceName = { ...cachedModelToDeviceName, ...fetchedModelToDeviceName };
    setCachedModelToDeviceName(mergedModelToDeviceName);
    let options = makeOptions(mergedModelToDeviceName);
    if (options.length === 0) {
      options.push({
        label: searchValue,
        value: searchValue,
      })
    }
    setOptions(options);
  }

  function makeOptions(modelToDeviceName) {
    return Object.entries(modelToDeviceName).map(([model, deviceName]) => ({
      label: (deviceName && model !== deviceName) ? `${deviceName} (${model})` : model,
      value: model,
    }));
  }

  function onInputSearch(searchValue) {
    const v = (searchValue || '').trim();

    setSearchValue(v);
    if (isComposition) {
      setLastSearchInputBeforeCompositionEnd(v);
    }
    if (!v || isComposition) {
      return;
    }
    fetchOptions(searchValue);
  }

  function onCompositionStart() {
    setIsComposition(true);
  }

  function onCompositionEnd() {
    setIsComposition(false);
    if (lastSearchInputBeforeCompositionEnd) {
      fetchOptions(lastSearchInputBeforeCompositionEnd);
    }
  }

  const optionsWithUnmatched = searchValue && options.filter(x => (x.label || '').includes(searchValue)).length === 0
    ? [{ label: searchValue, value: searchValue }, ...options]
    : options;

  return <Select
    style={{ width: '100%' }}
    mode='multiple'
    autoClearSearchValue={false}
    allowClear={true}
    showSearch={true}
    onSearch={onInputSearch}
    filterOption={(input, option) => {
      return (option.label || '').toLowerCase().includes((input).toLowerCase().trim());
    }}
    optionLabelProp='label'
    options={optionsWithUnmatched}
    value={value || []}
    onCompositionStart={onCompositionStart}
    onCompositionEnd={onCompositionEnd}
    onChange={v => {
      v.forEach(model => {
        if (!options.find(x => x.value === model)) {
          setDeviceNameNotMatchedDict({ ...deviceNameNotMatchedDict, [model]: true });
        }
      });
      onChange(v);
    }}
    dropdownRender={(menu) => {
      return <div>
        <div>{menu}</div>
        <Divider style={{margin: '8px 0'}}/>
        <div style={{ padding: '5px 12px', color: '#AAA' }}>{ ze('输入设备Model后，在弹出的选项中选择设备。', 'After entering the name of the device, select the devices from the options.') }</div>
      </div>;
    }}
  />
};

export default AndroidDeviceSelect;
