import React, { useMemo, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { SearchBox } from 'components/commons';
import { useImmer } from 'use-immer';
import {
  DATE_MAP_VALUE,
  ERROR_EXCEPTION_TYPE_LIST_CUSTOM_ERROR_OPTIONS,
  ISSUE_STATUS_VALUE_TO_LABEL,
  PLATFORM_ID,
  TapdBugStatusOptions
} from 'utils/constants';
import { Link } from 'react-router-dom';
import style from './style.scss';
import { Translation, useTranslation } from 'react-i18next';
import { Checkbox, Button, Select, Space, Row, Col, DatePicker, Tooltip, Divider } from 'antd';
import { groupBy, isEqual } from 'lodash';
import { getTreeSelectVersionOptions } from 'utils/getTreeSelectVersionOptions';
import { isMobile, isPcOrLinux } from 'utils/platform';
import i18n from '../../../../i18n';
import VersionHybridSelect from 'components/commons/VersionHybridSelect';
import { isNotNullish } from 'utils/nullish';
import LabeledField from 'components/exception/BaseProblemPage/PageHeader/LabeledField';
import moment from 'moment';
import { ze } from 'utils/zhEn';
import { ExceptionCategoryUtil } from 'utils/exception-category';
import { useSelector } from 'react-redux';
import { selectNullableServerAppSettings } from 'utils/selectors/selectors';
import { ServerAppSettings } from 'utils/server-app-settings';
import reportEvent, { EVENT_ACTIONS, getPageTitle } from 'utils/reportEvent';
import DuibiIconNormal from 'svg/v2/newcs_dashboard_duibi_icon_normal.svg';
import IconFilter from 'svg/v2/newcs_dashboard_crashanalysis_issuelist_slide_myfilter_icon.svg';
import { CS_STYLES } from 'utils/constants/style-constants';
import { CsSelect } from 'components/antd-extension/CsSelect';
import IssueStatusSelect from 'components/commons/IssueCrashFilter/IssueStatusSelect';

const TOP_HEADER_HEIGHT = parseInt(CS_STYLES.MAIN_HEADER_HEIGHT.split('px')[0]);

const RelativeTimeSelect = ({
  value,
  onChange,
}) => {
  const { t } = useTranslation();
  const options = [
    ...[
      'last_1_hour',
      'last_1_day',
      'last_2_day',
      'last_7_day',
      'last_15_day',
      'last_30_day',
    ].map(x => ({ value: x, label: t(`timePicker.${DATE_MAP_VALUE[x]}`) })),
    { label: t('timePicker.所有上报时间'), value: '' },
  ];

  const valueMatchedOption = options.find(x => x.value === value);
  const innerValue = isNotNullish(valueMatchedOption) ? valueMatchedOption.value : '';

  return <Select
    size='large'
    style={{ width: '100%', fontSize: '14px' }}
    options={options}
    value={innerValue}
    onChange={(v) => onChange(v)}
    dropdownRender={(originNode) => {
      return <div>
        {originNode}
        <div style={{padding: '0 8px'}}><Divider style={{ margin: 0 }}/></div>
        <div style={{ padding: '5px 12px', color: '#AAA' }}>{ t('timePicker.问题列表不支持自定义时间提示') }</div>
      </div>
    }}
  />;
}

/**
 * 崩溃分析-问题列表-头部搜索条件视图
 */
const PageHeader = ({
  searchOptions, searchParams, handleChange, handleChangeWithoutSendSearchRequest, handleSearch, currentApp, exceptionType, handleChangeSearchBarHeight
}) => {
  const { t } = useTranslation();
  const {
    version, exceptionTypeList, status, processor,
  } = searchOptions.toJS();

  const { date, cmpDate } = searchParams.toJS();

  const { appId, pid, appName } = currentApp.toJS();
  const tapdWorkspaceId = useSelector(reduxState => reduxState.app.get('appIdToTapdWorkspaceId')[appId]);
  const showTapdFilter = !!tapdWorkspaceId;

  const nullableServerAppSettings = useSelector(reduxState => selectNullableServerAppSettings(reduxState, appId));
  const enableCustomErrorTypes = !!(nullableServerAppSettings || {})[ServerAppSettings.keys.enableCustomErrorTypes];

  const isCompareVersionSupported = true;

  const isCompareSearch = !!(searchParams.get('isCmp') | 0);

  const [hookState, setHookState] = useState({
    isVersionSelectDropdownVisible: false,
    isCmpVersionSelectDropdownVisible: false,
    versionsBeforeDropdownVisible: null,
    cmpVersionsBeforeDropdownVisible: null,
  });

  const [pageHeaderHeight, setPageHeaderHeight] = useState(0);
  const [isSearchBarExpanded, setSearchBarExpanded] = useState(false);
  const [searchData, updateSearchData] = useImmer({
    version: [],
    versionA: [],
    changeEventTrigger: 0,
  });

  const pageHeaderInstance = useRef(null);
  const [needFixedHeight, setNeedFixedHeight] = useState(100);
  const [searchSelectBaseWidth, setSearchSelectBaseWidth] = useState(0);

  const handleCalcWidth = () => {
    const componentWidth = pageHeaderInstance.current.offsetWidth;
    const searchBar = pageHeaderInstance.current;
    const searchBarRect = searchBar.getBoundingClientRect();
    setNeedFixedHeight(searchBarRect.top);
    setSearchSelectBaseWidth((componentWidth - 42 - 10 * 3 - 1) / 8); // 将宽度分成7个，减去padding，减去中间的间隔，减去边宽
  };
  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        handleCalcWidth();
      }
    });

    if (pageHeaderInstance.current) {
      resizeObserver.observe(pageHeaderInstance.current);
    }

    return () => {
      if (pageHeaderInstance.current) {
        resizeObserver.unobserve(pageHeaderInstance.current);
      }
    };
  }, []);

  const topHeaderHeight = TOP_HEADER_HEIGHT;
  const [isFixed, setIsFixed] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const searchBar = pageHeaderInstance.current;
      const searchBarRect = searchBar.getBoundingClientRect();

      const _isFixed = scrollTop >= ((needFixedHeight > searchBar.offsetHeight) ? 10 : searchBarRect.bottom);
      setIsFixed(_isFixed);
      // if (_isFixed && searchBarRect.top !== topHeaderHeight) {
      //   document.documentElement.scrollTop += searchBarRect.top - topHeaderHeight;
      // }
      _isFixed && setPageHeaderHeight(pageHeaderInstance.current.offsetHeight);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    handleChangeSearchBarHeight(pageHeaderHeight)
  }, [pageHeaderHeight])

  const processorNameMap = (processor.options ?? []).reduce((obj, { label, value }) => ({ ...obj, [value]: label }), {});

  let exceptionTypeOptions = exceptionTypeList.options.map(x => ({
    ...x,
    shortLabel: x.shortLabel || x.label,
  }));
  switch (exceptionType) {
    case 'crash':
      if (parseInt(pid) === 1) {
        exceptionTypeOptions = exceptionTypeOptions.filter((item) => item.value !== 'ExtensionCrash');
      } else if (parseInt(pid) === 2) {
        exceptionTypeOptions = exceptionTypeOptions.filter((item) => item.value !== 'Native');
      }
      break;
    default:
  }
  if (ExceptionCategoryUtil.isError(exceptionType) && enableCustomErrorTypes) {
    exceptionTypeOptions = [
      ...exceptionTypeOptions,
      ...ERROR_EXCEPTION_TYPE_LIST_CUSTOM_ERROR_OPTIONS,
    ];
  }

  function handleChangeSelect(name, val) {
    console.log("=== handleChangeSelect ===", name, val)
    handleChangeWithoutSendSearchRequest(name, val);
  }

  function getSelectedVersions() {
    const { version } = searchParams.toJS();
    return (version && version !== 'all') ? version.split(';') : [];
  }

  function getSelectedIssueStartingVersionList() {
    const { issueStartingVersion } = searchParams.toJS();
    return (issueStartingVersion && issueStartingVersion !== 'all') ? issueStartingVersion.split(';') : [];
  }

  function getSelectedCmpVersions() {
    const { cmpVersion } = searchParams.toJS();
    return (cmpVersion && cmpVersion !== 'all') ? cmpVersion.split(';') : [];
  }

  function getIssueFirstUploadTimeValuePair() {
    const { issueFirstUploadTimeBeginMillis, issueFirstUploadTimeEndMillis } = searchParams.toJS();
    return [
      issueFirstUploadTimeBeginMillis ? moment(Number(issueFirstUploadTimeBeginMillis)) : null,
      issueFirstUploadTimeEndMillis ? moment(Number(issueFirstUploadTimeEndMillis)) : null,
    ];
  }

  function handleKeyPress(search) {
    updateSearchData(draft => { draft.issueID = search; });
    handleChangeSelect({
        search,
      });
  }

  function getVersionTree() {
    return getTreeSelectVersionOptions(version.options.filter(x => x.value && x.value !== 'all'));
  }

  /**
   * @param {string[]} v
   */
  function getVersionWithWildcardStringFromTreeSelectValue(v) {
    return v.join(';');
  }

  const showExceptionTypeListSelect = isMobile(pid) && exceptionTypeList.options.length > 1;

  const statusQueryParam = searchParams.get('status');
  const statusListValue = (statusQueryParam && statusQueryParam !== 'all')
    ? statusQueryParam.split(',')
    : [];
  const exceptionTypeListQueryString = searchParams.get('exceptionTypeList') || '';
  const exceptionTypeListValue = exceptionTypeListQueryString
    ? exceptionTypeListQueryString.split(',')
    : [];
  const tableStyles = !(!isCompareSearch && isCompareVersionSupported)
    ? {background:'#fafafa', padding:'8px', border:'1px solid #e4e4e4'}
    : null;

  const categoryLabel = ExceptionCategoryUtil.toI18n(exceptionType, pid);
  const searchCriteria = (
    <Space size={8}  wrap={!isCompareSearch}>
      <div style={{...tableStyles,marginRight:'8px'}}>
        {isCompareSearch && <div style={{ margin: '0 0 4px', color: 'rgba(0, 0, 0, 0.65)' }}>{ t('BASEPROBLEM.查询条件B') }</div>}
        {isCompareSearch && <div><Space size={8}>
          <LabeledField label={t('BASEPROBLEM.包含版本')}><VersionHybridSelect
            size='large'
            style={{ width: '300px' }}
            treeData={getVersionTree()}
            value={getSelectedCmpVersions()}
            onChangeWhenDropdownClosed={(v) => {
              const versionListString = getVersionWithWildcardStringFromTreeSelectValue(v);
              handleChangeSelect({ cmpVersion: versionListString });
            }}
          /></LabeledField>
          <LabeledField label={t('BASEPROBLEM.最后上报时间')}><RelativeTimeSelect
            value={cmpDate}
            onChange={(v) => handleChangeSelect('cmpDate', { date: v, startDate: '', endDate: '' })}
          /></LabeledField>
        </Space></div>}
      </div>
      { isCompareVersionSupported && <Checkbox
        checked={isCompareSearch}
        style={{ marginTop:'8px' }}
        onChange={(e) => {
          handleChangeWithoutSendSearchRequest({
            isCmp: e.target.checked | 0,
            search: '',
          });
        }}
      >{t('BASEPROBLEM.开启对比查询')}1</Checkbox>}
    </Space>
  );

  /** 渲染开启对比查询的icon */
  const renderCompareIcon = () => {
    if (!isCompareVersionSupported) {
      return;
    }
    return <Tooltip arrow={true}
      placement="right"
      title={isCompareSearch? ze('关闭对比模式', 'Disable Compare Search') : t('BASEPROBLEM.开启对比查询')}
    ><div className={isCompareSearch ? style.icon_compare_close : style.icon_compare} onClick={(e) => {
      handleChangeWithoutSendSearchRequest({
        isCmp: isCompareSearch ? 0 : 1,
        search: '',
      });
      if (!isCompareSearch) {
        reportEvent({
          action: EVENT_ACTIONS.CLICK,
          tp1: `${getPageTitle()}-开启对比查询`
        })
      }
    }}><DuibiIconNormal /></div>
    </Tooltip>
  }

  const searchBarSelectTitleStyle = { color: '#807997', fontSize: 12, padding: '0 0 8px 12px' };

  const searchSelectMap = {
    'appVersion': (
      <LabeledField style={{ width: searchSelectBaseWidth * 2}} labelStyle={searchBarSelectTitleStyle} label={t('BASEPROBLEM.包含版本')}><VersionHybridSelect
        style={{ width: '100%', fontSize: '14px' }}
        treeData={getVersionTree()}
        value={getSelectedVersions()}
        onChangeWhenDropdownClosed={(v) => {
          const versionListString = getVersionWithWildcardStringFromTreeSelectValue(v);
          handleChangeSelect({ version: versionListString });
          updateSearchData(draft => { draft.version = versionListString; });
        }}
      /></LabeledField>
    ),
    'appVersionCompareB': (
      <LabeledField style={{ width: searchSelectBaseWidth * 2 }} labelStyle={searchBarSelectTitleStyle} label={t('BASEPROBLEM.包含版本')}><VersionHybridSelect
        style={{ width: '100%', fontSize: '14px' }}
        treeData={getVersionTree()}
        value={getSelectedCmpVersions()}
        onChangeWhenDropdownClosed={(v) => {
          const versionListString = getVersionWithWildcardStringFromTreeSelectValue(v);
          handleChangeSelect({ cmpVersion: versionListString });
          updateSearchData(draft => { draft.cmpVersion = versionListString; });
        }}
      /></LabeledField>
    ),
    'lastReportedTime': (
      <LabeledField style={{ width: searchSelectBaseWidth * 2 }} labelStyle={searchBarSelectTitleStyle} label={t('BASEPROBLEM.最后上报时间')}><RelativeTimeSelect
        value={date}
        onChange={(v) => {
          handleChangeSelect('date', { date: v, startDate: '', endDate: '' })
          updateSearchData(draft => { draft.date = v; });
        }}
      /></LabeledField>
    ),
    'lastReportedTimeCompareB': (
      <LabeledField style={{ width: searchSelectBaseWidth * 2 }} labelStyle={searchBarSelectTitleStyle} label={t('BASEPROBLEM.最后上报时间')}><RelativeTimeSelect
        value={cmpDate}
        onChange={(v) => {
          handleChangeSelect('cmpDate', { date: v, startDate: '', endDate: '' })
          updateSearchData(draft => { draft.cmpDate = v; });
        }}
      /></LabeledField>
    ),
    'startingVersion': (
      isMobile(pid) && <LabeledField style={{ width: searchSelectBaseWidth * 2 }} labelStyle={searchBarSelectTitleStyle} label={t('BASEPROBLEM.指定版本的新增问题')}><VersionHybridSelect
        style={{ width: '100%', fontSize: '14px' }}
        treeData={getVersionTree()}
        value={getSelectedIssueStartingVersionList()}
        onChangeWhenDropdownClosed={(v) => {
          const versionListString = getVersionWithWildcardStringFromTreeSelectValue(v);
          handleChangeSelect({ issueStartingVersion: versionListString });
          updateSearchData(draft => { draft.issueStartingVersion = versionListString; });
        }}
      /></LabeledField>
    ),
    'issueFirstReportedTime': (
      <LabeledField style={{ width: searchSelectBaseWidth * 2 }} labelStyle={searchBarSelectTitleStyle} label={t('BASEPROBLEM.指定时间段内的新增问题')}><DatePicker.RangePicker
        style={{ width: '100%' }}
        allowEmpty={[true, true]}
        showTime
        value={getIssueFirstUploadTimeValuePair()}
        placeholder={[ze('开始日期','Start Date'), ze('结束日期','End Date')]}
        ranges={{
          [ze('最近1小时', 'Last 1 Hour')]: [moment().subtract(1, 'hours'), null],
          [ze('最近24小时', 'Last 24 Hours')]: [moment().subtract(1, 'days'), null],
          [ze('最近72小时', 'Last 72 Hours')]: [moment().subtract(3, 'days'), null],
          [ze('最近7天', 'Last 7 Days')]: [moment().subtract(7, 'days'), null],
        }}
        onChange={(dates) => {
          const [beginMoment, endMoment] = dates || [null, null];
          const issueFirstUploadTimeBeginMillis = beginMoment ? beginMoment.valueOf() : '';
          const issueFirstUploadTimeEndMillis = endMoment ? endMoment.valueOf() : '';
          handleChangeSelect({ issueFirstUploadTimeBeginMillis, issueFirstUploadTimeEndMillis });
          updateSearchData(draft => {
            draft.issueFirstUploadTimeBeginMillis = issueFirstUploadTimeBeginMillis;
            draft.issueFirstUploadTimeEndMillis = issueFirstUploadTimeEndMillis;
          });
        }}
      /></LabeledField>
    ),
    'issueID': (
      <LabeledField style={{ width: searchSelectBaseWidth * 2 }} labelStyle={searchBarSelectTitleStyle} label={t('BASEPROBLEM.问题ID或异常名')}>
        <div style={{ width: '100%' }}>
          <SearchBox
            defaultSearch={searchParams.get('search')}
            handleSearch={handleSearch}
            handleSearchInput={(event) => handleKeyPress(event)}
            style={style}
            placeholder={ isCompareSearch ? t('BASEPROBLEM.searchPlaceholderCompareSearch') : t('BASEPROBLEM.searchPlaceholder') }
          />
        </div>
      </LabeledField>
    ),
    'issueStatus': (
      <LabeledField style={{ width: searchSelectBaseWidth * 2 }} labelStyle={searchBarSelectTitleStyle} label={t('BASEPROBLEM.处理状态')}><IssueStatusSelect
        style={{ width: '100%', fontSize: '14px' }}
        isMultiple={true}
        value={statusListValue}
        onChange={v => {
          handleChangeSelect('status', (v || []).join(','));
          updateSearchData(draft => { draft.status = (v || []).join(','); });
        }}
      /></LabeledField>
    ),
    'TAPDIssueLinked': (
      showTapdFilter && <LabeledField style={{ width: searchSelectBaseWidth * 2 }} labelStyle={searchBarSelectTitleStyle} label={t('BASEPROBLEM.是否关联缺陷单')}><Select
        style={{ width: '100%', fontSize: '14px' }}
        placeholder={t('tapdBugStatusOptions.所有TAPD缺陷关联状态')}
        options={TapdBugStatusOptions}
        value={searchParams.get('tapdBugStatus') || null}
        defaultValue={searchParams.get('tapdBugStatus') || null}
        onChange={value => {
          handleChangeSelect('tapdBugStatus', value);
          updateSearchData(draft => { draft.tapdBugStatus = value; });
        }}
      /></LabeledField>
    ),
    'category': (
      showExceptionTypeListSelect && (
        <LabeledField style={{ width: searchSelectBaseWidth * 2 }} labelStyle={searchBarSelectTitleStyle} label={ ze(`${categoryLabel}类型`, `${categoryLabel} Category`) }><Select
          style={{ width: '100%', fontSize: '14px' }}
          optionLabelProp='shortLabel'
          mode='multiple'
          placeholder={ t('exceptionTypeOptions.所有类型') }
          options={exceptionTypeOptions}
          value={exceptionTypeListValue}
          onChange={(v) => {
            v = v || [];
            // 带逗号的是全部类型的选项
            if (v.some(x => x.includes(',')) || v.length === 0) {
              handleChangeSelect('exceptionTypeList', '');
              updateSearchData(draft => { draft.exceptionTypeList = ''; });
            } else {
              handleChangeSelect('exceptionTypeList', v.join(','));
              updateSearchData(draft => { draft.exceptionTypeList = v.join(','); });
            }
          }}
        /></LabeledField>
      )
    ),
    'processor': (
      <LabeledField style={{ width: searchSelectBaseWidth * 2 }} labelStyle={searchBarSelectTitleStyle} label={t('issueCrashFilterKey.processor')}><Select
        style={{ width: '100%', fontSize: '14px' }}
        showSearch={true}
        options={processor.options}
        optionFilterProp='label'
        placeholder={t('processorOptions.所有处理人')}
        value={searchParams.get('processor') || null}
        onChange={(value) => {
          handleChangeSelect('processor', value);
          updateSearchData(draft => { draft.processor = value; });
        }}
      /></LabeledField>
    )
  }

  const renderSearchBarNotCompared = () => {
    const expandBtn = <div style={{ marginLeft: 10 }}>
      <div style={{ fontSize: '12px', color: 'rgba(0,0,0,0)', padding: '0 0 4px 6px', userSelect: 'none'}}>-</div>
      <div className={style.expand_btn} onClick={() => setSearchBarExpanded(!isSearchBarExpanded)}>
        <span className={isSearchBarExpanded ? style.expand_btn_arrow_top : style.expand_btn_arrow_bottom}></span>
        {/* {isSearchBarExpanded ? <div className={style.expand_btn_arrow_top}></div> : <div className={style.expand_btn_arrow_bottom}></div>} */}
      </div>
    </div>

    // 搜索栏不可以收起的下拉选择菜单
    const searchBarCannotCollapsedSelectNameList = [
      'appVersion', 'lastReportedTime', 'issueID',
      showExceptionTypeListSelect? 'category' : 'category',
    ];
    const searchBarCannotCollapsed = <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ flex: 1, display: 'flex', gap: 10}}>
        {searchBarCannotCollapsedSelectNameList.map((selectName) => searchSelectMap[selectName])}
      </div>
      {expandBtn}
    </div>


    // 搜索栏可以被收起的下拉选择菜单
    const searchBarCanCollapsedSelectNameList = [
      'startingVersion', 'issueFirstReportedTime', 'issueStatus',
      'TAPDIssueLinked', 'processor'
    ];
    const searchBarCanCollapsed = <div className={style.expand_search_bar_box}>
      <div style={{ flex: 1, display: 'flex', gap: 10, flexWrap: 'wrap'}}>
        {searchBarCanCollapsedSelectNameList.map((selectName) => searchSelectMap[selectName])}
      </div>
    </div>

    return <div>
      {searchBarCannotCollapsed}
      {isSearchBarExpanded && searchBarCanCollapsed}
      <Space>
        <div style={{ width: '100%', marginTop: '20px' }}>
          {isCompareSearch
            ? <Button type='primary' onClick={() => handleSearch()} >{ t('SENIORSEARCH.search') }</Button>
            : <Button type='primary' onClick={() => handleChange()} style={{marginBottom:'20px'}}>{ t('SENIORSEARCH.search') }</Button>}
        </div>
      </Space>
    </div>
  }

  const renderSearchBarCompared = () => {
    const searchConditionBar = <div style={{ display: 'flex', gap: 10, marginBottom: 20 }}>
      <LabeledField labelStyle={{ color: "#807997", fontSize: 14, marginBottom: 15 }} label={t('BASEPROBLEM.查询条件A')}>
        <div className={style.search_condition_bar_box}>
          <div style={{display: 'flex', gap: 10}}>
            {['appVersion', 'lastReportedTime'].map((selectName) => searchSelectMap[selectName])}
          </div>
        </div>
      </LabeledField>
      <LabeledField labelStyle={{ color: "#807997", fontSize: 14, marginBottom: 15 }} label={t('BASEPROBLEM.查询条件B')}>
        <div className={style.search_condition_bar_box}>
          <div style={{display: 'flex', gap: 10}}>
            {['appVersionCompareB', 'lastReportedTimeCompareB'].map((selectName) => searchSelectMap[selectName])}
          </div>
        </div>
      </LabeledField>
    </div>

    // 搜索栏可以被收起的下拉选择菜单
    const searchBarNameList = [
      'startingVersion', 'issueFirstReportedTime', 'issueStatus',
      'TAPDIssueLinked', 'processor', 'issueID',
      showExceptionTypeListSelect? 'category' : 'category',
    ];
    const searchBar = <div>
      <div style={{ flex: 1, display: 'flex', gap: 10, flexWrap: 'wrap'}}>
        {searchBarNameList.map((selectName) => searchSelectMap[selectName])}
      </div>
    </div>

    return <div>
      {searchConditionBar}
      {searchBar}
      <Space>
        <div style={{ width: '100%', marginTop: '15px' }}>
          {isCompareSearch
            ? <Button type='primary' onClick={() => handleSearch()} >{ t('SENIORSEARCH.search') }</Button>
            : <Button type='primary' onClick={() => handleChange()} style={{marginBottom:'15px'}}>{ t('SENIORSEARCH.search') }</Button>}
        </div>
      </Space>
    </div>
  }

  const renderSearchBarFixedSelectTab = (label, value) => {
    if (value === 'all') {
      value = 'All';
    }
    return <Space size={8}>
      <span style={{ fontSize: 12, color: '#807997', opacity: 0.6 }}>{ label }</span>
      <span style={{ fontSize: 14, color: '#393157' }}>{ value }</span>
    </Space>
  }

  const formatSearchParamVersion = (version) => {
    let versionStr = '';
    if (version && version !== 'all') {
      let versionSplit = version.split(';');
      versionStr = versionSplit[0];
      if (versionSplit.length > 1) {
        versionStr += `,+${versionSplit.length - 1}`;
      }
    } else {
      versionStr = t('EXCP_OVERVIEW.allVersion');
    }
    return versionStr;
  }

  const renderSearchBarFixed = () => {
    const { version, cmpVersion, issueStartingVersion, issueFirstUploadTimeBeginMillis, issueFirstUploadTimeEndMillis } = searchParams.toJS();
    const searchBarFixedAndCompared = <Space size={32} wrap={true}>
      <Space size={8} wrap={true}>
        <span className={style.compare_title_span}>{t('BASEPROBLEM.查询条件A')}</span>
        { renderSearchBarFixedSelectTab(t('BASEPROBLEM.包含版本'), formatSearchParamVersion(version))}
        {renderSearchBarFixedSelectTab(t('BASEPROBLEM.最后上报时间'), (date && date !== "all") ? t(`timePicker.${DATE_MAP_VALUE[date]}`) : t('timePicker.所有上报时间'))}
      </Space>
      <Space size={8} wrap={true}>
        <span className={style.compare_title_span}>{t('BASEPROBLEM.查询条件B')}</span>
        { renderSearchBarFixedSelectTab(t('BASEPROBLEM.包含版本'), formatSearchParamVersion(cmpVersion)) }
        {renderSearchBarFixedSelectTab(t('BASEPROBLEM.最后上报时间'), (cmpDate && cmpDate !== "all") ? t(`timePicker.${DATE_MAP_VALUE[cmpDate]}`) : t('timePicker.所有上报时间'))}
      </Space>
    </Space>

    const searchBarFixed = <Space size={[16, 8]} wrap={true}>
      {!isCompareSearch &&
        <span className={style.not_compare_title_span}>
          <IconFilter />
          {ze('过滤', 'My Filter')}
        </span>
      }
      {!isCompareSearch && renderSearchBarFixedSelectTab(t('BASEPROBLEM.包含版本'), formatSearchParamVersion(version))}
      {!isCompareSearch && renderSearchBarFixedSelectTab(t('BASEPROBLEM.最后上报时间'), (date && date !== "all") ? t(`timePicker.${DATE_MAP_VALUE[date]}`) : t('timePicker.所有上报时间'))}
      { renderSearchBarFixedSelectTab(t('BASEPROBLEM.指定版本的新增问题'), formatSearchParamVersion(issueStartingVersion)) }
      {
        issueFirstUploadTimeBeginMillis && issueFirstUploadTimeEndMillis &&
        renderSearchBarFixedSelectTab(
          t('BASEPROBLEM.指定时间段内的新增问题'),
          `${moment(Number(issueFirstUploadTimeBeginMillis)).format('YYYY-MM-DD HH:mm:ss')}
           ~ ${moment(Number(issueFirstUploadTimeBeginMillis)).format('YYYY-MM-DD HH:mm:ss')}`
        )
      }
      { searchParams.get('search') && renderSearchBarFixedSelectTab(t('BASEPROBLEM.问题ID或异常名'), searchParams.get('search')) }
      { (statusListValue.length > 0) && renderSearchBarFixedSelectTab(t('BASEPROBLEM.处理状态'), statusListValue.map(v => ISSUE_STATUS_VALUE_TO_LABEL[v]).join(',')) }
      { (exceptionTypeListValue.length > 0) && renderSearchBarFixedSelectTab(ze(`${categoryLabel}类型`, `${categoryLabel} Category`), exceptionTypeListValue) }
      { searchParams.get('tapdBugStatus') && renderSearchBarFixedSelectTab(t('BASEPROBLEM.是否关联缺陷单'), searchParams.get('tapdBugStatus') === 'all' ? ze('不限', 'All') :searchParams.get('tapdBugStatus')) }
      { searchParams.get('processor') && renderSearchBarFixedSelectTab(t('issueCrashFilterKey.processor'), processorNameMap[searchParams.get('processor')]) }
    </Space>
    return <div style={{ width: '100%' }}>
      {
        isCompareSearch && <div style={{ marginBottom: 7 }}>
          {searchBarFixedAndCompared}
        </div>
      }
      <div>
        { searchBarFixed }
      </div>
    </div>;
  }

  return (
    <div ref={pageHeaderInstance} className={isFixed ? style.page_header_fixed : style.page_header} style={{ paddingTop: (isFixed || isCompareSearch) ? 15 : 24, top: isFixed ? TOP_HEADER_HEIGHT : 'unset' }} >
      { !isFixed && renderCompareIcon() }
      { isFixed ? renderSearchBarFixed() : (isCompareSearch ? renderSearchBarCompared() : renderSearchBarNotCompared()) }
    </div>
  );
};

PageHeader.propTypes = {
  searchParams: PropTypes.object.isRequired,
  searchOptions: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleChangeWithoutSendSearchRequest: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  currentApp: PropTypes.object.isRequired,
  exceptionType: PropTypes.string.isRequired,
  handleChangeSearchBarHeight: PropTypes.func,
};

export default PageHeader;
