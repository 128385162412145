import React, { useState, useEffect, useMemo, useContext } from 'react';
import { DatePicker, Input, InputNumber, Select, Space, Tooltip } from 'antd';
import { isNotNullish, isNullish } from 'utils/nullish';
import { CaseSensitivity, QueryType } from 'components/commons/IssueCrashFilter/IssueCrashFilterExUtil';
import { ze } from 'utils/zhEn';
import { VscCaseSensitive } from 'react-icons/vsc';
import { CS_STYLES } from 'utils/constants/style-constants';
import { VersatileKeywordSearchMethod } from 'components/commons/IssueCrashFilter/VersatileKeywordSearchMethod';
import WrappedTipsIcon from 'components/antd-extension/WrappedTipsIcon.jsx';
import { CsSelect } from 'components/antd-extension/CsSelect';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';

const SearchMethod = VersatileKeywordSearchMethod;

const SearchMethodToInfo = {
  [SearchMethod.IN]: {
    queryType: QueryType.TERMS,
    not: false,
    isTermsLike: true,
  },
  [SearchMethod.NOT_IN]: {
    queryType: QueryType.TERMS,
    not: true,
    isTermsLike: true,
  },
  [SearchMethod.IS_EMPTY]: {
    queryType: QueryType.NONEMPTY,
    not: true,
  },
  [SearchMethod.IS_NOT_EMPTY]: {
    queryType: QueryType.NONEMPTY,
    not: false,
  },
};

const SearchMethodInfoEntries = Object.entries(SearchMethodToInfo);

function findSearchMethod(queryType, not) {
  const foundEntry = SearchMethodInfoEntries.find(([k, v]) => {
    return v.queryType === queryType && v.not === Boolean(not);
  });
  return isNotNullish(foundEntry) ? foundEntry[0] : null;
}
const QUERY_TYPE_OPTIONS = [
  { name: ze('包含', 'Contains'), value: SearchMethod.IN },
  { name: ze('不包含', 'Not Contains'), value: SearchMethod.NOT_IN },
  { name: ze('为空', 'Is Empty'), value: SearchMethod.IS_EMPTY },
  { name: ze('非空', 'Not Empty'), value: SearchMethod.IS_NOT_EMPTY },
].map(x => ({
  ...x,
  label: x.name,
}));

const ProcessorSearch = ({
  options,
  value,
  onChange = () => {},
  isReadOnly,
}) => {
  const { t } = useTranslation();

  const {
    queryType: nullableQueryType,
    not,
    terms,
  } = value || {};

  const queryType = nullableQueryType || QueryType.TERMS;
  let searchMethodValue = findSearchMethod(queryType, not);

  return <div style={{ display: 'flex' }}>
    <Select
      style={{ width: '120px', flex: '0 0 auto' }}
      options={QUERY_TYPE_OPTIONS}
      optionFilterProp='name'
      optionLabelProp='name'
      value={ searchMethodValue }
      onChange={(v) => {
        if (isReadOnly) {
          return;
        }
        const newInfo = SearchMethodToInfo[v];
        onChange({ queryType: newInfo.queryType, not: newInfo.not });
      }}
    />
    { queryType === QueryType.NONEMPTY && <Input
      style={{ flexGrow: 1 }}
      disabled
    /> }
    { queryType !== QueryType.NONEMPTY && <CsSelect
      style={{ width: '100%', fontSize: '14px' }}
      mode='multiple'
      allowClear
      showSearch={true}
      optionFilterProp='label'
      options={options}
      placeholder={ t('processorOptions.所有处理人') }
      value={ terms }
      onChange={(v) => {
        if (isReadOnly) {
          return;
        }
        onChange({
          queryType,
          not,
          terms: v,
        });
      }}
    /> }
  </div>
}

export default ProcessorSearch;
