import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Divider, Input, Select } from 'antd';
import { ze } from 'utils/zhEn';
import { useDispatch, useSelector } from 'react-redux';
import { selectIssueCrashFilterCustomKeyOptionsInfo } from 'utils/selectors/selectors';
import { actionFetchCustomKeyOptions } from 'reducers/seniorSearch';
import { CS_STYLES } from 'utils/constants/style-constants';

function CustomKvSearchKey({
  appId,
  isReadOnly,
  value,
  onChange,
}) {
  const { hasFetched, options } = useSelector(selectIssueCrashFilterCustomKeyOptionsInfo);
  const dispatch = useDispatch();

  const enrichedOptions = useMemo(() => {
    return (options || []).map(x => {
      return {
        ...x,
        rawLabel: x.label,
        label: <div>
          <span>{ x.label }</span>
          <span style={{ color: '#807997', marginLeft: '8px' }}>({ x.keyTypeLabel })</span>
        </div>,
      };
    });
  }, [options]);

  const [userInputKey, setUserInputKey] = useState('');
  const [userInputError, setUserInputError] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!appId) {
      return;
    }
    if (!hasFetched) {
      dispatch(actionFetchCustomKeyOptions());
    }
  }, [appId]);

  if (isReadOnly) {
    return <Input
      style={{ width: '300px' }}
      value={value}
      readOnly={true}
    />;
  }

  return <Select
    open={isOpen}
    style={{ width: '300px' }}
    options={enrichedOptions}
    value={value}
    showSearch={true}
    optionFilterProp='rawLabel'
    onDropdownVisibleChange={(v) => setIsOpen(v)}
    onChange={(v) => {
      if (isReadOnly) {
        return;
      }
      onChange(v);
    }}
    dropdownRender={(menu) => {
      return <div>
        <div>{menu}</div>
        <Divider style={{ margin: '8px 0' }}/>
        <div style={{ padding: '5px 12px' }}>
          <div style={{ fontSize: '12px', color: '#999', userSelect: 'none' }}>{ ze('选项只列出了近期有过上报的自定义关键字，如果选项里没有列出要搜索的关键字，可以直接输入：', 'Only lists the keys that have recently reported, if the key to be searched is not listed in the option, you can directly enter here:') }</div>
          <Input.Group compact style={{ display: 'flex' }}>
            <Input
              value={userInputKey}
              onChange={(e) => setUserInputKey(e.target.value.trim())}
            />
            <Button onClick={() => {
              if (!/^(C03_)?[A-Z]#.+$/.test(userInputKey)) {
                setUserInputError(ze('格式必须是 {类型(大写字母)}#{key名} 。例如：K#test', 'Must be the format: {type}#{key}. For example: K#test'));
                return;
              }
              setUserInputError('');
              const finalKey = userInputKey;
              setIsOpen(false);
              onChange(finalKey);
            }}>{ ze('确认', 'OK') }</Button>
          </Input.Group>
          { userInputError && <div style={{ fontSize: '12px', color: 'red' }}>{userInputError}</div> }
        </div>
      </div>
    }}
  />;
}

export default CustomKvSearchKey;
