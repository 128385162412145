import { message } from 'antd';
import { ze } from 'utils/zhEn';

export const DemoUtil = (function() {
  function checkAndWarnIfDemoApp(demoApp) {
    if (demoApp) {
      message.warn(ze('Demo项目不支持此操作', 'Cannot perform the operation on demo apps.'));
    }
    return demoApp;
  }

  return Object.freeze({
    checkAndWarnIfDemoApp,
  });
})();
