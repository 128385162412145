import RestHelper from './RestHelper';

export default RestHelper.publish({
  user: {
    getUserInfo: {
      method: 'post',
      uri: '/api/user/getUserInfo',
    },
    update: {
      method: 'post',
      uri: '/redir/api/userResource/updateUserInfo',
    },
    login: {
      method: 'post',
      uri: '/login',
    },
  },

  members: {
    /**
     * 获取所有的成员, qq号
     */
    get: {
      method: 'get',
      uri: '/members/appId/{appId}/platformId/{pid}',
    },
  },
  members2: {
    /**
     * 获取所有的成员, qq号,用于更新当前成员角色
     */
    get: {
      method: 'get',
      uri: '/members/appId/{appId}/platformId/{pid}',
    },
  },

  city: {
    get: {
      method: 'get',
      uri: '/getCity',
    },
  },

  token: {
    refresh: {
      method: 'get',
      uri: '/refreshToken',
    },
  },

  sendVeriticate: {
    /**
     * 发送手机验证码
     * 0修改手机 1新注册
     */
    get: {
      method: 'get',
      uri: '/users/{userId}/validateUser/operationFrom/{operationFrom}/phone/{phoneNum}',
    },
  },
  veriticateCode: {
    get: {
      method: 'get',
      uri: '/users/{userId}/checkVerifyCode/phone/{phoneNum}/code/{code}',
    },
  },

  frontendWebVolatileConfig: {
    post: {
      method: 'post',
      uri: '/redir/api/frontend-web-volatile-config/select-all',
    },
  },

  listPermission: {
    get: {
      method: 'get',
      uri: '/users/listPermission/{appId}',
    },
  },
});
