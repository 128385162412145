import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment-timezone';
import { DEFAULT_TIME_ZONE } from 'utils/time-zone-util';
import { CS_STYLES } from 'utils/constants/style-constants';
import { Tag, Tooltip } from 'antd';
import { ze } from 'utils/zhEn';
import { useTranslation } from 'react-i18next';

const HeaderClock = () => {
  const { t } = useTranslation();
  const [currentFormattedTimeWithZone, setCurrentFormattedTimeWithZone] = useState('');

  const [systemDefaultUtcOffsetMinutes, setSystemDefaultUtcOffsetMinutes] = useState(0);

  const [currentUtcOffsetMinutes, setCurrentUtcOffsetMinutes] = useState(0);

  useEffect(() => {
    setSystemDefaultUtcOffsetMinutes(moment().tz(DEFAULT_TIME_ZONE).utcOffset());
  }, []);

  useEffect(() => {
    const intervalId  = setInterval(() => {
      const currentFormattedTime = moment().format('YYYY-MM-DD HH:mm:ss');
      const utcOffsetMinutes = moment().utcOffset();
      const utcOffsetHours = utcOffsetMinutes / 60;
      let utcOffsetString = '(UTC)';
      if (utcOffsetHours > 0) {
        utcOffsetString = `(UTC+${utcOffsetHours})`;
      } else if (utcOffsetHours < 0) {
        utcOffsetString = `(UTC${utcOffsetHours})`;
      }

      setCurrentFormattedTimeWithZone(`${currentFormattedTime} ${utcOffsetString}`);
      setCurrentUtcOffsetMinutes(utcOffsetMinutes);
    }, 200);

    return () => {
      clearInterval(intervalId);
    }
  }, []);

  const isCustomUtcOffset = systemDefaultUtcOffsetMinutes !== currentUtcOffsetMinutes;

  if (!isCustomUtcOffset) {
    return <div
      style={{ color: '#ACB0BF' }}
    >{ currentFormattedTimeWithZone }</div>
  }

  return <div
    style={{ color: CS_STYLES.PRIMARY_COLOR, display: 'flex' }}
  >
    <Tooltip title={t('misc.项目自定义时区提示')} placement="bottom">
      <Tag color='geekblue' style={{ fontSize: '14px' }}>{ ze('自定义时区', 'Custom')}</Tag>
    </Tooltip>
    <div>{ currentFormattedTimeWithZone }</div>
  </div>;
};

export default HeaderClock;
