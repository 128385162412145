import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { formatNum } from 'utils/helper';
import style from './style.scss';
import { ze } from "utils/zhEn";
import { Table } from 'antd';
import RestHelper from 'utils/RestHelper';
import { ServerAppSettings } from 'utils/server-app-settings';
import moment from 'moment';
import { TimeUtil } from 'utils/time-util';
import {
  selectNullableServerAppSettings,
} from 'utils/selectors/selectors';
import { useSelector } from 'react-redux';
import { isNotNullish } from 'utils/nullish';
import { useTranslation } from 'react-i18next';
import { transformLocaleStrngToNumber } from 'utils/format/format-number'
import { VersionUtil } from 'utils/version-util';
import PercentageBar from 'components/commons/PercentageBar/PercentageBar';
import VersionHybridSelect from 'components/commons/VersionHybridSelect';
import { getTreeSelectVersionOptions } from 'utils/getTreeSelectVersionOptions';

const SystemInfoTable = (props) => {
  const { t } = useTranslation();
  const {
    appId,
    platformId,
    versions,
    issueId,
  } = props;

  const nullableServerAppSettings = useSelector(reduxState => selectNullableServerAppSettings(reduxState, appId));
  const hasServerAppSettingsFetched = isNotNullish(nullableServerAppSettings);
  const enableIssueLifetimeAllDataStats = (nullableServerAppSettings || {})[ServerAppSettings.keys.enableIssueLifetimeAllDataStats];

  const [issueLifetimeAllDataStatList, setIssueLifetimeAllDataStatList] = useState([]);
  const [innerValue, setInnerValue] = useState([]);

  useEffect(() => {
    setIssueLifetimeAllDataStatList([]);
  }, [appId]);

  useEffect(() => {
    if (!appId || !hasServerAppSettingsFetched) {
      return;
    }
    if (enableIssueLifetimeAllDataStats) {
      queryIssueLifetimeAllDataStat();
    }
  }, [appId, hasServerAppSettingsFetched]);

  function getDataSource(issueVersionList) {
    let countSum = 0;
    let arrList = [];
    const len = issueVersionList.length;
    if (len) {
      issueVersionList.forEach((arr) => {
        countSum += arr.count;
      });
    }// 没有数据的时候怎么显示，还没确定，还有动画

    arrList = issueVersionList;
    const mergedArr = [...arrList, ...issueLifetimeAllDataStatList].reduce((map, item) => {
      map.set(item.version, { ...(map.get(item.version) || {}), ...item });
      return map;
    }, new Map());
    const result = Array.from(mergedArr.values());
    return result.map((issue) => {
      const proportionPercentage = issue.count && countSum
        ? issue.count / countSum * 100
        : 0;

      return {
      version:issue.version ? issue.version : '-',
      postFirstTime: issue.firstUploadTimestamp ? moment(TimeUtil.fixChaosMillis(issue.firstUploadTimestamp)).format('YYYY-MM-DD HH:mm:ss') : '-',
      postLastTime: issue.lastUploadTimestamp ? moment(TimeUtil.fixChaosMillis(issue.lastUploadTimestamp)).format('YYYY-MM-DD HH:mm:ss') : '-',
      occurNum:issue.count ? formatNum(issue.count) : '-',
      proportionPercentage,
      userNumAffect:issue.deviceCount ? formatNum(issue.deviceCount) : '-',
      numAllData:issue.numAllData ? formatNum(issue.numAllData) : '-',
      devicesAllData:issue.devicesAllData ? formatNum(issue.devicesAllData) : '-',
    }});
  }

  async function queryIssueLifetimeAllDataStat() {
    const rsp = await RestHelper.post('/redir/api/statResource/queryIssueLifetimeAllDataStats',{ appId, issueId, platformId });
    setIssueLifetimeAllDataStatList(rsp.json.data);
  }

  // 数值对比方法
  function getCountCompareFn(key) {
    return (a, b) => {
      const aValue = transformLocaleStrngToNumber(a[key]);
      const bValue = transformLocaleStrngToNumber(b[key]);

      if (Number.isNaN(aValue)) return -1;
      if (Number.isNaN(bValue)) return 1;

      return aValue - bValue;
    };
  }

  function getColumns() {
    return [{
      title: t("SYMBOLINFOTABLE.version"),
      dataIndex: 'version',
      showcase:true,
      sorter: {
        compare: (a, b) => VersionUtil.compareVersion(a.version, b.version),
      }
    },{
      title: t("SYMBOLINFOTABLE.postFirstTime") ,
      dataIndex: 'postFirstTime',
      showcase:true,
      sorter: {
        compare: (a, b) => new Date(a.postFirstTime) - new Date(b.postFirstTime),
      },
    },{
      title: t("SYMBOLINFOTABLE.postLastTime") ,
      dataIndex: 'postLastTime',
      showcase:true,
      sorter: {
        compare: (a, b) => new Date(a.postLastTime) - new Date(b.postLastTime),
      },
    },{
      title: <div>
        {t("SYMBOLINFOTABLE.occurNum")}
        {enableIssueLifetimeAllDataStats && `(${ze('丢弃后','post-disposable')})`}
      </div>,
      dataIndex: 'occurNum',
      align: 'right',
      showcase:true,
      sorter: {
        compare: getCountCompareFn('occurNum'),
      },
    },{
      title: `${t("SYMBOLINFOTABLE.occurNum")}(${ze('丢弃前','pre-rejection')})`,
      dataIndex: 'numAllData',
      align: 'right',
      showcase:enableIssueLifetimeAllDataStats,
      sorter: {
        compare: getCountCompareFn('numAllData'),
      },
    },{
      title: t("SYMBOLINFOTABLE.numRate") ,
      dataIndex: 'proportionPercentage',
      align: 'right',
      showcase:true,
      render: (text, record) => {
        return <PercentageBar
          style={{ width: '100%' }}
          percentage={record.proportionPercentage}
        />;
      },
      sorter: {
        compare: (a, b) => a.proportionPercentage - b.proportionPercentage,
      },
    },{
      title: <div>
        {t("SYMBOLINFOTABLE.userNumAffect")}
        {enableIssueLifetimeAllDataStats && `(${ze('丢弃后','post-disposable')})`}
      </div>,
      dataIndex: 'userNumAffect',
      align: 'right',
      showcase:true,
      sorter: {
        compare: getCountCompareFn('userNumAffect'),
      },
    },{
      title: `${t("SYMBOLINFOTABLE.userNumAffect")}(${ze('丢弃前','pre-rejection')})` ,
      dataIndex: 'devicesAllData',
      showcase:enableIssueLifetimeAllDataStats,
      align: 'right',
      sorter: {
        compare: getCountCompareFn('devicesAllData'),
      },
    },].filter(item => item.showcase)
  }

  function render() {
    let issueVersionList = [];
    if (versions && versions.size > 0) {
      issueVersionList = versions.toJS();
    }
    const pageSize = 5;
    const data = getDataSource(issueVersionList);
    const isMultiplePages = (data || []).length > pageSize;
    const versionTreeData = data.map(item => {
      return {
        value:item.version,
        label:item.version
      }
    });
    const versionTree = getTreeSelectVersionOptions(versionTreeData || []);
    const tableData = innerValue.length > 0
      ? data.filter(item => {
          const itemVersion = item.version;
          return innerValue.some(val => {
            if (val.endsWith('*')) {
              const baseVal = val.slice(0, -1);
              return itemVersion.startsWith(baseVal);
            } else {
              return val === itemVersion;
            }
          });
        })
      : data;
    return (
      <div>
        <div style={{marginBottom:'10px'}}>
          <VersionHybridSelect
            style={{ minWidth:'320px', zIndex:'0' }}
            treeData={versionTree}
            value={ innerValue }
            onChange={(v) => { setInnerValue(v) }}
          />
        </div>
        <Table
          className={style.issue_table}
          size="small"
          columns={getColumns()}
          dataSource={tableData}
          pagination={isMultiplePages ? { pageSize } : false}
        />
      </div>
    );
  }

  return render();
}

SystemInfoTable.propTypes = {
  versions: PropTypes.object,
  issueId: PropTypes.object,
  platformId: PropTypes.number,
  appId: PropTypes.any,
};

export default SystemInfoTable;
