import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { PAGE_TYPE_SHOW } from 'utils/constants';
import { Select, DatePicker, Row, Col, Checkbox, Space } from 'antd';
import { Translation, useTranslation } from 'react-i18next';
import CardHead from '../../commons/CardHead';
import { CountryUtil } from 'utils/country';
import CountrySelect from 'components/exception/ProductSummaryPage/CountrySelect';
import { Tab } from 'components/exception/issue';
import VersionHybridSelect from 'components/commons/VersionHybridSelect';
import { CsvUtil } from 'utils/csv-util';
import { orange } from '@ant-design/colors';
import { VersionUtil } from 'utils/version-util';
import { ze } from 'utils/zhEn';
import SingleOrRangeDatePicker from 'components/exception/ProductSummaryPage/SingleOrRangeDatePicker';
import { useSelector } from 'react-redux';
import { selectCurrentAppDatePickerAvailableMomentRange } from 'utils/selectors/selectors';
import { ExceptionCategoryUtil } from 'utils/exception-category';
import TrendIcon from 'svg/v2/newcs_dashboard_overview_crashtrend_icon.svg';
import { UpdateTimeTag } from './UpdateTimeTag';

const ALL_VERSION = '-1';

const RankingListOptions = ({
  appId, pid, style, handleChangeRankSearchParams, searchParams, selectOptions,
  isRegionalDashboard, changeTag, setSortOption, bigDataResultDailyUpdateMillis,
}) => {
  const { t } = useTranslation();

  const [
    datePickerMinAvailableMoment,
    datePickerMaxAvailableMoment,
  ] = useSelector(selectCurrentAppDatePickerAvailableMomentRange);

  const { rankVersion, pageType } = selectOptions.toJS();
  const startDate3 = searchParams.get('startDate3');
  const rankMaxDate = searchParams.get('rankMaxDate');
  const subModuleId = searchParams.get('subModuleId') || '-1';
  const minMoment = startDate3 ? moment(startDate3) : moment();
  const maxMoment = rankMaxDate ? moment(rankMaxDate) : minMoment;
  const isMultipleDates = !minMoment.isSame(maxMoment);

  const { versionTree } = rankVersion;
  const versionListValue = searchParams.get('rankVersion') !== ALL_VERSION
    ? CsvUtil.singleLineParse(searchParams.get('rankVersion'))
    : [];
  const isMultipleVersions = VersionUtil.isMultipleVersions(versionListValue);

  const selectedCountryList = CountryUtil.queryStringToList(searchParams.get('rankCountry'));
  const [currentTab, setCurrentTab] = useState('RANKINGLIST.userNumAffect');
  const pageTypeStr = {
    崩溃: 'EXCP_OVERVIEW.userCrashRate',
    ANR: 'EXCP_OVERVIEW.userANRRate',
    错误: 'EXCP_OVERVIEW.userErrorRate',
  };

  useEffect(() => {
    // tab切换的逻辑改为了统一控制，按照之前的逻辑，切换时三者统一切换
    if (appId && pid) {
      ['system', 'model', 'app'].forEach((key) => {
        changeTag(`${key}${currentTab.includes('Connect') ? 'Access' : 'Rate'}`);
      });
      setSortOption(currentTab);
    }
  }, [currentTab, appId, pid]);

  if (subModuleId !== '-1') {
    return <CardHead
      title={t('RANKINGLISTOPTION.rankWithType', { type: ExceptionCategoryUtil.toI18n(pageType, pid) })}
      hideDivider={true}
    >
      <div>{ ze('暂不支持子场景下的查询', 'Currently not available when sub module ID is set.') }</div>
    </CardHead>;
  }

  return <CardHead
      title={t('RANKINGLISTOPTION.rankWithType', { type: ExceptionCategoryUtil.toI18n(pageType, pid) })}
      hideDivider={false}
      svgIcon={<TrendIcon />}
      iconExtraStyle={{ height: '16px' }}
    >
    <div style={{ flexGrow: '1', display: 'flex', flexDirection: 'column' }}>
    </div>
    <Tab
      tabs={['RANKINGLIST.userNumAffect', pageTypeStr[pageType], 'RANKINGLIST.userNumConnect']}
      currentTab={currentTab}
      tabType='trend'
      style={style}
      changeTab={tag => setCurrentTab(tag)}
    />
    <UpdateTimeTag updateTime={bigDataResultDailyUpdateMillis} />
  </CardHead>;
};

RankingListOptions.propTypes = {
  style: PropTypes.object,
  handleChangeRankSearchParams: PropTypes.func,
  searchParams: PropTypes.object,
  selectOptions: PropTypes.object,
  isRegionalDashboard: PropTypes.bool, // 是否是国家维度的大盘统计
  changeTag: PropTypes.func,
  setSortOption: PropTypes.func,
};

export default RankingListOptions;
