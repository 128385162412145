import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { CS_STYLES } from 'utils/constants/style-constants';

const LabeledField = ({
  label,
  style,
  labelStyle,
  children,
}) => {
  return <div style={style ?? {}}>
    <div style={labelStyle ?? { fontSize: '12px', color: CS_STYLES.SUB_TEXT_COLOR, padding: '0 0 4px 8px'}}>{label}</div>
    <div>{children}</div>
  </div>;
};

LabeledField.propTypes = {
  label: PropTypes.string,
}

export default LabeledField;
