import UserDataRest from 'utils/UserDataRest';
import { createAction } from 'redux-actions';
import { getStartEndDateFromStr } from 'utils/StringUtils';

export const updateRetentParams = createAction('UPDATE_RETENT_PARAMS');

export const updateDurationParams = createAction('UPDATE_DURATION_PARAMS');

export const changeDurationTab = createAction('CHANGE_DURATION_CURTAB');

/**
 *  获取留存分析
 */
export function getRetent() {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    const {
      version, channel, dateScope, date,
    } = getState().userdata.get('retentParams').toJS();
    const dateObj = getStartEndDateFromStr(date.date, date.startDate, date.endDate);
    return dispatch({
      rest: UserDataRest.getRetent.get,
      data: {
        appId,
        pid,
        version,
        dateScope,
        startDate: dateObj.startDate,
        endDate: dateObj.endDate,
        channel,
      },
    });
  };
}

/**
 *  获取启动次数分布
 */
export function getStartupCountTrend() {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    const { version, channel, date } = getState().userdata.get('durationParams').toJS();
    const dateObj = getStartEndDateFromStr(date.date, date.startDate, date.endDate);
    return dispatch({
      rest: UserDataRest.getStartupCountTrend.get,
      data: {
        appId,
        pid,
        version,
        channel,
        date: dateObj.startDate,
      },
    });
  };
}

/**
 *  获取次使用时长
 */
export function getUseTimeTrend() {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    const { version, channel, date } = getState().userdata.get('durationParams').toJS();
    const dateObj = getStartEndDateFromStr(date.date, date.startDate, date.endDate);
    return dispatch({
      rest: UserDataRest.getUseTimeTrend.get,
      data: {
        appId,
        pid,
        version,
        channel,
        date: dateObj.startDate,
      },
    });
  };
}

/**
 *  获取日使用时长
 */
export function getDayUseTimeTrend() {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    const { version, channel, date } = getState().userdata.get('durationParams').toJS();
    const dateObj = getStartEndDateFromStr(date.date, date.startDate, date.endDate);
    return dispatch({
      rest: UserDataRest.getDayUseTimeTrend.get,
      data: {
        appId,
        pid,
        version,
        channel,
        date: dateObj.startDate,
      },
    });
  };
}
