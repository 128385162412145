import React from 'react';

export const notifyStyle = {
  Containers: {
    DefaultStyle: {
      textAlign: 'right',
    },
    tr: {
      top: '86px',
      right: '6px',
    },
  },
  MessageWrapper: {
    DefaultStyle: {
      margin: 0,
      padding: 0,
      maxWidth: '300px',
      textAlign: 'left',
      wordBreak: 'break-word',
    },
  },
  NotificationItem: {
    DefaultStyle: {
      fontSize: '13px',
      lineHeight: '16px',
      display: 'inline-block',
      marginTop: 0,
      width: 'auto',
      borderRadius: '4px',
      position: 'relative',
      paddingRight: '12px',
    },
    success: {
      backgroundColor: '#F8FFF0',
      border: '1px solid #9ED161',
      color: '#71B031',
    },
    info: {
      background: '#E7F7FE',
      border: '1px solid #7AC0F8',
      color: '#3498DB',
    },
    warning: {
      /* Rectangle 785: */
      background: '#FEF9F3',
      border: '1px solid #F3C159',
      color: '#BA6E0E',
    },
    error: {
      background: '#FFF3F3',
      border: '1px solid #F47268',
      color: '#F44336',
    },
  },
  Title: {
    DefaultStyle: {
      textAlign: 'left',
      marginLeft: '26px',
      marginRight: '16px',
      fontSize: '14px',
      lineHeight: '14px',
    },
  },
  Dismiss: {
    DefaultStyle: {
      color: '#b2b2b2',
      background: 'none',
      fontWeight: 'normal',
      fontSize: '20px',
      top: '10px',
    },
  },
};

/**
 * 通知通过事件机制来实现, 通过引入EventEmitter, trigger 事件'notify.show'来触发
 *
 * 参考文档: https://github.com/igorprado/react-notification-system
 *          https://github.com/Olical/EventEmitter/blob/master/docs/guide.md
 *
 * @example import * as EventEmitter from 'helpers/EventEmitterHelper';
 *
 *          EventEmitter.trigger('notify.show', {
 *            title,        {String}    标题
 *            message,      {String}    内容
 *            level,        {String}    success, error, warning, info四选一
 *            type,         {String}    Alias to level
 *            position,     {String}    tr/tl/tc/br/bl/bc 其中之一
 *            autoDismiss   {Number}    多少秒后自动关闭, 设为0则不自动关闭
 *            dismissible   {Boolean}   是否展示xx
 *            action        {Object}    给提示增加一个按钮, 可以设置特殊回调,具体参见: https://github.com/igorprado/react-notification-system#action
 *            onAdd         {Function}  添加时执行的回调
 *            onRemove      {Function}  关闭后执行回调
 *          })
 *
 */
export function showNotifyHandler(style, options, callback) {
  if (options.type) options.level = options.type;
  const { message } = options;

  let iconClass = `${style.notifyIcon} `;
  if (options.level && style[options.level]) {
    iconClass += style[options.level];
  } else {
    iconClass += style.success;
  }

  if (typeof options.duration !== 'undefined') options.autoDismiss = options.duration;

  options.autoDismiss = typeof options.autoDismiss !== 'undefined' ? options.autoDismiss : 4;

  options.message = (
    <div className={style.itemWrap}>
      <i className={iconClass} />
      <div className={style.messageWrap}>
        {message}
      </div>
    </div>
  );

  callback(options);
}
