import { MEMBER_TYPE_KEY } from 'utils/constants';
import i18n from 'i18n.js';

export const UserTypeEnum = Object.freeze({
  ADMIN: 1, // 项目管理员
  MEMBER: 2, // 开发人员（或运营人员）
  STATS_VIEWER: 4,  // 受限查看人员
  /** 数据合规受限管理员 */
  COMPLIANCE_ADMIN: 11,
  /** 数据合规受限成员 */
  COMPLIANCE_MEMBER: 12,

  hasAdminPermission(type) {
    return Number(type) === this.ADMIN || Number(type) === this.COMPLIANCE_ADMIN;
  },
});

// 用户类型列表
export const UserTypeList = [
  {
    label: i18n.t('MEMBERMANAGE.admin'),
    value: MEMBER_TYPE_KEY.admin,
    type: UserTypeEnum.ADMIN,
    isOperator: 0,
  },
  {
    label: i18n.t('MEMBERMANAGE.complianceAdmin'),
    value: MEMBER_TYPE_KEY.complianceAdmin,
    type: UserTypeEnum.COMPLIANCE_ADMIN,
    isOperator: 0,
    extraDescription: i18n.t('MEMBERMANAGE.complianceDescription'),
  },
  {
    label: i18n.t('MEMBERMANAGE.normal'),
    value: MEMBER_TYPE_KEY.normal,
    type: UserTypeEnum.MEMBER,
    isOperator: 0,
  },
  {
    label: i18n.t('MEMBERMANAGE.complianceMember'),
    value: MEMBER_TYPE_KEY.complianceMember,
    type: UserTypeEnum.COMPLIANCE_MEMBER,
    isOperator: 0,
    extraDescription: i18n.t('MEMBERMANAGE.complianceDescription'),
  },
  {
    label: i18n.t('MEMBERMANAGE.operator'),
    value: MEMBER_TYPE_KEY.operator,
    type: UserTypeEnum.MEMBER,
    isOperator: 1,
  },
  {
    label: i18n.t('MEMBERMANAGE.statsViewer'),
    value: MEMBER_TYPE_KEY.statsViewer,
    type: UserTypeEnum.STATS_VIEWER,
    isOperator: 0,
  },
];