export const RolePermission = Object.freeze({
  CREATE: 'CREATE',
  BETA_VIEW: 'BETA_VIEW',
  BETA_EDIT: 'BETA_EDIT',
  VIEW: 'VIEW',
  EDIT: 'EDIT',
  CRASH_VIEW: 'CRASH_VIEW',
  CRASH_EDIT: 'CRASH_EDIT',
  VIEW_DETAIL: 'VIEW_DETAIL',
  VIEW_PERSONAL_DATA: 'VIEW_PERSONAL_DATA',
  VIEW_DASHBOARD: 'VIEW_DASHBOARD',
  /** （元梦需求）大盘统计，查看场景维度统计结果的权限 */
  VIEW_DASHBOARD_USER_SCENE_TAG: 'VIEW_DASHBOARD_USER_SCENE_TAG',
  VIEW_APP_GENERAL_CONFIG: 'VIEW_APP_GENERAL_CONFIG',

  /** app group管理员特有的权限 */
  APP_GROUP_CREATE: 'APP_GROUP_CREATE',
});
