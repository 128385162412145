import React from 'react';
import { Tabs } from 'antd';
import scss from 'components/antd-extension/CsTabs.scss'
import classNames from 'classnames';

/** 包装了新版2.0样式的antd Tabs */
const CsTabs = ({
  bigSize = false, // false代表设计稿里面的Tab类型B，true代表TAB类型C
  ...props
}) => {
  return <Tabs
    className={classNames(scss.csTabs, { [scss.bigSize]: bigSize }, {[scss.csTabsHiddenOperations]: !!!(props.tabBarExtraContent)})}
    {...props}
  />;
};

export default CsTabs;
