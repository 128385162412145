import React from 'react';
import PropTypes from 'prop-types';
import { ErrorBox } from 'components/commons';
import _style from './style.scss';
import { Translation } from 'react-i18next';
import SendErrorBox from "components/exception/BaseProblemPage/SendErrorBox/SendErrorBox";
import { isGameConsole } from 'utils/platform';
import IconEmpty from 'images/v2/newcs_dashboard_empty_icon.png';

const IssueList = (props) => {
  const { issueList, issueListStatusCode, refreshFunc, renderIssue, style, exceptionType, searchOptions, pid } = props;
  const versionOptions = searchOptions.toJS().version.options;
  const hiddenSendErrorBox = issueList.size > 0
    || (versionOptions && versionOptions.filter(item => item.value !== 'all').length > 0)
    || isGameConsole(pid);
  return (
    <Translation>
      {
        (t) => parseInt(issueListStatusCode) === 0 ? (
          <div className={style.issue_list}>
            { hiddenSendErrorBox ?
              (
                issueList.size ? issueList.map((issue) => renderIssue(issue)) :
                  <div className={style.empty_tip}>
                    <img src={IconEmpty} />
                    {t("BASEPROBLEM.noData")}
                  </div>
              )
              : <SendErrorBox exceptionType={exceptionType} refreshFunc={refreshFunc}/>}
          </div>
        ) : (
          <ErrorBox style={_style} refreshFunc={refreshFunc} />
        )
      }
    </Translation>
  );
};

IssueList.propTypes = {
  issueList: PropTypes.object.isRequired,
  renderIssue: PropTypes.func.isRequired,
  issueListStatusCode: PropTypes.number.isRequired,
  style: PropTypes.object,
  refreshFunc: PropTypes.func,
  exceptionType: PropTypes.string,
  searchOptions: PropTypes.object,
  pid: PropTypes.number,
};

export default IssueList;
