import RestHelper from './RestHelper';
export default RestHelper.publish({
  solution: {
    info: {
      method: 'get',
      uri: '/solutionInfoV2/apps/{appId}/pid/{pid}',
    },
    caseApprove: {
      method: 'post',
      uri: '/caseApprove/apps/{appId}/pid/{pid}',
    },
    caseSolution: {
      method: 'post',
      uri: '/caseSolution/apps/{appId}/pid/{pid}',
    },
  },
});
