import 'isomorphic-fetch';
import partial from 'lodash/function/partial';
import isFunction from 'lodash/lang/isFunction';
import { v4 as uuidV4 } from 'uuid';
import cookie from './cookie';
import { Modal, notification } from 'antd';
import i18n from 'i18n.js';
import { ze } from 'utils/zhEn';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { ApiErrorCode } from 'utils/api-error-code';

const APP_JSON_CONTENT_TYPE = 'application/json;charset=utf-8';
const REG_URL_PARAM = /\{(\w+)\}/ig;

const PHP_BASE_HTTP = `${location.protocol}//${location.host}`;

const NODE_BASE_HTTP = `${location.protocol}//${location.host}/${__NODEROOTPATH__}`;
// __ISNODESUPPORT__  // 当前是否支持node接口服务的

// 根据php打到cookie的skey值生成一个token,然后把这个值塞到请求头; 多portal情况下，node这边直接从cookie中的字段读取token，再添加到头部。
function getToken(tokenType) {
  // 根据tokenType切换返回的token信息。
  if (tokenType === 'node') return cookie.get('csrfToken');
  // php的token处理。
  const skey = cookie.get('token-skey');
  let hash = 5381;
  if (skey) {
    for (let i = 0, len = skey.length; i < len; ++i) {
      hash += (hash << 5 & 0x7fffffff) + skey.charAt(i).charCodeAt();
    }
    return hash & 0x7fffffff;
  }
}

// 通过fetchUserInfo接口区分是未登录还是无权限
const handle401 = (() => {
  let isFetchingUserInfo = false;
  return async (reqUrl, errorCode) => {
    if (isFetchingUserInfo) {  // 防止多个接口重复触发401判断
      return;
    }

    isFetchingUserInfo = true;
    try {
      const { json, response } = await post('/api/user/getUserInfo');
      if (response.status === 401 || !json.ret.newUserId) {
        Modal.info({
          title: i18n.t('api.notice401'),
          onOk() {
            window.location.href = '/welcome/login';
          },
        });
      } else {
        notification.error({
          message: i18n.t('api.notice403'),
          description: `status: 401\n\nURL: ${reqUrl}\n\nerrorCode: ${errorCode || '-'}`,
          style: { whiteSpace: 'pre-wrap', wordBreak: 'break-all' },
        });
      }
    } finally {
      isFetchingUserInfo = false;
    }
  }
})();

function makeNotificationErrorTitle(httpStatus, errorCode) {
  httpStatus = Number(httpStatus);
  if (httpStatus === 401) {
    return i18n.t('api.notice401');
  } else if (httpStatus === 403) {
    return i18n.t('api.notice403');
  } else if (httpStatus === 400 && errorCode === ApiErrorCode.IllegalArgument) {
    return i18n.t('api.noticeIllegalArgument');
  }

  return i18n.t('api.服务接口出现错误');
}

// TODO: 待改进，如何写好一个配置项。
function ajaxAsync(method, uri, data = {}, options) {
  console.log(options, 'options');

  const { silentFailure } = (options || {}); // 当接口请求失败的时候，是否屏蔽notification提示
  let { silentErrorCodes } = (options || {}); // // 当接口请求失败且errorCode在指定的列表里，屏蔽notification提示
  if (!Array.isArray(silentErrorCodes)) {
    silentErrorCodes = [];
  }

  const isMultipartFormData = data instanceof FormData;

  const fetchOptions = {
    method,
    credentials: 'include',
    headers: {
      ...(!isMultipartFormData ? { 'Content-Type': APP_JSON_CONTENT_TYPE } : {}),
      Accept: APP_JSON_CONTENT_TYPE,
      'X-token': getToken('php'),
      'x-csrf-token': getToken('node'),
    },
  };
  if (method !== 'GET' && method !== 'DELETE') {
    fetchOptions.body = !isMultipartFormData
      ? JSON.stringify(data)
      : data;
  }

  const reqUrl = getReqUrl(method, uri, data, options);
  return fetch(reqUrl, fetchOptions).then((response) => {
    const textResp = response.text();

    return new Promise((resolve) => {
      textResp.then((text) => {
        let json = undefined;
        try {
          json = JSON.parse(text);
        } catch(e) {
          /** ignore */
        }
        resolve({ json, text, response });
      }).catch(() => {
        resolve({ response });
      });
    });
  }).then(({ json, text, response }) => {
    const httpStatus = response.status;
    const statusCode = (json || {}).status || (json || {}).ret;
    const status = httpStatus >= 400 ? httpStatus : statusCode;
    const errorCode = (json || {}).errorCode;
    const message = (json || {}).message;
    // TODO: api.js那边也有很多状态码的判断，后面要统一一下
    if (!silentFailure && !silentErrorCodes.includes(errorCode)) {
      // 501是门神拦截，腾讯云WAF拦截看body内容里有没有waf字样
      if (status === 501
        || (status >= 400 && (text || '').toLowerCase().includes('waf') )) {
        notification.error({
          message: ze('请求内容因包含不安全信息被防火墙拦截，请联系客服。', 'Request blocked by WAF.'),
          description: `status: ${status}\n\nURL: ${reqUrl}\n\nerrorCode: ${errorCode || '-'}`,
          style: { whiteSpace: 'pre-wrap', wordBreak: 'break-all' },
          duration: 0,
        });
        console.error(`URL blocked by WAF: ${reqUrl}`);
      } else if (status === 401) {
        handle401(reqUrl, errorCode);
      } else if (status >= 400 || errorCode) {
        notification.error({
          message: makeNotificationErrorTitle(status, errorCode),
          description: `status: ${status}\n\nURL: ${reqUrl}\n\nerrorCode: ${errorCode || '-'}\n\nmessage: ${message || '-'}`,
          style: { whiteSpace: 'pre-wrap', wordBreak: 'break-all' },
        });
      } else if (!json) {
        notification.error({
          message: 'Invalid response format, must response JSON.',
          description: `status: ${status}\n\nURL: ${reqUrl}`,
          style: { whiteSpace: 'pre-wrap', wordBreak: 'break-all' },
        });
      }
    }
    const isReject = status >= 400 || !!errorCode;

    return isReject
      ? Promise.reject({ json, response, errorCode, message })
      : Promise.resolve({ json, response, errorCode, message });
  });
}

function getReqUrl(method, uri, data, options) {
  console.log(options, 'options的输出');
  let url = uri;
  url = url.replace(REG_URL_PARAM, (...args) => {
    const key = args && !!args[1] && args[1];
    const ret = key ? data[key] : args[0];
    delete data[key];
    return ret;
  });

  const DUMMY_HOST = 'http://localhost'; // URL的构造函数需要一个host，所以填个假的host进去
  const querySearchParams = new URLSearchParams(new URL(uri, DUMMY_HOST).search);
  let queryObj = [...querySearchParams].reduce((o, i) => ({ ...o, [i[0]]: i[1] }), {});

  if (method === 'GET' || method === 'DELETE') {
    queryObj = Object.assign(queryObj, data);
  }
  if (options && options.query) {
    queryObj = Object.assign(queryObj, options.query);
  }

  // 如果是截图服务访问的页面，全部请求都带上screenshotSecret和t
  const currentPageSearchParams = new URLSearchParams(window.location.search);
  const isScreenshotVisit = !!currentPageSearchParams.get('screenshotSecret');
  if (isScreenshotVisit) {
    queryObj = {
      ...queryObj,
      screenshotSecret: currentPageSearchParams.get('screenshotSecret'),
      t: currentPageSearchParams.get('t'),
    };
  }

  queryObj = Object.assign(queryObj, { fsn: uuidV4() });
  url = `${url.split('?')[0]}?${new URLSearchParams(queryObj)}`;
  return url;
}

function get(uri, data, options) {
  return ajaxAsync('GET', uri, data, options);
}

function post(uri, data, options) {
  return ajaxAsync('POST', uri, data, options);
}

async function mustPost(uri, data, options) {
  /**
   * @type {{response: *, json: *} | {response: *}}
   */
  const rsp = await post(uri, data, options);
  const { response, json } = rsp;
  const httpStatus = response.status;
  if (httpStatus >= 500) {
    throw {
      description: `HTTP Status ${httpStatus}`,
      status: httpStatus,
    };
  }
  if (!json) {
    throw {
      description: `JSON is null`,
      status: httpStatus,
    };
  }
  const status = httpStatus >= 400 ? httpStatus : (json.status || json.ret);
  const errorCode = json.errorCode;
  if (status >= 400 || errorCode) {
    throw {
      description: `bad request`,
      status,
      errorCode,
    };
  }
  return rsp;
}

function postEventStream(uri, data, options) {
  const { statusCodeToCallback } = options || {};

  return fetchEventSource(uri, {
    ...options,
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'X-token': getToken('php'),
      'x-csrf-token': getToken('node'),
    },
    credentials: 'include',
    openWhenHidden: true,
    onopen(res) {
      const status = res.status;
      const contentType = res.headers.get('content-type');
      if (!(contentType || '').toLowerCase().startsWith('text/event-stream')) {
        const message = `Expected content-type to be text/event-stream, Actual: ${contentType}`;
        notification.error({
          message,
          description: `URL: ${uri}`,
        });
        throw new Error(message);
      }
      if (status >= 400) {
        const message = {
          401: i18n.t('api.notice401'),
          403: i18n.t('api.notice403'),
        }[status] || i18n.t('api.服务接口出现错误');
        const callback = (statusCodeToCallback || {})[status];
        if (typeof callback === 'function') {
          callback(message);
        } else {
          notification.error({
            message,
            description: `status: ${status}\n\nURL: ${uri}`,
            style: {whiteSpace: 'pre-wrap', wordBreak: 'break-all'},
          });
        }
        throw new Error(message);
      }
    },
    onerror(err) {
      if (typeof options.onerror === 'function') {
        options.onerror(err);
      }
      throw err;
    },
  });
}

function put(uri, data, options) {
  return ajaxAsync('PUT', uri, data, options);
}

function remove(uri, data, options) {
  return ajaxAsync('DELETE', uri, data, options);
}

function handleArrayConfig(arrayConfig) {
  const [method, uri] = arrayConfig;
  return partialHttp(method.toLowerCase(), uri);
}

function $param(json) {
  return Object.keys(json).reduce((sum, key) => {
    return `${(sum.length ? `${sum}&` : sum)}${key}=${json[key]}`;
  }, '');
}

function partialHttp(method, uri) {
  switch (method) {
    case 'get':
      return partial(get, uri);
    case 'post':
      return partial(post, uri);
    case 'put':
      return partial(put, uri);
    case 'delete':
      return partial(remove, uri);
    default:
      return '';
  }
}

function handleObjectConfig(entity, action, actionConfig) {
  const {
    method, uri, schema, types,
  } = actionConfig;
  const httpFn = partialHttp(method, uri);
  if (schema) {
    httpFn.schema = schema;
  }
  httpFn.types = types || generateServiceTypes(entity, action);
  return httpFn;
}

function generateServiceTypes(entity, action) {
  let typePrefix = `${entity.toUpperCase()}_${action.toUpperCase()}`;
  const actionArr = action.split(/(?=[A-Z])/);
  if (actionArr.length > 1) {
    typePrefix = actionArr.map((val) => val.toUpperCase()).join('_');
  }
  return [`${typePrefix}_REQ`, `${typePrefix}_SUCC`, `${typePrefix}_FAIL`,
    `${typePrefix}_CREATED`, `${typePrefix}_ACCEPTED`];
}

function simplePathJoin(...args) {
  if (args.length === 0) {
    return '';
  }
  const first = args[0] === '/' ? '/' : '';
  const last = args[args.length - 1] === '/' ? '/' : '';
  return first + args.reduce((pathArr, path) => pathArr.concat([
    path.replace(/^\//, '').replace(/\/$/, ''),
  ]), []).join('/') + last;
}
export default {
  get,
  post,
  mustPost,
  postEventStream,
  put,
  remove,
  PHP_BASE_HTTP,
  NODE_BASE_HTTP,

  publish(serviceConfig) {
    const publish = {};

    Object.keys(serviceConfig).forEach((entity) => {
      const exportsTemp = {};
      const services = serviceConfig[entity];
      const uri = serviceConfig.uri || '';

      Object.keys(services).forEach((action) => {
        const actionConfig = services[action];
        let destFn;
        if (['get', 'post', 'put', 'remove'].indexOf(action) !== -1) {
          if (isFunction(actionConfig)) {
            destFn = actionConfig;
          } else {
            actionConfig.method = action;
            !!actionConfig.uri || (actionConfig.uri = uri);
            destFn = handleObjectConfig(entity, action, actionConfig);
          }
        } else {
          switch (typeof actionConfig) {
            case 'function':
              destFn = actionConfig;
              break;
            case 'array':
              destFn = handleArrayConfig(actionConfig);
              break;
            case 'object':
              destFn = handleObjectConfig(entity, action, actionConfig);
              console.log('object', entity, action, actionConfig, destFn, destFn.types);
              break;
            default: break;
          }
        }
        if (destFn && !destFn.types) { // destFn exsi, but without property types
          destFn.types = generateServiceTypes(entity, action);
        }
        exportsTemp[action] = destFn;
        publish[entity] = exportsTemp;
      });
    });
    return publish;
  },
};
