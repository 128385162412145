import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Welcome } from 'components/mail';
import * as userActions from 'reducers/user';
import { push as pushState, replace as replaceState } from 'connected-react-router';
import style from './style.scss';
import { withTranslation } from 'react-i18next'
/**
 * 个人信息页面
 */
@withTranslation()
class MailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'bind',
    };
  }

  render() {
    return (
      <div className="exception_page">
        <div className={style.main_content}>
          <h3>{ this.props.t('ACCOUNT.mail_editTitle') }</h3>
          <div className={style.user_center_box}>
            <div className={style.status_box}>
              <div className={style.title}>
                <h4>{ this.props.t('ACCOUNT.mail_editTitle2') }</h4>
              </div>

              <Welcome actions={this.props.actions} currentUser={this.props.currentUser} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MailPage.propTypes = {
  currentUser: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

// 设置props
export default connect((state, router) => {
  return {
    router,
    currentUser: state.user.get('current').toJS(),
  };
}, (dispatch) => ({
  actions: bindActionCreators({
    ...userActions,
    replaceState,
    pushState,
  }, dispatch),
  dispatch,
}))(MailPage);
