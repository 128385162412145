import React from 'react';
import PropTypes from 'prop-types';
import { Translation } from 'react-i18next';
import style from './style.scss';
import { Radio, Tooltip } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { ze } from 'utils/zhEn';
import WrappedTipsIcon from 'components/antd-extension/WrappedTipsIcon.jsx';

/**
 * 选择按小时和累计数据
 */
const DataTypeSelect = ({
  handleDataTypeChange,
  searchParams,
  reduxState,
}) => {
  const bigDataRealtimeUpdateMillis = reduxState.summary.get('bigDataRealtimeUpdateMillis');

  return (
    <Translation>
      {
        (t, {i18n}) =>
        <div className={style.data_type_box}>
          <span className={style.data_type} style={{ paddingRight: '4px' }}>{ t("DATATYPESELECT.dimension") }</span>
          <Tooltip arrow={true}
            placement="topLeft"
            align={{ offset: [-17, 0] }}
            overlayStyle={{ maxWidth: '30vw'}}
            title={(
              <div>
                <p style={{ marginBottom: 2, fontSize: '12px' }}>{ze(
                  '小时级崩溃率和累计崩溃率之间的大小没有必然联系，原因如下：',
                  'The hourly crash rate and daily crash rate are not necessarily related for the following reason:'
                )}</p>
                <p style={{ marginBottom: 2, fontSize: '12px' }}>{ ze('崩溃率 = 崩溃设备去重 / 活跃设备去重', 'Rate calculation = count(affected devices) / count(active devices). ') }</p>
                <p style={{ marginBottom: 2, fontSize: '12px' }}>{ ze('例如：', 'For example:') }</p>
                <p style={{ marginBottom: 2, fontSize: '12px' }}>{ze(
                  '1、小时崩溃率 < 累计崩溃率。假设总共100个活跃用户， 每小时都在线。 前10个小时每小时崩溃一个设备（不同小时崩溃设备不一样） ，小时级崩溃率为1% ，累计崩溃率为10%。',
                  '1. Hourly crash rate < daily crash rate. Assuming there are a total of 100 active devices, and all of them are active every hour. In the first 10 hours, one device crashes per hour (different devices crash in different hours), so the hourly crash rate is 1% and the daily crash rate is 10%.'
                )}</p>
                <p style={{ marginBottom: 2, fontSize: '12px' }}>{ze(
                  '2、小时崩溃率 > 累计崩溃率。假设总共100个活跃用户， 每小时都10个用户在线。 前10个小时每小时崩溃一个设备（不同小时同一个设备一样） ，小时级崩溃率为10% ，累计崩溃率为1%。',
                  '2. Hourly crash rate > daily crash rate. Assuming there are a total of 100 active devices, and 10 users are active every hour. In the first 10 hours, one device crashes per hour (the same device crashes in different hours), so the hourly crash rate is 10% and the daily crash rate is 1%. '
                )}</p>
                <p style={{ marginBottom: 2, fontSize: '12px' }}>{ze(
                  '在大多数情况，小时级崩溃率会小于累计崩溃率。',
                  'In most cases, the hourly crash rate is smaller than the daily crash rate.'
                )}</p>
              </div>
              )}>
            <WrappedTipsIcon />
          </Tooltip>
          {/* <span style={{ marginRight: '15px' }}>{ ze('：', ':')}</span> */}
          <Radio.Group
            style={{marginLeft: '8px'}}
            size="small"
            defaultValue={searchParams.get('dataType')}
            onChange={(value) => { console.log('change'); handleDataTypeChange(value); }}
          >
            <Radio value='hourly'>{ t("DATATYPESELECT.hour") }</Radio>
            <Radio value='appendly'>{ t("DATATYPESELECT.total") }</Radio>
          </Radio.Group>
        </div>
      }
    </Translation>
  );
};

const mapStateToProps = (state, router) => ({
  reduxState: state,
});

DataTypeSelect.propTypes = {
  handleDataTypeChange: PropTypes.func,
  searchParams: PropTypes.object,
  reduxState: PropTypes.object,
};

export default connect(mapStateToProps)(DataTypeSelect);
