import Immutable, { Map, List, fromJS } from 'immutable';
import { DEFAULT_EXCEPTION_TYPE_LIST_ANDROID, PLATFORM_ID } from 'utils/constants';
import { handleActions } from 'redux-actions';
import i18n from 'i18n.js';
import moment from 'moment';
import { isMobile, isPcOrLinux, isPlaystation } from 'utils/platform';
import { compareBundleId, getIssueDetailUrlPrefix, getUserId, getVersionRangeString } from 'utils/helper';
import { message } from 'antd';
import {
  ANDROID_EXCEPTION_TYPE_LIST_OPTIONS,
  IOS_EXCEPTION_TYPE_LIST_OPTIONS,
  ANR_EXCEPTION_TYPE_LIST_OPTIONS,
  ERROR_EXCEPTION_TYPE_LIST_OPTIONS,
} from 'utils/constants';
import { getNickName, parseSearch } from 'utils/helper';
import {
  LOCATION_CHANGE,
  RESET_SEARCH_PARAMS,
  CHANGE_EXCEPTION_TYPE_OPTIONS,
  GETSOLUTIONINFO_GET_SUCC,
  TAPD_SUBMIT_SUCC,
} from 'store/actions';
import { initialState, initialSearchParams } from './issueInitialState';
import XLSX from 'xlsx';
import {getReportCategoryByExceptionTypeInt} from "utils/constants/exception-type-int";
import { isNotNullish } from 'utils/nullish';
import uniqBy from 'lodash.uniqby';
import { CustomKvUtil } from 'utils/custom-kv';
import { TapdUtil } from "utils/tapd-util";
import { ze } from 'utils/zhEn';
import { CrashAttachmentFileType, CrashAttachmentUtil } from 'utils/crash-attachment-util';
import { CrashUtil } from 'utils/api/crash';
import { ExceptionCategoryUtil } from 'utils/exception-category';
import uniq from 'lodash/array/uniq';
import { DataDetailUtil } from 'components/exception/issue/DataDetail';
import { updateReduxIssueSearchParams } from 'reducers/issue/issueActions';
import { StackUtil } from 'utils/stack';

function handleFetchIssueFail(state) {
  return state.update('issueList', (issueList) => issueList.clear())
    .set('numFound', 0)
    .set('statusCode', -1);
}

function createExcel(data,title){
      const { utils } = XLSX;
      var ws_name = "sheet1";
      const wb= utils.book_new();
      /* make worksheet */
      var ws = utils.aoa_to_sheet(data);
      /* Add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      XLSX.writeFile(wb, title);
    }

export default handleActions({
  ISSUES_LIST_SUCC: (state, action) => {
    if (!action.response) {
      return handleFetchIssueFail(state);
    }
    const { numFound, issueList, queryInaccurateReason } = action.response;
    return state.update('issueList', (domain) => domain.clear().mergeDeep(issueList))
      .set('numFound', numFound)
      .set('queryInaccurateReason', queryInaccurateReason)
      .set('loading', false)
      .set('statusCode', 0);
  },

  ISSUES_DOWNLOAD_SUCC: (state, action) => {
    const { exceptionTypeList, platformId } = action.params;
    let filenamePrefix = '';
    if (exceptionTypeList) {
      const typeList = exceptionTypeList.split(',');
      const queryCategories = uniq(typeList.map(x => ExceptionCategoryUtil.fromExceptionType(x)));
      filenamePrefix = queryCategories.map(x => ExceptionCategoryUtil.toI18n(x, platformId)).join(',');
    }
    const {  issueList } = action.response;
    const title = ze(`${filenamePrefix}问题列表.xlsx`, `${filenamePrefix} Issue List.xlsx`);
    const header = [
      i18n.t('issueCrashFilterKey.issueId'),
      i18n.t('issueCrashFilterKey.errorType'),
      i18n.t('REPORTDETAIL.expMessage'),
      i18n.t('REPORTDETAIL.首行关键堆栈'),
      i18n.t('issueCrashFilterKey.version'),
      i18n.t('EXCP_OVERVIEW.occurNum'),
      i18n.t('EXCP_OVERVIEW.userNum'),
      i18n.t('issueCrashFilterKey.issueTag'),
      i18n.t('issueCrashFilterKey.issueFirstUploadTime'),
      ze('平台链接', 'Web URL'),
      ze('关联缺陷单标题', 'Bound Tapd Issue Titie'),
      ze('关联缺陷单链接', 'Bound Tapd Issue URL'),
    ];
    const body = issueList.map(item=>{
      let tagStr = [];
      if(item.tagList){
        tagStr = item.tagList.map(item=>{
          return item.tagName
        });
      }
      const issueDetailUrlPrefix = getIssueDetailUrlPrefix(getReportCategoryByExceptionTypeInt(item.issueExceptionType));
      const pureVersions = Array.isArray(item.issueVersions)
        ? item.issueVersions.map(x => x.version)
        : [];

        const bugs = item.bugs;
        const tapdBugInfo = bugs && bugs[0];
        const { workspaceId, id: bugId, commercialTapd } = tapdBugInfo || {};

        const bugUrl = TapdUtil.makeBugUrl(tapdBugInfo, action.params.teamType);

        return [
          item.issueId,
          item.exceptionName,
          item.exceptionMessage,
          item.keyStack,
          getVersionRangeString(pureVersions),
          item.count,
          item.imeiCount,
          item.tagInfoList.map((num) => num.tagName).join(","),
          moment(item.firstUploadTime,'YYYY-MM-DD HH:mm:ss SSS').format('YYYY-MM-DD HH:mm:ss'),
          `${window.location.protocol}//${window.location.host}/${issueDetailUrlPrefix}/${action.params.appId}/${item.issueId}?pid=${action.params.platformId}`,
          item.bugs && item.bugs.length > 0 ? item.bugs[0].title : '',
          bugUrl,
        ];
      });
    const downloadedIssues = body.length;
    const data = [header,...body];
    createExcel(data,title);
    if (downloadedIssues < 1000) {
      message.success(ze(`已成功下载 ${downloadedIssues} 条问题数据`, `${downloadedIssues} issue(s) have been downloaded.`));
    } else {
      message.success(ze(`已成功下载 ${downloadedIssues} 条问题数据 （最大支持下载 ${1000} 条）`, `${downloadedIssues} (Limit reached) issue(s) have been downloaded.)`));
    }
    return state
  },

  ISSUES_LIST_FAIL: handleFetchIssueFail,

  CHECK_ISSUE: (state, action) => { // 选中/取消问题列表项
    const {
      issue, checkAll, checked, multi,
    } = action;
    return state.update('issueList', (issueList) => issueList.map((oneIssue) => {
      if (checkAll) {
        return oneIssue.set('checked', checked);
      }
      const targetIssue = multi ? oneIssue : oneIssue.set('checked', false);
      return targetIssue.get('issueId') === issue.get('issueId') ? targetIssue.set('checked', checked) : targetIssue;
    }));
  },
  // 首次获取crash的详细信息；这里有约束条件，retrashCrashDetail和crashDetail都已经是设定好的。
  GET_CRASH_DETAIL_SUCC: (state, action) => {
    const { params, response: { retraceCrashDetail: crashDetail, callStack: originDetail } } = action;
    return state.update('issueList', (issueList) => issueList.map((issue) => {
      if (issue.get('issueId') === params.issueId) {
        return issue.merge({
          detailInfo: action.response,
          crashDetail: crashDetail ? crashDetail.split(/\n|\r/g).filter((item) => !!item) : [],
          originDetail: originDetail ? originDetail.split(/\n|\r/g).filter((item) => !!item) : [],
          showDetail: true,
          offsetTop: params.offsetTop,
        });
      } else {
        return issue.get('showDetail') ? issue.set('showDetail', false) : issue;
      }
    }));
  },


  GET_ISSUE_INFO_SUCC: (state, action) => handleGetIssueInfoSuccess(state, action),

  GET_ISSUE_INFO_FROM_SHARE_LINK_SUCC: (state, action) => handleGetIssueInfoSuccess(state, action),

  SET_ISSUE_LIST: (state, action) => {
    return state.update('issueList', (x) => x.clear().mergeDeep(action.issueList));
  },

  GET_CRASH_DOC_FROM_SHARE_LINK_SUCC: (state, action) => handleGetCrashDoc(state, action),

  saveCrashDocToReduxState: (state, action) => {
    const newState = handleGetCrashDoc(state, action);
    const { crashMap } = action.response || {};
    return saveResponeToCurrent(newState, 'detailInfo', CrashUtil.makeRefinedCrashMap(crashMap));
  },

  resetCrashRelatedInfoInCurrentIssue: (state, action) => {
    const patch = {
      attachList: null,
      attachCustomKvList: null,
      attachAllKvList: null,
      appDetailCrashInfo: null,
      crashDoc: undefined,
      detailInfo: undefined,
      extraFile: null,
      otherThread: null,
      userLogs: null,
      sysLogs: null,
      isShowingMinidumpStack: false,
      isShowingRetracedAnrTrace: false,
      pcModuleInfoList: null,
      pcAllModuleInfoLines: null,
      pcRegisterInfoFetching: false,
      pcRegisterInfoLines: null,
      minidumpDetailStackMemoryInfoList: undefined,
      nonMinidumpDetailStackMemoryInfoList: undefined,
      pcStackMemoryLines: undefined,
      retracedAnrTrace: null,
      lastFetchedPcSoInfoCrashId: undefined,
    };
    return state.update('current', current => current.merge(patch));
  },

  updateReduxIssueState: (state, action) => {
    const { data } = action;
    return state.merge(data);
  },

  GET_CRASH_ATTACHMENT_SUCC: (state, action) => handleGetCrashAttachment(state, action),

  GET_CRASH_ATTACHMENT_FROM_SHARE_LINK_SUCC: (state, action) => handleGetCrashAttachment(state, action),

  // 新添加的重还原的解析接口请求
  RELOAD_CRASH_ATTCHMENT_SUCC: (state, action) => test(state, action),

  /**
   * 切换显示问题详情
   */
  toggle_crash_detail: (state, action) => {
    const payloadIssue = action.payload;
    return state.update('issueList', (issueList) => issueList.map((issue) => {
      if (issue.get('issueId') === payloadIssue.get('issueId')) {
        return issue.set('showDetail', !issue.get('showDetail'));
      }
      return issue.get('showDetail') ? issue.delete('showDetail') : issue;
    }));
  },

  ISSUEOPTIONS_GET_SUCC: (state, action) => handleGetIssueOptions(state, action),

  GETSELECTOR_GET_SUCC: (state, action) => handleGetSelector(state, action),

  [LOCATION_CHANGE]: (state, action) => {
    const { location } = action.payload;
    const { pathname } = location;
    const query = parseSearch(location);
    if (isMatchIssueRoute(pathname)) {
      return state.update('searchParams', (searchParams) => {
        return Object.keys(query).length > 1 ? searchParams.merge(query) : initialSearchParams;
      })
        .updateIn(['searchParams', 'exceptionTypeList'], () => query.exceptionTypeList || '');
    }
    return state;
  },

  updateReduxIssueSearchParams: (state, action) => {
    const query = action.payload;
    return state.update('searchParams', (searchParams) => {
      return searchParams.merge(query);
    })
      .updateIn(['searchParams', 'exceptionTypeList'], () => query.exceptionTypeList || '');
  },

  CHANGE_TAB: (state, action) => state.update('current', (issue) => issue.set(`tab${action.tab}`, action.value)),

  INITIAL_TAB: (state) => state.update('current', (issue) => issue.set('tab1', '').set('tab2', '')),

  NOTELIST_GET_SUCC: (state, action) => state.update('current', (issue) => issue.set('noteList', new List(action.response))),

  NOTELIST_ADD_REQ: (state) => state.update('current', (issue) => issue.set('noteDisabled', true)),

  NOTELIST_ADD_SUCC: (state, action) => state.update('current', (issue) => {
    return issue.update('noteList', (noteList) => noteList.unshift(action.params)).set('noteDisabled', false);
  }),

  SHORTURL_GET_SUCC: (state, action) => state.update('current', (issue) => issue.set('shortUrl', action.response)),

  clear_short_url: (state) => state.update('current', (issue) => issue.set('shortUrl', null)),

  CHANGE_LOG: (state, action) => state.update('current', (issue) => issue.set('logType', action.logType)),

  CHANGE_LEVEL: (state, action) => state.update('current', (issue) => issue.set('logLevel', action.level)),

  CLICK_CHECKBOX: (state, action) => state.update('current', (issue) => issue.set('ideChecked', action.checked)),

  INIT_LOG_STATUS: (state) => state.update('current', (issue) => issue.set('ideChecked', true)
    .set('logLevel', 'verbose')
    .set('logType', 'sysLog')
    .set('logKey', '')
    .set('stackType', 'retrace')
    .set('isShowOtherThread', false)),

  CHANGE_KEY: (state, action) => state.update('current', (issue) => issue.set('logKey', action.value)),

  TAG_ADD_SUCC: (state, action) => handleAddOrDelTagSucc(state, action),
  TAG_DEL_SUCC: (state, action) => handleAddOrDelTagSucc(state, action),

  STACK_TYPE_CHANGE: (state, action) => saveResponeToCurrent(state, 'stackType', action.stackType),

  IS_SHOW_OTHER_THREAD: (state, action) => saveResponeToCurrent(state, 'isShowOtherThread', action.isShow),

  CHANGE_STATE_SUCC: changeStateSucc,

  [TAPD_SUBMIT_SUCC]: changeStateSucc,

  CHANGE_ISSUE_TREND_TAG: (state, action) => state.update('current', (issue) => issue.set('issueTrendTag', action.tagName)),

  CHANGE_ISSUE_TREND_VERSION: (state, action) => state.update('current', (issue) => issue.set('issueTrendVersion', action.version)),
  CHANGE_ISSUE_TREND_COUNTRY_LIST: (state, action) => state.update('current', (issue) => issue.set('issueTrendCountryList', action.countryList)),

  /**
   * 重置问题列表搜索条件
   */
  [RESET_SEARCH_PARAMS]: (state, action) => {
    const { pid, exceptionType } = action.payload;
    return changeExceptionOptionsAndTypeList(state.update('searchParams', () => initialSearchParams), pid, exceptionType);
  },

  [CHANGE_EXCEPTION_TYPE_OPTIONS]: (state, action) => {
    const { pid, exceptionType } = action.payload;
    return changeExceptionOptionsAndTypeList(state, pid, exceptionType);
  },

  [GETSOLUTIONINFO_GET_SUCC]: (state, action) => {
    return state.update('current', (issue) => issue.set('solution', action.response.datas));
  },

  QUERY_ISSUE_TREND_START: (state, action) => state.set('issueListTrendLoading', true),

  QUERY_ISSUE_TREND_END: (state, action) => state.set('issueListTrendLoading', false),

  setIssueTrendData: (state, action) => state.update('current', (issue) => issue.set('issueTrendData', action.payload)),

  setIssueTrendAccessDeviceTrendData: (state, action) => state.update('current', (issue) => issue.set('issueTrendAccessDeviceTrendData', action.payload)),

  setIssueIdToDailyTrendList: (state, action) => state.set('issueIdToDailyTrendList', action.payload),

  setIssueIdToHourlyTrendList: (state, action) => state.set('issueIdToHourlyTrendList', action.payload),

  patchIssueIdToDailyTrendListLastFetchMillis: (state, action) => state.update('issueIdToDailyTrendListLastFetchMillis', x => ({ ...x, ...action.payload })),

  patchIssueIdToHourlyTrendListLastFetchMillis: (state, action) => state.update('issueIdToHourlyTrendListLastFetchMillis', x => ({ ...x, ...action.payload })),

  patchCurrentIssueInfo: (state, action) => state.update('current', x => x.merge(action.payload),
  ),

  PARSE_AND_SAVE_PC_MODULE_INFO: (state, action) => {
    const { payload } = action;
    let { text, crashId } = payload;
    text = (text || '').trim();
    const SYMBOL_MODULE_INFO_HEADER = 'Symbol Module infos:';
    const ALL_MODULE_INFO_HEADER = 'All Module infos:';
    const STACK_MEMORY_INFO_HEADER = 'Stack Memory Infos:';
    const DETAIL_STACK_MEMORY_INFO_JSON_HEADER = 'JSON Stack contents:';
    const ALL_HEADERS = [
      SYMBOL_MODULE_INFO_HEADER,
      ALL_MODULE_INFO_HEADER,
      STACK_MEMORY_INFO_HEADER,
      DETAIL_STACK_MEMORY_INFO_JSON_HEADER,
    ];

    // TODO: 适配后台JSON Stack contents的内容后面漏了个换行导致后面JSON parse失败的问题。
    // TODO: 这个需要后台解决了之后，跑两个月数据然后再把这个逻辑去掉。2024-10-28之后可以去掉。
    text = text.replace(STACK_MEMORY_INFO_HEADER, '\n' + STACK_MEMORY_INFO_HEADER);

    let symbolModuleLines = [];
    const allModuleLines = DataDetailUtil.filterSectionLinesByHeaderList(
      text, [ALL_MODULE_INFO_HEADER], ALL_HEADERS, false)
      .slice(1);
    const stackMemoryLines = DataDetailUtil.filterSectionLinesByHeaderList(
      text, [STACK_MEMORY_INFO_HEADER], ALL_HEADERS, false)
      .slice(1);

    if (text.includes(SYMBOL_MODULE_INFO_HEADER)) {
      symbolModuleLines = DataDetailUtil.filterSectionLinesByHeaderList(
        text, [SYMBOL_MODULE_INFO_HEADER], ALL_HEADERS, false)
        .slice(1);
    } else {
      symbolModuleLines = text.split('\n');
    }

    const detailStackMemoryInfoJson = DataDetailUtil.filterSectionLinesByHeaderList(
      text, [DETAIL_STACK_MEMORY_INFO_JSON_HEADER], ALL_HEADERS, false)
      .slice(1)[0];
    let minidumpDetailStackMemoryInfoList = [];
    try {
      if (detailStackMemoryInfoJson) {
        minidumpDetailStackMemoryInfoList = JSON.parse(detailStackMemoryInfoJson.trim());
      }
    } catch (e) {
      console.error(`parse detailStackMemoryInfoJson failed for crashId = ${crashId}`, e);
      message.error('Parse detailStackMemoryInfoJson failed.');
    }

    const pcSymbolModuleInfoList = symbolModuleLines
      .map(x => x.trim())
      .filter(x => x)
      .map(line => {
        const [moduleName, uuid, baseAddressHex = ''] = line.split(/\s/).filter(x => x.trim());
        return { moduleName, uuid, baseAddressHex };
      });
    return state
      .update('current', (issue) => issue.set('lastFetchedPcSoInfoCrashId', crashId))
      .update('current', (issue) => issue.set('pcModuleInfoList', pcSymbolModuleInfoList))
      .update('current', (issue) => issue.set('pcAllModuleInfoLines', allModuleLines))
      .update('current', (issue) => issue.set('minidumpDetailStackMemoryInfoList', minidumpDetailStackMemoryInfoList))
      .update('current', (issue) => issue.set('pcStackMemoryLines', stackMemoryLines));
  },

  PARSE_AND_SAVE_PC_REGISTER_INFO: (state, action) => {
    const { payload } = action;
    const { registerInfoText } = payload;
    const pcRegisterInfoLines = String(registerInfoText || '').split('\n').filter(x => x.trim());
    return state.update('current', (issue) => issue.set('pcRegisterInfoLines', pcRegisterInfoLines));
  },

  PARSE_AND_SAVE_PC_OTHER_THREADS: (state, action) => {
    const { payload } = action;
    const { otherThreadsText } = payload;
    const splitRes = String(otherThreadsText || '').split(/^(Thread.+)$/im);
    const otherThreads = [];
    for (let i = 1; i < splitRes.length; i += 2) {
      const fileName = splitRes[i];
      const content = splitRes[i + 1].trim();
      otherThreads.push({
        fileName,
        content,
        fileType: CrashAttachmentFileType.OTHER_THREAD,
      });
    }

    return state.update('current', (issue) => issue.set('otherThread', otherThreads));
    /* PC其他线程格式样例：
Thread(1)
	ntdll.dll	pc 0x14	NtRemoveIoCompletion	(:0)[amd64:Windows NT:1D272E6EB138642C3C71E6089E65E2431]
	mswsock.dll	pc 0x9b	SockAsyncThread	(:0)[amd64:Windows NT:41A36919692661781D0CD5E84885B7B01]
	kernel32.dll	pc 0x1d	BaseThreadInitThunk	(:0)[amd64:Windows NT:7C4E08F45D72C45361D8C89A0278B6991]
	ntdll.dll	pc 0x28	RtlUserThreadStart	(:0)[amd64:Windows NT:1D272E6EB138642C3C71E6089E65E2431]
Thread(2)
	ntdll.dll	pc 0x14	NtWaitForSingleObject	(:0)[amd64:Windows NT:1D272E6EB138642C3C71E6089E65E2431]
	KERNELBASE.dll	pc 0x33f8e		(:0)[amd64:Windows NT:23CC3BB5BB5C6F341F5ADCBF41ADE6A91]
	NGR-Win64-ShippingBase.dll	pc 0xc6b35d		(:0)[amd64:Windows NT:0E33CB77D744411FA7727CF16FD940E81]
	NGR-Win64-ShippingBase.dll	pc 0x26f880		(:0)[amd64:Windows NT:0E33CB77D744411FA7727CF16FD940E81]
	NGR-Win64-ShippingBase.dll	pc 0x26b6a0		(:0)[amd64:Windows NT:0E33CB77D744411FA7727CF16FD940E81]
	NGR-Win64-ShippingBase.dll	pc 0x18f1e4		(:0)[amd64:Windows NT:0E33CB77D744411FA7727CF16FD940E81]
	NGR-Win64-ShippingBase.dll	pc 0x26b6a0		(:0)[amd64:Windows NT:0E33CB77D744411FA7727CF16FD940E81]
	NGR-Win64-ShippingBase.dll	pc 0x2592a5		(:0)[amd64:Windows NT:0E33CB77D744411FA7727CF16FD940E81]
	NGR-Win64-ShippingBase.dll	pc 0xeb2bd0		(:0)[amd64:Windows NT:0E33CB77D744411FA7727CF16FD940E81]
...
    */
  },
}, initialState);

function handleGetIssueInfoSuccess(state, action) {
  const { response: issueInfo } = action;
  if (issueInfo) {
    const current = state.get('current');
    if (current) {
      return state.set('current', current.merge(Immutable.fromJS(issueInfo)));
    } else {
      return state.set('current', Immutable.fromJS(issueInfo));
    }
  }
  console.error('getIssueInfo response issueList must have one element! reponse:', action.response);
  return state;
}

function changeExceptionOptionsAndTypeList(state, pid, exceptionType) {
  let options;
  switch (exceptionType) {
    case 'crashes':
    case 'crash':

      if ( parseInt(pid) === PLATFORM_ID.ANDROID ) {
        options = ANDROID_EXCEPTION_TYPE_LIST_OPTIONS
      } else if ( parseInt(pid) === PLATFORM_ID.IOS ) {
        options = IOS_EXCEPTION_TYPE_LIST_OPTIONS
      } else {
        options = [
          { label: i18n.t('exceptionTypeOptions.所有类型'), value: DEFAULT_EXCEPTION_TYPE_LIST_ANDROID },
        ];
      }
      //options = parseInt(pid) === 1 ? ANDROID_EXCEPTION_TYPE_LIST_OPTIONS : IOS_EXCEPTION_TYPE_LIST_OPTIONS;
      break;
    case 'blocks':
    case 'anr':
      options = ANR_EXCEPTION_TYPE_LIST_OPTIONS;
      break;
    case 'errors':
    case 'error':
      options = ERROR_EXCEPTION_TYPE_LIST_OPTIONS;
      break;
    default: break;
  }
  return state
    .updateIn(['selectOptions', 'exceptionTypeList'], () => new Map({ options }))
    .updateIn(['searchParams', 'exceptionCategoryList'], () => ExceptionCategoryUtil.unify(exceptionType))  // 这个list是逗号分隔的
}

/**
 * 处理获取issue搜索条件可选项
 */
function handleGetIssueOptions(state, action) {
  const { tags = [], members = [], versions = [] } = action.response;
  return state.update('selectOptions', (options) => options.mergeDeep({
    version: {
      // options: new List(versions).map((item) => ({ label: item.version, value: encodeURIComponent(item.version) })).unshift({
      //  label: '全版本',
      //  value: 'all',
      // }),
      options: (() => {
        const results = [];

        versions.forEach((item) => {
          results.push({
            label: parseInt(item.enable) === 0 ? (`${item.version}(已关闭)`) : item.version,
            value: encodeURIComponent(item.version),
          });
          if (Array.isArray(item.hotpatchVersions) && item.hotpatchVersions.length > 0) {
            item.hotpatchVersions.forEach((hotpatchVersion) => {
              results.push({
                label: parseInt(item.enable) === 0
                  ? (`${item.version}(hotpatch: ${hotpatchVersion})(已关闭)`) : `${item.version}(hotpatch: ${hotpatchVersion})`,
                value: encodeURIComponent(`${item.version}[${hotpatchVersion}]`),
              });
            });
          }
          if (Array.isArray(item.hotPluginVersionBeans) && item.hotPluginVersionBeans.length > 0) {
            item.hotPluginVersionBeans.forEach((pluginVersionBean) => {
              if (Array.isArray(pluginVersionBean.hotPluginVersion) && pluginVersionBean.hotPluginVersion.length > 0) {
                pluginVersionBean.hotPluginVersion.forEach((hotPluginVersion) => {
                  results.push({
                    label: parseInt(item.enable) === 0
                      ? (`${item.version}(plugin: ${pluginVersionBean.hotPluginId}--${hotPluginVersion})(已关闭)`)
                      : `${item.version}(plugin: ${pluginVersionBean.hotPluginId}--${hotPluginVersion})`,
                    value: encodeURIComponent(`${item.version}[${pluginVersionBean.hotPluginId}[${hotPluginVersion}]]`),
                  });
                });
              }
            });
          }
        });
        results.unshift({
          label: i18n.t('versionOptions.全版本'),
          value: 'all',
        });
        return results;
      })(),
    },
    processor: {
      options: new List(uniqBy(members, x => getUserId(x))).map((item) => ({
        label: getNickName(item),
        value: (item || {}).userId,
        localUserId: (item || {}).newUserId,
      })).unshift({ label: i18n.t('processorOptions.所有处理人'), value: 'all' }),
    },
    tagList: {
      options: new List(tags).map((item) => ({ label: item.tagName, value: item.tagId })).unshift({
        label: i18n.t('tagOptions.所有标签'),
        value: 'all',
      }),
    },
  }));
}

function fillPartialNonMinidumpDetailStackMemoryInfoList(nonMinidumpDetailStackMemoryInfoList, pid, { memoryData, retraceCrashDetail }) {
  let res = [...(nonMinidumpDetailStackMemoryInfoList || [])];
  let patchList = [];
  if (memoryData) {
    patchList.push(...memoryData.map((x, i) => ({
      lineNumber: i + 1,
      originAddress: x?.originAddress || '',
      originMemoryData: x?.originMemoryData || '',
      asmCodeInfo: x?.asm || '',
    })));
  }
  if (retraceCrashDetail) {
    const stackLines = StackUtil.splitStackIntoLines(retraceCrashDetail, pid);
    patchList.push(...stackLines.map((x, i) => ({
      lineNumber: i + 1,
      lineStack: x,
    })));
  }

  patchList.forEach(patch => {
    const { lineNumber } = patch;
    const hasMatchedLineNumber = res.some(x => x?.lineNumber === lineNumber);
    if (hasMatchedLineNumber) {
      res = res.map(x => {
        if (x?.lineNumber !== lineNumber) {
          return x;
        }
        return {
          ...x,
          ...patch,
        };
      });
    } else {
      res.push(patch);
    }
  });

  res.sort((a, b) => a.lineNumber - b.lineNumber);
  return res;
}

// 注意，这里的处理逻辑在IssueCrashPreview那里有用到
function handleGetCrashDoc(state, action) {
  let crashDoc = action.response;
  crashDoc.crashMap = crashDoc.crashMap || {};
  crashDoc.detailMap = crashDoc.detailMap || {};
  let newState = saveResponeToCurrent(state, 'crashDoc', crashDoc);
  const pid = action.params.pid;

  let newNonMinidumpDetailStackMemoryInfoList;
  // 移动端非minidump，为了解析显示反汇编结果，需要填充堆栈内容
  if (isMobile(pid)) {
    const { retraceCrashDetail } = crashDoc.crashMap;
    const nonMinidumpDetailStackMemoryInfoList = newState.get('current').get('nonMinidumpDetailStackMemoryInfoList');
    newNonMinidumpDetailStackMemoryInfoList = fillPartialNonMinidumpDetailStackMemoryInfoList(
      nonMinidumpDetailStackMemoryInfoList, pid, { retraceCrashDetail });
    newState = saveResponeToCurrent(newState, 'nonMinidumpDetailStackMemoryInfoList', newNonMinidumpDetailStackMemoryInfoList);
  }

  if (!isPcOrLinux(pid) && !isPlaystation(pid)) {
    return newState;
  }
  // PC旧的数据 / PS5 需要从esMap里面取出自定义kv数据，和移动端不同。移动端是getCrashAttachment的时候从hbase取自定义kv。
  const { esMap } = crashDoc;
  const hashKeyValues = (esMap || {}).hashKeyValues || {};
  let attachCustomKvList = Object.values(hashKeyValues)
    .filter(x => x.originKey)
    .map(x => CustomKvUtil.splitCompositeKv(x.originKey, x.originValue))
    .flat();
  attachCustomKvList = uniqBy(attachCustomKvList, x => x.keyWithType);
  const attachAllKvList = attachCustomKvList;

  return newState.update('current', (issue) => issue.set('attachCustomKvList', attachCustomKvList)
    .set('attachAllKvList', attachAllKvList));
}

/**
 * 获得额外数据
 * // 注意，这里的处理逻辑在IssueCrashPreview那里有用到
 */
function handleGetCrashAttachment(state, action) {
  const pid = action.params.pid;
  if (!pid) {
    message.warn('handleGetCrashAttachment, no pid provided');
  }
  // 获得附件数据，只展示指定的文件
  const attachList = action.response.attachList || [];
  const showAttachmentList = CrashAttachmentUtil.getKnownAttachmentFilenames();
  const extraFile = attachList.map((attachment) => {
    if (showAttachmentList.indexOf(attachment.fileName) > -1) {
      return attachment.fileName;
    }
    return '';
  }).filter((fileName) => !!fileName);

  // 取出文件名为valueMapOthers.txt的内容，并筛选出C03开头（PC除外）的为用户自定义kv
  const delimiter = isPcOrLinux(pid) ? '\n' : ';';
  const allValueMapTexts = attachList.filter(attach => attach.fileName === 'valueMapOthers.txt')
    .map(attach => attach.content)
    .join(delimiter);
  let attachAllKvList = allValueMapTexts
    .split(delimiter)
    .filter(x => x)
    .map((keyValue) => {
      const kvDelimiter = isPcOrLinux(pid) ? '=' : ':';
      const kv = keyValue.split(kvDelimiter);
      let value = kv[1];
      if (kv.length > 2) { // 用户的value可能带有分隔符
        value = kv.slice(1).join(kvDelimiter);
      }
      const key = kv[0];
      return { type: '-', key, keyWithType: key, value };
    });
  attachAllKvList = uniqBy(attachAllKvList, x => x.key);
  const attachCustomKvList = attachAllKvList
    .filter(x => isPcOrLinux(pid) || /C03/i.test(x.key))
    .map(x => CustomKvUtil.splitCompositeKv(x.key, x.value))
    .flat();

  // 其他线程为type=2的附件,这里attachList和otherThread的内容是一样的东西，只是有的有经过一些过滤的操作而已
  const otherThread = attachList.filter((attachment) => {
    if (parseInt(attachment.fileType) === 2) {
      return true;
    } else {
      return false;
    }
  });

  let newNonMinidumpDetailStackMemoryInfoList;
  // 移动端非minidump，解析反汇编结果
  if (isMobile(pid)) {
    const memoryDataJsonText = attachList.find(attach => attach.fileName === 'memoryData.txt')?.content || '';
    let memoryData = [];
    if (memoryDataJsonText) {
      try {
        memoryData = JSON.parse(memoryDataJsonText);
        const nonMinidumpDetailStackMemoryInfoList = state.get('current').get('nonMinidumpDetailStackMemoryInfoList');
        newNonMinidumpDetailStackMemoryInfoList = fillPartialNonMinidumpDetailStackMemoryInfoList(
          nonMinidumpDetailStackMemoryInfoList, pid, { memoryData });
      } catch (e) {
        console.error(`parse memoryData.txt failed.`, e);
        message.error('Parse memoryData.txt failed.');
      }
    }
  }

  let userLogs = action.response.userLogs;
  // PC平台接口里的userLogs字段没有填充内容，需要从附件里面拿
  if (isPcOrLinux(pid)) {
    const pcUserLogsBase64Text = attachList.find(attach => attach.fileName === 'crashSightLog.log')?.content || '';
    if (pcUserLogsBase64Text) {
      try {
        const pcLogUint8Array = Uint8Array.from(window.atob(pcUserLogsBase64Text), c => c.charCodeAt(0));
        const pcLogText = new TextDecoder().decode(pcLogUint8Array);
        userLogs = (pcLogText || '').split('\n');
      } catch (e) {
        console.error('parse crashSightLog.log failed.', e);
        message.error(ze('解析自定义日志失败', 'Parse CustomLog failed'));
      }
    }
  }

  return state.update('current', (issue) => {
    let res = issue.set('sysLogs', action.response.sysLogs)
      .set('userLogs', userLogs)
      .set('extraFile', extraFile)
      .set('otherThread', otherThread)
      .set('attachList', attachList)
      .set('appDetailCrashInfo', action.response);

    // 除PS5以外的平台，通过附件拿自定义kv
    if (!isPlaystation(pid)) {
      res = res
        .set('attachCustomKvList', attachCustomKvList)
        .set('attachAllKvList', attachAllKvList);
    }

    if (newNonMinidumpDetailStackMemoryInfoList) {
      res = res.set('nonMinidumpDetailStackMemoryInfoList', newNonMinidumpDetailStackMemoryInfoList);
    }
    return res;
  });
}

function handleGetSelector(state, action) {
  // 改成getSelectorDatas的接口调用。
  console.log('handleGetSelector的页面信心', action);
  const {
    tagList = [], processorList = [], versionList = [], channelList = [], bundleIdList = [],
  } = action.response;
  const pid = action.params.platformId;
  return state.update('selectOptions', (options) => options.merge({
    version: {
      // options: new List(versions).map((item) => ({ label: item.version, value: encodeURIComponent(item.version) })).unshift({
      //  label: '全版本',
      //  value: 'all',
      // }),
      options: (() => {
        const results = [];

        versionList.forEach((item) => {
          results.push({
            label: parseInt(item.enable) === 0 ? (`${item.name}(已关闭)`) : item.name,
            value: encodeURIComponent(item.name),
          });
          if (Array.isArray(item.hotpatchVersions) && item.hotpatchVersions.length > 0) {
            item.hotpatchVersions.forEach((hotpatchVersion) => {
              results.push({
                label: parseInt(item.enable) === 0
                  ? (`${item.name}(hotpatch: ${hotpatchVersion})(已关闭)`) : `${item.name}(hotpatch: ${hotpatchVersion})`,
                value: encodeURIComponent(`${item.name}[${hotpatchVersion}]`),
              });
            });
          }
          if (Array.isArray(item.hotPluginVersionBeans) && item.hotPluginVersionBeans.length > 0) {
            item.hotPluginVersionBeans.forEach((pluginVersionBean) => {
              if (Array.isArray(pluginVersionBean.hotPluginVersion) && pluginVersionBean.hotPluginVersion.length > 0) {
                pluginVersionBean.hotPluginVersion.forEach((hotPluginVersion) => {
                  results.push({
                    label: parseInt(item.enable) === 0
                      ? (`${item.name}(plugin: ${pluginVersionBean.hotPluginId}--${hotPluginVersion})(已关闭)`)
                      : `${item.name}(plugin: ${pluginVersionBean.hotPluginId}--${hotPluginVersion})`,
                    value: encodeURIComponent(`${item.name}[${pluginVersionBean.hotPluginId}[${hotPluginVersion}]]`),
                  });
                });
              }
            });
          }
        });
        results.unshift({
          label: i18n.t('versionOptions.全版本'),
          value: 'all',
        });
        return results;
      })(),
    },
    processor: {
      options: new List(uniqBy(processorList, x => getUserId(x))).map((item) => ({
        label: getNickName(item),
        value: (item || {}).userId,
        localUserId: (item || {}).localUserId,
        rtx: (item || {}).rtx,
      })).unshift({ label: i18n.t('processorOptions.所有处理人'), value: 'all' }),
    },
    tagList: {
      options: new List(tagList).map((item) => ({ label: item.tagName, value: item.tagId })).unshift({
        label: i18n.t('tagOptions.所有标签'),
        value: 'all',
      }),
    },
    autoTagList: {// 所有自动标签
      options: new List(tagList).filter((item) => parseInt(item.tagType) === 1).map((item) => ({
        label: item.tagName,
        value: item.tagId,
      })).unshift({
        label: i18n.t('tagOptions.所有自动标签'),
        value: 'all',
      }),
    },
    channelList: {
      options: new List(channelList).map((item) => ({ label: item, value: encodeURIComponent(item) })).unshift({
        label: i18n.t('channelOptions.全渠道'),
        value: 'all',
      }),
    },
    bundleIdList: {
      options: new List(bundleIdList).map((item) => ({ label: item, value: encodeURIComponent(item) })).unshift({
        label: parseInt(pid) === 1 ? i18n.t('bundleOptions.所有应用包名') : i18n.t('bundleOptions.所有BundleId'),
        value: 'all',
      })
        .sort((a, b) => compareBundleId(a.label, b.label)),
    },
  }));
}

/**
 * 是否匹配问题列表的路由
 */
function isMatchIssueRoute(pathname) {
  return pathname && pathname.match(/crash\-reporting\/(crashes|blocks|errors)\/[^\/]+$/i);
}


// 1 实现切换当前的attachList的解析和还原状态。
function saveResponeToCurrent(state, key, response) {
  // state, 'stackType', action.stackType
  const current = state.get('current');
  if (current) {
    return state.update('current', (issue) => issue.set(key, response));
  } else {
    return state.set('current', { [key]: response });
  }
}

function handleAddOrDelTagSucc(state, action) {
  // 在列表页，需把结果存在issueList，在详情页（URL中又issue字段），需要结果存在current
  if (action.params.issueId === -1 || action.params.path === 'advanced-search' || action.params.path === 'advancedSearch') {
    return state;
  }
  const { tagInfoList } = action.response;
  const detailPageReg = /crashes|anr|block|error/i;
  // 是否是详情页的请求
  if (detailPageReg.test(action.params.path)) {
    return state.update('current', (issue) => issue.set('tagInfoList', new List(tagInfoList)));
  }
  const selectedIdx = state.get('issueList').findIndex((issue) => issue.get('issueId') === action.params.issueId);
  if (selectedIdx === -1) {
    return state;
  }
  return state.update('issueList', (issueList) => issueList.update(selectedIdx, (issue) => issue.set('tagInfoList', new List(tagInfoList))));
}

function changeStateSucc(state, action) {
  // 直接前端修改转状态结果
  if (action.params.page === 'issueDetail') {
    const noteItem = {
      userId: action.params.userId,
      newUserId: action.params.newUserId,
      note: action.params.note,
      createTime: action.params.createTime,
      issueStatus: action.params.status,
      processors: action.params.processors,
    };
    return state.update('current', (issue) => issue.set('processor', action.params.processors)
      .set('assigneeList', action.params.assigneeWithoutLocalUserIdList)
      .set('status', action.params.status)
      .update('noteList', (noteList = fromJS([])) => noteList.unshift(noteItem)));
  } else if (action.params.page === 'issueList') {
    const issueIdArr = !!action.params.issueIds && action.params.issueIds.split(';');
    return state.update('issueList', (issueList) => {
      return issueList.map((issue) => {
        if (issueIdArr && issueIdArr.indexOf(issue.get('issueId')) !== -1) {
          return issue.set('processor', action.params.processors)
            .set('assigneeList', action.params.assigneeWithoutLocalUserIdList)
            .set('status', action.params.status);
        } else {
          return issue;
        }
      });
    });
  } else {
    return state;
  }
}

export const IssueReducerUtil = Object.freeze({
  handleGetCrashDoc,
  handleGetCrashAttachment,
});
