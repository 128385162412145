export const SymbolFileTypeEnum = Object.freeze({
  ANDROID_MAPPING: 1,
  IOS_STIF: 2,
  ANDROID_STIF: 3,
  PC_PDB: 10,  // windbg 用的 pdb 文件
  PC_STIF: 11, // 加工成stif格式的breakpad符号表
  PC_BREAKPAD: 12,  // breakpad符号表文件
  NINTENDO_SWITCH_NSS: 13,
  SOURCE_MAP: 30,
});

export const SymbolFileTypeUtil = Object.freeze({
  isMapping(symbolFileType) {
    symbolFileType = Number(symbolFileType);
    return symbolFileType === SymbolFileTypeEnum.ANDROID_MAPPING
      || symbolFileType === SymbolFileTypeEnum.SOURCE_MAP;
  },
});
