import React, { useState, useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Select } from 'antd';
import { COUNTRY_LIST, countryI18n, CountryUtil,countryFlagUrl } from 'utils/country';
import { isNotNullish } from 'utils/nullish';
import { bindActionCreators } from 'redux';
import { setCountryGroupConfigModalVisible } from 'reducers/summary/summaryActions';
import isString from 'lodash/lang/isString';
import { useTranslation } from 'react-i18next';
import { useZhEn } from 'utils/react-hooks/useZhEn';
import { ze } from 'utils/zhEn';
import { CsSelect } from 'components/antd-extension/CsSelect';

export const COUNTRY_LIST_OPTIONS = COUNTRY_LIST.map(x => {
  const name = countryI18n(x);
  return {
    label: !CountryUtil.isDeprecated(x)
      ? name
      : `${name} ${ze('(已废弃)', '(Deprecated)')}`,
    value: x,
  };
});

// select组件的options数组，返回国家+国旗icon（svg格式）
export const COUNTRY_WITH_FLAG_LIST_OPTIONS = COUNTRY_LIST.map(x => {
  const name = countryI18n(x);
  return {
    rawLabel: !CountryUtil.isDeprecated(x) ? `${name}` : `${name} ${ze('(已废弃)', '(Deprecated)')}`,
    label: <span style={{ display: 'flex', alignItems: 'center' }}>
      {countryFlagUrl(x) ?
        (<img style={{ width: '20px', marginRight: '5px' }} alt="Icon" src={countryFlagUrl(x)} />)
        : (<span style={{ width: '20px', marginRight: '5px' }}> </span>)}
      {!CountryUtil.isDeprecated(x) ? `${name}` : `${name} ${ze('(已废弃)', '(Deprecated)')}`}
    </span>,
    value: x,
  };
});

const CountrySelect = (props) => {
  const {
    reduxState,
    actions,
    size,
    style,
    value,
    isMultiple,
    onChangeWhenDropdownClosed,
  } = props;

  const { t } = useTranslation();
  const { ze } = useZhEn();

  const selectRef = useRef(null);

  const countryGroupConfigList = reduxState.summary.get('countryGroupConfigList').toJS();
  const countryGroupOptions = countryGroupConfigList.map(x => ({
    rawLabel: x.countryGroup,
    label: x.countryGroup,
    value: `${CountryUtil.COUNTRY_GROUP_PREFIX}${x.id}`,
  }));

  const options = [{
    label: <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ whiteSpace: 'pre'}}>{ze('自定义国家/地区（', 'Custom country/region (')}</span>
        <Button
          type='link'
          size='small'
          style={{ fontSize: '12px', padding: 0, borderWidth: 0 }}
          onClick={() => {
            if (selectRef && selectRef.current) {
              selectRef.current.blur();
            }
            actions.setCountryGroupConfigModalVisible(true);
          }}
        >{ze('组合配置', 'Config Custom Areas')}
        </Button>
      <span>{ze('）', ')')}</span>
    </div>,
    options: countryGroupOptions,
  }, {
    label: ze('国家/地区', 'Country/Region'),
    options: COUNTRY_WITH_FLAG_LIST_OPTIONS,
  }]

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [intermediateValue, setIntermediateValue] = useState(null);

  useEffect(() => {
    setIntermediateValue(value);
  }, [value]);

  return <CsSelect
    ref={selectRef}
    allowClear={true}
    {...(isNotNullish(size) ? { size } : {})}
    style={style || { width: '300px' }}
    {...(isMultiple ? { mode: 'multiple' } : {})}
    value={intermediateValue}
    virtual={false}
    options={options}
    showArrow={true}
    onChange={(v) => {
      if (dropdownOpen) {
        setIntermediateValue(v);
      } else {
        onChangeWhenDropdownClosed(v);
      }
    }}
    onDropdownVisibleChange={(open) => {
      setDropdownOpen(open);
      if (open) {
        setIntermediateValue(value);
      } else if (value !== intermediateValue) {
        onChangeWhenDropdownClosed(intermediateValue);
      }
    }}
    showSearch={true}
    filterOption={(input, option) => {
      if (!isString(option.rawLabel)) {
        return false;
      }
      return option.rawLabel.toLowerCase().includes(input.toLowerCase())
    }}
    placeholder={t('EXCP_OVERVIEW.全部国家地区')}
  />
}

const mapStateToProps = state => ({
  reduxState: state,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    setCountryGroupConfigModalVisible,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CountrySelect);
