import { isNullish, isNotNullish } from 'utils/nullish';

export const TapdUtil = (function() {
  return Object.freeze({
    makeBugUrl(tapdBugInfo, teamType) {
      if (isNullish(tapdBugInfo)) return null;
      let bugUrl = '';
      const { bugPlatform, commercialTapd, workspaceId, id: bugId } = tapdBugInfo;

      // 兼容返回的历史tapd数据不带bugPlatform字段
      const platForm = isNotNullish(bugPlatform)
        ? bugPlatform
        : 'TAPD';

      // 兼容以前bug数据里面没有commercialTapd字段，没有这个字段的用teamType判断
      const isCommercialTapd = isNotNullish(commercialTapd)
        ? commercialTapd
        : teamType === 'COMMERCIAL';

      if (platForm === 'JIRA') {
        bugUrl = `${tapdBugInfo.self.substring(0, tapdBugInfo.self.indexOf('/rest'))}/browse/${tapdBugInfo.key}`;
      } else {
        bugUrl = `https://${isCommercialTapd ? 'tapd.cn' : 'tapd.woa.com'}/${workspaceId}/bugtrace/bugs/view?bug_id=${bugId}`;
      }
      return bugUrl;
    },
  });
})();
