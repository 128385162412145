import React, {useState, useEffect, useMemo, useDebugValue} from 'react';
import { bindActionCreators } from 'redux';
import { useImmer } from 'use-immer';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  AutoComplete,
  Button,
  Checkbox, Col,
  Form,
  Input,
  InputNumber, message,
  Modal,
  Popconfirm, Row,
  Select,
  Space,
  Spin,
  Switch,
  Table
} from 'antd';
import { useZhEn } from 'utils/react-hooks/useZhEn';
import { selectServerAppSettings } from 'utils/selectors/selectors';
import { ServerAppSettings } from 'utils/server-app-settings';
import { ze } from 'utils/zhEn';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { upsertServerAppSettings } from 'reducers/app/appActions';
import { ExceptionCategory, ExceptionCategoryUtil } from 'utils/exception-category';
import range from 'lodash/utility/range';
import uniq from 'lodash/array/uniq';
import { DownOutlined } from '@ant-design/icons';

function makeFormInitialValue() {
  return {
    enabled: false,
    triggerHour: 8,
    categoryListNeedTopIssues: [ExceptionCategory.CRASH],
    crashTopIssueCount: DailyReportConfigUtil.DEFAULT_TOP_ISSUE_COUNT,
    errorTopIssueCount: DailyReportConfigUtil.DEFAULT_TOP_ISSUE_COUNT,
    receiverRtxList: [],
    superAdminBccRtxList: [],
    receiverWebhookUrlList: [],
  };
}

const SingleValueAsArrayInput = ({ value, onChange, ...rest }) => {
  const innerValue = (value || [])[0];

  return <Input
    value={innerValue}
    onChange={(e) => {
      const v = e.target.value;
      if (onChange) {
        onChange([v]);
      }
    }}
    {...rest}
  />;
}

const triggerHourOptions = range(2, 23).map(x => ({ label: x, value: x }));

export const DailyReportConfigUtil = Object.freeze({
  DEFAULT_TOP_ISSUE_COUNT: 3,
  MAX_TOP_ISSUE_COUNT: 10,
});

const DailyReportConfigCore = (props) => {
  const {
    reduxState,
    hasEditPermission,
    submitTrigger,
    onSubmitDone = () => {},
  } = props;

  const currentApp = reduxState.app.get('current').toJS();
  const { appId, pid } = currentApp;
  const currentUser = reduxState.user.get('current');
  const isSuperAdmin = !!currentUser.get('isSuper');
  const currentUserRtx = currentUser.get('rtx');
  const dispatch = useDispatch();
  const serverAppSettings = useSelector(state => selectServerAppSettings(state, appId));
  const dailyReportSetting = serverAppSettings[ServerAppSettings.keys.dailyReportSetting] || {};

  const selectOptions = reduxState.summary.get('selectOptions');
  const { processor } = selectOptions.toJS();

  let rtxListForOptions = ((processor || {}).options || [])
    .map(x => x.rtx)
    .filter(x => x);
  if (isSuperAdmin && currentUserRtx) {
    rtxListForOptions = uniq([...rtxListForOptions, currentUserRtx]);
  }
  const rtxReceiverOptions = rtxListForOptions.map(x => ({ label: x, value: x }));

  const [initDone, setInitDone] = useState(false);

  const [formValue, setFormValue] = useState(makeFormInitialValue());
  const [formInstance] = Form.useForm();
  const [resetFieldsTrigger, setResetFieldsTrigger] = useState(0);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!appId) {
      return;
    }
    setInitDone(true);
  }, [appId]);

  useDeepCompareEffect(() => {
    setFormValue({ ...makeFormInitialValue(), ...dailyReportSetting });
    setResetFieldsTrigger(resetFieldsTrigger + 1);
  }, [dailyReportSetting]);

  useEffect(() => {
    if (resetFieldsTrigger > 0) {
      formInstance.resetFields();
    }
  }, [resetFieldsTrigger]);

  useEffect(() => {
    if (submitTrigger) {
      submit();
    }
  }, [submitTrigger]);

  async function submit() {
    await dispatch(upsertServerAppSettings(appId, { [ServerAppSettings.keys.dailyReportSetting]: formValue }));
    onSubmitDone();
  }

  if (!initDone) {
    return <div>{ ze('加载配置中', 'Loading Config')}</div>;
  }

  const formDom = <Form
    form={formInstance}
    layout='horizontal'
    labelCol={{ style: { width: '150px' } }}
    initialValues={formValue}
    onValuesChange={(changedValues) => {
      setFormValue({
        ...formValue,
        ...changedValues,
      })
    }}
  >
    <Form.Item noStyle={true}>
      <div style={{ marginBottom: '16px', fontWeight: '300'}}>{ ze('每日定时推送前一日大盘异常概览及TOP问题。', 'Sends dashboard report every day.') }</div>
    </Form.Item>
    <Form.Item name='enabled' valuePropName="checked" labelAlign='left' label={ze('开启订阅', 'Enable Subscription')}>
      <Switch checkedChildren={ze('开启', 'On')} unCheckedChildren={ze('关闭', 'Off')} disabled={!hasEditPermission}/>
    </Form.Item>
    <Form.Item label={ze('日报推送时间', 'Report Send Time')} labelAlign='left'><Space>
      <div>{ ze('每天', 'Every Day') }</div>
      <Form.Item name='triggerHour' noStyle={true}>
        <Select
          disabled={!hasEditPermission}
          style={{ width: '70px' }}
          showSearch={true}
          options={triggerHourOptions}
        />
      </Form.Item>
      <div>{ ze('点', 'O\'Clock') }</div>
    </Space></Form.Item>
    <Form.Item name='categoryListNeedTopIssues' label={ze('附带信息', 'Extra Infos In Report')} labelAlign='left'>
      <Checkbox.Group><Row gutter={[12, 12]}>{
        [ExceptionCategory.CRASH, ExceptionCategory.ERROR].map(x => {
          const categoryLabel = ExceptionCategoryUtil.toI18n(x, pid);
          const topCountFormName = {
            [ExceptionCategory.CRASH]: 'crashTopIssueCount',
            [ExceptionCategory.ERROR]: 'errorTopIssueCount',
          }[x];
          return <Col span={24} key={x}>
            <Space>
              <Checkbox value={x} disabled={!hasEditPermission}>TOP</Checkbox>
              <div><Form.Item name={topCountFormName} noStyle={true}>
                <InputNumber
                  disabled={!hasEditPermission}
                  style={{ width: '80px' }}
                  min={1}
                  max={DailyReportConfigUtil.MAX_TOP_ISSUE_COUNT}
                />
              </Form.Item></div>
              <div>{ ze(`${categoryLabel}问题`, `${categoryLabel} Issues`) }</div>
            </Space>
          </Col>;
        })
      }</Row></Checkbox.Group>
    </Form.Item>
    <Form.Item name='receiverRtxList' label={ze('企微通知人员', 'WeCom Notify Users')} labelAlign='left'>
      <Select
        disabled={!hasEditPermission}
        mode='multiple'
        options={rtxReceiverOptions}
    showArrow={true}
    suffixIcon={<DownOutlined />}
      />
    </Form.Item>
    { isSuperAdmin && <Form.Item name='superAdminBccRtxList' label='(超管)额外密送人员' labelAlign='left'>
      <Select
        disabled={!hasEditPermission}
        mode='tags'
        options={rtxReceiverOptions}
      />
    </Form.Item> }
    <Form.Item name='receiverWebhookUrlList' label={ze('企微群机器人Webhook', 'WeCom Bot Webhook')} labelAlign='left'>
      <SingleValueAsArrayInput disabled={!hasEditPermission}/>
    </Form.Item>
  </Form>;

  return <Spin spinning={loading}>
    <div>{ formDom }</div>
    { isSuperAdmin && <Space>
      <Popconfirm
        title={'确定要发送？请勿在线上项目上面使用此功能！'}
        onConfirm={() => {
          (async () => {
            setLoading(true);
            await RestHelper.mustPost('/super-admin-api/dailyReport/generateDailyReportIgnoreTriggerHour', {appId});
            message.success('发送成功');
            setLoading(false);
          })();
        }}
      ><Button style={{marginLeft: '150px'}}>（超管）测试直接发送</Button></Popconfirm>
      <Button
        onClick={async () => {
          const rsp = await RestHelper.mustPost('/super-admin-api/dailyReport/testGenerateScreenshotUrl', { appId });
          const screenCutUrl = new URL(rsp.json.data);
          const pageUrl = screenCutUrl.searchParams.get('originPath');
          window.open(pageUrl, '_blank');
        }}
      >（超管）访问日报对应页面</Button>
    </Space> }
  </Spin>;
};

DailyReportConfigCore.propTypes = {
  reduxState: PropTypes.object,
  hasEditPermission: PropTypes.bool,
  submitTrigger: PropTypes.number,
};

const mapStateToProps = state => ({
  reduxState: state,
});

export default connect(mapStateToProps)(DailyReportConfigCore);
