import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import scss from './CsDownloadButton.scss';
import classnames from 'classnames';
import DownloadIcon from 'svg/v2/newcs_dashboard_download_icon_normal.svg';

const CsDownloadButton = (props) => {
  const {
    disabled,
    onClick,
    ...restProps
  } = props || {};

  return <DownloadIcon
    onClick={!disabled ? onClick : undefined}
    className={classnames(scss.csDownloadButton, { [scss.disabled]: disabled })}
    {...restProps}
  />;
};

CsDownloadButton.propTypes = {
  onClick: PropTypes.func,
};

export default CsDownloadButton;
