import React, { useState, useEffect, useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useImmer } from 'use-immer';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import scss from './style.scss';
import CardHead from 'components/commons/CardHead';
import { Button, Checkbox, Col, DatePicker, Row, Select, Space, Spin, Table, Tooltip } from 'antd';
import moment from 'moment';
import { PAGE_TYPE_CONVERT, SummaryVmOptions } from 'utils/constants';
import groupBy from 'lodash/collection/groupBy';
import { useTranslation } from 'react-i18next';
import { countryI18n, CountryUtil } from 'utils/country';
import i18n from 'i18n';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { formatEnNumber } from 'utils/format/format-number';
import CountrySelect from 'components/exception/ProductSummaryPage/CountrySelect';
import { useZhEn } from 'utils/react-hooks/useZhEn';
import uniq from 'lodash/array/uniq';
import TrendIcon from 'svg/v2/newcs_dashboard_overview_crashtrend_icon.svg';
import WrappedTipsIcon from 'components/antd-extension/WrappedTipsIcon.jsx';
import CsCollapseButton from 'components/commons/CsCollapseButton/CsCollapseButton';

const { RangePicker } = DatePicker;

const StatByIntegratedApp = (props) => {
  const { t } = useTranslation();
  const { ze } = useZhEn();
  const { reduxState } = props;
  const currentApp = reduxState.app.get('current').toJS();
  const { appId, platformId } = currentApp;
  const { pageType, rankVersion, integratedAppOptions } = reduxState.summary.get('selectOptions').toJS();
  const reportCategory = PAGE_TYPE_CONVERT[pageType];
  const versionOptions = rankVersion.options;

  const [loading, setLoading] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [version, setVersion] = useState(null);
  const [vmStatus, setVmStatus] = useState(0);
  const [minDate, setMinDate] = useState(moment().format('YYYY-MM-DD'));
  const [maxDate, setMaxDate] = useState(moment().format('YYYY-MM-DD'));
  const [tableData, setTableData] = useState([]);
  const [isMultiDayResult, setIsMultiDayResult] = useState(false);
  const [isExpand, setIsExpand] = useState(false);
  const [isAutoFetchNeeded, setIsAutoFetchNeeded] = useState(false);

  // 切换app时重置搜索字段
  useEffect(() => {
    if (!appId) {
      return;
    }
    setCountryList([]);
    setVersion(null);
    setMinDate(moment().format('YYYY-MM-DD'));
    setMaxDate(moment().format('YYYY-MM-DD'));
    setIsAutoFetchNeeded(true);
  }, [appId]);

  useEffect(() => {
    if (!appId) {
      return;
    }
    setIsAutoFetchNeeded(true);
  }, [pageType]);

  useEffect(() => {
    if (!isAutoFetchNeeded || integratedAppOptions.length === 0) {
      return;
    }
    setIsAutoFetchNeeded(false);
    fetchTrend();
  }, [isAutoFetchNeeded, integratedAppOptions]);

  async function fetchTrend() {
    setLoading(true);

    const startDate = minDate.replace(/-/g, '');
    const endDate = maxDate.replace(/-/g, '');
    const rsp = await RestHelper.mustPost('/api/app/getTrend', {
      appId,
      extraAppIdList: integratedAppOptions.map(x => `${appId}:${x.value}`),
      platformId,
      version: version || '-1',
      startDate,
      endDate,
      type: reportCategory,
      vm: vmStatus,
      needCountryDimension: false,
    });
    const trendDataList = rsp.json.ret.data;
    // 多个日期的数据合并（GET_TREND_SUCC回调，StatByCountry那边有类似逻辑，后面可以抽取成公共的）
    const groupKeyToDateTrendList = groupBy(trendDataList, x => x.appId);
    const groupStatList = Object.entries(groupKeyToDateTrendList).map(([groupKey, dataTrendList]) => {
      return dataTrendList.reduce((acc, x) => {
        if (!acc) {
          return x;
        }
        return {
          ...acc,
          accessNum: x.accessNum + acc.accessNum,
          accessUser: x.accessUser + acc.accessUser,
          crashNum: x.crashNum + acc.crashNum,
          crashUser: x.crashUser + acc.crashUser,
        };
      }, null);
    });
    const enrichedIntegratedAppStatList = groupStatList
      .map(x => {
        let integratedAppId, integratedAppName;
        if (appId === x.appId) {
          integratedAppId = '';
          integratedAppName = ze('全量', 'All');
        } else {
          integratedAppId = x.appId.split(':')[1];
          integratedAppName = (integratedAppOptions.find(o => o.value === integratedAppId) || {}).label;
        }

        const devicePercentage = x.accessUser > 0
          ? (100 * x.crashUser / x.accessUser)
          : 0;
        const numPercentage = x.accessNum > 0
          ? (100 * x.crashNum / x.accessNum)
          : 0;
        return {
          ...x,
          integratedAppId,
          integratedAppName,
          devicePercentage,
          numPercentage,
        };
      })
      .filter(x => x.integratedAppId);
    setTableData(enrichedIntegratedAppStatList);
    setIsMultiDayResult(startDate !== endDate);
    setLoading(false);
  }

  const multiDayNotSupportedDom = <Tooltip
  title={ze(
    '多日查询不支持设备数相关指标（影响设备数、联网设备数等）',
    `If the selected time range spans multiple days and performance considerations are taken into account, some device-related metric queries may not be supported, such as "Affected devices" or "Active devices".`
  )}>
    <Space size={4}>
      <div>-</div>
      <WrappedTipsIcon />
    </Space>
  </Tooltip>;

  const columns = [{
    title: ze('接入项目', 'Integrated App'),
    width: '200px',
    dataIndex: 'integratedAppName',
    sorter: (a, b) => (a.integratedAppName || '').localeCompare(b.integratedAppName),
  }, {
    title: t('EXCP_OVERVIEW.userCrashRate'),
    width: '200px',
    key: 'devicePercentage',
    dataIndex: 'devicePercentage',
    render: (text, record) => {
      if (isMultiDayResult) {
        return multiDayNotSupportedDom;
      }
      const { devicePercentage } = record;
      return `${devicePercentage.toFixed(2)} %`;
    },
    sorter: (a, b) => a.devicePercentage - b.devicePercentage,
  }, {
    title: t('EXCP_OVERVIEW.occurNumCrashRate'),
    width: '200px',
    key: 'countPercentage',
    dataIndex: 'countPercentage',
    render: (text, record) => {
      const { numPercentage } = record;
      return `${numPercentage.toFixed(2)} %`;
    },
    sorter: (a, b) => a.numPercentage - b.numPercentage,
  }, {
    title: t('EXCP_OVERVIEW.occurNumber'),
    width: '200px',
    key: 'crashNum',
    dataIndex: 'crashNum',
    defaultSortOrder: 'descend',
    render: (text, record) => {
      return formatEnNumber(text);
    },
    sorter: (a, b) => a.crashNum - b.crashNum,
  }, {
    title: t('EXCP_OVERVIEW.userNumAffect'),
    width: '200px',
    key: 'crashUser',
    dataIndex: 'crashUser',
    render: (text, record) => {
      if (isMultiDayResult) {
        return multiDayNotSupportedDom;
      }
      return formatEnNumber(text);
    },
    sorter: (a, b) => a.crashUser - b.crashUser,
  }, {
    title: t('EXCP_OVERVIEW.sessionCount'),
    width: '200px',
    key: 'accessNum',
    dataIndex: 'accessNum',
    render: (text, record) => {
      return formatEnNumber(text);
    },
    sorter: (a, b) => a.accessNum - b.accessNum,
  }, {
    title: t('EXCP_OVERVIEW.userNumConnect'),
    width: '200px',
    key: 'accessUser',
    dataIndex: 'accessUser',
    render: (text, record) => {
      if (isMultiDayResult) {
        return multiDayNotSupportedDom;
      }
      return formatEnNumber(text);
    },
    sorter: (a, b) => a.accessUser - b.accessUser,
  }, ]

  return <Spin spinning={loading}>
    <CardHead title={ze('按接入项目统计详情', 'Stats By Integrated App')}
      hideDivider={!isExpand}
      svgIcon={<TrendIcon />}
      onClick={() => {
        setIsExpand((prev) => !prev);
      }}
      >
      <div style={{display: 'flex', flexGrow: '1', alignItems: 'center', justifyContent: 'flex-end'}}>
        <CsCollapseButton
          extraStyle={{ width: '14px', height: '14px' }}
          isCollapsed={!isExpand}
          enableHover={true}
          onClick={(e) => {
            e.stopPropagation();
            setIsExpand((prev) => !prev);
          }}
        />
      </div>
      {/* <Row gutter={12} align='middle'>
        <Col>
          <RangePicker
            style={{ width: '240px' }}
            allowClear={false}
            allowEmpty={[false, false]}
            showToday={false}
            placeholder={[ze('日期范围', 'Time Range')]}
            value={[moment(minDate), moment(maxDate)]}
            onChange={(dates) => {
              const [minDate, maxDate] = (dates || [null, null]).map(x => x && x.format('YYYY-MM-DD'));
              setMinDate(minDate);
              setMaxDate(maxDate);
            }}
          />
        </Col>
        <Col>
          <Select
            style={{ width: '180px' }}
            options={SummaryVmOptions}
            value={vmStatus}
            onChange={v => setVmStatus(v)}
          />
        </Col> */}
        {/*<Col>
          <CountrySelect
            value={countryList}
            isMultiple={true}
            onChangeWhenDropdownClosed={(v) => setCountryList(v)}
          />
        </Col>*/}
        {/* <Col>
          <Button
            type='primary'
            onClick={() => fetchTrend()}
          >{ t('common.查询') }</Button>
        </Col>
      </Row> */}
    </CardHead>
    { isExpand && <div>
      <Row gutter={10} align='middle' style={{margin: '20px -5px'}}>
        <Col>
          <RangePicker
            style={{ width: '220px' }}
            allowClear={false}
            allowEmpty={[false, false]}
            showToday={false}
            placeholder={[ze('日期范围', 'Time Range')]}
            value={[moment(minDate), moment(maxDate)]}
            onChange={(dates) => {
              const [minDate, maxDate] = (dates || [null, null]).map(x => x && x.format('YYYY-MM-DD'));
              setMinDate(minDate);
              setMaxDate(maxDate);
            }}
          />
        </Col>
        <Col>
          <Select
            style={{ width: '200px' }}
            options={SummaryVmOptions}
            value={vmStatus}
            onChange={v => setVmStatus(v)}
          />
        </Col>
        <Col>
          <Button
            type='primary'
            onClick={() => fetchTrend()}
          >{ t('common.查询') }</Button>
        </Col>
      </Row>
      <Table
        size='small'
        columns={columns}
        dataSource={tableData}
        pagination={{
          defaultPageSize: 5,
          pageSizeOptions: [5, 10, 20, 50, 100],
        }}
      />
    </div>}
  </Spin>;
}

StatByIntegratedApp.propTypes = {
  reduxState: PropTypes.object,
  actions: PropTypes.object,
};

const mapStateToProps = state => ({
  reduxState: state,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    // ...productActions,
    // ...appActions,
    // ...globalActions,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(StatByIntegratedApp);
