export const SubAppType = Object.freeze({
  UNITY: 'UNITY',
  UE4: 'UE4',
  UE5: 'UE5',
  OTHER: 'OTHER',
});

export const SubAppTypeUtil = Object.freeze({
  isUnity(subAppType) {
    return subAppType === SubAppType.UNITY;
  },
  isUnreal(subAppType) {
    return subAppType === SubAppType.UE4 || subAppType === SubAppType.UE5;
  },
});
