import RestHelper from './RestHelper';

export default RestHelper.publish({
  issues: {
    list: {
      method: 'post',
      uri: '/api/issue/queryIssueList',
    },
    download: {
      method: 'post',
      uri: '/api/issue/queryIssueList',
    },
  },
  issueOptions: {
    get: {
      method: 'get',
      uri: '/appId/{appId}/platformId/{platformId}/selInfos',
    },
  },
  issue: {
    changeState: {
      method: 'post',
      uri: '/redir/api/issue/updateIssue',
    },
  },

  issueInfo: {
    getIssueInfo: {
      method: 'get',
      uri: '/issueInfo/appId/{appId}/platformId/{pid}/issueId/{issueId}',
    },
    getIssueInfoFromShareLink: {
      method: 'post',
      uri: '/api/issue/getIssueInfoFromShareLink',
    },
  },

  lastCrashDoc: {
    getCrashDocFromShareLink: {
      method: 'post',
      uri: '/api/crash/getCrashDocFromShareLink',
    },
  },

  crashAttachment: {
    getCrashAttachment: {
      method: 'get',
      uri: '/appDetailCrash/appId/{appId}/platformId/{pid}/crashHash/{crashHash}',
    },
    getCrashAttachmentFromShareLink: {
      method: 'post',
      uri: '/api/crash/getCrashAttachmentFromShareLink',
    },
    reloadCrashAttachment: {
      method: 'get',
      uri: '重还原的信息尽量与前面的getCrashattch的数据信息一样吧',
    },
  },

  noteList: {
    get: {
      method: 'get',
      uri: '/noteList/appId/{appId}/platformId/{pid}/issueId/{issueId}',
    },
    add: {
      method: 'post',
      uri: '/addIssueNote',
    },
  },

  shortUrl: {
    get: {
      method: 'get',
      uri: '/shortUrl/appId/{appId}/platformId/{pid}/issueId/{issueId}/category/{category}',
    },
  },

  tag: {
    add: {
      method: 'post',
      uri: '/addTag',
    },
    del: {
      method: 'get',
      uri: '/deleteTag/appId/{appId}/platformId/{pid}/issueId/{issueId}/tagId/{tagId}',
    },
    update: {
      method: 'post',
      uri: '/updateTag',
    },
  },
  tags: {
    get: {
      method: 'get',
      uri: '/tagList/appId/{appId}/platformId/{pid}/issueId/-1',
    },
  },

  getSelector: {
    get: {
      method: 'get',
      uri: '/getSelector/appId/{appId}/platformId/{platformId}',
    },
  },

  ignoreSymbol: {
    post: {
      method: 'post',
      uri: '/ignoreSymbol/appId/{appId}/platformId/{pid}',
    },
  },

  tapd: {
    submit: {
      method: 'post',
      uri: '/tapd/submit',
    },
    id: {
      method: 'get',
      uri: '/tapd/workspace-id?appId={appId}&pid={pid}',
    },
    tapdUsers: {
      method: 'get',
      uri: '/tapd/tapdUsers?appId={appId}&pid={pid}&tapdId={tapdId}',
    },
    saveTapdId: {
      method: 'post',
      uri: '/tapd/save',
    },
    delete: {
      method: 'delete',
      uri: '/tapd/delete',
    },
  },
});
