import React, { Component } from 'react';
import PropTypes from 'prop-types';
import hoistNonReactStatic from 'hoist-non-react-statics';
import shouldComponentUpdate from './shouldComponentUpdate';
import toggleImmState from './toggleImmState';

export default function PureRender(Target) {
  class Wrapper extends Component {
    static propTypes = {
      children: PropTypes.object,
    }

    render() {
      return React.createElement(Target, this.props, this.props.children);
    }
  }

  Target.prototype.shouldComponentUpdate = shouldComponentUpdate;
  Target.prototype.setImmState = function (updater, callback) {
    return this.setState(({ data }) => ({ data: updater(data) }), callback);
  };
  Target.prototype.toggleImmState = toggleImmState;

  return hoistNonReactStatic(Wrapper, Target);
}
