/**
 * Created by ellis on 24/11/2016.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Pager } from 'components/commons';
import { formatNum } from 'utils/helper';
import style from './style.scss';
import { withTranslation } from 'react-i18next';
import NoJumpNextPagination from 'components/antd-extension/NoJumpNextPagination.jsx';
import { getBackendInjectEnvs } from 'utils/backend-inject-envs';
import { SymbolFileTypeUtil } from 'utils/constants/symbol-file-type-enum';
import { useSelector } from 'react-redux';
import { selectFormatWithAppTimeZone } from 'utils/selectors/selectors';
import { useConvertTimeToAppTimeZone } from 'utils/react-hooks/crashsight-hooks';
import { isMobile } from 'utils/platform';
import DownloadIcon from 'svg/v2/newcs_dashboard_download_icon_normal.svg';
import CopyreportIcon from 'svg/v2/newcs_dashboard_crashanalysis_issuedetail_copyreport_icon_normal.svg';

const ListBody = ({
  pid,
  selectedType,
  uploadHandler, download, handlePageClick, symbolList, pageData, upperStyle, t, i18n
}) => {
  const convertTimeToAppTimeZone = useConvertTimeToAppTimeZone();
  const copyContent = async (content) => {
    try {
      await navigator.clipboard.writeText(content);
    } catch (e) {
      console.log('error',e)
    }
  };
  function renderList(symbols) {
    return symbols.list.map((element, index) => {
      const { uploadedFilename, symbolPath, soName, uuid, symbolMd5 } = element;
      const fileCosKey = String(symbolPath || '').split('/').pop();
      const downloadUrl = `${getBackendInjectEnvs().cosDownloadOrigin}/${fileCosKey}`;

      const lastOne = index === symbols.list.length - 1;
      const bottom = !lastOne ? style.bottom : '';
      let statusName = '';
      let symbolFileName = <div className={style.successFileName} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
        { downloadUrl &&<span style={{marginRight:'4px'}}>{<DownloadIcon width="14" height="12" viewBox="0 0 16 14"></DownloadIcon>}</span>}
        { downloadUrl && <a href={downloadUrl} download>{soName || uploadedFilename || fileCosKey}</a> }
        { soName && <span style={{ marginLeft: '4px' }}>({ uploadedFilename })</span> }
      </div>;
      switch (element.status) {
        case 0:
          statusName = t('SYMBOLMANAGE.statusNoUpload');
          symbolFileName = (
            <a
              onClick={(e) => {
                e.preventDefault();
                uploadHandler(element.uuid);
              }}>
              {t("SYMBOLMANAGE.noUploadTip")}
            </a>
          );
          break;
        case 1:
          statusName = t('SYMBOLMANAGE.statusProcessing');
          break;
        case 2:
          statusName = t('SYMBOLMANAGE.statusProcessed');
          break;
        case -1:
          statusName = (
            <span className={style.warn}>{ t("SYMBOLMANAGE.illegalFile") }</span>
          );
          symbolFileName = (
                <div className={style.highlight}>
                  { uploadedFilename }
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      uploadHandler(element.uuid);
                    }}>
                    {t("SYMBOLMANAGE.reUpload")}
                  </a>
                </div>
          );
          break;
        default:
          statusName = '';
      }

      const channel = element.channel !== '-1' ? element.channel : t('SYMBOLMANAGE.allChannel');
      return (
            <ul key={index} className={lastOne ? classnames(style.list_item, style.bottom) : style.list_item}>
              {index === 0
                ? (
                  <li
                    className={classnames(upperStyle.others_msg, upperStyle.first)}
                    title={symbols.version ? symbols.version : t("SYMBOLMANAGE.noPostException")}>
                    {symbols.version ? symbols.version : t("SYMBOLMANAGE.noPostException")}
                  </li>
                )
                : <li className={classnames(upperStyle.others_msg, upperStyle.first)}> </li>}
              <li className={classnames(upperStyle.others_msg, upperStyle.second, bottom)}>
                <div style={{display:'flex',flexDirection:'row',gap:'4px'}}>
                  <li style={{flex:'1',padding:'0',maxWidth:'max-content'}} className={classnames(upperStyle.others_msg, upperStyle.second, bottom)}>
                    { SymbolFileTypeUtil.isMapping(selectedType) ? channel : uuid }
                  </li>
                  { !SymbolFileTypeUtil.isMapping(selectedType) && <div className={style.copy_icon} onClick={() => copyContent(uuid)}><CopyreportIcon /></div>}
                </div>

              </li>
              <li className={classnames(upperStyle.others_msg, upperStyle.md5, bottom)}>
                { symbolMd5 }
              </li>

              <li className={classnames(upperStyle.others_msg, upperStyle.forth, bottom)}>
                {symbolFileName}
              </li>

              <li className={classnames(upperStyle.others_msg, upperStyle.fifth, bottom)}>
                { convertTimeToAppTimeZone(element.uploadTime) }
              </li>

              <li className={classnames(upperStyle.last_report, upperStyle.sixth, bottom)}>
                {statusName}
              </li>
            </ul>
      );
    });
  }

  function getPager() {
    const remainder = pageData.offset % pageData.size;
    let page = parseInt(pageData.offset / pageData.size) - 1;
    page = remainder > 0 ? page + 1 : page;
    page < 0 && (page = 0);
    return (
      <div
        className={style.pager}
        style={{ display:'flex',justifyContent:'flex-end',paddingRight:'80px', marginTop: '16px' }}
      >
        <NoJumpNextPagination
          current={pageData.offset.selected ? pageData.offset.selected+1 : 1}
          pageSize={1}
          total={pageData.total}
          onChange={(page,pageSize)=>handlePageClick({selected:page-1})}
          showSizeChanger={false}
        />
      </div>
    );
  }

  return (
    <div className={style.symbol_list}>
      {symbolList.length
        ? [symbolList.map((item) => renderList(item)), getPager()]
        : <div className={style.empty_tip}>{t("SYMBOLMANAGE.noRecord")}</div>}
    </div>
  );
};

ListBody.propTypes = {
  uploadHandler: PropTypes.func.isRequired,
  download: PropTypes.func.isRequired,
  handlePageClick: PropTypes.func.isRequired,
  symbolList: PropTypes.object.isRequired,
  pageData: PropTypes.object.isRequired,
  upperStyle: PropTypes.object.isRequired,
};

export default withTranslation()(ListBody);
