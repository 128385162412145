import React, { useState } from 'react';
import { Input, Modal, Select } from 'antd';
import { FullscreenExitOutlined } from '@ant-design/icons';

/**
 * @deprecated
 */
const EnlargeableInput = ({
  value,
  onChange,
  description,
  style,
  ...props
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [editingValue, setEditingValue] = useState();

  function onClickEnlarge() {
    setEditingValue(value);
    setModalVisible(true);
  }

  return <Input
    {...props}
    style={style}
    value={value}
    onChange={e => onChange(e.target.value)}
    addonAfter={<div>
      <FullscreenExitOutlined
        onClick={onClickEnlarge}
      />
      <Modal
        title='编辑'
        visible={modalVisible}
        onCancel={() => { setModalVisible(false) }}
        onOk={() => {
          onChange(editingValue);
          setModalVisible(false);
        }}
      >
        { description && <div>{ description }</div> }
        <Input.TextArea
          style={{ height: 'max(100px, 60vh)' }}
          value={editingValue}
          onChange={e => setEditingValue(e.target.value)}
        />
      </Modal>
    </div>}
  />;
};

export default EnlargeableInput;
