export const DetailStackMemoryInfoUtil = Object.freeze({
  /** 获取崩溃时候执行的汇编 */
  getCrashedAsmLine(detailStackMemoryInfoList) {
    const detailStackMemoryInfo = detailStackMemoryInfoList?.[0];
    const {
      lineNumber,
      lineStack,
      stackMemoryInfo,
      asmCodeInfo,
    } = detailStackMemoryInfo || {};

    if (!asmCodeInfo) {
      return '';
    }
    const crashedAsmLineWithAddress = asmCodeInfo.split('\n')
      .map(x => x.trim())
      .filter(x => x)
      .pop() || '';
    return crashedAsmLineWithAddress.replace(/^0x[0-9a-fA-F]+:/, '').trim();
  }
});
