import React from 'react';
import PropTypes from 'prop-types';
import { ISSUE_STATUS_VALUE_TO_LABEL } from 'utils/constants';
import { findNickName } from 'utils/helper';
import { ze } from 'utils/zhEn';
import { IssueStatus } from 'utils/constants/issue-status';

const PROCESSOR_SYSTEM = 'SYSTEM';

const TimelineItem = ({ noteItem, style, members }) => {
  const {
    createTime, newUserId: localUserId, note, issueStatus, processors,
  } = noteItem;

  const isProcessedBySystem = localUserId === PROCESSOR_SYSTEM;

  const memberList = members && members.size && members.get('options').toJS();
  const localUser = memberList && memberList.find(x => x.localUserId === localUserId);
  const localUserName = localUser ? localUser.label : localUserId;

  function renderNote(localUserId, status) {
    if (parseInt(status) === 3) { // 添加备注
      return (
        <div className={style.info}>
          {localUserName}
          {' '}
          {ze('添加备注', 'Add Comment')}
        </div>
      );
    } else { // 状态变更,需要区分处理人和操作人
      return (
        <div className={style.info}>
          {ze('状态变更为: ', 'Issue Status Changed: ')}
          { !isProcessedBySystem && localUserName && memberList && findNickName(memberList, processors)}
          {' '}
          {ISSUE_STATUS_VALUE_TO_LABEL[status]}
          {' '}
          <span
            className={style.man}
          >
            { !isProcessedBySystem && ze(`(操作人: ${localUserName})`, `(Operator: ${localUserName})`) }
            { isProcessedBySystem && ze('(系统自动操作)', '(Operated By System)') }
          </span>
        </div>
      );
    }
  }

  // 获取不同状态下的小logo
  function getClassNames() {
    switch (parseInt(issueStatus)) {
      case 0:// 没处理
        return `${style.status} ${style.status_waiting}`;
      case 1:// 已处理
        return `${style.status} ${style.status_right}`;
      case 2:// 处理中
        return `${style.status} ${style.status_keyboard}`;
      case 3:// 添加备注
        return `${style.status} ${style.status_message}`;
      case IssueStatus.REOPENED:
        return `${style.status} ${style.status_reopened}`;
      default:
        return `${style.status} ${style.status_right}`;
    }
  }

  let comment = note;
  if (note && isProcessedBySystem && issueStatus === IssueStatus.REOPENED) {
    try {
      const { version } = JSON.parse(note);
      if (version) {
        comment = ze(`问题在新版本 ${version} 复现，状态由“已处理”自动变更为“重新打开”`, `The issue reappeared in the new version ${version}, and the status automatically changed from "Resolved" to "Reopened"`);
      }
    } catch (e) {
      console.error(`TimelineItem: parse note failed, note = ${note}`, e);
    }
  }

  return (
    <li className={getClassNames()}>
      <div className={style.time}>{createTime}</div>
      {renderNote(localUserId, issueStatus)}
      { !!comment && <div className={style.bubble}>{comment}</div>}
    </li>
  );
};

TimelineItem.propTypes = {
  noteItem: PropTypes.object,
  style: PropTypes.object,
  members: PropTypes.object,
};
export default TimelineItem;
