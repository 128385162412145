import { createAction } from 'redux-actions';
import globalRest from 'utils/globalRest';
import userSummaryRest from 'utils/userSummaryRest';
import { getStartEndDateFromStr } from 'utils/StringUtils';

export const updateSearchParams = createAction('UPDATE_SEARCH_PARAMS');

export const changeCurTab = createAction('CHANGE_CURTAB');

export const changeShowDetail = createAction('CHANGE_SHOW_DETAIL');

// 实时或累计状态
export const changeUserSummaryDataType = createAction('CHANGE_USER_SUMMARY_DATA_TYPE');

export function getOptions(types) {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    return dispatch({
      rest: globalRest.getSelector.get,
      data: {
        appId,
        pid,
        types,
      },
    });
  };
}

export function getOperateSummary() {
  return (dispatch, getState) => {
    const currentApp = getState().app.get('current');
    if (!currentApp || !currentApp.toJS) {
      console.log('fail to get currentApp');
      return;
    }
    const { appId, pid } = currentApp.toJS();
    dispatch({
      rest: userSummaryRest.getOperateSummary.get,
      data: {
        appId,
        pid,
      },
    });
  };
}

export function getOperateTrend(dataType) {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    const { version, channel, date } = getState().userSummary.get('searchParams').toJS();
    const dateObj = getStartEndDateFromStr(date.date, date.startDate, date.endDate);
    let startHour = dateObj.startDate;
    let endHour = dateObj.endDate;
    if (dateObj.len < 1) {
      startHour += '00';
      endHour += '23';
    }

    return dispatch({
      rest: (dataType === 'appendly' && dateObj.len < 1) ? userSummaryRest.getOperateRealtimeAppendTrend.get : userSummaryRest.getOperateTrend.get,
      data: {
        appId,
        pid,
        version,
        channel: date.date === 'today' ? '-1' : channel,
        startHour,
        endHour,
      },
    });
  };
}

export function getRankData(date, dataType, userType) {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    return dispatch({
      rest: userSummaryRest.getOperateRank.get,
      data: {
        appId,
        pid,
        date,
        type: dataType,
        userType: `${userType}User`,
        limit: 5,
      },
    });
  };
}

export const changeRankTag = createAction('RANKTAG_CHANGE');
