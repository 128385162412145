import React, { useEffect, useState } from 'react';
import { Button, Col, Divider, Input, message, Modal, notification, Row, Space, Spin } from 'antd';
import { ze } from 'utils/zhEn';
import RestHelper from 'utils/RestHelper';
import { isNotNullish } from 'utils/nullish';
import style from './style.scss';
import { CopyOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import NoticeIcon from 'svg/v2/newcs_dashboard_notice_icon.svg';
import CopyreportIcon from 'svg/v2/newcs_dashboard_crashanalysis_issuedetail_copyreport_icon_normal.svg';
import ManageopenapikeyIcon from 'svg/v2/newcs_dashboard_personalinfo_manageopenapikey_icon.svg';

const UserOpenapiKeyInfo = ({ currentUser, actions }) => {
  const { userOpenapiKey, newUserId } = currentUser || {};
  const [ resetKey, setResetKey ] = useState(false)
  const isInitDone = isNotNullish(newUserId);

  const [loading, setLoading] = useState(false);

  async function generateUserOpenapiKey(isResetOldKey) {
    setLoading(true);
    await RestHelper.post('/api/user/generateUserOpenapiKey');
    await actions.actionGetUserInfo();
    setLoading(false);
    const msg = isResetOldKey
      ? ze('密钥重置成功', 'Successfully resetted your OpenAPI key.')
      : ze('密钥生成成功', 'Successfully generated your OpenAPI key.')
    message.success(msg);
  }

  async function copyToClipboard(text) {
    try {
      await navigator.clipboard.writeText(text);
      message.success(ze('已复制至剪贴板', 'Copied to clipboard.'));
    } catch (e) {
      notification.error({ message: 'Failed to copy text to clipboard.' });
    }
  }

  function onClickResetKey() {
    setResetKey(false);
    generateUserOpenapiKey(true);
  }

  if (!isInitDone) {
    return null;
  }

  return <div><Spin spinning={loading}>
    <div className={style.openApiTop}>
      <ManageopenapikeyIcon width="14" height="14" viewBox="0 0 14 14" />
      <div>{ze('OpenAPI密钥管理', 'OpenAPI Key')}</div>
    </div>
    <div className={style.openApiTitle}>
      { ze('用户使用OpenAPI时，需要用到密钥进行用户鉴权。', 'The OpenAPI key is used to authenticate user when using OpenAPI.') }
    </div>
    <div className={style.openApiBody}>
      {!userOpenapiKey &&  <div className={style.generateTitle}>
        <div>{ ze('你还没有生成过OpenAPI密钥。', 'You have not generated an OpenAPI key yet.') }</div>
        <Button
          className={style.generateBottom}
          onClick={() => generateUserOpenapiKey(false)}
        >{ ze('生成密钥', 'Generate Key') }</Button>
      </div> }
      { userOpenapiKey && <div>
        <div style={{fontSize:'14px'}}>
          <div>
            { ze('你的OpenAPI密钥（点击可自动复制到剪贴板）', 'Your OpenAPI key (Click to copy the key to your clipboard)') }
          </div>
          <div>
            { ze('请保管好你的密钥，不要泄露给他人，以免被盗用身份进行OpenAPI调用。', 'Please keep your key secure and do not disclose it to others to prevent unauthorized individuals from using your identity to make OpenAPI calls.') }
          </div>
        </div>
        <div className={style.userOpenApiKeyInput}>
          <Input
            value={userOpenapiKey}
            readOnly
            suffix={
              <CopyreportIcon onClick={() => copyToClipboard(userOpenapiKey)} style={{cursor: 'pointer'}}/>
            }
          />
          <Button
            onClick={() => {setResetKey(true)}}
            className={style.userOpenApiKeyReset}
          >{ ze('重置', 'Reset') }</Button>
        </div>
      </div> }
      <Modal title={ze('警告', 'Warning')} open={resetKey} onOk={() => onClickResetKey()} onCancel={() => {setResetKey(false)}}>
        <div style={{display:'flex',gap:'4px',lineHeight:'14px'}}>
          <div style={{transform:'rotate(180deg)',height:'14px'}}>
            {<NoticeIcon />}
          </div>
          <div>
            {ze('是否确认要重置密钥？重置后旧的密钥将会失效，使用了旧密钥的OpenAPI调用将会鉴权失败。', 'Do you confirm that you want to reset the key? After resetting, the old key will become invalid, and OpenAPI calls using the old key will fail authentication.')}
          </div>
        </div>
      </Modal>
    </div>
  </Spin></div>;
};
export default UserOpenapiKeyInfo;
