import { createAction } from 'redux-actions';
import { getStartEndDateFromStr } from 'utils/StringUtils';
import userVersionRest from 'utils/userVersionRest';
import globalRest from 'utils/globalRest';

export const updateSearchParams = createAction('UPDATE_VERSION_PARAMS');// 更新搜索参数

export const updatePageNum = createAction('UPDATE_PAEG_NUM');// 更新页数

export const changeCurTab = createAction('CHANGE_CURTAB');// 更新tab


export function getOptions(types) {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    return dispatch({
      rest: globalRest.getSelector.get,
      data: {
        appId,
        pid,
        types,
      },
    });
  };
}


export function getVersionTrend() {
  return (dispatch, getState) => {
    const { userVersion, app } = getState();
    const { appId, pid } = app.get('current').toJS();
    const { channel, date } = userVersion.get('searchParams').toJS();
    const dateObj = getStartEndDateFromStr(date.date, date.startDate, date.endDate);
    if (appId && pid && channel && dateObj && dateObj.startDate && dateObj.endDate) {
      return dispatch({
        rest: userVersionRest.getVersionTrend.get,
        data: {
          appId,
          pid,
          channel: decodeURIComponent(channel),
          startDate: dateObj.startDate,
          endDate: dateObj.endDate,
        },
      });
    }
    return null;
  };
}
