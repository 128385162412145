import { Map, List } from 'immutable';
import { handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'store/actions';
import {
  isEmptyObject, getHoursFillData, getDayFillData, getLastNHour, parseSearch,
} from 'utils/helper';
import moment from 'moment';
import { initialState, initialSearchParams } from './userSummaryInitialState';

export default handleActions({
  [LOCATION_CHANGE]: (state, action) => {
    const { location } = action.payload;
    const { pathname } = location;
    const query = parseSearch(location);
    if (pathname.match(/analytics\/dashboard/i)) {
      const newParams = initialSearchParams.toJS();
      if (query.date) {
        newParams.date.date = query.date;
        newParams.date.startDate = query.startDate;
        newParams.date.endDate = query.endDate;
      }
      query.version && (newParams.version = query.version);
      query.channel && (newParams.channel = query.channel);
      query.dataType && (newParams.dataType = query.dataType);
      return state.set('searchParams', new Map(newParams));
    }
    return state;
  },
  GETSELECTOR_GET_SUCC: (state, action) => {
    const { channelList = [], versionList = [] } = action.response;
    return state.update('selectOptions', (options) => options.merge({
      version: {
        options: new List(versionList).map((item) => ({
          label: (parseInt(item.enable) === 0 ? (`${item.name}已关闭`) : item.name),
          value: encodeURIComponent(item.name),
          sdkVersion: item.sdkVersion,
        })).unshift({
          label: '全版本',
          value: '-1',
          sdkVersion: '',
        }),
      },
      channelId: {
        options: new List(channelList).map((item) => ({ label: item, value: encodeURIComponent(item) })).unshift({
          label: '全渠道',
          value: '-1',
        }),
      },
    }));
  },
  GETOPERATESUMMARY_GET_SUCC: (state, action) => state.set('operateSummary', action.response.data),

  UPDATE_SEARCH_PARAMS: (state, action) => state.update('searchParams', (params) => {
    const { type, value } = action.payload;
    return params.set(type, value);
  }),

  CHANGE_CURTAB: (state, action) => state.set('currentTab', action.payload),

  GETOPERATETREND_GET_SUCC: (state, action) => {
    const { data } = action.response;
    if (!data || isEmptyObject(data)) {
      return state;
    }
    const { crossDayFlag, compareList, dataList } = data;
    if (parseInt(crossDayFlag) === 1) {
      // 为datalist 补齐23小时数据
      const fillData = getHoursFillData(dataList);
      return state.set('trendData', Object.assign({}, data, { dataList: fillData }));
    } else {
      const sortedCompareList = compareList.reverse();
      const sortedDataList = dataList.reverse();

      const { startHour, endHour } = action.params;
      const days = moment(endHour).diff(moment(startHour), 'days') + 1;
      const fileDayData = getDayFillData(sortedDataList, action.params);
      const fileCompareDayData = getDayFillData(sortedCompareList, Object.assign({}, action.params, {
        startHour: getLastNHour(startHour, days),
        endHour: getLastNHour(endHour, days),
      }));
      const sortedData = Object.assign({}, data, {
        compareList: fileCompareDayData,
        dataList: fileDayData,
      });
      return state.set('trendData', sortedData);
    }
  },

  GETOPERATEREALTIMEAPPENDTREND_GET_SUCC: (state, action) => {
    const { data } = action.response;
    if (!data || isEmptyObject(data)) {
      return state;
    }
    const fillData = getHoursFillData(data.dataList);
    return state.set('trendData', Object.assign({}, data, { dataList: fillData }));
  },

  CHANGE_SHOW_DETAIL: (state, action) => state.set('isShowDetail', action.payload),

  // 'CHANGE_USER_SUMMARY_DATA_TYPE': (state, action) => state.set('dataType', action.payload),

  GETOPERATERANK_GET_SUCC: (state, action) => {
    console.log(`rank${action.params.type}Data`, action.response);
    return state.set(`rank${action.params.type}Data`, action.response.data);
  },

  RANKTAG_CHANGE: (state, action) => {
    console.log(`ranktag change ${action.payload.type} ${action.payload.value}`);
    return state.update('searchParams', (params) => params.set(`rank${action.payload.type}`, action.payload.value));
  },
}, initialState);
