import RestHelper from './RestHelper';
export default RestHelper.publish({
  sdkSummary: {
    summaryInfo: {
      method: 'get',
      uri: '/sdkOperateStat/pid/{pid}/appId/{appId}/getOperateSummary',
    },
    sdkList: {
      method: 'get',
      uri: '/sdkOperateStat/pid/{pid}/appId/{appId}/getOperateTrend',
    },
    detailInfo: {
      method: 'get',
      uri: '/sdkOperateStat/pid/{pid}/appId/{appId}/getOperateDetail',
    },
    sdkVersions: {
      method: 'get',
      uri: '/sdkOperateStat/pid/{pid}/appId/{appId}/versions',
    },
    topVersions: {
      method: 'get',
      uri: '/sdkOperateStat/pid/{pid}/appId/{appId}/top-use-app-versions',
    },
  },
  sdkCrash: {
    crashSdkList: {
      method: 'get',
      uri: '/sdkOperateStat/pid/{pid}/appId/{appId}/getCrashTrend',
    },
    topCrashVersions: {
      method: 'get',
      uri: '/sdkOperateStat/pid/{pid}/appId/{appId}/crash-top-crash-user-versions',
    },
  },
  sdkApp: {
    sdkAppBundles: {
      method: 'get',
      uri: '/sdkOperateStat/pid/{pid}/appId/{appId}/bundles',
    },
    sdkAppList: {
      method: 'get',
      uri: '/sdkOperateStat/pid/{pid}/appId/{appId}/getAppsTrend',
    },
    topApp: {
      method: 'get',
      uri: '/sdkOperateStat/pid/{pid}/appId/{appId}/crash-top-crash-user-apps',
    },
    hasPriority: {
      method: 'get',
      uri: '/sdkOperateStat/pid/{pid}/appId/{appId}/priority',
    },
  },
});
