import React, { useState, useEffect, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { TagList } from 'components/commons';
import { IssueListTrendTypeEnum } from 'utils/constants';
import {
  formatNum, makeDetailObjectFromStackLine, highlight, getStatus, getIssueDetailUrlPrefix,
  convertIssueAssigneeListToDisplayText,
} from 'utils/helper';
import _style from './style.scss';
import { Translation, useTranslation } from 'react-i18next';
import * as platformUtils from 'utils/platform';
import { Button, Tag, Row, Col, Popconfirm, message, Tooltip, Space, Checkbox } from 'antd';
import { CheckCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import i18n from 'i18n.js';
import { blue, green, grey, red } from '@ant-design/colors';
import { getIsIframe } from 'utils/iframe';
import IssueCrashPreview from 'components/exception/IssueCrashPreview';
import { connect } from 'react-redux';
import RestHelper from 'utils/RestHelper';
import IssueTrendBarChart from 'components/exception/BaseProblemPage/IssueItem/IssueTrendBarChart';
import IssueTapdBugStatusEx from 'components/exception/BaseProblemPage/IssueItem/IssueTapdBugStatusEx';
import { ze } from 'utils/zhEn';
import { DemoUtil } from 'utils/demo-util';
import moment from 'moment';
import { TimeUtil } from 'utils/time-util';
import { CrashUtil } from 'utils/api/crash';
import reportEvent, { EVENT_ACTIONS, getPageTitle, getExceptionTypeReportName } from 'utils/reportEvent';
import ExceptionsIcon from 'svg/v2/newcs_dashboard_overview_dailytopissue_list_yichangname_icon.svg';
import IssueIcon from 'svg/v2/newcs_dashboard_overview_dailytopissue_list_issueid_icon.svg';
import StatusItem from 'components/commons/StatusItem/StatusItem';
import { CS_STYLES } from 'utils/constants/style-constants';

/**
 * 问题列表项视图
 */
const IssueItem = ({
  demoApp, issue, dispatch, handleCheck, style,
  toggleCrashDetail, appId, pid, addTag, delTag, exceptionType, path, memberList, handleChangeIssueStatus,
  isShowUserNum, onClickUpsertTapdBugByIssueIdList, tapdBugIdToStatus,
  reduxState,
  onBugUnbind,
}) => {
  const { t } = useTranslation();
  const { issueStartingVersion } = reduxState.issue.get('searchParams').toJS();
  const issueIdToDailyTrendList = reduxState.issue.get('issueIdToDailyTrendList') || {};
  const issueIdToHourlyTrendList = reduxState.issue.get('issueIdToHourlyTrendList') || {};
  const currentUser = reduxState.user.get('current');
  const isSuper = !!currentUser.get('isSuper');
  const currentApp = reduxState.app.get('current').toJS();
  const { teamType } = currentApp || {};

  const { issueListTrendType } = reduxState.global.get('localStorage').toJS();
  const showIssueTrend = issueListTrendType && issueListTrendType !== IssueListTrendTypeEnum.NONE;

  function checkIssue(checked) {
    handleCheck({ issue, checked, multi: true });
  }

  const [isIssueExpanded, setIsIssueExpanded] = useState(false);

  const {
    status, issueId, checked = false, imeiCount, count, crashNumA = 0, crashNumB = 0,
    exceptionName,
    exceptionMessage, crashDetail, showDetail, keyStack, offsetTop, issueVersions, ftName = '', bugs,
    firstUploadTimestamp,
    latestUploadTimestamp,
    firstCrashVersion,
    assigneeList,
    lastMatchedReport,
    esMap,
  } = issue.toJS();

  const hasAssignee = assigneeList && assigneeList.length > 0;

  const issueDetailUrlPrefix = getIssueDetailUrlPrefix(exceptionType);

  const isOpenIssueInNewTab = !process.env.REACT_APP_IS_WETEST_IFRAME && !getIsIframe();

  const isTapdEnabled = process.env.REACT_APP_USE_TAPD;
  const tapdBugInfo = bugs && bugs[0];
  const bugId = tapdBugInfo != null ? tapdBugInfo.id : null;
  const tapdBugStatusInfo = tapdBugIdToStatus[bugId];
  const tapdBugStatusLoading = tapdBugStatusInfo ? tapdBugStatusInfo.isLoading : false;
  const tapdBugStatusFetched = tapdBugStatusInfo ? tapdBugStatusInfo.isFetched : false;
  const tapdBugStatus = tapdBugStatusInfo ? tapdBugStatusInfo.status : null;

  const expandedIssueDom = isIssueExpanded && <IssueCrashPreview
    appId={appId}
    platformId={pid}
    issueId={issueId}
    exceptionType={exceptionType}
    crashListQueryObj={{}}
    matchReportCount={count}
    showMatchReportCount={false}
    onClose={() => setIsIssueExpanded(false)}
  />;

  const tapdDom = !demoApp && isTapdEnabled && <div style={{ display: 'flex', minWidth: '140px', justifyContent: 'center' }}>{
    tapdBugInfo
      ? (
        tapdBugStatusLoading
          ? <LoadingOutlined/>
          : <IssueTapdBugStatusEx
            currentApp={currentApp}
            issueId={issueId}
            tapdBugInfo={tapdBugInfo}
            tapdBugStatus={tapdBugStatus}
            isStatusFetchFailed={!tapdBugStatusFetched}
            onBugUnbind={() => {
              onBugUnbind && onBugUnbind(issueId);
            }}
            btnStyle={{
              // whiteSpace: 'normal',
              wordWrap: 'break-word',
              height: 'fit-content',
              maxWidth: 120
            }}
          />
      )
      : (<Button
        type='link' style={{ padding: '0', color: CS_STYLES.PRIMARY_COLOR }}
        // className={_style.displayOnHover}
        onClick={() => onClickUpsertTapdBugByIssueIdList([issue.get('issueId')])}>
        {t('issueCrashFilterKey.createBugForm')}
      </Button>)
  }</div>;

  const renderStatusDom = () => {
    return <div
      // className={(assigneeList && assigneeList.length) ? '' : _style.displayOnHover}
    >
      <StatusItem
        assigneeList={assigneeList}
        statusNo={status}
        isOmitted={true}
        handleChangeStatus={() => {
          !DemoUtil.checkAndWarnIfDemoApp(demoApp) && handleChangeIssueStatus
            && handleChangeIssueStatus(issue, assigneeList, hasAssignee ? getStatus(status) : '', memberList);
        }}
      />
    </div>;
  };

  const hasLastMatchedReport = (lastMatchedReport || {}).exists || false;
  const lastMatchedReportRefinedCrashMap = CrashUtil.makeRefinedCrashMap((lastMatchedReport || {}).crashMap);
  const {
    expMessage: lastReportExpMessage,
    firstDefinitiveStackLine,
  } = lastMatchedReportRefinedCrashMap;
  const displayExpMessage = hasLastMatchedReport ? lastReportExpMessage : exceptionMessage;
  const displayKeyStack = hasLastMatchedReport ? firstDefinitiveStackLine : keyStack;

  const lastReportTime = moment(TimeUtil.fixChaosMillis(latestUploadTimestamp)).format('YYYY-MM-DD HH:mm:ss');

  return (
    <div className={_style.listItemContainer}>
      <ul className={`${_style.list_item} ${parseInt(status) === 1 ? _style.disabled : ''}`} style={{ display: 'flex', alignItems: 'center' }}>
        <li className={style.checkbox_container}>
          <Checkbox id={`issue-${issueId}`} checked={checked} onChange={(e) => checkIssue(e.target.checked)}></Checkbox>
        </li>
        <li className="main-content" style={{ flexGrow: '1' }}>
          <Row gutter='15'>
            <Col flex='none'>
              <div style={{ display: 'flex' }}>
                <Link
                  to={`/${issueDetailUrlPrefix}/${appId}/${issueId}?pid=${pid}`}
                  {...(isOpenIssueInNewTab && { target: '_blank' })}
                  rel="noopener noreferrer"
                  onClick={() => {
                    reportEvent({
                      action: EVENT_ACTIONS.CLICK,
                      tp1: `${getExceptionTypeReportName(exceptionType)}点击链接`,
                      tp6: `${window.location.origin}/${issueDetailUrlPrefix}/${appId}/${issueId}?pid=${pid}`,
                    })
                  }}
                >
                  <Space className={_style.error_title} size={4}>
                    {<span style={{ marginTop: -2 }}>{<IssueIcon />}</span>}
                    {
                      <Tooltip arrow={true}
                        placement="top"
                        title={(<div>{ issueId }</div>)}
                      >
                        {<div className={_style.error_title_id}>{issueId}</div>}
                      </Tooltip>
                    }
                    {<span style={{ margin: '-2px 0 0 1px' }}>{<ExceptionsIcon />}</span>}
                    {
                      <Tooltip arrow={true}
                        placement="top"
                        title={(<div>{ exceptionName }</div>)}
                      >
                        {<div className={_style.error_title_type}>{exceptionName}</div>}
                      </Tooltip>
                    }
                  </Space>
                </Link>
              </div>
            </Col>
            <Col className={_style.hover_text}>
              <span
                className={_style.quick_pre_btn}
                onClick={() => {
                  if (!isIssueExpanded) {
                    reportEvent({
                      action: EVENT_ACTIONS.CLICK,
                      tp1: `${getPageTitle()}-快速预览`,
                    });
                  }
                  setIsIssueExpanded(!isIssueExpanded);
                }}
              >{ isIssueExpanded ? t('SENIORSEARCH.收起快速预览') : t('SENIORSEARCH.快速预览') }</span>
              <Link
                className={_style.quick_pre_btn}
                to={`/${issueDetailUrlPrefix}/${appId}/${issueId}?pid=${pid}`}
                {...(isOpenIssueInNewTab && { target: '_blank' })}
                rel="noopener noreferrer"
                onClick={() => {
                  reportEvent({
                    action: EVENT_ACTIONS.CLICK,
                    tp1: `${getPageTitle()}-进入问题详情`,
                    tp6: `/${issueDetailUrlPrefix}/${appId}/${issueId}?pid=${pid}`,
                  });
                }}
              >{t('SENIORSEARCH.进入问题详情')}</Link>
            </Col>
          </Row>

          <div className={_style.error_info}>
            { !hasLastMatchedReport && <div style={{ fontSize: '12px', color: '#AAA', marginRight: '4px' }}>{ ze('未找到最近一条上报，将显示首次上报信息。', 'Last report not found, showing first report instead.') }</div> }
            { displayExpMessage && <div><span style={{ fontSize: '12px', color: '#AAA', marginRight: '4px' }}>{ t('issueCrashFilterKey.crashExpMessage') }</span><span>{ displayExpMessage }</span></div> }
            <div className={_style.less_info}>
              <p className={_style.key_stack} onClick={() => setIsIssueExpanded(!isIssueExpanded)}>
                <Tooltip title={ze('用于问题分类的第一行堆栈。', 'The top of the stack trace used for issue grouping.')}>
                  <span style={{ fontSize: '12px', color: '#AAA', marginRight: '4px' }}>{t('REPORTDETAIL.首行关键堆栈')}</span>
                  <span>{displayKeyStack || ''}</span>
                </Tooltip>
              </p>
            </div>
          </div>

          {
            <div>
              <TagList
                tags={issue.get('tagInfoList')}
                {...{
                  dispatch, addTag, delTag, issueId, path, demoApp, issueVersions, ftName, appId, pid,
                  firstUploadTimestamp, latestUploadTimestamp, mergeIssueType: esMap?.mergeIssueType,
                  firstCrashVersion: issueStartingVersion ? firstCrashVersion : null, // 搜索条件带首次版本的时候tag里才显示
                }} />
            </div>
          }
        </li>
        { showIssueTrend && <li style={{ width: '180px', height: '50px', marginRight: '20px', alignSelf: 'center' }}>
          <IssueTrendBarChart
            trendType={issueListTrendType}
            style={{ height: '60px' }}
            dailyTrendList={issueIdToDailyTrendList[issueId]}
            hourlyTrendList={issueIdToHourlyTrendList[issueId]}
          />
        </li> }
        <li className={style.last_report}><p style={{ marginBottom: 0, whiteSpace: 'pre-wrap', textAlign: 'center' }}>{`${lastReportTime.split(' ')[0]}`}<br />{`${lastReportTime.split(' ')[1]}`}</p></li>
        <li className={`${style.others_msg} ${_style.doneMsg}`}>{count >= 0 ? formatNum(count) : 0}</li>
        {
          isShowUserNum
            ? <li className={`${style.others_msg} ${_style.doneMsg}`} style={{ minWidth: 130 }}>{imeiCount >= 0 ? formatNum(imeiCount) : 0}</li>
            : <li className={`${style.others_msg} ${_style.doneMsg}`} style={{ minWidth: 130 }}>-</li>
        }
        <li style={{ minWidth: 140, maxWidth: 140 }}>
          {renderStatusDom()}
          {tapdDom}
        </li>
        {/* <li className={`${style.others_msg} ${_style.doneMsg}`} style={{ minWidth: 140, maxWidth: 140 }}>{ tapdDom }</li> */}
      </ul>
      {isIssueExpanded && <div className={_style.quickPreview}>{expandedIssueDom}</div>}
    </div>
  );
};

IssueItem.propTypes = {
  issue: PropTypes.object.isRequired,
  isShowUserNum: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleCheck: PropTypes.func.isRequired,
  toggleCrashDetail: PropTypes.func,
  addTag: PropTypes.func.isRequired,
  delTag: PropTypes.func.isRequired,
  style: PropTypes.object,
  memberList: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  tapdBugIdToStatus: PropTypes.object,
  demoApp: PropTypes.bool.isRequired,
  handleChangeIssueStatus: PropTypes.func.isRequired,
  appId: PropTypes.any,
  pid: PropTypes.any,
  exceptionType: PropTypes.any,
  path: PropTypes.any,
};

const mapStateToProps = state => ({
  reduxState: state,
});

export default connect(mapStateToProps)(IssueItem);
