import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Col, DatePicker, Input, InputNumber, Row, Select, Space, Tooltip, } from 'antd';
import scss from './IssueCrashFilter.scss';
import { getTreeSelectVersionOptions } from 'utils/getTreeSelectVersionOptions';
import { useTranslation } from 'react-i18next';
import { useImmer } from 'use-immer';
import i18n from 'i18n.js';
import TimePicker from 'components/commons/TimePicker';
import { getDateOption } from 'utils/StringUtils';
import { isNotNullish, isNullish } from 'utils/nullish';
import { isAndroidOrHarmony, isGameConsole, isIos, isMobile, isPcOrLinux } from 'utils/platform';
import moment from 'moment';
import { getCrashStackSearchTooltipDom, } from 'utils/crash-search/convert-search-content';
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import VersionHybridSelect from 'components/commons/VersionHybridSelect';
import DurationRangeInput from 'components/commons/IssueCrashFilter/DurationRangeInput';
import IosDeviceSelect from 'components/commons/IssueCrashFilter/IosDeviceSelect';
import RamSelect from 'components/commons/IssueCrashFilter/RamSelect';
import { ProductSummaryUtil } from 'components/exception/ProductSummaryPage/ProductSummaryUtil';
import CustomKvSearchKey from 'components/commons/IssueCrashFilter/CustomKvSearchKey';
import { connect, useSelector } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import {
  selectCurrentAppDatePickerAvailableMomentRange,
  selectFrontendWebVolatileConfigValueByKey,
} from 'utils/selectors/selectors';
import { FrontendWebVolatileConfigKeys } from 'utils/constants/frontend-web-volatile-config';
import { ze } from 'utils/zhEn';
import { TapdBugStatusOptions } from 'utils/constants';
import EnlargeableInput from 'components/commons/IssueCrashFilter/EnlargeableInput';
import { GlobalResizeObserver } from 'utils/global-resize-observer';
import { FieldName, QueryType } from 'components/commons/IssueCrashFilter/IssueCrashFilterExUtil';
import { ConvertUtil } from 'utils/convert-util';
import { COUNTRY_LIST_OPTIONS } from 'components/exception/ProductSummaryPage/CountrySelect';
import RamGibSelect from 'components/commons/IssueCrashFilter/RamGibSelect';
import { CsSelect } from 'components/antd-extension/CsSelect';
import DropdownAdd from 'svg/v2/newcs_dashboard_dropdown_add.svg';
import OverviewSave from 'svg/v2/newcs_dashboard_selectbox_close_icon_normal.svg';
import WrappedTipsIcon from 'components/antd-extension/WrappedTipsIcon.jsx';

const IssueCrashFilterContext = React.createContext({
  appId: '',
  platformId: 0,
  hideIssueFields: false,
  fieldWhitelist: [],
  fieldBlacklist: [],
  fixedFieldList: [],
  isReadOnly: false,
  isOom: false,
  enableIgameSearchFields: false,
  enableSearchKeyStackSoName: false,
});

const CRASH_SEARCH_INPUT_FIELDS = [
  {
    name: 'issueId',
    valueToSearchCondition: v => ({ field: FieldName.issueId, queryType: QueryType.TERM, term: v }),
  },
  {
    name: 'crashId',
    valueToSearchCondition: v => ({ field: FieldName.crashId, queryType: QueryType.TERM, term: v }),
  },
  {
    name: 'expUid',
    valueToSearchCondition: v => ({ field: FieldName.expUid, queryType: QueryType.TERM, term: v }),
  },
  {
    name: 'contectInfo',
    valueToSearchCondition: (v) => {
      const terms = ConvertUtil.splitAndTrimCommaSeparatedValues(v);
      return { field: FieldName.userId, queryType: QueryType.TERMS_WILDCARD, terms };
    },
  },
  {
    name: 'deviceIdList',
    valueToSearchCondition: v => ({ field: FieldName.deviceId, queryType: QueryType.TERMS, terms: v }),
  },
  {
    name: 'errorType',
    availableByPid: pid => !isGameConsole(pid),
    valueToSearchCondition: v => ({ field: FieldName.errorType, queryType: QueryType.TERM_CONTAINS, term: v }),
  },
  {
    name: 'crashExpMessage',
    valueToSearchCondition: v => ({ field: FieldName.crashExpMessage, queryType: QueryType.TERM_CONTAINS, term: v }),
  },
  {
    name: 'detail',
    defaultAvailable: true,
    inputPrefixByPid: pid => <Tooltip overlayStyle={{ maxWidth: '1000px' }} title={getCrashStackSearchTooltipDom()}>
      <WrappedTipsIcon />
    </Tooltip>,
    valueToSearchCondition: v => ({ field: FieldName.crashDetail, queryType: QueryType.TEXT_LEGACY_SEARCH, text: v }),
  },
  {
    name: 'crashInfos',
    availableByPid: pid => isMobile(pid),
    inputPrefixByPid: pid => <Tooltip overlayStyle={{ maxWidth: '1000px' }} title={getCrashStackSearchTooltipDom()}>
      <WrappedTipsIcon />
    </Tooltip>,
    valueToSearchCondition: v => ({ field: FieldName.crashInfos, queryType: QueryType.TEXT_LEGACY_SEARCH, text: v }),
  },
  {
    name: 'hardware',
    availableByPid: pid => isMobile(pid),
    placeholderByPid: pid => isIos(pid) ? i18n.t('issueCrashFilter.iosHardwarePlaceholder') : '',
    valueToSearchCondition: v => ({ field: FieldName.model, queryType: QueryType.TERM_CONTAINS, term: v }),
  },
  {
    name: 'osVersion',
    availableByPid: pid => !isGameConsole(pid),
    inputPrefixByPid: pid => isPcOrLinux(pid)
      ? <Tooltip title={ i18n.t('issueCrashFilter.noticeCaseSensitiveField') }>
          <WrappedTipsIcon />
        </Tooltip>
      : null,
    valueToSearchCondition: v => ({ field: FieldName.osVersion, queryType: QueryType.TERM_CONTAINS, term: v }),
  },
  {
    name: 'processName',
    availableByPid: pid => isMobile(pid),
    valueToSearchCondition: v => ({ field: FieldName.processName, queryType: QueryType.TERM_WILDCARD, term: v }),
  },
  {
    name: 'threadName',
    availableByPid: pid => isMobile(pid),
    valueToSearchCondition: v => ({ field: FieldName.threadName, queryType: QueryType.TERM_WILDCARD, term: v }),
  },
  { name: 'userSceneTag', oomOnly: true },
  {
    name: 'customLog',
    availableByPid: pid => isMobile(pid),
    inputPrefixByPid: pid => <Tooltip overlayStyle={{ maxWidth: '1000px' }} title={getCrashStackSearchTooltipDom()}>
      <WrappedTipsIcon />
    </Tooltip>,
    valueToSearchCondition: v => ({ field: FieldName.customLog, queryType: QueryType.TEXT_LEGACY_SEARCH, text: v }),
  },
  {
    name: 'systemLog',
    availableByPid: pid => isAndroidOrHarmony(pid),
    inputPrefixByPid: pid => <Tooltip overlayStyle={{ maxWidth: '1000px' }} title={getCrashStackSearchTooltipDom()}>
      <WrappedTipsIcon />
    </Tooltip>,
    valueToSearchCondition: v => ({ field: FieldName.systemLog, queryType: QueryType.TEXT_LEGACY_SEARCH, text: v }),
  },
];

// 从特征分析那边来的特殊过滤字段
const FEATURE_ANALYSIS_HIDDEN_INPUT_FIELDS = [
  { name: 'exceptionCategoryList' },
  { name: 'rawSearchGroupJsonStr' },
];

const CRASH_SEARCH_INPUT_FIELD_NAMES = CRASH_SEARCH_INPUT_FIELDS.map(x => x.name);
const FEATURE_ANALYSIS_HIDDEN_INPUT_FIELD_NAMES = FEATURE_ANALYSIS_HIDDEN_INPUT_FIELDS.map(x => x.name);

export const IssueCrashFilterUtil = {
  /**
   *
   * @param queryDict {Object}
   * @param ignoreDefaultAvailable {boolean}
   * @returns {*}
   */
  makeFilterDataFromQueryDict(queryDict, ignoreDefaultAvailable = false) {
    if (isNullish(queryDict)) {
      return [];
    }

    const res = ALL_FILTER_FIELDS
      .map(x => {
        const { name, queryToValue, queryToNameValueList } = x;
        const hasQuery = typeof x.hasQueryFunc === 'function'
          ? x.hasQueryFunc(queryDict)
          : queryDict.hasOwnProperty(name);
        if (!hasQuery) {
          return x.defaultAvailable && !ignoreDefaultAvailable
            ? { name, value: x.defaultValue }
            : null;
        }
        if (typeof queryToNameValueList === 'function') {
          return queryToNameValueList(queryDict);
        }

        let value = queryDict[name];
        if (typeof queryToValue === 'function') {
          value = queryToValue(queryDict[name], queryDict);
        }
        return { name, value };
      })
      .flat()
      .filter(x => x && x.name);

    return res;
  },

  /**
   * 将旧的IssueCrashFilter的filterData转换为新的IssueCrashFilterEx的SearchConditionGroup里面的Conditions
   * @param filterData {[{ name: string , value: any }]}
   * */
  makeSearchConditionGroupConditionsFromFilterData(filterData) {
    return (filterData || []).map(oneFilter => {
      const {name, value} = oneFilter;
      if (!name || isNullish(value)) {
        return null;
      }
      const filterFieldInfo = ALL_FILTER_FIELDS.find(filter => filter.name === name);
      if (filterFieldInfo && typeof filterFieldInfo.valueToSearchCondition === 'function') {
        try {
          return filterFieldInfo.valueToSearchCondition(value);
        } catch (e) {
          console.error('makeSearchConditionGroupConditionsFromFilterData throws error', e);
          // fallthrough to LEGACY
        }
      }

      return {field: `LEGACY_${name}`, legacyValue: value};
    }).filter(x => x);
  },

  /**
   *
   * @param filterData {[{ name: string , value: any }]}
   */
  makeQueryDictFromFilterData(filterData) {
    // 非自定义关键字
    const nonCustomKeyQueryDict = filterData
      .filter(x => x && x.name && x.name !== 'customKey')
      .reduce((acc, x) => {
        const { name, value } = x;
        const filterFieldInfo = ALL_FILTER_FIELDS.find(filter => filter.name === name);
        if (!filterFieldInfo) {
          return acc;
        }
        const { valueToQuery } = filterFieldInfo;
        return typeof valueToQuery === 'function'
          ? Object.assign(acc, valueToQuery(value))
          : Object.assign(acc, { [name]: value });
      }, {});

    // 自定义关键字
    const customKeyFilters = filterData
      .filter(x => x && x.name && x.name === 'customKey')
      .map(x => {
        if (Array.isArray(x.value)) { // 兼容老格式的value，老格式的value只支持terms类型
          const [ field, terms ]  = x.value || [];
          return { field, queryType: 'terms', terms };
        } else { // 新格式的value和SearchBean的CustomKeyFilter字段一致
          return x.value;
        }
      });
    const customKeyQueryValue = customKeyFilters.length > 0 ? JSON.stringify(customKeyFilters) : null;

    const res = Object.assign(nonCustomKeyQueryDict, { customKeyFilters: customKeyQueryValue });
    return res;
  },
};

/**
 * hasQueryFunc: 如果query参数的name和字段name不一致，需要用这个func判断query中有没有包含这个field
 * valueToSearchCondition: 将IssueCrashFilter的filterData的value转换为IssueCrashFilterEx的SearchConditionGroup里面的一条condition
 */
export const ALL_FILTER_FIELDS = [{
  name: 'version',
  defaultValue: [],
  queryToValue: (v, queryObj) => (v && v !== 'all') ? v.split(';') : [],
  valueToQuery: v => ({ version: v.join(';') }),
  valueToSearchCondition: v => ({ field: FieldName.version, queryType: QueryType.TERMS_WILDCARD, terms: v }),
  defaultAvailable: true,
}, {
  name: 'uploadTime',
  defaultValue: { date: '', startDate: '', endDate: '' },
  hasQueryFunc: (queryObj) => isNotNullish(queryObj['date']),
  queryToValue: (v, queryObj) => {
    return {
      date: queryObj['date'],
      startDate: queryObj['startDateStr'],
      endDate: queryObj['endDateStr'],
    }
  },
  valueToQuery: v => {
    return {
      date: v['date'],
      startDateStr: v['startDate'],
      endDateStr: v['endDate'],
    }
  },
  valueToSearchCondition: (v) => {
    const { date, startDate, endDate } = v;
    if (date === 'custom') {
      const gte = startDate ? moment(startDate).valueOf() : null;
      const lte = endDate ? moment(endDate).valueOf() + ( 24 * 3600000 - 1 ) : null;
      return { field: FieldName.crashUploadTime, queryType: QueryType.RANGE, gte, lte };
    }
    const [_, magnitude, unit] = (date || '').match(/last_(\d+)_([a-z]+)/) || [];
    const multiplier = {
      hour: 3600000,
      day: 3600000 * 24,
      week: 3600000 * 24 * 7,
      month: 3600000 * 24 * 30,
    }[unit];
    if (isNullish(magnitude) || isNullish(multiplier)) {
      return null;
    }

    const gte = multiplier * magnitude;
    return { field: FieldName.crashUploadTime, queryType: QueryType.RANGE_RELATIVE_DATETIME, gte };
  },
  defaultAvailable: true,
}, {
  name: 'crashTime',
  defaultValue: [null, null],
  hasQueryFunc: (queryObj) => isNotNullish(queryObj['crashTimeBeginMillis']) || isNotNullish(queryObj['crashTimeEndMillis']),
  queryToValue: (v, queryObj) => [
    isNotNullish(queryObj['crashTimeBeginMillis']) ? moment(Number(queryObj['crashTimeBeginMillis'])) : null,
    isNotNullish(queryObj['crashTimeEndMillis']) ? moment(Number(queryObj['crashTimeEndMillis'])): null,
  ],
  valueToQuery: v => ({
    crashTimeBeginMillis: v[0] ? +v[0] : null,
    crashTimeEndMillis: v[1] ? +v[1] : null,
  }),
  valueToSearchCondition: (v) => {
    const gte = v[0] ? +v[0] : null;
    const lte = v[1] ? +v[1] : null;
    return { field: FieldName.crashTime, queryType: QueryType.RANGE, gte, lte };
  },
  availableByPid: pid => isMobile(pid),
}, {
  name: 'queryAccessUploadTime',
  defaultValue: [null, null],
  onlyAvailableViaWhitelist: true,
}, {
  name: 'issueFirstUploadTime',
  defaultValue: [null, null],
  hasQueryFunc: (queryObj) => isNotNullish(queryObj['issueFirstUploadTimeBeginMillis']) || isNotNullish(queryObj['issueFirstUploadTimeEndMillis']),
  queryToValue: (v, queryObj) => [
    isNotNullish(queryObj['issueFirstUploadTimeBeginMillis']) ? moment(Number(queryObj['issueFirstUploadTimeBeginMillis'])) : null,
    isNotNullish(queryObj['issueFirstUploadTimeEndMillis']) ? moment(Number(queryObj['issueFirstUploadTimeEndMillis'])): null,
  ],
  valueToQuery: v => ({
    issueFirstUploadTimeBeginMillis: v[0] ? +v[0] : null,
    issueFirstUploadTimeEndMillis: v[1] ? +v[1] : null,
  }),
  valueToSearchCondition: (v) => {
    const gte = v[0] ? +v[0] : null;
    const lte = v[1] ? +v[1] : null;
    return { field: FieldName.issueFirstUploadTime, queryType: QueryType.RANGE, gte, lte };
  },
  availableByPid: pid => isMobile(pid),
  issueOnly: true,
}, {
  name: 'exceptionTypeList',
  defaultValue: [],
  placeholder: i18n.t('exceptionTypeOptions.所有类型'),
  queryToValue: (v, queryObj) => (v && v !== 'all') ? v.split(',') : [],
  valueToQuery: v => ({ exceptionTypeList: v.join(',') }),
  valueToSearchCondition: v => ({ field: FieldName.exceptionType, queryType: QueryType.TERMS, terms: v }),
  issueOnly: true,
}, {
  name: 'tagList',
  defaultValue: { value: [], isMustNot: false },
  placeholder: i18n.t('tagOptions.所有标签'),
  queryToValue: (_, queryObj) => {
    const { tagList, tagListMustNot } = queryObj;
    if (tagList && tagList !== 'all') {
      return {
        value: tagList.split(','),
        isMustNot: (tagListMustNot === 'true' || !!Number(tagListMustNot)),
      };
    }
    return { value: [], isMustNot: false };
  },
  valueToQuery: v => ({
    ...(v.value ? { tagList: v.value.join(',') } : {}),
    ...(v.isMustNot ? { tagListMustNot: v.isMustNot } : {}),
  }),
  valueToSearchCondition: v => ({ field: FieldName.issueTag, queryType: QueryType.TERMS, terms: v.value, not: v.isMustNot }),
  issueOnly: true,
}, {
  name: 'status',
  defaultValue: [],
  placeholder: i18n.t('statusOptions.所有状态'),
  queryToValue: (v, queryObj) => (v && v !== 'all') ? v.split(',') : [],
  valueToQuery: v => ({ status: v.join(',') }),
  valueToSearchCondition: v => ({ field: FieldName.issueStatus, queryType: QueryType.TERMS, terms: v }),
  issueOnly: true,
}, {
  name: 'tapdBugStatus',
  defaultValue: 'all',
  queryToValue: (v, queryObj) => v || 'all',
  valueToQuery: v => ({ tapdBugStatus: v }),
  valueToSearchCondition: v => ({ field: FieldName.tapdBugStatus, queryType: QueryType.TERM, term: v }),
  issueOnly: true,
}, {
  name: 'processor',
  defaultValue: '',
  placeholder: i18n.t('processorOptions.所有处理人'),
  queryToValue: (v, queryObj) => v,
  valueToQuery: v => ({ processor: v }),
  valueToSearchCondition: v => ({ field: FieldName.processor, queryType: QueryType.TERMS, terms: v ? [v] : [] }),
  issueOnly: true,
}, {
  name: 'bundleId',
  defaultValue: '',
  placeholder: i18n.t('bundleOptions.所有应用包名'),
  queryToValue: (v, queryObj) => v,
  valueToQuery: v => ({ bundleId: v }),
  valueToSearchCondition: v => ({ field: FieldName.bundleId, queryType: QueryType.TERMS, terms: v ? [v] : [] }),
}, {
  name: 'channelId',
  defaultValue: '',
  placeholder: i18n.t('channelOptions.全渠道'),
  queryToValue: (v, queryObj) => v,
  valueToQuery: v => ({ channelId: v }),
  valueToSearchCondition: v => ({ field: FieldName.channelId, queryType: QueryType.TERMS, terms: v ? [v] : [] }),
  issueOnly: true,
}, {
  name: 'vmStatus',
  availableByPid: pid => isMobile(pid),
  defaultValue: null,
  placeholder: i18n.t('issueCrashFilter.anyPlaceholder'),
  queryToValue: (v, queryObj) => v,
  valueToQuery: v => ({ vmStatus: v }),
  valueToSearchCondition: v => ({ field: FieldName.vmStatus, queryType: QueryType.TERM, term: v }),
}, {
  name: 'systemExitStatus', // 系统退出
  availableByPid: pid => isMobile(pid),
  defaultValue: null,
  placeholder: i18n.t('issueCrashFilter.anyPlaceholder'),
  queryToValue: (v, queryObj) => v,
  valueToQuery: v => ({ systemExitStatus: v }),
  valueToSearchCondition: v => ({ field: FieldName.systemExitStatus, queryType: QueryType.TERM, term: v }),
}, ...CRASH_SEARCH_INPUT_FIELDS.map(x => ({
  ...x,
  defaultValue: '',
  queryToValue: (v, queryObj) => v,
  valueToQuery: v => ({ [x.name]: v }),
})), {
  name: 'modelList',
  availableByPid: pid => isIos(pid),
  defaultValue: [],
  queryToValue: (v, queryObj) => v,
  valueToQuery: v => ({ modelList: v }),
  valueToSearchCondition: v => ({ field: FieldName.iosModel, queryType: QueryType.TERMS, terms: v }),
}, {
  name: 'customKey',
  defaultValue: { field: '', queryType: '', terms: [], gte: 0, lte: 0 },
  availableByPid: pid => !isGameConsole(pid),
  hasQueryFunc: (queryObj) => isNotNullish(queryObj['customKeyFilters']),
  queryToNameValueList: (queryObj) => {
    try {
      // debugger;
      const customKeyFilters = JSON.parse(queryObj['customKeyFilters']);
      if (!Array.isArray(customKeyFilters)) {
        return [];
      }
      return customKeyFilters.map(x => {
        return {
          name: 'customKey',
          value: {
            field: x.field,
            queryType: x.queryType,
            terms: x.terms,
            gte: x.gte,
            lte: x.lte,
          },
        };
      });
    } catch (e) {
      return [];
    }
  },
  valueToSearchCondition: (v) => {
    if (!v) {
      return null;
    }
    if (Array.isArray(v)) { // 兼容老格式的value，老格式的value只支持terms类型
      const [ field, terms ] = v;
      return { field: FieldName.customKey, subField: field, queryType: QueryType.TERMS, terms };
    } else {
      const { field, queryType, terms, wildcard, gte, lte } = v; // 和SearchBean的CustomKeyFilter字段一致
      if (queryType === 'terms') {
        return { field: FieldName.customKey, subField: field, queryType: QueryType.TERMS, terms };
      } else if (queryType === 'range') {
        return { field: FieldName.customKey, subField: field, queryType: QueryType.RANGE, gte, lte };
      } else if (queryType === 'wildcard') {
        return { field: FieldName.customKey, subField: field, queryType: QueryType.TERM_WILDCARD, term: wildcard };
      } else {
        throw Error('Invalid queryType in customKey value object.');
      }
    }
  },
  // TODO: custom的value to query暂时不太好写，先放到makeQueryDictFromFilterData那边去
}, {
  name: 'country',
  availableByPid: pid => ProductSummaryUtil.getIsRegionalDashboard(pid),
  defaultValue: [],
  queryToValue: (v, query) => v ? v.split(',') : [],
  valueToQuery: v => ({ country: v.join(',') }),
  valueToSearchCondition: v => ({ field: FieldName.countryOrRegion, queryType: QueryType.TERMS, terms: v }),
}, ...FEATURE_ANALYSIS_HIDDEN_INPUT_FIELDS.map(x => ({
  ...x,
  defaultValue: '',
  queryToValue: (v, queryObj) => v,
  valueToQuery: v => ({ [x.name]: v }),
})), {
  oomOnly: true,
  name: 'oomStatus',
  defaultValue: null,
  placeholder: i18n.t('issueCrashFilter.anyPlaceholder'),
  queryToValue: (v, queryObj) => v,
  valueToQuery: v => ({ oomStatus: v }),
}, {
  name: 'elapsedTime',
  availableByPid: pid => !isGameConsole(pid),
  defaultValue: [null, null],
  hasQueryFunc: (queryObj) => isNotNullish(queryObj['elapsedTimeBeginMillis']) || isNotNullish(queryObj['elapsedTimeEndMillis']),
  queryToValue: (v, queryObj) => [
    queryObj['elapsedTimeBeginMillis'],
    queryObj['elapsedTimeEndMillis'],
  ],
  valueToQuery: v => ({
    elapsedTimeBeginMillis: v[0],
    elapsedTimeEndMillis: v[1],
  }),
  valueToSearchCondition: v => ({ field: FieldName.elapsedTime, queryType: QueryType.RANGE, gte: v[0], lte: v[1] }),
}, {
  oomOnly: true,
  name: 'ramRange',
  defaultValue: null,
  queryToValue: (v, queryObj) => v,
  valueToQuery: v => ({ ramRange: v }),
}, {
  oomOnly: true,
  name: 'ramGib',
  defaultValue: [],
  queryToValue: (v, queryObj) => v,
  valueToQuery: v => ({ ramGib: v }),
}, {
  name: 'keyStackSoName',
  defaultValue: [],
  queryToValue: (v, queryObj) => v,
  valueToQuery: v => ({ keyStackSoName: v }),
  valueToSearchCondition: (v) => {
    const terms = ConvertUtil.splitAndTrimCommaSeparatedValues(v);
    return { field: FieldName.keyStackSoName, queryType: QueryType.TERMS, terms };
  },
}, {
  name: 'appSoNameWhitelist',   // iGame定制需求
  defaultValue: { appSoNameWhitelistTextValue: '', appSoNameWhitelistMustNot: true },
  queryToValue: (_, queryObj) => {
    const { appSoNameWhitelist, appSoNameWhitelistMustNot } = queryObj;
    if (appSoNameWhitelist) {
      return {
        appSoNameWhitelistTextValue: appSoNameWhitelist.join(', '),
        appSoNameWhitelistMustNot: !!appSoNameWhitelistMustNot,
      };
    }
    return { appSoNameWhitelistTextValue: '', appSoNameWhitelistMustNot: true };
  },
  valueToQuery: v => ({
    ...(v.appSoNameWhitelistTextValue ? { appSoNameWhitelist: v.appSoNameWhitelistTextValue.split(',').map(x => x.trim()).filter(x => x) } : {}),
    appSoNameWhitelistMustNot: !!v.appSoNameWhitelistMustNot,
  }),
  valueToSearchCondition: (v) => {
    const whitelist = (v.appSoNameWhitelistTextValue || '').split(',').map(x => x.trim()).filter(x => x);
    return { field: FieldName.appSoNameWhitelist, queryType: QueryType.CUSTOM, terms: whitelist, not: !!v.appSoNameWhitelistMustNot };
  }
}];

const NAME_TO_FILTER_FIELD = ALL_FILTER_FIELDS.reduce((acc, x) => {
  return Object.assign(acc, { [x.name]: x });
}, Object.create(null));

const ALL_FILTER_NAMES = ALL_FILTER_FIELDS.map(x => x.name);

const OOM_WHITELIST_FILTER_NAMES = [
  'version',
  'crashTime',
  'uploadTime',
  'deviceIdList',
  'contectInfo',
  'oomStatus',
  'ramGib',
  'modelList',
  'hardware',
  'osVersion',
  'elapsedTime',
  'expUid',
  'userSceneTag',
];

// iGame定制需求，搜索system log, custom log，crashInfo（其他信息）和appSoNameWhitelist暂时只对iGame开放
const IGAME_FIELDS_SET = new Set(['customLog', 'systemLog', 'crashInfos', 'appSoNameWhitelist']);

const SingleFilter = ({
  filterOptions,
  filterData,
  name,
  value,
  onChangeKv,
}) => {
  const { t } = useTranslation();
  const {
    appId,
    platformId,
    hideIssueFields,
    fieldWhitelist,
    fieldBlacklist,
    fixedFieldList,
    isReadOnly,
    isOom,
    enableIgameSearchFields,
    enableSearchKeyStackSoName,
  } = useContext(IssueCrashFilterContext);

  const [
    datePickerMinAvailableMoment,
    datePickerMaxAvailableMoment
  ] = useSelector(selectCurrentAppDatePickerAvailableMomentRange);

  const [innerName, setInnerName] = useState('');
  const [innerValue, setInnerValue] = useState(null);

  const isFixedField = useMemo(() => fixedFieldList.includes(innerName), [innerName, fixedFieldList]);

  const selectedNameSet = new Set(filterData.map(x => x.name));

  let nameOptions = ALL_FILTER_FIELDS
    .filter(x => {
      if ((fieldWhitelist || []).length > 0 && !fieldWhitelist.includes(x.name)) {
        return false;
      }
      if (x.onlyAvailableViaWhitelist && !(fieldWhitelist || []).includes(x.name)) {
        return false;
      }
      if (isOom && !OOM_WHITELIST_FILTER_NAMES.includes(x.name)) {
        return false;
      }
      if (!isOom && x.oomOnly) {
        return false;
      }
      if (hideIssueFields && x.issueOnly) {
        return false;
      }
      if (fieldBlacklist && fieldBlacklist.includes(x.name)) {
        return false;
      }
      if (typeof x.availableByPid == 'function' && !x.availableByPid(platformId)) {
        return false;
      }
      if (FEATURE_ANALYSIS_HIDDEN_INPUT_FIELD_NAMES.includes(x.name)) {
        return false;
      }
      if (!enableIgameSearchFields && IGAME_FIELDS_SET.has(x.name)) {
        return false;
      }
      if (!enableSearchKeyStackSoName && x.name === 'keyStackSoName') {
        return false;
      }
      return true;
    })
    .map(x => ({
      label: t(`issueCrashFilterKey.${x.name}`),
      richLabel: <div>{ t(`issueCrashFilterKey.${x.name}`) }</div>,
      value: x.name,
      issueOnly: x.issueOnly,
      disabled: selectedNameSet.has(x.name) && x.name !== 'customKey', // 暂时写死的逻辑，customKey可以多个
    }));

  const isFieldNotInOptions = innerName && isNullish(nameOptions.find(x => x.value === innerName));
  let unsupportedFieldTooltipDom = undefined;

  // 如果innerName不在nameOptions里，增加有提示icon的选项
  // 主要原因是
  // 1. 以前支持的字段，后来添加到blacklist标为不支持了，但是要兼容旧数据，所以要显示出来但要有提示
  // 2. 特征规则那边传过来的隐藏字段
  if (isFieldNotInOptions) {
    if (FEATURE_ANALYSIS_HIDDEN_INPUT_FIELD_NAMES.includes(innerName)) {
      unsupportedFieldTooltipDom = <Tooltip title={t('issueCrashFilter.featureAnalysisHiddenField')}>
        <ExclamationCircleOutlined style={{color: '#AAA'}}/>
      </Tooltip>;
    } else {
      unsupportedFieldTooltipDom = <Tooltip title={t('issueCrashFilter.invalidFieldName')}>
        <ExclamationCircleOutlined style={{color: 'red'}}/>
      </Tooltip>;
    }

    nameOptions.push({
      label: t(`issueCrashFilterKey.${innerName}`),
      richLabel: <Space size={4}>
        <div>{unsupportedFieldTooltipDom}</div>
        <div>{t(`issueCrashFilterKey.${innerName}`)}</div>
      </Space>,
      value: innerName,
      disabled: true,
      fieldNotInOptions: true,
    });
  }

  const nameOptionGroups = [{
    label: t('issueCrashFilter.问题级字段'),
    options: nameOptions.filter(x => !x.fieldNotInOptions && x.issueOnly),
  }, {
    label: t('issueCrashFilter.上报级字段'),
    options: nameOptions.filter(x => !x.fieldNotInOptions && !x.issueOnly),
  }, {
    label: ze('其他', 'Others'),
    options: nameOptions.filter(x => x.fieldNotInOptions),
  }].filter(x => x.options.length > 0);

  useEffect(() => {
    setInnerName(name);
    setInnerValue(value);
  }, [name, value]);

  function updateName(k) {
    const defaultValue = (ALL_FILTER_FIELDS.find(x => x.name === k) || {}).defaultValue;
    setInnerName(k);
    setInnerValue(defaultValue);
    onChangeKv(k, defaultValue);
  }

  function updateValue(v) {
    setInnerValue(v);
    onChangeKv(innerName, v);
  }

  const makeValueDomByName = (name, platformId) => {
    const fieldInfo = ALL_FILTER_FIELDS.find(x => x.name === name);
    if (!fieldInfo) {
      return <Input disabled/>;
    }

    if ('version' === name) {
      const versionTree = getTreeSelectVersionOptions(filterOptions.version || []);
      return <VersionHybridSelect
        style={{ width: '100%' }}
        treeData={versionTree}
        value={ innerValue }
        onChange={(v) => { updateValue(v) }}
      />;
    }
    if (['exceptionTypeList', 'status'].includes(name)) {
      return <CsSelect
        style={{ width: '100%', fontSize: '14px' }}
        mode='multiple'
        allowClear
        showArrow={true}
        showSearch={false}
        options={filterOptions[name]}
        placeholder={fieldInfo.placeholder}
        value={ innerValue }
        onChange={(v) => {
          if (isReadOnly) {
            return;
          }
          updateValue(v);
        }}
      />;
    }
    if (name === 'tagList') {
      const compatibleInnerValue = Array.isArray(innerValue) // 兼容老格式的value
        ? { value: innerValue, isMustNot: false }
        : innerValue || {};
      const value = compatibleInnerValue.value || [];
      const options = filterOptions[name].map(item => ({value: item.value, label: item.label}));
      console.log('compatibleInnerValue', compatibleInnerValue, filterOptions, options);
      return <div style={{ display: 'flex' }}>
        <Select
          style={{ width: '100px', height: '32px', flexGrow: 0, flexShrink: 0 }}
          options={[
            { label: t('issueCrashFilter.包含'), value: 0 },
            { label: t('issueCrashFilter.不包含'), value: 1 },
          ]}
          value={ Number(compatibleInnerValue.isMustNot) }
          onChange={(v) => {
            if (isReadOnly) {
              return;
            }
            updateValue({
              ...compatibleInnerValue,
              isMustNot: !!v,
            });
          }}
        />
        <CsSelect
          style={{ flexGrow: 1, fontSize: '14px' }}
          mode='multiple'
          allowClear
          showSearch={true}
          optionFilterProp='label'
          options={options}
          placeholder={fieldInfo.placeholder}
          value={ value }
          showArrow={true}
          onChange={(v) => {
            if (isReadOnly) {
              return;
            }
            updateValue({
              ...compatibleInnerValue,
              value: v,
            });
          }}
        />
      </div>
    }
    if (['processor', 'bundleId', 'channelId'].includes(name)) {
      return <Select
        style={{ width: '100%', fontSize: '14px' }}
        allowClear
        showSearch={true}
        filterOption={ (input, option) => (option.label || '').toLowerCase().includes(input.toLowerCase()) }
        options={filterOptions[name]}
        placeholder={fieldInfo.placeholder}
        value={ innerValue }
        onChange={(v) => {
          if (isReadOnly) {
            return;
          }
          updateValue(v);
        }}
      />
    }
    if (['vmStatus'].includes(name)) {
      return <Select
        style={{ width: '100%', fontSize: '14px' }}
        allowClear
        options={[
          { label: t('issueCrashFilter.anyPlaceholder'), value: 'ALL' },
          { label: t('EXCP_OVERVIEW.仅真机'), value: 'ONLY_NOT_VM' },
          { label: t('EXCP_OVERVIEW.仅模拟器'), value: 'ONLY_VM' },
        ]}
        placeholder={fieldInfo.placeholder}
        value={ innerValue }
        onChange={(v) => {
          if (isReadOnly) {
            return;
          }
          updateValue(v);
        }}
      />
    }
    if (['systemExitStatus'].includes(name)) {
      return <Select
        style={{ width: '100%', fontSize: '14px' }}
        allowClear
        options={[
          { label: t('issueCrashFilter.anyPlaceholder'), value: 'ALL' },
          { label: t('common.yes'), value: 'ONLY_SYSTEM_EXIT' },
          { label: t('common.no'), value: 'ONLY_NOT_SYSTEM_EXIT' },
        ]}
        placeholder={fieldInfo.placeholder}
        value={ innerValue }
        onChange={(v) => {
          if (isReadOnly) {
            return;
          }
          updateValue(v);
        }}
      />
    }
    if (['tapdBugStatus'].includes(name)) {
      return <Select
        style={{ width: '100%', fontSize: '14px' }}
        options={TapdBugStatusOptions}
        value={ innerValue }
        onChange={(v) => {
          if (isReadOnly) {
            return;
          }
          updateValue(v);
        }}
      />
    }
    if (['oomStatus'].includes(name)) {
      return <Select
        style={{ width: '100%', fontSize: '14px' }}
        allowClear
        options={[
          { label: t('issueCrashFilter.anyPlaceholder'), value: 'ALL' },
          { label: t('issueCrashFilter.仅OOM崩溃'), value: 'ONLY_IS_OOM' },
          { label: t('issueCrashFilter.仅非OOM崩溃'), value: 'ONLY_NOT_IS_OOM' },
        ]}
        placeholder={fieldInfo.placeholder}
        value={ innerValue }
        onChange={(v) => {
          if (isReadOnly) {
            return;
          }
          updateValue(v);
        }}
      />
    }
    if (['elapsedTime'].includes(name)) {
      return <DurationRangeInput
        value={ innerValue }
        onChange={(v) => {
          if (isReadOnly) {
            return;
          }
          updateValue(v);
        }}
      />;
    }
    if ('ramRange' === name) {
      return <RamSelect
        value={ innerValue }
        onChange={(v) => {
          if (isReadOnly) {
            return;
          }
          updateValue(v);
        }}
      />;
    }
    if ('ramGib' === name) {
      return <RamGibSelect
        platformId={platformId}
        value={ innerValue }
        onChange={(v) => {
          if (isReadOnly) {
            return;
          }
          updateValue(v);
        }}
      />;
    }
    if (['uploadTime'].includes(name)) {
      let defaultSelect = '';
      const { date, startDate, endDate } = innerValue;
      if (date === 'custom' && startDate && endDate) {
        defaultSelect = `${startDate} ~ ${endDate}`;
      } else {
        defaultSelect = getDateOption(date);
      }
      return <TimePicker
        style={{ select_control: 'issueCrashFilterDeprecatedTimePickerHack' }}
        key="date-select1"
        name="date"
        defaultSelect={defaultSelect}
        onChange={(v) => { updateValue(v) }}
      />
    }
    if (['crashTime', 'issueFirstUploadTime'].includes(name)) {
      return <DatePicker.RangePicker
        style={{ minWidth: '100%' }}
        disabledDate={moment => moment && (moment.isBefore(datePickerMinAvailableMoment) || moment.isAfter(datePickerMaxAvailableMoment))}
        allowEmpty={[true, true]}
        showTime
        value={innerValue}
        ranges={{
          [ze('最近1小时', 'Last 1 Hour')]: [moment().subtract(1, 'hours'), null],
          [ze('最近24小时', 'Last 24 Hours')]: [moment().subtract(1, 'days'), null],
          [ze('最近72小时', 'Last 72 Hours')]: [moment().subtract(3, 'days'), null],
          [ze('最近7天', 'Last 7 Days')]: [moment().subtract(7, 'days'), null],
        }}
        onChange={(dates) => {
          updateValue(dates || [null, null]);
        }}
      />
    }
    if (name === 'queryAccessUploadTime') {
      return <DatePicker.RangePicker
        style={{ minWidth: '100%' }}
        disabledDate={moment => moment && (moment.isBefore(datePickerMinAvailableMoment) || moment.isAfter(datePickerMaxAvailableMoment))}
        allowEmpty={[true, true]}
        showTime
        value={innerValue}
        ranges={{
          [ze('最近1小时', 'Last 1 Hour')]: [moment().subtract(1, 'hours'), null],
          [ze('最近24小时', 'Last 24 Hours')]: [moment().subtract(1, 'days'), null],
          [ze('最近72小时', 'Last 72 Hours')]: [moment().subtract(3, 'days'), null],
        }}
        onChange={(dates) => {
          updateValue(dates || [null, null]);
        }}
      />
    }
    if ('customKey' === name) {
      const compatibleInnerValue = Array.isArray(innerValue) // 兼容老格式的value，老格式的value只支持terms类型
        ? { field: innerValue[0], terms: innerValue[1], queryType: 'terms' }
        : innerValue || {};
      const { field, queryType, terms, wildcard, gte, lte } = compatibleInnerValue;
      const isRangeQueryTypeAvailable = /^(?:C03_)?I#.+$/.test(field || '');
      return <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <CustomKvSearchKey
          appId={appId}
          isReadOnly={isReadOnly}
          value={field}
          onChange={v => updateValue({ field: v, queryType: 'terms', terms: [], gte: 0, lte: 0 })}
        />
        <Select
          style={{ width: '150px' }}
          options={[
            { value: 'terms', label: t('issueCrashFilter.精确匹配') },
            { value: 'wildcard', label: t('issueCrashFilter.通配符匹配') },
            ...(isRangeQueryTypeAvailable ? [{ value: 'range', label: t('issueCrashFilter.数值范围') }] : []),
          ]}
          value={queryType}
          onChange={v => {
            if (isReadOnly) {
              return;
            }
            updateValue({ ...compatibleInnerValue, queryType: v });
          }}
        />
        { queryType === 'terms' && <Select
          mode='tags'
          placeholder='输入一个或多个值。单击弹出的选项，或按回车键确认输入的值'
          style={{ flexGrow: 1 }}
          value={terms}
          allowClear={true}
          notFoundContent={null}
          onChange={v => {
            if (isReadOnly) {
              return;
            }
            updateValue({ ...compatibleInnerValue, terms: v })
          }}
        /> }
        { queryType === 'wildcard' && <div style={{ flexGrow: '1' }}>
          <Input
            placeholder={ze('支持两种通配符：*（匹配0到多个任意字符）和 ?（匹配单个任意字符）', 'Supports two wildcard operators: * and ?')}
            value={wildcard}
            onChange={e => {
              if (isReadOnly) {
                return;
              }
              updateValue({ ...compatibleInnerValue, wildcard: e.target.value })
            }}
          />
        </div> }
        { queryType === 'range' && <div style={{ flexGrow: '1' }}>
          <Input.Group compact>
            <InputNumber
              value={gte}
              readOnly={isReadOnly}
              style={{ width: 'calc(50% - 20px)', textAlign: 'center' }}
              placeholder="下限（包含）"
              onChange={v => updateValue({ ...compatibleInnerValue, gte: v })}
            />
            <Input style={{ width: '40px', backgroundColor: 'rgba(0,0,0,0)', textAlign: 'center', pointerEvents: 'none' }} placeholder="~" disabled />
            <InputNumber
              value={lte}
              readOnly={isReadOnly}
              style={{ width: 'calc(50% - 20px)', textAlign: 'center' }}
              placeholder="上限（包含）"
              onChange={v => updateValue({ ...compatibleInnerValue, lte: v })}
            />
          </Input.Group>
        </div> }
      </div>;
    }
    if ('modelList' === name) {
      return <IosDeviceSelect
        value={innerValue}
        onChange={(v) => {
          if (isReadOnly) {
            return;
          }
          updateValue(v);
        }}
      />;
    }
    if ('country' === name) {
      return <CsSelect
        style={{ width: '100%', fontSize: '14px' }}
        mode='multiple'
        allowClear
        showSearch={true}
        filterOption={ (input, option) => (option.label || '').toLowerCase().includes(input.toLowerCase()) }
        options={COUNTRY_LIST_OPTIONS}
        value={ innerValue }
        showArrow={true}
        onChange={(v) => {
          if (isReadOnly) {
            return;
          }
          updateValue(v);
        }}
      />
    }
    if (CRASH_SEARCH_INPUT_FIELD_NAMES.includes(name)) {
      const placeholder = fieldInfo.placeholderByPid
        ? fieldInfo.placeholderByPid(platformId)
        : fieldInfo.placeholder;
      return <Input
        readOnly={isReadOnly}
        style={{ width: '100%' }}
        allowClear={true}
        placeholder={placeholder}
        value={innerValue}
        onChange={(e) => { updateValue(e.target.value) }}
        prefix={
          typeof fieldInfo.inputPrefixByPid === 'function'
            ? fieldInfo.inputPrefixByPid(platformId)
            : null
        }
      />
    }
    if (FEATURE_ANALYSIS_HIDDEN_INPUT_FIELD_NAMES.includes(name)) {
      return <Input
        disabled={true}
        style={{ width: '100%' }}
        value={innerValue}
      />
    }

    if (name === 'keyStackSoName') {
      return <div style={{ display: 'flex' }}>
        <EnlargeableInput
          style={{ flexGrow: 1, fontSize: '14px' }}
          value={innerValue}
          placeholder='大小写敏感，用逗号分隔多个so'
          description='大小写敏感，用逗号分隔多个so。'
          onChange={v => {
            if (isReadOnly) {
              return;
            }
            updateValue(v);
          }}
        />
      </div>;
    }

    if (name === 'appSoNameWhitelist') { // iGame定制需求
      const { appSoNameWhitelistTextValue, appSoNameWhitelistMustNot } = innerValue;
      return <div style={{ display: 'flex' }}>
        <Select
          style={{ width: '100px' }}
          options={[
            // { label: '全命中', value: 0 },
            { label: '非全命中', value: 1 },
          ]}
          value={Number(appSoNameWhitelistMustNot)}
          onChange={(v) => {
            if (isReadOnly) {
              return;
            }
            updateValue({
              ...innerValue,
              appSoNameWhitelistMustNot: !!v,
            });
          }}
        />
        <EnlargeableInput
          style={{ flexGrow: 1, fontSize: '14px' }}
          value={appSoNameWhitelistTextValue}
          placeholder='大小写敏感，用逗号分隔多个so'
          description='大小写敏感，用逗号分隔多个so。'
          onChange={v => {
            if (isReadOnly) {
              return;
            }
            updateValue({ ...innerValue, appSoNameWhitelistTextValue: v });
          }}
        />
      </div>
    }

    return <Input disabled/>;
  };

  const valueDom = makeValueDomByName(innerName, platformId);

  return <div className={scss.singleFilterBox}>
    <div className={scss.singleFilterFieldSelect}>
      { !isReadOnly && !isFixedField && <Select
        style={{ width: '100%' }}
        options={nameOptionGroups}
        showSearch
        allowClear={false}
        optionLabelProp='richLabel'
        placeholder={t('issueCrashFilter.fieldNamePlaceholder')}
        filterOption={ (input, option) => isNullish(option.options) && (option.label || '').toLowerCase().includes(('' || input).toLowerCase()) }
        value={innerName}
        onChange={(v) => updateName(v)}
      /> }
      { (isReadOnly || isFixedField) && <Input
        style={{ width: '100%' }}
        readOnly={true}
        value={ innerName ? t(`issueCrashFilterKey.${innerName}`) : null }
        prefix={ isFieldNotInOptions ? unsupportedFieldTooltipDom : undefined }
      /> }
      { !isReadOnly && !isFixedField && <div className={scss.singleFilterFieldSelectDeleteBtn}>
        <div
          onClick={() => updateName(null)}
        ><OverviewSave/></div>
      </div> }
    </div>
    <div style={{ flexGrow: 1, marginLeft: '0' }}>{valueDom}</div>
  </div>;
};


const IssueCrashFilter = ({
  reduxState,
  selectFrontendWebVolatileConfigValueByKey,
  platformId,
  hideIssueFields,
  fieldWhitelist,
  maxConditions,
  fieldBlacklist,
  fixedFieldList, // 固定字段，不可以删除或者修改
  filterOptions,
  filterData,
  onChange,
  hideSubmitButton,
  onSubmit,
  extraDomAfterSubmitButton,
  isFeatureConfig, // 是否是自定义特征这边用的
  isOom, // 是否是OOM的页面
  isReadOnly, // feature config专用
  onDelete, // feature config专用
}) => {
  const { t } = useTranslation();
  const appId = reduxState.app.get('current').get('appId');

  // 是否支持搜索system log和custom log
  const enableIgameSearchFields = (selectFrontendWebVolatileConfigValueByKey(
    FrontendWebVolatileConfigKeys.ENABLE_IGAME_SEARCH_FIELDS_APP_ID_WHITELIST
  ) || '')
    .split(',')
    .map(x => x.trim())
    .includes(appId);

  const enableSearchKeyStackSoName = (selectFrontendWebVolatileConfigValueByKey(
    FrontendWebVolatileConfigKeys.ENABLE_SEARCH_KEY_STACK_SO_NAME
  ) || '')
    .split(',')
    .map(x => x.trim())
    .includes(appId);

  const [containerWidthPx, setContainerWidthPx] = useState(0);

  const [state, updateState] = useImmer({
    filterData: [],
    changeEventTrigger: 0,
  });

  const containerRefCallback = useCallback((node) => {
    if (!node) {
      GlobalResizeObserver.unobserve(node);
      return;
    }

    setContainerWidthPx(node.offsetWidth);
    GlobalResizeObserver.observe(node, (entry) => {
      setContainerWidthPx(entry.target.offsetWidth);
    });
  }, []);

  useEffect(() => {
    updateState(draft => {
      if (!filterData) {
        draft.filterData = [];
      } else {
        draft.filterData = filterData.filter(x => x && NAME_TO_FILTER_FIELD[x.name])
          .map(x => ({
            ...x,
            value: isNotNullish(x.value) ? x.value : NAME_TO_FILTER_FIELD[x.name].defaultValue,
          }));
      }
    });
  }, [filterData]);

  useEffect(() => {
    if (state.changeEventTrigger <= 0) {
      return;
    }
    onChange({
      newFilterData: state.filterData,
    });
  }, [state.changeEventTrigger]);

  const singleFilters = state.filterData.map((x, i) => {
    if (!x) {
      return null;
    }
    if (x.name && !ALL_FILTER_NAMES.includes(x.name)) {  // 允许name为空，但必须在名单内
      return null;
    }

    let colSpan;
    if (isFeatureConfig) {
      colSpan = containerWidthPx < 1100 ? 22 : 11; // 留2格span给特征分析的操作按钮
    } else {
      if (containerWidthPx > 1500) {
        colSpan = 8;
      } else if (containerWidthPx > 1000) {
        colSpan = 12;
      } else {
        colSpan = 24;
      }
    }
    if (x.name === 'customKey') {
      colSpan = isFeatureConfig ? 22 : 24;
    }

    return <Col
      key={`${i}-${x.name}`}
      span={colSpan}
    >
      <ErrorBoundary fallback={<div style={{ color: 'red' }}>过滤器渲染故障</div>}><SingleFilter
        filterOptions={filterOptions}
        filterData={state.filterData}
        name={x.name}
        value={x.value}
        onChangeKv={(k, v) => {
          updateState(draft => {
            if (!k) {
              draft.filterData.splice(i, 1); // 删除field
            } else {
              draft.filterData[i].name = k;
              draft.filterData[i].value = v;
            }
            draft.changeEventTrigger += 1;
          });
        }}
      /></ErrorBoundary>
    </Col>;
  });

  // （点击搜索后）对数据进行后处理，返回处理后的数据
  function postprocessFilterData(filterData) {
    return filterData.map(x => {
      if (x.name !== 'detail') {
        return x;
      }

      return {
        ...x,
        value: (x.value || '').trim(),
      };
    });
  }

  const filterButtonDomColSpan = isFeatureConfig ? 2 : 8;

  const allowAddMoreButton = !maxConditions || maxConditions > state.filterData.length;

  const filterButtonDom = <Col span={filterButtonDomColSpan}>
    { !isReadOnly && <div className={scss.singleFilterBox}>
      { allowAddMoreButton && <Button
        icon={<PlusOutlined/>}
        onClick={() => {
          updateState(draft => {
            draft.filterData.push({ name: '', value: null });
          });
        }}
        disabled={maxConditions ? state.filterData.length > 1 : false}
      /> }
      { !isFeatureConfig && !hideSubmitButton && <Button
        style={{ marginLeft: allowAddMoreButton ? '16px' : 0 }}
        type='primary'
        onClick={() => {
          updateState(draft => ({
            ...draft,
            filterData: postprocessFilterData(state.filterData)
          }));
          onSubmit({
            newFilterData: postprocessFilterData(state.filterData),
          });
        }}
      >{t('common.search')}</Button>}
      { isFeatureConfig && <Button
        style={{ marginLeft: '8px' }}
        danger
        icon={<DeleteOutlined />}
        onClick={() => {
          if (onDelete) {
            onDelete();
          }
        }}/> }
      { extraDomAfterSubmitButton }
    </div>}
  </Col>;

  return <div
    ref={containerRefCallback}
    className={scss.filterContainer}
  >
    <IssueCrashFilterContext.Provider value={{
      appId,
      platformId,
      hideIssueFields,
      fieldWhitelist: fieldWhitelist || [],
      fieldBlacklist: fieldBlacklist || [],
      fixedFieldList: fixedFieldList || [],
      isReadOnly: !!isReadOnly,
      isOom: !!isOom,
      enableIgameSearchFields,
      enableSearchKeyStackSoName,
    }}>
      <Row gutter={[12, 6]}>
        {singleFilters}
        {filterButtonDom}
      </Row>
    </IssueCrashFilterContext.Provider>
  </div>;
};

const mapStateToProps = state => ({
  reduxState: state,
  selectFrontendWebVolatileConfigValueByKey: (key) => selectFrontendWebVolatileConfigValueByKey(state, key),
});

export default connect(mapStateToProps)(IssueCrashFilter);
