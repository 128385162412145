import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _style from './style.scss';
import { Translation } from 'react-i18next'
import { Tooltip } from 'antd';

const Tab = ({
  bigSize = false,
  children, tabs, changeTab, currentTab, tabType, style = {}, id = '',
}) => {
  return (
    <Translation>
      {
        (t, {i18n}) =>
        <div className={classNames(_style.tab_container, style.tab_container, bigSize ? _style.bigSize : '')} id={id}>
      {
        tabs.filter(item => !!item).map((tabItem, i) => {
          const tab = typeof tabItem === 'object' ? tabItem.label : tabItem;
          const tooltip = typeof tabItem === 'object' ? tabItem.tooltip : '';
          const className = classNames(_style.tab_item, style.tab_item, {
            active: (i === 0 && !currentTab) || (currentTab && currentTab === tab),
          });
          return <Tooltip
            title={tooltip || null}
            overlayStyle={{ maxWidth: '80vw' }}
          >
            <div className={className} key={tab} onClick={() => changeTab(tab, tabType)}>{ t(tab) }</div>
          </Tooltip>;
        })
      }
      {children}
    </div>
      }
    </Translation>
  );
};

Tab.propTypes = {
  bigSize: PropTypes.bool,
  children: PropTypes.object,
  tabs: PropTypes.array,
  changeTab: PropTypes.func,
  currentTab: PropTypes.string,
  tabType: PropTypes.string,
  style: PropTypes.object,
  id: PropTypes.string,
};

export default Tab;
