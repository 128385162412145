import { List, fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import {
  getTop5, getLastSeries, getSelectOption, getFtArr,
} from 'utils/helper';
import { initialState } from './initialState';
export default handleActions({
  CHANGE_SEARCH_PARAM: (state, action) => {
    const { type, value } = action.payload;
    const paramVal = /version|date|ftConfig|ft|pageType|chartType/.test(type)
      ? 'chartSearchParams'
      : 'listSearchParams';

    // 当ft 改变时，ftConfig 需要联动修改
    if (type === 'ft') {
      return state.update(paramVal,
        (params) => params.set(type, value).set('ftConfig', 'diy'));
    } else if (type === 'ftConfig' && value === 'top5') {
      const top5 = state.get('top5');
      return state.update(paramVal, (params) => params.set(type, value)
        .set('ft', top5));
    } else if (type === 'pageType') {
      // 需要同时同步listSearchParams 的version,date
      const { version, date } = state.get('chartSearchParams').toJS();

      return state.update(paramVal, (params) => params.set(type, value))
        .update('listSearchParams', (params) => params.set('detailVersion', version)
          .update('detailDate', (_date) => _date.set('startDate', date.endDate)));
    }

    return state.update(paramVal, (params) => params.set(type, value));
  },
  UPDATE_FTLIST_SIZE: (state, action) => {
    return state.set('ftListLength', action.payload);
  },
  UPDATE_STATE_STATUS: (state, action) => {
    const { type, value } = action.payload;
    return state.set(type, value);
  },
  UPDATE_PAEG_NUM: (state, action) => state.set('totalPages', Math.floor(state.get('ftList').length / action.payload) + (state.get('ftList').length % action.payload === 0 ? 0 : 1)),
  FTTREND_GET_SUCC: (state, action) => {
    const rows = state.get('listSearchParams').get('rows');
    const { code } = action.response;
    const { actionType } = action.params;
    const { datas = {} } = action.response.data;
    const lastSeries = getLastSeries(datas);
    const opt = getSelectOption(lastSeries).toArray();
    const ftArr = getFtArr(lastSeries && lastSeries.size > 0 ? lastSeries.toJS() : []);
    if (actionType === 'list') { // 单独请求详情
      return state.set('ftList', ftArr)
        .set('ftListLength', ftArr.length)
        .set('totalPages', Math.floor(ftArr.length / rows) + (ftArr.length % rows === 0 ? 0 : 1))
        .set('statusCode', code);
    } else {
      // 请求图表的时候,ftList取最近的一天的数据
      const top5 = getTop5('crashNum', lastSeries.toJS(), {
        withoutAll: true,
        _symbol: ',',
      });
      if (actionType === 'all') {
        return state.set('ftSeries', datas)
          .set('ftList', ftArr)
          .set('ftListLength', ftArr.length)
          .set('totalPages', Math.floor(ftArr.length / rows) + (ftArr.length % rows === 0 ? 0 : 1))
          .set('statusCode', code)
          .update('selectOptions', (options) => options.set('ft', opt))
          .update('chartSearchParams', (param) => param.set('ft', top5))
          .set('top5', top5)
          .set('firstLoad', false);
      } else {
        return state.set('ftSeries', datas)
          .update('selectOptions', (options) => options.set('ft', opt))
          .update('chartSearchParams', (param) => param.set('ft', top5))
          .set('top5', top5);
      }
    }
  },
  GETVERSIONS_GET_SUCC: (state, action) => {
    const { versions = [] } = action.response;
    return state.update('selectOptions', (options) => options.merge({
      version: new List(versions).map((item) => {
        return ({
          label: (parseInt(item.enable) === 0 ? (`${item.version}已关闭`) : item.version),
          value: encodeURIComponent(item.version),
        });
      }).unshift({
        label: '全版本',
        value: '-1',
      }),
    }));
  },

  FTINFO_GET_SUCC: (state, action) => {
    return state.set('ftInfo', fromJS(action.response.data.datas));
  },

  FTINFO_ADD_SUCC: (state, action) => {
    return state.set('ftInfo', fromJS(action.response.data.datas));
  },

  FTINFO_EDIT_SUCC: (state, action) => {
    const { ftId, ftName, desc } = action.params;
    const index = state.get('ftInfo').findIndex((ft) => {
      return ft.get('ftId') === ftId;
    });

    console.log('found index', index);
    return state.update('ftInfo', (ft) => ft.update(index, (t) => t.set('ftName', ftName).set('desc', desc)));
  },

  FTINFO_DELETE_SUCC: (state, action) => {
    return state.set('ftInfo', fromJS(action.response.data.datas));
  },

  EDIT_FT_PLUGIN_INFO_SUCC: (state, action) => {
    return state.set('ftInfo', fromJS(action.response.data.datas));
  },

  ADD_FT_PLUGIN_INFO_SUCC: (state, action) => {
    return state.set('ftInfo', fromJS(action.response.data.datas));
  },

  DELETE_FT_PLUGIN_INFO_SUCC: (state, action) => {
    return state.set('ftInfo', fromJS(action.response.data.datas));
  },
}, initialState);
