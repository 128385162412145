import { Map } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { FETCH_FAILURE } from 'store/actions';
import { ERR_MSG } from 'utils/constants';

const initialState = new Map({
  error: '',
  message: '',
  failureType: '',
});

export const showFetchFailure = createAction(FETCH_FAILURE);

export const showError = createAction('SHOW_ERROR');

function getErrorMessage(failureType, error) {
  let message = ERR_MSG[failureType];
  if (!message) {
    message = `未知错误: ${JSON.stringify(error)}!`;
  }
  return message;
}

export default handleActions({
  /**
   * 发送fetch请求失败
   */
  [FETCH_FAILURE]: (state, action) => {
    const { error, failureType } = action.payload ? action.payload : action;
    return state.merge({
      error, failureType, message: getErrorMessage(failureType, error),
    });
  },

  SHOW_ERROR: (state, action) => {
    const { error, message } = action.payload;
    return state.merge({ error, message });
  },

}, initialState);
