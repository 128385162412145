import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { fromJS } from 'immutable';
import { Tab, TapdModal } from 'components/exception/issue';
import { Loading, CopyRight, PureRender } from 'components/commons';
import {
  EXCEPTION_HEAD_DATA_TYPE_DOWNLOAD,
  PLATFORM_ID,
} from 'utils/constants';
import * as PlatformUtils from 'utils/platform'
import { getStartEndDateFromStr, getChartTags, getDateDiff2, getSummaryTagTooltipContent } from 'utils/StringUtils';
import {
  routeReclick, getStatus, parseSearch, generateSearch,
} from 'utils/helper';
import {
  SYSTEM_RATE_SUCC,
  SYSTEM_ACCESS_SUCC,
  MODEL_RATE_SUCC,
  MODEL_ACCESS_SUCC,
  APP_RATE_SUCC,
  APP_ACCESS_SUCC,
  TAG_RATE_SUCC,
  TAG_ACCESS_SUCC,
  GPU_RATE_SUCC,
  GPU_ACCESS_SUCC,
  CPU_RATE_SUCC,
  CPU_ACCESS_SUCC
} from 'store/actions';
import RankingList from './RankingList';
import TopIssueDataTypeSelect from './TopIssueDataTypeSelect';
import TopIssueList, { TopIssueUtils } from 'components/exception/ProductSummaryPage/TopIssueList/TopIssueList';
import RealTimeTopIssue from 'components/exception/ProductSummaryPage/RealTimeTopIssue';
import SummaryTab from 'components/exception/ProductSummaryPage/SummaryTab/SummaryTab';
import style from './style.scss';
import SummaryHead from 'components/exception/ProductSummaryPage/SummaryHead';
import SummaryOptions from './SummaryOptions';
import DataTypeSelect from './DataTypeSelect';
import RankingListOptions from './RankingListOptions';
import TrendChart from 'components/exception/ProductSummaryPage/TrendChart';
import { Select, Button, Input, Radio, Col, Space, Spin, Affix, Checkbox } from 'antd';
import { withTranslation } from 'react-i18next';
import { isAndroidOrHarmony, isGameConsole, isMobile, isSwitch, PlatformUtil } from 'utils/platform';
import RestHelper from 'utils/RestHelper';
import throttle from 'lodash/function/throttle';
import CardHead from 'components/commons/CardHead';
import { CountryUtil } from 'utils/country';
import {
  selectFrontendWebVolatileConfigValueByKey,
  selectHasSpecifiedPermissionInCurrentApp,
} from 'utils/selectors/selectors';
import { isNullish } from 'utils/nullish';
import StatByCountry from 'components/exception/ProductSummaryPage/StatByCountry';
import { ProductSummaryUtil } from 'components/exception/ProductSummaryPage/ProductSummaryUtil';
import ModelBitTrend from './ModelBitTrend';
import CountryGroupConfigModal from 'containers/CountryGroupConfigModal';
import { FrontendWebVolatileConfigKeys } from 'utils/constants/frontend-web-volatile-config';
import { ze } from 'utils/zhEn';
import { XlsxUtil } from 'utils/xlsx-util';
import StatByIntegratedApp from 'components/exception/ProductSummaryPage/StatByIntegratedApp';
import { ServerAppSettings } from 'utils/server-app-settings';
import SubModuleIdSelect from 'components/exception/ProductSummaryPage/SubModuleIdSelect';
import DailyReportConfigModalButton from 'components/exception/DailyReportConfigModalButton';
import isEmpty from 'lodash/lang/isEmpty';
import { formatCrashPercentageString } from 'utils/format/format-number';
import { queryBigDataLastUpdateTime } from 'reducers/summary/summaryActions';
import { CsvUtil } from 'utils/csv-util';
import VmTypeCheckboxGroup from 'components/exception/ProductSummaryPage/VmTypeCheckboxGroup';
import { DividerWithTime } from './DividerWithTime';
import { ExceptionCategoryUtil } from 'utils/exception-category';
import reportEvent, { EVENT_ACTIONS, ReportEventUtil } from 'utils/reportEvent';
import TrendIcon from 'svg/v2/newcs_dashboard_overview_crashtrend_icon.svg';
import { UpdateTimeTag } from './UpdateTimeTag';
import CsDownloadButton from 'components/commons/CsDownloadButton/CsDownloadButton';
import CsCollapseButton from 'components/commons/CsCollapseButton/CsCollapseButton';
import NoticeIconGreen from 'svg/v2/newcs_dashboard_left_notice_icon2.svg'
import CsTabs from 'components/antd-extension/CsTabs';
import { CsNewTabs } from 'components/antd-extension/CsNewTabs';

import { LoadingPage } from '../../../route/index';
import StatByUserSceneTag from 'components/exception/ProductSummaryPage/StatByUserSceneTag';
import { RolePermission } from 'utils/constants/role-permission';

const OomDashboard = React.lazy(() => import(/* webpackChunkName: "oom-dashboard" */ 'components/workbench/SpaceElevator/OomDashboard'));

const TAG = '[ProductionSummaryPage]:: ';

/**
 * 产品概览页面
 */
@connect((state, router) => {
  const query = parseSearch(router.location);
  const pid = query.pid;
  return {
  reduxState: state,
  currentApp: state.app.get('current'),
  currentUser: state.user.get('current'),
  selectOptions: state.summary.get('selectOptions'),
  path: router.location.pathname,
  currentAppId: state.app.get('current').get('appId'),
  routerPid: state.app.get('current').get('platformId'),
  trendData: state.summary.get('trendData'),
  compareData: state.summary.get('compareData'),
  searchParams: state.summary.get('searchParams'),
  topIssueData: state.summary.get('topIssueData'),
  isShowTop3: state.summary.get('isShowTop3'),
  realTimeTrendData: state.summary.get('realTimeTrendData'),
  realTimeCompareData: state.summary.get('realTimeCompareData'),
  appRateData: state.summary.get(APP_RATE_SUCC),
  appAccessData: state.summary.get(APP_ACCESS_SUCC),
  systemRateData: state.summary.get(SYSTEM_RATE_SUCC),
  systemAccessData: state.summary.get(SYSTEM_ACCESS_SUCC),
  modelRateData: state.summary.get(MODEL_RATE_SUCC),
  modelAccessData: state.summary.get(MODEL_ACCESS_SUCC),
  tagRateData: state.summary.get(TAG_RATE_SUCC),
  tagAccessData: state.summary.get(TAG_ACCESS_SUCC),
  gpuRateData: state.summary.get(GPU_RATE_SUCC),
  gpuAccessData: state.summary.get(GPU_ACCESS_SUCC),
  cpuRateData: state.summary.get(CPU_RATE_SUCC),
  cpuAccessData: state.summary.get(CPU_ACCESS_SUCC),
  realTimeTopIssueData: state.summary.get('realTimeTopIssueData'),
  realTimeAppendData: state.summary.get('realTimeAppendData'),
  loading: state.global.get('loading'),
  isDemoApp: state.global.get('isDemoApp'),
  location: router.location,
  leftMenuState: state.global.get('leftMenuState'),
  getHasSpecifiedPermission: (permission) => selectHasSpecifiedPermissionInCurrentApp(state, permission),
    selectFrontendWebVolatileConfigValueByKey: (key) => selectFrontendWebVolatileConfigValueByKey(state, key),
  };
  })
@PureRender
@withTranslation()
export default class ProductSummaryPage extends Component {
  static contextTypes = {
    actions: PropTypes.object,
  }

  static propTypes = {
    currentAppId: PropTypes.string.isRequired,
    routerPid: PropTypes.string.isRequired,
    currentApp: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    searchParams: PropTypes.object.isRequired,
    realTimeAppendData: PropTypes.object.isRequired,
    selectOptions: PropTypes.object.isRequired,
    isDemoApp: PropTypes.bool,
    loading: PropTypes.bool,
    compareData: PropTypes.object,
    trendData: PropTypes.object,
    realTimeTrendData: PropTypes.object,
    realTimeCompareData: PropTypes.object,
    topIssueData: PropTypes.object,
    isShowTop3: PropTypes.bool,
    realTimeTopIssueData: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]),
    appRateData: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]),
    appAccessData: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]),
    systemRateData: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]),
    systemAccessData: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]),
    modelRateData: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]),
    modelAccessData: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]),
    tagRateData: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]),
    tagAccessData: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]),
    gpuRateData: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]),
    gpuAccessData: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]),
    cpuRateData: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]),
    cpuAccessData: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]),
    getHasSpecifiedPermission: PropTypes.func,
  }

  constructor(props) {
    super(props);
    this.state = {
      hasPermission: true,
      permissionLoaded: false,
      trendDateOutOfRange: false,
      topIssueCount: 20, // 按日top展示条数

      isTrendDataBeforeGrayStrategyDrop: false, // true: 灰名单丢弃前的趋势数据 false: 灰名单丢弃后趋势数据
      sortOption: 'affect',

      tapdModalVisible: false,
      tapdModalIssueInfoList: [],
      isTrendVisible: true,

      statByCountryForceFetchTrigger: 0,
      statByUserSceneTagForceFetchTrigger: 0,
    };
  }

  componentDidMount() {
    this.loadInitialData(this.props);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const nextProps = this.props;
    const nextQuery = parseSearch(nextProps.location);
    if (!prevProps.currentApp.equals(nextProps.currentApp)) {
      this.loadInitialData(nextProps);
      return;
    } else if (routeReclick(nextProps.location, prevProps.location) && Object.keys(nextQuery).length === 1) {
      this.loadInitialData(nextProps);
      return;
    }

    if (this.props.searchParams.get('subModuleId') !== prevProps.searchParams.get('subModuleId')) {
      this.reloadDashboardWhenSubModuleIdChange(nextProps);
      return;
    }

    if (this.props.selectOptions.get('vmSelectValue') !== prevProps.selectOptions.get('vmSelectValue')) {
      // TODO: handleChangeSearchParam里面逻辑写的巨复杂，不好改，就当作是改了trendVersion触发一下更新
      const { searchParams } = this.props;
      const { trendVersion } = searchParams.toJS();
      this.handleChangeSearchParam('trendVersion', trendVersion);
    }
  }

  getIsRegionalDashboard() {
    const { routerPid } = this.props;
    const platformId = routerPid;
    return ProductSummaryUtil.getIsRegionalDashboard(platformId);
  }

  getHasViewDashboardPermission() {
    return this.props.getHasSpecifiedPermission(RolePermission.VIEW_DASHBOARD);
  }

  getHasViewDashboardUserSceneTagPermission() {
    return this.props.getHasSpecifiedPermission(RolePermission.VIEW_DASHBOARD_USER_SCENE_TAG);
  }

  // 当前项目是否是被其他app接入了的公共组件
  getHasIntegratedApps() {
    const { currentAppId } = this.props;
    const appIdWhitelist = (this.props.selectFrontendWebVolatileConfigValueByKey(
      FrontendWebVolatileConfigKeys.HAS_INTEGRATED_APPS_APP_ID_WHITELIST) || '')
      .split(',')
      .map(x => x.trim())
      .filter(x => x);
    return appIdWhitelist.includes(currentAppId);
  }

  getServerAppSettings() {
    const { reduxState, currentAppId: appId } = this.props;
    return reduxState.app.get('appIdToServerAppSettings')[appId] || {};
  }

  getIsEnableSubModuleId() {
    const serverAppSettings = this.getServerAppSettings();
    return !!serverAppSettings[ServerAppSettings.keys.enableSubModuleId];
  }

  getIsEnableUserSceneTag() {
    const serverAppSettings = this.getServerAppSettings();
    return !!serverAppSettings[ServerAppSettings.keys.enableUserSceneTag]
      && this.getHasViewDashboardUserSceneTagPermission();
  }

  // 是否关闭按国家维度统计
  getIsDisableStatsByCountry() {
    return !!this.getServerAppSettings()[ServerAppSettings.keys.disableStatsByCountry];
  }

  // 是否展开32位机型CPU展示
  getIsEnableDevicesPercentage() {
    return !!this.getServerAppSettings()[ServerAppSettings.keys.enableDevicesPercentage];
  }

  // 获取当前项目配置的丢弃上报策略
  getReportDiscardStrategy() {
    const { currentAppId, reduxState } = this.props;
    return reduxState.app.get('appIdToReportDiscardStrategy')[currentAppId];
  }

  // 获取当前项目是否有错误类型的的丢弃上报策略
  getHasErrorReportDiscardStrategy() {
    const reportDiscardStrategy = this.getReportDiscardStrategy();
    if (isEmpty(reportDiscardStrategy)) {
      return false;
    }
    const { appStrategyList, issueStrategyTotalCount } = reportDiscardStrategy;
    const errorAppStrategyList = (appStrategyList || []).filter(x => x.exceptionType === 'error');
    // issue级别的丢弃策略没有异常大类信息，目前就先全算到error上
    return errorAppStrategyList.length > 0 || issueStrategyTotalCount > 0;
  }

  makeChartTagsByCurrentApp(searchParams, selectOptions, pageType) {
    const { currentApp } = this.props;
    const platformId = currentApp.get('platformId');
    const tags = getChartTags(searchParams, selectOptions, pageType);
    const hideAccessCount = false;  // 当前全量开放
    if (!hideAccessCount) {
      return tags;
    }
    return tags.filter((x) => {
      return ![
        'EXCP_OVERVIEW.occurNumCrashRate',
        'EXCP_OVERVIEW.occurNumANRRate',
        'EXCP_OVERVIEW.occurNumErrorRate',
        'EXCP_OVERVIEW.sessionCount',
      ].includes(x);
    });
  }

  // 选择对比日期时，根据选择的日期和trendDate的长度，计算compareDate
  // 已废弃，现在compareDate可以自由设置不需要参考trendDate
  getCompareDateObjWhenChangeCompareDate(startDate) {
    const { searchParams } = this.props;
    const { date1, startDate1, endDate1 } = searchParams.toJS();
    const dateTmp = getStartEndDateFromStr(date1, startDate1, endDate1);
    const endDate2 = moment(startDate).add(dateTmp.len, 'days').format('YYYYMMDD');
    return { date2: 'custom', startDate2: moment(startDate).format('YYYYMMDD'), endDate2 };
  }

  convertToTrendDateObj({ date, startDate, endDate }) {
    return { date1: date, startDate1: startDate, endDate1: endDate };
  }

  convertToCompareDateObj({ date, startDate, endDate }) {
    if (date === 'custom') {
      return { date2: date, startDate2: startDate, endDate2: endDate };
    }
    return { date2: date, startDate2: null, endDate2: null };
  }

  handleChangePageType(pageType) {
    const { location, routerPid, searchParams, selectOptions } = this.props;
    const pid = routerPid;
    const { actions } = this.context;
    const { isRealTime, compareVersion } = searchParams.toJS();
    actions.changePageType(pageType);

    const tagArr = this.makeChartTagsByCurrentApp(searchParams, selectOptions, pageType);
    if (tagArr.indexOf(searchParams.get('trendTagName')) === -1) {
      actions.changeTrendTag(tagArr[0]);
    }

    const tasks = [
      parseInt(isRealTime) === 1
        ? actions.getTrendOrCompareData({ trend: true }).then((res) => {
          let hour = '';
          if (res && res.data && res.data.length > 0) {
            hour = res.data[res.data.length - 1].date;
          }
          return actions.fetchRealTimeTopIssueAndSetState({ hour });
        })
        : actions.getTrendOrCompareData({ trend: true }),

    ];

    if (compareVersion !== 'no_compare') {
      tasks.push(actions.getTrendOrCompareData({ compare: true }));
    }
    actions.showLoading();
    Promise.all(tasks).then(actions.hideLoading);

    // 非首屏，放在promise.all 外加载数据，提高响应速度
    if (!ExceptionCategoryUtil.isOom(pageType)) {
      actions.getTopIssue({topIssueDataType: searchParams.get('topIssueDataType'), limit: this.state.topIssueCount});
    }
    actions.getRealTimeAppendData();

    actions.pushState({
      pathname: location.pathname,
      search: generateSearch(location.search, { exceptionCategory: ExceptionCategoryUtil.unify(pageType) }),
    });

    // 初始化排行榜数据
    if (!ExceptionCategoryUtil.isOom(pageType)) {
      this.initRankData(searchParams.get('rankVersion'));
    }
  }

  handleShowClickTip() {
    const { searchParams } = this.props;
    if (parseInt(searchParams.get('isRealTime')) === 1) {
      this.clickTip.style.display = 'block';
    }
  }

  handleHideClickTip() {
    const { searchParams } = this.props;
    if (searchParams.get('isRealTime') === 1) {
      this.clickTip.style.display = 'none';
    }
  }

  handleDataTypeChange(e) {
    this.handleChangeSearchParam('dataType', e.target.value);
  }

  handleTopIssueDataTypeChange(v) {
    this.handleChangeSearchParam('topIssueDataType', v);
  }

  onTopIssueDateChange(v) {
    const { location, searchParams } = this.props;
    const { actions } = this.context;
    const query = { topIssueDate: v };

    actions.pushState({
      pathname: location.pathname,
      search: generateSearch(location.search, query),
    });
    actions.getTopIssue({ topIssueDataType: searchParams.get('topIssueDataType'), limit: this.state.topIssueCount });
  }

  onTopIssueParamsChange(patchParams) {
    const { location, searchParams } = this.props;
    const { actions } = this.context;

    actions.updateSummarySearchParams(patchParams);
    actions.pushState({
      pathname: location.pathname,
      search: generateSearch(location.search, patchParams, true),
    });
    actions.getTopIssue({ topIssueDataType: searchParams.get('topIssueDataType'), limit: this.state.topIssueCount });
  }

  onTopIssueVersionChange(v) {
    const { location, searchParams } = this.props;
    const { actions } = this.context;
    const query = {
      topIssueVersion: v,
    };

    actions.pushState({
      pathname: location.pathname,
      search: generateSearch(location.search, query),
    });
    actions.getTopIssue({ topIssueDataType: searchParams.get('topIssueDataType'), limit: this.state.topIssueCount });
  }

  onTopIssueCountryChange(v) {
    const { location, searchParams } = this.props;
    const { actions } = this.context;
    const query = {
      topIssueCountry: v,
    };

    actions.pushState({
      pathname: location.pathname,
      search: generateSearch(location.search, query),
    });
    actions.getTopIssue({topIssueDataType: searchParams.get('topIssueDataType'), limit: this.state.topIssueCount});
  }

  onTopIssueEnableFilterChange(v) {
    const { location, searchParams } = this.props;
    const { actions } = this.context;
    const query = {
      topIssueEnableFilter: v,
    };

    actions.pushState({
      pathname: location.pathname,
      search: generateSearch(location.search, query),
    });
    actions.getTopIssue({topIssueDataType: searchParams.get('topIssueDataType'), limit: this.state.topIssueCount});
  }

  onTopIssueFilterRawSearchChange(v) {
    const { location, searchParams } = this.props;
    const { actions } = this.context;
    const query = {
      topIssueFilterRawSearchJsonStr: JSON.stringify(v),
    };

    actions.pushState({
      pathname: location.pathname,
      search: generateSearch(location.search, query),
    });
    actions.getTopIssue({topIssueDataType: searchParams.get('topIssueDataType'), limit: this.state.topIssueCount});
  }

  onTopIssueIntegratedAppIdChange(v) {
    const { location, searchParams, currentAppId } = this.props;
    const { actions } = this.context;
    const query = {
      topIssueIntegratedAppId: v,
      topIssueVersion: '-1',
    };

    actions.pushState({
      pathname: location.pathname,
      search: generateSearch(location.search, query),
    });
    if (v && v !== '-1') {
      actions.fetchSdkIntegratedAppVersions(currentAppId, v);
    }
    actions.getTopIssue({topIssueDataType: searchParams.get('topIssueDataType'), limit: this.state.topIssueCount});
  }

  onTopIssueSortFieldChange(v) {
    const { location, searchParams } = this.props;
    const { actions } = this.context;
    const query = {
      topIssueSortField: v,
    };

    actions.pushState({
      pathname: location.pathname,
      search: generateSearch(location.search, query),
    });
    actions.getTopIssue({topIssueDataType: searchParams.get('topIssueDataType'), limit: this.state.topIssueCount});
  }

  onTopIssueCountChange(v) {
    const { location, searchParams } = this.props;
    const { actions } = this.context;
    this.setState({ topIssueCount: v });
    actions.getTopIssue({ topIssueDataType: searchParams.get('topIssueDataType'), limit: v });
  }

  handleChangeIssueStatus(members, type, item) {
    const { actions } = this.context;
    actions.activePopAlert({
      issueId: item.issueId,
      assigneeList: item.issueInfo.assigneeList,
      members,
      status: getStatus(item.state),
      page: type,
    });
  }

  onClickUpsertTapdBugByIssueIdList(issueIdList, isRealtimeNotDaily) {
    const {
      topIssueData,
      realTimeTopIssueData,
    } = this.props;
    const rawTopIssueList = (isRealtimeNotDaily ? realTimeTopIssueData : topIssueData).toJS().topIssueList;
    const tapdModalIssueInfoList = rawTopIssueList.filter(x => issueIdList.includes(x.issueId))
      .map(x => x.issueInfo); // top问题的issueList，issueInfo是嵌套在里面的

    this.setState({
      tapdModalIssueInfoList,
      tapdModalVisible: true,
    });
  }

  handleClickTop3() {
    const { isShowTop3 } = this.props;
    const { actions } = this.context;
    actions.changeTop3Status(!isShowTop3);
    if (!isShowTop3) {
      reportEvent({
        action: EVENT_ACTIONS.CLICK,
        tp1: '每小时TOP问题-展开',
      });
    }
  }

  /**
   * 改变崩溃排行搜索状态
   */
  handleChangeRankSearchParams(patchParams) {
    const { location, searchParams } = this.props;
    const { actions } = this.context;

    actions.updateSummarySearchParams(patchParams);
    actions.pushState({
      pathname: location.pathname,
      search: generateSearch(location.search, patchParams, true),
    });
    // 初始化排行榜数据
    this.initRankData(searchParams.get('rankVersion'));
  }

  handleClickRankTag(value) {
    console.log(value);
    const { actions } = this.context;
    actions.changeSummaryRankTag(value);
    actions[value]();
  }
  setSortOption(value){
    this.setState({ sortOption: value });
  }

  handleClickChart(params) {
    const { actions } = this.context;
    const { searchParams } = this.props;
    const {
      date1, startDate1, endDate1, isRealTime, trendVersion,
    } = searchParams.toJS();
    const trendDate = getStartEndDateFromStr(date1, startDate1, endDate1);// trendDate
    const dataHour = trendDate.startDate + params.name;
    if (parseInt(isRealTime) !== 1) {
      return;
    }
    // 更新点击top issue时间点状态
    actions.showLoading();
    actions.fetchRealTimeTopIssueAndSetState({ hour: dataHour, version: trendVersion }, true).then(() => {
      actions.hideLoading();
    });
  }

  // 真机/模拟器select切换的回调
  onVmFilterSelectChange(v) {
    const { actions } = this.context;
    console.log('onVmFilterSelectChange>>>', v)
    actions.changeSummaryVmSelectValue(v);
  }

  //计算下载按日崩溃数据的崩溃率
  crashRate(x) {
    const { selectOptions } = this.props;
    const { pageType } = selectOptions.toJS();
    if (pageType === '崩溃'){
      return x.crashNum > 0 && x.accessNum > 0 ?`${(100 * x.crashNum / x.accessNum).toFixed(2)} %` : '0.00%';
    } else {
      return x.crashNum > 0 && x.accessNum > 0 ?`${(x.crashNum / x.accessNum).toFixed(4)} ` : '0.00';
    }
  }

  //将下载时间格式化
  dateFormatting(date) {
    // 解析原始日期时间字符串
    const dateTimeString = moment(date, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm');

    // 如果时间已经是当天的 23:00 - 23:58 分钟之间，将它增加 59 分钟
    const hour = moment(dateTimeString).hour();
    const minute = moment(dateTimeString).minute();
    const newDateTime = moment(dateTimeString);
    if (hour === 23 && minute >= 0 && minute <= 58) {
      newDateTime.add(59, 'minute');
    } else {
      newDateTime.add(1, 'hour');
    }

    // 格式化新的日期时间
    const newDateString = newDateTime.format('YYYY-MM-DD HH:mm');
    return `${dateTimeString}  -  ${newDateString}`;
  }

  onClickDownloadDailyTrendDataAsSheets() {
    const realTimeTrendData = this.props.realTimeTrendData.toJS();
    const { isRealTime } = this.props.searchParams.toJS()
    const trendData = !isRealTime ? this.props.trendData.toJS() : realTimeTrendData;
    const currentApp = this.props.currentApp.toJS();
    const { t, selectOptions, searchParams, reduxState } = this.props;
    const isRegionalDashboard = this.getIsRegionalDashboard();
    let countryList = {};
    if(isRegionalDashboard){
      const { trendCountry, compareCountry, trendVersion } = searchParams.toJS();
      const trendCountryList = this.getCountryList(trendCountry);
      const compareCountryList = this.getCountryList(compareCountry);
      const isCountryDimensionMode = trendCountryList.length > 0 || compareCountryList.length > 0;
      const countryGroupConfigList = reduxState.summary.get('countryGroupConfigList').toJS();
      const trendCountryListWithAll = trendCountryList.length > 0
        ? CountryUtil.convertPrefixedGroupIdToName(countryGroupConfigList, trendCountryList)
        : '-';
      const trendLegendList = isCountryDimensionMode
      ? [...trendCountryListWithAll]
      : [parseInt(trendVersion) === -1 ? '-' : decodeURIComponent(trendVersion)];
      for(let item in trendCountryList){
        countryList[trendCountryList[item]] = trendLegendList[item];
      }
    }
    const { pageType } = selectOptions.toJS();
    const { appName, pid } = currentApp;
    const header = [
      t('EXCP_OVERVIEW.日期'),
      ...(isRegionalDashboard ? [ze('国家/地区', 'Country / Region')] : []),
      t('EXCP_OVERVIEW.userCrashRate'),
      t(EXCEPTION_HEAD_DATA_TYPE_DOWNLOAD[pageType]),
      t('EXCP_OVERVIEW.occurNumber'),
      t('EXCP_OVERVIEW.userNumAffect'),
      t('EXCP_OVERVIEW.sessionCount'),
      t('EXCP_OVERVIEW.userNumConnect'),
    ];
    const rows = trendData.map(x => [
      !isRealTime ? x.date : this.dateFormatting(x.date),
      ...(isRegionalDashboard ? [x.country == -1 ? '-' : countryList[x.country]] : []),
      x.accessUser > 0 ? `${(x.crashUser * 100 / x.accessUser)} %` : '0.00 %',
      this.crashRate(x),
      x.crashNum,
      x.crashUser,
      x.accessNum,
      x.accessUser,
    ]);
    const totalRow = [
      t('EXCP_OVERVIEW.总计'),
      ...(isRegionalDashboard ? ['-'] : []),
      '-',
      '-',
      trendData.map(x => x.crashNum).reduce((a, b) => a + b, 0),
      trendData.map(x => x.crashUser).reduce((a, b) => a + b, 0),
      trendData.map(x => x.accessNum).reduce((a, b) => a + b, 0),
      trendData.map(x => x.accessUser).reduce((a, b) => a + b, 0),
    ];
    const data = [header, ...rows, totalRow];
    const beginDate = Math.min(...trendData.map(x => x.date));
    const endDate = Math.max(...trendData.map(x => x.date));
    const filename = !isRealTime
      ? `${ExceptionCategoryUtil.toI18n(pageType, pid)}${t('EXCP_OVERVIEW.trend')}_${appName}_pid${pid}_${beginDate}-${endDate}.xlsx`
      : `${ExceptionCategoryUtil.toI18n(pageType, pid)}${t('EXCP_OVERVIEW.trend')}_${ze('下载按小时数据','Download hourly Crash data')}.xlsx`;
    XlsxUtil.createExcelAndDownload(filename, data);
  }

  getCountryList(country) {
    const ALL_COUNTRY = '-1';
    const isRegionalDashboard = this.getIsRegionalDashboard();
    return isRegionalDashboard && country && country !== ALL_COUNTRY
      ? country.split(',')
      : [];
  }

  onClickDownloadDailyTopIssues() {
    const { t, searchParams } = this.props;
    const topIssueData = this.props.topIssueData.toJS();
    const topIssueDataType = searchParams.get('topIssueDataType');
    const topIssueEnrichedIssueList = TopIssueUtils.makeTopIssueEnrichedIssueList(topIssueData, topIssueDataType);

    const columns = [
      { label: t('issueCrashFilterKey.issueId'), field: 'issueId' },
      { label: ze('平台链接', 'Website URL'), field: 'issueWebsiteUrl', isHyperlink: true },
      { label: t("issueCrashFilterKey.errorType"), field: 'exceptionName' },
      { label: t("TOPISSUELIST.exceptionInfo"), field: 'exceptionMessage' },
      { label: t("TOPISSUELIST.stack"), field: 'keyStack' },
      { label: t("TOPISSUELIST.affectedDevices"), field: 'crashUser' },
      {
        label:t("TOPISSUELIST.proportion"),
        field: 'toAllCrashDevicesProportionDecimal',
        isPercentFormat: true,
      },
      {
        label: t("TOPISSUELIST.issueReportRate"),
        field: 'toAllAccessDevicesProportionDecimal',
        isPercentFormat: true,
      },
      {
        label: t("REALTIMETOPISSUE.occurNum"),
        field: 'crashNum',
      },
      { label: t("TOPISSUELIST.dayOnDayGrowth"), field: 'fluctuateDecimal', isPercentFormat: true },
      { label: ze('标签', 'Tags'), field: 'commaJoinedTagNames' },
      { label: t("TOPISSUELIST.firstPostTime"), field: 'formattedFirstUploadTime' },
      { label: t("TOPISSUELIST.userAffectTotal"), field: 'accumulateCrashUser' },
      { label: t("TOPISSUELIST.numAffectTotal"), field: 'accumulateCrashNum' },
    ];

    function excelFriendlyClamp(number) {
      const CLAMP_MAX = 1e6 - 1e-4;
      const CLAMP_MIN = -CLAMP_MAX;
      if (number > CLAMP_MAX) {
        return CLAMP_MAX;
      }
      if (number < CLAMP_MIN) {
        return CLAMP_MIN;
      }
      return number;
    }

    function makeCellObject(rawValue, column, isHeader) {
      if (column.isPercentFormat) {
        return {
          v: isHeader ? rawValue : excelFriendlyClamp(rawValue),
          t: isHeader ? 's' : 'n',
          z: '0.00%',
        };
      }
      // excel, word有个已知问题，点击超链接会丢失登录态，这个以后再解决，现在先不生成超链接：
      // https://github.com/spilliton/fix_microsoft_links
      /* if (column.isHyperlink && !isHeader) {
        return {
          v: rawValue,
          l: {
            Target: rawValue,
          },
        };
      } */
      return rawValue;
    }

    const header = columns.map(column => makeCellObject(column.label, column, true));
    const body = topIssueEnrichedIssueList.map(topIssue => {
      return columns.map(column => makeCellObject(topIssue[column.field], column, false));
    });
    XlsxUtil.createExcelAndDownload(ze('TOP问题数据.xlsx', 'Top Issues.xlsx'), [header, ...body]);
  }

  handleChangeSearchParam(type, value, isPreventSearch) {
    const { actions } = this.context;
    const { searchParams, location, currentAppId } = this.props;
    const { compareVersion, dataType } = searchParams.toJS();
    let isRealTime = parseInt(searchParams.get('isRealTime'));

    if (type === 'headDataType') {
      actions.setPartialLocalStorage({ summaryHeadDataType: value });
      // 点击“头部数据”，进行上报
      reportEvent({
        action: EVENT_ACTIONS.CLICK,
        tp1: '头部数据',
        tp6: value,
      });
    }

    let query = fromJS({});
    switch (type) {
      case 'isIntegratedAppTrend':
        query = query.set(type, value);
        if (searchParams.get('trendVersion') !== '-1') {
          query = query.set('trendVersion', '-1');
        }
        if (searchParams.get('compareVersion') !== 'no_compare' && searchParams.get('compareVersion') !== '-1') {
          query = query.set('compareVersion', '-1');
        }
        break;
      case 'headDataType':
      case 'integratedAppId':
      case 'dataType':
      case 'topIssueDataType':
      case 'enableRealtimePrevDayTrend':
      case 'enableRealtimePrevWeekTrend':
        query = query.set(type, value);
        break;
      case 'trendVersion':
        query = query.set(type, value || -1);
        break;
      case 'trendCountry':
        query = query.set(type, value.join(',') || '-1');
        break;
      case 'compareCountry':
        query = query.set(type, value.join(',') || '-1');
        break;
      case 'trendUserSceneTag':
        query = query.set(type, value.join(','));
        break;
      case 'compareVersion':
        query = query.set(type, value);
        if (searchParams.get('startDate2')) {
          query = query.merge(this.getCompareDateObjWhenChangeCompareDate(searchParams.get('startDate2')));
        }
        break;
      case 'isRealTime': {
        isRealTime = value;
        const { date1, date2, startDate1, startDate2, endDate1, endDate2 } = searchParams.toJS();
        query = query.set(type, value);
        if (value) {
          query = query.merge(ProductSummaryUtil.convertToTrendDateObj(ProductSummaryUtil.convertDailyToHourlyDateObj(date1, startDate1, endDate1)))
            .merge(ProductSummaryUtil.convertToCompareDateObj(ProductSummaryUtil.convertDailyToHourlyDateObj(date2, startDate2, endDate2)));
        } else {
          query = query.merge(ProductSummaryUtil.convertToTrendDateObj(ProductSummaryUtil.convertHourlyToDailyDateObj(date1, startDate1, endDate1, 6)))
            .merge(ProductSummaryUtil.convertToCompareDateObj(ProductSummaryUtil.convertHourlyToDailyDateObj(date2, startDate2, endDate2, 6)));
        }
        break;
      }
      case 'compareDate': {
        const { startDate, endDate } = getStartEndDateFromStr(value.date, value.startDate, value.endDate);
        query = query.merge(this.convertToCompareDateObj({ startDate, endDate, date: value.date }));
        break;
      }
      case 'trendDate': {
        // 改变trendDate决定是否进入实时，因此这里的isRealTime用当前值，state的值是旧的
        let { len, startDate: startDate1, endDate: endDate1 } = getStartEndDateFromStr(value.date, value.startDate, value.endDate);
        isRealTime = (len === 0 ? 1 : 0);
        if (value.date !== 'custom') {
          startDate1 = null;
          endDate1 = null;
        }
        query = query.set('isRealTime', isRealTime).merge({
          startDate1, endDate1, date1: value.date,
        });
        break;
      }
      default:
        throw new Error('unhandle switch default!');
    }

    actions.pushState({
      pathname: location.pathname,
      search: generateSearch(location.search, query.toJS(), true),
    });

    const oldQueryObj = searchParams.toJS();
    const newQueryObj = Object.assign({}, oldQueryObj, query.toJS());

    // 判断按天趋势范围是否超过180天
    let outOfRange = false;
    if (isRealTime === 0 && value.date === 'custom') {
      const diff = getDateDiff2(value.startDate, value.endDate);
      if (diff >= 180) {
        outOfRange = true;
        this.setState({ trendDateOutOfRange: true });
      } else {
        this.setState({ trendDateOutOfRange: false });
      }
    } else {
      this.setState({ trendDateOutOfRange: false });
    }

    const tasks = [];
    switch (type) {
      case 'compareDate':
      case 'compareVersion':
      case 'compareCountry':
        if (isPreventSearch) {
          break;
        }
        if (!outOfRange) {
          actions.showLoading();
          tasks.push((dataType === 'appendly' && isRealTime === 1) ? actions.getTrendOrCompareDataAppend({ compare: true }) : actions.getTrendOrCompareData({ compare: true }));
          Promise.all(tasks).then(actions.hideLoading);
        }
        break;
      case 'enableRealtimePrevDayTrend':
      case 'enableRealtimePrevWeekTrend': {
        if (isPreventSearch) {
          break;
        }
        actions.showLoading();
        tasks.push((dataType === 'appendly' && isRealTime === 1) ? actions.getTrendOrCompareDataAppend({ compare: false }) : actions.getTrendOrCompareData({ compare: false }));
        Promise.all(tasks).then(actions.hideLoading);
        break;
      }
      case 'isIntegratedAppTrend':
      case 'isRealTime':
      case 'integratedAppId':
      case 'trendDate':
      case 'trendVersion':
      case 'trendCountry':
      case 'trendUserSceneTag':
        if (type === 'integratedAppId') {
          actions.fetchSdkIntegratedAppVersions(currentAppId, value);
        }
        if (isPreventSearch) {
          break;
        }
        if (!outOfRange) {
          actions.showLoading();
          actions.changeTop3Status(false);
          const { date1, startDate1, endDate1 } = newQueryObj;
          const { startDate, endDate } = getStartEndDateFromStr(date1, startDate1, endDate1);

          const lastDate = searchParams.get('realTimeTopIssueHour');
          let hour = '';
          // 如果是切换到某天内的数据，请求topissue时指定hour
          if (lastDate && startDate && endDate && startDate === endDate) {
            hour = startDate + lastDate.substring(8, lastDate.length);
            tasks.push(
              actions.fetchRealTimeTopIssueAndSetState({ hour })
            );
          } else {
            tasks.push(
              actions.fetchRealTimeTopIssueAndSetState({ hour })
            );
          }

          tasks.push((dataType === 'appendly' && isRealTime === 1) ? actions.getTrendOrCompareDataAppend({ trend: true }) : actions.getTrendOrCompareData({ trend: true }));
          if (compareVersion !== 'no_compare') {
            tasks.push((dataType === 'appendly' && isRealTime === 1) ? actions.getTrendOrCompareDataAppend({ compare: true }) : actions.getTrendOrCompareData({ compare: true }));
          }
          Promise.all(tasks).then(actions.hideLoading);
        }
        break;
      case 'dataType':
        actions.changeDataType(value);
        actions.showLoading();
        console.log('isAppend in handleChangeSearchParam', value === 'appendly' && isRealTime === 1);
        // in dataType case, both trend and compare data should load
        if (value === 'appendly' && isRealTime === 1) {
          // 累计数据
          tasks.push(actions.getTrendOrCompareDataAppend({
            trend: true,
            compare: true,
          }));
        } else {
          // 实时数据
          tasks.push(actions.getTrendOrCompareData({
            trend: true,
            compare: true,
          }));
        }
        Promise.all(tasks).then(actions.hideLoading);
        break;
      case 'topIssueDataType':
        actions.changeTopIssueDataType(value);
        actions.showLoading();
        console.log('unSystemExit in handleChangeSearchParam', value === 'unSystemExit');
        tasks.push(actions.getTopIssue({ topIssueDataType: value, limit: this.state.topIssueCount }));
        Promise.all(tasks).then(actions.hideLoading);
        break;
      default:
        console.log(`${TAG} handleChangeSearchParam(): unhandle switch default`);
        break;
    }
  }

  async initRankData(rankVersion) {
    const { actions } = this.context;
    const { selectOptions, routerPid } = this.props;
    const { appTag, modelTag, systemTag, gpuTag, cpuTag, tagTag } = selectOptions.toJS();

    actions.updateSummarySearchParams({ rankLoading: true });
    let tasks = [];
    if (parseInt(routerPid) === PLATFORM_ID.PC) {
      tasks = [
        actions[gpuTag](),
        actions[cpuTag](),
        actions[systemTag](),
      ];
    } else {
      tasks = [
        actions[modelTag](),
        actions[systemTag](),
      ];
    }

    // 全版本才重新查询top应用版本
    if (rankVersion === '-1') {
      tasks.push(actions[appTag]());
    }

    await Promise.all(tasks);
    actions.updateSummarySearchParams({ rankLoading: false });
  }

  async loadInitialData(props) {
    const { routerPid, currentApp, selectOptions, currentAppId } = props;
    const pid = routerPid;
    if (!currentApp.size) {
      return;
    }
    const isRegionalDashboard = this.getIsRegionalDashboard();

    document.title = `${this.props.t('EXCP_LEFTMENU.overview')}-${currentApp.get('appName')}`;
    this.setState({
      hasPermission: true,
      permissionLoaded: false,
    });

    const { actions } = this.context;
    const { searchParams, currentUser } = props;
    const {
      realTimeTopIssueHour,
      isRealTime,
      dataType,
      isIntegratedAppTrend,
      integratedAppId,
      topIssueIntegratedAppId,
    } = searchParams.toJS();

    const pageType = selectOptions.get('pageType');

    const tagArr = this.makeChartTagsByCurrentApp(searchParams, selectOptions);
    if (tagArr.indexOf(searchParams.get('trendTagName')) === -1) {
      actions.changeTrendTag(tagArr[0]);
    }

    actions.changeSummaryVmSelectValue(0); // 重置折线图的模拟器状态radio

    actions.showLoading();

    if (isRegionalDashboard) {
      await actions.fetchCountryGroupConfig();
    }

    if (!this.getHasViewDashboardPermission()) {
      this.setState({ hasPermission: false, permissionLoaded: true });
      actions.hideLoading();
      return;
    }
    this.setState({ permissionLoaded: true });

    const tasks = [];
    const isAppend = parseInt(isRealTime) === 1 && dataType === 'appendly';
    const isTrend = searchParams.get('compareVersion') !== 'no_compare';

    // Nintendo Switch没有全版本，所以要先加载版本列表。移动端、PC可以并行加载。
    if (isSwitch(pid)) {
      const { versionList } = await actions.getOptions('version,member,tag,channel');
      const [firstVersion] = versionList;
      if (firstVersion) {
        this.handleChangeSearchParam('trendVersion', firstVersion.name);
      }
    } else {
      actions.getOptions('version,member,tag,channel');
    }

    tasks.push(isAppend ? actions.getTrendOrCompareDataAppend({ trend: true }) : actions.getTrendOrCompareData({ trend: true }));

    if (isTrend) {
      tasks.push(isAppend ? actions.getTrendOrCompareDataAppend({ compare: true }) : actions.getTrendOrCompareData({ compare: true }));
    }

    if (this.getHasIntegratedApps()) {
      tasks.push(actions.fetchSdkIntegratedApps(currentAppId));
      if (isIntegratedAppTrend && integratedAppId) {
        tasks.push(actions.fetchSdkIntegratedAppVersions(currentAppId, integratedAppId));
      }
      if (topIssueIntegratedAppId && topIssueIntegratedAppId !== '-1') {
        tasks.push(actions.fetchSdkIntegratedAppVersions(currentAppId, topIssueIntegratedAppId));
      }
    }

    Promise.all(tasks).then((res) => {
      let hour = '';
      if (res && res[0] && res[0].data) {
        hour = res[0].data[res[0].data.length - 1].date;
      }
      if (parseInt(isRealTime) === 1) {
        actions.fetchRealTimeTopIssueAndSetState({ hour }).then((topData) => {
          // 如果当前无top3，则默认收起
          if (topData && topData.data) {
            if (!topData.data[realTimeTopIssueHour] || topData.data[realTimeTopIssueHour].length === 0) {
              actions.changeTop3Status(false);
            } else {
              actions.changeTop3Status(true);
            }
          }
        });
      }
    }).then(actions.hideLoading, actions.hideLoading);

    // 非核心接口，不loading
    actions.queryBigDataLastUpdateTime();
    actions.fetchReportDiscardStrategyForCurrentApp();
    actions.getRealTimeAppendData();
    if (!ExceptionCategoryUtil.isOom(pageType)) {
      actions.getTopIssue({topIssueDataType: searchParams.get('topIssueDataType'), limit: this.state.topIssueCount});
    }
    // 初始化排行榜数据
    if (!ExceptionCategoryUtil.isOom(pageType)) {
      this.initRankData(searchParams.get('rankVersion'));
    }
  }

  async reloadDashboardWhenSubModuleIdChange(props) {
    const { routerPid, currentApp, selectOptions, currentAppId, searchParams } = props;
    const { actions } = this.context;
    actions.showLoading();
    // TODO: 和loadInitialData有重复逻辑
    const {
      isRealTime,
      dataType,
    } = searchParams.toJS();

    this.setState({
      statByCountryForceFetchTrigger: this.state.statByCountryForceFetchTrigger + 1,
      statByUserSceneTagForceFetchTrigger: this.state.statByUserSceneTagForceFetchTrigger + 1,
    });

    const tasks = [];
    const isAppend = parseInt(isRealTime) === 1 && dataType === 'appendly';
    const isTrend = searchParams.get('compareVersion') !== 'no_compare';
    tasks.push(isAppend ? actions.getTrendOrCompareDataAppend({ trend: true }) : actions.getTrendOrCompareData({ trend: true }));
    if (isTrend) {
      tasks.push(isAppend ? actions.getTrendOrCompareDataAppend({ compare: true }) : actions.getTrendOrCompareData({ compare: true }));
    }
    tasks.push(actions.fetchRealTimeTopIssueAndSetState());
    tasks.push(actions.getRealTimeAppendData());
    tasks.push(actions.getTopIssue({ topIssueDataType: searchParams.get('topIssueDataType'), limit: this.state.topIssueCount }));
    await Promise.all(tasks);
    actions.hideLoading();
  }

  renderChartParams(trendTagName, t) {
    const { actions } = this.context;
    const { searchParams, selectOptions } = this.props;
    const tabKeys = this.makeChartTagsByCurrentApp(searchParams, selectOptions);

    const tabObjects = tabKeys.map(tab => {
      return {
        value: tab,
        label: t(tab),
        tooltip: getSummaryTagTooltipContent(tab),
      };
    });


    return (
      <div style={{marginTop: 20, marginBottom: 16, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        {`${searchParams.get('isRealTime')}` === '1' && <DataTypeSelect {...{ style, handleDataTypeChange: this.handleDataTypeChange.bind(this), searchParams }} />}
        <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
          {/* <CsTabs
            bigSize={true}
            activeKey={trendTagName}
            onChange={actions.changeTrendTag}
            items={tabObjects}
          /> */}
          <CsNewTabs options={tabObjects} value={trendTagName} onChange={actions.changeTrendTag} />
        </div>
      </div>
    );
  }

  renderDashboardGlobalSelectOptions() {
    const isEnableSubModuleId = this.getIsEnableSubModuleId();
    const { location, searchParams } = this.props;
    const { actions } = this.context;

    return isEnableSubModuleId && <Space style={{ fontSize: '16px' }}>
      <div>{ ze('子场景：', 'Sub Module ID: ') }</div>
      <SubModuleIdSelect
        value={searchParams.get('subModuleId')}
        onChange={v => {
          const query = {
            subModuleId: v,
          };
          actions.pushState({
            pathname: location.pathname,
            search: generateSearch(location.search, query),
          });
        }}
      />
    </Space>;
  }

  render() {
    const {
      location,
      reduxState,
      currentUser,
      routerPid, currentApp, currentAppId, isDemoApp, loading,
      selectOptions, searchParams, compareData, trendData,
      realTimeTrendData, realTimeCompareData, topIssueData,
      isShowTop3, realTimeTopIssueData, appRateData, appAccessData,
      systemRateData, systemAccessData, modelRateData, modelAccessData,
      tagAccessData, tagRateData,
      realTimeAppendData,
      gpuRateData, gpuAccessData, cpuRateData, cpuAccessData, t
    } = this.props;
    const { actions } = this.context;
    const {
      appTag, systemTag, modelTag, gpuTag, cpuTag,
      tagTag,
      processor, pageType, vmSelectValue
    } = selectOptions.toJS();
    const {
      subModuleId,
      isIntegratedAppTrend,
      trendTagName,
      isRealTime, realTimeTopIssueHour, headDataType, startDate1, topIssueDataType,
      topIssueLoading,
      rankLoading,
    } = searchParams.toJS();
    const bigDataRealtimeUpdateMillis = reduxState.summary.get('bigDataRealtimeUpdateMillis');
    const bigDataResultDailyUpdateMillis = reduxState.summary.get('bigDataResultDailyUpdateMillis');
    const pid = routerPid;
    const appId = currentAppId;
    const isRealtimeChart = Number(searchParams.get('isRealTime')) === 1;
    const demoApp = currentApp.get('demoApp');

    const isInTencentLocalNetwork = reduxState.user.get('isInTencentLocalNetwork');
    const issueIdToDailyTrendList = reduxState.issue.get('issueIdToDailyTrendList') || {};
    const { issueListTrendType } = reduxState.global.get('localStorage').toJS();
    const currentUserIsRtx = !!currentUser.get('rtx');
    const isSuperAdmin = !!currentUser.get('isSuper');
    const showDailyReportConfigButton = isInTencentLocalNetwork || currentUserIsRtx; // 内网用户或者企微登录用户可以配周报

    const isRegionalDashboard = this.getIsRegionalDashboard();

    const hasErrorReportDiscardStrategy = this.getHasErrorReportDiscardStrategy();
    const isErrorType = ExceptionCategoryUtil.isError(pageType);
    const isOomType = ExceptionCategoryUtil.isOom(pageType);

    let members = [];
    if (processor) {
      members = processor.options.map((item) => item);
    }

    const vmTypeListValue = CsvUtil.singleLineParse(vmSelectValue).filter(x => x.trim()).map(x => Number(x));

    const containerStyle = style.product_problem;
    const renderingStyle = `${searchParams.get('isRealTime')}` === '1' ? bigDataRealtimeUpdateMillis : null;

    const tapdModalProps = {
      visible: this.state.tapdModalVisible,
      onCancel: () => this.setState({ tapdModalVisible: false }),
      onOk: () => {
        this.setState({ tapdModalVisible: false });
        actions.getTopIssue({ topIssueDataType: searchParams.get('topIssueDataType'), limit: this.state.topIssueCount });
        actions.fetchRealTimeTopIssueAndSetState();
      },
    };

    if (!this.state.hasPermission) {
      return (
        <div className={containerStyle}>
          <div>{ this.props.t("EXCP_OVERVIEW.用户角色无权限") }</div>
        </div>
      );
    }

    return (
      <div className={containerStyle}>
        <div className={style.headerContainer}>
          <div className={style.page_title}>
            {/* { this.renderDashboardGlobalSelectOptions() } */}
            {/* <div style={{ flex: 1 }}/> */}
            <SummaryHead
              pid={pid}
              pageType={pageType}
              {...{ handleChangeSearchParam: this.handleChangeSearchParam.bind(this), headDataType}}
            />
            { showDailyReportConfigButton && <div style={{marginRight: '16px'}}><DailyReportConfigModalButton /></div> }
          </div>

          <div className={style.summaryTabArea}>
            <SummaryTab
              appId={currentApp.get('appId')}
              reportDiscardStrategy={this.getReportDiscardStrategy()}
              realTimeAppendData={realTimeAppendData}
              pageType={pageType}
              headDataType={headDataType}
              pid={currentApp.get('pid')}
              onClick={this.handleChangePageType.bind(this)}
            />
          </div>
        </div>
        <div className={style.product_summary}>
          <CardHead
            title={t("EXCP_OVERVIEW.trendWithType", { type: ExceptionCategoryUtil.toI18n(pageType, pid) })}
            hideDivider={!this.state.isTrendVisible}
            svgIcon={<TrendIcon />}
            iconExtraStyle={{height: '16px'}}
            onClick={() => {
              const isExpand = !this.state.isTrendVisible;
              ReportEventUtil.reportCardHeadClickEvent('崩溃趋势', false, isExpand);
              this.setState({ isTrendVisible: isExpand });
            }}
            >
            {/* { PlatformUtils.isAndroidOrHarmony(routerPid) && <div style={{marginLeft: '10px'}}>
              <VmTypeCheckboxGroup
                value={vmTypeListValue}
                onChange={(v) => this.onVmFilterSelectChange(CsvUtil.singleLineStringify(v))}
              />
            </div>} */}
            <div style={{display: 'flex', flexGrow: '1', alignItems: 'center', justifyContent: 'end'}}>
              <UpdateTimeTag updateTime={renderingStyle} type='fiveMinute' />
              <CsDownloadButton onClick={(e) => {
                e.stopPropagation();
                this.onClickDownloadDailyTrendDataAsSheets();
              }} style={{ margin: '0px 24px'}} />
              <CsCollapseButton
                extraStyle={{ width: '14px', height: '14px' }}
                isCollapsed={!this.state.isTrendVisible}
                enableHover={true}
                onClick={(e) => {
                  e.stopPropagation();
                  const isExpand = !this.state.isTrendVisible;
                  ReportEventUtil.reportCardHeadClickEvent('崩溃趋势', true, isExpand);
                  this.setState({ isTrendVisible: isExpand });
                }}
              />
              {/* {
                this.state.isTrendVisible ? <UpOutlined onClick={() => this.setState({ isTrendVisible: false })} />
                  : <DownOutlined onClick={() => this.setState({ isTrendVisible: true })} />
              } */}
            </div>

            {/* {!isRealtimeChart ? <div>
              <Button
                onClick={this.onClickDownloadDailyTrendDataAsSheets.bind(this)}
              >{t("EXCP_OVERVIEW.下载按日数据", { type: ExceptionCategoryUtil.toI18n(pageType, pid) })}</Button>
            </div> : <div>
              <Button
                onClick={this.onClickDownloadDailyTrendDataAsSheets.bind(this)}
              >{ze('下载按小时数据','Download hourly Crash data')}</Button>
            </div>} */}
          </CardHead>
          {/* <DividerWithTime
            {...{
              data:renderingStyle,
              type:'REALTIME',
            }} /> */}
          {
            this.state.isTrendVisible  ? <>
              <SummaryOptions
                {...{
                  style,
                  handleChangeSearchParam: this.handleChangeSearchParam.bind(this),
                  searchParams,
                  selectOptions,
                  isRegionalDashboard,
                  enableUserSceneTag: this.getIsEnableUserSceneTag(),
                  isErrorType,
                  hasErrorReportDiscardStrategy,
                  isTrendDataBeforeGrayStrategyDrop: this.state.isTrendDataBeforeGrayStrategyDrop,
                  hasIntegratedApps: this.getHasIntegratedApps(),
                  vmTypeListValue,
                  routerPid,
                  setIsTrendDataBeforeGrayStrategyDrop: (value) => this.setState({ isTrendDataBeforeGrayStrategyDrop: !!value }),
                  onVmFilterSelectChange: (value) => this.onVmFilterSelectChange(CsvUtil.singleLineStringify(value)),
                }} />

              {this.renderChartParams(trendTagName, t) }
              <div className={style.charts_container}>
                <TrendChart
                  {...{
                    style,
                    isTrendDataBeforeGrayStrategyDrop: isErrorType && this.state.isTrendDataBeforeGrayStrategyDrop,
                    selectOptions,
                    searchParams,
                    compareData,
                    realTimeCompareData,
                    trendData,
                    realTimeTrendData,
                    isRegionalDashboard: this.getIsRegionalDashboard(),
                    handleClickChart: this.handleClickChart.bind(this),
                    handleShowClickTip: this.handleShowClickTip.bind(this),
                    handleHideClickTip: this.handleHideClickTip.bind(this),
                    isProductSummaryPage: true,
                    handleChangeSearchParam: this.handleChangeSearchParam.bind(this),
                  }} />
                {(isRealTime === 0 && this.state.trendDateOutOfRange) && <p className={style.no_realtime_data_tip}>{this.props.t("EXCP_OVERVIEW.dataOutOfRangeTip")}</p> }
                { isRealTime === 1 && <span className={style.realtime_tip} ref={(val) => (this.clickTip = val)}>
                  <div style={{height:'24px',display:'flex',alignItems:'center',gap:'5px'}}>
                    <div style={{display:'inline-flex',alignItems:'center'}}><NoticeIconGreen width='12' height='12' viewBox="0 0 12 12"/></div>
                    <div>{this.props.t("EXCP_OVERVIEW.clickTip")}</div>
                  </div>
                  </span>}
              </div>
            </> : null
          }
        </div>

        { !!isRealTime && !isOomType && <div className={style.product_summary} style={{ minHeight: '0px' }}>
          <RealTimeTopIssue
            demoApp={demoApp}
            collapseIssues={isShowTop3}
            realTimeTopIssueData={realTimeTopIssueData.toJS()}
            handleChangeStatus={this.handleChangeIssueStatus.bind(this, members, 'realtimeTopIssue')}
            onToggleCollapseIssues={() => this.handleClickTop3()}
            onClickUpsertTapdBugByIssueIdList={(issueIdList) => this.onClickUpsertTapdBugByIssueIdList(issueIdList, true)}
            onBugUnbind={() => {
              // 小时和日级都要刷新，因为同一个issue可能两个地方都有
              actions.getTopIssue({ topIssueDataType: searchParams.get('topIssueDataType'), limit: this.state.topIssueCount });
              actions.fetchRealTimeTopIssueAndSetState();
            }}
            members={members}
            style={style}
            pageType={pageType}
            actions={actions}
            isRegionalDashboard={isRegionalDashboard}
          />
        </div> }

        { this.getIsEnableUserSceneTag() && <div className={style.product_summary}>
          <StatByUserSceneTag
            forceFetchTrigger={this.state.statByUserSceneTagForceFetchTrigger}
          />
        </div> }

        { this.state.permissionLoaded && isRegionalDashboard && !this.getIsDisableStatsByCountry() && !isOomType && <div className={style.product_summary} style={{ minHeight: 0 }}>
          <StatByCountry
            forceFetchTrigger={this.state.statByCountryForceFetchTrigger}
          />
        </div> }

        { this.getHasIntegratedApps() && !isOomType && <div className={style.product_summary}>
          <StatByIntegratedApp/>
        </div> }

        { this.state.permissionLoaded && isAndroidOrHarmony(pid) && isRegionalDashboard && this.getIsEnableDevicesPercentage() && !isOomType && <div className={style.product_summary} style={{ minHeight: 0 }}>
          <ModelBitTrend/>
        </div> }

        { !isGameConsole(pid) && !isOomType && <div className={style.product_summary} style={{ minHeight: 0 }}><Spin spinning={rankLoading}>
          {/* <RankingListOptions
            {...{
              appId,
              pid,
              style,
              handleChangeRankSearchParams: this.handleChangeRankSearchParams.bind(this),
              searchParams,
              selectOptions,
              isRegionalDashboard,
              bigDataResultDailyUpdateMillis,
            }}
            setSortOption={(val) => this.setSortOption(val)}
            changeTag={(val) => this.handleClickRankTag(val)}
          /> */}
          {/* <DividerWithTime
            {...{
              data:bigDataResultDailyUpdateMillis,
              type:'DAILY',
            }} /> */}
          { subModuleId === '-1' && <RankingList
            {...{
              style,
              appTag,
              systemTag,
              modelTag,
              gpuTag,
              cpuTag,
              tagTag,
              appRateData,
              appAccessData,
              systemRateData,
              systemAccessData,
              modelRateData,
              modelAccessData,
              tagRateData,
              tagAccessData,
              gpuRateData,
              gpuAccessData,
              cpuRateData,
              cpuAccessData,
              pageType,
              actions,
              reduxState,
              isRegionalDashboard,
              bigDataResultDailyUpdateMillis,
              handleChangeRankSearchParams: this.handleChangeRankSearchParams.bind(this),
            }}
            appId={appId}
            pid={routerPid}
            rankVersion={searchParams.get('rankVersion')}
            searchParams={searchParams}
            selectOptions={selectOptions}
            sortOption={this.state.sortOption}
            onClickRankTag={(val) => this.handleClickRankTag(val)}
            setSortOption={(val) => this.setSortOption(val)}
            changeTag={(val) => this.handleClickRankTag(val)}
            />
          }
        </Spin></div> }
        { !isOomType && <div className={style.product_summary} style={{marginBottom: 0}}>
          <Spin spinning={topIssueLoading}>
           {/* <TopIssueDataTypeSelect {...{
              style,
              isRegionalDashboard,
              hasIntegratedApps: this.getHasIntegratedApps(),
              handleTopIssueDataTypeChange: this.handleTopIssueDataTypeChange.bind(this),
              onTopIssueParamsChange: (v) => { this.onTopIssueParamsChange(v) },
              onTopIssueDateChange: (v) => { this.onTopIssueDateChange(v) },
              onTopIssueVersionChange: (v) => { this.onTopIssueVersionChange(v) },
              onTopIssueCountryChange: (v) => { this.onTopIssueCountryChange(v) },
              onTopIssueEnableFilterChange: (v) => { this.onTopIssueEnableFilterChange(v)},
              onTopIssueFilterRawSearchChange: (v) => { this.onTopIssueFilterRawSearchChange(v)},
              onTopIssueIntegratedAppIdChange:(v) => { this.onTopIssueIntegratedAppIdChange(v)},
              onTopIssueSortFieldChange:(v) => { this.onTopIssueSortFieldChange(v)},
              topIssueCount: this.state.topIssueCount,
              onTopIssueCountChange: (v) => { this.onTopIssueCountChange(v) },
              onDownloadData: () => { this.onClickDownloadDailyTopIssues() },
              searchParams,
              selectOptions }} /> */}
          {/* <DividerWithTime
            {...{
              data:bigDataResultDailyUpdateMillis,
              type:'DAILY',
            }} /> */}
          <TopIssueList
            style={style}
            data={topIssueData.toJS()}
            issueIdToDailyTrendList={issueIdToDailyTrendList}
            issueListTrendType={issueListTrendType}
            members={members}
            demoApp={demoApp}
            topIssueDataType={topIssueDataType}
            bigDataResultDailyUpdateMillis={bigDataResultDailyUpdateMillis}
            searchParams={searchParams}
            topIssueCount={this.state.topIssueCount}
            selectOptions={selectOptions}
            isRegionalDashboard={this.getIsRegionalDashboard()}
            hasIntegratedApps={this.getHasIntegratedApps()}
            onTopIssueCountChange={(v) => { this.onTopIssueCountChange(v) }}
            handleChangeStatus={this.handleChangeIssueStatus.bind(this, members, 'dailyTopIssue')}
            onClickUpsertTapdBugByIssueIdList={(issueIdList) => this.onClickUpsertTapdBugByIssueIdList(issueIdList, false)}
            onBugUnbind={() => {
              // 小时和日级都要刷新，因为同一个issue可能两个地方都有
              actions.getTopIssue({ topIssueDataType: searchParams.get('topIssueDataType'), limit: this.state.topIssueCount });
              actions.fetchRealTimeTopIssueAndSetState();
            }}
            onDownloadData={() => { this.onClickDownloadDailyTopIssues() }}
            onTopIssueSortFieldChange={(v) => { this.onTopIssueSortFieldChange(v)}}
            onTopIssueVersionChange={(v) => { this.onTopIssueVersionChange(v) }}
            onTopIssueParamsChange={(v) => { this.onTopIssueParamsChange(v) }}
            onTopIssueIntegratedAppIdChange={(v) => { this.onTopIssueIntegratedAppIdChange(v)}}
            onTopIssueCountryChange={(v) => { this.onTopIssueCountryChange(v) }}
            onTopIssueEnableFilterChange={(v) => { this.onTopIssueEnableFilterChange(v)}}
            handleTopIssueDataTypeChange={ (v) => {this.handleTopIssueDataTypeChange(v)}}
            onTopIssueFilterRawSearchChange={(v) => { this.onTopIssueFilterRawSearchChange(v)}}
            actions={actions} />
          </Spin>
        </div> }
        { isOomType && <Suspense fallback={<LoadingPage/>}>
          <OomDashboard
            isUsedInProductSummaryPage={true}
            onUpdateSearchParams={this.handleChangeSearchParam.bind(this)}
            location={location}
          />
        </Suspense> }
        <CountryGroupConfigModal/>
        <TapdModal
          issueInfoList={this.state.tapdModalIssueInfoList}
          modalProps={tapdModalProps}
        />
        {loading && <Loading />}
      </div>
    );
  }
}
