function extend() {
  let i = 0;
  const result = {};
  for (; i < arguments.length; i++) {
    const attributes = arguments[i];
    for (const key in attributes) {
      result[key] = attributes[key];
    }
  }
  return result;
}

function init(converter) {
  function api(key, value, attributes) {
    let result;

    // Write

    if (arguments.length > 1) {
      attributes = extend({
        path: '/',
      }, api.defaults, attributes);

      if (typeof attributes.expires === 'number') {
        const expires = new Date();
        expires.setMilliseconds(expires.getMilliseconds() + attributes.expires * 864e+5);
        attributes.expires = expires;
      }

      try {
        result = JSON.stringify(value);
        if (/^[\{\[]/.test(result)) {
          value = result;
        }
      } catch (e) {
      }

      value = encodeURIComponent(String(value));
      value = value.replace(/%(23|24|26|2B|3A|3C|3E|3D|2F|3F|40|5B|5D|5E|60|7B|7D|7C)/g, decodeURIComponent);

      key = encodeURIComponent(String(key));
      key = key.replace(/%(23|24|26|2B|5E|60|7C)/g, decodeURIComponent);
      key = key.replace(/[\(\)]/g, escape);

      return (document.cookie = [
        key, '=', value,
        attributes.expires && `; expires=${attributes.expires.toUTCString()}`, // use expires attribute, max-age is not supported by IE
        attributes.path && `; path=${attributes.path}`,
        attributes.domain && `; domain=${attributes.domain}`,
        attributes.secure ? '; secure' : '',
      ].join(''));
    }

    // Read

    if (!key) {
      result = {};
    }

    // To prevent the for loop in the first place assign an empty array
    // in case there are no cookies at all. Also prevents odd result when
    // calling "get()"
    const cookies = document.cookie ? document.cookie.split('; ') : [];
    const rdecode = /(%[0-9A-Z]{2})+/g;
    let i = 0;

    for (; i < cookies.length; i++) {
      const parts = cookies[i].split('=');
      const name = parts[0].replace(rdecode, decodeURIComponent);
      let cookie = parts.slice(1).join('=');

      if (cookie.charAt(0) === '"') {
        cookie = cookie.slice(1, -1);
      }

      try {
        cookie = converter && converter(cookie, name) || cookie.replace(rdecode, decodeURIComponent);

        if (this.json) {
          try {
            cookie = JSON.parse(cookie);
          } catch (e) {
          }
        }

        if (key === name) {
          result = cookie;
          break;
        }

        if (!key) {
          result[name] = cookie;
        }
      } catch (e) {
      }
    }

    return result;
  }

  api.get = api.set = api;
  api.getJSON = function () {
    return api.apply({
      json: true,
    }, [].slice.call(arguments));
  };
  api.defaults = {};

  api.remove = function (key, attributes) {
    api(key, '', extend(attributes, {
      expires: -1,
    }));
  };

  api.withConverter = init;

  return api;
}

export default init();
