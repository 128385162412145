/**
 * Created by rexyan on 2016/3/8.
 */
import moment from 'moment';
import { ILLEGAL_REG } from './constants';
import { isGameConsole, isPcOrLinux, PlatformUtil } from 'utils/platform';
import { ExceptionCategoryUtil } from 'utils/exception-category';
import { ze } from 'utils/zhEn';
import i18n from 'i18n.js';

export function encodeForHtml(str) {
  return str.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')
    .replace(/\n/g, '<br/>');
}

export function getDateFromStr(str) {
  if (!str) {
    return false;
  }
  const dateStr = str.toString();
  if (dateStr.length === 8) {
    return { year: dateStr.substr(0, 4), month: dateStr.substr(4, 2), day: dateStr.substr(6, 2) };
  } else if (dateStr.length === 10) {
    return {
      year: dateStr.substr(0, 4), month: dateStr.substr(4, 2), day: dateStr.substr(6, 2), hour: dateStr.substr(8, 2),
    };
  } else if (dateStr.length === 6) {
    return { year: dateStr.substr(0, 4), month: dateStr.substr(4, 2) };
  } else {
    return false;
  }
}

/**
 * 输入dateType格式, 导出包含开始和结束时间的对象格式
 * @param {string} dateType, today, last_1_day, last_1_month
 * @returns {{startDate: string, endDate: string, len}}
 */

export function getStartEndDateFromStr(dateType, startDate, endDate, options) {
  const result = { startDate: '', endDate: '', len: 0 };

  switch (dateType) {
    case 'today':
      result.startDate = moment();
      result.endDate = result.startDate;
      result.len = 0;
      break;
    case 'last_1_day':
      result.startDate = moment().subtract(1, 'days');
      result.endDate = result.startDate;
      result.len = 0;
      break;
    case 'last_2_day':
      result.startDate = moment().subtract(2, 'days');
      result.endDate = moment().subtract(1, 'days');
      result.len = 1;
      break;
    case 'last_7_day':
      result.startDate = moment().subtract(7, 'days');
      result.endDate = moment().subtract(1, 'days');
      result.len = 6;
      break;
    case 'last_15_day':
      result.startDate = moment().subtract(15, 'days');
      result.endDate = moment().subtract(1, 'days');
      result.len = 14;
      break;
    case 'last_30_day':
      result.startDate = moment().subtract(30, 'days');
      result.endDate = moment().subtract(1, 'days');
      result.len = 29;
      break;
    case 'last_60_day':
      result.startDate = moment().subtract(60, 'days');
      result.endDate = moment().subtract(1, 'days');
      result.len = 59;
      break;
    case 'last_2_week':
      result.startDate = moment().subtract(14, 'days');
      result.endDate = moment().subtract(1, 'days');
      result.len = 13;
      break;
    case 'last_1_month':
      result.startDate = moment().subtract(1, 'months');
      result.endDate = moment().subtract(1, 'days');
      result.len = 29;
      break;
    case 'custom':
      result.startDate = moment(startDate);
      result.endDate = moment(endDate);
      result.len = getDateDiff2(startDate, endDate);
      break;
    default: break;
  }

  if (options && options.format === 'millseconds') {
    result.startDate = result.startDate.valueOf();
    result.endDate = result.endDate.valueOf();
  } else {
    result.startDate = result.startDate.format('YYYYMMDD');
    result.endDate = result.endDate.format('YYYYMMDD');
  }

  return result;
}

export function changeDateFormat(date, format) {
  return moment(date).format(format);
}

export function getCurrentTime(format) {
  return moment().format(format);
}


// 计算两个日期的之间的长度，输入格式必须为YYYY-MM-DD
export function getDateDiff(startDate, endDate) {
  const sd = new Date(startDate);
  const ed = new Date(endDate);
  return (ed - sd) / (24 * 3600 * 1000);
}

// 计算两个日期的之间的长度，格式支持YYYY-MM-DD YYYYMMDD等moment支持的格式
export function getDateDiff2(startDate, endDate) {
  const start = moment(startDate);
  const end = moment(endDate);
  return end.diff(start, 'days');
}

export function transformDateFormat(date) {
  if (date.length !== 8) {
    return '';
  }
  const year = date.slice(0, 4);
  const month = date.slice(4, 6);
  const day = date.slice(6, 8);
  return [year, month, day].join('-');
}

// 获取最近时间段参数
export function getStartEndDateFromName(name) {
  const result = { startDate: '', endDate: '' };
  switch (name) {
    case 'LAST_30_DAYS_DAILY':
      result.startDate = moment().subtract(30, 'days').format('YYYYMMDD');
      result.endDate = moment().subtract(1, 'days').format('YYYYMMDD');
      return result;
    default:
    case 'LAST_72_HOURS_HOURLY':
      result.startDate = moment().subtract(72, 'hours').format('YYYYMMDDHH');
      result.endDate = moment().format('YYYYMMDDHH');
      return result;
  }
}

// 获取文件名后缀
export function getFileNameSubfix(fileName) {
  if (!fileName) {
    return '';
  }
  const tmp = fileName.match(/\.[^\.]+$/);
  return (tmp && tmp.length > 0) ? tmp[0].slice(1) : '';
}

/**
 * 通过dateType获取中文的option信息
 */
export function getDateOption(dateType) {
  if (!dateType) return '所有上报时间';
  switch (dateType) {
    case 'today':
      return '今天';
    case 'last_1_hour':
      return '最近1小时';
    case 'last_1_day':
      return '最近1天';
    case 'last_2_day':
      return '最近2天';
    case 'last_7_day':
      return '最近7天';
    case 'last_15_day':
      return '最近15天';
    case 'last_30_day':
      return '最近30天';
    case 'last_60_day':
      return '最近60天';
    case 'last_2_week':
      return '最近2周';
    case 'last_1_month':
      return '最近1月';
    case 'custom':
      return '自定义时间段';
    default:
      return '所有上报时间';
  }
}

// 根据概览页面类型  获取趋势图的tags
export function getSummaryTags(pageType, pid) {
  const hasAccessStats = PlatformUtil.hasAccessStats(pid);
  if (!hasAccessStats) {
    return ['EXCP_OVERVIEW.occurNumber', 'EXCP_OVERVIEW.userNumAffect'];
  }

  if (ExceptionCategoryUtil.isCrash(pageType)) {
    pageType = '崩溃';
  } else if (ExceptionCategoryUtil.isAnr(pageType)) {
    pageType = '卡顿';
  } else if (ExceptionCategoryUtil.isError(pageType)) {
    pageType = '错误';
  } else if (ExceptionCategoryUtil.isOom(pageType)) {
    pageType = 'oom';
  }

  return {
    崩溃: (isPcOrLinux(pid)
      ? ['EXCP_OVERVIEW.userCrashRate', 'EXCP_OVERVIEW.sessionCrashRate', 'EXCP_OVERVIEW.occurNumber', 'EXCP_OVERVIEW.userNumAffect', 'EXCP_OVERVIEW.sessionNumAffect', 'EXCP_OVERVIEW.sessionCount', 'EXCP_OVERVIEW.userNumConnect']
      : ['EXCP_OVERVIEW.userCrashRate', 'EXCP_OVERVIEW.occurNumCrashRate', 'EXCP_OVERVIEW.occurNumber', 'EXCP_OVERVIEW.userNumAffect', 'EXCP_OVERVIEW.sessionCount', 'EXCP_OVERVIEW.userNumConnect']),
    卡顿: ['EXCP_OVERVIEW.userANRRate', 'EXCP_OVERVIEW.occurNumANRRate', 'EXCP_OVERVIEW.occurNumber', 'EXCP_OVERVIEW.userNumAffect', 'EXCP_OVERVIEW.sessionCount', 'EXCP_OVERVIEW.userNumConnect'],
    ANR: ['EXCP_OVERVIEW.userANRRate', 'EXCP_OVERVIEW.occurNumANRRate', 'EXCP_OVERVIEW.occurNumber', 'EXCP_OVERVIEW.userNumAffect', 'EXCP_OVERVIEW.sessionCount', 'EXCP_OVERVIEW.userNumConnect'],
    错误: (isPcOrLinux(pid)
      ? ['EXCP_OVERVIEW.userErrorRate', 'EXCP_OVERVIEW.occurNumErrorRate', 'EXCP_OVERVIEW.occurNumber', 'EXCP_OVERVIEW.userNumAffect', 'EXCP_OVERVIEW.sessionNumAffect', 'EXCP_OVERVIEW.sessionCount', 'EXCP_OVERVIEW.userNumConnect']
      : ['EXCP_OVERVIEW.userErrorRate', 'EXCP_OVERVIEW.occurNumErrorRate', 'EXCP_OVERVIEW.occurNumber', 'EXCP_OVERVIEW.userNumAffect', 'EXCP_OVERVIEW.sessionCount', 'EXCP_OVERVIEW.userNumConnect']),
    oom: ['EXCP_OVERVIEW.deviceOomRate', 'EXCP_OVERVIEW.numOomRate', 'EXCP_OVERVIEW.occurNumber', 'EXCP_OVERVIEW.userNumAffect', 'EXCP_OVERVIEW.sessionCount', 'EXCP_OVERVIEW.userNumConnect'],
  }[pageType];
}

export function getSummaryTagTooltipContent(tag) {
  switch (tag) {
    case 'EXCP_OVERVIEW.sessionCrashRate': {
      return ze(
        `计算方式是 ${i18n.t('EXCP_OVERVIEW.sessionNumAffect')} 除以 ${i18n.t('EXCP_OVERVIEW.sessionCount')}`,
        `${i18n.t('EXCP_OVERVIEW.sessionCrashRate')} = ${i18n.t('EXCP_OVERVIEW.sessionNumAffect')} / ${i18n.t('EXCP_OVERVIEW.sessionCount')}`.replaceAll(' ', '\xa0')
      );
    }
    default: {
      return '';
    }
  }
}

export function getChartTags(searchParams, selectOptions, originType) {
  const { isRealTime, dataType, pid } = searchParams.toJS();
  const pageType = originType || selectOptions.get('pageType');
  return getSummaryTags(pageType, pid);
}

export function isURL(str) {
  const pattern = new RegExp('^(https?:\\/\\/)?' // protocol
  + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' // domain name
  + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
  + '(\\:\\d+)?(\\/[-a-z\\d%_.~+=]*)*' // port and path
  + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
  + '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return pattern.test(str);
}

export function hasSpecialChar(str) {
  return ILLEGAL_REG.test(str);
}

export function safeDecodeStr(str) {
  return typeof str === 'string' ? str.replace(/[%]/g, (m) => {
    return `%${m.charCodeAt(0).toString(16)}`;
    // Basically changes "%" to "%25".
  }) : str;
}

/**
 * 类似java的hashcode，计算字符串的hash返回32bit整数
 * @param str
 */
export function javaLikeHashCode(str) {
  const s = String(str);
  let hash = 0;
  for (let i = 0; i < s.length; i++) {
    let char = s.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}

// 百分比指标
export const PERCENTAGE_TAGS = [
  'EXCP_OVERVIEW.userCrashRate',
  'EXCP_OVERVIEW.userANRRate',
  'EXCP_OVERVIEW.userErrorRate',
  'EXCP_OVERVIEW.sessionCrashRate',
  'EXCP_OVERVIEW.deviceOomRate',
  'EXCP_OVERVIEW.occurNumCrashRate',
];
