import RestHelper from './RestHelper';
export default RestHelper.publish({
  auth: {
    accredit: {
      method: 'post',
      uri: '/authorize/appId/{appId}/pid/{pid}',
    },
    info: {
      method: 'get',
      uri: '/getAuthorizedInfo/appId/{appId}/pid/{pid}',
    },
    unaccredit: {
      method: 'post',
      uri: '/unauthorize/appId/{appId}/pid/{pid}',
    },
  },
});
