import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  Button,
  Input,
  InputNumber,
  Modal,
  Form,
  Spin,
  Checkbox,
  Row,
  Col,
  Space,
  Tag,
  Tooltip,
  Table
} from 'antd';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ze } from 'utils/zhEn';
import RestHelper from 'utils/RestHelper';
import { CS_STYLES } from 'utils/constants/style-constants';
import moment from 'moment';

export const RetraceHistoryUtil = Object.freeze({
  getTextByRetraceStatus(retraceStatus) {
    if (retraceStatus > 0) {
      return ze('还原中', 'Processing');
    } else if (retraceStatus < 0 ) {
      return ze('失败', 'Failed');
    } else if (retraceStatus === 0) {
      return ze('成功', 'Succeeded');
    } else {
      return null;
    }
  },

  getStyleObjectByRetraceStatus(retraceStatus) {
    if (retraceStatus > 0) {
      return { color: CS_STYLES.PRIMARY_COLOR_ORANGE };
    } else if (retraceStatus < 0 ) {
      return { color: CS_STYLES.PRIMARY_COLOR_RED };
    } else {
      return undefined;
    }
  },

  getIsRetraceProcessing(retraceStatus) {
    return retraceStatus > 0;
  },
});

const RetraceHistoryModal = (props) => {
  const { t } = useTranslation();

  const {
    visible,
    onClose,
    appId,
    issueId,
    crashId,
  } = props;

  const [retraceHistoryItemList, setRetraceHistoryItemList] = useState([]);

  useEffect(() => {
    if (!visible) {
      return;
    }
    fetchHistory();
  }, [visible]);

  async function fetchHistory() {
    const rsp = await RestHelper.post('/redir/api/issue/queryRetraceStatus', {
      appId,
      issueId,
      crashId,
      needHistory: true,
    });
    const retraceHistoryItemList = (rsp.json.data.retraceHistoryItemList || [])
      .map(x => ({
        startTime: x.startTime && moment(x.startTime).format('YYYY-MM-DD HH:mm:ss'),
        endTime: x.endTime && moment(x.endTime).format('YYYY-MM-DD HH:mm:ss'),
        ...x,
      }));
    setRetraceHistoryItemList(retraceHistoryItemList);
  }

  const columns = [{
    key: 'startTime',
    dataIndex: 'startTime',
    title: ze('请求发起时间', 'Request Time'),
  }, {
    key: 'endTime',
    dataIndex: 'endTime',
    title: ze('完成时间', 'Completion Time'),
    render: (_, record) => {
      const { retraceStatus, endTime } = record;
      const isRetraceProcessing = RetraceHistoryUtil.getIsRetraceProcessing(retraceStatus);
      return isRetraceProcessing ? '-' : endTime;
    },
  }, {
    key: 'retraceStatus',
    dataIndex: 'retraceStatus',
    title: ze('状态', 'Status'),
    render: (_, record) => {
      const { retraceStatus } = record;
      const text = RetraceHistoryUtil.getTextByRetraceStatus(retraceStatus);
      const styleObject = RetraceHistoryUtil.getStyleObjectByRetraceStatus(retraceStatus);
      return <div style={styleObject}>{ text }</div>;
    },
  }, {
    key: 'operatorName',
    dataIndex: 'operatorName',
    title: ze('操作人', 'Operator'),
  }];

  const tableDom = <Table
    columns={columns}
    dataSource={retraceHistoryItemList}
  />;

  return <Modal
    visible={visible}
    width='640px'
    title={ze('重还原历史记录', 'Re-Symbolication History')}
    onCancel={() => onClose()}
    footer={null}
  >
    { tableDom }
  </Modal>;
};

RetraceHistoryModal.propTypes = {
};

const mapStateToProps = state => ({
  reduxState: state,
});

export default connect(mapStateToProps)(RetraceHistoryModal);
