import React from 'react';
import { ze } from 'utils/zhEn';
import { Tooltip } from 'antd';
import WrappedTipsIcon from 'components/antd-extension/WrappedTipsIcon';

const DISCARDED_HINT_ZH = '该上报已被丢弃，可能的原因：\n'
  + '1. 项目开启了按比例丢弃错误上报的策略。\n'
  + '2. 当同一个问题下的错误当日上报过万后，如遇到磁盘紧张的情况会自动清理数据。';

const DISCARDED_HINT_EN = 'The report has been discarded. Possible reasons include:\n'
  + '1. The project has enabled a strategy for proportionally discarding error reports.\n'
  + '2. If the same issue has been reported over ten thousand times in a single day, the data may be automatically cleaned up in case of disk space constraints.';

export const QueryAccessUtil = Object.freeze({
  makeReportDiscardedDom() {
    return <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <div>{ ze('已丢弃', 'Discarded') }</div>
      <Tooltip
        title={ <div style={{ whiteSpace: 'pre-wrap' }}>{ ze(DISCARDED_HINT_ZH, DISCARDED_HINT_EN) }</div> }
      ><WrappedTipsIcon /></Tooltip>
    </div>;
  },
});
