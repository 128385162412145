import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { isAndroidOrHarmony, isPcOrLinux, isPlaystation, PlatformUtil } from 'utils/platform';
import { DataDetailUtil } from 'components/exception/issue/DataDetail';
import { Radio, Table, Tooltip, Tag } from 'antd';
import { ErrorBoundary } from 'react-error-boundary';
import scss from './ModuleList.scss';
import { ze } from 'utils/zhEn';
import escapeRegExp from 'lodash/string/escapeRegExp';
import { CS_STYLES } from 'utils/constants/style-constants';

function parseAndroidLine(line) {
  const [address, perms, offset, dev, inode, pathname = ''] = (line || '').split(/\s/).filter(x => x);
  return {
    address,
    perms,
    offset,
    dev,
    inode,
    pathname,
  };
}

const SystemSoLinesFilterType = Object.freeze({
  ALL: 'ALL',
  ONLY_APP: 'ONLY_APP',
});

const SystemSoLinesFilterTypeOptions = [{
  label: ze('显示全部', 'All'),
  value: SystemSoLinesFilterType.ALL,
}, {
  label: ze('仅显示应用so行（去重）', 'Only App Modules (Dedup)'),
  value: SystemSoLinesFilterType.ONLY_APP,
}];

/**
 * @param {{ address: string, perms: string, offset: string, dev: string, inode: string, pathname: string}[]} tableData
 * @param {string[]} appSoNameList
 * @param {SystemSoLinesFilterType} systemSoLinesFilterType
 */
function enrichAndFilterAndroidTableDataByAppSoNameList(tableData, appSoNameList, systemSoLinesFilterType) {
  const hasAppSoNameList = appSoNameList && appSoNameList.length > 0;
  if (!hasAppSoNameList) {
    return tableData;
  }
  const remainingAppSoSet = new Set(appSoNameList);
  const reg = new RegExp('(' + appSoNameList.map(x => escapeRegExp(x)).join('|') + ')');
  return tableData.map(x => {
    const { pathname } = x;
    const splitRes = String(pathname || '').split(reg);
    const hasAppSo = splitRes.length > 1;
    const appSo = hasAppSo ? splitRes[1] : '';

    const richPathname = splitRes.map((x, i) => {
      if (i % 2 === 0) {
        return <span>{x}</span>;
      }
      return <Tooltip title={ze('应用so', 'App .so')}><span style={{ color: CS_STYLES.PRIMARY_COLOR }}>{x}</span></Tooltip>;
    });

    return {
      ...x,
      appSo,
      pathname: richPathname,
    };
  }).filter(x => {
    if (systemSoLinesFilterType === SystemSoLinesFilterType.ALL) {
      return true;
    }
    if (remainingAppSoSet.has(x.appSo)) {
      remainingAppSoSet.delete(x.appSo);
      return true;
    }
    return false;
  });
}

function parseIosLine(line) {
  const [binaryImageName, ...rest] = (line || '').split(':');
  const restStr = rest.join('').trim();
  const [binaryArch, uuid, loadAddress] = restStr.split(';');

  return {
    binaryImageName,
    binaryArch,
    uuid,
    loadAddress,
  };
}





const ModuleList = ({
  issue,
  pid,
}) => {
  const issueObj = useMemo(() => issue.toJS() || {}, [issue]);

  const [systemSoLinesFilterType, setSystemSoLinesFilterType] = useState(SystemSoLinesFilterType.ALL);

  const {
    attachList,
    crashDoc,
    pcRegisterInfoLines,
    pcModuleInfoList,
    pcAllModuleInfoLines,
    isShowingMinidumpStack,
    isShowingRetracedAnrTrace,
    retracedAnrTrace,
  } = issueObj;
  const { esMap } = crashDoc || {};
  const { crashId, modelBit, appSoNameList, hasWritePermSoList, notInProjLoadModule } = esMap || {};

  const isMinidump = isPcOrLinux(pid) || isPlaystation(pid) || isShowingMinidumpStack;
  const showFilterTypeOptions = isAndroidOrHarmony(pid) && appSoNameList && appSoNameList.length > 0;

  let tableData = [];
  if (isMinidump) {
    if (pcAllModuleInfoLines && pcAllModuleInfoLines.length > 0) { // all module info是后加的，兼容旧数据
      tableData = pcAllModuleInfoLines.map(line => {
        const [moduleName, uuid, baseAddressHex = ''] = (line || '').split(/\t/);
        return { moduleName, uuid, baseAddressHex };
      });
    } else {
      tableData = pcModuleInfoList;
    }
  } else if (isAndroidOrHarmony(pid)) {
    const lines = DataDetailUtil.makeSoInfoTextLines(pid, attachList)
      .filter(x => x && !x.includes(DataDetailUtil.SYSTEM_SO_INFO_HEADER));
    tableData = enrichAndFilterAndroidTableDataByAppSoNameList(lines.map(parseAndroidLine),
      appSoNameList, systemSoLinesFilterType);
  } else if (PlatformUtil.isIosOrMac(pid)) {
    const lines = DataDetailUtil.makeSoInfoTextLines(pid, attachList)
      .filter(x => x && !x.includes(DataDetailUtil.SYSTEM_BINARY_INFO_HEADER));
    tableData = lines.map(parseIosLine);
  }

  const tableSpecialRender = {
    'perms' : {
      render: (label, record) => {
        if (label.includes('w') && hasWritePermSoList && hasWritePermSoList.some((so) => record.pathname.includes(so))) {
          return <Tooltip
            title={ze('当前so有w权限，疑似外挂行为', 'Current so file has write permissions, suspected hacking.')}
          ><Tag
            color='red'
            style={{ userSelect: 'none' }}
          >{ label }</Tag></Tooltip>;
        } else {
          return label;
        }
      }
    },
    'pathname': {
      render: (label, record) => {
        if (notInProjLoadModule && notInProjLoadModule.some((so) => record.pathname.includes(so))) {
          return <Tooltip
            overlayStyle={{ maxWidth: '500px' }}
            title={ze('当前so并不是当前项目默认加载的so，疑似外挂行为', `Current so file isn't the default one loaded by the project, suspected hacking.`)}
          ><Tag
            color='red'
            style={{ userSelect: 'none' }}
          >{label}</Tag></Tooltip>;
        } else {
          return label;
        }
      }
    },
  }

  const noWrapKeys = ['address', 'perms', 'offset', 'dev', 'inode'];
  const noWrapRender = noWrapKeys.reduce((acc, key) => {
    acc[key] = {
      render: (label, record) => {
        return <div style={{ whiteSpace: 'nowrap' }}>{label}</div>;
      }
    };
    return acc;
  }, {});

  const columns = useMemo(() => {
    const androidColumns = ['address', 'perms', 'offset', 'dev', 'inode', 'pathname'].map(x => ({
      title: x[0].toUpperCase() + x.slice(1),
      dataIndex: x,
      available: isAndroidOrHarmony(pid) && !isMinidump,
      ...(noWrapRender[x] ?? {}),
      ...(tableSpecialRender[x] ?? {})
    }));
    const iosColumns = [{
      title: 'Binary Image Name',
      dataIndex: 'binaryImageName',
    }, {
      title: 'Binary Arch',
      dataIndex: 'binaryArch',
    }, {
      title: 'UUID',
      dataIndex: 'uuid',
    }, {
      title: 'Load Address',
      dataIndex: 'loadAddress',
    }];

    if (!isMinidump && isAndroidOrHarmony(pid)) {
      return androidColumns;
    } else if (!isMinidump && PlatformUtil.isIosOrMac(pid)) {
      return iosColumns;
    }

    return [{
      title: ze('模块名', 'Module Name'),
      dataIndex: 'moduleName',
    }, {
      title: ze('UUID', 'Signature UUID'),
      dataIndex: 'uuid',
    }, {
      title: ze('模块基地址', 'Module Base Address'),
      dataIndex: 'baseAddressHex',
    }];
  }, [pid, isMinidump]);

  const table = <Table
    size='small'
    columns={columns}
    dataSource={tableData}
    pagination={false}
  />;

  const dom = <div>
    { showFilterTypeOptions && <Radio.Group
      style={{ marginBottom: '16px' }}
      optionType='button'
      options={SystemSoLinesFilterTypeOptions}
      value={systemSoLinesFilterType}
      onChange={(e) => setSystemSoLinesFilterType(e.target.value)}
    /> }
    { table }
  </div>;

  return <ErrorBoundary
    fallback={<div style={{ color: 'red' }}>Error occurred during rendering libs.</div>}
  ><div className={scss.moduleList}>{ dom }</div></ErrorBoundary>;
};

ModuleList.propTypes = {
  reduxState: PropTypes.object,
  issue: PropTypes.object,
  pid: PropTypes.string,
};

const mapStateToProps = state => ({
  reduxState: state,
});

export default connect(mapStateToProps)(ModuleList);
