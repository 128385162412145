import React, { useMemo } from 'react';
import {  Select } from 'antd';
import { PLATFORM_ID } from 'utils/constants';

const PLATFORM_ID_TO_RAM_GIB_LIST = {
  [PLATFORM_ID.ANDROID]: [1, 2, 3, 4, 6, 8, 10, 12, 14, 16],
  [PLATFORM_ID.IOS]: [1, 2, 3, 4, 6, 8, 16],
};

const RamGibSelect = ({
  platformId,
  value,
  onChange,
}) => {
  const options = useMemo(() => {
    return (PLATFORM_ID_TO_RAM_GIB_LIST[platformId] || [])
      .map(x => ({ label: `${x} GiB`, value: x }));
  }, [platformId]);

  return <Select
    style={{ width: '100%' }}
    mode='multiple'
    allowClear={true}
    options={options}
    value={value}
    onChange={v => {
      onChange(v);
    }}
  />
};

export default RamGibSelect;
