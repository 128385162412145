import React, {useState, useRef, useEffect} from 'react';
import { Select } from 'antd';
import { useHover } from 'ahooks';
import { isNullish } from 'utils/nullish';
import { useTranslation } from 'react-i18next';
import scss from './FilterFieldSelect.scss';

const FilterFieldSelect = ({
  optionGroups,
  value,
  onChange,
}) => {
  const { t } = useTranslation();

  return <Select
    style={{ width: '100%' }}
    options={optionGroups}
    allowClear={false}
    optionLabelProp='richLabel'
    placeholder={t('issueCrashFilter.fieldNamePlaceholder')}
    filterOption={ (input, option) => isNullish(option.options) && (option.label || '').toLowerCase().includes(('' || input).toLowerCase()) }
    value={value}
    onChange={(v) => onChange && onChange(v)}
    popupClassName={scss.filterFieldSelect}
    dropdownMatchSelectWidth={false}
  />;
}

export default FilterFieldSelect;
