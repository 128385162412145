import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Radio, Select, Space } from 'antd';
import { PlatformUtil } from 'utils/platform';
import { SummaryHeadDataType } from 'utils/constants/summary-head-data-type';
import style from './SummaryHead.scss';
import { CsNewTabs } from 'components/antd-extension/CsNewTabs';

const SummaryHead = ({ pid, handleChangeSearchParam, headDataType, pageType }) => {
  const { t } = useTranslation();
  const options = [
    ...(PlatformUtil.hasAccessStats(pid) ? [{ label: t('EXCP_OVERVIEW.crashUserToday'), value: SummaryHeadDataType.userRate }] : []),
    ...(PlatformUtil.hasAccessStats(pid) ? [{ label: t('EXCP_OVERVIEW.sessionCrashRateToday'), value: SummaryHeadDataType.sessionCrashRate }] : []),
    { label: t('EXCP_OVERVIEW.occurNum'), value: SummaryHeadDataType.crashNum },
    { label: t('EXCP_OVERVIEW.userNum'), value: SummaryHeadDataType.crashUser },
  ];

  return <CsNewTabs options={options} value={headDataType} onChange={(value) => handleChangeSearchParam('headDataType', value)} />;
};

SummaryHead.propTypes = {
  handleChangeSearchParam: PropTypes.func,
  headDataType: PropTypes.string,
};

export default SummaryHead;
