import React, { useEffect, useMemo } from 'react';
import { CsSelect } from 'components/antd-extension/CsSelect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectNullableCurrentAppSettings } from 'utils/selectors/selectors';
import { ServerAppSettings } from 'utils/server-app-settings';
import { IssueStatus } from 'utils/constants/issue-status';
import i18n from 'i18n';
import HourGlassIcon from 'svg/v2/newcs_dashboard_list_status_icon1.svg';
import SuccessIcon from 'svg/v2/newcs_dashboard_list_status_icon3.svg';
import WaitingIcon from 'svg/v2/newcs_dashboard_list_status_icon2.svg';
import ReopenedIcon from 'svg/v2/newcs_dashboard_list_status_icon4.svg';
import { isNotNullish } from 'utils/nullish';

const STATUS_IMG = {
  0: <HourGlassIcon />,
  1: <SuccessIcon />,
  2: <WaitingIcon />,
  [IssueStatus.REOPENED]: <ReopenedIcon/>,
};

const ISSUE_STATUS_OPTIONS = [
  { label: <div style={{display: 'flex', alignItems: 'center', gap: 4}}>{ STATUS_IMG[0] }{ i18n.t('statusOptions.未处理') }</div>, value: 0 },
  { label: <div style={{display: 'flex', alignItems: 'center', gap: 4}}>{ STATUS_IMG[1] }{ i18n.t('statusOptions.已处理') }</div>, value: 1 },
  { label: <div style={{display: 'flex', alignItems: 'center', gap: 4}}>{ STATUS_IMG[2] }{ i18n.t('statusOptions.处理中') }</div>, value: 2 },
  { label: <div style={{display: 'flex', alignItems: 'center', gap: 4}}>{ STATUS_IMG[IssueStatus.REOPENED] }{ i18n.t('statusOptions.重新打开') }</div>, value: IssueStatus.REOPENED },
];

const IssueStatusSelect = ({
  style,
  isMultiple,
  value,
  onChange,
  ...rest
}) => {
  const { t } = useTranslation();

  const serverAppSettings = useSelector(selectNullableCurrentAppSettings);
  const enableIssueStatusReopened = !!serverAppSettings?.[ServerAppSettings.keys.enableIssueStatusReopened];

  const innerValue = useMemo(() => {
    if (isMultiple && Array.isArray(value)) {
      return (value || [])
        .filter(isNotNullish)
        .map(x => Number(x))
    } else {
      return isNotNullish(value) ? Number(value) : value;
    }
  }, [value, isMultiple]);

  const hasReopenedValue = isMultiple
    ? (innerValue || []).includes(IssueStatus.REOPENED)
    : innerValue === IssueStatus.REOPENED;

  const options = enableIssueStatusReopened || hasReopenedValue
    ? ISSUE_STATUS_OPTIONS
    : ISSUE_STATUS_OPTIONS.filter(x => x.value !== IssueStatus.REOPENED);

  return <CsSelect
    style={style}
    {...(isMultiple ? { mode: 'multiple' } : {})}
    allowClear={true}
    placeholder={t('statusOptions.所有状态')}
    options={options}
    value={innerValue}
    onChange={v => onChange && onChange(v)}
    {...rest}
  />
}

export default IssueStatusSelect;
