import { ze } from 'utils/zhEn';
import { Button, Form, Input, Space, Table } from 'antd';
import { CS_STYLES } from 'utils/constants/style-constants';
import { red } from '@ant-design/colors';
import { EditOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import OnlineRestoreModal from 'components/exception/OnlineRetraceModal';

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            pattern:/^0x[0-9a-fA-F]*$/
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

const OnlineRetraceModuleTable = ({
  analysisTableData = [],
  onChangeAnalysisTableData = () => {},
  reduxState,
}) => {
  const { t } = useTranslation();
  const currentApp = reduxState.app.get('current').toJS();
  const { appId, platformId, appName } = currentApp;

  const [CurrentRowData, setCurrentRowData] = useState(false);
  const [ selectionSymbolTableModal, setSelectionSymbolTableModal ] = useState(false);

  const abolishSymbolTable = (uuid) => {
    let data = [];
    for (let item of analysisTableData){
      if(item.uuid == uuid){
        item.uploadStatus.symbolMap = {};
        data.push(item);
      } else {
        data.push(item)
      }
    }
    onChangeAnalysisTableData(data);
  }

  const selectionSymbolTable = async() => {
    setSelectionSymbolTableModal(true);
  }

  const analysisTableColumns = [
    {
      title: ze('模块名', 'Module'),
      dataIndex: 'soName',
      key: 'soName',
    },
    {
      title: 'UUID',
      dataIndex: 'uuid',
      key: 'uuid',
    },
    {
      title: ze('上传状态', 'Upload Status'),
      dataIndex: 'uploadStatus',
      key: 'uploadStatus',
      render:(_,{uploadStatus,soName,uuid}) => {
        const { eachUploadStatus, updateTime,symbolMap } = uploadStatus;
        const uploadSymbolSheetUrl = `${window.location.origin}/crash-reporting/preferences/dsyms/${appId}?pid=${platformId}`;
        const TableAvailableSymbols = ((symbolMap || {})[soName] || {}).symbolPath;
        if ( eachUploadStatus >= 0) {
          return <Space style={{ color: CS_STYLES.PRIMARY_COLOR }}>
            <div>{ ze('已上传（UUID匹配）', 'Uploaded (UUID Matched)') }</div>
            <div>({ updateTime })</div>
          </Space>;
        } else if(TableAvailableSymbols) {
          return <Space>
            <div>{ze('已选取符号表','Selected symbol table')}</div>
            <Button type="link" onClick={() => abolishSymbolTable(uuid)}>{ze('取消选取','Deselect')}</Button>
          </Space>
        } else {
          return <Space>
            <div style={{ color: red.primary }}>{ ze('未上传', 'Not Uploaded') }</div>
            <a target= '_blank' href={uploadSymbolSheetUrl}>{ze('上传符号表','Upload Symbol Sheet')}</a>
            <Button type="link" onClick={() => selectionSymbolTable()}>{ze('选择已有符号表','Select an existing symbol table')}</Button>
          </Space>
        }
      }
    },
    {
      title: ze('基地址', 'Base Address'),
      dataIndex: 'baseAddress',
      key:'baseAddress',
      editable:true,
      render:(text) => <div>
        <span>{text}</span>
        <Button type="link" shape="circle" size='small' icon={<EditOutlined />} />
      </div>
    },
    {
      title: ze('匹配符号表UUID', 'Matched Symbol File UUID'),
      dataIndex: 'matchedSymbolFileUuid',
      key:'matchedSymbolFileUuid',
    },
  ];

  const handleSave = (row) => {
    const newData = [...analysisTableData];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    onChangeAnalysisTableData(newData);
  };

  const columns = analysisTableColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  // 覆盖默认的table元素
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const tableDom = <Table
    columns={columns}
    dataSource={analysisTableData}
    components={components}
    scroll={{ x:1600 }}
    onRow={record => {
      return {
        onClick: event => {
          setCurrentRowData(record)
        },
      }
    }}
  />;

  const CloseSubComponentPopup = (type,value) => {
    const soName = CurrentRowData.soName;
    for (let item of analysisTableData){
      if(item.key == CurrentRowData.key){
        item.uploadStatus.symbolMap[soName] = {};
        item.uploadStatus.symbolMap[soName].symbolPath = value;
      }
    }
    setSelectionSymbolTableModal(false);
  }

  return <div>
    { tableDom }
    <OnlineRestoreModal modalState={selectionSymbolTableModal} reduxState={reduxState} CloseSubComponentPopup={CloseSubComponentPopup}/>
  </div>
};

const mapStateToProps = state => ({
  reduxState: state,
});

export default connect(mapStateToProps)(OnlineRetraceModuleTable);

