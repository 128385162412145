import React, { useState, useEffect, useMemo } from 'react';
import Cookies from 'js-cookie';
import { Button, Col, Popover, Radio, Row, Select, Space } from 'antd';
import { red } from '@ant-design/colors';
import { getBackendInjectEnvs } from 'utils/backend-inject-envs';
import { sleep } from 'utils/sleep';
import { EnvUtil } from 'utils/env-util';

const SERVICE_MESH_WEB_CANARY_NONE = '非灰度';

const SERVICE_MESH_WEB_CANARY_COOKIE_KEY = 'cs_web_canary_service_name';

const SERVICE_MESH_WEB_CANARY_COOKIE_VALUE_PREFIX = 'cs-web-php-canary-';

const SERVICE_MESH_WEB_CANARY_OPTIONS = [
  { label: SERVICE_MESH_WEB_CANARY_NONE, value: SERVICE_MESH_WEB_CANARY_NONE },
  ...[
    'wenqing',
    'enjie',
    'yanjie',
    'houlong',
    'noopy',
  ].map(x => ({ label: x, value: SERVICE_MESH_WEB_CANARY_COOKIE_VALUE_PREFIX + x })),
];

const CanaryPopover = ({
  isSuperAdmin,
  children,
}) => {
  // 环境是否支持开启灰度
  const supportCanary = !!getBackendInjectEnvs().supportCanary;
  // 是否使用服务网格作为web前台灰度切换
  const useServiceMesh = !!getBackendInjectEnvs().useServiceMesh;

  const BACKEND_COOKIE_NEY = useServiceMesh ? 'cs_enable_backend_canary' : 'cs_enable_canary';

  const [webPodName, setWebPodName] = useState('');

  const [canaryEnabled, setCanaryEnabled] = useState(false);

  const [serviceMeshWebCanaryValue, setServiceMeshWebCanaryValue] = useState('');

  const frontendCanaryEnabled = serviceMeshWebCanaryValue && serviceMeshWebCanaryValue !== SERVICE_MESH_WEB_CANARY_NONE;

  const defaultValueIsChinaEnv = EnvUtil.isChinaEnv({ ignoreCustomIsChinaEnv: true });
  const cookieValueIsChinaEnv = Cookies.get(EnvUtil.COOKIE_KEY_IS_CHINA_ENV);
  let radioValueIsChinaEnv = defaultValueIsChinaEnv;
  if (cookieValueIsChinaEnv === 'true') {
    radioValueIsChinaEnv = true;
  } else if (cookieValueIsChinaEnv === 'false') {
    radioValueIsChinaEnv = false;
  }
  const hasSetCustomIsChinaEnv = defaultValueIsChinaEnv !== radioValueIsChinaEnv;

  const radioValueIsSuperAdminWatermarkDisabled = Cookies.get(EnvUtil.COOKIE_KEY_IS_SUPER_ADMIN_WATERMARK_DISABLED) === 'true';

  useEffect(() => {
    loadCanaryStatus();
    loadServiceMeshWebCanaryValue();
    const intervalId  = setInterval(() => {
      loadCanaryStatus();
    }, 10000);

    return () => {
      clearInterval(intervalId);
    }
  }, []);

  useEffect(() => {
    if (useServiceMesh && isSuperAdmin) {
      fetchWebPodInfo();
    }
  }, [useServiceMesh, isSuperAdmin]);

  async function fetchWebPodInfo() {
    const rsp = await RestHelper.post('/super-admin/getWebPodInfo');
    setWebPodName(rsp.json.data.podName);
  }

  function loadCanaryStatus() {
    const v = Cookies.get(BACKEND_COOKIE_NEY);
    setCanaryEnabled(v === 'true');
  }

  function enableCanary() {
    Cookies.set(BACKEND_COOKIE_NEY, 'true', { expires: useServiceMesh ? 7 : 1 });
    window.location.reload();
  }

  function disableCanary() {
    Cookies.remove(BACKEND_COOKIE_NEY);
    window.location.reload();
  }

  function onChangeEnableCanary(v) {
    if (v) {
      enableCanary();
    } else {
      disableCanary();
    }
  }

  function onChangeIsChinaEnv(v) {
    if (v === defaultValueIsChinaEnv) {
      Cookies.remove(EnvUtil.COOKIE_KEY_IS_CHINA_ENV);
    } else {
      Cookies.set(EnvUtil.COOKIE_KEY_IS_CHINA_ENV, String(v), { expires: useServiceMesh ? 7 : 1 });
    }
    window.location.reload();
  }

  function loadServiceMeshWebCanaryValue() {
    const v = Cookies.get(SERVICE_MESH_WEB_CANARY_COOKIE_KEY);
    setServiceMeshWebCanaryValue(v || '');
  }

  async function onChangeServiceMeshWebCanaryValue(v) {
    Cookies.set(SERVICE_MESH_WEB_CANARY_COOKIE_KEY, v, { expires: 7 }); // 7天过期
    window.location.reload();
  }

  const notSupportCanaryPopoverContent = <div>
    此环境不支持切换至灰度服。
  </div>;

  const commonSuperAdminPopoverContents = isSuperAdmin && <>
    <Row align='middle'>
      <Col span={8}>（超管）水印控制：</Col>
      <Col span={16} style={{ display: 'flex', gap: '8px' }}>
        <Radio.Group
          value={radioValueIsSuperAdminWatermarkDisabled}
          onChange={e => {
            const v = e.target.value;
            if (v) {
              Cookies.set(EnvUtil.COOKIE_KEY_IS_SUPER_ADMIN_WATERMARK_DISABLED, 'true', { expires: 1 });
            } else {
              Cookies.remove(EnvUtil.COOKIE_KEY_IS_SUPER_ADMIN_WATERMARK_DISABLED);
            }
            window.location.reload();
          }}
        >
          <Radio.Button value={false}>开启</Radio.Button>
          <Radio.Button value={true}>关闭（1天内有效）</Radio.Button>
        </Radio.Group>
      </Col>
    </Row>
  </>;

  const supportCanaryPopoverContent = <div
    style={{
      width: '480px',
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
    }}
  >
    <Row align='middle'>
      <Col span={8}>灰度：</Col>
      <Col span={16} style={{ display: 'flex', gap: '8px' }}>
        <div>
          <Button
            type='primary'
            onClick={() => enableCanary()}
          >{canaryEnabled ? '灰度服连接续期（1天）' : '连接灰度服（1天内有效）'}</Button>
        </div>
        {canaryEnabled && <div>
          <Button
            onClick={() => disableCanary()}
          >断开灰度服</Button>
        </div>}
      </Col>
    </Row>
    { commonSuperAdminPopoverContents }
  </div>;

  const serviceMeshCanaryPopoverContent = <div
    style={{
      width: '480px',
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
    }}
  >
    <Row align='middle'>
      <Col span={8}>前台灰度：</Col>
      <Col span={16}>
        <Select
          style={{ width: '100%' }}
          options={SERVICE_MESH_WEB_CANARY_OPTIONS}
          value={serviceMeshWebCanaryValue || SERVICE_MESH_WEB_CANARY_NONE}
          onChange={v => onChangeServiceMeshWebCanaryValue(v)}
        />
      </Col>
    </Row>
    { webPodName && <Row align='middle'>
      <Col span={16} offset={8} style={{ fontSize: '13px', color: '#888' }}>
        <div>当前连接前台Pod: </div>
        <div>{webPodName}</div>
      </Col>
    </Row> }
    <Row align='middle'>
      <Col span={8}>后台灰度</Col>
      <Col span={16}>
        <Radio.Group
          value={canaryEnabled}
          onChange={e => onChangeEnableCanary(e.target.value)}
        >
          <Radio.Button value={false}>非灰度</Radio.Button>
          <Radio.Button value={true}>灰度</Radio.Button>
        </Radio.Group>
      </Col>
    </Row>
    <Row align='middle'>
      <Col span={8}>国内/海外功能差异</Col>
      <Col span={16}>
        <Radio.Group
          value={radioValueIsChinaEnv}
          onChange={e => onChangeIsChinaEnv(e.target.value)}
        >
          <Radio.Button value={true}>对齐国内站 { defaultValueIsChinaEnv && '(默认)' }</Radio.Button>
          <Radio.Button value={false}>对齐海外站 { !defaultValueIsChinaEnv && '(默认)' }</Radio.Button>
        </Radio.Group>
      </Col>
    </Row>
    { commonSuperAdminPopoverContents }
  </div>;

  let popoverContent = notSupportCanaryPopoverContent;
  if (supportCanary) {
    if (useServiceMesh) {
      popoverContent = serviceMeshCanaryPopoverContent;
    } else {
      popoverContent = supportCanaryPopoverContent;
    }
  }

  function makeCanaryHintDom() {
    if (useServiceMesh) {
      if (!frontendCanaryEnabled && !canaryEnabled && !hasSetCustomIsChinaEnv) {
        return null;
      } else {
        return <Space>
          <div
            style={frontendCanaryEnabled ? { color: red.primary } : undefined}
          >前台：{ (serviceMeshWebCanaryValue || '非灰度').replace(SERVICE_MESH_WEB_CANARY_COOKIE_VALUE_PREFIX, '') }</div>
          <div
            style={canaryEnabled ? { color: red.primary } : undefined}
          >后台：{ canaryEnabled ? '灰度' : '非灰度' }</div>
          { hasSetCustomIsChinaEnv && <div style={{color: red.primary }}>{ radioValueIsChinaEnv ? '国内' : '海外' }</div> }
        </Space>;
      }
    } else {
      return canaryEnabled && <div style={{ color: red.primary }}>已连接灰度服</div>;
    }
  }

  return <div>
    <Popover
      trigger='contextMenu'
      content={popoverContent}
      placement="bottom"
    >
      { makeCanaryHintDom() }
      <div>{ children }</div>
    </Popover>
  </div>;
};

export default CanaryPopover;
