import React, { useEffect, useState } from 'react';
import { Button, Col, Divider, Input, message, Modal, notification, Row, Space, Spin, Tooltip } from 'antd';
import { ze } from 'utils/zhEn';
import RestHelper from 'utils/RestHelper';
import { isNotNullish } from 'utils/nullish';
import scss from './MyWallet.scss';
import BasicinfoIcon from 'svg/v2/newcs_dashboard_personalinfo_basicinfo_icon.svg';
import { useTranslation } from 'react-i18next';
import { EnvUtil } from 'utils/env-util';

const MyWallet = (props) => {
  const {
    currentUser,
  } = props;

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const [userQuota, setUserQuota] = useState(undefined);

  const [addFundsModalVisible, setAddFundsModalVisible] = useState(false);
  const [addFundsLoading, setAddFundsLoading] = useState(false);
  const [isAddFundsNeedRelogin, setIsAddFundsNeedRelogin] = useState(false);

  const allowCharge = EnvUtil.isChinaEnv(); // 目前仅国内环境支持自助充值

  useEffect(() => {
    queryUserWetestQuota();
  }, []);

  async function queryUserWetestQuota() {
    setLoading(true);
    const rsp = await RestHelper.post('/redir/api/userResource/queryUserWetestQuota');
    const { personStock } = rsp.json.data;
    const { validNum } = personStock;
    setUserQuota(validNum);
    setLoading(false);
  }

  async function onClickAddFunds() {
    setAddFundsModalVisible(true);
    setAddFundsLoading(true);
    const rsp = await RestHelper.mustPost('/api/personal-account/checkWetestSessionThenForceLoginWetest');
    setAddFundsLoading(false);
    const { sessionIdExpired } = rsp.json.data;
    setIsAddFundsNeedRelogin(sessionIdExpired);
  }

  function onChargeClosed() {
    queryUserWetestQuota();
  }

  return <div className={scss.myWallet}>
    <Spin spinning={loading}>
      <div className={scss.title}>
        <BasicinfoIcon width="14" height="14" viewBox="0 0 14 14" />
        <div>{ t('ACCOUNT.myWallet') }</div>
      </div>
      <div style={{ marginTop: '16px' }}>
        <span style={{ marginRight: '16px' }}>{ ze('WeTest账户余额', 'WeTest Wallet Balance')}</span>
        <span style={{ marginRight: '16px' }} className={scss.balanceNumber}>{ isNotNullish(userQuota) ? userQuota : '-' } MAU</span>
        <Tooltip title={allowCharge ? null : ze('海外环境暂不开放自助充值，如有问题请联系客服。', 'The oversea CrashSight site does not support self-service add funds. Please contact customer service.') }>
          <Button type='primary' disabled={!allowCharge} onClick={() => onClickAddFunds()}>{ ze('立即充值', 'Add Credits') }</Button>
        </Tooltip>
      </div>
    </Spin>
    <Modal
      title={ze('立即充值', 'Add Credits')}
      visible={addFundsModalVisible}
      maskClosable={false}
      onCancel={() => setAddFundsModalVisible(false)}
      footer={<Space>
        <Button onClick={() => setAddFundsModalVisible(false)}>{ze('取消', 'Cancel')}</Button>
        { isAddFundsNeedRelogin
          ? <Button disabled={addFundsLoading} type='primary' href='/relogin'>{ ze('重新登录', 'Re-Login') }</Button>
          : <Button
            disabled={addFundsLoading}
            type='primary'
            href='https://wetest.qq.com/n/buy?productId=1EkLN0XW'
            target='_blank'
            rel='noopener noreferrer'
            onClick={() => {
              setAddFundsModalVisible(false);
              Modal.info({
                title: ze('已打开充值页面', 'Add Credits Page Has Opened'),
                content: ze('请在充值页面完成充值。若已经完成或取消了充值，请关闭此对话框。', 'Please complete add credits on the add credits page. If you have already completed or canceled, please close this dialog box.'),
                onOk: () => onChargeClosed(),
              });
            }}
          >{ ze('前往充值', 'Add Credits') }</Button> }
      </Space>
      }
    >
      <Spin spinning={addFundsLoading}>
        <div>{ isAddFundsNeedRelogin
          ? ze('由于安全原因，需要重新登录后才可以进行充值操作。', 'Due to security reasons, you need to re-login before you can perform the add credits operation.')
          : ze('即将打开充值界面，请确认。充值完成后可刷新页面查看最新余额。', 'The recharge screen will be opened soon, please confirm. You can refresh the page to check the latest balance after the recharge is completed.')
        }</div>
      </Spin>
    </Modal>
  </div>;
};
export default MyWallet;
