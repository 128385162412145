import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import {
  LOCATION_CHANGE,
  GETCHANNELTREND_GET_SUCC,
  GETRETENTTREND_GET_SUCC,
  UPDATE_CHANNEL_PARAMS,
  CHANGE_CURTAB,
  UPDATE_PAEG_NUM,
  CHANNELDATA_GET_SUCC,
  GETNEWSELECTOR_GET_SUCC,
} from 'store/actions';
import {
  getArraySum, getNumFound, getTop5, getVersionStr, getDayFillData, parseSearch,
} from 'utils/helper';
import { initialState, initialSearchParams } from './initialState';

export default handleActions({
  [LOCATION_CHANGE]: (state, action) => {
    const { location } = action.payload;
    const { pathname } = location;
    const query = parseSearch(location);
    if (pathname.match(/analytics\/channel\/distribution/i)) {
      const date = { date: query.date ? query.date : 'last_7_day', startDate: query.startDateStr ? query.startDateStr : '', endDate: query.endDateStr ? query.endDateStr : '' };
      const channel = query.customChannel === 'custom' ? getVersionStr(query.channel) : '';
      return state.update('searchParams', (searchParams) => {
        return Object.keys(query).length ? searchParams.merge(query).set('date', date).set('channel', channel) : initialSearchParams;
      });
    }
    return state;
  },
  [GETCHANNELTREND_GET_SUCC]: (state, action) => {
    const rows = state.get('searchParams').get('rows');
    const data = (action.response.data && action.response.data.dataMap) || {};
    const { startDate, endDate } = action.params;
    const dataMap = {};
    Object.keys(data).forEach((key) => {
      if (data[key] && data[key].length > 0) {
        dataMap[key] = getDayFillData(data[key].reverse(), { startHour: startDate, endHour: endDate });
      }
    });
    const numFound = getNumFound(dataMap);
    return state.set('dataList', dataMap)
      .set('top5Channel', getTop5('addUserAmount', dataMap))
      .set('totalAddUserAmount', getArraySum('addUserAmount', dataMap['-1'] || []))
      .set('numFound', numFound)
      .set('totalPages', Math.floor(numFound / rows) + (numFound % rows === 0 ? 0 : 1));
  },
  [GETRETENTTREND_GET_SUCC]: (state, action) => {
    return state.set('curRetentTrend', action.response.data);
  },
  [UPDATE_CHANNEL_PARAMS]: (state, action) => state.update('searchParams', (params) => {
    // console.log('UPDATE_CHANNEL_PARAMS payload => ', action.payload);
    const { type, value } = action.payload;
    return params.set(type, value);
  }),
  [CHANGE_CURTAB]: (state, action) => state.set('currentTab', action.payload),
  [UPDATE_PAEG_NUM]: (state, action) => state.set('totalPages', Math.floor(getNumFound(state.get('dataList')) / action.payload) + (getNumFound(state.get('dataList')) % action.payload === 0 ? 0 : 1)),
  [GETNEWSELECTOR_GET_SUCC]: (state, action) => {
    const { channelList = [], versionList = [] } = (action.response.data && action.response.data.datas) || {};
    return state.update('selectOptions', (options) => options.merge({
      version: {
        options: [{
          label: '全版本',
          value: '-1',
        }].concat(versionList.map((item) => ({
          label: (parseInt(item.enable) === 0 ? (`${item.productVersion}已关闭`) : item.productVersion),
          value: encodeURIComponent(item.productVersion),
        }))),
      },
      channelId: {
        options: [{
          label: '全渠道',
          value: '-1',
        }].concat(channelList.map((item) => ({ label: item, value: encodeURIComponent(item) }))),
      },
    }));
  },

  [CHANNELDATA_GET_SUCC]: (state, action) => {
    const { datas } = action.response.data;

    /**
     * 变量数组，按照每个数组项的date属性，相同的放到一个新数组里
     * 同时，做下和运算
     */
    function processData(originData) {
      if (originData.length === 0) return { data: [], lastDate: '' };
      const obj = {};
      let lastDate = '';
      originData.forEach((data) => {
        const {
          newUser, simulatorUser, fakeUser, fakeActiveUser, date, channelId,
        } = data;
        if (!obj[date]) {
          obj[date] = [data];
          // 更新lastDate, 保存当前最大值
          // debugger
          (!lastDate || date > lastDate) && (lastDate = date);
        } else {
          obj[date].push(data);
        }

        // 对total 不做计算
        if (channelId === 'total') return;
        const user = newUser - simulatorUser - fakeUser - fakeActiveUser;
        if (!obj[`${date}summary`]) {
          obj[`${date}summary`] = {
            newUser,
            simulatorUser,
            fakeUser,
            fakeActiveUser,
            user,
          };
        } else {
          obj[`${date}summary`].newUser += newUser;
          obj[`${date}summary`].simulatorUser += simulatorUser;
          obj[`${date}summary`].fakeUser += fakeUser;
          obj[`${date}summary`].fakeActiveUser += fakeActiveUser;
          obj[`${date}summary`].user += user;
        }
      });
      return {
        data: obj,
        lastDate,
      };
    }

    const obj = processData(datas);
    const allData = fromJS(obj.data);
    const data = allData.get(obj.lastDate);

    return state.set('channelData', allData)
      .update('channelSearchParams', (param) => param.set('selectedPoint', obj.lastDate).set('start', 0).set('selectedData', data));
  },
  RESET_CHANNELDATA: (state) => state.set('channelData', fromJS([])),
  UPDATE_CHANNEL_PARAMS2: (state, action) => {
    const { type, value } = action.payload;
    // console.log('action payload ==> ', type, value)
    return state.update(
      'channelSearchParams',
      (params) => params.set(type, value)
    );
  },

  CHANNELID_GET_SUCC: (state, action) => {
    const { data } = action.response;
    const channelList = data && data.datas && data.datas.channelList;

    if (!channelList || !(channelList instanceof Array)) {
      return state;
    }
    return state.update('channelSelectOptions', (opts) => {
      return opts.updateIn(['channel', 'options'], (list) => {
        return list.concat(channelList.map((d) => {
          return { value: d, label: d };
        }));
      });
    });
  },

  SAVE_POINT: (state, action) => {
    // 取出目标数据，并根据newUser 字段排序
    const { value } = action.payload;
    const data = state.get('channelData').get(value);
    const sorted = data ? data.sort(comparator) : data;

    return state.update('channelSearchParams',
      (params) => params.set('selectedPoint',
        action.payload.value).set('start', 0).set('selectedData', sorted));
  },
}, initialState);

function comparator(itemA, itemB) {
  return itemA.get('newUser') < itemB.get('newUser') ? 1 : -1;
}
