import { isNullish } from 'utils/nullish';

export const Base64 = Object.freeze({
  /**
   * @param str {string}
   * @return {string}
   * */
  encodeAsString(str) {
    if (isNullish(str)) {
      return str;
    }
    const uint8Array = new TextEncoder().encode(String(str));
    return window.btoa(String.fromCharCode(...uint8Array));
  }
});
