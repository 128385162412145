import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import PackupArrow from 'svg/v2/newcs_dashboard_left_packup_arrow_normal.svg';
import OtherthreadDown from 'svg/v2/newcs_dashboard_crashanalysis_issuedetail_otherthread_down_normal.svg';
import scss from './CsCollapseButton.scss';
import classnames from 'classnames';

const CsCollapseButton = ({
  style: extraStyle,
  isSmallSize,
  enableHover,
  isCollapsed,
  onClick,
}) => {
  const props = {
    onClick,
  };

  if (isSmallSize) {
    return isCollapsed
      ? <OtherthreadDown {...props} className={classnames(scss.csCollapseButton,scss.otherthreadDown)}/>
      : <OtherthreadDown style={{transform:'rotate(180deg)'}} {...props} className={classnames(scss.csCollapseButton,scss.otherthreadDown)}/>;
  } else {
    return !isCollapsed
      ?<PackupArrow style={{transform:'rotate(90deg)'}} {...props} className={classnames(scss.csCollapseButton,scss.packupArrow)}/>
      :<PackupArrow style={{transform:'rotate(270deg)'}} {...props} className={classnames(scss.csCollapseButton,scss.packupArrow)}/>;
  }


};

CsCollapseButton.propTypes = {
  isCollapsed: PropTypes.bool,
  onClick: PropTypes.func,
};

export default CsCollapseButton;
