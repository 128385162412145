import { Map, List } from 'immutable';
import { AUTH_CODE } from 'utils/constants';


// 搜索的参数
export const initialSearchParams = new Map({
  pid: '',
  from: '',
  sign: '',
  timestamp: '',
  callbackUrl: '',
});


export const initialState = new Map({
  authCode: AUTH_CODE.SUCCESS,
  searchParams: initialSearchParams,
  grant: '',
  isAuth: false,
  authIsSuccess: false,
  currentAuthAppInfo: new Map(),
  authAppInfo: new List(),
});
