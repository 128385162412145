import React from 'react';
import PropTypes from 'prop-types';

const ReportImg = ({ reportUrl }) => {
  return <img src={reportUrl} style={{ position: 'absolute', top: '-3000px', left: '-3000px' }} role="presentation" />;
};

ReportImg.propTypes = {
  reportUrl: PropTypes.string,
};

export default ReportImg;
