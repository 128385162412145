import { Map } from 'immutable';

export const initialOptions = new Map({
  version: {
    options: [],
  },
  channel: {
    options: [],
  },
  dateScope: {
    options: [
      { label: '按天统计', value: 'day' },
      { label: '按周统计', value: 'week' },
      { label: '按月统计', value: 'month' }],
  },
});

export const initialSearchParams = new Map({
  version: '-1',
  channel: '-1',
  dateScope: 'day',
  date: { date: 'last_7_day', startDate: '', endDate: '' },
});

export const initialDurationParams = new Map({
  version: '-1',
  channel: '-1',
  dateScope: 'day',
  date: { date: 'last_1_day', startDate: '', endDate: '' },
});

export const initialState = new Map({
  // 留存分析
  retentData: false,
  retentParams: initialSearchParams,
  durationParams: initialDurationParams,
  durationCurTab: '启动次数分布',
  durationData: false,
});
