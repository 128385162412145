import React, { useState, useEffect, useMemo, useContext } from 'react';
import { DatePicker, Input, InputNumber, Select, Space, Tooltip } from 'antd';
import { getCrashStackSearchTooltipDom } from 'utils/crash-search/convert-search-content';
import { QueryType } from 'components/commons/IssueCrashFilter/IssueCrashFilterExUtil';
import { isZh, ze } from 'utils/zhEn';
import { isNotNullish, isNullish } from 'utils/nullish';
import scss from './VersatileTextSearch.scss';
import WrappedTipsIcon from 'components/antd-extension/WrappedTipsIcon.jsx';

const CONTAINS_TOOLTIP_CONTENT = [
  [
    ze('只能填写单个词语，不能填写多个词语。 连续的字母、数字、下划线、非标点字符视作一个词语。', 'Only one word can be entered, multiple words are not allowed. Consecutive letters, numbers, underscores, and non-punctuation characters are considered as one word.'),
    ze('例如 foo, fooBar, foo_bar, foo_bar123 都算一个词语，但是 foo-bar, foo.bar 算多个词语。', 'For example: foo, fooBar, foo_bar, foo_bar123 are all considered as one word, but foo-bar, foo.bar are considered as multiple words.'),
  ],
  [ze('如果需要搜索多个词语，可以选择搜索类型为复杂搜索，或者添加多个相同字段的搜索条件。', 'If you need to search for multiple words, you can choose the search type to Query String, or add multiple search conditions with the same field.')],
];

const MATCH_PHRASE_TOOLTIP_CONTENT = [
  [ze('此模式适合搜索连续的多个词语，例如搜索内容是一条堆栈或者一条消息。', 'This mode is suitable for searching continuous multiple words, such as searching for a stack trace or a message. ')],
  [
    ze('连续的字母、数字、下划线、非标点字符视作一个词语。所有词语必须精确匹配，不能是包含匹配。', 'Consecutive letters, numbers, underscores, and non-punctuation characters are considered as one word. All words must be exact matches, not containment matches.'),
    ze('例如搜索 fooBar(int baz) 可以匹配到 void fooBar(int baz)', 'For Example: Search "fooBar(int baz)" will match "void fooBar(int baz)"'),
    ze('但是搜索 Bar(int baz) 无法匹配 void fooBar(int baz)', 'But search "Bar(int baz)" will NOT match "void fooBar(int baz)"'),
  ],
];

const SIMPLE_QUERY_STRING_TOOLTIP_CONTENT = [
  [
    ze('内容是根据除_以外的标点符号和空格进行分词的。', 'The content is segmented into words based on punctuation marks and spaces other than _.'),
    ze('例如 void foo_bar(int foo, int barBaz); 会被分词为 void, foo_bar, int, foo, int, barBaz 六个词语。', 'For example: "void foo_bar(int a, int b);" will be segmented into 6 words: void, foo_bar, int, foo, int, barBaz.'),
  ],
  [ze('本模式只支持单个词语或多个词语的 精确匹配 和 前缀匹配搜索。不支持包含匹配。如需包含匹配，请切换至包含搜索方式。', 'The mode only supports exact match and prefix match searches for single words or multiple words. It does not support containment matching. If you need containment matching, please switch to the contains search method.')],
  [
    ze('搜索方式示例：', 'Examples:'),
    ze('精确匹配单个词语，例如： foo_bar', 'Exact match for a single word, for example: foo_bar'),
    ze('前缀匹配单个词语，在前缀后面添加*号，例如： foo*', 'Prefix match for a single word, add an asterisk after the prefix, for example: foo*'),
    ze('需要同时满足多个词语的匹配，例如： foo_bar barBaz', 'Match multiple words simultaneously, for example: foo_bar barBaz'),
    ze('使用引号包住多个词语，表示多个词语必须相邻，例如： "libunity.so"', 'Use quotes around multiple words to indicate that the words must be adjacent, for example: "libunity.so"'),
    ze('词语前面加上-，表示不允许精确匹配到该词语，例如： -fooBar', 'Add a minus sign (-) before a word to exclude exact matches for that word, for example: -fooBar'),
  ],
];

const SearchMethod = Object.freeze({
  SINGLE_WORD_CONTAINS: 'SINGLE_WORD_CONTAINS',
  SINGLE_WORD_NOT_CONTAINS: 'SINGLE_WORD_NOT_CONTAINS',
  TEXT_MATCH_PHRASE: 'TEXT_MATCH_PHRASE',
  TEXT_NOT_MATCH_PHRASE: 'TEXT_NOT_MATCH_PHRASE',
  TEXT_SIMPLE_QUERY_STRING: 'TEXT_SIMPLE_QUERY_STRING',
  TEXT_LEGACY_SEARCH: 'TEXT_LEGACY_SEARCH',
});

function makeLabelWithTooltip(label, tooltipContent) {
  if (!tooltipContent) {
    return label;
  }
  return<Tooltip
    placement='right'
    overlayStyle={{ maxWidth: '640px' }}
    title={tooltipContent}
  >
    <Space>
      <div>{ label }</div>
      <WrappedTipsIcon />
    </Space>
  </Tooltip>;
}

const SearchMethodOptions = [{
  value: SearchMethod.SINGLE_WORD_CONTAINS,
  name: ze('包含(单个词)', 'Contains (Single Word)'),
  queryType: QueryType.TERM_CONTAINS,
  not: false,
}, {
  value: SearchMethod.SINGLE_WORD_NOT_CONTAINS,
  name: ze('不包含(单个词)', 'Not Contains (Single Word)'),
  queryType: QueryType.TERM_CONTAINS,
  not: true,
}, {
  value: SearchMethod.TEXT_MATCH_PHRASE,
  name: ze('连续多词', 'Match Phrase'),
  queryType: QueryType.TEXT_MATCH_PHRASE,
  not: false,
}, {
  value: SearchMethod.TEXT_NOT_MATCH_PHRASE,
  name: ze('NOT 连续多词', 'Not Match Phrase'),
  queryType: QueryType.TEXT_MATCH_PHRASE,
  not: true,
}, {
  value: SearchMethod.TEXT_SIMPLE_QUERY_STRING,
  name: ze('复杂搜索', 'Complex Query'),
  queryType: QueryType.TEXT_SIMPLE_QUERY_STRING,
  not: false,
}, {
  value: SearchMethod.TEXT_LEGACY_SEARCH,
  name: ze('旧版搜索(已废弃)', 'Legacy Search (Deprecated)'),
  queryType: QueryType.TEXT_LEGACY_SEARCH,
  not: false,
}].map(x => ({
  ...x,
  label: makeLabelWithTooltip(x.name, makeTooltip(x.value)),
}));

const DEFAULT_QUERY_TYPE = QueryType.TERM_CONTAINS;

function convertTooltipContentToTooltip(tooltipContent) {
  return <ul>{
    tooltipContent.map(x => <li
      style={{ listStyleType: 'disc', listStylePosition: 'inside', textIndent: '-20px', marginLeft: '20px', whiteSpace: 'pre-wrap', fontSize: '16px' }}
    >{x.join('\n')}</li>)
  }</ul>;
}

function makeTooltip(searchMethod) {
  if (searchMethod === SearchMethod.TEXT_LEGACY_SEARCH) {
    return getCrashStackSearchTooltipDom();
  } else if (searchMethod === SearchMethod.SINGLE_WORD_CONTAINS || searchMethod === SearchMethod.SINGLE_WORD_NOT_CONTAINS) {
    return convertTooltipContentToTooltip(CONTAINS_TOOLTIP_CONTENT);
  } else if (searchMethod === SearchMethod.TEXT_SIMPLE_QUERY_STRING) {
    return convertTooltipContentToTooltip(SIMPLE_QUERY_STRING_TOOLTIP_CONTENT);
  } else if (searchMethod === SearchMethod.TEXT_MATCH_PHRASE || searchMethod === SearchMethod.TEXT_NOT_MATCH_PHRASE) {
    return convertTooltipContentToTooltip(MATCH_PHRASE_TOOLTIP_CONTENT);
  }
  return null;
}

const VersatileTextSearch = ({
  value,
  onChange = () => {},
  isReadOnly,
}) => {
  const {
    queryType: nullableQueryType,
    not,
    term,
    text,
  } = value || {};

  const queryType = nullableQueryType || DEFAULT_QUERY_TYPE;
  const searchMethodValue = (SearchMethodOptions.find(x => x.queryType === queryType && x.not === Boolean(not)) || {}).value;

  let innerValue = '';
  if (queryType === QueryType.TERM || queryType === QueryType.TERM_CONTAINS || queryType === QueryType.TERM_WILDCARD) {
    innerValue = term;
  } else {
    innerValue = text;
  }

  function onInputValueChange(inputValue) {
    if (queryType === QueryType.TERM || queryType === QueryType.TERM_CONTAINS || queryType === QueryType.TERM_WILDCARD) {
      onChange({ queryType, not, term: inputValue });
    } else {
      onChange({ queryType, not, text: inputValue });
    }
  }

  // 旧版搜索的选项只有兼容旧数据的时候才显示出来
  const options = searchMethodValue === SearchMethod.TEXT_LEGACY_SEARCH
    ? SearchMethodOptions
    : SearchMethodOptions.filter(x => x.value !== SearchMethod.TEXT_LEGACY_SEARCH);

  return <div style={{ display: 'flex' }}>
    <Select
      style={{ width: '120px', flex: '0 0 auto' }}
      options={options}
      optionLabelProp='name'
      value={ searchMethodValue }
      popupClassName={scss.versatileTextSearchTypePopover}
      onChange={(v, option) => {
        if (isReadOnly) {
          return;
        }
        const { queryType, not } = option;
        onChange({ queryType, not });
      }}
    />
    <Input
      style={{ flexGrow: 1 }}
      readOnly={isReadOnly}
      allowClear={true}
      value={innerValue}
      onChange={(e) => { onInputValueChange(e.target.value) }}
    />
  </div>
}

export default VersatileTextSearch;
