import i18n from 'i18next';
import { isNotNullish } from 'utils/nullish';

export function ze(zh, en) {
  const enDefaultZh = isNotNullish(en) ? en : zh;
  return isZh() ? zh : enDefaultZh;
}

/** 当前站点语言是否是中文 */
export function isZh() {
  return i18n.language.includes('zh');
}
