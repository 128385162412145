export const IssueStatus = Object.freeze({
  /** 未处理 */
  NOT_PROCESSED: 0,
  /** 已处理 */
  RESOLVED: 1,
  /** 处理中 */
  RESOLVING: 2,
  /** 用户在问题下面的备注留言。只用在IssueNote的issueStatus上，issue本身的issueStatus字段不会有这个取值。 */
  USER_COMMENT: 3,
  /** 重新打开 */
  REOPENED: 4,
});
