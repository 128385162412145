import React, { useState, useEffect, useMemo, useRef } from 'react';
import style from './style.scss';
import { connect, useSelector } from 'react-redux';
import { isNotNullish } from 'utils/nullish';
import {
  selectFrontendWebVolatileConfigValueByKey
} from 'utils/selectors/selectors';
import addWatermark from 'utils/watermark';
import { FrontendWebVolatileConfigKeys } from 'utils/constants/frontend-web-volatile-config';
import Cookies from 'js-cookie';
import { EnvUtil } from 'utils/env-util';

function WatermarkAppContainerDiv({
  children,
  reduxState,
}) {
  const containerRef = useRef();
  const isContainerRefReady = isNotNullish(containerRef) && isNotNullish(containerRef.current);
  const [watermarkStyle, setWatermarkStyle] = useState({});

  const isInTencentLocalNetwork = reduxState.user.get('isInTencentLocalNetwork');
  const { newUserId, nickname, isSuper: isSuperAdmin } = reduxState.user.get('current').toJS();

  const isSuperAdminWatermarkDisabled = isSuperAdmin && Cookies.get(EnvUtil.COOKIE_KEY_IS_SUPER_ADMIN_WATERMARK_DISABLED) === 'true';

  const enableStr = useSelector(state => selectFrontendWebVolatileConfigValueByKey(state, FrontendWebVolatileConfigKeys.ENABLE_WATERMARK)) || '';
  const enableWatermark = !isSuperAdminWatermarkDisabled && (Number(enableStr) > 0 || enableStr.toLowerCase() === 'true');

  useEffect(() => {
    if (!enableWatermark || !isContainerRefReady || !isInTencentLocalNetwork || !newUserId) {
      setWatermarkStyle({});
      return;
    }

    const base64 = addWatermark({
      nickname: nickname,
      userId: `(${newUserId})`,
      fontSize: 25,
      gap: 150,
      type: 'dark',
    }).base64;

    setWatermarkStyle({
      backgroundImage: `url(${base64})`
    });
  }, [enableWatermark, isContainerRefReady, isInTencentLocalNetwork, newUserId]);

  return <div
    className={style.watermark}
    ref={containerRef}
    style={watermarkStyle}
  >{ children }</div>;
}


const mapStateToProps = state => ({
  reduxState: state,
});

export default connect(mapStateToProps)(WatermarkAppContainerDiv);
