import { Map, List, fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import {
  GETMEMBERLIST_GET_SUCC,
  MEMBER_UPDATE_SUCC,
  MEMBER_DELETE_SUCC,
  MEMBER_ADD_SUCC,
  DAILYCONFIG_GET_SUCC,
  GETSELECTOR_GET_SUCC,
  PRODUCT_HASREPORT_SUCC,
  APPINFO_UPDATE_SUCC,
  SHOW_INVITATEPOP,
} from 'store/actions';

import { getNickName, getUserId } from 'utils/helper';
import { initialState, dailyReportConfig } from './initialState';

export default handleActions({
  GETNEWSELECTOR_GET_SUCC: (state, action) => { // 新的selector接口,
    const { data = {} } = action.response;
    const { processorList = [], disableMemberInvitation } = data;
    console.log("memeber===",data);
    return state.set('memberList', processorList).set('disableMemberInvitation', disableMemberInvitation);
  },
  [GETMEMBERLIST_GET_SUCC]: (state, action) => { // 老的selector接口,暂时保留
    const { members = [] } = action.response;
    return state.set('memberList', members);
  },
  VERSIONS_GET_SUCC: (state, action) => {
    const { versions = [] } = action.response;
    return state.update('versionList', (versionList) => versionList.clear().merge(versions));
  },
  UPDATE_VERSION_PARAMS: (state, action) => state.update('versionSearchParams', (params) => {
    const { type, value } = action.payload;
    return params.set(type, value);
  }),
  DISABLEVERSION_GET_SUCC: (state, action) => {
    const verIndex = state.get('versionList').findIndex((version) => {
      return action.params.version === version.get('version');
    });
    if (verIndex === -1) {
      return state;
    }
    return state.update('versionList', (versionList) => versionList.update(verIndex, (version) => version.set('isShow', action.params.isShow).set('enable', 0)));
  },
  ENABLEVERSION_GET_SUCC: (state, action) => {
    const verIndex = state.get('versionList').findIndex((version) => {
      return action.params.version === version.get('version');
    });
    if (verIndex === -1) {
      return state;
    }
    return state.update('versionList', (versionList) => versionList.update(verIndex, (version) => version.set('isShow', action.params.isShow).set('enable', 1)));
  },
  APPINFO_GET_SUCC: (state, action) => state.set('app', new Map(action.response.data)),
  [APPINFO_UPDATE_SUCC]: (state, action) => {
    const { appInfo } = action.response;
    return state.set('app', appInfo ? fromJS(appInfo) : new Map());
  },
  [MEMBER_UPDATE_SUCC]: (state, action) => {
    const { data = {},code} = action.response;
    console.log("infooooo==",data);
    return state.set('memberList', (parseInt(code) === 200 ? data : state.get('memberList')));
  },
  [MEMBER_DELETE_SUCC]: (state, action) => {
    const { data = {} ,code} = action.response;
    return state.set('memberList', (parseInt(code) === 200 ? data : state.get('memberList')));
  },
  [MEMBER_ADD_SUCC]: (state, action) => {
    const { data = [] } = action.response;
    return state.set('memberList', data);
  },
  PRODUCT_DELETE_SUCC: (state) => {
    return state;
  },
  [DAILYCONFIG_GET_SUCC]: (state, { response: { data: { datas } } = { datas: [] } }) => {
    if (datas.length) {
      return state.set('dailyConfig', dailyReportConfig.merge(datas[0]));
    }
    return state;
  },
  DAILYCONFIG_UPDATE_SUCC: (state, { response: { dailyConfig: { datas } } = { datas: [] } }) => {
    return state.set('dailyConfig', datas.length && dailyReportConfig.merge(datas[0]));
  },
  [GETSELECTOR_GET_SUCC]: (state, action) => {
    const { processorList = [], versionList = [] } = action.response;
    const tempVersion = new List(versionList).filter((item) => parseInt(item.enable) === 1);// 先过滤出显示的版本
    return state.update('dailyOptions', (options) => options.merge({
      version: {
        options: tempVersion.map((item) => ({
          label: item.name,
          value: encodeURIComponent(item.name),
        })),
      },
      processorList: {
        options: new List(processorList).map((item) => ({ label: getNickName(item), value: getUserId(item) })), // 需要做转换
      },
    }));
  },
  CHANGE_CURTAB: (state, action) => state.set('currentTab', action.payload),
  [SHOW_INVITATEPOP]: (state, action) => state.set('isShowInvitated', action.payload),
  CHANGE_CHECKED: (state, action) => {
    const {
      type, subType, label, checked, checkall,
    } = action.payload;
    return state.updateIn(['permission', type, subType],
        (val) => val.map((i) => {
          if (checkall) {
            return { label: i.label, checked };
          }
          return i.label === label ? { label, checked } : i;
        }));
  },
  [PRODUCT_HASREPORT_SUCC]: (state, { response: { data: { datas } } = { datas: false } }) => {
    return state.set('isIntegrate', datas);
  },
  CHANGE_APPLOGO: (state, action) => {
    const { fileId } = action.payload;// 暂时还是取fileId
    return state.update('app', (app) => app.set('logoUrl', fileId));
  },
  CHECK_VERSION: (state, action) => {
    const verIndex = state.get('versionList').findIndex((version) => {
      return action.payload.version === version.get('version');
    });
    if (verIndex === -1) {
      return state;
    }
    const setted = action.payload.checked;
    return state.update('versionList', (versionList) => versionList.update(verIndex, (version) => version.set('checked', (setted !== undefined ? setted : !version.get('checked')))));
  },
  CLEAR_CHECKED: (state) => state.update('versionList', (versionList) => versionList.map((ver) => ver.set('checked', false))),
  WEBHOOKCONFIG_GET_SUCC: (state, action) => state.set('webhookInfo', action.response.data.datas),
}, initialState);
