import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useImmer } from 'use-immer';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import RestHelper from 'utils/RestHelper';
import scss from './IssueRegroup.scss';
import { isZh } from 'utils/zhEn';
import { Row, Col, Button, Modal, Form, Table, Switch, Card, Input, Radio, InputNumber, Spin, DatePicker, List, Select, Checkbox, Space, message, Tabs, Popover, Tooltip  } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import groupBy from 'lodash/collection/groupBy';
import LastReport from 'components/exception/issue/LastReport/LastReport';
import reportRecordStyle from './ReportRecord/style.scss';
import lastReportStyle from './LastReport/style.scss';
import {connect, useDispatch, useSelector} from 'react-redux';
import { bindActionCreators } from 'redux';
import * as productActions from '../../../reducers/product/actions';
import * as appActions from 'reducers/app/appActions';
import * as globalActions from '../../../reducers/global/globalActions';
import { isNotNullish, isNullish } from 'utils/nullish';
import DataDetail from './DataDetail';
import IssueCrashPreview from '../IssueRegroupCrashInfoList'
import { getPercent, makeDetailObjectFromStackLine } from 'utils/helper';
import FLAMETHROWER from './flamethrower'
import ReactECharts from 'echarts-for-react';
import {
  getReportCategoryByExceptionTypeInt,
  EXCEPTION_TYPE_INT_ANDROID_JAVA,
  EXCEPTION_TYPE_INT_ANDROID_NATIVE,
} from 'utils/constants/exception-type-int';
import { isAndroidOrHarmony, isMobile, isIos, isPcOrLinux } from 'utils/platform';
import { PLATFORM_ID } from 'utils/constants';
import IssueCrashFilter, {
  IssueCrashFilterUtil
} from 'components/commons/IssueCrashFilter/IssueCrashFilter';
import pickBy from 'lodash.pickby';
import { useZhEn } from 'utils/react-hooks/useZhEn';
import { UserTypeEnum } from "utils/constants/user-type-enum";
import {ServerAppSettings} from "utils/server-app-settings";
import {selectServerAppSettings} from "utils/selectors/selectors";
import { ExceptionCategoryUtil } from 'utils/exception-category';
import { GlobalResizeObserver } from 'utils/global-resize-observer';
import { COLOR_LIST } from 'utils/constants/chart-options';
import WrappedTipsIcon from 'components/antd-extension/WrappedTipsIcon.jsx';
import IssueCrashFilterEx from 'components/commons/IssueCrashFilter/IssueCrashFilterEx';
import { FieldName, QueryType } from 'components/commons/IssueCrashFilter/IssueCrashFilterExUtil';
import IssueCrashFilterScss from 'components/commons/IssueCrashFilter/IssueCrashFilter.scss';

const { RangePicker } = DatePicker;

const EXPAND_MODE_STACK = 'EXPAND_MODE_STACK';
const EXPAND_MODE_CRASH_LIST = 'EXPAND_MODE_CRASH_LIST';

const asyncConfirmNoReject = (config) => {
  return new Promise((resolve, reject) => {
    Modal.confirm({
      ...config,
      onOk() {
        resolve();
      },
    });
  });
};

const REGROUP_RULE_ITEM_MIN_WIDTH_PX = 450;

/**
 * 问题重分类子tab页面
 * @param {*} props
 */
const IssueRegroup = (props) => {
  const { t } = useTranslation();
  const { ze } = useZhEn();
  const { appId, platformId, issueHash, issue, exceptionType, path, reduxState, actions, isFromSubIssueTab } = props;
  const issueId = issueHash;
  const pid = platformId;
  const currentApp = reduxState.app.get('current').toJS();
  const { appName } = currentApp;

  const classificationTips = {
    'cn': "分类结果为一次性计算的实时展示，并不会影响到当前项目的分类规则，如果想配置项目的分类规则，请前往",
    'en': "Regrouping results is real time and disposable, and has no impact on grouping rule of project. If you want to edit project classification rule, please go to the web page ",
    'routeNameCN': "异常配置/分类配置页面",
    'routeNameEN': "Issue Grouping Configuration",
  }

  // 监听堆栈行关键词编辑框的宽度，用于自适应调整字段宽度及一行的字段数量
  const [regroupRuleContainerWidthPx, setRegroupRuleContainerWidthPx] = useState(0);
  const regroupRuleContainerRefCallback = useCallback((node) => {
    if (!node) {
      GlobalResizeObserver.unobserve(node);
      return;
    }

    setRegroupRuleContainerWidthPx(node.offsetWidth);
    GlobalResizeObserver.observe(node, (entry) => {
      setRegroupRuleContainerWidthPx(entry.target.offsetWidth);
    });
  }, []);
  const regroupRuleItemsPerRow = Math.max(1, Math.floor(regroupRuleContainerWidthPx / REGROUP_RULE_ITEM_MIN_WIDTH_PX));

  const issueObj = reduxState.issue.toJS();
  const versionOptions = issueObj.selectOptions.version.options.filter(x => x.value && x.value !== 'all');

  const [initDone, setInitDone] = useState(false);
  const [dataValue, setDataValue] = useState({
    javaLimit: 0,
    nativeLimit: 0,
    stackKeyWords: [],
    numericSearchConditions: [],
  });
  const [stackKeyWordsValue, setStackKeyWords] = useState([]);
  const [pagination, setPagination] = useState({current: 1,pageSize: 10});
  const [needExpMessage, setNeedExpMessage] = useState(true);
  const [needExpName, setNeedExpName] = useState(true);
  const [needPcAddress, setNeedPcAddress] = useState(true);
  const [treeTackTrace, setTreeTackTrace] = useState(null);
  const [segmentation, setSegmentation] = useState('survey');
  const { issueExceptionType } = issue.toJS();
  const [formInstance] = Form.useForm();
  const dispatch = useDispatch();
  const serverAppSettings = useSelector(state => selectServerAppSettings(state, appId));
  const pcClassify = serverAppSettings[ServerAppSettings.keys.pcClassify] || {};
  const matchTypeList = {
    'INCLUDE': t('issueCrashFilter.包含'),
    'PRECISE': t('issueCrashFilter.精准'),
  };

  const [state, updateState] = useImmer({
    checkbox:[],
    javaLimit: 0,
    nativeLimit: 0,
    pcLimit: pcClassify.stackLine ? pcClassify.stackLine : 3,
    stackLine: pcClassify.stackLine ? pcClassify.stackLine : 3,
    maxCrashes: 1000,
    loading: false,
    newIssueList: [],
    setConfigurationState: false,
    isEmptyFetchResult: false,
    expandedNewIssueId: null,
    expandedLoading: false,
    expandedCrashId: null,
    expandedCrashDoc: null,
    searchConditionGroup: {
      conditions: [
        { field: FieldName.version },
        { field: FieldName.crashUploadTime, queryType: QueryType.RANGE_RELATIVE_DATETIME, gte: 7 * 86400 * 1000 },
      ],
    },
  });

  const showNativeLimit = isIos(pid) && !isPcOrLinux(pid);
  const showPCLimit = isPcOrLinux(pid);
  const showJavaLimit = (!isPcOrLinux(pid) && isAndroidOrHarmony(pid)) || appId === '1106467070'; // TODO: 国内和平正式服先全量允许显示javaLimit
  const showDFMNumericSearchConditions = isPcOrLinux(pid) && appName.includes("DFM"); // DFM-pc的特殊筛选条件

  // const showNativeLimit = (Number(issueExceptionType) === EXCEPTION_TYPE_INT_ANDROID_NATIVE || ExceptionCategoryUtil.isError(exceptionType) || isIos(platformId))
  //   && !isPcOrLinux(pid);
  // const showJavaLimit = (Number(issueExceptionType) === EXCEPTION_TYPE_INT_ANDROID_JAVA && !isPcOrLinux(pid))
  //   || appId === '1106467070'; // TODO: 国内和平正式服先全量允许显示javaLimit
  const {
    searchConditionGroup,
  } = state;

  const expandedIssue = isNotNullish(state.expandedCrashDoc)
    ? issue.set('crashDoc', state.expandedCrashDoc)
    : issue;

  // 初始化状态
  useEffect(() => {
    if (!appId || !platformId || !issueHash) {
      setInitDone(false);
    }
    setInitDone(true);
    reclassificationMessage();
    requestIssueRegroup({}, 'auto');
  }, [appId, platformId, issueHash]);

  const makeDetailedStack = (crashInfo) => {
    const { addFrame, addIosFrame } = crashInfo;
    const nativeUsedFrames = ({
      [PLATFORM_ID.ANDROID]: addFrame,
      [PLATFORM_ID.IOS]: addIosFrame,
    }[platformId]) || [];
    const { retraceCrashDetail } = crashInfo;
    const stackLines = retraceCrashDetail.split('\n');
    return stackLines.map((stackLine, i) => {
      const isDefinitive = isNotNullish(nativeUsedFrames.find(x => x.fLevel === i));
      const isSkipped = !isDefinitive;
      return makeDetailObjectFromStackLine(stackLine, exceptionType, pid, {
        isSkipped,
        isDefinitive,
      });
    }).filter(x => x.line);
  };

  //触发分页事件
  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
  };

  //当每页条数发生变化的时候更改页数
  useEffect(() => {setPagination({current: 1,pageSize: pagination.pageSize})},[pagination.pageSize]);

  const totalFetchedCrashes = useMemo(() => {
    return state.newIssueList.reduce((acc, x) => acc + x.crashList.length, 0);
  }, [state.newIssueList]);

  const chartData = useMemo(() => {
    const MAX_DISPLAYED_ISSUES = 10;
    const res = state.newIssueList.map(x => ({
      name: x.newIssueId,
      value: x.crashList.length,
    }));
    if (res.length > 10) {
      const otherCount = res
        .map(x => x.value)
        .reduce((x, acc, i) => {
          if (i < MAX_DISPLAYED_ISSUES) {
            return acc;
          }
          return acc + x;
        }, 0);
      return [
        ...res.filter((x, i) => i < MAX_DISPLAYED_ISSUES),
        { name: '其他', value: otherCount, isOther: true },
      ];
    }
    return res;
  }, [state.newIssueList]);

  const chartOptions = useMemo(() => {
    return {
      color: COLOR_LIST,
      tooltip: {
        trigger: 'item',
        formatter: `{b}<br>${t('issueRegroup.次数')}: {c}<br>${t('issueRegroup.占比')}: {d} %`,
      },
      series: [{
        type: 'pie',
        radius: '50%',
        data: chartData,
        label: {
          position: 'outer',
          alignTo: 'edge',
          margin: 10,
        },
      }],
    };
  }, [state.newIssueList]);

  // echarts-for-react的问题，onEvents一定要传同一个引用，不然会触发重渲染
  const chartEvents = useMemo(() => ({
    click: (params) => {
      if (params.data.isOther) {
        return;
      }
      updateState((draft) => {
        draft.expandedNewIssueId = params.name;
      });
    },
  }), []);
  const reclassification = async (value) => {
    const { maxCrashes } = state;
    if (maxCrashes >= 5000) {
      await asyncConfirmNoReject({
        title: ze('重分类计算确认','Issue Regroup Confirmation'),
        content: ze(`对${maxCrashes}条上报进行重分类计算可能需要较长的计算时间（一分钟左右），要继续吗？`,`Recalculating the group of ${maxCrashes} reported records may take a long time (about one minute). Please confirm whether to continue. `),
      });
    }
    requestIssueRegroup(value);
    requestIssueRegroup(value,'auto');
  }

  const requestIssueRegroup = async (value,type) => {
    const { checkbox, javaLimit, nativeLimit, maxCrashes } = state;

    updateState({ ...state, loading: true });
    if (value.nativeLimit < 1 && type !== 'auto') {
      if (showJavaLimit) {
        message.error(`${ze('C++关键堆栈行数不能为空', 'The number of C++ stack lines cannot be empty')}`);
      } else {
        message.error(`${ze('C++关键堆栈行数不能为空', 'The number of C++ stack line cannot be empty')}`);
      }
      updateState((draft) => {
        draft.loading = false;
      });
      return;
    }
    if (value.javaLimit < 1 && type !== 'auto') {
      message.error(`${ze('java关键堆栈行数不能为空', 'The number of java stack line cannot be empty')}`);
      updateState((draft) => {
        draft.loading = false;
      });
      return;
    }
    let numericSearchConditions = '';
    if (showDFMNumericSearchConditions && value.numericSearchConditions) {
      let searchKeys = {}
      value.numericSearchConditions.forEach(data => {
        let tmp = {}
        tmp[data['operator']] = data['limitNumber'];
        searchKeys[data['keyword']] = tmp;
      });
      numericSearchConditions = JSON.stringify(searchKeys);
    }

    const rsp = await RestHelper.post('/redir/query/data/queryIssueSplit', {
      appId,
      platformId,
      crashSearchBean: !isFromSubIssueTab ? {
        searchConditionGroup,
      } : {},
      issueId,
      maxCrashes,
      stackKeyWords: !isFromSubIssueTab ? value.stackKeyWords : [],
      stackLine: value.pcLimit ? value.pcLimit : 3,
      needExpMessage: needExpMessage,
      needExpName: needExpName,
      needPcAddress: needPcAddress,
      ...(type == 'auto' ? {
        type:'auto',
        javaLimit: 100,
        nativeLimit: 100,
        pcLimit: 100,
      } : {
        javaLimit: isAndroidOrHarmony(platformId) ? value.javaLimit ? value.javaLimit : 0 : 0,
        nativeLimit: value.nativeLimit ? value.nativeLimit : 0,
        pcLimit: value.pcLimit ? value.pcLimit : 3,
      }),
      numericSearchConditions,
    });
    const { requestList, treeTackTrace } = rsp.json.data || {};
    if(type == 'auto'){
      setTreeTackTrace(treeTackTrace || null);
    }
    if(type !== 'auto' || isFromSubIssueTab) {
      setSegmentation('dispatch')
      const refinedRequestList = (requestList || []).map(x => ({
        ...x,
        key: x.crashId,
        issueId: x.crossVersionIssueHash.replace(/:/g, ''),
        stackLineDetailObjectList: makeDetailedStack(x),
      }));
      const newIssueList = Object.entries(groupBy(refinedRequestList, x => x.issueId))
        .map(([k, v]) => ({ newIssueId: k, crashList: v }))
        .sort((a, b) => b.crashList.length - a.crashList.length);
      updateState((draft) => {
        draft.newIssueList = newIssueList;
        draft.expandedNewIssueId = (newIssueList[0] || {}).newIssueId;
        draft.isEmptyFetchResult = newIssueList.length === 0;
      });
    }
    updateState((draft) => {
      draft.loading = false;
    });
  };

  //获取配置重分类的信息
  const reclassificationMessage = async() => {
    const { current } = issueObj || {};
    const { crashDoc } = current || {};
    const { esMap } = crashDoc || {};
    const { addCodeFrame } = esMap || {};
    let addCodeFrameLength = 0;
    if (addCodeFrame) {
      addCodeFrameLength = addCodeFrame.length;
    }
    const rep = await  RestHelper.post('/redir/api/classify/getClassifyInfoByAppId',{
      appId,
      platformId,
      issueId
    });
    let dataList = rep.json.data;
    const getClassifyInfoByAppId = {
      javaLimit:dataList.javaLimit,
      nativeLimit:dataList.nativeLimit,
      stackKeyWords:isPcOrLinux(pid) ? (pcClassify.ignoreKeyWords || []).map(item => ({matchType:"INCLUDE",stackKeyWord:item})) : dataList.stackKeyWords,
    }
    if(dataList){
      formInstance.setFieldsValue(getClassifyInfoByAppId);
      setDataValue(getClassifyInfoByAppId);
    };
    setNeedExpMessage(pcClassify.needExpMessage);
    setNeedExpName(pcClassify.needExpName);
    setNeedPcAddress(pcClassify.needPcAddress);
  };

  const fetchCrashDetailByCrashId = async (crashId) => {
    const rsp = await RestHelper.get(`/crashDoc/appId/${appId}/platformId/${platformId}/crashHash/${crashId}`);
    return rsp.json.ret;
  };

  const tableColumns = [{
    dataIndex: 'crashId',
    title: 'Crash ID',
  }];

  const dispatchResultTableColums = [{
    dataIndex: 'newIssueId',
    title: `${ze('子分类','Subgroup')}(${state.newIssueList.length})`,
  },{
    dataIndex: 'percentage',
    title: t('issueRegroup.占比'),
  },{
    title: ze('操作','Operation'),
    dataIndex: '',
    key: 'x',
    render: (record) => (
      <a onClick={async e => {
        if (state.expandedCrashId !== record.newIssueId) {
          updateState({ ...state, expandedCrashId: record.newIssueId, expandedLoading: true });
        } else {
          updateState({ ...state, expandedCrashId: null, expandedLoading: true });
        }
      }}>
        {state.expandedCrashId !== record.newIssueId? ze('展开', 'expand'): ze('收起', 'collapse')}
      </a>
    ),
  }];

  //应用配置确认
  const affirmConfigurationOK = async() => {
    try {
      let data = formInstance.getFieldsValue();
      if(isPcOrLinux(pid)){
        await dispatch(appActions.upsertServerAppSettings(appId, {
          pcClassify: {
            needExpMessage: needExpMessage,
            needExpName: needExpName,
            needPcAddress: needPcAddress,
            stackLine: state.pcLimit,
            ignoreKeyWords: (data.stackKeyWords || []).map(item => item.stackKeyWord),
            stackType: -1
          }
        }));
      } else {
        const rep = await RestHelper.post('/redir/api/classify/applyClassifyInfoByAppId',{
          appId,
          platformId,
          javaLimit: data.javaLimit,
          nativeLimit: data.nativeLimit,
          stackKeyWords:data.stackKeyWords,
        });
      }
      updateState({
        ...state,
        setConfigurationState:false,
      })
      message.success('应用配置成功');
    } catch(e) {
      message.error(e);
    };
  };
  //判断管理员
  const hasAdminPermission = () => {
    const {isSuper, type } = reduxState.user.get('current').toJS();
    return isSuper || type === UserTypeEnum.ADMIN;
  };

  //应用配置按钮
  const configurationButton = () =>{
    formInstance.validateFields()
      .then( () => {
        let data = formInstance.getFieldsValue();
        if (data.nativeLimit < 1 ){
          if (showJavaLimit) {
            message.error(`${ze('C++关键堆栈行数不能为空', 'The number of C++ stack line cannot be empty')}`);
          } else {
            message.error(`${ze('C++关键堆栈行数不能为空', 'The number of C++ stack line cannot be empty')}`);
          }
          return;
        }
        if (data.javaLimit < 1 ){
          message.error(`${ze('java关键堆栈行数不能为空', 'The number of Java stack line cannot be empty')}`);
          return;
        }
        updateState({
          ...state,
          javaLimit: data.javaLimit,
          nativeLimit: data.nativeLimit,
          setConfigurationState:true,
        });
        setStackKeyWords(data.stackKeyWords);
      })
      .catch( (err) => {
        message.error(err);
      })
  }

  const renderNewIssueListItem = (newIssue) => {
    const { newIssueId, crashList } = newIssue;
    const { stackLineDetailObjectList } = crashList[0];

    return <List.Item>
      <div style={{ width: '100%' }}>
        <Row align='middle' gutter={10}>
          <Col>{ t('issueRegroup.子问题newIssueId下的最近一次上报堆栈', { newIssueId }) }</Col>
          <Col>
            {false && <Radio.Group
              options={[{ value: EXPAND_MODE_STACK, label: '堆栈' }, { value: EXPAND_MODE_CRASH_LIST, label: '上报列表' }]}
            />}
          </Col>
        </Row>
        <div className={lastReportStyle.data_container}>
          <DataDetail {...{
            pid,
            dataType: 'stack',
            style: lastReportStyle,
            exceptionType,
            isRetracedStack: true,
            stackLineDetailObjectList,
          }}/>
        </div>
        <div style={{ marginTop: '15px' }}>{ t('issueRegroup.子问题newIssueId的上报列表', { newIssueId }) }</div>
        { newIssueId === state.expandedNewIssueId && <div>
          <Table
            columns={tableColumns}
            dataSource={crashList}
            pagination={pagination}
            onChange={handleTableChange}
            expandable={{
              expandRowByClick: true,
              expandedRowKeys: state.expandedCrashId ? [state.expandedCrashId] : [],
              expandedRowRender: (crashInfo) => (<Spin spinning={state.expandedLoading}>
                <LastReport {...{
                  appId,
                  pid,
                  issueId,
                  issue: expandedIssue,
                  style: reportRecordStyle,
                  exceptionType,
                  path,
                  user: reduxState.user.get('current'),
                  crashHash: state.expandedCrashId,
                  isIssueRegroup: true,
                  stackLineDetailObjectList: crashInfo.stackLineDetailObjectList,
                }}/>
              </Spin>),
              onExpandedRowsChange: async (expandedRows) => {
                const oldExpandedKey = state.expandedCrashId;
                const newExpandedKey = expandedRows.find(x => x !== oldExpandedKey) || null;
                if (newExpandedKey === oldExpandedKey) {
                  return;
                }

                updateState({ ...state, expandedCrashId: newExpandedKey, expandedLoading: true });
                const { crashId } = crashList.find(x => x.key === newExpandedKey) || {};
                if (!crashId) {
                  return;
                }
                const expandedCrashDoc = await fetchCrashDetailByCrashId(crashId);
                updateState((draft) => {
                  draft.expandedCrashDoc = expandedCrashDoc;
                  draft.expandedLoading = false;
                });
              },
            }}
          />
        </div>}
      </div>
    </List.Item>;
  };

  //展示应用配置更改的内容
  const useConfigurationOld = () => {
    let dataList = dataValue.stackKeyWords;
    if (dataValue.stackKeyWords.length > stackKeyWordsValue.length){
      dataList = dataValue.stackKeyWords;
    } else {
      dataList = stackKeyWordsValue;
    }
    let universalityStackDataSource = dataList.map((item,index) => {
      return {
        key: index+2,
        name: `${index === 0 ? ze('配置需跳过的通用堆栈行:', 'Configure generic stack lines to be skipped:') : ''}`,
        beforeChange:`${ t('issueRegroup.关键词') }${index+1}:${matchTypeList[(dataValue.stackKeyWords[index] || {})['matchType']]},${(dataValue.stackKeyWords[index] || {})['stackKeyWord']}`,
        afterChange: `${ t('issueRegroup.关键词') }${index+1}:${matchTypeList[(stackKeyWordsValue[index] || {})['matchType']]},${(stackKeyWordsValue[index] || {})['stackKeyWord']}`,
      }
    }
    );
    const columns = [
      {
        title: '',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: `${ze('变更前问题分类参数配置', 'Configuration of the problem classification parameters before the change')}`,
        dataIndex: 'beforeChange',
        key: 'beforeChange',
        render:(_,record) => (
          <span>
            {(!/关键词\d+:undefined/.test(record.beforeChange) && !/Keywords\d+:undefined/.test(record.beforeChange)) && record.beforeChange}
          </span>
        ),
      },
      {
        title: `${ze('变更后问题分类参数配置', 'Changed problem classification parameters configuration')}`,
        dataIndex: 'afterChange',
        key: 'afterChange',
        render:(_,record) => (
          <span key={record.key} style={{color:(record.beforeChange === record.afterChange ? "" : "red")}}>
            {(!/关键词\d+:undefined/.test(record.afterChange) && !/Keywords\d+:undefined/.test(record.afterChange)) && record.afterChange}
          </span>
        ),
      },
    ];
    let dataSourceStack = [];
    if (showJavaLimit) {
      dataSourceStack = [
        {
          key: '2',
          name: `${ze('Java关键堆栈行数:', 'The number of Java stack lines:')}`,
          beforeChange: `${dataValue.javaLimit}`,
          afterChange: `${state.javaLimit}`,
        },
      ];
    }
    if (showNativeLimit) {
      dataSourceStack = [
        {
          key: '1',
          name: `${ze('Native关键堆栈行数:', 'The number of C++ stack lines:')}`,
          beforeChange: `${dataValue.nativeLimit}`,
          afterChange: `${state.nativeLimit}`,
        },
      ];
    }
    if (isPcOrLinux(pid)) {
      dataSourceStack = [
        {
          key: '1',
          name: `${ze('PC关键堆栈行数:', 'The number of C++ stack lines:')}`,
          beforeChange: `${pcClassify.stackLine ? pcClassify.stackLine : 3}`,
          afterChange: `${state.pcLimit}`,
        },
      ];
    }
    let dataSourceList =[...dataSourceStack,...universalityStackDataSource];
    // let dataSourceList =[...dataSourceStack];
    return (
      <Table columns={columns} dataSource={dataSourceList} pagination={false}/>
    )
  }

  // 渲染分类条件配置
  const renderStackTagConfig = () => {
    return <div style={{ border: "1px solid #f0f0f0", width: "100%", display: 'flex' }}>
      <div style={{ width: '100%', borderRight: '1px solid #f0f0f0' }}>
        <Form
          form={formInstance}
          style={{ marginBottom: '10px', margin: "10px" }}
          initialValues={state}
          onValuesChange={(changedValues) => {
            updateState({ ...state, ...changedValues });
          }}
          onFinish={reclassification}
        >
          <div>
            <div
              ref={regroupRuleContainerRefCallback}
              className={scss.regroupRuleContainer}
            >
              <div style={{ display: "flex" }}>
                <h3>{t('issueRegroup.配置需跳过的通用堆栈行')}:</h3>
              </div>
              <Form.List name="stackKeyWords">
                {(fields, { add, remove }) => (
                  <div style={{ display: 'flex', flexWrap: 'wrap', rowGap: '8px' }}>
                    {fields.map(({ key, name, ...restField }) => (
                      <div
                        key={key}
                        style={{
                          display: 'flex',
                          gap: '8px',
                          paddingRight: '32px',
                          width: `${Math.floor(100 / regroupRuleItemsPerRow)}%`,
                        }}
                      >
                        <div style={{ minWidth: isZh() ? '65px' : '80px', marginTop: '5px' }}>{t('issueRegroup.关键词')}{name + 1}</div>
                        <Form.Item
                          {...restField}
                          name={[name, 'matchType']}
                          rules={[
                            {
                              required: true,
                              message: (
                                <div style={{ fontSize: '12px' }}>{ze('类型不能为空', 'Keyword cannot be empty')}</div>
                              )
                            },
                          ]}
                        >
                          <Select
                            style={{
                              width: 150,
                            }}
                            options={[
                              {
                                value: 'INCLUDE',
                                label: `${t('issueCrashFilter.包含')}`,
                              },
                              ...(!isPcOrLinux(pid) ? [{
                                value: 'PRECISE',
                                label: `${t('issueCrashFilter.精准')}`,
                              }] : []),
                            ]}
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'stackKeyWord']}
                          style={{ flex: 1 }}
                          rules={[{
                            required: true,
                            message: (
                              <div style={{ fontSize: '12px' }}>{ze('关键词不能为空', 'Keyword cannot be empty')}</div>
                            )
                          }]}
                        >
                          <Input
                            placeholder={t('issueRegroup.关键词')}
                          />
                        </Form.Item>
                        <div style={{ marginTop: '5px' }}><MinusCircleOutlined onClick={() => remove(name)} /></div>
                      </div>
                    ))}
                    <div style={{ width: `${Math.floor(100 / regroupRuleItemsPerRow)}%` }}>
                      <Button onClick={() => add({ matchType: "INCLUDE", stackKeyWord: '' })} icon={<PlusOutlined />}>
                        {/* {t('issueRegroup.新增匹配跳过的关键词')} */}
                        {/* {ze("新增", "Add")} */}
                      </Button>
                    </div>
                  </div>
                )}
              </Form.List>
            </div>
          </div>
          <hr style={{ color: "#f0f0f0", border: "1px dashed", marginBottom: "10px" }} />
          <div className={scss.regroupRuleContainer}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div >
                <h3 style={{ paddingRight: '10px' }}>{t('issueRegroup.参与问题分类的堆栈行数')}:</h3>
                <div style={{ display: "flex" }}>
                  {showJavaLimit && <Form.Item
                    label={ze('C++堆栈特征行数:', 'The number of C++ stack lines:')}
                    name='nativeLimit'
                    rules={[{ required: true }]}
                  >
                    <InputNumber
                      min={0}
                      max={20}
                    />
                  </Form.Item>}
                  {showNativeLimit && <Form.Item
                    label={ze('C++堆栈特征行数:', 'The number of C++ stack lines:')}
                    name='nativeLimit'
                    rules={[{ required: true }]}
                  >
                    <InputNumber
                      min={0}
                      max={20}
                    />
                  </Form.Item>}
                  {showJavaLimit && <Form.Item
                    label={ze('Java关键堆栈行数', 'The number of Java stack lines')}
                    name='javaLimit'
                    rules={[{ required: true }]}
                    style={{ paddingLeft: '20px' }}
                  >
                    <InputNumber
                      min={0}
                      max={20}
                    />
                    </Form.Item>}
                  {isPcOrLinux(pid) && <Form.Item
                    label={ze('C++堆栈特征行数:', 'The number of C++ stack lines')}
                    name='pcLimit'
                    rules={[{ required: true }]}
                  >
                    <InputNumber
                      min={0}
                      max={20}
                    />
                  </Form.Item>}
                </div>
              </div>
            </div>

            {isPcOrLinux(pid) && <div style={{ marginBottom: "10px" }}>
              <Checkbox checked={needExpMessage} onChange={() => setNeedExpMessage(!needExpMessage)}>{ze('异常消息', 'Exception Message')}</Checkbox>
              <Checkbox checked={needExpName} onChange={() => setNeedExpName(!needExpName)}>{ze('异常名', 'Exception Name')}</Checkbox>
              <Checkbox checked={needPcAddress} onChange={() => setNeedPcAddress(!needPcAddress)}>{ze('地址是否参与分类', 'Stack Address')}</Checkbox>
            </div>}
          </div>
          {showDFMNumericSearchConditions && <hr style={{ color: "#f0f0f0", border: "1px dashed", marginBottom: "10px" }} />}
          {showDFMNumericSearchConditions && <div>
            <div
              ref={regroupRuleContainerRefCallback}
              className={scss.regroupRuleContainer}
            >
              <h3 style={{ paddingRight: '10px' }}>{ze("自定义问题分类规则", "Custom issue group rule")}:</h3>
              <Form.List name="numericSearchConditions">
                {(fields, { add, remove }) => (
                  <div style={{ display: 'flex', flexWrap: 'wrap', rowGap: '8px' }}>
                    {fields.map(({ key, name, ...restField }) => (
                      <div
                        key={key}
                        style={{
                          display: 'flex',
                          gap: '8px',
                          paddingRight: '32px',
                          width: `${Math.floor(100 / regroupRuleItemsPerRow)}%`,
                        }}
                      >
                        <Form.Item
                          {...restField}
                          name={[name, 'keyword']}
                          rules={[{
                            required: true,
                            message: (<div style={{ fontSize: '12px' }}>{ze('关键词不能为空', 'Keyword cannot be empty')}</div>)
                          }]}
                        >
                          <Select
                            style={{ width: 200 }}
                            options={[
                              {
                                value: 'K#FreePageFile-OOM',
                                label: `K#FreePageFile-OOM`,
                              }, {
                                value: 'K#FreePageFile-Start',
                                label: `K#FreePageFile-Start`,
                              }, {
                                value: 'K#FreePhysical-Start',
                                label: `K#FreePhysical-Start`,
                              },
                            ]}
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'operator']}
                          rules={[{
                            required: true, message: (<div style={{ fontSize: '12px' }}>{ze('关键词不能为空', 'Keyword cannot be empty')}</div>)
                          }]}
                        >
                          <Select
                            defaultValue={'gte'}
                            style={{ width: 70 }}
                            options={[
                              {
                                value: 'gte',
                                label: `≥`,
                              }, {
                                value: 'lte',
                                label: `≤`,
                              }
                            ]}
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          style={{ flex: 1 }}
                          name={[name, 'limitNumber']}
                          rules={[{
                            required: true,
                            message: (
                              <div style={{ fontSize: '12px' }}>{ze('不能为空', 'Cannot be empty')}</div>
                            )
                          }]}
                        >
                          {/* 设置了最大值为2147483647, 32位int的上限 */}
                          <InputNumber style={{ width: '100%' }} min={1} max={Number.MAX_VALUE} />
                        </Form.Item>
                        <div style={{ marginTop: '5px' }}><MinusCircleOutlined onClick={() => remove(name)} /></div>
                      </div>
                    ))}
                    <div style={{ width: `${Math.floor(100 / regroupRuleItemsPerRow)}%` }}>
                      <Button onClick={() => add({ keyword: "K#FreePageFile-OOM", operator: 'gte', limitNumber: 1 })} icon={<PlusOutlined />}>
                      </Button>
                    </div>
                  </div>
                )}
              </Form.List>
            </div>
          </div>}
        </Form>
      </div>
    </div>;
  }

  // 选择查询数据条数的dom
  const extraSlot = <div className={IssueCrashFilterScss.singleFilterBox}>
    <div className={IssueCrashFilterScss.singleFilterFieldSelect}>
      <Input
        style={{ width: '100%' }}
        readOnly={true}
        value={ ze('数据源', 'Data Source') }
      />
    </div>
    <div style={{ flexGrow: 1, marginLeft: '0' }}>
      <Select
        style={{ width: '100%' }}
        value={state.maxCrashes}
        onChange={(changedValues) => {
          updateState({ ...state, maxCrashes: changedValues });
        }}
        options={[1000, 2000, 5000, 10000].map(x => ({ value: x, label: t('issueRegroup.取最近n次上报', { n: x }) }))}
      />
    </div>
  </div>;

  // 渲染结果的条件过滤器和崩溃数量
  const renderIssueResultFilter = () => {
    return <div style={{ border: "1px solid #f0f0f0", width: "100%", marginTop: '10px', marginBottom: '10px'}}>
      <div style={{ margin: '10px', fontSize: '14px' }}>
        <IssueCrashFilterEx
          hideIssueFields={true}
          hideSubmitButton={true}
          fieldBlacklist={['crashId', 'issueId', 'bundleId', 'osVersion', 'deviceIdList', 'expUid', 'crashId', 'issueId', 'contectInfo', 'exceptionTypeList', 'customKey']}
          platformId={platformId}
          filterOptions={{
            version: versionOptions,
          }}
          searchConditionGroup={searchConditionGroup}
          onChange={({ searchConditionGroup }) => {
            updateState(draft => {
              draft.searchConditionGroup = searchConditionGroup;
            });
          }}
          prefixSlotList={[extraSlot]}
        />
      </div>
    </div>;
  }

  const renderDispatch = () => {
    if (state.isEmptyFetchResult) {
      return <div style={{ fontWeight: '700', marginLeft: '10px', marginTop: '10px' }}>
        {t('issueRegroup.过滤条件内未匹配到任何上报')}
      </div>;
    }
    return <div style={{width:'100%',paddingTop:'10px'}}>
      <Table
        columns={dispatchResultTableColums}
        dataSource={state.newIssueList.map(x => ({
          key: x.newIssueId,
          newIssueId: x.newIssueId,
          percentage: `${getPercent(x.crashList.length, totalFetchedCrashes, 2)}%`,
          ...x
        }))}
        pagination={pagination}
        onChange={handleTableChange}
        expandable={{
          expandIconAsCell: false,
          expandIconColumnIndex: -1,
          expandedRowKeys: state.expandedCrashId ? [state.expandedCrashId] : [],
          expandedRowRender: (issueInfo) => (<div>
            <IssueCrashPreview
              appId={appId}
              platformId={platformId}
              issueId={issueInfo.newIssueId}
              crashList={issueInfo.crashList}
              exceptionType={exceptionType}
              showMatchReportCount={false}
            />
          </div>),
        }}
      />
    </div>;
  }

  const renderDispatchOld = () => {
    return <div>
      <div style={{ marginTop: '10px', border: "1px solid #f0f0f0" }}>
        {state.isEmptyFetchResult && <div style={{ fontWeight: '700' }}>
          {t('issueRegroup.过滤条件内未匹配到任何上报')}
        </div>}
        {state.newIssueList.length > 0 && <div style={{ margin: '10px', width: '100%' }}>
          <Row align='middle' gutter={20} style={{ marginBottom: '10px' }}>
            <Col>{t('issueRegroup.重分类后共计算出n个子问题', { n: state.newIssueList.length })}</Col>
            <Col>
              <Select
                style={{ width: '500px' }}
                options={state.newIssueList.map(x => ({
                  value: x.newIssueId,
                  label: `${t('issueRegroup.子问题')}# ${x.newIssueId}  (${t('issueRegroup.占比')} ${getPercent(x.crashList.length, totalFetchedCrashes, 2)}%)`,
                }))}
                defaultValue={state.expandedNewIssueId}
                value={state.expandedNewIssueId}
                onChange={(v) => updateState({ ...state, expandedNewIssueId: v })}
              />
            </Col>
          </Row>
          <Row>
            <ReactECharts
              option={chartOptions}
              style={{ width: '100%' }}
              onEvents={chartEvents}
            />
          </Row>
        </div>}
      </div>

      {state.expandedNewIssueId && <div style={{ width: '100%', paddingTop: '10px' }}>
        <List
          bordered
          dataSource={state.newIssueList.filter(x => x.newIssueId === state.expandedNewIssueId)}
          renderItem={renderNewIssueListItem}
        />
      </div>}
    </div>;
  }

  return (
    <div>
      <Spin spinning={state.loading}>
        {/* <div style={{ marginBottom: '5px' }}>{t('issueRegroup.功能说明1')}{t('issueRegroup.功能说明2')}</div> */}
        {
          // 渲染issue分类TAG的配置
          !isFromSubIssueTab &&
          renderStackTagConfig()
        }
        {
          // 渲染分类结果过滤器
          !isFromSubIssueTab &&
          renderIssueResultFilter()
        }

        {!isFromSubIssueTab && <div style={{ marginLeft: '10px', display:"flex" }}>
          <div style={{ marginRight:"20px" }}>
            <Button
              disabled={state.loading}
              type='primary'
              onClick={() => formInstance.submit()}
            >
              <div  style={{ display:"flex", justifyContent: 'center',gap:'5px' }}>
                <span>{ze("分类预览", "Classification Preview")}</span>
                <Tooltip arrow={true}
                  placement="topLeft"
                  overlayInnerStyle={{width: "720px" }}
                  title={(
                    <div style={{ fontSize: '12px', maxWidth: "45vw"}}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div>{ze(classificationTips.cn, classificationTips.en)}<Link
                          style={{ fontSize: '12px' }}
                          to={`/crash-reporting/preferences/issue-classification-setting/${appId}?pid=${pid}`}
                        >{ze(classificationTips.routeNameCN, classificationTips.routeNameEN)}</Link>
                        </div>
                      </div>
                    </div>
                  )}
                >
                  <WrappedTipsIcon />
                </Tooltip>
              </div>
            </Button>
          </div>
        </div>}

        {isFromSubIssueTab && <div style={{fontSize:'13px',color:'#807997'}}>{ze('CrashSight支持在单一问题下划分子分类。作为问题的子集，每个子分类有相似的堆栈和相同的错误信息。通过这些子分类可以辅助开发者更好的判断同一个问题是否有多个不同的发生原因。','CrashSight allows for the creation of subgrouping of a single issue. Each subgroup, as a subset of the issue, features similar stack traces and the same error messages. These subgroup assist developers in better determining whether a single issue has multiple distinct causes.')}</div>}

        <div style={{margin:'10px'}}>
          <Tabs activeKey={segmentation} onChange={(key) => {
            console.log("切换到了分类结果", key)
            setSegmentation(key);
            if(key === 'dispatch' && state.newIssueList.length == 0){
              formInstance.submit();
            }
          }}>
            <Tabs.TabPane tab={ze("分类结果","Dispatch")} key="dispatch">
            </Tabs.TabPane>
            <Tabs.TabPane tab={ze("分类概览","Survey")} key="survey">
            </Tabs.TabPane>
          </Tabs>
        </div>
        {segmentation == 'dispatch' && renderDispatch()}
        {/* {segmentation == 'dispatch' && renderDispatchOld()} */}

        {segmentation == 'survey' && <div style={{paddingTop:'10px'}}>
            {(treeTackTrace && !isPcOrLinux(pid)) &&  <div style={{width:'100%', border:"1px solid #f0f0f0"}}>
              <div style={{margin:'5px'}}>
                <FLAMETHROWER
                  treeTackTrace={treeTackTrace}
                  nativeLimitFormer={dataValue.nativeLimit} //旧的深度
                  nativeLimitNewly={state.nativeLimit} //当前深度
                  javaLimitFormer={dataValue.javaLimit}//旧的深度
                  javaLimitNewly={state.javaLimit}//当前深度
                  showNativeLimit={showNativeLimit}
                  showJavaLimit={showJavaLimit}
                ></FLAMETHROWER>
              </div>
            </div>}
          </div>}



      </Spin>
      <Modal width={ 800 } title={ t('issueRegroup.配置会应用到整个项目') } visible={ state.setConfigurationState } onOk={ affirmConfigurationOK } onCancel={() => {
        updateState({
          ...state,
          setConfigurationState:false,
        })
      }}>
        <p style={{ color:"red" }}>{ t('issueRegroup.风险提示') }</p>
        { useConfigurationOld() }
      </Modal>
    </div>
  );
};

IssueRegroup.propTypes = {
  reduxState: PropTypes.object,
  actions: PropTypes.object,
  appId: PropTypes.string,
  platformId: PropTypes.string,
  issueHash: PropTypes.string,
  issue: PropTypes.object,
  exceptionType: PropTypes.string,
  path: PropTypes.string,
  isFromSubIssueTab: PropTypes.bool,
};

const mapStateToProps = state => ({
  reduxState: state,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...productActions,
    ...appActions,
    ...globalActions,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(IssueRegroup);
