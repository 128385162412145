import { isNullish } from 'utils/nullish';
import cloneDeep from 'lodash/lang/cloneDeep';
import { LogicalOperator } from 'components/commons/IssueCrashFilter/IssueCrashFilterExUtil';

export const SearchConditionGroupUtil = Object.freeze({
  /** 为scg添加一条必须满足的condition，返回新的scg */
  addMustCondition(searchConditionGroup, condition) {
    if (isNullish(searchConditionGroup) || isNullish(condition)) {
      return searchConditionGroup;
    }
    let res = cloneDeep(searchConditionGroup);
    // 如果searchConditionGroup的逻辑关系是OR，那么要把当前的group变成子group套在AND里面
    if (searchConditionGroup.operator === LogicalOperator.OR) {
      const subgroup = res;
      res = {
        operator: LogicalOperator.AND,
        conditions: [],
        subgroups: [subgroup],
      };
    }
    if (isNullish(res.conditions)) {
      res.conditions = [];
    }
    res.conditions.push(condition);
    return res;
  }
});
