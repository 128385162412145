import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Echart from 'components/commons/Echart';
import EmptyData from 'components/commons/EmptyData';
import { getDateFromStr } from 'utils/StringUtils';
import { LINE_COLOR } from 'utils/constants';
import { formatNum } from 'utils/helper';
import { fromJS } from 'immutable';
import isEmpty from 'lodash/lang/isEmpty';
import { Translation, useTranslation } from 'react-i18next';
import moment from 'moment';
import ReactECharts from 'echarts-for-react';
import { ErrorBoundary } from 'react-error-boundary';
import { isNotNullish } from 'utils/nullish';
import uniq from 'lodash/array/uniq';
import { connect } from 'react-redux';
import { CountryUtil } from 'utils/country';
import { Button } from 'antd';
import { ze } from 'utils/zhEn';
import { cyan, geekblue, orange, red } from '@ant-design/colors';
import { useCrashsightLocalStorage } from 'utils/useCrashSightLocalStorage';
import { COLOR_LIST } from 'utils/constants/chart-options';

const ALL_COUNTRY = '-1';

const REALTIME_PREV_DAY_LEGEND_NAME = ze('对比昨日', 'Prev Day');
const REALTIME_PREV_WEEK_LEGEND_NAME = ze('对比7日前', 'Prev Week');

const TrendChart = ({
  searchParams, compareData, realTimeCompareData, trendData,
  realTimeTrendData,
  handleClickChart, handleShowClickTip, handleHideClickTip,
  isRegionalDashboard,
  reduxState,
  isProductSummaryPage,
  handleChangeSearchParam,
  isTrendDataBeforeGrayStrategyDrop, // true: 灰名单丢弃前的趋势数据 false: 灰名单丢弃后趋势数据
}) => {
  const { t } = useTranslation();

  const REALTIME_PREV_DAY_SECONDARY_ACCESS_DEVICES_LEGEND_NAME = `${REALTIME_PREV_DAY_LEGEND_NAME} (${t('EXCP_OVERVIEW.userNumConnect')})`;
  const REALTIME_PREV_DAY_SECONDARY_ACCESS_NUM_LEGEND_NAME = `${REALTIME_PREV_DAY_LEGEND_NAME} (${t('EXCP_OVERVIEW.sessionCount')})`;
  const REALTIME_PREV_WEEK_SECONDARY_ACCESS_DEVICES_LEGEND_NAME = `${REALTIME_PREV_WEEK_LEGEND_NAME} (${t('EXCP_OVERVIEW.userNumConnect')})`;
  const REALTIME_PREV_WEEK_SECONDARY_ACCESS_NUM_LEGEND_NAME = `${REALTIME_PREV_WEEK_LEGEND_NAME} (${t('EXCP_OVERVIEW.sessionCount')})`;

  const searchParamsObj = searchParams.toJS();
  const {
    compareVersion,
    trendCountry,
    compareCountry,
    enableRealtimePrevDayTrend,
    enableRealtimePrevWeekTrend,
  } = searchParamsObj;
  const isCompare = compareVersion !== 'no_compare';
  const isRealTime = !!Number(searchParamsObj.isRealTime);
  const countryGroupConfigList = reduxState.summary.get('countryGroupConfigList').toJS();
  const realTimePrevDayTrendData = reduxState.summary.get('realTimePrevDayTrendData').toJS();
  const realTimePrevWeekTrendData = reduxState.summary.get('realTimePrevWeekTrendData').toJS();

  const [storage, updateStorage] = useCrashsightLocalStorage();

  const [legendSelectedRealtimePrevDay, setLegendSelectedRealtimePrevDay] = useState(false);
  const [legendSelectedRealtimePrevWeek, setLegendSelectedRealtimePrevWeek] = useState(false);
  const [legendSelectedRealtimePrevDaySecondaryAccess, setLegendSelectedRealtimePrevDaySecondaryAccess] = useState(false);
  const [legendSelectedRealtimePrevWeekSecondaryAccess, setLegendSelectedRealtimePrevWeekSecondaryAccess] = useState(false);

  const realTimeTrendDataJs = useMemo(() => realTimeTrendData.toJS(), [realTimeTrendData]);
  const trendDataJs = useMemo(() => trendData.toJS(), [trendData]);
  const realTimeCompareDataJs = useMemo(() => realTimeCompareData.toJS(), [realTimeCompareData]);
  const compareDataJs = useMemo(() => compareData.toJS(), [compareData]);

  const trendCountryList = getCountryList(trendCountry);
  const compareCountryList = getCountryList(compareCountry);

  const isCountryDimensionMode = trendCountryList.length > 0 || compareCountryList.length > 0;

  function getCountryList(country) {
    return isRegionalDashboard && country && country !== ALL_COUNTRY
      ? country.split(',')
      : [];
  }

  function makeCountryDimensionTrendListGroup(countryList, trendList) {
    if (countryList.length === 0) {
      return [trendList];
    }
    const allTimePoints = uniq(trendList.map(x => x.date));
    return countryList.map(country => allTimePoints.map((timePoint) => {
      const trend = trendList.find(x => x.country === country && x.date === timePoint);
      if (trend) {
        return trend;
      }
      return {
        country,
        date: timePoint,
        accessNum: 0,
        accessUser: 0,
        crashNum: 0,
        crashUser: 0,
        reportNumAllData: 0,
        reportDeviceAllData: 0,
      };
    }));
  }

  function getChartOptions() {
    const { trendVersion, compareVersion, isRealTime, trendTagName } = searchParams.toJS();
    const chartDataResult = getChartData();

    if (!chartDataResult) {
      return {};
    }

    const {
      data: datas,
      secondaryAccessSeriesNameList,
      secondaryAccessSeriesMaxValue,
      secondaryAccessTagName,
    } = chartDataResult;

    const { xAxisList } = datas;

    const yAxisHasName = trendTagName !== 'EXCP_OVERVIEW.userNumConnect' && trendTagName !== 'EXCP_OVERVIEW.sessionCount';

    const options = {
      animation: false,
      color: COLOR_LIST,
      grid: {
        top: 35,
        left: 8,
        right: 8,
        bottom: 44,
        containLabel: true,
      },
      tooltip: {
        trigger: 'axis',
        z: 0,
        axisPointer: {
          type: 'line',
          lineStyle: {
            color: '#cccccc',
            width: 1,
            type: 'solid',
          },
        },
        formatter(params) {
          let tips = '';
          if (isRealTime) {
            tips += `${formatDateTimeRange(params[0].name)}: ${isCompare ? t(trendTagName) : ''}<br />`;
          } else {
            tips += `${t(trendTagName)}<br />`;
          }
          params.forEach((x) => {
            const { axisValue, seriesName, data, marker } = x;
            if (isNotNullish(data)) {
              const ySuffix = !secondaryAccessSeriesNameList.includes(seriesName)
                ? datas.ySuffix
                : '';
              tips += `<div style="display: flex;justify-content:space-between;">
              <span style="padding-right: 20px">${marker} ${!isRealTime ? axisValue : ''} ${seriesName}</span>
              <span>${formatNum(data)} ${ySuffix}</span>
              </div>`;
            }
          });
          return tips;
        },
      },
      legend: {
        bottom: 0,
        selected: {
          [REALTIME_PREV_DAY_LEGEND_NAME]: legendSelectedRealtimePrevDay,
          [REALTIME_PREV_DAY_SECONDARY_ACCESS_DEVICES_LEGEND_NAME]: legendSelectedRealtimePrevDaySecondaryAccess,
          [REALTIME_PREV_DAY_SECONDARY_ACCESS_NUM_LEGEND_NAME]: legendSelectedRealtimePrevDaySecondaryAccess,
          [REALTIME_PREV_WEEK_LEGEND_NAME]: legendSelectedRealtimePrevWeek,
          [REALTIME_PREV_WEEK_SECONDARY_ACCESS_DEVICES_LEGEND_NAME]: legendSelectedRealtimePrevWeekSecondaryAccess,
          [REALTIME_PREV_WEEK_SECONDARY_ACCESS_NUM_LEGEND_NAME]: legendSelectedRealtimePrevWeekSecondaryAccess,
        },
        itemHeight: 7, // 圆点的大小
      },
      xAxis: xAxisList.map((x, i) => ({
        type: 'category',
        data: x,
        show: !isRealTime || i === 0,
        position: 'bottom',
        offset: i * 15,
        axisLine: {
          show: i === 0,
          lineStyle: {
            color: '#cccccc',
          },
        },
        splitLine: {
          show: false,
          lineStyle: {
            color: '#f0f0f0',
          },
        },
        axisTick: false,
        axisLabel: {
          formatter: (date) => {
            if (isRealTime) {
              return `${date}:00`;
            }
            return date;
          },
          interval: 'auto',
          textStyle: {
            color: isCompare ? LINE_COLOR[i] : '#999999',
          },
        },
      })),
      yAxis: [{
        name: yAxisHasName ? t(trendTagName) : undefined,
        nameTextStyle: {
          padding: [0, 0, 0, 4],
          align: 'left',
        },
        type: 'value',
        axisLabel: {
          formatter: datas.ySuffix ? `{value} ${datas.ySuffix}` : '{value}',
          textStyle: {
            color: '#999999',
          },
        },
        splitLine: {
          lineStyle: {
            color: '#f0f0f0',
          },
        },
        axisTick: false,
        min: 0,
      }, { // 第二个y轴给额外展示的联网设备数用的
        name: yAxisHasName ? t(secondaryAccessTagName) : undefined,
        type: 'value',
        nameTextStyle: {
          // 数据较少时，右侧留出padding防止遮挡
          padding: [0, secondaryAccessSeriesMaxValue < 100 ? 24 : 0, 0, 0]
        },
        axisLabel: {
          textStyle: {
            color: '#999999',
          },
        },
        splitLine: {
          lineStyle: {
            color: '#f0f0f0',
          },
        },
        axisTick: false,
        min: 0,
        minInterval: secondaryAccessSeriesMaxValue > 0 ? secondaryAccessSeriesMaxValue : 0,
        splitNumber: 1,
      }],
      series: datas.series,
    };

    return options;
  }

  /**
   *
   * @param {string[]} xAxis
   * @param {number} expectedLength
   */
  function extendDailyXAxis(xAxis, expectedLength) {
    if (xAxis.length >= expectedLength) {
      return xAxis;
    }
    const extraX = new Array(expectedLength - xAxis.length).fill('');
    return [...xAxis, ...extraX];
  }

  // 将01,02这种时间改成区间，01:00-02:00,02:00-03:00这样
  function formatDateTimeRange(hour) {
    const startHour = hour.padStart(2, '0');
    let endHour = '';

    if (hour === '23') {
      endHour = '23:59';
    } else {
      const nextHour = (parseInt(hour) + 1).toString().padStart(2, '0');
      endHour = `${nextHour}:00`;
    }

    return `${startHour}:00-${endHour}`;
  }

  function getChartData() {
    const { trendVersion, compareVersion, trendTagName: tagName } = searchParams.toJS();

    // isRealTime 最近一天24小时的实时数据
    if (isRealTime) {
      if (realTimeTrendDataJs.length < 1) {
        return false;
      }
    } else {
      if (trendData.size < 1) {
        return false;
      }
      if (isCompare && (!compareData || compareData.size < 1)) {
        return false;
      }
    }
    // 获取X轴标签
    let xAxis = [];
    let xAxisCompare = [];
    if (isRealTime) {
      [xAxis, xAxisCompare] = [realTimeTrendDataJs, realTimeCompareDataJs].map(x => uniq(x.map(item => item.date.substr(8))));
    } else {
      [xAxis, xAxisCompare] = [trendDataJs, compareDataJs].map(x => uniq(x.map((item) => {
        const date = getDateFromStr(item.date);
        return `${date.month}-${date.day}`;
      })));
    }

    const trendCountryListWithAll = trendCountryList.length > 0
      ? CountryUtil.convertPrefixedGroupIdToName(countryGroupConfigList, trendCountryList)
      : [t('EXCP_OVERVIEW.全部国家地区')];
    const compareCountryListWithAll = compareCountryList.length > 0
      ? CountryUtil.convertPrefixedGroupIdToName(countryGroupConfigList, compareCountryList)
      : [t('EXCP_OVERVIEW.全部国家地区')];

    let trendLegendList = [];
    if (isCountryDimensionMode) {
      trendLegendList = [...trendCountryListWithAll];
    } else if (isCompare) {
      trendLegendList = [parseInt(trendVersion) === -1 ? t('EXCP_OVERVIEW.allVersion') : decodeURIComponent(trendVersion)];
    } else {
      trendLegendList = [t(tagName)];
    }

    let cmpLegendList = [];
    if (isCompare) {
      cmpLegendList = isCountryDimensionMode
        ? [...compareCountryListWithAll]
        : [parseInt(compareVersion) === -1 ? t('EXCP_OVERVIEW.allVersion') : decodeURIComponent(compareVersion)];
      cmpLegendList = cmpLegendList.map(cmpLegend => (trendLegendList.includes(cmpLegend) ? `${cmpLegend}(${t('EXCP_OVERVIEW.vs')})` : cmpLegend));
    }
    const legend = [...trendLegendList, ...cmpLegendList];

    // 小时对比查询的时候，直接填充
    const isMultipleSeries = legendSelectedRealtimePrevDay
      || legendSelectedRealtimePrevWeek
      || legendSelectedRealtimePrevDaySecondaryAccess
      || legendSelectedRealtimePrevWeekSecondaryAccess
      || isCompare;
    if (isRealTime && isMultipleSeries) {
      xAxis = new Array(24).fill(0)
        .map((x, i) => String(i).padStart(2, '0'));
      xAxisCompare = new Array(24).fill(0)
        .map((x, i) => String(i).padStart(2, '0'));
    }
    // 对比查询的时候，两个xAxis要一样长度，不一样的话要补齐
    if (isCompare && !isRealTime && xAxis.length !== xAxisCompare.length) {
      const expectedLength = Math.max(xAxis.length, xAxisCompare.length);
      xAxis = extendDailyXAxis(xAxis, expectedLength);
      xAxisCompare = extendDailyXAxis(xAxisCompare, expectedLength);
    }

    const xAxisList = isCompare
      ? [xAxis, xAxisCompare]
      : [xAxis];

    const isPercentage = [
      'EXCP_OVERVIEW.userCrashRate',
      'EXCP_OVERVIEW.userANRRate',
      'EXCP_OVERVIEW.userErrorRate',
      'EXCP_OVERVIEW.sessionCrashRate',
      'EXCP_OVERVIEW.deviceOomRate',
      'EXCP_OVERVIEW.numOomRate',
      'EXCP_OVERVIEW.occurNumCrashRate',
    ].includes(tagName);

    const data = {
      legend,
      xAxis,
      xAxisList,
      ySuffix: isPercentage ? '%' : '',
    };

    const dataGroup = [];
    const cmpDataGroup = [];
    if (isRealTime) {
      makeCountryDimensionTrendListGroup(trendCountryList, realTimeTrendDataJs).forEach((trendList) => {
        dataGroup.push(getSeries(trendList, tagName, xAxis.length));
      });
      if (isCompare) {
        makeCountryDimensionTrendListGroup(compareCountryList, realTimeCompareDataJs).forEach((trendList) => {
          cmpDataGroup.push(getSeries(trendList, tagName, xAxisCompare.length));
        });
      }
    } else {
      makeCountryDimensionTrendListGroup(trendCountryList, trendDataJs).forEach((trendList) => {
        dataGroup.push(getSeries(trendList, tagName, xAxis.length));
      });
      if (isCompare) {
        makeCountryDimensionTrendListGroup(compareCountryList, compareDataJs).forEach((trendList) => {
          cmpDataGroup.push(getSeries(trendList, tagName, xAxisCompare.length));
        });
      }
    }

    let secondaryAccessTagName = 'EXCP_OVERVIEW.userNumConnect';
    let secondaryAccessPrevDayTagName = REALTIME_PREV_DAY_SECONDARY_ACCESS_DEVICES_LEGEND_NAME;
    let secondaryAccessPrevWeekTagName = REALTIME_PREV_WEEK_SECONDARY_ACCESS_DEVICES_LEGEND_NAME;
    if (tagName === 'EXCP_OVERVIEW.occurNumCrashRate'
      || tagName === 'EXCP_OVERVIEW.occurNumANRRate'
      || tagName === 'EXCP_OVERVIEW.occurNumErrorRate'
      || tagName === 'EXCP_OVERVIEW.occurNumber') {
      secondaryAccessTagName = 'EXCP_OVERVIEW.sessionCount';
      secondaryAccessPrevDayTagName = REALTIME_PREV_DAY_SECONDARY_ACCESS_NUM_LEGEND_NAME;
      secondaryAccessPrevWeekTagName = REALTIME_PREV_WEEK_SECONDARY_ACCESS_NUM_LEGEND_NAME;
    }

    const secondaryAccessSeriesNameList = [t(secondaryAccessTagName), secondaryAccessPrevDayTagName, secondaryAccessPrevWeekTagName];

    const secondaryAccessUseNewAxis = tagName !== 'EXCP_OVERVIEW.sessionCount';

    // 如果大盘选择的不是联网设备数，额外叠加显示一个当前联网设备或次数的线
    let secondaryAccessSeries = null;
    let secondaryAccessSeriesMaxValue = 0;
    if (tagName !== 'EXCP_OVERVIEW.userNumConnect' && !isCompare) {
      const seriesData = getSeries(isRealTime ? realTimeTrendDataJs : trendDataJs, secondaryAccessTagName, xAxis.length);
      secondaryAccessSeriesMaxValue = Math.max(...seriesData, secondaryAccessSeriesMaxValue);
      secondaryAccessSeries = {
        name: t(secondaryAccessTagName),
        type: 'line',
        yAxisIndex: secondaryAccessUseNewAxis ? 1 : 0,
        // smooth: true,
        data: seriesData,
        showSymbol: false,
        symbol: 'circle',
        symbolSize: 8,
        lineStyle: {
          normal: {
            // color: geekblue[3],
            // type: [5, 5],
            width: 1,
          },
        },
        itemStyle: {
          normal: {
            // color: geekblue[3],
          },
        },
      };
    }

    let realtimePrevDay = null;
    let secondaryAccessPrevDaySeries = null;
    let realtimePrevWeek = null;
    let secondaryAccessPrevWeekSeries = null;
    if (isProductSummaryPage && isRealTime && !isCompare && trendCountryList.length === 0) {
      realtimePrevDay = {
        name: REALTIME_PREV_DAY_LEGEND_NAME,
        type: 'line',
        xAxisIndex: 0,
        data: getSeries(realTimePrevDayTrendData, tagName, xAxis.length),
        symbol: 'circle',
        showSymbol: false,
        symbolSize: 8,
        lineStyle: {
          normal: {
            // color: orange.primary,
          },
        },
        itemStyle: {
          normal: {
            // color: orange.primary,
          },
        },
      };

      if (tagName !== 'EXCP_OVERVIEW.userNumConnect') {
        const secondaryAccessPrevDaySeriesData = getSeries(realTimePrevDayTrendData, secondaryAccessTagName, xAxis.length);
        secondaryAccessSeriesMaxValue = Math.max(...secondaryAccessPrevDaySeriesData, secondaryAccessSeriesMaxValue);
        secondaryAccessPrevDaySeries = {
          name: secondaryAccessPrevDayTagName,
          type: 'line',
          yAxisIndex: secondaryAccessUseNewAxis ? 1 : 0,
          // smooth: true,
          data: secondaryAccessPrevDaySeriesData,
          showSymbol: false,
          symbol: 'circle',
          symbolSize: 8,
          lineStyle: {
            normal: {
              // color: orange[3],
              type: [5, 5],
              width: 1,
            },
          },
          itemStyle: {
            normal: {
              // color: orange[3],
            },
          },
        };
      }

      realtimePrevWeek = {
        name: REALTIME_PREV_WEEK_LEGEND_NAME,
        type: 'line',
        xAxisIndex: 0,
        data: getSeries(realTimePrevWeekTrendData, tagName, xAxis.length),
        symbol: 'circle',
        showSymbol: false,
        symbolSize: 8,
        lineStyle: {
          normal: {
            // color: red.primary,
          },
        },
        itemStyle: {
          normal: {
            // color: red.primary,
          },
        },
      };

      if (tagName !== 'EXCP_OVERVIEW.userNumConnect') {
        const secondaryAccessPrevWeekSeriesData = getSeries(realTimePrevWeekTrendData, secondaryAccessTagName, xAxis.length);
        secondaryAccessSeriesMaxValue = Math.max(...secondaryAccessPrevWeekSeriesData, secondaryAccessSeriesMaxValue);
        secondaryAccessPrevWeekSeries = {
          name: secondaryAccessPrevWeekTagName,
          type: 'line',
          yAxisIndex: secondaryAccessUseNewAxis ? 1 : 0,
          // smooth: true,
          data: secondaryAccessPrevWeekSeriesData,
          showSymbol: false,
          symbol: 'circle',
          symbolSize: 8,
          lineStyle: {
            normal: {
              // color: red[3],
              type: [5, 5],
              width: 1,
            },
          },
          itemStyle: {
            normal: {
              // color: red[3],
            },
          },
        };
      }
    }
    data.series = [
      ...dataGroup.map((oneOfSeries, i) => ({
        name: trendLegendList[i],
        type: 'line',
        xAxisIndex: 0,
        data: oneOfSeries,
        // showSymbol: false,
        symbol: 'circle',
        symbolSize: 8,
        lineStyle: {
          normal: {
            // ...(!isCountryDimensionMode && { color: LINE_COLOR[0] }),
          },
        },
        itemStyle: {
          normal: {
            // ...(!isCountryDimensionMode && { color: LINE_COLOR[0] }),
          },
        },
      })),
      secondaryAccessSeries,
      realtimePrevDay,
      secondaryAccessPrevDaySeries,
      realtimePrevWeek,
      secondaryAccessPrevWeekSeries,
      ...cmpDataGroup.map((oneOfSeries, i) => ({
        name: cmpLegendList[i],
        type: 'line',
        xAxisIndex: 1,
        data: oneOfSeries,
        showSymbol: false,
        symbol: 'circle',
        symbolSize: 8,
        lineStyle: {
          normal: {
            // ...(!isCountryDimensionMode && { color: LINE_COLOR[1] }),
          },
        },
        itemStyle: {
          normal: {
            // ...(!isCountryDimensionMode && { color: LINE_COLOR[1] }),
          },
        },
      })),
    ].filter(x => x);

    return {
      data,
      secondaryAccessSeriesNameList,
      secondaryAccessSeriesMaxValue,
      secondaryAccessTagName,
    };
  }

  // 通过trendData或者compareData获取当前series
  function getSeries(data, tagName, fillUpLength) {
    let series = fillUpLength > 0 ? new Array(fillUpLength).fill(null) : [];
    if (!data || data.length === 0) {
      return series;
    }

    const reportNumField = isTrendDataBeforeGrayStrategyDrop ? 'reportNumAllData' : 'crashNum';
    const reportDeviceField = isTrendDataBeforeGrayStrategyDrop ? 'reportDeviceAllData' : 'crashUser';

    let isRateData = false;
    let numeratorField;
    let denominatorField;
    switch (tagName) {
      case 'EXCP_OVERVIEW.occurNumANRRate':
      case 'EXCP_OVERVIEW.occurNumErrorRate': {
        series = data.map((item) => {
          const { accessNum } = item;
          if (accessNum <= 0) {
            return 0;
          }
          const avgCount = item[reportNumField] / accessNum;
          if (avgCount <= 0) {
            return avgCount;
          } if (avgCount >= 1) {
            return avgCount.toFixed(2);
          } if (avgCount >= 0.01) {
            return avgCount.toFixed(4);
          }
          return avgCount.toPrecision(2);
        });
        break;
      }
      case 'EXCP_OVERVIEW.numOomRate':
      case 'EXCP_OVERVIEW.occurNumCrashRate': {
        isRateData = true;
        numeratorField = reportNumField;
        denominatorField = 'accessNum';
        break;
      }
      case 'EXCP_OVERVIEW.userCrashRate':
      case 'EXCP_OVERVIEW.userANRRate':
      case 'EXCP_OVERVIEW.userErrorRate':
      case 'EXCP_OVERVIEW.deviceOomRate': {
        isRateData = true;
        numeratorField = reportDeviceField;
        denominatorField = 'accessUser';
        break;
      }
      case 'EXCP_OVERVIEW.sessionCrashRate': {
        isRateData = true;
        numeratorField = 'crashSession';
        denominatorField = 'accessNum';
        break;
      }
      case 'EXCP_OVERVIEW.occurNumber':
        series = data.map(item => Math.max(item[reportNumField], 0));
        break;
      case 'EXCP_OVERVIEW.sessionNumAffect': {
        series = data.map(item => Math.max(item.crashSession || 0, 0));
        break;
      }
      case 'EXCP_OVERVIEW.userNumAffect':
        series = data.map(item => Math.max(item[reportDeviceField], 0));
        break;
      case 'EXCP_OVERVIEW.userNumConnect':
        series = data.map(item => Math.max(item.accessUser, 0));
        break;
      case 'EXCP_OVERVIEW.sessionCount': {
        series = data.map(item => Math.max(item.accessNum, 0));
        break;
      }
      default:
    }

    if (isRateData) {
      series = data.map(item => ((item[numeratorField] > 0 && item[denominatorField] > 0)
        ? (100 * item[numeratorField] / item[denominatorField])
        : 0));
      const maxPercentage = Math.max(...series);
      const fractionDigits = (maxPercentage > 0 && maxPercentage < 0.1) ? 4 : 2;
      series = series.map(x => x.toFixed(fractionDigits));
    }

    let res = series;
    if (res.length < fillUpLength) {
      res = [...res, ...new Array(fillUpLength - res.length).fill(null)];
    }
    return res;
  }

  const onEvents = useMemo(() => ({
    click: handleClickChart,
    mouseover: handleShowClickTip,
    mouseout: handleHideClickTip,
    legendselectchanged: (e) => {
      const { name, selected } = e;
      if (!handleChangeSearchParam) {
        return;
      }
      if (name === REALTIME_PREV_DAY_LEGEND_NAME) {
        setLegendSelectedRealtimePrevDay(selected[name]);
        if (!legendSelectedRealtimePrevDay && !legendSelectedRealtimePrevDaySecondaryAccess) {
          handleChangeSearchParam('enableRealtimePrevDayTrend', true);
        } else if (!selected[name]) {
          handleChangeSearchParam('enableRealtimePrevDayTrend', false);
        }
      } else if (name === REALTIME_PREV_DAY_SECONDARY_ACCESS_DEVICES_LEGEND_NAME || name === REALTIME_PREV_DAY_SECONDARY_ACCESS_NUM_LEGEND_NAME) {
        setLegendSelectedRealtimePrevDaySecondaryAccess(selected[name]);
        if (!legendSelectedRealtimePrevDay && !legendSelectedRealtimePrevDaySecondaryAccess) {
          handleChangeSearchParam('enableRealtimePrevDayTrend', true);
        } else if (!selected[name]) {
          handleChangeSearchParam('enableRealtimePrevDayTrend', false);
        }
      } else if (name === REALTIME_PREV_WEEK_LEGEND_NAME) {
        setLegendSelectedRealtimePrevWeek(selected[name]);
        if (!legendSelectedRealtimePrevWeek && !legendSelectedRealtimePrevWeekSecondaryAccess) {
          handleChangeSearchParam('enableRealtimePrevWeekTrend', true);
        } else if (!selected[name]) {
          handleChangeSearchParam('enableRealtimePrevWeekTrend', false);
        }
      } else if (name === REALTIME_PREV_WEEK_SECONDARY_ACCESS_DEVICES_LEGEND_NAME || name === REALTIME_PREV_WEEK_SECONDARY_ACCESS_NUM_LEGEND_NAME) {
        setLegendSelectedRealtimePrevWeekSecondaryAccess(selected[name]);
        if (!legendSelectedRealtimePrevWeek && !legendSelectedRealtimePrevWeekSecondaryAccess) {
          handleChangeSearchParam('enableRealtimePrevWeekTrend', true);
        } else if (!selected[name]) {
          handleChangeSearchParam('enableRealtimePrevWeekTrend', false);
        }
      }
    },
  }), []);

  const opt = getChartOptions();
  return !isEmpty(opt)
    ? <div>
        <ErrorBoundary fallback={<div style={{ color: 'red' }}>崩溃率图表渲染发生异常，请刷新页面重试。如仍有问题请联系管理员。</div>}>
          <ReactECharts
            notMerge={true}
            option={opt}
            onEvents={onEvents}
          />
        </ErrorBoundary>
      </div>
    : <EmptyData text={t('RANKINGLIST.noData')} emptyImgVisiable={false} extraStyle={{paddingTop: 55}} />;
};

TrendChart.propTypes = {
  searchParams: PropTypes.object,
  compareData: PropTypes.object,
  realTimeCompareData: PropTypes.object,
  trendData: PropTypes.object,
  realTimeTrendData: PropTypes.object,
  isRegionalDashboard: PropTypes.bool,
  handleClickChart: PropTypes.func,
  handleShowClickTip: PropTypes.func,
  handleHideClickTip: PropTypes.func,
  isTrendDataBeforeGrayStrategyDrop: PropTypes.bool, // true: 灰名单丢弃前的趋势数据 false: 灰名单丢弃后趋势数据
};

const mapStateToProps = state => ({
  reduxState: state,
});

export default connect(mapStateToProps)(TrendChart);
