import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import CrashRest from 'utils/CrashRest';
import { getExceptionTypeList, parseSearch } from 'utils/helper';
import { DATE_OPTIONS } from 'utils/constants';
import { makeCrashListSearchParamsFromSearchParams } from 'utils/api/crash';
import IssueCrashSearchUtil from 'utils/IssueCrashSearchUtil';
// 注意: babel问题, 更新了actions.js后, CRASHES_LIST_SUCC为undefined
const { LOCATION_CHANGE, CRASHES_LIST_SUCC, IS_SHOW_CRASHITEM } = require('store/actions');

const TAG = '[crash.js]-';

// 下拉框的参数
const initialSelectOptions = {
  version: {
    options: [
      { label: '全版本', value: 'all' },
    ],
  },
  date: DATE_OPTIONS,
  tagList: {
    options: [],
  },
};

// 搜索的参数
const initialSearchParams = {
  searchType: 'detail',
  search: '',
  start: 0,
  version: 'all',
  issueId: '',
  date: 'all',
  tagList: '',
  startDateStr: '',
  endDateStr: '',
  bundleId: '',
  channelId: '',
  rows: 10,
};

// 初始化crash列表页的state
const initialCrashState = fromJS({
  searchParams: initialSearchParams,
  selectOptions: initialSelectOptions,
  crashIdList: [],
  crashDatas: {},
  numFound: 0,
  current: {},
});


/**
 * 获取crash列表
 */
export function getCrashList(issueId, crashDataType) {
  return (dispatch, getState) => {
    const { crash, app } = getState();
    const { appId, pid } = app.get('current').toJS();
    const params = makeCrashListSearchParamsFromSearchParams(crash.get('searchParams'));
    return dispatch({
      rest: CrashRest.crashes.list,
      data: params.merge({
        appId,
        platformId: pid,
        issueId,
        crashDataType,
        rows: getState().global.get('rows'),
      }).toJS(),
    });
  };
}

/**
 * 是否匹配更多上报记录页面的路由
 */
function isMatchReportRoute(pathname) {
  return pathname && pathname.match(/(?:crashes|anr|errors|blocks)\/\w+\/\w+\/report/i);
}

export default handleActions({

  /**
   * 奔溃列表加载成功
   */
  [CRASHES_LIST_SUCC]: (state, action) => {
    console.log(`${TAG} CRASHES_LIST_SUCC handled! `);
    const { numFound, crashIdList, crashDatas } = action.response;
    const rows = state.getIn(['searchParams', 'rows']);
    const totalPages = Math.ceil(numFound / rows);
    return state.set('crashIdList', crashIdList)
      .set('crashDatas', fromJS(crashDatas))
      .set('numFound', numFound)
      .set('totalPages', totalPages);
  },

  [IS_SHOW_CRASHITEM]: (state, action) => {
    return state.set('showCrashItemFlag', action.value);
  },

  [LOCATION_CHANGE]: (state, action) => {
    const { location } = action.payload;
    const { pathname } = location;
    const query = IssueCrashSearchUtil.urlQueryObjToSearchParamsObj(parseSearch(location));
    if (isMatchReportRoute(pathname)) {
      return state.update('searchParams', (/* searchParams */) => {
        return fromJS(Object.assign({}, initialSearchParams, query));
      });
    }
    return state;
  },
}, initialCrashState);
