import RestHelper from './RestHelper';

const UserDataRest = RestHelper.publish({
  getRetent: {
    get: {
      method: 'get',
      uri: '/appId/{appId}/pid/{pid}/version/{version}/startDate/{startDate}/endDate/{endDate}/dateScope/{dateScope}/retentTrend',
    },
  },
  getStartupCountTrend: {
    get: {
      method: 'get',
      uri: '/appId/{appId}/pid/{pid}/version/{version}/channel/{channel}/date/{date}/startupCountTrend',
    },
  },
  getUseTimeTrend: {
    get: {
      method: 'get',
      uri: '/appId/{appId}/pid/{pid}/version/{version}/channel/{channel}/date/{date}/useTimeTrend',
    },
  },
  getDayUseTimeTrend: {
    get: {
      method: 'get',
      uri: '/appId/{appId}/pid/{pid}/version/{version}/channel/{channel}/date/{date}/dayUseTimeTrend',
    },
  },
});

export default UserDataRest;
