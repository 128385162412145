import React, { useState, useEffect, useMemo } from 'react';
import RestHelper from 'utils/RestHelper';
import {
  Form,
  Modal,
  Input,
  Select,
  Row,
  Col,
  Spin,
  Button,
  notification,
  Upload,
  Divider,
  Table,
  message,
  Tooltip,
  Tag,
  Popconfirm,
  Space,
} from 'antd';
import { connect, useSelector } from 'react-redux';
import style from 'containers/NintendoSwitchConfigPage/style.scss';
import { useTranslation } from "react-i18next";
import { useImmer } from 'use-immer';
import { countryI18n } from 'utils/country';
import { useZhEn } from 'utils/react-hooks/useZhEn';
import { UserTypeEnum } from 'utils/constants/user-type-enum';
import { selectHasSpecifiedPermissionInCurrentApp } from 'utils/selectors/selectors';
import { COUNTRY_LIST_OPTIONS, COUNTRY_WITH_FLAG_LIST_OPTIONS } from 'components/exception/ProductSummaryPage/CountrySelect';
import SaveIcon from 'svg/v2/newcs_dashboard_overview_save_icon.svg';
import ProjectdeleteIcon from 'svg/v2/newcs_dashboard_projectinformation_projectdelete_icon.svg';
import EditIcon from 'svg/v2/newcs_dashboard_setting_alertsetting_customalert_operate_edit_icon_normal.svg';
import { CsSelect } from 'components/antd-extension/CsSelect';
import NoticeIcon from 'svg/v2/newcs_dashboard_notice_icon.svg';

const CountryGroupConfigCore = ({ reduxState }) => {
  const { t } = useTranslation();
  const { ze } = useZhEn();
  const currentApp = reduxState.app.get('current').toJS();
  const { appId } = currentApp;
  const currentUser = reduxState.user.get('current').toJS();
  const visible = reduxState.summary.get('countryGroupConfigModalVisible');

  const [configList, updateConfigList] = useImmer([]);
  const [loading, setLoading] = useImmer(false);
  const [pageNumber, setPageNumber] = useState(1);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const [currentEditConfig, setCurrentEditConfig] = useImmer({
    id: '',
    countryGroup: '',
  });

  const hasEditPermission = useSelector(state => selectHasSpecifiedPermissionInCurrentApp(state, 'EDIT'));

  function updateConfig(id, updateObj) {
    updateConfigList((draft) => {
      const i = draft.findIndex(x => x.id === id);
      if (i >= 0) {
        draft[i] = {
          ...draft[i],
          ...updateObj,
        };
      }
    });
  }

  function addNewConfig() {
    setPageNumber(1);
    const id = -new Date(); // 用负的id标记新增项
    const newConfig = {
      id,
      key: id,
      countryGroup: '',
      countries: [],
      not: 0,
      editing: true,
    };
    updateConfigList([newConfig, ...configList]);
  }

  // 每次打开时重新拉去一次国家地区数据，用于清除原有未操作数据
  useEffect(() => {
    if (visible) {
      listCountryGroup();
    }
  }, [visible]);

  async function listCountryGroup() {
    setLoading(true);
    const rsp = await RestHelper.mustPost('/api/app/listCountryGroup', { appId });
    const configList = rsp.json.data.map(x => ({
      ...x,
      key: x.id,
    })).sort((a, b) => b.id - a.id);
    updateConfigList(configList);
    setLoading(false);
  }

  async function upsertCountryGroup(config) {
    const { id, countryGroup, countries, not } = config;
    if (!countryGroup) {
      message.error(ze('自定义地区名不能为空', 'Custom area name must be not empty'));
      return;
    }
    if (countries.length === 0) {
      message.error(ze('包含的国家不能为空', 'Containing countries/regions must be not empty'));
      return;
    }
    setLoading(true);
    const postData = {
      appId,
      countryGroup,
      countries,
      not,
      id: config.id > 0 ? config.id : 0,
    };
    try {
      await RestHelper.mustPost('/api/app/upsertCountryGroup', postData, {
        silentErrorCodes: ['CountryGroupExists'],
      });
      if (id > 0) {
        updateConfig(id, {editing: false});
      } else {
        await listCountryGroup();
      }
    } catch (e) {
      if (e.errorCode === 'CountryGroupExists') {
        message.error(ze('保存失败，存在同名的自定义地区，请修改地区名。', 'Cannot have duplicate area names.'));
      }
    }
    setLoading(false);
  }

  async function deleteCountryGroup(id) {
    setLoading(true);
    updateConfigList(draft => draft.filter(x => x.id !== id));
    if (id > 0) {
      await RestHelper.mustPost('/api/app/deleteCountryGroup', {id, appId});
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!appId) {
      return;
    }
    listCountryGroup();
  }, [appId]);

  const tableColumns = [{
    title: ze('自定义地区', 'Custom Area'),
    key: 'countryGroup',
    width: '250px',
    render: (text, record) => {
      const { id, countryGroup, editing } = record;
      // if (!editing) {
      //   return <div>{ countryGroup }</div>;
      // }
      return <Input
        value={countryGroup}
        placeholder={ze('自定义名称', 'Custom Area Name')}
        onChange={(e) => {
          updateConfig(id, { countryGroup: e.target.value.trim() });
        }}
      />;
    },
  }, {
    title: ze('包含/不包含', 'Contains / Not Contains'),
    key: 'not',
    width: '150px',
    render: (text, record) => {
      const { id, not } = record;
      return <CsSelect
        value={not || 0}
        style={{ width: '100%' }}
        options={[
          {
            value: 0,
            label: ze('包含', 'Contains'),
          },
          {
            value: 1,
            label: ze('不包含', 'Not Contains'),
          }]}
        onChange={(v) => {
          updateConfig(id, { not: v });
        }}
      />
    },
  }, {
    title: ze('国家/地区', 'Countries / Regions'),
    key: 'countries',
    width: '400px',
    render: (text, record) => {
      const { id, countries, editing } = record;
      return <CsSelect
        style={{ width: '100%' }}
        mode='multiple'
        options={COUNTRY_WITH_FLAG_LIST_OPTIONS}
        showSearch={true}
        value={countries}
        optionFilterProp='rawLabel'
        placeholder={ze('选择国家/地区', 'Select Country/Region')}
        onChange={(v) => {
          updateConfig(id, { countries: v });
        }}
      />
    },
  }, {
    title: ze('操作', 'Operation'),
    key: 'op',
    width: '138px',
    align: 'center',
    render: (text, record) => {
      if (!hasEditPermission) {
        return <div/>;
      }
      const { id, countryGroup, editing } = record;
      return <Space size={12} align="center" split={<Divider type="vertical"  style={{margin: 0}}/>}>
        <div style={{ textAlign: 'center', display: 'flex', alignItems: 'center', cursor: 'pointer'}}  onClick={() => {
            upsertCountryGroup(record);
          }}>
          {/* { ze('保存', 'Save') } */}
          {<SaveIcon />}
        </div>

        <div style={{textAlign: 'center', display: 'flex', alignItems: 'center', cursor: 'pointer'}} onClick={()=>{
            setCurrentEditConfig({
              id: id,
              countryGroup: countryGroup,
            })
            setIsDeleteModalVisible(true);
          }}>
          {/* { ze('删除', 'Delete') } */}
          {<ProjectdeleteIcon style={{color:'#807997'}} />}
        </div>
      </Space>;
    },
  }];

  return <div className={style.tag_mgr}>
    <div className={`clearfix ${style.tag_mgr_list}`}>
      <Spin spinning={loading}>
        { !hasEditPermission && <div>{ ze('仅项目管理员可进行编辑操作。', 'Only admins can edit the config.')}</div>}
        { hasEditPermission && <div style={{marginBottom: '24px'}}>
          <Button type='primary' style={{padding: '0px 16px'}} onClick={() => addNewConfig()}>{ ze('新增自定义地区', 'Add New Custom Area') }</Button>
        </div> }
        <Table
          size='small'
          columns={tableColumns}
          dataSource={configList}
          bordered={false}
          pagination={{
            current: pageNumber,
            showSizeChanger: false,
            pageSize: 10,
            hideOnSinglePage: true,
            onChange(page, pageSize) {
              setPageNumber(page);
            },
          }}
        />
        <Modal
          title={ze('删除地区', 'Delete Area')}
          visible={isDeleteModalVisible}
          onCancel={() => setIsDeleteModalVisible(false)}
          onOk={() => {
            deleteCountryGroup(currentEditConfig.id);
            setIsDeleteModalVisible(false);
          }}
        >
          <div style={{display:'flex',alignItems:'center',gap:'4px',marginBottom:'50px'}}>
            <div style={{transform: 'rotate(180deg)',marginTop:'-5px'}}><NoticeIcon /></div>
            <div>{ze(`确定要删除 ${currentEditConfig.countryGroup} 吗？`, `Are you sure to delete ${currentEditConfig.countryGroup} ?`) }</div>
          </div>
        </Modal>
      </Spin>
    </div>
  </div>;
};

const mapStateToProps = state => ({
  reduxState: state,
});

export default connect(mapStateToProps)(CountryGroupConfigCore);
