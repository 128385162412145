/**
 * 和平台有关的辅助函数
 * @author wenqingwu
 */

import { PLATFORM_ID } from 'utils/constants';
import { ExceptionCategoryUtil } from 'utils/exception-category';
import { isNullish } from 'utils/nullish';
import { message } from 'antd';
import { ze } from 'utils/zhEn';

const PLATFORM_ID_TO_NAME = Object.freeze({
  [PLATFORM_ID.ANDROID]: 'Android',
  [PLATFORM_ID.IOS]: 'iOS',
  [PLATFORM_ID.MAC]: 'Mac',
  [PLATFORM_ID.PC]: 'PC',
  [PLATFORM_ID.LINUX]: 'Linux',
  [PLATFORM_ID.SWITCH]: 'Switch',
  [PLATFORM_ID.PS4]: 'PS4/PS5',
  [PLATFORM_ID.XBOX]: 'Xbox',
  [PLATFORM_ID.WECHAT_MINI_PROGRAM]: ze('微信小程序', 'WeChat Mini Program'),
});

/**
 * 判断给定的platform id是否是主机
 */
export function isGameConsole(pid) {
  return [PLATFORM_ID.PS4, PLATFORM_ID.SWITCH, PLATFORM_ID.XBOX].includes(Number(pid));
}

/**
 * 判断给定的platform id是否是NINTENDO SWITCH
 */
export function isSwitch(pid) {
  return Number(pid) === PLATFORM_ID.SWITCH;
}

/**
 * 判断给定的platform id是否是PS4
 */
export function isPlaystation(pid) {
  return Number(pid) === PLATFORM_ID.PS4;
}

/**
 * 判断给定的platform id是否是XBOX
 */
export function isXbox(pid) {
  return Number(pid) === PLATFORM_ID.XBOX;
}

/**
 * 判断给定的platform id是否是PC、linux或XBOX。
 * 2024-11-08：XBOX和PC共用SDK和处理链，也算作PC
 */
export function isPcOrLinux(pid) {
  return Number(pid) === PLATFORM_ID.PC
    || Number(pid) === PLATFORM_ID.LINUX
    || Number(pid) === PLATFORM_ID.XBOX;
}

/**
 * 判断给定的platform id是否是移动端
 */
export function isMobile(pid) {
  return isAndroidOrHarmony(pid) || isIos(pid);
}

/**
 * 判断给定的platform id是否是主机或PC
 */
export function isPcOrGameConsole(pid) {
  return isPcOrLinux(pid) || isGameConsole(pid);
}

export function isWechatMiniProgram(pid) {
  return Number(pid) === PLATFORM_ID.WECHAT_MINI_PROGRAM;
}

export function isAndroid(pid) {
  return Number(pid) === PLATFORM_ID.ANDROID;
}

export function isHarmony(pid) {
  return Number(pid) === PLATFORM_ID.HARMONY;
}

export function isAndroidOrHarmony(pid) {
  return isAndroid(pid) || isHarmony(pid);
}

/**
 * 判断给定的platform id是否是iOS
 */
export function isIos(pid) {
  return Number(pid) === PLATFORM_ID.IOS;
}

export function isMac(pid) {
  return Number(pid) === PLATFORM_ID.MAC;
}

// 线上使用了PC的pid，但是实际上是linux项目的app id set
const LINUX_USE_PC_PID_APP_ID_SET = new Set([
  // 国内
  '104d25117a', '2773a2f57c', 'e064a315c2', '6dcaa20873', '9b20d7d7e9', 'b418090ecd', 'dc0ed7ed31', '6af9f87b4c',
  // 海外
  '6af9f87b4c', '47a2abd377', '97873105ae', 'f213379a6c',
]);

/**
 * 判断给定的platform id是否是PC。
 * 目前线上部分pid是PC的项目，实际上是linux项目，如果需要区分这种项目，可以额外传入appId判断
 */
export function isPc(pid, appId = '') {
  return Number(pid) === PLATFORM_ID.PC
    && !(appId && LINUX_USE_PC_PID_APP_ID_SET.has(appId));
}

/**
 * 判断给定的platform id是否是Linux。
 * 目前线上部分pid是PC的项目，实际上是linux项目，如果需要区分这种项目，可以额外传入appId判断
 */
export function isLinux(pid, appId = '') {
  return Number(pid) === PLATFORM_ID.LINUX
    || (appId && LINUX_USE_PC_PID_APP_ID_SET.has(appId));
}

export const PlatformUtil = Object.freeze({
  // 将平台id转为名称
  toName(pid) {
    return PLATFORM_ID_TO_NAME[pid] || pid;
  },

  is(pid, platformIdEnum) {
    return Number(pid) === Number(platformIdEnum);
  },

  /** 是否是移动端 或者 功能对齐移动端的其他端（Mac） */
  isMobileOrMobileLike(pid) {
    return isMobile(pid) || isMac(pid);
  },

  isMac(pid) {
    return Number(pid) === PLATFORM_ID.MAC;
  },

  isIosOrMac(pid) {
    return isIos(pid) || isMac(pid);
  },
  /**
   * 指定的platform id是否有联网数据
   */
  hasAccessStats(pid) {
    return true;
  },

  hasCrashAttachment(pid, exceptionType, currentIssue) {
    const isGameConsoleError = isGameConsole(pid) && ExceptionCategoryUtil.isError(exceptionType);
    if (!isPcOrLinux(pid)) {
      return isMobile(pid) || isMac(pid) || isPlaystation(pid) || isGameConsoleError;
    }
    // PC要特殊处理，为兼容旧数据要看crashMap里面有没有bucketPath
    if (isNullish(currentIssue)) {
      message.warn('check hasCrashAttachment, issue is null!');
      return false;
    }
    if (typeof currentIssue.toJS === 'function') {
      currentIssue = currentIssue.toJS();
    }
    const { crashDoc } = currentIssue;
    const { crashMap } = crashDoc || {};
    const { bucketPath } = crashMap || {};
    return !!bucketPath;
  },

  /**
   * 指定的platform id是否支持崩溃功能
   */
  isCrashAvailable(pid) {
    return !isWechatMiniProgram(pid);
  },

  /**
   * 指定的platform id是否支持卡顿功能
   */
  isAnrAvailable(pid) {
    return isAndroidOrHarmony(pid);
  },

  /**
   * 指定的platform id是否支持错误功能
   */
  isErrorAvailable(pid) {
    return true;
  },

  /**
   * 指定的platform id是否支持OOM功能
   */
  isOomAvailable(pid) {
    return isMobile(pid);
  },

  isSystemLogEnabled(pid) {
    return this.isMobileOrMobileLike(pid);
  },

  /** 系统、自定义日志是否支持过滤日志级别 */
  isLogLevelEnabled(pid, logType) {
    return !(this.isIosOrMac(pid) && logType === 'sysLog');
  },
});
