import isString from 'lodash/lang/isString';
import { isNullish } from 'utils/nullish';

/**
 * @author wenqingwu
 * 和后台java代码里的ConvertUtil基本一样
 */
export const ConvertUtil = (function() {


  return Object.freeze({
    /**
     * 将多个值通过逗号拼起来的字符串拆分成list。空值会被去除。list中每个值会去除头尾空白字符。 如果输入为nullish，返回empty数组。
     */
    splitAndTrimCommaSeparatedValues(s) {
      return this.splitAndTrimDelimiterSeparatedValues(s, ',');
    },

    /**
     * 将多个值通过指定分隔符拼起来的字符串拆分成list。空值会被去除。list中每个值会去除头尾空白字符。 如果输入为nullish，返回empty数组。
     * @throws { TypeError } 当delimiter非字符串或者为空时抛出异常
     */
    splitAndTrimDelimiterSeparatedValues(s, delimiter) {
      if (!isString(delimiter)) {
        throw TypeError('delimiter is not string! given delimiter = ', delimiter);
      }
      if (isNullish(s)) {
        return [];
      }
      return String(s).split(delimiter).map(x => x.trim()).filter(x => x);
    }
  });
})();
