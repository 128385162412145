import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {ExceptionCategory, ExceptionCategoryUtil} from 'utils/exception-category';
import {Button, message, Modal, notification, Spin} from 'antd';
import { ze } from 'utils/zhEn';
import RestHelper from 'utils/RestHelper';
import { sleep } from 'utils/sleep';
import {QueryType, FieldName} from "components/commons/IssueCrashFilter/IssueCrashFilterExUtil";
import {isAndroidOrHarmony} from "utils/platform";
import { CS_STYLES } from 'utils/constants/style-constants';
import scss from './SendErrorBox.scss';

const SendErrorBox = ({ reduxState, exceptionType, refreshFunc }) => {
  const currentApp = reduxState.app.get('current').toJS();
  const { appId, pid } = currentApp;
  const [sending, setSending] = useState(false);
  const successModal = (name) => {
    Modal.success({
      content: `${name}${ze('数据发送成功。', 'data has been sent successfully.')}`,
    });
  };

  async function fetchCrashList(expUid, exceptionType) {
    let rsp;
    // OOM用高级搜索查询有问题，需要单独用这个接口查
    if(ExceptionCategoryUtil.unify(exceptionType) === ExceptionCategory.OOM){
      rsp = await RestHelper.post('/api/crash/queryOomMmapCrashes', {
        appId,
        platformId: pid,
        offset: 0,
        limit: 10,
        sortField: null,
        desc: true,
        rawSearch: {
          expUid,
        },
        aggFields: [
          "version",
          "osVer",
          "model",
          "elapsedTime",
          isAndroidOrHarmony(pid) ? 'aosTotalMemoryBytes' : 'iosTotalMemoryBytes',
          "userSceneTag"
        ]
      })
      const { total } = rsp.json.data || {};
      return total > 0;
    }else{
      rsp = await RestHelper.post('/api/advancedSearch', {
        start: '0',
        sortField: 'imeiCount',
        desc: true,
        pid,
        searchConditionGroup: {
          conditions: [
            {
              queryType: QueryType.TERM,
              term: expUid,
              field: FieldName.expUid
            }
          ]
        },
        appId,
        platformId: pid,
        rows: 10,
      })
      const { numFound } = rsp.json.data || {};
      return numFound > 0;
    }
  }

  async function sendException() {
    const rsp = await RestHelper.mustPost('/redir/api/mock/send', {
      appId,
      exceptionCategory: ExceptionCategoryUtil.unify(exceptionType),
      platformId: pid,
    })
    const data = rsp.json.data;
    if (data) {
      setSending(true);
      message.success(ze('已开始发送', 'Start sending.'));
      // 轮询crashList，判断是否发送成功
      const INTERVAL_MS = 10000;
      const MAX_POOLING_TRY = 30;
      for (let i = 0; i < MAX_POOLING_TRY; i++) {
        await sleep(INTERVAL_MS);
        const success = await fetchCrashList(data.expUid, exceptionType);
        if (success) {
          setSending(false);
          successModal(ExceptionCategoryUtil.toI18n(exceptionType, pid))
          refreshFunc();
          return;
        }
      }
      notification.error({
        message: ze('处理超时，请联系管理员。', 'Processing timed out, please contact the administrator.'),
        duration: 0,
      });
    } else {
      notification.error({ message: ze(`发送失败，请联系管理员。${rsp.json.message}`, `Sending failed, please contact the administrator.${rsp.json.message}`) });
    }
    setSending(false);
  }

  return (
    <div style={{
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <Spin spinning={sending} size='large' tip={ ze(`消息处理中，正常在2分钟内处理完成。在消息处理高峰期，可能存在处理延迟，请稍后手动刷新页面查看消息。`, 'Message processing is normally completed within 2 minutes. During the peak period of message processing, there may be processing delays. Please manually refresh the page later to view messages.') }>
        { sending && <div style={{ width: '500px' }}></div> }
        {!sending && <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <div className={scss.errorTitle}>{ze('暂未发现异常问题……', 'No unusual problems have been found yet...')}</div>
          <div className={scss.errorTips}>{ze('CrashSight支持发送异常', 'CrashSight supports sending exceptions')}</div>
          <Button type='primary' style={{ marginBottom: 24 }} disabled={sending} onClick={sendException}>{ze('发送', 'Send')}{ExceptionCategoryUtil.toI18n(exceptionType, pid)}</Button>
          <Link className={scss.guideLink} to={`/crash-reporting/tutorial/${appId}?pid=${pid}`}>{ze('进入接入引导', 'Enter the Access Guide.')}</Link>
          {/* <div style={{ display: 'flex', width: '250px', justifyContent: 'space-between' }}>
            <Link to={`/crash-reporting/online-send-crash/${appId}?pid=${pid}`}>{ze('进入效率工具-模拟发包', 'Go to Efficiency Tools - Simulate Sending Contracts')}</Link>
            <Link to={`/crash-reporting/tutorial/${appId}?pid=${pid}`}>{ze('进入接入引导', 'Enter the Access Guide.')}</Link>
          </div> */}
        </div>}
      </Spin>
    </div>
  );
};

SendErrorBox.propTypes = {
  reduxState: PropTypes.object,
  exceptionType: PropTypes.string,
};

const mapStateToProps = state => ({
  reduxState: state,
});

export default connect(mapStateToProps)(SendErrorBox);
