import React, {useState, useRef, useEffect} from 'react';
import { TreeSelect } from 'antd';
import { useHover } from 'ahooks';
import scss from './CsTreeSelect.scss';
import { CloseOutlined } from '@ant-design/icons';

export const CsTreeSelect = (props = {}) => {
  const selectRef = useRef(null);
  const isHovering = useHover(selectRef);
  const [isHoverSelectFocused, setIsHoverSelectFocused] = useState(false);

  // 是否多选
  const isMultiSelect = !!props?.multiple || !!props?.treeCheckable;

  // 传入的部分属性
  const originStyle = props?.style || {};
  const originOnFocus = props?.onFocus;
  const originOnBlur = props?.onBlur;

  const maxTagPlaceholder = (nodes = []) => `+${nodes.length}`

  const hoverSelectOnFocus = () => {
    setIsHoverSelectFocused(true);
    typeof originOnFocus === 'function' && originOnFocus();
  }
  const hoverSelectOnBlur = () => {
    setIsHoverSelectFocused(false);
    typeof originOnBlur === 'function' && originOnBlur();
  }

  const commonProps = isMultiSelect ? {...props, maxTagCount: 'responsive', maxTagPlaceholder} : {...props};
  // hover Select需要重写属性
  const hoverProps = isMultiSelect ? {...props, maxTagCount: undefined, onFocus: hoverSelectOnFocus, onBlur: hoverSelectOnBlur } : {...props};

  return <div ref={selectRef} style={{...originStyle, display: 'inline-block', position: 'relative' }}>
    <TreeSelect {...commonProps} style={{width: '100%', zIndex: '100'}} clearIcon={<CloseOutlined />}>
    </TreeSelect>
    {
      isMultiSelect && (isHoverSelectFocused || isHovering) &&
        <TreeSelect {...hoverProps} 
          className={scss.cs_tree_select}
          style={{ position: 'absolute', top: '0px', left: '0px', width: '100%',zIndex: '101' }}
          clearIcon={<CloseOutlined />}  
        >
        </TreeSelect>
    }
  </div>;
}