import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push as pushState, replace as replaceState } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import * as symbolActions from 'reducers/symbol';
import { SYMBOL_TIPS, IOS_SYMBOL_TOOL, ANDROID_SYMBOL_TOOL } from 'utils/constants';
import _style from './style.scss';
import { withTranslation } from 'react-i18next';
import { isAndroidOrHarmony, isIos, isMobile, isPcOrLinux, isPlaystation, PlatformUtil } from 'utils/platform';
import { EXCEPTION_TYPE_INT_ANDROID_JAVA } from 'utils/constants/exception-type-int';
import isEqual from 'lodash/lang/isEqual';
import { is } from 'immutable';
import { Button, Popconfirm, Space, Table, Tooltip } from 'antd';
import { isNotNullish } from 'utils/nullish';
import { ze } from 'utils/zhEn';
import { CS_STYLES } from 'utils/constants/style-constants';
import { orange, red } from '@ant-design/colors';
import { DataDetailUtil } from 'components/exception/issue/DataDetail';
import { TimeZoneUtil } from 'utils/time-zone-util';
import { getBackendInjectEnvs } from 'utils/backend-inject-envs';
import { BillingTeamType } from 'utils/constants/billing';
import { DocsUtil } from 'utils/docs-util';
import CsDownloadButton from 'components/commons/CsDownloadButton/CsDownloadButton';
import IconRight from 'svg/v2/newcs_dashboard_crashanalysis_issuedetail_symbolfile_list_right_icon.svg';
import IconWrong from 'svg/v2/newcs_dashboard_crashanalysis_issuedetail_symbolfile_list_wrong_icon.svg';
import IconManage from 'svg/v2/newcs_dashboard_crashanalysis_issuedetail_symbolfile_managebtn_icon.svg';
import { CrashModuleUtil } from 'utils/crash-module-util';

@withTranslation()
class SymbolTable extends Component {
  componentDidMount() {
    this.mountAndUpdateCommonHandler(undefined);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.mountAndUpdateCommonHandler(prevProps);
  }

  mountAndUpdateCommonHandler(prevProps) {
    const {
      symbolInfo: prevSymbolInfo,
      issue: prevIssue,
    } = prevProps || {};
    const { symbolInfo, issue, pid } = this.props;

    if (is(prevIssue, issue) && isEqual(symbolInfo, prevSymbolInfo)) {
      return;
    }

    // 检查tip状态
    const { crashDoc, issueExceptionType } = issue.toJS();
    if (!crashDoc || !symbolInfo) {
      return;
    }
    const { soInfo } = crashDoc.crashMap;
    // 发送更新tips的action
    if (PlatformUtil.isIosOrMac(pid) || isPcOrLinux(pid) || isPlaystation(pid)) {
      this.getNativeSymbolInfo(symbolInfo, issue, true);
    } else if (this.getShouldRenderJava()) {
      this.getJavaSymbolInfo(symbolInfo, issue, true);
    } else if (soInfo) {
      this.getNativeSymbolInfo(symbolInfo, issue, true);
    }
  }

  formatWithAppTimeZone(serverTime) {
    const { currentApp } = this.props;
    const utcOffset = currentApp.get('utcOffset');
    const timeStr = TimeZoneUtil.convertDefaultTimeZoneLocalDateTimeToUtcOffsetMoment(serverTime, utcOffset).format('YYYY-MM-DD HH:mm:ss');
    const offsetStr = TimeZoneUtil.makeUtcOffsetLabel(utcOffset);
    return offsetStr ? `${timeStr} (${offsetStr})` : timeStr;
  }

  getShouldRenderJava() {
    const { issue, pid } = this.props;
    if (!isAndroidOrHarmony(pid)) {
      return false;
    }

    const { issueExceptionType, crashDoc } = issue.toJS();
    const { retraceCrashDetail } = crashDoc.crashMap;
    const hasJavaStack = /java/i.test(retraceCrashDetail);
    return Number(issueExceptionType) === EXCEPTION_TYPE_INT_ANDROID_JAVA && hasJavaStack;
  }

  getJavaSymbolInfo(infos, issue, shouldSendAction) {
    const {
      uploadingId, uploadPercent, currentApp, actions,
    } = this.props;
    const { appId, pid } = currentApp.toJS();
    const { crashDoc } = issue.toJS();
    const channel = crashDoc.crashMap.channelId;
    const version = crashDoc.crashMap.productVersion;

    const thisCrashChannels = infos.filter((info) => info.channel === channel);
    const isThisCrashChannelUpload = thisCrashChannels.length > 0;
    const thisCrashChannelInfo = isThisCrashChannelUpload && thisCrashChannels[0];

    const allChannels = infos.filter((info) => info.channel === '-1');
    const isAllChannelUpload = (allChannels.length > 0);
    const allChannelInfo = isAllChannelUpload && allChannels[0];

    // 更新tips
    if (shouldSendAction) {
      if (!isThisCrashChannelUpload && !isAllChannelUpload) {
        actions.changeSymbolTips('no_symbol');
      } else {
        actions.changeSymbolTips('');
      }
    }

    return {
      uploadingId,
      uploadPercent,
      appId,
      pid,
      channel,
      version,
      isThisCrashChannelUpload,
      thisCrashChannelInfo,
      isAllChannelUpload,
      allChannelInfo,
    };
  }

  /**
   * @param uploadedInfos {{ uuid, soName, arch, uploadTime, productVersion, fileName, filePath }[]}
   */
  getNativeSymbolInfo(uploadedInfos, issue, shouldSendAction) {
    const {
      uploadingId, uploadPercent, actions, currentApp,
    } = this.props;
    const { appId, pid } = currentApp.toJS();
    const {
      attachList,
      crashDoc,
      pcModuleInfoList,
      isShowingMinidumpStack,
    } = issue.toJS();
    const { productVersion } = crashDoc.crashMap || {};
    const {
      soInfo,
      appUUID,
      systemSoNameList,
    } = crashDoc.crashMap || {};
    const { systemSymbolUuids, appSoNameList } = crashDoc.esMap || {};
    // 这个目前只有AOS/IOS有
    const lowerSystemSoNameList = (systemSoNameList || []).map(x => (x || '').toLowerCase());
    // 这个目前只有MAC有
    const lowerAppSoNameList = (appSoNameList || []).map(x => (x || '').toLowerCase());
    // 这个目前只有PC有
    const lowerSystemSymbolUuids = (systemSymbolUuids || []).map(x => (x || '').toLowerCase());

    const { ignoreSymbolInfo } = this.props;
    if (!ignoreSymbolInfo) {
      return <div></div>;
    }

    let soInfos = [];
    if (isAndroidOrHarmony(pid) && !isShowingMinidumpStack) {
      soInfos = soInfo.split('|').filter((str) => !!str);
      soInfos = soInfos.map((info) => {
        const tmp = info.split(':');
        let soName = '';
        let arch = '';
        let uuid = '';
        if (tmp.length >= 4) {
          soName = tmp[0];
          arch = tmp[1];
          uuid = tmp[3];
        }

        // 填充匹配的符号表信息
        // 移动端native匹配规则：先uuid匹配，如果匹配不到则根据version+arch+soName匹配
        const matchedUploadedSymbolInfo = uploadedInfos.find(x => {
          if (x.uuid.toLowerCase() === uuid.toLowerCase()) {
            return true;
          }
          return x.arch === arch && x.productVersion === productVersion && x.soName.toLowerCase() === soName.toLowerCase();
        });

        const isUploaded = isNotNullish(matchedUploadedSymbolInfo);
        const matchedSymbolFileUuid = isUploaded ? matchedUploadedSymbolInfo.uuid : '';
        const isUuidMatched = isUploaded && matchedSymbolFileUuid.toLowerCase() === uuid.toLowerCase();
        const isSystemSymbol = CrashModuleUtil.getIsSystemModule(soName, lowerSystemSoNameList, lowerAppSoNameList);
        return {
          soName,
          arch,
          uuid,
          isSystemSymbol,
          isUploaded,
          isUuidMatched,
          matchedSymbolFileUuid,
          uploadTime: (matchedUploadedSymbolInfo || {}).uploadTime,
          matchedUploadedSymbolInfo,
        };
      });
    } else if (PlatformUtil.isIosOrMac(pid) && !isShowingMinidumpStack) {
      let uuids = isIos(pid) ? [appUUID || '-1'] : [];
      // iOS额外增加UnityFramework模块。Mac额外增加appSoNameList中的模块。
      const soInfoLines = DataDetailUtil.makeSoInfoTextLines(pid, attachList);
      const extraModuleNames = isIos(pid)
        ? ['UnityFramework']
        : appSoNameList || [];
      const extraModuleLines = soInfoLines.filter(x => extraModuleNames.some(name => x.includes(name)));
      extraModuleLines.forEach((extraLine) => {
        // 格式是： UnityFramework: arm64;e9a1f943db113cccab834b045eb91aae;0x0000000115d88000 这样
        const extraUuid = (extraLine.split(';')[1] || '').toUpperCase();
        uuids.push(extraUuid);
      })

      soInfos = uuids.map(uuid => {
        const line = soInfoLines.find(x => x.includes(uuid.toLowerCase())) || '';
        const soName = line.split(':')[0] || '';
        const matchedUploadedSymbolInfo = uploadedInfos.find(x => x.uuid.toLowerCase() === uuid.toLowerCase());
        const isUploaded = isNotNullish(matchedUploadedSymbolInfo);
        const isSystemSymbol = CrashModuleUtil.getIsSystemModule(soName, lowerSystemSoNameList, lowerAppSoNameList);
        return {
          soName,
          uuid,
          isSystemSymbol,
          isUploaded,
          isUuidMatched: isUploaded,
          matchedSymbolFileUuid: isUploaded ? uuid : '',
          uploadTime: (matchedUploadedSymbolInfo || {}).uploadTime,
          matchedUploadedSymbolInfo,
        }
      });
    } else if (Array.isArray(pcModuleInfoList)) {
      soInfos = pcModuleInfoList.filter(x => x.uuid).map(({ moduleName, uuid }) => {
        const lowerUuid = uuid.toLowerCase();
        const matchedUploadedSymbolInfo = uploadedInfos.find(x => x.uuid.toLowerCase() === lowerUuid);
        const isUploaded = isNotNullish(matchedUploadedSymbolInfo);
        const isSystemSymbol = lowerSystemSymbolUuids.includes(lowerUuid);
        return {
          soName: moduleName,
          uuid,
          isSystemSymbol,
          isUploaded,
          isUuidMatched: isUploaded,
          matchedSymbolFileUuid: isUploaded ? uuid : '',
          uploadTime: (matchedUploadedSymbolInfo || {}).uploadTime,
          matchedUploadedSymbolInfo,
        };
      });
    }

    const hasAnyInfoNoSymbolMatched = soInfos.some(x => !x.isUploaded && !x.isSystemSymbol);

    // 过滤被忽略的so符号表
    const thisCrashIgnoreInfos = [];
    if (ignoreSymbolInfo.length > 0) {
      for (let i = 0; i < ignoreSymbolInfo.length; ++i) {
        for (let j = 0; j < soInfos.length; ++j) {
          if (ignoreSymbolInfo[i].soName === soInfos[j].soName) {
            thisCrashIgnoreInfos.push(ignoreSymbolInfo[i]);
          }
        }
      }

      soInfos = soInfos.filter((info) => {
        // 如果与ignoreSymbol中的soName一致，则被忽略
        let isIgnore = false;
        for (let i = 0; i < thisCrashIgnoreInfos.length; ++i) {
          if (thisCrashIgnoreInfos[i].soName === info.soName) {
            isIgnore = true;
            break;
          }
        }
        return !isIgnore;
      });
    }

    // 更新tips
    if (shouldSendAction) {
      if (hasAnyInfoNoSymbolMatched) {
        actions.changeSymbolTips('no_all_symbol');
      } else {
        actions.changeSymbolTips('');
      }
    }

    return {
      productVersion,
      soInfos,
      thisCrashIgnoreInfos,
    };
  }

  jumpToSymbolMgrPage() {
    const { actions, currentApp } = this.props;
    const { appId, pid } = currentApp.toJS();
    window.open(`/crash-reporting/preferences/dsyms/${appId}?pid=${pid}`,'_blank');
  }

  loadInitialData() {
    const { issue, fetchSymbolInfoAndIgnoreSymbolInfo } = this.props;
    const { crashDoc } = issue.toJS();
    if (!crashDoc) {
      return;
    }
    fetchSymbolInfoAndIgnoreSymbolInfo();
  }

  async handleIgnore(info) {
    const { actions, isDemoApp } = this.props;
    if (isDemoApp) return;
    await actions.ignoreSymbol(info.soName, info.uuid, info.arch, 1);
    this.loadInitialData();
  }

  async handleUnIgnore(info) {
    const { actions, isDemoApp } = this.props;
    if (isDemoApp) return;
    await actions.ignoreSymbol(info.soName, info.uuid, info.arch, 2);
    this.loadInitialData();
  }

  // 根据crash的类型渲染符号表内容
  renderSymbol(style, symbolInfo, issue, pid, goSymbolTag) {
    const { crashDoc } = issue.toJS();
    if (!crashDoc) {
      return <div></div>;
    }
    const { soInfo } = crashDoc.crashMap;
    const shouldRenderJava = this.getShouldRenderJava();

    const noNeedSymbol = isAndroidOrHarmony(pid) && !soInfo && !shouldRenderJava;

    return (
      <div>
          <div>
            { crashDoc.crashMap.retraceTime && !noNeedSymbol && <div className={`clearfix ${style.subtitle}`}>
              { <span className="color_gray">{`${this.props.t("SYMBOLTABLE.lastRestoreTime")}${ this.formatWithAppTimeZone(crashDoc.crashMap.retraceTime) }`}</span>}
            </div> }
            {shouldRenderJava && this.renderJavaSymbol(symbolInfo, issue)}
            {soInfo && this.renderNativeSymbol(symbolInfo, issue, shouldRenderJava)}
            {noNeedSymbol && <div className={_style.no_need_symbol}>{ this.props.t('SYMBOLTABLE.noNeedSymbol')}</div>}
            {(PlatformUtil.isIosOrMac(pid) || isPcOrLinux(pid) || isPlaystation(pid)) && this.renderNativeSymbol(symbolInfo, issue)}
          </div>
      </div>
    );
  }

  // native符号表
  // 展示的native符号表信息，存在crashdoc，为soInfos
  // 已上传的符号表信息，使用soinfos接口获取，存在state的symbol里
  // 对比二者的uuid和架构、soname来判断是否有上传
  renderNativeSymbol(uploadedInfos, issue, shouldRenderJava) {
    const {
      productVersion, soInfos, thisCrashIgnoreInfos,
    } = this.getNativeSymbolInfo(uploadedInfos, issue);
    const { symbolInfo, isDemoApp, pid } = this.props;

    const tableColumns = [{
      // dataIndex: 'soName',
      key: 'soName',
      title: ze('模块名', 'Module'),
      render: (rowData, record, index) => {
        const { uploadTime, isUploaded, isUuidMatched, isSystemSymbol } = record;
        if (isSystemSymbol) {
          return <Tooltip
            title={ze('系统模块', 'System Module')}
          ><div><span className={`${_style.system_symbol_text}`}>{rowData.soName}</span></div></Tooltip>;
        } else {
          return <div><span>{rowData.soName}</span></div>
        }
      }
    }, {
      dataIndex: 'uuid',
      title: 'UUID',
    }, {
      key: 'symbolMd5',
      title: ze('符号表MD5', 'Symbol File MD5'),
      render: (_, record) => {
        const { matchedUploadedSymbolInfo } = record;
        return (matchedUploadedSymbolInfo || {}).symbolMd5 || '-';
      },
    }, {
      dataIndex: 'arch',
      title: 'Arch',
      disabled: !isAndroidOrHarmony(pid),
    }, {
      key: 'uploadStatus',
      title: <div>
        <span>{ze('上传状态', 'Upload Status')}</span>
        {!isMobile(pid) && <span> ({this.renderSymbolUploadTips()})</span>}
      </div>,
      onCell: () => {
        return {
          style: {
            paddingTop: 0,
            paddingBottom: 0,
          },
        };
      },
      render: (text, record, index) => {
        const { uploadTime, isUploaded, isUuidMatched, isSystemSymbol } = record;
        if (isSystemSymbol) {
          return <div>{ ze('系统符号表', 'System File (Auto-configured by CrashSight)') }</div>;
        } else if (isUploaded) {
          return <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <IconRight/>
            <div>{ ze('已上传', 'Uploaded') }</div>
            <div>({ this.formatWithAppTimeZone(uploadTime) })</div>
          </div>;
        } else {
          return <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <IconWrong/>
            <div style={{ color: red.primary }}>{ ze('未上传', 'Not Uploaded') }</div>
            { /* <Popconfirm
              title={ze('要忽略此符号表吗？忽略后将不会提示该符号表的上传状态。', 'Do you want to ignore this symbol file? Ignoring this file will not hint the upload status of it.')}
              onConfirm={() => this.handleIgnore(record)}
            >
              <Button
                type='text'
                style={{ color: '#999', padding: '0' }}
              >{ ze('(忽略)', '(Ignore)') }</Button>
            </Popconfirm> */ }
          </div>
        }
      },
    }, {
      title: ze('操作','Operation'),
      render: (_,record) => {
        const { isUploaded, matchedUploadedSymbolInfo } = record;
        const symbolPath = (matchedUploadedSymbolInfo || {}).symbolPath || '';
        const fileCosKey = String(symbolPath || '').split('/').pop();
        const downloadUrl = `${getBackendInjectEnvs().cosDownloadOrigin}/${fileCosKey}`;
        return isUploaded && <div>
          <a href={isUploaded ? downloadUrl : undefined}><CsDownloadButton
            disabled={!isUploaded}
          /></a>
        </div>
      }
    }].filter(x => !x.disabled);

    return (
      <div key="native_symbol">
        <Space size={16} style={{ fontSize: '14px' }}>
          {isMobile(pid) && !isDemoApp && <Button
            style={{ borderColor: 'var(--cs-primary-color-alt)', color: 'var(--cs-primary-color-alt)' }}
            onClick={() => this.jumpToSymbolMgrPage()}
          ><div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <IconManage/>
            <div>{ ze('管理符号表文件', 'Manage Symbol Files')}</div>
          </div></Button> }
          {!shouldRenderJava && this.renderSymbolTips()}
        </Space>
        <div style={{ marginTop: '12px' }}>
          <Table
            size='small'
            columns={tableColumns}
            dataSource={soInfos}
          />
        </div>
        {thisCrashIgnoreInfos.length > 0 && (
          <div>
            <div className={`clearfix ${_style.symbol_title}`}>
              <div className={`fl ${_style.symbol_txt}`}>{ this.props.t("SYMBOLTABLE.symbolIgnored") }</div>
              <div className={`fl ${_style.read_rule}`}>
              { this.props.t("SYMBOLTABLE.ignoreRule") }
                <div className={_style.symbol_pop}>
                { this.props.t("SYMBOLTABLE.ignoreRuleTip") }
                </div>
              </div>

            </div>
            <ul className={_style.mod_symbol}>
              {
                thisCrashIgnoreInfos.map((ignoreInfo) => {
                  return (
                    <li>
                      <p className="fl color_gray">
                        <span>{`${ignoreInfo.soName}(${ignoreInfo.uuid} Arch:${ignoreInfo.arch})`}</span>
                      </p>
                      <div className={`fr ${_style.symbol_right}`}>
                        <span
                          className={`color_gray ${_style.cursor_pointer}`}
                          onClick={() => this.handleUnIgnore(ignoreInfo)}>
                          { this.props.t("SYMBOLTABLE.cancelIgnore") }
                        </span>
                      </div>
                    </li>
                  );
                })
              }
            </ul>
          </div>
        )}
      </div>
    );
  }

  // java符号表
  // java符号表一共有两条，一条是本条crash的channel，一条是all；如果infos中有channel等于本crashchannel的，则本crash的channel已上传；如infos中有channel为-1的，则all已上传
  renderJavaSymbol(infos, issue) {
    const { t } = this.props;
    const {
      uploadingId, uploadPercent, appId, pid, channel, version, isThisCrashChannelUpload, thisCrashChannelInfo,
      isAllChannelUpload, allChannelInfo,
    } = this.getJavaSymbolInfo(infos, issue);
    return (
      <div key="java_symbol">
        <Space size={16} style={{ fontSize: '14px' }}>
          <div>{t("SYMBOLTABLE.javaSymbol")}({ this.renderSymbolUploadTips() })</div>
          { this.renderSymbolTips() }
        </Space>
        <ul className={_style.mod_symbol}>
          {channel && (
            <li>
              <p className="fl ">
                <span>{`Version: ${version} Channel: ${channel}`}</span>
                {isThisCrashChannelUpload ? (
                  <span className={_style.upload_success}>
                    {`${thisCrashChannelInfo.fileName}   ${this.formatWithAppTimeZone(thisCrashChannelInfo.uploadTime)}   ${thisCrashChannelInfo.operateUser || ''}`}
                  </span>
                )
                  : <span className={_style.hold_upload}>{ this.props.t("SYMBOLTABLE.tobeUploaded") }</span>}
              </p>
            </li>
          )}
          <li>
            <p className="fl ">
              <span>{`Version: ${version} Channel: all`}</span>
              {isAllChannelUpload ? (
                <span
                  className={_style.upload_success}>
                  {`${allChannelInfo.fileName}   ${this.formatWithAppTimeZone(allChannelInfo.uploadTime)}   ${allChannelInfo.operateUser || ''}`}
                </span>
              )
                : <span className={_style.hold_upload}>{ this.props.t("SYMBOLTABLE.tobeUploaded") }</span>}
            </p>
          </li>
        </ul>
      </div>
    );
  }

  renderSymbolTips() {
    const { symbolTips } = this.props;
    if (symbolTips) {
      return (
        <div
          className={`color_${SYMBOL_TIPS[symbolTips].color} ${_style.symbol_tips}`}>
          {
            symbolTips === '40412'
            ?(
              <div>
                { this.props.t("SYMBOLTABLE.40412Tip1") }
                <a href={IOS_SYMBOL_TOOL} className="color_blue">{ this.props.t("SYMBOLTABLE.40412Tip2") }</a>
                { this.props.t("SYMBOLTABLE.40412Tip3") }
              </div>
            ):(
              symbolTips === '40413'
              ? (
                <div>
                  { this.props.t("SYMBOLTABLE.40413Tip1") }
                  <a href={ANDROID_SYMBOL_TOOL} className="color_blue">{ this.props.t("SYMBOLTABLE.40413Tip2") }</a>
                  { this.props.t("SYMBOLTABLE.40413Tip3") }
                </div>
              ): (
                this.props.t(SYMBOL_TIPS[symbolTips].tips)
              )
            )
          }
        </div>
      );
    }
    return null;
  }

  /** 渲染符号表上传的说明文档提示 */
  renderSymbolUploadTips() {
    const { currentApp } = this.props;
    const { teamType } = currentApp.toJS();
    let url = ''
    if (teamType === BillingTeamType.TEST || teamType === BillingTeamType.TENCENT) {
      url = DocsUtil.makeDocsUrl('symbolsDocuments/landun-plugin')
    } else {
      url = DocsUtil.makeDocsUrl('symbolsDocuments/symbol-table')
    }

    return <a href={url} target="_blank">{
        ze('上传教程', 'Upload Tutorial')
    }</a>
  }

  render() {
    const {
      style, issue, pid, symbolInfo, goSymbolTag,
    } = this.props;

    if (!symbolInfo) {
      return <div></div>;
    }

    return this.renderSymbol(style, symbolInfo, issue, pid, goSymbolTag);
  }
}

SymbolTable.propTypes = {
  style: PropTypes.object.isRequired,
  actions: PropTypes.object,
  symbolInfo: PropTypes.array,
  issue: PropTypes.object,
  pid: PropTypes.string,
  symbolTips: PropTypes.string,
  uploadPercent: PropTypes.number,
  uploadingId: PropTypes.string,
  currentApp: PropTypes.object,
  ignoreSymbolInfo: PropTypes.array,
  isDemoApp: PropTypes.bool,
  goSymbolTag: PropTypes.func,
  currentUser: PropTypes.object,
  fetchSymbolInfoAndIgnoreSymbolInfo: PropTypes.func,
};

// 设置props
export default connect((state) => {
  return {
    currentApp: state.app.get('current'),
    currentUser: state.user.get('current'),
    uploadingId: state.symbol.get('uploadingId'),
    uploadPercent: state.symbol.get('uploadPercent'),
    symbolTips: state.symbol.get('symbolTips'),
    isDemoApp: state.app.get('isDemoApp'),
    dsymsSize: state.global.get('dsymsSize'),
  };
}, (dispatch) => ({
  actions: bindActionCreators({
    ...symbolActions,
    pushState,
    replaceState,
  }, dispatch),
  dispatch,
}))(SymbolTable);
