import { notification } from 'antd';
import moment from 'moment-timezone';

export const DEFAULT_TIME_ZONE = process.env.REACT_APP_TIMEZONE || 'Asia/Shanghai';

const supportedUtcOffsetOptions = (function() {
  const [minHourOffset, maxHourOffset] = [-12, 12];
  let options = [];
  for (let h = minHourOffset; h <= maxHourOffset; h += 1) {
    const label = h === 0
      ? 'UTC'
      : (h > 0 ? `UTC+${h}` : `UTC-${-h}`);
    const zoneId = h === 0
      ? 'Etc/UTC'
      : (h > 0 ? `Etc/GMT-${h}` : `Etc/GMT+${-h}`);
    const paddedAbsHour = String(Math.abs(h)).padStart(2, '0');
    options.push({
      value: h >= 0 ? `+${paddedAbsHour}:00` : `-${paddedAbsHour}:00`,
      label,
      zoneId,
    });
  }
  return Object.freeze(options);
})();

const supportedUtcOffsetToZoneId = Object.assign(
  Object.create(null),
  ...supportedUtcOffsetOptions.map(x => ({ [x.value]: x.zoneId })),
);

const supportedUtcOffsetToLabel = Object.assign(
  Object.create(null),
  ...supportedUtcOffsetOptions.map(x => ({ [x.value]: x.label })),
);

export const TimeZoneUtil = Object.freeze({
  makeZoneIdByUtcOffset(utcOffset) {
    if (!utcOffset) {
      return '';
    }
    const zoneId = supportedUtcOffsetToZoneId[utcOffset];
    if (zoneId) {
      return zoneId;
    } else {
      notification.error({ message: `Invalid utcOffset ${utcOffset}` });
      return '';
    }
  },

  makeUtcOffsetLabel(utcOffset) {
    if (!utcOffset) {
      return '';
    }
    return supportedUtcOffsetToLabel[utcOffset] || utcOffset;
  },

  makeMomentConstructorWithUtcOffset(utcOffset) {
    if (!utcOffset) {
      return moment;
    }
    return (...args) => {
      return moment(...args).utcOffset(utcOffset);
    };
  },

  /** 把系统默认时区表示的字面格式化时间，转换为指定UTC偏移量的moment */
  convertDefaultTimeZoneLocalDateTimeToUtcOffsetMoment(defaultTimeZoneLocalDateTime, utcOffset) {
    if (!utcOffset) {
      return moment(defaultTimeZoneLocalDateTime);
    }
    return moment.tz(defaultTimeZoneLocalDateTime, DEFAULT_TIME_ZONE).tz(this.makeZoneIdByUtcOffset(utcOffset));
  },

  getSupportedUtcOffsetOptions() {
    return supportedUtcOffsetOptions;
  }
});
