import { getAllInOneReduxState } from '../index';
import { PlatformUtil } from './platform';
import i18n from '../i18n';
import { UserTypeList } from './constants/user-type-enum';
import RestHelper from "utils/RestHelper";

// 埋点行为
export const EVENT_ACTIONS = Object.freeze({
  OPEN: 'open', // 访问页面，pv/uv
  CLICK: 'click', // 点击行为
  EXPOSURE: 'exposure',  // 组件曝光事件
});

// 日志埋点方法
export default async function reportEvent(data = {}) {
  const state = getAllInOneReduxState();

  const currentApp = state.app.get('current').toJS();
  const currentUser = state.user.get('current').toJS();

  const { newUserId, nickname, type, isOperator } = currentUser;
  const { appId, pid } = currentApp;

  if (!newUserId) {
    return;
  }

  console.log('type', type, isOperator, UserTypeList)

  const userType = UserTypeList.find(x => x.type === type && x.isOperator === isOperator)
      || UserTypeList.find(x => x.type === type);

  const language = i18n.language === 'en' ? 'en' : 'zh-CN';
  // 上报参数
  const postForm = {
    appId: appId || 'unknow_appId',
    // 默认为 exposure
    action: EVENT_ACTIONS.EXPOSURE,
    tp1: document.title,
    tp2: PlatformUtil.toName(pid) || 'unknow_platform',
    tp4: language,
    ...data,
  };

  try {
    const res = await RestHelper.mustPost('/redir/api/userEventTracking/report', postForm, { silentFailure: true });
    console.log('%c数据上报>>>>', 'color: blue;font-weight: blod', postForm, res);
  } catch (error) {
    console.log('%c数据上报>>>>', 'color: red;font-weight: blod', postForm, error);
  }
}

const cardHeadNameToLastClickMillis = {};

export const ReportEventUtil = Object.freeze({
  async reportCardHeadClickEvent(cardHeadName, isFromArrowButton, isExpand) {
    const nowMillis = +new Date();
    const lastClickMillis = cardHeadNameToLastClickMillis[cardHeadName];
    const clickIntervalMillis = lastClickMillis ? nowMillis - lastClickMillis : 0;
    cardHeadNameToLastClickMillis[cardHeadName] = nowMillis;

    let tp6Json = {
      isExpand,
      isFromArrowButton,
    };
    if (clickIntervalMillis > 0 && clickIntervalMillis <= 60000) {
      tp6Json.clickIntervalMillis = clickIntervalMillis;
      if (clickIntervalMillis < 1500) { // 1.5秒内重复点击视为误点击
        tp6Json.misclick = true;
      }
    }

    const tp1Prefix = isExpand ? '大盘卡片展开' : '大盘卡片收起';
    const tp1 = `${tp1Prefix}-${cardHeadName || 'Unknown'}`;
    await reportEvent({
      action: EVENT_ACTIONS.CLICK,
      tp1,
      tp6: JSON.stringify(tp6Json),
    })
  },
});

export function getPageTitle() {
  const { title = '' } = document;
  return title.split('-')[0] || '';
}

export function getExceptionTypeReportName (exceptionType) {
  const map = {
    crash: '崩溃列表',
    anr: 'ANR列表',
    error: '错误列表',
  }

  return map[exceptionType] || exceptionType;
}
