export const formatCrashPercentageString = (numerator, denominator, digits = 2) => {
  if (typeof numerator !== 'number') {
    console.error('numerator must be typeof number !');
    return 'ERROR';
  }
  if (typeof denominator !== 'number') {
    console.error('denominator must be typeof number !');
    return 'ERROR';
  }

  if (numerator <= 0) {
    return (0).toFixed(digits);
  }
  if (numerator > denominator) {
    return `> ${(100).toFixed(digits)}`;
  }
  return (100 * numerator / denominator).toFixed(digits);
};

/** 把数值转为带千位分隔符的格式 */
export const formatEnNumber = (v) => {
  try {
    return Number(v).toLocaleString('en');
  } catch (e) {
    return v;
  }
};

/**
 * 把格式化的字符串数值转换为数值
 */
export const transformLocaleStrngToNumber = (strNum) => {
  return Number(strNum.replace(/[^0-9-.]/g, ''));
}

/**
 * 数字简化
 */
export const numberToBKM = ({value,fractionDigits = 0}) => {
  if (value >= 1000000000) {
    return `${parseFloat(value / 1000000000, 10).toFixed(fractionDigits)}B`;
  }
  if (value >= 1000000) {
    return `${parseFloat(value / 1000000, 10).toFixed(fractionDigits)}M`;
  }
  if (value >= 1000) {
    return `${parseFloat((value / 1000).toFixed(fractionDigits))}K`;
  }
  return `${value}`;
}