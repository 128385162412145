import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'antd';

const CardHead = ({
  title,
  titleExtraStyle,
  hideDivider,
  icon,
  svgIcon,
  iconExtraStyle,
  children,
  onClick,
}) => {
  const titleStyle = {
    fontSize: '16px',
    fontWeight: 'bold',
    paddingLeft: '8px',
    paddingRight: '24px',
    whiteSpace: 'nowrap',
    ...(titleExtraStyle || {}),
  };
  const iconStyle = {
    height: '18px',
    width: '18px',
    ...(iconExtraStyle || {}),
  };
  if (!icon && !svgIcon) {
    Object.assign(titleStyle, {
      borderLeft: '4px solid #4371FD',
    });
  }
  
  const containerStyle = {fontSize: '14px'};
  if (typeof onClick === 'function') {
    Object.assign(containerStyle, {
      cursor: 'pointer',
    });
  }

  return <div style={containerStyle}
    onClick={onClick}  
  >
    <div style={{ display: 'flex', alignItems: 'center', height: '60px' }}>
      {
        icon && <img style={iconStyle} src={icon} />
      }
      { svgIcon }
      <div style={titleStyle}>{title}</div>
      {children}
    </div>
    { !hideDivider && <Divider style={{ marginTop: '0px', marginBottom: '0px' }}/> }
  </div>;
};

CardHead.propTypes = {
  hideDivider: PropTypes.bool,
};

export default CardHead;
