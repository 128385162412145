/**
 * Created by ellis on 23/11/2016.
 */
import { handleActions } from 'redux-actions';
import { VERSION_LIST_SUCC, SYMBOL_LIST_SUCC, RESET_SYMBOL_DATA } from 'store/actions';
import { Map as iMap, List as iList } from 'immutable';
import { versionComparation } from 'utils/helper';
import { initialState } from './initialState';

export default handleActions({
  [VERSION_LIST_SUCC]: (state, action) => {
    console.log('versionList 成功获取了', action);
    const { versions = [] } = action.response;
    return state.update('selectOptions', (options) => options.mergeDeep({
      versions: {
        options: iList(versions).map((item) => ({ label: item.version, value: encodeURIComponent(item.version) })).unshift({
          label: '全版本',
          value: '-1',
        }),
      },
    }));
  },
  [SYMBOL_LIST_SUCC]: (state, action) => {
    if (action.params.init && (typeof action.response !== 'object' || !action.response.infos || !action.response.infos.length)) {
      return state.set('empty', !!action.params.init).set('list', iMap({
        total: 0,
        symbols: [],
      }));
    } else {
      const { infos, totalCount } = action.response;

      let symbols = [];
      let current = null;
      infos.forEach((item) => {
        if (!current || current.version !== item.version) {
          current = {
            version: item.version,
            list: [],
          };
          symbols.push(current);
        }
        current.list.push(item);
      });
      if (action.params.size === 1) {
        symbols = [];
      }
      const result = {
        total: totalCount,
        symbols,
      };
      return state.set('empty', false).set('selectedType', action.params.symbolType).set('list', iMap(result));
    }
  },
  [RESET_SYMBOL_DATA]: (state) => {
    return state.set('selectedType', 3);
  },
}, initialState);
