import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as platformUtils from 'utils/platform';
import moment from 'moment';
import { Button, Col, DatePicker, Radio, Row, Select, Space, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import CardHead from 'components/commons/CardHead';
import { CountryUtil } from 'utils/country';
import CountrySelect from 'components/exception/ProductSummaryPage/CountrySelect';
import IssueCrashFilter, { IssueCrashFilterUtil } from 'components/commons/IssueCrashFilter/IssueCrashFilter';
import { connect, useSelector } from 'react-redux';
import pickBy from 'lodash.pickby';
import { isNotNullish } from 'utils/nullish';
import { ze, isZh } from 'utils/zhEn';
import { DownloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { VersionUtil } from 'utils/version-util';
import VersionHybridSelect from 'components/commons/VersionHybridSelect';
import { CsvUtil } from 'utils/csv-util';
import { orange } from '@ant-design/colors';
import SingleOrRangeDatePicker from 'components/exception/ProductSummaryPage/SingleOrRangeDatePicker';
import { TopIssueSortField } from 'components/exception/ProductSummaryPage/TopIssueList/TopIssueList';
import { selectCurrentAppDatePickerAvailableMomentRange } from 'utils/selectors/selectors';
import { Link } from 'react-router-dom';
import { DocsUtil } from 'utils/docs-util';
import reportEvent, { EVENT_ACTIONS } from 'utils/reportEvent';

/**
 * 选择topIssue问题统计类型：全部数据、异常退出、非异常退出
 */
const ALL_VERSION = '-1';

function makeDefaultTopIssueFilterData() {
  return [
    { name: 'tagList', value: { isMustNot: false, value: [] } },
  ];
}

const TOP_ISSUE_FILTER_FIELD_WHITELIST = ['tagList', 'status'];

const TopIssueDataTypeSelect = ({
  reduxState,
  style,
  handleTopIssueDataTypeChange,
  onTopIssueParamsChange,
  onTopIssueVersionChange,
  onTopIssueCountryChange,
  onTopIssueEnableFilterChange,
  onTopIssueFilterRawSearchChange,
  onTopIssueIntegratedAppIdChange,
  onTopIssueSortFieldChange,
  topIssueCount,
  onTopIssueCountChange,
  onDownloadData,
  // handleChangeTopIssueSearchParams,
  searchParams,
  selectOptions,
  isRegionalDashboard,
  hasIntegratedApps,
}) => {
  const { t } = useTranslation();

  const [
    datePickerMinAvailableMoment,
    datePickerMaxAvailableMoment,
  ] = useSelector(selectCurrentAppDatePickerAvailableMomentRange);

  const tagListReduxState = reduxState.issue.get('selectOptions').get('tagList');
  const tagListOptions = (tagListReduxState.get ? tagListReduxState.get('options') : tagListReduxState.options)
    .map(x => ({ ...x, value: String(x.value) }))
    .filter(x => x.value !== 'all');
  const issueStatusReduxState = reduxState.issue.get('selectOptions').get('status');
  const issueStatusOptions = (issueStatusReduxState.get ? issueStatusReduxState.get('options') : issueStatusReduxState.options).filter(x => x.value !== 'all');

  const [topIssueFilterData, setTopIssueFilterData] = useState(makeDefaultTopIssueFilterData());

  useEffect(() => {
    const topIssueFilterRawSearchJsonStr = searchParams.get('topIssueFilterRawSearchJsonStr');
    if (!topIssueFilterRawSearchJsonStr) {
      setTopIssueFilterData(makeDefaultTopIssueFilterData());
      return;
    }
    const filterData = IssueCrashFilterUtil.makeFilterDataFromQueryDict(JSON.parse(topIssueFilterRawSearchJsonStr))
      .filter(x => TOP_ISSUE_FILTER_FIELD_WHITELIST.includes(x.name));
    setTopIssueFilterData(filterData);
  }, [searchParams.get('topIssueFilterRawSearchJsonStr')]);

  const { rankVersion, integratedAppOptions, integratedAppIdToVersionTree } = selectOptions.toJS();
  const { pid, topIssueDate, topIssueMaxDate, topIssueSortField } = searchParams.toJS();
  const minMoment = topIssueDate ? moment(topIssueDate) : moment();
  const maxMoment = topIssueMaxDate ? moment(topIssueMaxDate) : minMoment;
  const isMultipleDates = !minMoment.isSame(maxMoment);

  const versionListValue = searchParams.get('topIssueVersion') !== ALL_VERSION
    ? CsvUtil.singleLineParse(searchParams.get('topIssueVersion'))
    : [];
  const topIssueIntegratedAppIdValue = searchParams.get('topIssueIntegratedAppId') !== ALL_VERSION
    ? searchParams.get('topIssueIntegratedAppId')
    : null;

  const countryList = searchParams.get('topIssueCountry') && searchParams.get('topIssueCountry') !== '-1'
    ? searchParams.get('topIssueCountry').split(',')
    : [];

  const sortFieldOptions = [
    { label: ze('按设备数', 'By Affected Devices'), value: 'DEVICE_COUNT' },
    { label: ze('按次数', 'By Reports'), value: 'REPORT_COUNT' },
  ];

  const topIssueCountOptions = [20, 50, 100]
    .map(x => ({ label: t(`DATATYPESELECT.top${x}`), value: x }));

  const topIssueEnableFilterValue = Number(searchParams.get('topIssueEnableFilter') || 0);

  const isQueryIntegratedApp = isNotNullish(topIssueIntegratedAppIdValue);

  const versionTree = isQueryIntegratedApp
    ? integratedAppIdToVersionTree[topIssueIntegratedAppIdValue] || []
    : rankVersion.versionTree;

  const isMultipleVersions = VersionUtil.isMultipleVersions(versionListValue);

  const topRowDom = <div>
    <Row align='middle' gutter={[8, 6]}>
      <Col flex={null}>
        <Radio.Group
          optionType='button'
          options={sortFieldOptions}
          value={topIssueSortField || TopIssueSortField.DEVICE_COUNT}
          onChange={(e) => {
            if (e.target.value === 'REPORT_COUNT') {
              reportEvent({
                action: EVENT_ACTIONS.CLICK,
                tp1: '每日TOP问题-按次数',
              });
            }

            onTopIssueSortFieldChange(e.target.value);
          }}
        />
      </Col>
      <Col flex={null}>
        <Select
          style={{ width: '100px' }}
          options={topIssueCountOptions}
          value={topIssueCount}
          onChange={v => onTopIssueCountChange(v)}
        />
      </Col>
      <Col flex={null}>
        <VersionHybridSelect
          style={{ width: '200px' }}
          value={versionListValue}
          treeData={versionTree}
          onChange={v => onTopIssueVersionChange(CsvUtil.singleLineStringify(v) || ALL_VERSION)}
        />
      </Col>
      <Col flex={null}>
        <SingleOrRangeDatePicker
          disabledDate={moment => moment && (moment.isBefore(datePickerMinAvailableMoment) || moment.isAfter(datePickerMaxAvailableMoment))}
          allowClear={false}
          value={[minMoment, maxMoment]}
          onChange={([minDate, maxDate]) => onTopIssueParamsChange({
            topIssueDate: minDate ? minDate.format('YYYYMMDD') : null,
            topIssueMaxDate: (maxDate && !maxDate.isSame(minDate)) ? maxDate.format('YYYYMMDD') : null,
          })}
        />
      </Col>
      { hasIntegratedApps && <Col flex={null}>
        <Select
          allowClear={true}
          showSearch
          style={{ width: '200px' }}
          options={integratedAppOptions}
          placeholder={ze('所有接入项目', 'All Integrated App')}
          value={topIssueIntegratedAppIdValue}
          onChange={v => onTopIssueIntegratedAppIdChange(v || ALL_VERSION)}
        />
      </Col> }
      { isRegionalDashboard && <Col>
        <CountrySelect
          value={countryList}
          isMultiple={true}
          onChangeWhenDropdownClosed={v => onTopIssueCountryChange(CountryUtil.listToQueryString(v))}
        />
      </Col> }
      { platformUtils.isMobile(pid) && <Col>
        <div style={{ fontSize: '12px', userSelect: 'none', color: '#999' }}>{t('DATATYPESELECT.增加问题过滤条件')}</div>
        <div><Radio.Group
          options={[
            { label: t('common.yes'), value: 1 },
            { label: t('common.no'), value: 0 },
          ]}
          value={topIssueEnableFilterValue}
          onChange={(e) => {
            if (e.target.value) {
              reportEvent({
                action: EVENT_ACTIONS.CLICK,
                tp1: '增加问题过滤条件',
              });
            }
            onTopIssueEnableFilterChange(e.target.value);
          }}
        /></div>
      </Col> }
      { platformUtils.isMobile(pid) && <Col>
        <Tooltip placement="top" arrowPointAtCenter={true} title={ze('点击查看关键字列表', 'click and view keywords list')}>
          <div>
            <div style={{ fontSize: '12px', userSelect: 'none', color: '#999' }}>
              <a
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#999' }}
              href={isZh()
                ? DocsUtil.makeDocsUrl('/terms/#%E7%B3%BB%E7%BB%9F%E9%80%80%E5%87%BA%E5%85%B3%E9%94%AE%E5%AD%97')
                : DocsUtil.makeDocsUrl('/terms/#system-exit-keywords')
              }>{t('DATATYPESELECT.matchSysExitKW')}
            </a>
            </div>
            <Radio.Group
              options={[
                { label: t('common.yes'), value: 'systemExit' },
                { label: t('common.no'), value: 'unSystemExit' },
              ]}
              value={searchParams.get('topIssueDataType')}
              onChange={(e) => {
                if (e.target.value === 'systemExit') {
                  reportEvent({
                    action: EVENT_ACTIONS.CLICK,
                    tp1: '增加问题过滤条件',
                  });
                }

                handleTopIssueDataTypeChange(e.target.value);
              }}
            />
          </div>
        </Tooltip>
        <div></div>
      </Col> }
      <Col>
        <Button
          icon={<DownloadOutlined />}
          onClick={() => {
            reportEvent({
              action: EVENT_ACTIONS.CLICK,
              tp1: '每日TOP问题-下载',
            });
            onDownloadData();
          }}
        >{ ze('下载数据', 'Download') }</Button>
      </Col>
    </Row>
  </div>;

  return <CardHead title={t('DATATYPESELECT.dailyTopIssue')} hideDivider={false}>
    <div style={{ flexGrow: '1', display: 'flex', flexDirection: 'column' }}>
      { topRowDom }
      { (isMultipleVersions || isMultipleDates) && <div style={{ marginTop: '4px', width: '100%', color: orange.primary, fontSize: '12px' }}>
        <Space>
          <ExclamationCircleOutlined/>
          <div>{ t('EXCP_OVERVIEW.multipleVersionsDatesWarning')}</div>
        </Space>
      </div> }
      { !!topIssueEnableFilterValue && <div>
        <IssueCrashFilter
          platformId={pid}
          maxConditions={2}
          fieldWhitelist={['tagList', 'status']}
          filterOptions={{
            tagList: tagListOptions,
            status: issueStatusOptions,
          }}
          filterData={topIssueFilterData}
          onChange={({ newFilterData }) => {
            setTopIssueFilterData(newFilterData);
          }}
          onSubmit={({ newFilterData }) => {
            setTopIssueFilterData(newFilterData);
            const rawSearch = pickBy(IssueCrashFilterUtil.makeQueryDictFromFilterData(newFilterData), isNotNullish);
            onTopIssueFilterRawSearchChange(rawSearch);
          }}
        />
      </div> }
    </div>
  </CardHead>;
};

TopIssueDataTypeSelect.propTypes = {
  style: PropTypes.object,
  hasIntegratedApps: PropTypes.bool,
  handleTopIssueDataTypeChange: PropTypes.func,
  onTopIssueParamsChange: PropTypes.func,
  onTopIssueVersionChange: PropTypes.func,
  searchParams: PropTypes.object,
  selectOptions: PropTypes.object,
  isRegionalDashboard: PropTypes.bool, // 是否是国家维度的大盘统计
};


const mapStateToProps = state => ({
  reduxState: state,
});

export default connect(mapStateToProps)(TopIssueDataTypeSelect);
