import { ConfigProvider } from 'antd';
import { CS_STYLES } from 'utils/constants/style-constants';

export const ThemeColor = Object.freeze({
  ORANGE: 'ORANGE',
  BLUE: 'BLUE',
});

const THEME_COLOR_LOCAL_STORAGE_KEY = 'CsThemeColor';

export const ThemeColorUtil = Object.freeze({
  setThemeColor(themeColor) {
    if (themeColor) {
      window.localStorage.setItem(THEME_COLOR_LOCAL_STORAGE_KEY, themeColor);
    } else {
      window.localStorage.removeItem(THEME_COLOR_LOCAL_STORAGE_KEY);
    }
    this.setThemeClassToBodyByCurrentLocalStorage();
  },

  getThemeColorFromLocalStorage() {
    return window.localStorage.getItem(THEME_COLOR_LOCAL_STORAGE_KEY);
  },

  getIsThemeColorBlue() {
    return this.getThemeColorFromLocalStorage() === ThemeColor.BLUE;
  },

  setThemeClassToBodyByCurrentLocalStorage() {
    const isThemeColorBlue = ThemeColorUtil.getThemeColorFromLocalStorage() === ThemeColor.BLUE;
    if (isThemeColorBlue) {
      window.document.body.classList.add('cs-theme-blue');
    } else {
      window.document.body.classList.remove('cs-theme-blue');
    }
    ConfigProvider.config({
      theme: {
        primaryColor: CS_STYLES.getPrimaryColor(),
      },
    });
  }
});
