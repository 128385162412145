import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

const PercentageBar = ({
  percentage,
  style,
}) => {
  const formattedPercentage = (percentage || 0).toFixed(2) + '%';
  let percentageForCss = percentage;
  if (percentageForCss > 100) {
    percentageForCss = 100;
  } else if (percentageForCss < 0) {
    percentageForCss = 0;
  }

  return <div
    style={{
      position: 'relative',
      width: '180px',
      ...style,
    }}
  >
    <div style={{ position: 'absolute', height: '100%', width: '100%', backgroundColor: '#e9e7fb' }}/>
    <div style={{ position: 'absolute', height: '100%', width: `${percentageForCss}%`, backgroundColor: '#9187EB' }}/>
    <div style={{ position: 'relative', width: '100%', paddingRight: '4px', textAlign: 'right', zIndex: 1 }}>{ formattedPercentage }</div>
  </div>;
};

PercentageBar.propTypes = {
  text: PropTypes.string,
  percentage: PropTypes.number,
};

export default PercentageBar;
