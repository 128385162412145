import { ze } from "utils/zhEn";

export const MERGE_TYPE_IS_BE_MERGED_ISSUE = 1;
export const MERGE_TYPE_IS_MERGE_ISSUE = 2;
export const MERGE_TYPE_MERGING = 3;
export const MERGE_TYPE_MERGE_ISSUE_CREATING = 4;

const mergeTypeTagNameMap = {
  [MERGE_TYPE_IS_BE_MERGED_ISSUE]: ze('合并子问题', 'Merged Sub-issue'),
  [MERGE_TYPE_IS_MERGE_ISSUE]: ze('合并问题', 'Merged Issue'),
  [MERGE_TYPE_MERGING]: ze('问题合并中', 'Being Merged'),
  [MERGE_TYPE_MERGE_ISSUE_CREATING]: ze('合并问题生成中', 'Merging'),
}
export const mergeTypeTagName = (type) => {
  return mergeTypeTagNameMap[type] ?? ''
}