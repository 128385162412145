import React from 'react';
import PropTypes from 'prop-types';
import _style from './style.scss';
import EmptyIcon from 'images/newcs_dashboard_empty_icon.png';
export default function EmptyData(props) {
  const { text, reflashFunc, style, extraStyle = {}, emptyImgVisiable = true } = props;
  return (
    <div className={style || _style.empty_data} style={extraStyle}>
      {
        emptyImgVisiable && <img src={EmptyIcon} style={{ width: '80px', height: '80px', marginBottom: '16px' }} />
      }
      {/* <svg width="110px" height="110px" version="1.1">
        <defs></defs>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-590.000000, -1107.000000)">
            <g transform="translate(160.000000, 101.000000)">
              <g transform="translate(0.000000, 846.000000)">
                <g transform="translate(277.000000, 165.000000)">
                  <g id="Icon" transform="translate(158.000000, 0.000000)">
                    <circle stroke="#E8E8E8" cx="50" cy="50" r="50"></circle>
                    <path d="M70,40 L70,46 C70,49.3137085 61.045695,52 50,52 C38.954305,52 30,49.3137085 30,46 L30,40 C30,43.3137085 38.954305,46 50,46 C61.045695,46 70,43.3137085 70,40 Z M70,49 L70,55 C70,58.3137085 61.045695,61 50,61 C38.954305,61 30,58.3137085 30,55 L30,49 C30,52.3137085 38.954305,55 50,55 C61.045695,55 70,52.3137085 70,49 Z M70,58 L70,64 C70,67.3137085 61.045695,70 50,70 C38.954305,70 30,67.3137085 30,64 L30,58 C30,61.3137085 38.954305,64 50,64 C61.045695,64 70,61.3137085 70,58 Z M50,43 C61.045695,43 70,40.3137085 70,37 C70,33.6862915 61.045695,31 50,31 C38.954305,31 30,33.6862915 30,37 C30,40.3137085 38.954305,43 50,43 Z" id="Combined-Shape" fill="#E8E8E8"></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg> */}
      <p className={_style.tips}>{text}</p>
      {
        reflashFunc && <a onClick={(e) => {
          e.preventDefault(); reflashFunc();
        }}>点击刷新</a>
      }
    </div>
  );
}

EmptyData.propTypes = {
  reflashFunc: PropTypes.func,
  text: PropTypes.string.isRequired,
  style: PropTypes.object,
};


// style={{backgroundImage: 'url(' + svg + ')'}}
