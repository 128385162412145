import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import scss from './StackLineMemoryInfo.scss';
import IconMemory from 'svg/v2/newcs_dashboard_crashanalysis_issuedetail_stack_memory_icon.svg';
import { Col, Row } from 'antd';

const StackLineMemoryInfo = ({
  detailStackMemoryInfo,
  isMinidump,
}) => {
  const {
    lineNumber,
    lineStack,
    stackMemoryInfo,
    asmCodeInfo,
    stackVariable: stackVariableJsonStr,
    originAddress, // 来自移动端非minidump的memoryData
    originMemoryData, // 来自移动端非minidump的memoryData
  } = detailStackMemoryInfo || {};

  const stackVariableList = useMemo(() => {
    if (!stackVariableJsonStr) {
      return [];
    }
    try {
      return JSON.parse(stackVariableJsonStr);
    } catch (e) {
      console.error('parse stackVariable failed. ', e);
      return [];
    }
  }, [stackVariableJsonStr]);
  const hasStackVariable = !!stackVariableList && stackVariableList.length > 0;

  if (!detailStackMemoryInfo) {
    return null;
  }
  return <div className={scss.stackLineMemoryInfo}>
    <Row gutter={16}>
      { isMinidump && <Col span={16}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <IconMemory />
          <div>Memory</div>
        </div>
        <pre style={{ marginTop: '12px' }}>{ stackMemoryInfo }</pre>
      </Col> }
      <Col span={8}>
        <div>Assembly Code</div>
        <pre style={{ width: '100%', padding: '8px 20px' }}>{ asmCodeInfo || '(Not Collected)' }</pre>
        { hasStackVariable && <div style={{ marginTop: '12px' }}>Stack Variables</div> }
        { hasStackVariable && <pre style={{ width: '100%', padding: '8px 20px' }}>{
          stackVariableList.map(({ variableName, variableValue }, i) => <div key={i}>
            <span style={{ marginRight: '1em' }}>{ variableName }:</span>
            <span>{ variableValue }</span>
          </div>)
        }</pre> }
      </Col>
    </Row>
  </div>;
};

StackLineMemoryInfo.propTypes = {
  /**
   * @type { { lineNumber: number, lineStack: string, stackMemoryInfo: string } }
   * */
  detailStackMemoryInfo: PropTypes.object,
};

export default StackLineMemoryInfo;
