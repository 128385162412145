/**
 * @param props fontSize: 字体像素大小; nickname、userId: 水印文本; gap: 水印之间距离
*/
const addWatermark = (props) => {
  const { fontSize, nickname, userId, gap, type } = props
  const canvas = document.createElement('canvas');
  // 设置字体大小
  const font = `${fontSize}px Microsoft YaHei`;
  const ctx = canvas.getContext('2d');
  // 获取文字宽度
  ctx.font = font;
  const { width: nicknameWidth } = ctx.measureText(nickname);
  const { width: userIdWidth } = ctx.measureText(userId);
  const canvasSize = Math.max(100, nicknameWidth, userIdWidth) + gap;
  canvas.width = canvasSize;
  canvas.height = canvasSize;
  ctx.translate(canvas.width / 2, canvas.height / 2);
  
  // 旋转 30 度让文字变倾斜
  ctx.rotate((Math.PI / 180) * -30);
  ctx.fillStyle = type === 'dark' ? 'rgba(128, 121, 151, 0.1)' : 'rgba(255, 255, 255, 0.1)';
  ctx.font = font;
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  // 将文字画出来
  ctx.fillText(nickname, 0, 0);
  ctx.fillText(userId, 0, 25);
  return {
    base64: canvas.toDataURL(),
    size: canvasSize,
    styleSize: canvasSize,
  };
};

export default addWatermark;
