import { fromJS } from 'immutable';
import IssueRest from 'utils/IssueRest';
import { createAction, handleActions } from 'redux-actions';
import { isInArray } from 'utils/helper';
import { isNotNullish } from 'utils/nullish';

// 初始化state
const initialSymbolState = fromJS({
  symbolInfo: false,
  uploadingId: '',
  uploadPercent: 0,
  symbolTips: '',
});

// 改变正在上传中的input id
export const changeUploadingId = createAction('CHANGE_UPLOADING_ID');

// 改变正在上传中的进度条数值
export const changeUploadPercent = createAction('CHANGE_UPLOAD_PERCENT');

// 改变符号表页面提示
export const changeSymbolTips = createAction('CHANGE_SYMBOL_TIPS');

/**
 * 忽略so符号表
 * @returns {Function}
 */
export function ignoreSymbol(soName, uuid = '-1', arch, operateType) {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    const { userId } = getState().user.get('current').toJS();
    return dispatch({
      rest: IssueRest.ignoreSymbol.post,
      data: {
        appId, pid, soName, uuid, arch, operateType, operateUser: userId,
      },
    });
  };
}

export default handleActions({
  CHANGE_UPLOADING_ID: (state, action) => state.set('uploadingId', action.payload),

  CHANGE_UPLOAD_PERCENT: (state, action) => state.set('uploadPercent', action.payload),

  CHANGE_SYMBOL_TIPS: (state, action) => {
    const oldTips = state.get('symbolTips');
    if (isInArray(['0', '40403', '40401', '40404', '40405', '40406', '40407', '40409', '40410', '40411', '40412', '40413'], oldTips)
    && isInArray(['no_symbol', 'no_all_symbol'], action.payload)) {
      return state;
    }
    if (isNotNullish(action.payload) && action.payload !== oldTips) {
      return state.set('symbolTips', action.payload);
    }
    return state;
  },

}, initialSymbolState);
