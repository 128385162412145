import React, { useState } from 'react';
import { Button, Table } from 'antd';
import { ze } from 'utils/zhEn';
import { makeDetailObjectFromStackLine } from 'utils/helper';
import scss from './CrashDetailDisplay.scss'

const TABLE_CELL_STYLE = {
  fontSize: '13px',
  padding: '0 8px 0 0',
  fontFamily: 'Consolas, Menlo, Monaco, “DejaVu Sans Mono”, “Courier New”, monospace',
};

const CrashDetailDisplay = ({ stackTrace, platformId, currentReportExceptionType }) => {
  if (!stackTrace) return '-';

  const [expanded, setExpanded] = useState(false);
  const stackLines = stackTrace.split('\n');
  const parsedLines = stackLines.map((stackLine, i) => {
    return makeDetailObjectFromStackLine(stackLine, currentReportExceptionType, platformId);
  }).filter(x => x.line);

  const initialDisplayLineCount = 2;
  const [displayLines, setDisplayLines] = useState(initialDisplayLineCount);

  const toggleDisplay = () => {
    const newDisplayLines = expanded ? initialDisplayLineCount : parsedLines.length;
    setDisplayLines(newDisplayLines);
    setExpanded(prevExpanded => !prevExpanded);
  };

  // 定义表格列的配置
  const columns = [
    {
      dataIndex: 'lineNumber',
      key: 'lineNumber',
      onCell: (record, i) => {
        return {
          style: {
            ...TABLE_CELL_STYLE,
            minWidth: '16px',
          },
        };
      },
    },
    {
      dataIndex: 'libname',
      key: 'libname',
      onCell: (record, i) => {
        return {
          colSpan: record.isAnalyzed ? 1 : 3,
          style: TABLE_CELL_STYLE,
        };
      },
    },
    {
      dataIndex: 'hexadecimal',
      key: 'hexadecimal',
      render: (text) => <div style={{whiteSpace:'nowrap'}}>{text}</div>,
      onCell: (record, i) => {
        return {
          style: TABLE_CELL_STYLE,
        };
      },
    },
    {
      dataIndex: 'stackContent',
      key: 'stackContent',
      onCell: (record, i) => {
        return {
          style: TABLE_CELL_STYLE,
        };
      },
    },
  ];

  // 将数据转换成表格所需的格式
  const tableData = parsedLines.map((line, index) => {
    const isAnalyzed = !!line.libname;
    return {
      key: index,
      isAnalyzed,
      lineNumber: index + 1,
      libname: isAnalyzed ? line.libname : (line.line || line),
      hexadecimal: line.hexadecimal,
      stackContent: line.stackContent,
    };
  });

  return (
    <div className={scss.CrashDetailDisplay}>
      <Table
        columns={columns}
        dataSource={tableData.slice(0, displayLines)}
        pagination={false} // 禁用分页
        showHeader={false}
        bordered={false} // 去掉边框
        style={{ border: 'none' }} // 清空边框样式
      />
      {tableData.length > 2 && (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={toggleDisplay} type="link">
            {expanded ? ze('收起', 'Collapse') : ze('展开', 'Expand')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default CrashDetailDisplay;
