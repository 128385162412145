import moment from 'moment';
import React from 'react';
import i18n from 'i18n.js';
import { CS_STYLES } from 'utils/constants/style-constants';
import { ze } from 'utils/zhEn';
import { VmTypeInt } from 'utils/constants/vm-type';
import { DocsUtil } from 'utils/docs-util';
import { IssueStatus } from 'utils/constants/issue-status';

/**
 *
 *  程序中使用的所有常量
 *
 */
export const DEFAULT_HEAD_ICON_URL = '';

export const WXWORK_BOT_LAUNCH_URL = 'wxwork://message?uin=8444251012323176';

export const DEFAULT_EXCEPTION_TYPE_LIST = 'Crash,Native,ExtensionCrash';// ios 去掉 native 安卓 没有ExtensionCrash

export const DEFAULT_EXCEPTION_TYPE_LIST_IOS = 'Crash,ExtensionCrash';

export const DEFAULT_EXCEPTION_TYPE_LIST_ANDROID = 'Crash,Native';

export const PLATFORM_ID = {
  ANDROID: 1,
  IOS: 2,
  MAC: 4,
  PC: 10,
  PS4: 11,
  XBOX: 12,
  SWITCH: 13,
  LINUX: 20,
  WECHAT_MINI_PROGRAM: 32,
  HARMONY: 40,
};

export const PLATFORM_MAP = {
  1: 'Android',
  2: 'iOS',
  4: 'MAC',
  10: 'PC',
  11: 'PS4/PS5',
  12: 'XBOX',
  13: 'SWITCH',
  20: 'LINUX',
  32: 'WECHAT_MINI_PROGRAM',
};

export const ROLE_NAME_TO_ID = Object.freeze({
  ADMIN: 1,
  MEMBER: 2,
  STATS_VIEWER: 4,
});

export const EXCEPTION_TYPE_LIST = {
  crash: DEFAULT_EXCEPTION_TYPE_LIST,
  anr: 'ANR',
  error: 'AllCatched,Unity3D,Lua,JS',
  crashes: DEFAULT_EXCEPTION_TYPE_LIST,
  blocks: 'ANR',
  errors: 'AllCatched,Unity3D,Lua,JS',
};

export const ANDROID_EXCEPTION_TYPE_LIST_OPTIONS = [
  { label: i18n.t('exceptionTypeOptions.所有类型'), value: DEFAULT_EXCEPTION_TYPE_LIST_ANDROID },
  { label: i18n.t('exceptionTypeOptions.Java崩溃'), value: 'Crash', shortLabel: 'Java' },
  { label: i18n.t('exceptionTypeOptions.Native崩溃'), value: 'Native', shortLabel: 'Native' },
];

export const IOS_EXCEPTION_TYPE_LIST_OPTIONS = [
  { label: i18n.t('exceptionTypeOptions.所有类型'), value: DEFAULT_EXCEPTION_TYPE_LIST_IOS },
  { label: i18n.t('exceptionTypeOptions.App崩溃'), value: 'Crash', shortLabel: 'App' },
  { label: i18n.t('exceptionTypeOptions.Extension崩溃'), value: 'ExtensionCrash', shortLabel: 'Extension' },
];

export const ANR_EXCEPTION_TYPE_LIST_OPTIONS = [
  { label: 'ANR', value: 'ANR' },
];

export const ERROR_EXCEPTION_TYPE_LIST_OPTIONS = [
  { label: i18n.t('exceptionTypeOptions.所有类型'), value: EXCEPTION_TYPE_LIST.error },
  { label: i18n.t('exceptionTypeOptions.自定义错误'), value: 'AllCatched', shortLabel: i18n.t('exceptionTypeOptions.自定义') },
  { label: i18n.t('exceptionTypeOptions.CSharp脚本错误'), value: 'Unity3D', shortLabel: 'C#' },
  { label: i18n.t('exceptionTypeOptions.Lua脚本错误'), value: 'Lua', shortLabel: 'Lua' },
  { label: i18n.t('exceptionTypeOptions.JS脚本错误'), value: 'JS', shortLabel: 'JS' },
];

export const ERROR_EXCEPTION_TYPE_LIST_CUSTOM_ERROR_OPTIONS = new Array(10).fill(0)
  .map((_, i) => {
    const n = i + 1;
    return {
      value: `CustomError${n}`,
      label: ze(`用户自定义错误${n}`, `User Custom Error ${n}`),
      shortLabel: ze(`自定义${n}`, `Custom ${n}`),
    };
  });

/**
 * 高级搜索所有异常类型
 */
export const SEARCH_EXCEPTION_TYPE_TO_OPTIONS = {
  [PLATFORM_ID.ANDROID]: [
    { label: i18n.t('exceptionTypeOptions.所有类型'), value: '' },
    { label: i18n.t('exceptionTypeOptions.Java崩溃'), value: 'Crash' },
    { label: i18n.t('exceptionTypeOptions.Native崩溃'), value: 'Native' },
    { label: i18n.t('exceptionTypeOptions.ANR'), value: 'ANR' },
    { label: i18n.t('exceptionTypeOptions.自定义错误'), value: 'AllCatched' },
    { label: i18n.t('exceptionTypeOptions.CSharp脚本错误'), value: 'Unity3D' },
    { label: i18n.t('exceptionTypeOptions.Lua脚本错误'), value: 'Lua' },
    { label: i18n.t('exceptionTypeOptions.JS脚本错误'), value: 'JS' },
  ],
  [PLATFORM_ID.IOS]: [
    { label: i18n.t('exceptionTypeOptions.所有类型'), value: '' },
    { label: i18n.t('exceptionTypeOptions.App崩溃'), value: 'Crash' },
    { label: i18n.t('exceptionTypeOptions.Extension崩溃'), value: 'ExtensionCrash' },
    { label: i18n.t('exceptionTypeOptions.自定义错误'), value: 'AllCatched' },
    { label: i18n.t('exceptionTypeOptions.CSharp脚本错误'), value: 'Unity3D' },
    { label: i18n.t('exceptionTypeOptions.Lua脚本错误'), value: 'Lua' },
    { label: i18n.t('exceptionTypeOptions.JS脚本错误'), value: 'JS' },
  ],
  [PLATFORM_ID.PC]: [
    { label: i18n.t('exceptionTypeOptions.所有类型'), value: '' },
    { label: i18n.t('exceptionTypeOptions.崩溃'), value: 'Native' },
    { label: i18n.t('exceptionTypeOptions.错误'), value: 'AllCatched' },
  ],
  [PLATFORM_ID.SWITCH]: [
    { label: i18n.t('exceptionTypeOptions.所有类型'), value: '' },
    { label: i18n.t('exceptionTypeOptions.崩溃'), value: 'Native' },
    { label: i18n.t('exceptionTypeOptions.错误'), value: 'AllCatched' },
  ],
  [PLATFORM_ID.PS4]: [
    { label: i18n.t('exceptionTypeOptions.所有类型'), value: '' },
    { label: i18n.t('exceptionTypeOptions.崩溃'), value: 'Native' },
    { label: i18n.t('exceptionTypeOptions.错误'), value: 'AllCatched' },
  ],
  [PLATFORM_ID.XBOX]: [
    { label: i18n.t('exceptionTypeOptions.所有类型'), value: '' },
    { label: i18n.t('exceptionTypeOptions.崩溃'), value: 'Native' },
    { label: i18n.t('exceptionTypeOptions.错误'), value: 'AllCatched' },
  ],
  OTHER_PLATFORM: [
    { label: i18n.t('exceptionTypeOptions.所有类型'), value: '' },
  ],
};

export const logOps = [
  { label: i18n.t('LASTREPORT.系统日志'), value: 'sysLog' },
  { label: i18n.t('LASTREPORT.自定义日志'), value: 'userLog' },
];

export const SYSTEM_LOG_PLATFORM_ID_TO_DESC = {
  [PLATFORM_ID.ANDROID]: ze('数据来源是Android的Logcat。', 'The data source is Android\'s Logcat.'),
  [PLATFORM_ID.IOS]: ze(
    'iOS平台可以基于NSLog获取System Log，但是由于NSLog的获取会有性能影响，因此客户端SDK默认关闭NSLog的获取，如有需要打开，联系我们。',
    'On the iOS platform, CrashSight can obtain system logs based on NSLog. However, due to the performance impact of NSLog, the client SDK disables the acquisition of NSLog by default. If you need to enable this feature, please contact us.',
  ),
  [PLATFORM_ID.PC]: ze('由于Windows系统机制的原因，Windows系统没有提供系统日志。', 'Due to the mechanisms of the Windows system, it does not provide a system log.'),
  [PLATFORM_ID.LINUX]: ze('Linux SDK目前暂不支持采集系统日志。', 'Currently CrashSight does not support obtaining Linux system log.'),
};

export const levelOps = [
  { label: 'Verbose', value: 'verbose' },
  { label: 'Debug', value: 'debug' },
  { label: 'Info', value: 'info' },
  { label: 'Warn', value: 'warn' },
  { label: 'Error', value: 'error' },
];

export const levelOpsPc = [
  { label: 'Verbose', value: 'verbose' },
  { label: 'Debug', value: 'debug' },
  { label: 'Info', value: 'info' },
  { label: 'Warning', value: 'warn' },
  { label: 'Assert', value: 'assert' },
  { label: 'Error', value: 'error' },
  { label: 'Exception', value: 'exception' },
];

/*
原来是 _options, 以备后面出错时修正
*/
const DATE_LABEL_OPTIONS = [
  { label: '最近1小时', value: 'last_1_hour' },
  { label: '昨天', value: 'last_1_day' },
  { label: '今天', value: 'today' },
  { label: '最近1天', value: 'last_1_day' },
  { label: '最近2天', value: 'last_2_day' },
  { label: '最近7天', value: 'last_7_day' },
  { label: '最近15天', value: 'last_15_day' },
  { label: '最近2周', value: 'last_2_week' },
  { label: '最近1月', value: 'last_1_month' },
  { label: '最近30天', value: 'last_30_day' },
  { label: '最近60天', value: 'last_60_day' },
];

/**
 * 根据参数，获取label或者value
 */
export function getLabelOrValue(str) {
  let i = 0;
  const l = DATE_LABEL_OPTIONS.length;
  for (; i < l; i++) {
    if (str.indexOf(DATE_LABEL_OPTIONS[i].label) > -1) {
      return DATE_LABEL_OPTIONS[i].value;
    } if (str.indexOf(DATE_LABEL_OPTIONS[i].value) > -1) {
      return DATE_LABEL_OPTIONS[i].label;
    }
  }
  return '';
}

export const DATE_MAP_VALUE = {
  last_1_hour: '最近1小时',
  today: '今天',
  last_1_day: '最近1天',
  last_2_day: '最近2天',
  last_7_day: '最近7天',
  last_15_day: '最近15天',
  last_2_week: '最近2周',
  last_1_month: '最近1月',
  last_30_day: '最近30天',
  last_60_day: '最近60天',
  custom: '自定义时间段',
};

// 错误类型
export const EXCEPTION_ERROR_TYPE = {
  100: 'crash', // JavaCrash
  200: 'crash', // JavaCrash 合并
  101: 'crash', // NativeCrash
  201: 'crash', // NativeCrash 合并
  105: 'crash', // Ext
  205: 'crash', // Ext 合并
  103: 'anr', // 但是安卓的话,是叫stack
  203: 'anr',
  102: 'error', // JavaCatched
  202: 'error', // JavaCatched 合并
  104: 'error', // Unity 3D
  204: 'error', // Unity 3D 合并
  106: 'error', // Lua
  206: 'error', // Lua 合并
  107: 'error', // JS
  207: 'error', // JS 合并
};

// crashList列表搜索的下拉列表
/** @deprecated */
export const CRASH_SEARCH_TYPE = {
  options: [
    { label: '问题ID', value: 'issueId' },
    { label: '上报ID', value: 'crashId' },
    { label: '出错堆栈', value: 'detail' },
    { label: '异常名称', value: 'errorType' },
    { label: '用户标识', value: 'contectInfo' },
    { label: '设备', value: 'hardware' },
    { value: 'deviceId' },
    { label: '系统版本', value: 'osVersion' },
    { value: 'crashExpMessage' },
    { label: '出错进程', value: 'processName' },
    { label: '出错线程', value: 'threadName' },
    // { label: '异常标签', value: 'crashTag' },
    { label: 'Key(Value)', value: 'keyValue' },
  ].map(x => ({
    value: x.value,
    label: i18n.t(`SENIORSEARCHTYPE.${x.value}`),
  })),
};

/**
 * 时间选项
 */
export const DATE_OPTIONS = { // 所有上报时间
  options: [
    { label: '所有上报时间', value: 'all' },
    { label: '最近1小时', value: 'last_1_hour' },
    { label: '昨天', value: 'last_1_day' },
    { label: '今天', value: 'today' },
    { label: '最近2天', value: 'last_2_day' },
    { label: '最近7天', value: 'last_7_day' },
    { label: '最近15天', value: 'last_15_day' },
    { label: '最近2周', value: 'last_2_week' },
    { label: '最近一个月', value: 'last_1_month' },
    { label: '自定义时间段', value: 'autoDate' },
  ],
};

export const ISSUE_STATUS_VALUE_TO_LABEL = {
  '0': i18n.t('statusOptions.未处理'),
  '1': i18n.t('statusOptions.已处理'),
  '2': i18n.t('statusOptions.处理中'),
  [IssueStatus.REOPENED]: i18n.t('statusOptions.重新打开'),
};

/**
 * 折线图颜色
 */
export const LINE_COLOR = [CS_STYLES.PRIMARY_COLOR, '#BA7ED3', '#ff8d13', '#dd4746', '#6cb132', '#4e7181'];


// 表单验证错误提示语
export const FORM_ERROR_MSG = {
  nickname_info: '昵称不能为空',
  phone_info: '手机号码格式不正确',
  email_info: '邮箱格式不正确',
  city_info: '请填写省份和城市',
  idCard_info: '身份证格式不正确',
};

// 中文的页面类型转换英文的数据类型
export const PAGE_TYPE_CONVERT = {
  崩溃: 'crash',
  卡顿: 'anr',
  ANR: 'anr',
  错误: 'error',
  crash: '崩溃',
  anr: '卡顿',
  error: '错误',
  oom: 'oom',
  CRASH: 'crash',
  ERROR: 'error',
  OOM: 'oom',
};

// 符号表上传的提示信息
export const IOS_SYMBOL_TOOL = DocsUtil.makeDocsUrl('/symbolsDocuments/symbol-table');
export const ANDROID_SYMBOL_TOOL = DocsUtil.makeDocsUrl('/symbolsDocuments/symbol-table');
export const SYMBOL_TIPS = {
  no_symbol: { tips: 'SYMBOLTABLE.noSymbolTip1', color: 'red' },
  no_all_symbol: { tips: 'SYMBOLTABLE.noAllSymbolTip', color: 'red' },
  unknown: { tips: 'SYMBOLTABLE.unknownSymbolTip', color: 'yellow' },
  40403: { tips: 'SYMBOLTABLE.40403Tip', color: 'yellow' },
  40404: { tips: 'SYMBOLTABLE.40404Tip', color: 'yellow' },
  40401: { tips: 'SYMBOLTABLE.40401Tip', color: 'yellow' },
  40405: { tips: 'SYMBOLTABLE.40405Tip', color: 'yellow' },
  40406: { tips: 'SYMBOLTABLE.40406Tip', color: 'yellow' },
  40407: { tips: 'SYMBOLTABLE.40407Tip', color: 'yellow' },
  40409: { tips: 'SYMBOLTABLE.40409Tip', color: 'yellow' },
  40410: { tips: 'SYMBOLTABLE.40410Tip', color: 'yellow' },
  40411: { tips: 'SYMBOLTABLE.40411Tip', color: 'yellow' },
  40412: {
    tips: (<div>
抱歉，您使用的符号表工具过低，请
      <a href={IOS_SYMBOL_TOOL} className="color_blue">更新符号表工具</a>
后，重新生成符号表文件上传；或者直接上传dSYM文件的压缩包
           </div>),
    color: 'yellow',
  },
  40413: {
    tips: (<div>
抱歉，您使用的符号表工具过低，请
      <a href={ANDROID_SYMBOL_TOOL} className="color_blue">更新符号表工具</a>
后，重新生成符号表文件上传；或者直接上传SO文件压缩包
           </div>),
    color: 'yellow',
  },
  40416: { tips: 'SYMBOLTABLE.40416Tip', color: 'yellow' },
  upload_fail: { tips: 'SYMBOLTABLE.uploadSymbolFail', color: 'red' },
  0: { tips: 'SYMBOLTABLE.0Tip', color: 'yellow' },
  50500: { tips: 'SYMBOLTABLE.50500Tip', color: 'red' },
  50501: { tips: 'SYMBOLTABLE.50501Tip', color: 'red' },
  50502: { tips: 'SYMBOLTABLE.50502Tip', color: 'red' },
};

export const ERR_NO_PERMISSION = 'ERR_NO_PERMISSION'; // 没有操作该产品的权限
export const ERR_TOKEN_EXPIRED = 'ERR_TOKEN_EXPIRED'; // 用户token已经过期
export const ERR_MSG = {
  [ERR_NO_PERMISSION]: '没有操作该产品的权限! ',
  [ERR_TOKEN_EXPIRED]: '你的认证信息已经过期, 点击确定按钮后更新认证。',
};

// leftmenu
export const LEFTMENU_STATE = 'leftmenu';

// 成员的类型
export const MEMBER_TYPE_KEY = {
  admin: 'admin',
  normal: 'normal',
  operator: 'operator',
  statsViewer: 'statsViewer',
  complianceAdmin: 'complianceAdmin',
  complianceMember: 'complianceMember',
};

export const MEMBER_MSG = {
  normal: 'MEMBERMANAGE.memberDeleteMsgNormal',
  delete: 'MEMBERMANAGE.memberDeleteMsgDelete',
  update: '您是该产品的唯一管理员，为了保证产品的正常使用，请您先设置一位管理员后，再设置自己为普通成员。',
  out: '您确定要退出该产品吗？',
  onlyOne: '退出产品后，您将无法查看该产品在CrashSight上的崩溃信息，确定退出产品？',
  forbidden: '您是该产品的唯一管理员，为了保证产品的正常使用，请您在退出产品前先设置一位管理员。',
  exit: 'WORKBENCH.quitModalMsg',
};

// 异常概览的headDataType中英转换
export const EXCEPTION_HEAD_DATA_TYPE = {
  // userRate: '今日用户异常率',
  // numRate: '今日次数异常率',
  // crashNum: '发生次数',
  // crashUser: '影响用户数',
  userRate: 'EXCP_OVERVIEW.crashUserToday',
  numRate: 'EXCP_OVERVIEW.crashNumToday',
  crashNum: 'EXCP_OVERVIEW.occurNum',
  crashUser: 'EXCP_OVERVIEW.userNum',
};

// 异常概览下载按日崩溃数据的次数崩溃率
export const EXCEPTION_HEAD_DATA_TYPE_DOWNLOAD = {
  崩溃: 'EXCP_OVERVIEW.occurNumCrashRate',
  ANR: 'EXCP_OVERVIEW.occurNumANRRate',
  卡顿: 'EXCP_OVERVIEW.occurNumANRRate',
  错误: 'EXCP_OVERVIEW.occurNumErrorRate',
};

export const PAGE_TYPE_SHOW = {
  崩溃: 'EXCP_OVERVIEW.crash',
  crash: 'EXCP_OVERVIEW.crash',
  crashes: 'EXCP_OVERVIEW.crash',
  卡顿: 'EXCP_OVERVIEW.jank',
  anr: 'EXCP_OVERVIEW.jank',
  blocks: 'EXCP_OVERVIEW.jank',
  ANR: 'EXCP_OVERVIEW.anr',
  错误: 'EXCP_OVERVIEW.error',
  error: 'EXCP_OVERVIEW.error',
  errors: 'EXCP_OVERVIEW.error',
};

export const AUTH_CODE = {
  SUCCESS: 0,
  NETWORK_ERROR: -1,
  UNKNOWN_PLATFORM: -2,
  APPID_IS_NOT_EXIST: -3,
  URL_ILLEGAL: -4,
  HAS_NO_RIGHT: -401,
};

/**
 * 获取最近n天的日期
 */
export function getLastNDay(n, originFormat) {
  const format = originFormat || 'YYYY-MM-DD';
  return moment().add(0 - n, 'days')
    .format(format);
}

// datadetail 中的对应的文件
export const FILE_OPTIONS = {
  anrMessage: {
    filename: 'anrMessage.txt',
    msg: ze('暂无ANR Message信息', 'No ANR Message'),
  },
  anrTrace: {
    filename: 'trace.zip',
    value: 'anrTrace',
    msg: ze('暂无ANR Trace信息', 'No ANR Trace'),
  },
  otherMsg: {
    filename: 'log.txt',
    msg: i18n.t('LASTREPORT.暂无信息'),
  },
  崩溃线程寄存器信息IOS: {
    filename: 'log.txt',
    value: '崩溃线程寄存器信息',
    msg: i18n.t('LASTREPORT.暂无信息'),
  },
  加载模块信息IOS: {
    filename: 'log.txt',
    value: '加载模块信息',
    msg: i18n.t('LASTREPORT.暂无信息'),
  },
  crashInfos: {
    filename: 'crashInfos.txt',
    msg: i18n.t('LASTREPORT.暂无其他信息'),
  },
  崩溃线程寄存器信息: {
    filename: 'crashInfos.txt',
    value: '崩溃线程寄存器信息',
    msg: i18n.t('LASTREPORT.暂无信息'),
  },
  加载模块信息: {
    filename: 'crashInfos.txt',
    value: '加载模块信息',
    msg: i18n.t('LASTREPORT.暂无信息'),
  },
  FD信息: {
    value: 'FD信息',
    msg: i18n.t('LASTREPORT.暂无信息'),
  },
  consolelog: {
    filename: 'consolelog.txt',
    msg: '暂无控制台信息',
  },
  meminfo: {
    filename: 'meminfo.txt',
    msg: '暂无内存日志信息',
  },
};

// 解决方案的tab
export const SOLUTION_ARR = ['我的解决方案', '系统推荐方案'];

// sdk 判断是否已有数据
/**
 * @param dataList {Map}
 * @param version {String}
 * @return {boolean}
 */
export function hasDataNeedLoad(dataList, version) {
  if (dataList.size > 0) {
    const versionArr = version.split(',');
    if (versionArr.length > 6) return false;
    for (let i = 0, l = versionArr.length; i < l; i++) {
      if (!dataList.has(versionArr[i])) {
        return true;
      }
    }
    return false;
  }
  return true;
}
export const MODULE_ID = 14000;
export const ACTIONIDS = {
  REGISTER_BTN: 14003,
  REGISTER_SUCC: 14004,
  CRASH: 14005,
  OPERATION: 14006,
  BETA: 14007,
  UPGRADE: 14008,
};
export const MODULE_PRODUCT_SUMMARY_ID = 16000;
export const ACTIONS_PRODUCT_SUMMARY = {
  COMPARE_VERSION: 16001,
  COMPARE_DATE: 16002,
};

export function injectAppInfo(fn) {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();

    // 不能把dispatch传递过去.
    // 因为fn返回回来的必须是一个action(包括type或者是通过能够被middleware处理的函数)
    // 而如果你把dispatch传递过去了, 调用injectAppInfo的地方最终执行了dispatch(...)逻辑, 会导致异步封装的事件
    // 如fetchCached/fetchDroped处理后返回的Promise被多dispatch一次, 因为报错
    // 如果你需要需要传递dispatch的情况, 请用下面的injectAppInfo2.
    const finalFn = fn({
      appId, pid, getState,
    });
    if (typeof finalFn.then === 'function') {
      return finalFn;
    }
    return dispatch(finalFn);
  };
}

export const ILLEGAL_REG = /[\s…￥·`，【】‘；’。、《》—<>（）\\=\]\^\|'"\.#\+\?,\*&%$!@\{\}\(\)\[\/:;]/;

export const SDK_TYPE_APP = 40;

export const REQUEST_PARAMS_ERROR = 100004;
export const REQUEST_PARAMS_ERROR_TIPS = '你的搜索参数有误，请检查后重试。';

export const IssueListTrendTypeEnum = Object.freeze({
  NONE: 'NONE', // 隐藏最近问题趋势
  HOURLY: 'HOURLY', // 最近24小时
  DAILY: 'DAILY', // 最近14天
});

export const IssueTrendGranularityUnit = Object.freeze({
  DAY: 'DAY',
  HOUR: 'HOUR',
  MINUTE: 'MINUTE',
});

export const TapdBugStatusOptions = Object.freeze([
  { label: i18n.t('tapdBugStatusOptions.所有TAPD缺陷关联状态'), value: 'all' },
  { label: i18n.t('tapdBugStatusOptions.已关联TAPD缺陷'), value: 'REPORTED' },
  { label: i18n.t('tapdBugStatusOptions.未关联TAPD缺陷'), value: 'UNREPORTED' },
]);

/** 大盘统计用的vm选项 */
export const SummaryVmOptions = Object.freeze([
  { label: i18n.t('EXCP_OVERVIEW.全量模拟器加真机'), value: 0 },
  { label: i18n.t('EXCP_OVERVIEW.仅真机'), value: 1 },
  { label: i18n.t('EXCP_OVERVIEW.仅模拟器'), value: 2 },
]);

/** 大盘统计用的vm选项 */
export const SummaryVmTypeMultipleSelectOptions = Object.freeze([
  { label: i18n.t('REPORTDETAIL.真机'), value: VmTypeInt.REAL_DEVICE },
  { label: i18n.t('REPORTDETAIL.模拟器'), value: VmTypeInt.EMULATOR },
  { label: i18n.t('REPORTDETAIL.云游戏'), value: VmTypeInt.CLOUD_GAMING },
]);

/** 全部版本的值 */
export const ALL_VERSION = '-1';
