import React, { Component,createRef } from 'react';
import PropTypes from 'prop-types';
import { IconTip } from 'components/commons';
import { Popover } from 'antd';
import classNames from 'classnames';
import _style from './style.scss';
import { withTranslation } from 'react-i18next'
import SearchIcon from 'svg/v2/newcs_dashboard_crashanalysis_issuedetail_tracelog_filt_search_icon.svg';

@withTranslation()
export default class SearchBox extends Component {
  static defaultProps = {
    style: {},
  };

  constructor(props) {
    super(props);
    this.inputRef = createRef();
    this.handleChangeSearchType = this.handleChangeSearchType.bind(this);

    this.state = {
      searchText: props.defaultSearch,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.defaultSearch !== this.props.defaultSearch) {
      this.setState({ searchText: nextProps.defaultSearch });
    }
  }

  handleChangeInput(event) {
    event.stopPropagation();
    const { handleSearchInput } = this.props;
    const { value } = event.target;
    this.setState({ searchText: value });
    handleSearchInput && handleSearchInput(event.target.value);
  }

  onBlur(event) {
    const { onSearchBlur } = this.props;
    onSearchBlur && onSearchBlur(event);
  }

  handleChangeSearchType(e) {
    const { handleSearchType } = this.props;
    handleSearchType && handleSearchType(e);
  }

  handleKeyPress(e) {
    const { handleKeyPress,handleEnterPress } = this.props;
    handleKeyPress && handleKeyPress(e, this.state.searchText);

    if (e && e.keyCode == 13) {
      handleEnterPress && handleEnterPress(e, this.state.searchText);
    }
  }

  renderPopoverTrigger() {
    const content = (
      <div>
        <p>{ this.props.t("SENIORSEARCH.searchTip1") }</p>
        <p>{ this.props.t("SENIORSEARCH.searchTip2") }</p>
        <p>{ this.props.t("SENIORSEARCH.searchTip3") }</p>
        <p>{ this.props.t("SENIORSEARCH.searchTip4") }</p>
        <p>{ this.props.t("SENIORSEARCH.searchTip5") }</p>
      </div>
    );

    return (
      <Popover content={content} placement='bottom'>
        <div className={_style.popover}><IconTip /></div>
      </Popover>
    )
  }

  handleIconClick = () => {
    const { handleIconClick } = this.props;
    handleIconClick && handleIconClick(this.state.searchText);
  }

  render() {
    const {
      children, width, defaultSearchType, placeholder, isNeedBtn, handleSearch, style,
    } = this.props;
    const { searchText } = this.state;

    return (
      <div className={classNames(_style.search_box, style.search_box)}>
        <div
          className={classNames(_style.ui_search, style.ui_search)}
          style={width ? { width: `${width}px` } : {}}>
          <i className="icon-search" onClick={()=>{this.handleIconClick()}}>{<SearchIcon />}</i>
          <input
            type="search"
            ref={this.inputRef}
            value={searchText}
            placeholder={placeholder}
            onChange={(event) => this.handleChangeInput(event)}
            onBlur={(event) => this.onBlur(event)}
            onKeyUp={(event) => this.handleKeyPress(event)} />
          {defaultSearchType === 'detail' && this.renderPopoverTrigger()}
        </div>
        {children}
        {
          isNeedBtn && <a className={`btn btn_blue ${_style.btn_search}`} onClick={(e) => { e.preventDefault(); handleSearch && handleSearch(); }}>{this.props.t("SENIORSEARCH.search")}</a>
        }

      </div>
    );
  }
}

SearchBox.propTypes = {
  handleSearchType: PropTypes.func,
  handleKeyPress: PropTypes.func,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  defaultSearch: PropTypes.string,
  handleSearchInput: PropTypes.func,
  onSearchBlur: PropTypes.func,
  handleSearch: PropTypes.func,
  children: PropTypes.object,
  width: PropTypes.string,
  options: PropTypes.object,
  defaultSearchType: PropTypes.any,
  isNeedBtn: PropTypes.any,
};
