import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Divider, Space, Tooltip } from 'antd';
import { SummaryVmTypeMultipleSelectOptions } from 'utils/constants';
import { ze } from 'utils/zhEn';
import { VmTypeInt } from 'utils/constants/vm-type';
import { useTranslation } from 'react-i18next';
import { EnvUtil } from 'utils/env-util';
import reportEvent, { EVENT_ACTIONS } from 'utils/reportEvent';
import WrappedTipsIcon from 'components/antd-extension/WrappedTipsIcon.jsx';


const VmTypeCheckboxGroup = (props) => {
  const {
    value = [],
    onChange = (v) => {},
  } = props;
  const { t } = useTranslation();

  const isAllChecked = value.some(x => x === VmTypeInt.ALL);
  const valueWithoutAll = value.filter(x => x !== VmTypeInt.ALL);

  const options = useMemo(() => {
    if (!EnvUtil.isChinaEnv()) { // 目前只有国内支持云游戏维度
      return SummaryVmTypeMultipleSelectOptions.filter(x => x.value !== VmTypeInt.CLOUD_GAMING);
    }

    return SummaryVmTypeMultipleSelectOptions.map((x) => {
      if (x.value === VmTypeInt.CLOUD_GAMING) {
        return {
          ...x,
          label: <div style={{display:'flex',alignItems:'center'}}>
            <div>{ t('REPORTDETAIL.云游戏') }</div>
            <Tooltip
              overlayStyle={{ maxWidth: '1000px' }}
              title={ze('自1月30日起，云游戏设备独立于模拟器进行统计。在该日期之前，云游戏设备的数据包含在模拟器统计数据中。', 'Starting from January 30th, cloud gaming devices are counted separately from emulators. Prior to this date, data for cloud gaming devices was included in emulator statistics.')}
          >
            <WrappedTipsIcon />
          </Tooltip>
          </div>
        }
      } else {
        return x;
      }
    });
  }, []);

  return <Space size={0}>
    <Checkbox
      checked={isAllChecked}
      onChange={(e) => {
        // 点击全量，进行上报
        if (e.target.checked) {
          reportEvent({
            action: EVENT_ACTIONS.CLICK,
            tp1: `异常概览-${ze('全量', 'All')}`,
          })
        }
        onChange([VmTypeInt.ALL]);
      }}
    >{ ze('全量', 'All') }</Checkbox>
    <Divider type='vertical'/>
    <Checkbox.Group
      value={valueWithoutAll}
      options={options}
      onChange={(v) => {
        // 点击其他类型，进行上报
        const newValue = v.filter(x => !valueWithoutAll.includes(x));
        if (newValue.length) {
          const newType = SummaryVmTypeMultipleSelectOptions.find(item => item.value === newValue[0]);
          reportEvent({
            action: EVENT_ACTIONS.CLICK,
            tp1: `异常概览-${newType.label}`,
          })
        }

        if (v.length >= SummaryVmTypeMultipleSelectOptions.length) {
          onChange([VmTypeInt.ALL]);
        } else {
          onChange(v);
        }
      }}
    />
  </Space>;
};

VmTypeCheckboxGroup.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
};

export default VmTypeCheckboxGroup;
