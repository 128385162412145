import RestHelper from './RestHelper';

const userVersionRest = RestHelper.publish({
  getVersionTrend: {
    get: {
      method: 'get',
      uri: '/appId/{appId}/pid/{pid}/channel/{channel}/startDate/{startDate}/endDate/{endDate}/versionTrend',
    },
  },
});

export default userVersionRest;
