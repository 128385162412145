import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Divider, Space, Tooltip, Select } from 'antd';
import { SummaryVmTypeMultipleSelectOptions } from 'utils/constants';
import { ze } from 'utils/zhEn';
import { VmTypeInt } from 'utils/constants/vm-type';
import { useTranslation } from 'react-i18next';
import { EnvUtil } from 'utils/env-util';
import reportEvent, { EVENT_ACTIONS } from 'utils/reportEvent';
import { CsSelect } from 'components/antd-extension/CsSelect';
import WrappedTipsIcon from 'components/antd-extension/WrappedTipsIcon.jsx';


const VmTypeSelect = (props) => {
  const {
    value = [],
    style = { width: '200px'},
    onChange = (v) => {},
  } = props;
  const { t } = useTranslation();

  const options = useMemo(() => {
    let list = [];
    if (!EnvUtil.isChinaEnv()) { // 目前只有国内支持云游戏维度
      list = SummaryVmTypeMultipleSelectOptions.filter(x => x.value !== VmTypeInt.CLOUD_GAMING);
    } else {
      list = SummaryVmTypeMultipleSelectOptions.map((x) => {
        if (x.value === VmTypeInt.CLOUD_GAMING) {
          return {
            ...x,
            label: <div style={{display:'flex',alignItems:'center'}}>
              <div style={{paddingRight: 4}}>{ t('REPORTDETAIL.云游戏') }</div>
              <Tooltip
                placement='right'
                overlayStyle={{ maxWidth: '600px' }}
                title={ze('自1月30日起，云游戏设备独立于模拟器进行统计。在该日期之前，云游戏设备的数据包含在模拟器统计数据中。', 'Starting from January 30th, cloud gaming devices are counted separately from emulators. Prior to this date, data for cloud gaming devices was included in emulator statistics.')}
              >
                <WrappedTipsIcon />
              </Tooltip>
            </div>
          }
        } else {
          return x;
        }
      });
    }

    list.unshift({
      label: ze('全量', 'All'),
      value: VmTypeInt.ALL,
    })

    return list;
  }, []);
  const valueWithoutAll = options.map(item => item.value).filter(x => x !== VmTypeInt.ALL);

  return <CsSelect
    mode="multiple"
    maxTagCount="responsive"
    style={style}
    value={value}
    options={options}
    showArrow={true}
    showSearch={false}
    onChange={v => {
      console.log('vvvv', v);
      const newValue = v.filter(item => !value.includes(item));

      let newRes = [];
      if (!newValue.length) {
        // 删除选项
        newRes = v.length ? v : [VmTypeInt.ALL];
      } else {
        if (newValue[0] === VmTypeInt.ALL) {
          // 选择全部
          newRes = [VmTypeInt.ALL]
        } else {
          // 过滤全部
          newRes = v.filter(item => ![VmTypeInt.ALL].includes(item))
        }
      }
      // 选择其他所有选项
      // if (newRes.join('-') === valueWithoutAll.join('-')) {
      //   newRes = [VmTypeInt.ALL];
      // }
      onChange(newRes)
    }}
  />;
};

VmTypeSelect.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
};

export default VmTypeSelect;
