import ftRest from 'utils/ftRest';
import globalRest from 'utils/globalRest';
import { getStartEndDateFromStr } from 'utils/StringUtils';
import { createAction } from 'redux-actions';
import { PAGE_TYPE_CONVERT } from 'utils/constants';

// 更新参数
export const changeSearchParam = createAction('CHANGE_SEARCH_PARAM');

// 更新详情的长度
export const updateListSize = createAction('UPDATE_FTLIST_SIZE');

// 更新页数
export const updatePageNum = createAction('UPDATE_PAGE_NUM');

// 更新state里面的值
export const updateStateStatus = createAction('UPDATE_STATE_STATUS');

// 获取版本信息
export function getOptions() {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    dispatch({
      rest: globalRest.getVersions.get,
      data: {
        appId,
        pid,
      },
    });
  };
}

// 获取趋势图,ft详情:list
export function getFtData(actionType) {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    const { chartSearchParams, listSearchParams } = getState().ftAnalyse.toJS();
    // 从ftAnalyse 中获取相关的搜索参数
    const {
      version, date, ft, pageType,
    } = chartSearchParams;
    const { detailVersion, detailDate } = listSearchParams;
    const tempVerison = (actionType === 'list' ? detailVersion : version);
    const dateObj = (actionType === 'list' ? getStartEndDateFromStr(detailDate.date, detailDate.startDate, detailDate.endDate) : getStartEndDateFromStr(date.date, date.startDate, date.endDate));
    return dispatch({
      rest: ftRest.ftTrend.get,
      data: {
        appId,
        platformId: pid,
        startDate: dateObj.startDate,
        endDate: actionType === 'list' ? dateObj.startDate : dateObj.endDate,
        type: PAGE_TYPE_CONVERT[pageType],
        version: tempVerison,
        ftNames: ft,
        actionType,
      },
    });
  };
}

function getAppIdAndPid(getState) {
  const currentApp = getState().app.get('current');
  return {
    appId: currentApp.get('appId'),
    pid: currentApp.get('pid'),
  };
}
function generateAction(rest) {
  return (params) => {
    return (dispatch, getState) => {
      const { pid, appId } = getAppIdAndPid(getState);
      return dispatch({
        rest,
        data: {
          appId,
          platformId: pid,
          ...params,
        },
      });
    };
  };
}

// 获取ft的信息
export const getFtInfo = generateAction(ftRest.ftInfo.get);

// 增加ft信息
export const addFtInfo = generateAction(ftRest.ftInfo.add);

// 编辑Ft信息
export const editFtInfo = generateAction(ftRest.ftInfo.edit);

// 删除Ft信息 ftId
export const deleteFtInfo = generateAction(ftRest.ftInfo.delete);

// 添加ft下的插件信息 ftId, pluginName, tagId, soName, bundleId, proccessorName, desc,
export const addFtPluginInfo = generateAction(ftRest.ftInfo.addFtPluginInfo);

// 修改ft下的插件信息 ftId, pluginId, pluginName, tagId, soName, bundleId, proccessorName, desc,
export const editFtPluginInfo = generateAction(ftRest.ftInfo.editFtPluginInfo);

// 删除ft下的插件信息 @param ftId, pluginId,
export const deleteFtPluginInfo = generateAction(ftRest.ftInfo.deleteFtPluginInfo);
