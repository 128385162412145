/**
 * Created by ellis on 24/11/2016.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { SearchBox } from 'components/commons';
import style from './style.scss';
import { useTranslation } from 'react-i18next';
import { DemoUtil } from 'utils/demo-util';
import { SymbolFileTypeEnum, SymbolFileTypeUtil } from 'utils/constants/symbol-file-type-enum';
import { ze } from 'utils/zhEn';
import { message, Select, Button } from 'antd';
import { isMobile } from 'utils/platform';
import IconManage from 'svg/v2/newcs_dashboard_crashanalysis_issuedetail_symbolfile_managebtn_icon.svg';
const PageHeader = ({
  demoApp,
  handleSearchInput, handleKeyPress, handleSelect, types, uploadHandler, status, searchValue, selectedType, platformId
}) => {
  const { t } = useTranslation();
  let value;
  switch (status) {
    case '0':
      value = t('SYMBOLTABLE.未配置');
      break;
    case '-1,1,2':
      value = t('SYMBOLTABLE.已配置');
      break;
    default:
      value = t('SYMBOLTABLE.全部');
  }
  const placeHolder = SymbolFileTypeUtil.isMapping(selectedType)
    ? ze('搜索版本', 'Search Version')
    : 'SYMBOLMANAGE.searchUUIDPlaceholder';
  return (
    <div className={style.page_header}>
      {/* <div className={style.select_box}> */}
        <Select
          style={{ width: '250px', fontSize: '14px' }}
          size='large'
          options={types.options}
          value={value}
          onChange={(data) => handleSelect(data)}
        />
        <div className={style.searchBoxShell}>
          <SearchBox
            placeholder={ t(placeHolder) }
            handleSearchInput={(data) => handleSearchInput(data)}
            handleEnterPress={(data) => handleKeyPress(data)}
            handleIconClick={(data) => handleKeyPress(data)}
            defaultSearch={searchValue} />
        </div>
      {/* </div> */}
      {isMobile(platformId) && <Button
        className={style.uploadBtn}
        type='primary'
        onClick={(e) => {
          if (DemoUtil.checkAndWarnIfDemoApp(demoApp)) {
            return;
          }
          if (Number(selectedType) === SymbolFileTypeEnum.SOURCE_MAP) {
            message.warn(ze('Source map仅支持流水线插件或符号表工具上传', 'Uploading source map is only available via symbol upload tool.'));
            return;
          }
          e.preventDefault();
          uploadHandler();
        }}
      >
        <div style={{display:'flex',alignItems:'center',flexDirection:'row'}}>
          <IconManage ></IconManage>
          { t("SYMBOLMANAGE.uploadSym") }
        </div>
      </Button>}
    </div>
  );
};

PageHeader.propTypes = {
  demoApp: PropTypes.bool,
  handleSearchInput: PropTypes.func.isRequired,
  handleKeyPress: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  uploadHandler: PropTypes.func.isRequired,
  types: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  searchValue: PropTypes.string.isRequired,
  selectedType: PropTypes.number.isRequired,
  platformId: PropTypes.number.isRequired,
};

export default PageHeader;
