import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
// import { MyApp } from 'components/workbench';
// import CreateAppPage from 'components/workbench/CreateApp/CreateAppPage';
// import AccessGuide from 'containers/TutorialPage/AccessGuide/AccessGuide';
// import LadderPage from 'components/workbench/SpaceElevator/LadderPage';
import { LoadingPage } from '../index';

const MyApp = React.lazy(() => import(/* webpackChunkName: "my-app" */ 'components/workbench/MyApp'));
const CreateAppPage = React.lazy(() => import(/* webpackChunkName: "create-app-page" */ 'components/workbench/CreateApp/CreateAppPage'));
const AccessGuide = React.lazy(() => import(/* webpackChunkName: "access-guide" */ 'containers/TutorialPage/AccessGuide/AccessGuide'));
const LadderPage = React.lazy(() => import(/* webpackChunkName: "ladder-page" */ 'components/workbench/SpaceElevator/LadderPage'));

/* eslint-disable react/prop-types */
export default ({ match }) => (
  <Suspense fallback={<LoadingPage/>}>
    <Switch>
      <Route path={`${match.url}/apps`} component={MyApp} />
      <Route path={`${match.url}/create-app`} component={CreateAppPage} />
      <Route path={`${match.url}/access-guide`} component={AccessGuide} />
      <Route path={`${match.url}/ladder-page`} component={LadderPage} />
      <Route path={`${match.url}`} component={MyApp} />
    </Switch>
  </Suspense>
);
