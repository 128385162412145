import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ReactECharts from 'echarts-for-react';
import { IssueTrendGranularityUnit, LINE_COLOR } from 'utils/constants';
import { getDateFromStr } from 'utils/StringUtils';
import { Select, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useZhEn } from 'utils/react-hooks/useZhEn';
import { ze } from 'utils/zhEn';
import i18n from 'i18next';
import { isMobile } from 'utils/platform';
import { useDispatch, useSelector } from 'react-redux';
import { ServerAppSettings } from 'utils/server-app-settings';
import SubModuleIdSelect from 'components/exception/ProductSummaryPage/SubModuleIdSelect';
import { ProductSummaryUtil } from 'components/exception/ProductSummaryPage/ProductSummaryUtil';
import CountryGroupConfigModal from 'containers/CountryGroupConfigModal';
import CountrySelect from 'components/exception/ProductSummaryPage/CountrySelect';
import { fetchIssueTrendAccessDeviceTrend, patchCurrentIssueInfo } from 'reducers/issue/issueActions';
import moment from 'moment';
import { useCrashsightLocalStorage } from 'utils/useCrashSightLocalStorage';
import { cyan } from '@ant-design/colors';
import { COLOR_LIST } from 'utils/constants/chart-options';
import { CS_STYLES } from 'utils/constants/style-constants';

export const IssueTrendOptions = [{
  label: ze('最近30天（天粒度）', 'Last 30 Days (1-Day Granularity)'),
  value: 'LAST_30_DAYS_DAILY',
  granularityUnit: IssueTrendGranularityUnit.DAY,
  granularityMagnitude: 1,
  intervalUnit: 'day',
  intervalMagnitude: 30,
}, {
  label: ze('最近7天（小时粒度）', 'Last 7 Days (1-Hour Granularity)'),
  value: 'LAST_7_DAYS_HOURLY',
  granularityUnit: IssueTrendGranularityUnit.HOUR,
  granularityMagnitude: 1,
  intervalUnit: 'day',
  intervalMagnitude: 7,
}, {
  label: ze('最近72小时（小时粒度）', 'Last 72 Hours (1-Hour Granularity)'),
  value: 'LAST_72_HOURS_HOURLY',
  defaultOption: true,
  granularityUnit: IssueTrendGranularityUnit.HOUR,
  granularityMagnitude: 1,
  intervalUnit: 'day',
  intervalMagnitude: 3,
}, {
  label: ze('最近24小时（10分钟粒度）', 'Last 24 Hours (10-Minute Granularity)'),
  value: 'LAST_24_HOURS_10_MINUTELY',
  granularityUnit: IssueTrendGranularityUnit.MINUTE,
  granularityMagnitude: 10,
  intervalUnit: 'day',
  intervalMagnitude: 1,
}, {
  label: ze('最近24小时（5分钟粒度）', 'Last 24 Hours (5-Minute Granularity)'),
  value: 'LAST_24_HOURS_5_MINUTELY',
  granularityUnit: IssueTrendGranularityUnit.MINUTE,
  granularityMagnitude: 5,
  intervalUnit: 'day',
  intervalMagnitude: 1,
}, {
  label: ze('最近24小时（1分钟粒度）', 'Last 24 Hours (1-Minute Granularity)'),
  value: 'LAST_24_HOURS_1_MINUTELY',
  granularityUnit: IssueTrendGranularityUnit.MINUTE,
  granularityMagnitude: 1,
  intervalUnit: 'day',
  intervalMagnitude: 1,
}, {
  label: ze('最近6小时（5分钟粒度）', 'Last 6 Hours (5-Minute Granularity)'),
  value: 'LAST_6_HOURS_5_MINUTELY',
  granularityUnit: IssueTrendGranularityUnit.MINUTE,
  granularityMagnitude: 5,
  intervalUnit: 'hour',
  intervalMagnitude: 6,
}, {
  label: ze('最近6小时（1分钟粒度）', 'Last 6 Hours (1-Minute Granularity)'),
  value: 'LAST_6_HOURS_1_MINUTELY',
  granularityUnit: IssueTrendGranularityUnit.MINUTE,
  granularityMagnitude: 1,
  intervalUnit: 'hour',
  intervalMagnitude: 6,
}];

export const IssueTrendUtil = Object.freeze({
  makeMinMaxMomentPair(issueTrendOption) {
    if (!issueTrendOption) {
      console.error('makeMinMaxMomentPair: issueTrendOption is null!');
      return null;
    }

    const { granularityUnit, granularityMagnitude, intervalUnit, intervalMagnitude } = issueTrendOption;
    let maxMoment;
    if (granularityUnit === IssueTrendGranularityUnit.DAY) {
      maxMoment = moment().startOf('day').subtract(1, 'day');
    } else if (granularityUnit === IssueTrendGranularityUnit.HOUR) {
      maxMoment = moment().startOf('hour');
    } else if (granularityUnit === IssueTrendGranularityUnit.MINUTE) {
      const tempMaxMoment = moment().startOf('minute');
      // 分钟对齐至精度，比如10分钟精度则分钟必须是整十
      const alignedMinute = Math.ceil(tempMaxMoment.minute() / granularityMagnitude) * granularityMagnitude;
      maxMoment = tempMaxMoment.startOf('hour').add(alignedMinute, 'minute');
    } else {
      console.error('makeMinMaxMomentPair: Invalid granularityUnit');
      return null;
    }
    const minMoment = maxMoment.clone().subtract(intervalMagnitude, intervalUnit);
    return [minMoment, maxMoment];
  },
});

function makeXAxis(trendData) {
  return trendData.map((item) => {
    const date = getDateFromStr(item.date);
    if (item.date.length === 8) {
      return `${date.month}-${date.day}`;
    } else if (item.date.length === 10) {
      return `${date.month}-${date.day} ${date.hour}${ze('时', 'H')}`;
    } else {
      return item.date;
    }
  });
}

function getChartOptions(issueTrendData, issueTrendAccessDeviceTrendData, issueTrendShowAccessTrend, accessTrendSupported) {
  const series = [];
  let xAxis = [];
  let issueTrendMaxValue = 0;
  let accessDeviceMaxValue = 0;
  if (issueTrendData && issueTrendData.length > 0) {
    xAxis = makeXAxis(issueTrendData);
    issueTrendMaxValue = Math.max(...issueTrendData.map(x => x.crashNum), ...issueTrendData.map(x => x.crashUser));
    series.push({
      name: i18n.t('EXCP_OVERVIEW.occurNumber'),
      data: issueTrendData.map((item) => item.crashNum),
      lineStyle: {
        normal: {
          color: CS_STYLES.LINK_COLOR,
        },
      },
      itemStyle: {
        normal: {
          color: CS_STYLES.LINK_COLOR,
        },
      },
    });
    series.push({
      name: i18n.t('EXCP_OVERVIEW.userNumAffect'),
      data: issueTrendData.map((item) => item.crashUser),
      lineStyle: {
        normal: {
          color: CS_STYLES.PRIMARY_COLOR,
        },
      },
      itemStyle: {
        normal: {
          color: CS_STYLES.PRIMARY_COLOR,
        },
      },
    });
    if (accessTrendSupported && issueTrendAccessDeviceTrendData && issueTrendAccessDeviceTrendData.length > 0) {
      const data = issueTrendData.map(x => {
        const { date } = x;
        const matchedAccessTrend = issueTrendAccessDeviceTrendData.find(y => y.date === date);
        return (matchedAccessTrend || {}).accessUser || 0;
      });
      accessDeviceMaxValue = Math.max(...issueTrendAccessDeviceTrendData.map(x => x.accessUser));
      series.push({
        name: i18n.t('EXCP_OVERVIEW.userNumConnect'),
        yAxisIndex: 1,
        data,
        showSymbol: false,
        lineStyle: {
          normal: {
            color: cyan.primary,
            type: [5, 5],
            width: 1,
          },
        },
        itemStyle: {
          normal: {
            color: cyan.primary,
          },
        },
      });
    } else if (accessTrendSupported) {
      series.push({
        name: i18n.t('EXCP_OVERVIEW.userNumConnect'),
        yAxisIndex: 1,
        data: [],
        showSymbol: false,
        lineStyle: {
          normal: {
            color: cyan.primary,
            type: [5, 5],
            width: 1,
          },
        },
        itemStyle: {
          normal: {
            color: cyan.primary,
          },
        },
      });
    }
  }

  const options = {
    color: COLOR_LIST,
    grid: {
      top: '28px',
      left: 8,
      right: 8,
      bottom: 24,
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'line',
        lineStyle: {
          color: '#cccccc',
          width: 1,
          type: 'solid',
        },
      },
    },
    legend: {
      bottom: '0%',
      textStyle: {
        color: '#999999',
      },
      selected: {
        [i18n.t('EXCP_OVERVIEW.userNumConnect')]: issueTrendShowAccessTrend,
      },
      itemHeight: 6, // 圆点的大小
    },
    xAxis: {
      type: 'category',
      data: xAxis,
      axisLine: {
        lineStyle: {
          color: '#cccccc',
        },
      },
      splitLine: {
        show: false,
        lineStyle: {
          color: '#f0f0f0',
        },
      },
      axisTick: false,
      axisLabel: {
        textStyle: {
          color: '#999999',
        },
      },
    },
    yAxis: [{
      name: issueTrendShowAccessTrend ? `${i18n.t('EXCP_OVERVIEW.occurNumber')} / ${i18n.t('EXCP_OVERVIEW.userNumAffect')}` : undefined,
      type: 'value',
      min: 0,
      axisLabel: {
        show: true,
        textStyle: {
          color: '#999999',
        },
      },
      splitLine: {
        lineStyle: {
          color: '#f0f0f0',
        },
      },
      axisTick: false,
      nameTextStyle: {
        align: 'left'
      }
    }, { // 第二个y轴给额外展示的联网设备数用的
      name: issueTrendShowAccessTrend ? i18n.t('EXCP_OVERVIEW.userNumConnect') : undefined,
      type: 'value',
      min: 0,
      minInterval: accessDeviceMaxValue || 0,
      splitNumber: 1,
      axisLabel: {
        textStyle: {
          color: '#999999',
        },
      },
      splitLine: {
        lineStyle: {
          color: '#f0f0f0',
        },
      },
      axisTick: false,
    }],
    series: series.map((oneOfSeries, i) => ({
      type: 'line',
      showAllSymbol: true,
      showSymbol: false,
      symbol: 'circle',
      symbolSize: (xAxis || []).length < 100 ? 6 : 0,
      lineStyle: {
        normal: {
          color: LINE_COLOR[i],
        },
      },
      itemStyle: {
        normal: {
          color: LINE_COLOR[i],
        },
      },
      ...oneOfSeries,
    })),
  };
  if (issueTrendMaxValue <= 5) {
    options.yAxis.max = issueTrendMaxValue + 5;
  }
  return options;
}

const IssueTrend = ({
  platformId,
  issue, changeIssueTrendTag, getIssueTrend, changeIssueTrendVersion, changeIssueTrendCountryList, showLoading, hideLoading, crashDataType,
}) => {
  const { t } = useTranslation();
  const { ze } = useZhEn();
  const [countryList, setCountry] = useState([]);
  const dispatch = useDispatch();
  const [storage, updateStorage] = useCrashsightLocalStorage();
  const { issueTrendShowAccessTrend } = storage;
  let {
    issueTrendTag,
    issueTrendVersion,
    issueTrendSubModuleId,
    issueVersions,
    issueTrendData,
    issueTrendAccessDeviceTrendData,
  } = issue.toJS();
  issueTrendTag = issueTrendTag || IssueTrendOptions.find(x => x.defaultOption).value;
  issueTrendVersion = issueTrendVersion || '-1';

  const issueTrendOptions = IssueTrendOptions;
  const issueTrendOption = IssueTrendOptions.find(x => x.value === issueTrendTag);
  const { granularityUnit } = issueTrendOption;
  const accessTrendSupported = granularityUnit === IssueTrendGranularityUnit.DAY || granularityUnit === IssueTrendGranularityUnit.HOUR;

  const versionOptions =[
    { label: t('versionOptions.全版本'), value: '-1' },
    ...(issueVersions || []).map((x) => {
      const { version } = x;
      return { label: version, value: version };
    }),
  ];

  function changeTab(changeIssueTrendTag, getIssueTrend, showLoading, hideLoading, tag, crashDataType) {
    showLoading();
    changeIssueTrendTag(tag);
    dispatch(fetchIssueTrendAccessDeviceTrend());
    getIssueTrend().then(hideLoading, hideLoading);
  }

  function handleChangeVersion(changeIssueTrendVersion, getIssueTrend, issueId, crashDataType) {
    changeIssueTrendVersion(issueId);
    dispatch(fetchIssueTrendAccessDeviceTrend());
    getIssueTrend();
  }

  function handleChangeCountryList(changeIssueTrendCountryList, getIssueTrend, v, crashDataType) {
    setCountry(v)
    changeIssueTrendCountryList(v);
    dispatch(fetchIssueTrendAccessDeviceTrend());
    getIssueTrend();
  }

  const onEvents = useMemo(() => {
    return {
      legendselectchanged: (e) => {
        const { name, selected } = e;
        if (name === t('EXCP_OVERVIEW.userNumConnect')) {
          updateStorage({
            issueTrendShowAccessTrend: selected[name],
          });
        }
      },
    }
  }, []);

  const isRegionalDashboard = ProductSummaryUtil.getIsRegionalDashboard(platformId);

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Select
          style={{ width: '320px', marginRight: '10px' }}
          options={versionOptions}
          optionFilterProp='label'
          showSearch={true}
          value={issueTrendVersion}
          onChange={(v) => handleChangeVersion(changeIssueTrendVersion, getIssueTrend, v, crashDataType)}
        />
        {isRegionalDashboard && <CountrySelect
          value={countryList}
          isMultiple={true}
          onChangeWhenDropdownClosed={(v) => handleChangeCountryList(changeIssueTrendCountryList, getIssueTrend, v, crashDataType)}
        />}
        <Select
          style={{ width: '320px', marginRight: '10px' }}
          options={issueTrendOptions}
          value={issueTrendTag}
          onChange={(v) => changeTab(changeIssueTrendTag, getIssueTrend, showLoading, hideLoading, v, crashDataType)}
        />
      </div>
      <div style={{ fontSize: '14px', marginTop: '24px' }}>
        <ReactECharts
          option={getChartOptions(issueTrendData, issueTrendAccessDeviceTrendData, issueTrendShowAccessTrend, accessTrendSupported)}
          style={{ width: '100%', height: '250px' }}
          notMerge={true}
          onEvents={onEvents}
        />
      </div>
      <CountryGroupConfigModal/>
    </div>
  );
};

IssueTrend.propTypes = {
  platformId: PropTypes.string,
  issue: PropTypes.object,
  changeIssueTrendTag: PropTypes.func,
  getIssueTrend: PropTypes.func,
  changeIssueTrendVersion: PropTypes.func,
  changeIssueTrendCountryList: PropTypes.func,
  showLoading: PropTypes.func,
  hideLoading: PropTypes.func,
};

export default IssueTrend;
