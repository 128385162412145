import React from 'react';
import ReactECharts from 'echarts-for-react';
import { formatNum } from 'utils/helper';
import { CS_STYLES } from 'utils/constants/style-constants';

export function renderBarChart(originData, isRate, formatter, sortKey, key, key2Color, onClickBar) {
  const sortOption = sortKey.includes('Connect') ? 'access' : sortKey.includes('Rate') ? 'rate' : 'affect';
  function getPercent(crashUser, accessUser) { // (100 * o.crashUser / o.accessUser).toFixed(2)
    let result = 0;
    if (accessUser > 0 && crashUser >= 0) {
      let temp = (100 * crashUser / accessUser);
      if (temp >= 100) {
        temp = 100;
      }
      result = temp.toFixed(2);
    }
    return result;
  }

  function renderList(list){
    if(list.length === 5){
      return list;
    } else {
      const remaining = 5 - list.length;
      const newList = list.concat(Array(remaining).fill({}));
      return newList;
    }
  }

  function calculatedWidth(maxValue,value){
    const result = Math.max(value/maxValue, 0.01) * 100;
    return (result + '%');
  }

  function isGreaterThanTenPercent(dividend, divisor) {
    return key2Color[key];
    /*
    if (dividend / divisor < 0.1) {
      return key2Color[key];
    } else if (dividend > 10000) { // 当商大于百分之十且被除数大于 10000 时，返回 'red'
      return '#ff7875';
    } else {
      return key2Color[key];
    } */
  }

  const data = renderList(originData).map(item => {
    return {
      value: sortOption === 'affect' ? item.crashUser : sortOption === 'access' ? item.accessUser : getPercent(item.crashUser, item.accessUser),
      name: item.fieldValue,
      fieldOriginalValue: item.fieldOriginalValue,
      fieldRollupAccessDevices: item.fieldRollupAccessDevices,
      accessDevices: item.accessDevices,
    };
  });

  data.sort((a, b) => b.value - a.value);
  const valueList = data.map(item => item.value || 0);
  // 率的最大值限制最小为3
  const maxValue = sortOption === 'rate' ? Math.max(3, Math.max(...valueList)) : Math.max(...valueList);

  return <div style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between'}}>
    <ul style={{flexGrow: '1'}}>
      {data.map((item, index) => (<li style={{paddingTop:'20px'}}>
        {(item.name && item.value > 0) && <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (typeof onClickBar === 'function') {
              onClickBar(key, item.fieldOriginalValue);
            }
          }}
        >
          <div style={{fontSize: '12px', lineHeight: '12px', marginBottom: '6px'}}>{item.name}</div>
          <div style={{display:'flex', alignItems:'center', height:'10px'}}>
            <div style={{position:'relative', width:'100%',height:'100%',background:'#ededed',borderRadius:'4px',}}>
              <div style={{position:'absolute',width:calculatedWidth(maxValue,item.value), top: '0px', left: '0px',
               height:'100%', borderRadius:'4px', background: 'linear-gradient(90deg, #7C73F3, #A194FF)', overflow: 'hidden'}}></div>
            </div>
          </div>
        </div>}
      </li>) )}
    </ul>
    <ul style={{marginLeft: '6px'}}>
      {data.map((item, index) => (<li style={{paddingTop:'36px', fontSize: '12px', lineHeight: '12px'}}>
      {(item.name && item.value > 0) && <div
        style={{ cursor: 'pointer' }}
        onClick={() => {
          if (typeof onClickBar === 'function') {
            onClickBar(key, item.fieldOriginalValue);
          }
        }}
      >
       { isRate ? `${item.value}%` : item.value}
      </div>}
    </li>) )}
    </ul>
  </div>

  // return <ul style={{width:'100%', height:'100%', fontSize:'14px'}}>
  //   {data.map((item, index) => (<li style={{paddingTop:'10px'}}>
  //     {(item.name && item.value > 0) && <div
  //       style={{ cursor: 'pointer' }}
  //       onClick={() => {
  //         if (typeof onClickBar === 'function') {
  //           onClickBar(key, item.fieldOriginalValue);
  //         }
  //       }}
  //     >
  //       <div>{item.name}</div>
  //       <div style={{display:'flex', alignItems:'center', width:'95%',height:'15px'}}>
  //         <div style={{display:'absolute', width:'100%',height:'100%',background:'#ededed',borderRadius:'20px',}}>
  //           <div style={{display:'relative',width:calculatedWidth(maxValue,item.value), height:'100%', borderRadius:'20px', background: 'linear-gradient(90deg, #7C73F3, #A194FF)'}}></div>
  //         </div>
  //         <div style={{width:'5%', paddingLeft:'10px'}}>{item.value}</div>
  //       </div>
  //     </div>}
  //   </li>) )}
  // </ul>
}
