import React, { useState, useEffect, useMemo } from 'react';
import { Modal, Spin } from 'antd';
import { connect } from 'react-redux';
import OnlineRetraceModuleTable from 'components/exception/OnlineRetraceModuleTable';
import { ze } from 'utils/zhEn';

const RetraceAnrTraceModal = ({
  reduxState,
  visible,
  crashId,
  onCancel = () => {},
  onOk = () => {},
}) => {
  const [loading, setLoading] = useState(false);
  const [analysisTableData, setAnalysisTableData] = useState([]);
  const currentApp = reduxState.app.get('current').toJS();
  const { appId, platformId } = currentApp;

  useEffect(() => {
    if (!visible) {
      return;
    }
    getAnrTraceInfo();
  }, [visible, crashId]);

  async function getAnrTraceInfo() {
    setLoading(true);
    const rsp = await RestHelper.mustPost('/redir/reretrace/exceptionReRetrace/getAnrTraceInfo', {
      appId,
      platformId,
      crashId,
    });
    const rspValue = rsp.json.data;
    const rspDataCopy = [];
    for (let item of rspValue.moduleName){
      rspDataCopy.push({
        key:item,
        soName:item,
        baseAddress:'0x0',
        masterModule:'否',
        uploadStatus:{
          ...rspValue,
          symbolMap: (rspValue.symbolMap || {})
        }
      })
    }
    setAnalysisTableData(rspDataCopy);
    setLoading(false);
  }

  async function confirmRetrace() {
    let soNameSymbolMap = {};
    let soNameBaseAddrMap = {};
    for (let item of analysisTableData) {
      soNameBaseAddrMap[item.soName] = item.baseAddress;
      soNameSymbolMap[item.soName] = item.uploadStatus.symbolMap[item.soName] ? item.uploadStatus.symbolMap[item.soName].symbolPath : '';
    }
    setLoading(true);
    await RestHelper.mustPost('/redir/reretrace/exceptionReRetrace/createAnrRetraceTask', {
      appId,
      platformId,
      crashId,
      retraceType: 'ANR_TRACE',
      soNameSymbolMap,
      soNameBaseAddrMap,
    });
    setLoading(false);
    onOk();
  }

  return <Modal
    visible={visible}
    width='80vw'
    maskClosable={false}
    onCancel={onCancel}
    onOk={() => {
      confirmRetrace();
    }}
    title={ze('ANR Trace还原设置', 'ANR Trace Retrace Config')}
  >
    <Spin spinning={loading}>
      <div>{ ze('请设置ANR Trace中的模块对应的符号表及基地址。', 'Please set the symbols and base addresses for the modules used in the ANR Trace.') }</div>
      <OnlineRetraceModuleTable
        analysisTableData={analysisTableData}
        onChangeAnalysisTableData={v => setAnalysisTableData(v)}
      />
    </Spin>
  </Modal>;
};

const mapStateToProps = state => ({
  reduxState: state,
});

export default connect(mapStateToProps)(RetraceAnrTraceModal);
