import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactECharts from 'echarts-for-react';
import { useZhEn } from 'utils/react-hooks/useZhEn';
import { IssueListTrendTypeEnum } from 'utils/constants';
import moment from 'moment';
import { geekblue } from '@ant-design/colors';
import { graphic } from 'echarts';
import { COLOR_LIST } from 'utils/constants/chart-options';

const IssueTrendBarChart = (props) => {
  const {
    style,
    trendType,
    dailyTrendList,
    hourlyTrendList,
  } = props;

  const { ze } = useZhEn();

  const trendList = trendType === IssueListTrendTypeEnum.DAILY ? (dailyTrendList || []) : (hourlyTrendList || []);

  const yData = trendList.map(x => x.crashNum);
  const xData = trendList.map((x) => {
    if (trendType === IssueListTrendTypeEnum.DAILY) {
      return moment(x.date, 'YYYYMMDD').format('YYYY-MM-DD');
    } if (trendType === IssueListTrendTypeEnum.HOURLY) {
      return moment(x.date, 'YYYYMMDDHH').format('YYYY-MM-DD HH:00~HH:59');
    }
    return x.date;
  });
  const yMax = Math.max(...yData);
  const yAxisMax = Math.max(25, yMax);

  const chartOptions = {
    color: COLOR_LIST,
    grid: {
      top: 10,
      bottom: 10,
      left: 0,
      right: 0,
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'line',
      },
      formatter: (params) => {
        let tip = '';
        let str;
        // console.log(params);
        // return '312';
        params.map((item) => {
          const { axisValue } = item;
          str = `<div style="text-align: left;display:block;">${axisValue}</div>`;

          tip += `${item.marker}${item.seriesName}&nbsp&nbsp&nbsp&nbsp&nbsp${item.value}<br/>`;
        });
        return (str + tip).replace('\n', '<br/>');
      },
    },
    xAxis: {
      type: 'category',
      data: xData,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
        alignWithLabel: true,
      },
      axisLabel: {
        show: false,
      },
    },
    yAxis: {
      type: 'value',
      max: yAxisMax,
      splitNumber: 1,
      // 只展示最大值，这里强制设置interval
      interval: yAxisMax / 1,
      axisLine: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        show: false,
        showMaxLabel: false,
        showMinLabel: false,
      },
    },
    position(point, params, dom, rect, size) {
      return [0, point[1]];
    },
    series: [
      {
        name: ze('发生次数', 'Report Count'),
        data: yData,
        type: 'bar',
        barMinHeight: 1,
        itemStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#A194FF',
          }, {
            offset: 1,
            color: '#7c73f3',
          }]),
        },
      },
    ],
  };

  return <ReactECharts
    option={chartOptions}
    style={{ width: '184px', height: '80px', ...style }}
    notMerge={true}
  />;
};

IssueTrendBarChart.propTypes = {
  label: PropTypes.string,
};

export default IssueTrendBarChart;
