import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import TimelineItem from '../TimelineItem';
import moment from 'moment';
import style from './style.scss';
import { withTranslation } from 'react-i18next'
import { ze } from 'utils/zhEn';
import { Button, Divider } from 'antd';
import CsCollapseButton from 'components/commons/CsCollapseButton/CsCollapseButton';
import { CS_STYLES } from 'utils/constants/style-constants';

const MAX_INPUT_CHARS = 1000;

/*
 ** 崩溃详情页底部，时间轴组件
 */
@withTranslation()
export default class Timeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: false,
      num: MAX_INPUT_CHARS,
    };
  }

  handleInput() {
    const { input } = this;
    const { length } = input.value;
    if (length >= MAX_INPUT_CHARS) {
      input.value = input.value.slice(0, MAX_INPUT_CHARS);
    }
    this.setState({
      num: MAX_INPUT_CHARS - input.value.length,
    });
  }

  submit() {
    const { isDemoApp } = this.props;
    if (this.props.noteDisabled || isDemoApp) return;
    function dateFormat(date, fmt) {
      const o = {
        'M+': date.getMonth() + 1, // 月份
        'd+': date.getDate(), // 日
        'h+': date.getHours(), // 小时
        'm+': date.getMinutes(), // 分
        's+': date.getSeconds(), // 秒
        'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
        S: date.getMilliseconds(), // 毫秒
      };
      /* eslint-disable no-param-reassign */
      if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (`${date.getFullYear()}`).substr(4 - RegExp.$1.length));
      for (const k in o) {
        if (new RegExp(`(${k})`).test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : ((`00${o[k]}`).substr((`${o[k]}`).length)));
      }
      return fmt;
    }

    // 提交请求
    const { addIssueNote, issueId } = this.props;
    const note = this.input.value;
    const addIssueStatus = 3;
    if (encodeURIComponent(note) !== '') { // 内容为空的时候,不能提交
      addIssueNote(issueId, note, addIssueStatus);
      // 提交成功后，清空input
      this.input.value = '';
    }
  }

  render() {
    const { num } = this.state;
    const {
      noteList, noteDisabled, firstUploadTime, members,
    } = this.props;
    return <div className={style.timeline_container}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ fontSize: '16px', fontWeight: 'bold', flexGrow: 1 }}>{ ze('备注', 'Comments') }</div>
        <CsCollapseButton
          isCollapsed={this.state.isCollapsed}
          onClick={() => this.setState({ isCollapsed: !this.state.isCollapsed })}
        />
      </div>

      { !this.state.isCollapsed && <div>
        <Divider style={{ margin: '20px 0' }}/>
        <div className={style.input_area}>
          <textarea
            name=""
            id=""
            cols="30"
            rows="10"
            style={{ resize: 'none' }}
            placeholder={this.props.t("TIMELINE.commentPlaceholder")}
            ref={(val) => (this.input = val)}
            onChange={this.handleInput.bind(this)} />
        </div>

        <div style={{ display: 'flex', }}>
        <ul className={style.timeline}>
          <li style={{ color: CS_STYLES.SUB_TEXT_COLOR }}>{ ze(`可再输入 ${num} 字符`, `Content limited to ${MAX_INPUT_CHARS} characters, remaining: ${num}`) }</li>
          {noteList && noteList.map((note, index) => {
            return (<TimelineItem noteItem={note} key={index} style={style} members={members} />);
          })}
          {noteList && firstUploadTime && (
            <li className={style.first_time}>
              <p className={style.time}>{firstUploadTime.substr(0, 19)}</p>
              <p className={style.tip}>{this.props.t("TIMELINE.firstPost")}</p>
            </li>
          )}
        </ul>
        <Button
          style={{ marginTop: '12px' }}
          disabled={noteDisabled}
          onClick={this.submit.bind(this)}
          type='primary'
        >{ this.props.t("TIMELINE.submit") }</Button>
        </div>
      </div> }
    </div>;
  }
}

Timeline.propTypes = {
  noteList: PropTypes.object.isRequired,
  addIssueNote: PropTypes.func.isRequired,
  issueId: PropTypes.string.isRequired,
  firstUploadTime: PropTypes.string,
  members: PropTypes.object,
  isDemoApp: PropTypes.bool,
  noteDisabled: PropTypes.bool,
};

Timeline.defaultProps = {
  noteList: new List(),
};
