import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Echart extends Component {
  componentDidMount() {
    window.echarts && this.renderChart();
  }

  componentDidUpdate() {
    window.echarts && this.renderChart();
  }

  renderChart() {
    const { options, id, event } = this.props;
    const node = this.chartIds[id];
    const chart = window.echarts.init(node);

    chart.setOption(options);

    if (event && event.length > 0) {
      event.forEach((obj) => {
        chart.on(obj.name, obj.func);
      });
    }
  }

  render() {
    return (
      <div style={{ height: '100%', width: '100%' }}>
        {this.props.children}
        <div
          ref={(target) => {
            if (!this.chartIds) {
              this.chartIds = {};
            }
            this.chartIds[this.props.id] = target;
          }}
          style={this.props.style}>
        </div>
      </div>
    );
  }
}

Echart.propTypes = {
  options: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  style: PropTypes.object,
  event: PropTypes.array,
  children: PropTypes.object,
};
// event [{name, func}]
