import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _style from './style.scss';

const Checkbox = ({
  children, id, checked = false, handleCheck, style = {},
}) => {
  function handleClick(e) {
    e.stopPropagation();
    e.preventDefault();
    handleCheck && handleCheck(!checked, false);
  }

  return (
    <div className={classNames(_style.mod_checkbox, style.mod_checkbox)}>
      <input type="checkbox" id={id} readOnly checked={checked} />
      <label htmlFor={id} onClick={handleClick} className={classNames(_style.ui_checkbox, style.ui_checkbox)}>
        {children}
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  handleCheck: PropTypes.func.isRequired,
  style: PropTypes.object,
  children: PropTypes.string.isRequired,
};


export default Checkbox;
