export function downloadAsFile(fileName, fileContent) {
  const element = document.createElement('a');
  const blob = new Blob([fileContent]);
  element.href = window.URL.createObjectURL(blob);
  element.download = fileName;

  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export function downloadWithUrl(fileName, url) {
  const element = document.createElement('a');
  element.href = url;
  element.download = fileName;

  element.style.display = 'none';
  console.log(element);
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

/** 可以设置下载文件的文件名 */
// export function downloadWithUrlAndChangeName(filename, url) {
//   fetch(url)
//     .then(response => {
//       const disposition = response.headers.get('Content-Disposition');
//       const match = disposition && disposition.match(/filename="(.+?)"/);
//       const finalFilename = match ? match[1] : filename;
//       response.blob().then(blob => {
//         const url = window.URL.createObjectURL(new Blob([blob]));
//         const link = document.createElement('a');
//         link.href = url;
//         link.setAttribute('download', finalFilename);
//         link.style.display = 'none';
//         document.body.appendChild(link);
//         link.click();
//         document.body.removeChild(link);
//       });
//     })
//     .catch(error => {
//       console.error('Error downloading file:', error);
//     });
// }