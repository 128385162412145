export const AccountStatus = Object.freeze({
  BIND_PENDING: 'BIND_PENDING',
  BOUND: 'BOUND',
  UNBIND_PENDING: 'UNBIND_PENDING',
  UNBOUND: 'UNBOUND',
});

export const BillingCurrencyType = Object.freeze({
  MAU: 'MAU',
  REPORT_COUNT: 'REPORT_COUNT',
  REPORT_CRASH_COUNT: 'REPORT_CRASH_COUNT',
});

export const BillingRole = Object.freeze({
  /** 组织管理员。拥有一切组织权限（账户+项目） */
  ORG_OWNER: 'ORG_OWNER',
  /** 账单成员 */
  BILLING_MEMBER: 'billing_member',
  /** 账单管理员 */
  BILLING_ADMIN: 'billing_admin',
  /** 项目管理员 */
  PROJECT_ADMIN: 'PROJECT_ADMIN',
  /** 项目成员 */
  PROJECT_MEMBER: 'PROJECT_MEMBER',
});

/** 计费账户的商业化类型 */
export const BillingTeamType = Object.freeze({
  /** 腾讯内部项目 */
  TENCENT: 'TENCENT',
  /** 腾讯二方项目 */
  SECOND_PARTY: 'SECOND_PARTY',
  /** 商业化项目 */
  COMMERCIAL: 'COMMERCIAL',
  /** 测试项目 */
  TEST: 'TEST',
});

// 未付费账户最多拥有项目数
export const NON_PAYMENT_MAX_APP_COUNT = 5;

// 当前环境是否启用计费账户相关功能
export const ENABLE_BILLING = process.env.REACT_APP_IS_PUBLIC_ENV || process.env.REACT_APP_ENABLE_BILLING;
