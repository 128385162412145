import { List } from 'immutable';
import { handleActions } from 'redux-actions';
import { getNumFound, covertArrByDecode } from 'utils/helper';
import {
  SDK_VERSIONS_SUCC,
  CRASH_SDK_LIST_SUCC,
  TOP_CRASH_VERSIONS_SUCC,
} from 'store/actions';
import { initialState } from './initialState';

export default handleActions({
  UPDATE_SEARCH_PARAMS_SDK_CRASH_PAGE: (state, action) => state.update('searchParams', (params) => {
    const { type, value } = action.payload;
    return params.set(type, value);
  }),
  UPDATE_PAEG_NUM_SDK_CRASH_PAGE: (state, action) => state.set('totalPages', Math.floor(getNumFound(state.get('sdkListArr')) / action.payload) + (getNumFound(state.get('sdkListArr')) % action.payload === 0 ? 0 : 1)),
  [SDK_VERSIONS_SUCC]: (state, action) => {
    const { data = {} } = action.response;
    const { datas = [] } = data;
    return state.update('selectOptions', (options) => options.merge({
      version: {
        options: new List(datas).map((item) => ({
          label: item,
          value: encodeURIComponent(item),
        })).unshift({
          label: '全版本',
          value: '-1',
        }),
      },
    }));
  },
  [CRASH_SDK_LIST_SUCC]: (state, action) => {
    const rows = state.get('searchParams').get('rows');
    const { mod } = action.params;
    const { data = {}, reponseCode = 0 } = action.response;
    const len = getNumFound(data);
    return mod === 'chart' ? state.set('dataList', data) : state.set('sdkListArr', data)
      .set('numFound', len)
      .set('totalPages', Math.floor(len / rows) + (len % rows === 0 ? 0 : 1))
      .set('listHttpStatus', parseInt(reponseCode) === 0 ? 200 : 500);
  },
  CRASH_SDK_LIST_FAIL: (state, action) => {
    const { mod } = action.params;
    const { status = 500 } = action.response;
    return mod === 'chart' ? state.set('chartHttpStatus', status).set('dataList', {}) : state.set('sdkListArr', {})
      .set('numFound', 0)
      .set('totalPages', 0)
      .set('listHttpStatus', status);
  },
  [TOP_CRASH_VERSIONS_SUCC]: (state, action) => {
    const { versions = [], datas = {} } = action.response;
    return state.set('top5Version', covertArrByDecode(versions))
      .set('top5DataList', datas)
      .set('dataList', datas)
      .set('topHttpStatus', 200)
      .set('chartHttpStatus', 200);
  },
  TOP_CRASH_VERSIONS_FAIL: (state, action) => {
    const { status = 500 } = action.response;
    return state.set('top5Version', [])
      .set('top5DataList', {})
      .set('dataList', {})
      .set('topHttpStatus', status);
  },
}, initialState);
