import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _style from './style.scss';
import { connect, useSelector } from 'react-redux';
import {
  selectCurrentAppJoinedAppsInSameGroup,
  selectFrontendWebVolatileConfigValueByKey,
  selectHasSpecifiedPermissionInCurrentApp
} from 'utils/selectors/selectors';
import { Checkbox, Modal, message } from 'antd';
import { ze } from 'utils/zhEn';
import RestHelper from 'utils/RestHelper';
import { ApiErrorCode } from 'utils/api-error-code';
import { PlusOutlined } from '@ant-design/icons';
import { PlatformIconUtil } from 'utils/platform-icon';
import { PLATFORM_ID } from 'utils/constants';
import { isLinux, isPc } from 'utils/platform';
import { RolePermission } from 'utils/constants/role-permission';
import { BillingTeamType } from 'utils/constants/billing';
import { isNullish } from 'utils/nullish';
const PlatformMenu = (props) => {
  const {
    onSelectPlatformChange,
    showAddAppBtn,
    hideIfOnlyOneApp,
    reduxState,
  } = props;

  const sameAppGroupIdAppList = useSelector(selectCurrentAppJoinedAppsInSameGroup);

  const currentApp = reduxState.app.get('current');
  const { appId, appGroupId, teamType } = currentApp.toJS();
  const { isSuper } = reduxState.user.get('current').toJS();

  const [selectedPidList, setSelectedPidList] = useState([]);
  const [isShowAddNewAppModal, showAddNewAppModal] = useState(false);

  const sameAppGroupIdOptions = sameAppGroupIdAppList.map(x => ({
    label: <div style={{ width: '24px', height: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{PlatformIconUtil.getIcon(x.appId, x.platformId)}</div>,
    value: x.appId,
  }));
  // 当前AppGroup中已经存在的platformID的列表
  // 由于存在部分PC项目实际是Linux项目的原因，这里先做了转换，展示实际的pid
  const sameAppGroupIdAppPidList = sameAppGroupIdAppList.map(({ appId, platformId }) => isPc(platformId, appId) ? PLATFORM_ID.PC : (isLinux(platformId, appId) ? PLATFORM_ID.LINUX : platformId))
  // 平台选择的Options
  const addNewAppCheckboxOptions = Object.values(PLATFORM_ID).map((pid) => {
    return {
      label: <div style={{width:'24px',height:'24px',display:'flex',alignItems:'center',justifyContent:'center'}}>{ PlatformIconUtil.getIcon('', pid) }</div>,
      value: pid,
      disabled: sameAppGroupIdAppPidList.includes(pid),
    }
  })

  const isNoPlatformCanAdd = addNewAppCheckboxOptions.every(x => x.disabled); // 是否所有平台都已经创建，没有可以增加的平台
  const isAppGroupAdmin = useSelector(state => selectHasSpecifiedPermissionInCurrentApp(state, RolePermission.APP_GROUP_CREATE)); // 获取用户是否是组管理员
  const hasAddNewPlatformPermission = isSuper || (isAppGroupAdmin && teamType !== BillingTeamType.TENCENT); // 内部项目由于涉及kpicode计费，暂不开放自助增加平台
  const isShowNewAppInAppGroupIcon = appGroupId && !isNoPlatformCanAdd && hasAddNewPlatformPermission; // 是否需要展示增加平台的按钮， 前提：存在appGroup+存在可增加的平台+拥有增加平台的权限

  const itemClick = (selectedAppId) => {
    if (appId !== selectedAppId) {
      const selectedAppPlatformId = (sameAppGroupIdAppList.find(x => x.appId === selectedAppId) || {}).platformId;
      if (isNullish(selectedAppPlatformId)) {
        message.error('Platform ID is null!');
        return;
      }
      onSelectPlatformChange && onSelectPlatformChange(selectedAppId, selectedAppPlatformId);
    }
  }

  // 获取图形验证码
  async function asyncGetValidateCaptchaResult() {
    const { TencentCaptcha } = window;
    if (!TencentCaptcha) {
      message.error('图形验证码加载失败，请联系客服。');
      throw Error('TencentCaptcha is undefined !');
    }

    const rsp = await RestHelper.post('/redir/api/serverEnv/queryEnvs');
    const { captchaAppId } = rsp.json.data;
    if (!captchaAppId) {
      message.error('图形验证码App ID加载失败，请联系客服。');
      throw Error('captchaAppId is undefined !');
    }

    return new Promise((resolve, reject) => {
      const captcha = new TencentCaptcha(captchaAppId, ({ ret, ticket, randstr }) => {
        if (Number(ret) === 2) {
          message.warn(ze('用户已主动关闭验证码', 'User has cancelled captcha validation.'));
          reject();
        }

        resolve({ ticket, randstr });
      });
      captcha.show();
    });
  }

  // 增加新APP
  const onAddNewApp = async () => {
    const needAddPidList = selectedPidList.filter(v => !sameAppGroupIdAppPidList.includes(v));
    if (!needAddPidList.length) {
      message.error(ze('没有选择平台', 'No platform be selected '));
      return;
    }
    let ticket, randStr;
    try {
      const result = await asyncGetValidateCaptchaResult();
      ticket = result.ticket;
      randStr = result.randstr;
    } catch (e) {
      return;
    }

    try {
      const rsp = await RestHelper.post('/redir/api/personal-app/addPlatformsToAppGroup', {
        appId,
        platformIdList: needAddPidList,
        appGroupId,
        ticket,
        randStr,
      },
        { silentErrorCodes: [ApiErrorCode.IllegalArgument, ApiErrorCode.GeneralReject] }
      )
      if (rsp.json.status === 200) {
        message.success(ze('创建成功', 'successful'))
        window.location.reload();
      }
      showAddNewAppModal(false);
    } catch (e) {
      message.error(e.message);
      return;
    }
  }

  // 增加新的APP的modal框
  const newAppModalDom = <Modal width={600}
    title={<div>{ze('添加平台', 'Create Project')}</div>}
    open={isShowAddNewAppModal}
    onOk={onAddNewApp}
    onCancel={() => showAddNewAppModal(false)}>
    <div className={_style.createProject}>
      <Checkbox.Group
        options={addNewAppCheckboxOptions}
        defaultValue={sameAppGroupIdAppPidList}
        onChange={setSelectedPidList}
      />
    </div>
  </Modal>;

  const platformIconContainerDom = <div className={_style.platformIconContainer}>
    {sameAppGroupIdOptions.map((item) => {
      const isActive = item.value === appId;
      const itemBaseClassName = onSelectPlatformChange ? [_style.iconBox, _style.iconBoxHover] : [_style.iconBox];
      const itemClassName = isActive ? [_style.iconBox, _style.iconBoxActive].join(' ') : [...itemBaseClassName].join(' ');
      return <div
        onClick={() => itemClick(item.value)}
        className={itemClassName}
      >
        {item.label}
      </div>
    })}
  </div>;

  const addNewAppBtnDom = <div
    className={_style.addPaltformBtn}
    onClick={() => showAddNewAppModal(true)}
  >
    <PlusOutlined />
  </div>;

  if (hideIfOnlyOneApp && sameAppGroupIdAppList.length <= 1) {
    return null;
  }

  return <div className={_style.menu}>
    <div className={_style.itemContainer}>
      {platformIconContainerDom}
      {showAddAppBtn && isShowNewAppInAppGroupIcon && addNewAppBtnDom}
    </div>
    {newAppModalDom}
  </div>

}

PlatformMenu.propTypes = {
  showAddAppBtn: PropTypes.bool,
  onSelectPlatformChange: PropTypes.func,
  style: PropTypes.object,
}

const mapStateToProps = (state, router) => ({
  reduxState: state,
  selectFrontendWebVolatileConfigValueByKey: (key) => selectFrontendWebVolatileConfigValueByKey(state, key),
});

export default connect(mapStateToProps)(PlatformMenu);
