import { notification } from 'antd';

export const ClipboardUtil = Object.freeze({
  async copyToClipboard(content) {
    try {
      await navigator.clipboard.writeText(content);
    } catch (e) {
      notification.error({ message: 'Failed to copy text to clipboard.' });
    }
  }
});
