import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import {
  ProductSummaryPage,
} from 'components/exception';

/* eslint-disable react/prop-types */
export default ({ match }) => (
    <Switch>
      <Route path={`${match.url}/:appId`} key={match.url} component={ProductSummaryPage} />
      <Route path={`${match.url}/*`} key={match.url} component={ProductSummaryPage} />
    </Switch>
);
