
import React, { useState, useEffect, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Translation, useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import _style from './StatusItem.scss';
import HourGlassIcon from 'svg/v2/newcs_dashboard_list_status_icon1.svg';
import WaitingIcon from 'svg/v2/newcs_dashboard_list_status_icon2.svg';
import SuccessIcon from 'svg/v2/newcs_dashboard_list_status_icon3.svg';
import ReopenedIcon from 'svg/v2/newcs_dashboard_list_status_icon4.svg';
import { IssueStatus } from 'utils/constants/issue-status';

const STATUS_IMG = {
  0: <HourGlassIcon />, // 未处理
  1: <SuccessIcon />, // 已处理
  2: <WaitingIcon />, // 处理中
  [IssueStatus.REOPENED]: <ReopenedIcon/>,
};

const StatusItem = (props) => {
  const {
    assigneeList = [],
    handleChangeStatus,
    statusNo = 0,
    isOmitted = false,
  } = props;
  const { t } = useTranslation();
  const hasAssignee = assigneeList && assigneeList.length;
  const nicknameList = hasAssignee ? assigneeList.map(x => x.name) : [];
  const editStatusDom = <span className={_style.status_btn}>{ t('BASEPROBLEM.stateChange') }</span>;
  const statusDom = <Fragment>
    {STATUS_IMG[statusNo]}
    <Tooltip
      overlayClassName={_style.status_tooltip}
      placement="bottom"
      title={(<div style={{ maxWidth: 150 }}>{nicknameList.join(',')}</div>)}
    >
      {!isOmitted && <div className={_style.status_name_show}>{nicknameList.join(',')}</div>}
      {isOmitted && <div className={_style.status_name_show}>{nicknameList[0]}{nicknameList.length > 1 ? `,+${nicknameList.length - 1}` : ''}</div>}
    </Tooltip>
  </Fragment>;


  return <div className={_style.status_item} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 5 }}
    onClick={() => {
      handleChangeStatus
        && handleChangeStatus();
    }}>
    {hasAssignee ? statusDom : editStatusDom}
  </div>;
};

StatusItem.propTypes = {
  handleChangeStatus: PropTypes.func.isRequired,
};

export default StatusItem;
