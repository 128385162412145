import { handleActions } from 'redux-actions';
import {
  getCurrentAuthAppInfo, getAuthCode, getNewAppAuthArr, parseSearch,
} from 'utils/helper';
import {
  LOCATION_CHANGE,
  AUTH_INFO_SUCC,
  AUTH_ACCREDIT_SUCC,
  AUTH_UNACCREDIT_SUCC,
  AUTH_ACCREDIT_FAIL,
  AUTH_INFO_FAIL,
  AUTH_UNACCREDIT_FAIL,
} from 'store/actions';
import { AUTH_CODE } from 'utils/constants';
import { initialState, initialSearchParams } from './initialState';

export default handleActions({

  /**
   * 路由改变
   */
  [LOCATION_CHANGE]: (state, action) => {
    const { location } = action.payload;
    const { pathname } = location;
    const query = parseSearch(location);
    if (pathname.match(/auth\/apps/i)) {
      return state.update('searchParams', (searchParams) => {
        return Object.keys(query).length ? searchParams.merge(query) : initialSearchParams;
      });
    }
    return state;
  },
  [AUTH_INFO_SUCC]: (state, action) => {
    const { datas = [], reponseCode = 0 } = action.response.data;
    const { from = '' } = state.get('searchParams').toJS();
    return state.set('authAppInfo', datas)
      .set('authCode', parseInt(reponseCode) === 0 ? AUTH_CODE.SUCCESS : AUTH_CODE.NETWORK_ERROR)
      .set('currentAuthAppInfo', getCurrentAuthAppInfo(datas, from));
  },
  [AUTH_INFO_FAIL]: (state) => {
    return state.set('authCode', AUTH_CODE.NETWORK_ERROR);
  },
  [AUTH_ACCREDIT_SUCC]: (state, action) => {
    const { datas = {}, reponseCode = 0 } = action.response.data;
    const { grant = '' } = datas;
    return state.set('grant', grant)
      .set('isAuth', true)
      .set('authCode', parseInt(reponseCode) === 0 ? AUTH_CODE.SUCCESS : getAuthCode(reponseCode))
      .set('authIsSuccess', `${grant}` !== '');
  },
  [AUTH_ACCREDIT_FAIL]: (state, action) => {
    const { reponseCode = 0 } = action.response.data || {};
    return state.set('authCode', getAuthCode(reponseCode))
      .set('authIsSuccess', false);
  },
  [AUTH_UNACCREDIT_SUCC]: (state, action) => {
    const { thirdpartyId } = action.params;
    const { reponseCode = 0 } = action.response.data;
    const authAppInfo = state.get('authAppInfo');
    return state.set('authCode', parseInt(reponseCode) === 0 ? AUTH_CODE.SUCCESS : getAuthCode(reponseCode))
      .set('authAppInfo', parseInt(reponseCode) === 0 ? getNewAppAuthArr(thirdpartyId, authAppInfo) : authAppInfo);
  },
  [AUTH_UNACCREDIT_FAIL]: (state, action) => {
    const { reponseCode = 0 } = action.response.data;
    return state.set('authCode', getAuthCode(reponseCode));
  },
}, initialState);
