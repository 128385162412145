import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Record } from 'immutable';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Button, Col, Input, Modal, Row } from 'antd';
import style from './style.scss';
import { withTranslation } from 'react-i18next'

/**
 * 标签管理列表中每一项
 */
@withTranslation()
export default class TagItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showRenameModal: false,
      showDelModal: false,
      tagName: props.tag.tagName,
    };
  }

  getTagNameErrorAndTip() {
    const { tag, t } = this.props;
    const { tagName } = this.state;
    let tagNameError;
    let tagNameTip;
    if (!tagName) {
      tagNameError = false;
      tagNameTip = t("TAGMANAGE.tagNameTip");
    } else if (tagName.length > 100) {
      tagNameError = true;
      tagNameTip = t("TAGMANAGE.tagNameTip");
    } else {
      tagNameError = false;
      tagNameTip = t("TAGMANAGE.tagNameTip");
    }
    return {
      tagNameTip,
      tagNameError,
    };
  }

  handleChangeTagName(e) {
    let { value } = e.target;
    value = value ? value.trim() : value;

    this.setState({ tagName: value });
  }

  handleCancelEdit() {
    this.setState({
      showRenameModal: false,
      tagName: this.props.tag.tagName,
    });
  }

  handleConfirmEdit() {
    const { editTag, tag } = this.props;
    const { tagName } = this.state;
    const { tagNameError } = this.getTagNameErrorAndTip();
    if (tagName === tag.tagName || !tagName) {
      return true;
    } else if (tagNameError) {
      return false;
    } else {
      editTag(tag.tagId, tagName);
      this.setState({ showRenameModal: false });
      return true;
    }
  }

  handleConfirmDel() {
    const { delTag, tag } = this.props;
    delTag(tag.tagId);
    this.setState({ showDelModal: false });
  }

  renderRenameModal() {
    const { tag, t } = this.props;
    const { tagNameError, tagNameTip } = this.getTagNameErrorAndTip();

    return <Modal
      visible={ this.state.showRenameModal }
      title={ t('TAGMANAGE.editTag') }
      onOk={() => this.handleConfirmEdit()}
      okText={ t("TAGMANAGE.editTagConfirm") }
      okButtonProps={{ disabled: tagNameError }}
      onCancel={() => this.handleCancelEdit()}
      cancelText={ t("TAGMANAGE.editTagCancel") }
    >
      <Row align='middle'>
        <Col span={6}>{ t("TAGMANAGE.tagId") }</Col>
        <Col span={18}>{ tag.tagId }</Col>
        <Col span={6}>{ t("TAGMANAGE.tagName") }</Col>
        <Col span={18}>
          <Input value={this.state.tagName} onChange={this.handleChangeTagName.bind(this)} />
        </Col>
        { tagNameError && <Col span={18} offset={6} style={{ color: 'red' }}>{ tagNameTip }</Col> }
      </Row>
    </Modal>;
  }

  renderDelModal() {
    const { tag, t } = this.props;
    return <Modal
      visible={ this.state.showDelModal }
      title={ t('TAGMANAGE.deleteTag') }
      onOk={() => this.handleConfirmDel()}
      onCancel={() => this.setState({ showDelModal: false })}
    >
      <div className={style.del_label}>
        { `${t("TAGMANAGE.deleteTag")} ${tag.tagName} ?` }
      </div>
      <div className={style.del_tips}>
        { t("TAGMANAGE.deleteTagTip") }
      </div>
    </Modal>;
  }

  render() {
    const { tag, currentApp, demoApp } = this.props;
    const { appId, pid } = currentApp.toJS();
    return (
      <li className={classNames(style.tag_item, tag.systemTag && style.system_tag)}>
        <div className={style.tag_id}>{tag.tagId }</div>
        <div className={style.tag_name} title={tag.tagName}>{tag.tagName}</div>
        <div className={style.tag_problem}>
          {tag.tagCount > 0 && <Link to={`/crash-reporting/advanced-search/${appId}?pid=${pid}&tagList=${tag.tagId}`} title={this.props.t("TAGMANAGE.checkIssue")} className="color_blue">{tag.tagCount}</Link>}
          {!tag.tagCount && '0'}
        </div>
        {tag.systemTag ? <div title={this.props.t("TAGMANAGE.sysTagNoEdit")} className={style.tag_undo}>{this.props.t("TAGMANAGE.sysTagNoEdit")}</div>
          : ( !demoApp && <div className={style.tag_do}>
            <Button
              size='small'
              onClick={() => { this.setState({ showRenameModal: true })}}
            >{this.props.t("TAGMANAGE.rename")}</Button>
            <Button
              style={{ marginLeft: '8px' }}
              size='small'
              danger
              onClick={() => { this.setState({ showDelModal: true })}}
            >{this.props.t("TAGMANAGE.delete")}</Button>
            </div> )
          }
        <div>{ this.renderRenameModal() }</div>
        <div>{ this.renderDelModal() }</div>
      </li>
    );
  }
}

TagItem.propTypes = {
  tag: PropTypes.instanceOf(Record).isRequired,
  editTag: PropTypes.func.isRequired,
  delTag: PropTypes.func.isRequired,
  $style: PropTypes.object.isRequired,
  checkTagNameDuplicate: PropTypes.func.isRequired,
  currentApp: PropTypes.object.isRequired,
  demoApp: PropTypes.bool.isRequired,
};
