import React, { useState, useEffect, useMemo } from 'react';
import { Button, Modal, Space, Spin } from 'antd';
import { ze } from 'utils/zhEn';
import DailyReportConfigCore from 'components/exception/DailyReportConfigCore';
import { connect, useSelector } from 'react-redux';
import { selectHasSpecifiedPermissionInCurrentApp } from 'utils/selectors/selectors';
import { RolePermission } from 'utils/constants/role-permission';
import reportEvent, { EVENT_ACTIONS } from 'utils/reportEvent';
import { SubscribeButton } from './ProductSummaryPage/SubscribeButton';
import _style from './DailyReportConfigModalButton.scss';

const DailyReportConfigModalButton = (props) => {
  const {
  } = props;

  const hasEditPermission = useSelector(state => selectHasSpecifiedPermissionInCurrentApp(state, RolePermission.EDIT));

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitTrigger, setSubmitTrigger] = useState(0);

  function onCancel() {
    setVisible(false);
    setSubmitTrigger(0);
  }

  function onSave() {
    setLoading(true);
    setSubmitTrigger(submitTrigger + 1);
    // 日报订阅，数据上报
    reportEvent({
      action: EVENT_ACTIONS.CLICK,
      tp1: '日报订阅',
    });
  }

  const modalDom = <Modal
    title={ze('日报订阅', 'Subscribe Daily Report')}
    visible={visible}
    width='620px'
    maskClosable={false}
    destroyOnClose={true}
    onCancel={() => onCancel()}
    footer={
      <div className={_style.modalFooter}>
        { !hasEditPermission && <div style={{ marginBottom: '8px' }}>{ ze('你没有修改日报订阅配置的权限（需要管理员权限）', 'You do not have permission to edit the config. (Admin permission required)') }</div> }
        <Space>
          <Button
            disabled={loading}
            onClick={() => onCancel()}
          >{ ze('取消','Cancel') }</Button>
          <Button
            disabled={!hasEditPermission || loading}      
            type='primary'
            onClick={() => onSave()}
          >{ ze('保存修改', 'Save') }</Button>
        </Space>
      </div>
   }
  >
    <Spin spinning={loading}>
      <DailyReportConfigCore
        hasEditPermission={hasEditPermission}
        submitTrigger={submitTrigger}
        onSubmitDone={() => {
          setLoading(false);
          setVisible(false);
          setSubmitTrigger(0);
        }}
      />
    </Spin>
  </Modal>;

  return <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
    <SubscribeButton onClick={() => setVisible(true)} />
    {/* <Button
      onClick={() => setVisible(true)}
    >{ ze('日报订阅', 'Subscribe Daily Report') }</Button> */}
    { modalDom }
  </div>;
};

export default DailyReportConfigModalButton;
