import { fromJS } from 'immutable';
import { createAction } from 'redux-actions';
import SdkAppRest from 'utils/SdkAppRest';
import { PAGE_TYPE_CONVERT, hasDataNeedLoad } from 'utils/constants';
import { getStartEndDateFromStr, changeDateFormat } from 'utils/StringUtils';
import { transdate } from 'utils/helper';

export const updateSearchParams = createAction('UPDATE_SEARCH_PARAMS_SDK_CRASH_PAGE');// 更新搜索参数

export const updatePageNum = createAction('UPDATE_PAEG_NUM_SDK_CRASH_PAGE');// 更新页数

/**
 * 获取sdk版本
 */
export function getSDKVersions() {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    const yesterday = getState().sdkCrashVersion.get('yesterday');
    const timeStmp = transdate(`${yesterday} 00:00:00`);
    dispatch({
      rest: SdkAppRest.sdkSummary.sdkVersions,
      data: {
        appId,
        pid,
        date: timeStmp,
      },
    });
  };
}

/**
 * 异常版本分布获取列表的接口
 */
export function getCrashListInfo(version, mod, paramType) {
  return (dispatch, getState) => {
    const dataList = fromJS(getState().sdkCrashVersion.get('dataList'));
    // 如果没有数据需要加载，直接返回
    if (paramType === 'version' && !hasDataNeedLoad(dataList, version)) {
      return Promise.resolve();
    }
    const { appId, pid } = getState().app.get('current').toJS();
    const { date, chartDate, pageType } = getState().sdkCrashVersion.get('searchParams').toJS();
    const tempDate = (mod === 'chart' ? chartDate : date);// 需要区分是图表还是列表的时间
    const dateObj = getStartEndDateFromStr(tempDate.date, tempDate.startDate, tempDate.endDate);
    const startTime = changeDateFormat(dateObj.startDate, 'YYYY-MM-DD');
    const endTime = changeDateFormat(dateObj.endDate, 'YYYY-MM-DD');
    const startTimeStmp = transdate(`${startTime} 00:00:00`);
    const endTimeStmp = transdate(`${endTime} 00:00:00`);
    return dispatch({
      rest: SdkAppRest.sdkCrash.crashSdkList,
      data: {
        mod,
        type: PAGE_TYPE_CONVERT[pageType],
        appId,
        pid,
        versions: encodeURIComponent(version),
        startDate: startTimeStmp,
        endDate: (mod === 'chart' ? endTimeStmp : startTimeStmp),
      },
    });
  };
}

/**
 * 获取topN的crash sdk version
 */
export function getTopSDKCrashVersions() {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    const limit = getState().sdkCrashVersion.get('limit');
    const yesterday = getState().sdkCrashVersion.get('yesterday');
    const timeStmp = transdate(`${yesterday} 00:00:00`);// 以这个时间去获取TopN的版本
    const {
      chartDate, pageType, customVersion, version,
    } = getState().sdkCrashVersion.get('searchParams').toJS();
    const dateObj = getStartEndDateFromStr(chartDate.date, chartDate.startDate, chartDate.endDate);
    const startTime = changeDateFormat(dateObj.startDate, 'YYYY-MM-DD');
    const endTime = changeDateFormat(dateObj.endDate, 'YYYY-MM-DD');
    const startTimeStmp = transdate(`${startTime} 00:00:00`);
    const endTimeStmp = transdate(`${endTime} 00:00:00`);
    const versions = (customVersion === 'top5') ? ' ' : encodeURIComponent(version);
    return dispatch({
      rest: SdkAppRest.sdkCrash.topCrashVersions,
      data: {
        appId,
        pid,
        limit,
        yesterday: timeStmp,
        custom: (customVersion === 'top5') ? 'top' : 'auto',
        versions: encodeURIComponent(versions),
        type: PAGE_TYPE_CONVERT[pageType],
        startDate: startTimeStmp,
        endDate: endTimeStmp,
      },
    });
  };
}
