/**
 * Created by ellis on 23/11/2016.
 */
import { createAction } from 'redux-actions';
import appRest from 'utils/AppRest';
import symbolRest from 'utils/SymbolRest';
import { RESET_SYMBOL_DATA } from 'store/actions';

export function getOptions() {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    return dispatch({
      rest: appRest.App.versionList,
      data: {
        appId,
        platformId: pid,
      },
    });
  };
}

export function getList(query) {
  return (dispatch, getState) => {
    const { appId, platformId } = getState().app.get('current').toJS();
    return dispatch({
      rest: symbolRest.symbol.list,
      data: Object.assign({}, query, { appId, platformId }),
    });
  };
}

export const resetSymbolData = createAction(RESET_SYMBOL_DATA);
