import { PLATFORM_ID } from './constants.js';
/*
*校验js
 */

// 检查昵称
export function checkNickName(str) {
  return str.toString().length !== 0;
}

// 检查邮箱
export function checkEmail(str) {
  const emailReg = /\S+@\S+\.\S+/;
  return emailReg.test(str);
}

export function checkVersion(version) {
  return version && /^[0-9a-zA-Z-_\.\(\)\u4E00-\u9FA5 ]*$/.test(version) && version.length > 0 && version.length < 100;
}

export function checkChannel(channel) {
  return channel && /^[0-9a-zA-Z-_\.\(\)\u4E00-\u9FA5]*$/.test(channel) && channel.length > 0 && channel.length < 100;
}

export function checkPlatform(platform) {
  // return Number.isInteger(platform) && (platform === 1 || platform === 2 || platform === 10);
  if (Number.isInteger(platform)) {
    for (let key in PLATFORM_ID) {
      if (PLATFORM_ID[key] === platform) {
        return true;
      }
    }
  }
  return false;
}
