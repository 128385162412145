import React, { useEffect, useState } from 'react';
import { Button, Col, Input, message, Modal, Row, Spin, Tooltip } from 'antd';
import { connect, useDispatch, useSelector } from 'react-redux';
import style from 'containers/NintendoSwitchConfigPage/style.scss';
import { useTranslation } from 'react-i18next';
import { ze } from 'utils/zhEn';
import { selectHasSpecifiedPermissionInCurrentApp } from 'utils/selectors/selectors';
import Xlsx from 'xlsx';
import { WebAppSettings } from 'utils/web-app-settings';
import { upsertWebAppSettings } from 'reducers/app/appActions';
import _style from './UserIdDescriptionConfigPage.scss'
import CopyRight from 'components/commons/CopyRight';
import ViewIcon from 'svg/v2/newcs_dashboard_setting_alertsetting_useriddescriptionconfig_view_icon.svg';

export const UserIdDescriptionConfigUtil = Object.freeze({
  parseCsvContent(csvContent) {
    let res = {
      descriptionFieldList: [],
      userIdList: [],
      userIdToDescKeyValueList: {},
      errorMessage: '',
    };

    try {
      if (!(csvContent || '').trim()) {
        return res;
      }
      if (csvContent.length > 1024 * 1024) {
        res.errorMessage = '内容过长，最大支持1MB配置数据。';
        return res;
      }

      const wb = Xlsx.read(csvContent, {type: 'binary'});
      let json = Xlsx.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { header: 1, raw: false });
      json = (json || []).filter(row => row.length > 0);
      if (json.length <= 1) {
        res.errorMessage = '内容格式不合法。必须有头部及至少一行数据行。';
        return res;
      }

      let [header, ...body] = json;
      if (header.length <= 1) {
        res.errorMessage = '内容格式不合法。头部必须有至少包含两列，其中第一列是用户ID';
        return res;
      }
      if (header.length > 6) {
        res.errorMessage = '最多支持5个描述列。';
        return res;
      }

      const validBody = body.filter(row => row && row.length >= 2);

      const descriptionFieldList = header.slice(1);
      const userIdList = validBody.map(row => row[0]);
      validBody.forEach(row => {
        const [userId, ...fieldValues] = row;
        res.userIdToDescKeyValueList[userId] = descriptionFieldList.map((field, i) => {
          return {
            key: field,
            value: fieldValues[i] || '',
          }
        });
      });

      res.descriptionFieldList = descriptionFieldList;
      res.userIdList = userIdList;
      return res;
    } catch (e) {
      console.error(e);
      res.errorMessage = '解析CSV时发生异常';
      return res;
    }
  }
});

const UserIdDescriptionConfigPage = ({ reduxState }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentApp = reduxState.app.get('current').toJS();
  const { appId } = currentApp;
  const settings = reduxState.app.get('appIdToWebAppSettings')[appId];
  const configCsvText = (settings || {})[WebAppSettings.keys.userIdDescriptionConfigCsvText] || '';

  const hasEditPermission = useSelector(state => selectHasSpecifiedPermissionInCurrentApp(state, 'EDIT'));

  const [loading, setLoading] = useState(true);
  const [text, setText] = useState('');
  const [formatDescModalVisible, setFormatDescModalVisible] = useState(false);

  useEffect(() => {
    if (!appId) {
      return;
    }
    setText(configCsvText);
    setLoading(false);
  }, [appId, configCsvText]);

  async function onClickSave() {
    const { errorMessage, userIdToDescKeyValueList, userIdList, descriptionFieldList } = UserIdDescriptionConfigUtil.parseCsvContent(text);
    if (errorMessage) {
      message.error(errorMessage);
      return;
    }

    setLoading(true);
    await dispatch(upsertWebAppSettings(appId, {
      [WebAppSettings.keys.userIdDescriptionConfigCsvText]: text,
    }));
    message.success(ze('保存成功', 'Successfully saved.'));
    setLoading(false);
  }

  const formatDescTextExample = `${ze('用户ID', 'userId')}, ${ze('昵称', 'nickname')}, qq\nxaDrz0, Wenqing, 100012345\nAL0wqr, Mike, 100012123\npq9Mxe, Terry, 200023456`;

  return <div className={_style.config_page_box}><Spin spinning={loading}>
    <div className={`clearfix ${_style.config_page}`}>
      {/* <div className={_style.cardHead}>{ t('EXCP_LEFTMENU.userIdDescriptionConfig') }</div> */}
      <div style={{ padding: '24px' }}>
        <div className={_style.headerTip}>
          { ze('可配置上报用户ID备注表（CSV格式），配置过备注的用户ID在查看上报详情时可以额外展示信息。', 'You can configure a user ID remark table (in CSV format) for reporting. When viewing report details, additional information can be displayed for user IDs that have been configured with remarks.') }
          <a onClick={() => setFormatDescModalVisible(true)}><ViewIcon style={{marginLeft:'10px',marginRight:'4px'}}/>{ ze('查看备注表格式说明', 'View Format Description') }</a>
        </div>

        <div>
          <Input.TextArea
            style={{ height: '400px',padding:'10px' }}
            readOnly={!hasEditPermission}
            value={text}
            onChange={e => setText(e.target.value)}
          />
        </div>
        <div style={{ marginTop: '10px' }}>
          { hasEditPermission && <Button type='primary' onClick={() => onClickSave()}>{ ze('保存', 'Save') }</Button> }
          { !hasEditPermission && ze('仅管理员权限用户可编辑', 'Only admins can edit the config.') }
        </div>
      </div>
    </div>

    <Modal
      visible={formatDescModalVisible}
      onOk={() => setFormatDescModalVisible(false)}
      onCancel={() => setFormatDescModalVisible(false)}
      footer={[
        <Button key="submit" type="primary" onClick={() => setFormatDescModalVisible(false)}>
           {ze('确定', 'OK')}
         </Button>,
       ]}
      title={ze('格式说明', 'Format Description')}
      width='560px'
      wrapClassName={_style.formatDescModal}
    >
      <div>{ ze('配置格式为CSV格式，第一行为表头，第二行开始为表格内容。每一行的列与列之间用英文逗号分隔。', 'The configuration format is CSV format, the first row is the table header, and the second row starts with the table content. The columns of each row are separated from each other by English commas.') }</div>
      <div>{ ze('CSV至少需要包含两列，第一列代表需要映射的用户ID，第二列为用户备注描述字段列。', 'The CSV needs to contain at least two columns, the first column representing the user ID to be mapped and the second column being the user notes description field column.') }</div>
      <div>{ ze('可以配置超过1个用户备注字段列，最大支持5个备注字段。', 'More than 1 user note field column can be configured, and a maximum of 5 note fields are supported.') }</div>
      <div>{ ze('备注列的字段名可以任意指定，会以 字段名: 字段值 的形式显示在上报详情匹配到的用户ID的地方。', 'The field name of the comment column can be specified arbitrarily and will be displayed as Field name: Field value where the user ID is matched to the reported details.') }</div>
      <div style={{ margin:'20px 0 10px' }}>{ ze('下面是一个配置示例，包含2个用户备注字段，并配置了3名用户的ID备注信息。', 'An example with 3 user entries:') }</div>
      <Input.TextArea
        rows={5}
        style={{padding:'10px',resize:'none'}}
        readOnly
        value={formatDescTextExample}
      />
    </Modal>
  </Spin></div>;
};

const mapStateToProps = state => ({
  reduxState: state,
});

export default connect(mapStateToProps)(UserIdDescriptionConfigPage);
