import React from 'react';
import PropTypes from 'prop-types';
import _style from './style.scss';

const ButtonGotoTop = ({
  style,
}) => {
  const gotoTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return <div  className={style ?? _style.goto_top_btn} onClick={gotoTop}></div>;
};

ButtonGotoTop.propTypes = {
  style: PropTypes.object,
};


export default ButtonGotoTop;
