import Cookies from 'js-cookie';

export const EnvUtil = (function () {
  const CN_CDN_HOST = 'cdn.crashsight.qq.com';

  const COOKIE_KEY_IS_CHINA_ENV = 'cs_is_china_env';

  const COOKIE_KEY_IS_SUPER_ADMIN_WATERMARK_DISABLED = 'cs_is_super_admin_watermark_disabled';

  function isCrashSight() {
    const { hostname } = window.location;
    return hostname.includes('crashsight');
  }
  /**
   * 获取是否是国内环境。获取优先级：(1) 超管的手动指定国内、海外环境 (2) 环境的配置文件 (3) 域名。
   * @return {boolean}
   * */
  function isChinaEnv({ ignoreCustomIsChinaEnv = false } = {}) {
    if (!ignoreCustomIsChinaEnv) {
      const v = Cookies.get(COOKIE_KEY_IS_CHINA_ENV);
      if (v === 'true') {
        return true;
      } else if (v === 'false') {
        return false;
      }
    }
    if (typeof process.env.REACT_APP_IS_CHINA_ENV === 'boolean') {
      return process.env.REACT_APP_IS_CHINA_ENV;
    }
    const { hostname } = window.location;
    return hostname.includes('crashsight.qq.com') || hostname.includes('crashsight.woa.com');
  }

  function getCdnHost() {
    return isChinaEnv()
      ? CN_CDN_HOST
      : 'cdn-v2.crashsight.wetest.net';
  }

  /** 环境是否支持用户自助充值 */
  function isSupportUserAddFunds() {
    return isChinaEnv();
  }

  return Object.freeze({
    CN_CDN_HOST,
    COOKIE_KEY_IS_CHINA_ENV,
    COOKIE_KEY_IS_SUPER_ADMIN_WATERMARK_DISABLED,
    isChinaEnv,
    getCdnHost,
    isCrashSight,
    isSupportUserAddFunds,
  });
}());
