import React, { useState, useEffect } from 'react';
import { Badge, Tooltip } from 'antd';
import scss from './CrashSightHeader.scss';
import RestHelper from 'utils/RestHelper';
import { isZh, ze } from 'utils/zhEn';
import NotificationDrawer from './NoticeDrawer.jsx'
import PropTypes from "prop-types";
import reportEvent, { EVENT_ACTIONS } from 'utils/reportEvent.js';
import NoticeIconNormal from 'svg/v2/newcs_dashboard_topbar_notice_icon_normal.svg';

const NoticeButton = (prop) => {
  const { appId } = prop;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [ listNotices, setListNotices ] = useState([]);
  const [ trigger, setTrigger ] = useState(0);

  useEffect(() => {
    if(appId){
      getListNotices();
    }
  },[appId,trigger])

  useEffect(() => {
    const intervalId  = setInterval(() => {
      setTrigger(prevCount => prevCount + 1);
    }, 300000);

    return () => {
      clearInterval(intervalId);
    }
  }, []);

  //获取公告数据
  const getListNotices = async() => {
    setListNotices([]);
    const rsp = await RestHelper.mustPost('/redir/api/notice/listNotices',{
      appId,
      lange: isZh() ? 'zh' : 'en',
    })
    const rspData = rsp.json.data.items;
    const presentData = rspData.filter(item => isZh() ? item.hasZh : item.hasEn);
    const data = (presentData || []).map(item => ({
      ...item,
      showEdit:false,
    }))
    setListNotices(data);
  }

  //用户一键已读所有通知
  const getMarkAllAsRead = async() => {
    await RestHelper.mustPost('/redir/api/notice/markAllAsRead',{
      appId, //把指定项目下的通知全部标为已读
    })
    getListNotices();
  }

  //用户修改通知的已读状态
  const getChangeReadStatus = async(recordId,hasRead) => {
    const rsp = await RestHelper.mustPost('/redir/api/notice/changeReadStatus',{
      appId,
      recordId,
      hasRead: !hasRead, //true表示标为已读false表示标为未读
    })
    getListNotices();
  }

  //打开抽屉弹框
  const drawerShow = () => {
    setDrawerOpen(true);
    // 消息中心曝光
    reportEvent({
      action: EVENT_ACTIONS.EXPOSURE,
      tp1: '消息中心曝光',
    });
  };

  const drawerDisable = () => {
    setDrawerOpen(false);
  }

  //计算未读个数
  const listNoticesHasRead = listNotices.filter(item => !item.hasRead).length

  return (<div style={{cursor: 'pointer', marginTop:'6px'}}>
    <Badge size="small" count={listNoticesHasRead} offset={[5, 0]} onClick={drawerShow} color='#EE2F27'>
      <div className={scss.noticeIcon}>
        <Tooltip title={ze('消息提示','Message')} placement='bottom'><NoticeIconNormal /></Tooltip>
      </div>
    </Badge>
    <NotificationDrawer
      drawerOpen={drawerOpen}
      drawerDisable={drawerDisable}
      listNotices={listNotices}
      getMarkAllAsRead={getMarkAllAsRead}
      getChangeReadStatus={getChangeReadStatus}
    />
  </div>)
}

NoticeButton.propTypes = {
  appId: PropTypes.any,
};

export default NoticeButton;
