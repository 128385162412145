import RestHelper from './RestHelper';
export default RestHelper.publish({
  App: {
    versionList: {
      method: 'get',
      uri: '/versions/appId/{appId}/platformId/{platformId}',
    },

    list: {
      method: 'get',
      uri: '/users/null/appList',
    },

    /**
     * 判断是否是灰度产品
     */
    hasGray: {
      method: 'get',
      uri: '/appId/{appId}/platformId/{platformId}/hasGray',
    },

    /**
     * 事件统计
     */
    report: {
      method: 'get',
      uri: '/mm/report',
    },
    permission: {
      method: 'get',
      uri: '/userAuit/wasEnable/appId/{appId}/pid/{pid}',
    },
    postPermission: {
      method: 'post',
      uri: '/userAuit/enable/appId/{appId}/pid/{pid}',
    },
    sendMessage: {
      method: 'post',
      uri: 'sendMessage/product/{product}/appId/{appId}/pid/{pid}/applyer/{applyer}/reason/{reason}',
    },
  },
});
