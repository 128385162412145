import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push as pushState } from 'connected-react-router';
import { selectApp, hasGray } from 'reducers/app/appActions';
import {
  report,
} from 'reducers/global/globalActions';
import { getExceptionType } from 'utils/helper';
import { updateOperatorRight } from 'reducers/user';
import productRoute from 'route/product';
/**
 * 产品设置页
 */
class ProductPage extends Component {
  render() {
    const {
      url,
    } = this.props;
    return (
      <div style={{ height: '100%' }}>
        { productRoute({ match: { url } }) }
      </div>
    );
  }
}

ProductPage.propTypes = {
  currentApp: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
  appList: PropTypes.object.isRequired,
};

export default connect((state, router) => {
  return {
    url: router.match.url,
    currentApp: state.app.get('current'),
    appList: state.app.get('appList'),
    pathname: router.location.pathname,
  };
}, (dispatch) => ({
  actions: bindActionCreators({
    pushState,
    selectApp,
    hasGray,
    updateOperatorRight,
    report,
  }, dispatch),
}))(ProductPage);
