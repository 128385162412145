import React, { useState, useEffect, useMemo } from 'react';
import { CaretUpFilled, CaretDownFilled } from '@ant-design/icons';
import { CS_STYLES } from 'utils/constants/style-constants';
import TopNoselect from 'svg/v2/newcs_dashboard_list_arrow_top_noselect.svg';
import PropTypes from 'prop-types';

const ASC = 'asc';
const DESC = 'desc';

const SORT_BOX_STYLE = {
  height: '10px',
  // width: '10px',
  // cursor: 'pointer',
};

const SORT_STYLE = {
  width: '8px',
  height: '5px',
  display: 'block',
};


// const SortButton = (props) => {
//   const { order, onChange = (_) => {} } = props;

//   return <div style={{ fontSize: '14px' }}>
//     <div
//       style={{
//         height: '16px',
//         color: order === ASC ? CS_STYLES.PRIMARY_COLOR : '#AAA',
//         cursor: 'pointer',
//       }}
//       onClick={() => onChange(order !== ASC ? ASC : null)}
//     ><CaretUpFilled/></div>
//     <div
//       style={{
//         height: '16px',
//         color: order === DESC ? CS_STYLES.PRIMARY_COLOR : '#AAA',
//         cursor: 'pointer',
//       }}
//       onClick={() => onChange(order !== DESC ? DESC : null)}
//     ><CaretDownFilled/></div>
//   </div>;
// };
const SortButton = (props) => {
  const { order, onChange = (_) => {} } = props;

  return <div style={{ width: '8px' }}>
    <div
      style={{
        marginBottom: '2px',
        transform:'rotate(360deg)'
      }}
      onClick={() => onChange(order !== ASC ? ASC : null)}
    >
      {order === ASC ? <span>{<TopNoselect style={{color:'#807997'}}/>}</span> : <span>{<TopNoselect style={{color:'rgba(128, 121, 151, 0.6)'}}/>}</span>}
    </div>
    <div
      style={{
        ...SORT_BOX_STYLE,
        transform:'rotate(180deg)'
      }}
      onClick={() => onChange(order !== DESC ? DESC : null)}
    >
      {order === DESC ? <span>{<TopNoselect style={{color:'#807997'}}/>}</span> : <span>{<TopNoselect style={{color:'rgba(128, 121, 151, 0.6)'}}/>}</span>}
    </div>
  </div>;
};
SortButton.propTypes = {
  order: PropTypes.string,
  onChange: PropTypes.func,
};

export default SortButton;
