import React from "react";
import style from './CsNewTabs.scss';

const CsNewTabs = ({options = [], value, onChange,}) => {
  return (
    <div className={style.tab}>
      {
        options.map((item) => <div
          className={ item.value === value ? style.tab_item_checked : style.tab_item}
          onClick={() => {
            if (item.value !== value && typeof onChange === 'function') {
              onChange(item.value);
            }
          }}
          >
          <span>{item.label}</span>
        </div>)
      }
    </div>
  );
}

export {
  CsNewTabs
}