import RestHelper from './RestHelper';
export default RestHelper.publish({
  appInfo: {
    get: {
      method: 'get',
      uri: '/appId/{appId}/platformId/{platformId}/appInfo',
    },
    update: {
      method: 'post',
      uri: '/api/app/alterAppInfo',
    },
  },
  versions: {
    get: {
      method: 'get',
      uri: '/versions/appId/{appId}/platformId/{pid}',
    },
  },
  disableVersion: {
    get: {
      method: 'get',
      uri: '/apps/{appId}/platformId/{pid}/userId/{userId}/isShow/{isShow}/disableVersion',
    },
  },
  enableVersion: {
    get: {
      method: 'get',
      uri: '/apps/{appId}/platformId/{pid}/userId/{userId}/isShow/{isShow}/enableVersion',
    },
  },
  dailyConfig: {
    get: {
      method: 'get',
      uri: '/apps/{appId}/dailyReportConfig',
    },
    update: {
      method: 'post',
      uri: '/apps/{appId}/dailyReportConfig/update',
    },
  },
  member: {
    update: {
      method: 'post',
      uri: '/apps/{appId}/members/update',
    },
    delete: {
      method: 'post',
      uri: '/apps/{appId}/members/delete',
    },
    add: {
      method: 'post',
      uri: '/apps/{appId}/members/add',
    },
  },
  product: {
    delete: {
      method: 'post',
      uri: '/apps/{appId}/info/delete',
    },
    init: {
      method: 'post',
      uri: '/apps/add',
    },
    initOa: {
      method: 'post',
      uri: '/apps/addOa',
    },
    hasReport: {
      method: 'get',
      uri: '/apps/{appId}/hasReported',
    },
  },
  webhookConfig: {
    get: {
      method: 'get',
      uri: '/getWebhookConfig/appId/{appId}/platformId/{pid}',
    },
    alter: {
      method: 'post',
      uri: '/alterWebhookConfig/appId/{appId}/platformId/{pid}',
    },
    delete: {
      method: 'post',
      uri: '/deleteWebhookConfig/appId/{appId}/platformId/{pid}',
    },
  },
});
