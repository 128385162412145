import { Map } from 'immutable';

const initialSelectOptions = new Map({
  version: {
    options: [],
  },
  channelId: {
    options: [],
  },
  dateScope: {
    options: [
      { label: '按天统计', value: 'day' },
      { label: '按周统计', value: 'week' },
      { label: '按月统计', value: 'month' },
    ],
  },
});

export const initialSearchParams = new Map({
  version: '-1',
  channel: '-1',
  date: { date: 'today', startDate: '', endDate: '' },
  rankversion: 'new',
  rankchannel: 'new',
  dataType: 'hourly',
});

// 初始化state
export const initialState = new Map({
  selectOptions: initialSelectOptions,
  searchParams: initialSearchParams,
  currentTab: '使用用户',
  trendData: false,
  isShowDetail: false,
  rankversionData: new Map(),
  rankchannelData: new Map(),
});
