import { Map } from 'immutable';
import { handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'store/actions';
import { parseSearch } from 'utils/helper';
import { initialState, initialSearchParams, initialDurationParams } from './initialState';

export default handleActions({
  [LOCATION_CHANGE]: (state, action) => {
    const { location } = action.payload;
    const { pathname } = location;
    const query = parseSearch(location);
    if (pathname.match(/analytics\/users\/retention/i)) {
      const newParams = initialSearchParams.toJS();
      if (query.date) {
        newParams.date.date = query.date;
        newParams.date.startDate = query.startDate;
        newParams.date.endDate = query.endDate;
      }
      if (query.version) {
        newParams.version = query.version;
      }
      if (query.channel) {
        newParams.channel = query.channel;
      }
      if (query.dateScope) {
        newParams.dateScope = query.dateScope;
      }
      return state.set('retentParams', new Map(newParams));
    } else if (pathname.match(/^userdata\/duration\/apps/i)) {
      const newParams = initialDurationParams.toJS();
      if (query.date) {
        newParams.date.date = query.date;
        newParams.date.startDate = query.startDate;
        newParams.date.endDate = query.endDate;
      }
      if (query.version) {
        newParams.version = query.version;
      }
      if (query.channel) {
        newParams.channel = query.channel;
      }
      return state.set('durationParams', new Map(newParams));
    }
    return state;
  },
  GETRETENT_GET_SUCC: (state, action) => state.set('retentData', action.response.data.datas),

  UPDATE_RETENT_PARAMS: (state, action) => state.update('retentParams', (params) => {
    const { type, value } = action.payload;
    return params.set(type, value);
  }),

  UPDATE_DURATION_PARAMS: (state, action) => state.update('durationParams', (params) => {
    const { type, value } = action.payload;
    return params.set(type, value);
  }),

  CHANGE_DURATION_CURTAB: (state, action) => state.set('durationCurTab', action.payload),

  GETSTARTUPCOUNTTREND_GET_SUCC: (state, action) => state.set('durationData', action.response.data),

  GETUSETIMETREND_GET_SUCC: (state, action) => state.set('durationData', action.response.data),

  GETDAYUSETIMETREND_GET_SUCC: (state, action) => state.set('durationData', action.response.data),
}, initialState);
