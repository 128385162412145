import { List } from 'immutable';
import { handleActions } from 'redux-actions';
import {
  LOCATION_CHANGE,
  GETVERSIONTREND_GET_SUCC,
  UPDATE_VERSION_PARAMS,
  CHANGE_CURTAB,
  UPDATE_PAEG_NUM,
  GETSELECTOR_GET_SUCC,
} from 'store/actions';
import {
  getNumFound, getTop5, getVersionStr, getArraySum, getDayFillData, parseSearch,
} from 'utils/helper';
import { initialState, initialSearchParams } from './initialState';

export default handleActions({
  [LOCATION_CHANGE]: (state, action) => {
    const { location } = action.payload;
    const { pathname } = location;
    const query = parseSearch(location);
    if (pathname.match(/analytics\/users\/versions/i)) {
      const date = { date: query.date ? query.date : 'last_7_day', startDate: query.startDateStr ? query.startDateStr : '', endDate: query.endDateStr ? query.endDateStr : '' };
      const version = query.customVersion === 'custom' ? getVersionStr(query.version) : '';
      return state.update('searchParams', (searchParams) => {
        return Object.keys(query).length ? searchParams.merge(query).set('date', date).set('version', version) : initialSearchParams;
      });
    }
    return state;
  },
  [GETVERSIONTREND_GET_SUCC]: (state, action) => {
    const rows = state.get('searchParams').get('rows');
    // datas 和 dataMap 新旧两个接口返回结构有些不同，先兼容下
    const datas = (action.response.data && (action.response.data.datas || action.response.data.dataMap)) || {};
    const { startDate, endDate } = action.params;
    const dataMap = {};
    Object.keys(datas).forEach((key) => {
      if (datas[key] && datas[key].length > 0) {
        dataMap[key] = getDayFillData(datas[key].reverse(), { startHour: startDate, endHour: endDate });
      }
    });
    const numFound = getNumFound(dataMap);
    return state.set('dataList', dataMap)
      .set('top5Version', getTop5('addUserAmount', dataMap))
      .set('totalAddUserAmount', getArraySum('addUserAmount', (dataMap && dataMap['-1']) ? dataMap['-1'] : []))
      .set('numFound', numFound)
      .set('totalPages', Math.floor(numFound / rows) + (numFound % rows === 0 ? 0 : 1));
  },
  [UPDATE_VERSION_PARAMS]: (state, action) => state.update('searchParams', (params) => {
    const { type, value } = action.payload;
    return params.set(type, value);
  }),
  [CHANGE_CURTAB]: (state, action) => state.set('currentTab', action.payload),
  [UPDATE_PAEG_NUM]: (state, action) => state.set('totalPages', Math.floor(getNumFound(state.get('dataList')) / action.payload) + (getNumFound(state.get('dataList')) % action.payload === 0 ? 0 : 1)),
  [GETSELECTOR_GET_SUCC]: (state, action) => {
    const { channelList = [], versionList = [] } = action.response;
    return state.update('selectOptions', (options) => options.merge({
      version: {
        options: new List(versionList).map((item) => ({
          label: (parseInt(item.enable) === 0 ? (`${item.name}已关闭`) : item.name),
          value: encodeURIComponent(item.name),
        })).unshift({
          label: '全版本',
          value: '-1',
        }),
      },
      channelId: {
        options: new List(channelList).map((item) => ({ label: item, value: encodeURIComponent(item) })).unshift({
          label: '全渠道',
          value: '-1',
        }),
      },
    }));
  },
}, initialState);
