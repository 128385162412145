import { fromJS } from 'immutable';
import { createAction } from 'redux-actions';
import SdkAppRest from 'utils/SdkAppRest';
import { PAGE_TYPE_CONVERT, hasDataNeedLoad } from 'utils/constants';
import { getStartEndDateFromStr, changeDateFormat } from 'utils/StringUtils';
import { transdate } from 'utils/helper';

export const updateSearchParams = createAction('UPDATE_SEARCH_PARAMS_SDK_APP_PAGE');// 更新搜索参数

export const updatePageNum = createAction('UPDATE_PAEG_NUM_SDK_APP_PAGE');// 更新页数


/**
 * 判断权限是否能看联网
 */
export function hasPriority() {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    const priType = getState().sdkApp.get('pri_type');
    return dispatch({
      rest: SdkAppRest.sdkApp.hasPriority,
      data: {
        appId,
        pid,
        pri_type: priType,
      },
    });
  };
}


/**
 * 获取sdk版本
 */
export function getSDKVersions() {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    const yesterday = getState().sdkApp.get('yesterday');
    const timeStmp = transdate(`${yesterday} 00:00:00`);
    dispatch({
      rest: SdkAppRest.sdkSummary.sdkVersions,
      data: {
        appId,
        pid,
        date: timeStmp,
      },
    });
  };
}

/**
 * 获取bundles,会根据version不同而改变
 */
export function getAppBundles() {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    const yesterday = getState().sdkApp.get('yesterday');
    const { chartVersion } = getState().sdkApp.get('searchParams').toJS();
    const timeStmp = transdate(`${yesterday} 00:00:00`);
    dispatch({
      rest: SdkAppRest.sdkApp.sdkAppBundles,
      data: {
        appId,
        pid,
        version: chartVersion,
        date: timeStmp,
      },
    });
  };
}

/**
 * 异常应用分布获取列表的接口
 */
export function getSdkAppListInfo(bundle, mod, paramType) {
  return (dispatch, getState) => {
    console.log('bundle', bundle);
    const dataList = fromJS(getState().sdkApp.get('dataList'));
    // 如果没有数据需要加载，直接返回
    if (paramType === 'bundle' && !hasDataNeedLoad(dataList, bundle)) {
      return Promise.resolve();
    }
    const { appId, pid } = getState().app.get('current').toJS();
    const {
      date, chartDate, pageType, version, chartVersion,
    } = getState().sdkApp.get('searchParams').toJS();
    const tempDate = (mod === 'chart' ? chartDate : date);// 需要区分是图表还是列表的时间
    const dateObj = getStartEndDateFromStr(tempDate.date, tempDate.startDate, tempDate.endDate);
    const startTime = changeDateFormat(dateObj.startDate, 'YYYY-MM-DD');
    const endTime = changeDateFormat(dateObj.endDate, 'YYYY-MM-DD');
    const startTimeStmp = transdate(`${startTime} 00:00:00`);
    const endTimeStmp = transdate(`${endTime} 00:00:00`);
    const ver = (mod === 'chart' ? chartVersion : version);// 版本
    return dispatch({
      rest: SdkAppRest.sdkApp.sdkAppList,
      data: {
        mod,
        type: PAGE_TYPE_CONVERT[pageType],
        appId,
        pid,
        version: encodeURIComponent(ver),
        bundles: encodeURIComponent(bundle),
        startDate: startTimeStmp,
        endDate: (mod === 'chart' ? endTimeStmp : startTimeStmp),
      },
    });
  };
}

/**
 * 获取topN的app sdk bundle
 */
export function getTopSDKApp() {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    const limit = getState().sdkApp.get('limit');
    const yesterday = getState().sdkApp.get('yesterday');
    const timeStmp = transdate(`${yesterday} 00:00:00`);// 以这个时间去获取TopN的版本
    const { chartDate, pageType, chartVersion } = getState().sdkApp.get('searchParams').toJS();
    const dateObj = getStartEndDateFromStr(chartDate.date, chartDate.startDate, chartDate.endDate);
    const startTime = changeDateFormat(dateObj.startDate, 'YYYY-MM-DD');
    const endTime = changeDateFormat(dateObj.endDate, 'YYYY-MM-DD');
    const startTimeStmp = transdate(`${startTime} 00:00:00`);
    const endTimeStmp = transdate(`${endTime} 00:00:00`);
    return dispatch({
      rest: SdkAppRest.sdkApp.topApp,
      data: {
        appId,
        pid,
        limit,
        yesterday: timeStmp,
        version: encodeURIComponent(chartVersion),
        type: PAGE_TYPE_CONVERT[pageType],
        startDate: startTimeStmp,
        endDate: endTimeStmp,
      },
    });
  };
}
