/**
 * Created by ellis on 24/11/2016.
 */
import RestHelper from './RestHelper';

const SymbolRest = RestHelper.publish({
  symbol: {
    list: {
      method: 'post',
      uri: '/symbolTable/list',
    },
  },
});

export default SymbolRest;
