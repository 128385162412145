import React, { useState, useEffect, useMemo, useContext } from 'react';
import { DatePicker, Input, InputNumber, Select, Space, Tooltip } from 'antd';
import { isNotNullish, isNullish } from 'utils/nullish';
import { CaseSensitivity, QueryType } from 'components/commons/IssueCrashFilter/IssueCrashFilterExUtil';
import { ze } from 'utils/zhEn';
import { VscCaseSensitive } from 'react-icons/vsc';
import { CS_STYLES } from 'utils/constants/style-constants';
import { VersatileKeywordSearchMethod } from 'components/commons/IssueCrashFilter/VersatileKeywordSearchMethod';
import WrappedTipsIcon from 'components/antd-extension/WrappedTipsIcon.jsx';

const SearchMethod = VersatileKeywordSearchMethod;

const WILDCARD_PLACEHOLDER = ze('支持 * 和 ? 通配符', 'Supports * and ? as wildcards.');

const SearchMethodToInfo = {
  [SearchMethod.IS_EMPTY]: {
    queryType: QueryType.NONEMPTY,
    not: true,
  },
  [SearchMethod.IS_NOT_EMPTY]: {
    queryType: QueryType.NONEMPTY,
    not: false,
  },
  [SearchMethod.CONTAINS]: {
    queryType: QueryType.TERM_CONTAINS,
    not: false,
    isInput: true,
  },
  [SearchMethod.NOT_CONTAINS]: {
    queryType: QueryType.TERM_CONTAINS,
    not: true,
    isInput: true,
  },
  [SearchMethod.EQUALS]: {
    queryType: QueryType.TERM,
    not: false,
    isInput: true,
  },
  [SearchMethod.NOT_EQUALS]: {
    queryType: QueryType.TERM,
    not: true,
    isInput: true,
  },
  [SearchMethod.IN]: {
    queryType: QueryType.TERMS,
    not: false,
    isTermsLike: true,
  },
  [SearchMethod.NOT_IN]: {
    queryType: QueryType.TERMS,
    not: true,
    isTermsLike: true,
  },
  [SearchMethod.WILDCARD]: {
    queryType: QueryType.TERM_WILDCARD,
    not: false,
    isInput: true,
    placeholder: WILDCARD_PLACEHOLDER,
  },
  [SearchMethod.NOT_WILDCARD]: {
    queryType: QueryType.TERM_WILDCARD,
    not: true,
    isInput: true,
    placeholder: WILDCARD_PLACEHOLDER,
  },
  [SearchMethod.TERMS_WILDCARD]: {
    queryType: QueryType.TERMS_WILDCARD,
    not: false,
    isTermsLike: true,
  },
  [SearchMethod.NOT_TERMS_WILDCARD]: {
    queryType: QueryType.TERMS_WILDCARD,
    not: true,
    isTermsLike: true,
  },
};

const SearchMethodInfoEntries = Object.entries(SearchMethodToInfo);

function findSearchMethod(queryType, not) {
  const foundEntry = SearchMethodInfoEntries.find(([k, v]) => {
    return v.queryType === queryType && v.not === Boolean(not);
  });
  return isNotNullish(foundEntry) ? foundEntry[0] : null;
}

function makeLabelWithTooltip(label, tooltipContent) {
  if (!tooltipContent) {
    return label;
  }
  return<Tooltip
    placement='right'
    title={tooltipContent}
  >
    <Space>
      <div>{ label }</div>
      <WrappedTipsIcon />
    </Space>
  </Tooltip>;
}

const QUERY_TYPE_OPTIONS = [
  { name: ze('为空', 'Is Empty'), value: SearchMethod.IS_EMPTY },
  { name: ze('非空', 'Not Empty'), value: SearchMethod.IS_NOT_EMPTY },
  { name: ze('等于', 'Equals'), value: SearchMethod.EQUALS },
  { name: ze('不等于', 'Not Equals'), value: SearchMethod.NOT_EQUALS },
  {
    name: ze('包含', 'Contains'),
    value: SearchMethod.CONTAINS,
    description: ze('搜索内容必须是被搜索字段的子串。', 'The search content must be a substring of the field.'),
  },
  {
    name: ze('不包含', 'Not Contains'),
    value: SearchMethod.NOT_CONTAINS,
    description: ze('搜索内容必须不是被搜索字段的子串。', 'The search content must NOT be a substring of the field.'),
  },
  {
    name: ze('IN', 'In'),
    value: SearchMethod.IN,
    description: ze('被搜索字段必须等于（精确匹配）指定的多个值中的某一项。', 'The field must be equal to (exact match) one of the specified multiple values.'),
  },
  {
    name: ze('NOT IN', 'Not In'),
    value: SearchMethod.NOT_IN,
    description: ze('被搜索字段必须不等于指定的多个值当中的任何一项。', 'The field must NOT be equal to (exact match) any of the specified multiple values.'),
  },
  {
    name: ze('通配符匹配', 'Wildcard'),
    value: SearchMethod.WILDCARD,
    description: ze('被搜索字段必须匹配指定的通配符表达式，支持的通配符是“*”和“?”。', 'The field must wildcard match the search content. Supported wildcards are "*" and "?".'),
  },
  {
    name: ze('通配符不匹配', 'Not Wildcard'),
    value: SearchMethod.NOT_WILDCARD,
    description: ze('被搜索字段不能匹配指定的通配符表达式，支持的通配符是“*”和“?”。', 'The field must NOT wildcard match the search content. Supported wildcards are "*" and "?".'),
  },
  {
    name: ze('IN(通配符)', 'In (Wildcard)'),
    value: SearchMethod.TERMS_WILDCARD,
    description: ze('被搜索字段必须匹配指定的多个值中的某一项。若输入值不带通配符，匹配方式是精确匹配。若输入值包含通配符，匹配方式是通配符匹配。支持的通配符是“*”和“?”。 ', 'The field must exact match or wildcard match one of the specified multiple values. Supported wildcards are "*" and "?".'),
  },
  {
    name: ze('NOT IN(通配符)', 'Not In (Wildcard)'),
    value: SearchMethod.NOT_TERMS_WILDCARD,
    description: ze('被搜索字段必须不匹配指定的多个值当中的任何一项。若输入值不带通配符，匹配方式是精确匹配。若输入值包含通配符，匹配方式是通配符匹配。支持的通配符是“*”和“?”。 ', 'The field must NOT exact match or wildcard match any of the specified multiple values. Supported wildcards are "*" and "?".'),
  },
].map(x => ({
  ...x,
  label: makeLabelWithTooltip(x.name, x.description),
}));

function getQueryTypeOptions(searchMethodWhitelist, hasIsEmptyQueryType) {
  if (searchMethodWhitelist && searchMethodWhitelist.length > 0) {
    return QUERY_TYPE_OPTIONS.filter(x => searchMethodWhitelist.includes(x.value));
  } else if (!hasIsEmptyQueryType) {
    return QUERY_TYPE_OPTIONS.filter(x => x.value !== SearchMethod.IS_EMPTY && x.value !== SearchMethod.IS_NOT_EMPTY);
  } else {
    return QUERY_TYPE_OPTIONS;
  }
}

const VersatileKeywordSearch = ({
  value,
  searchMethodWhitelist,
  hasIsEmptyQueryType,
  onChange = () => {},
  defaultQueryType,
  isReadOnly,
}) => {
  const {
    queryType: nullableQueryType,
    not,
    term,
    terms,
    caseSensitivity,
  } = value || {};

  const queryType = nullableQueryType || defaultQueryType || QueryType.TERM_CONTAINS;
  let searchMethodValue = findSearchMethod(queryType, not);
  const { isInput, isTermsLike, placeholder, } = SearchMethodToInfo[searchMethodValue] || {};

  const queryTypeOptions = useMemo(() => getQueryTypeOptions(searchMethodWhitelist, hasIsEmptyQueryType), [searchMethodWhitelist, hasIsEmptyQueryType]);

  return <div style={{ display: 'flex' }}>
    <Select
      style={{ width: '120px', flex: '0 0 auto' }}
      options={queryTypeOptions}
      optionFilterProp='name'
      optionLabelProp='name'
      value={ searchMethodValue }
      onChange={(v) => {
        if (isReadOnly) {
          return;
        }
        const newInfo = SearchMethodToInfo[v];
        onChange({ queryType: newInfo.queryType, not: newInfo.not, caseSensitivity });
      }}
    />
    { queryType === QueryType.NONEMPTY && <Input
      style={{ flexGrow: 1 }}
      disabled
    /> }
    { isInput && <Input
      style={{ flexGrow: 1 }}
      placeholder={placeholder}
      suffix={<Tooltip title={ze('开启/关闭大小写敏感', 'Match Case')}><VscCaseSensitive
        style={{
          ...(isReadOnly ? { cursor: 'pointer' } : {}),
          transform: 'translateY(1px) scale(1.5)',
          color: caseSensitivity === CaseSensitivity.SENSITIVE ? CS_STYLES.PRIMARY_COLOR : '#999',
        }}
        onClick={() => {
          if (isReadOnly) {
            return;
          }
          const newCaseSensitivity = caseSensitivity === CaseSensitivity.SENSITIVE
            ? CaseSensitivity.INSENSITIVE
            : CaseSensitivity.SENSITIVE;
          onChange({ queryType, not, term, caseSensitivity: newCaseSensitivity });
        }}
      /></Tooltip>}
      value={ term }
      onChange={(e) => {
        if (isReadOnly) {
          return;
        }
        const v = e.target.value;
        onChange({ queryType, not, term: v, caseSensitivity });
      }}
    /> }
    { isTermsLike && <Select
      mode='tags'
      placeholder='输入一个或多个值。单击弹出的选项，或按回车键确认输入的值'
      style={{ flexGrow: 1 }}
      value={terms}
      allowClear={true}
      notFoundContent={null}
      onChange={v => {
        if (isReadOnly) {
          return;
        }
        onChange({ queryType, not, terms: v, caseSensitivity });
      }}
    />}
  </div>
}

export const VersatileKeywordSearchUtil = Object.freeze({
  SearchMethod,
});

export default VersatileKeywordSearch;
