import React from 'react';
import { ze } from 'utils/zhEn';

const TOOLTIP_CONTENT = [
  [ze('内容是根据除_以外的标点符号和空格进行分词的。','The content is divided into words based on punctuation marks and spaces other than _.')],
  [ze('搜索不区分大小写。','The search is not case sensitive.')],
  [ze('支持搜索多个短语（用空格分隔），表示搜索结果需要同时匹配所有的短语。','Supports searching multiple phrases (separated by spaces) . The search results must contain all the phrases.')],
  [ze('短语分两种类型。一种是不带除_以外任何标点的单词，一种是带_以外的标点的短语。','There are two types of phrases. One is a word without any punctuation marks, _ excluded. The other is a phrase that contains punctuation marks, _ excluded.')],
  [
    ze('对于不带除_以外任何标点的单词，处理方式是包含搜索，堆栈分词列表中任何一词只要包含搜索内容即为匹配。','For a word without any punctuation marks other than _, the keyword will be used as a partial string. As long as a word in the stack word list include the keyword, it will be matched.'),
    ze('例如搜索oba可以匹配到foobar。',' For example, searching for "oba" will match results such as "foobar".'),
    ze('不可以跨越多个堆栈分词。例如搜索filename无法匹配到file name， 搜索libso无法匹配lib.so。','It is not possible to use a keyword to search multiple stack words. For example, searching for "filename" will not match "file name" and searching for "ibso" will not match "ib.so"')
  ],
  [
    ze('对于带_以外的标点的短语，处理方式是精确匹配。','For phrases containing punctuation marks other than _, the keyword needs to be an exact match.'),
    ze('例如搜索unity.so不能匹配到libunity.so，必须搜索完整的libunity.so','For example, searching for "unity.so" will not match "libunity.so". You must use the full "libunity.so" instead.'),
  ],

];

export function getCrashStackSearchTooltipDom() {
  return <ul>{
    TOOLTIP_CONTENT.map(x => <li
      style={{ listStyleType: 'disc', listStylePosition: 'inside', textIndent: '-20px', marginLeft: '20px', whiteSpace: 'pre-wrap', fontSize: '16px' }}
    >{x.join('\n')}</li>)
  }</ul>;
}
