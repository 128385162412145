import React, { useRef } from 'react';
import _style from './style.scss';
import SubscribeIcon from 'svg/v2/newcs_dashboard_overview_subscribedailyreport.svg';

export const SubscribeButton = (props) => {
  const { style = {}, onClick } = props;
  const ref = useRef(null);
  return <div ref={ref} className={_style.subscribeBtn} onClick={() => onClick()}>
    {
       <SubscribeIcon style={{height: 28, width: 28, ...style}} />
    }
  </div>;
}
