import RestHelper from './RestHelper';

const globalRest = RestHelper.publish({
  getSelector: {// 获取下拉内容
    get: {
      method: 'get',
      uri: '/getSelector/appId/{appId}/platformId/{pid}',
    },
  },
  getVersions: {//
    get: {
      method: 'get',
      uri: '/versions/appId/{appId}/platformId/{pid}',
    },
  },
  getMemberList: {// 获取成员列表
    get: {
      method: 'get',
      uri: '/members/appId/{appId}/platformId/{platformId}',
    },
  },
  trailer: {
    organize: {
      method: 'post',
      uri: '/trail/organize',
    },
    trail: {
      method: 'post',
      uri: '/trail/trailer',
    },
  },
  getNewSelector: {// 获取下拉内容,新的接口.直接调api接口
    get: {
      method: 'get',
      uri: '/getSelectorDatas/appId/{appId}/pid/{pid}',
    },
  },
  appMemberList: {
    get: {
      method: 'get',
      uri: '/getSelectorDatas/appId/{appId}/pid/{pid}',
    },
  },
  report: {
    get: {
      method: 'get',
      uri: '/compass',
    },
  },
  department: {
    list: {
      method: 'get',
      uri: '/department',
    },
    item: {
      method: 'get',
      uri: '/department-item/{itemId}',
    },
  },
  notice: {
    info: {
      method: 'get',
      uri: '/notice',
    },
  },
});

export default globalRest;
