import invert from 'lodash/object/invert';

export const MinidumpUnwindTypeInt = Object.freeze({
  CONTEXT: 1,
  PREWALKED: 2,
  CFI: 3,
  CFI_SCAN: 4,
  FP: 5,
  SCAN: 6,
  INLINE: 7,
  LEAF: 8,
});

const unwindTypeIntToName = invert(MinidumpUnwindTypeInt);

export const MinidumpUnwindUtil = Object.freeze({
  getLabelByTypeInt(typeInt) {
    return (unwindTypeIntToName[typeInt] || 'UNKNOWN').toLowerCase();
  },
});
