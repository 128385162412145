import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import VersionHybridSelect from 'components/commons/VersionHybridSelect';
import { Select } from 'antd';
import { ze } from 'utils/zhEn';
import { QueryType } from 'components/commons/IssueCrashFilter/IssueCrashFilterExUtil';
import { isNullish } from 'utils/nullish';

const VersionSearchMethod = {
  IN: 'IN',
  NOT_IN: 'NOT_IN',
  GTE: 'GTE',
  GT: 'GT',
  LTE: 'LTE',
  LT: 'LT',
};

const SEARCH_METHOD_OPTIONS = [
  { label: ze('包含', 'In'),  value: VersionSearchMethod.IN },
  { label: ze('不包含', 'Not In'),  value: VersionSearchMethod.NOT_IN },
  { label: ze('大于', '>'),  value: VersionSearchMethod.GT },
  { label: ze('大于等于', '>='),  value: VersionSearchMethod.GTE },
  { label: ze('小于 ', '<'),  value: VersionSearchMethod.LT },
  { label: ze('小于等于', '<='),  value: VersionSearchMethod.LTE },
];

const METHOD_TO_INFO = {
  [VersionSearchMethod.IN]: {
    queryType: QueryType.TERMS_WILDCARD,
    valueField: 'terms',
    not: false,
  },
  [VersionSearchMethod.NOT_IN]: {
    queryType: QueryType.TERMS_WILDCARD,
    valueField: 'terms',
    not: true,
  },
  [VersionSearchMethod.GT]: {
    queryType: QueryType.RANGE,
    valueField: 'gt',
    not: false,
  },
  [VersionSearchMethod.GTE]: {
    queryType: QueryType.RANGE,
    valueField: 'gte',
    not: false,
  },
  [VersionSearchMethod.LT]: {
    queryType: QueryType.RANGE,
    valueField: 'lt',
    not: false,
  },
  [VersionSearchMethod.LTE]: {
    queryType: QueryType.RANGE,
    valueField: 'lte',
    not: false,
  },
}

/** IssueCrashFilterEx专用组件，版本过滤器 */
const VersionWithRangeFilter = ({
  value,
  treeData,
  onChange = () => {},
  isReadOnly,
  onDropdownVisibleChange,
  onChangeWhenDropdownClosed,
}) => {
  const {
    queryType,
    not,
    terms,
    gt,
    gte,
    lt,
    lte,
  } = value || {};

  const [innerSearchMethod, setInnerSearchMethod] = useState(null);

  useEffect(() => {
    if (isNullish(queryType)) {
      setInnerSearchMethod(VersionSearchMethod.IN);
    } else if (queryType === QueryType.TERMS_WILDCARD) {
      setInnerSearchMethod(not ? VersionSearchMethod.NOT_IN : VersionSearchMethod.IN);
    } else if (queryType === QueryType.RANGE) {
      if (gt) {
        setInnerSearchMethod(VersionSearchMethod.GT);
      } else if (gte) {
        setInnerSearchMethod(VersionSearchMethod.GTE);
      } else if (lt) {
        setInnerSearchMethod(VersionSearchMethod.LT);
      } else if (lte) {
        setInnerSearchMethod(VersionSearchMethod.LTE);
      }
    }
  }, [queryType, not, gt, gte, lt, lte]);

  const searchMethodInfo = METHOD_TO_INFO[innerSearchMethod];

  const isVersionRange = searchMethodInfo && searchMethodInfo.queryType === QueryType.RANGE;

  return <div style={{ display: 'flex' }}>
    <Select
      style={{ width: '120px', flex: '0 0 auto' }}
      options={SEARCH_METHOD_OPTIONS}
      value={innerSearchMethod}
      optionFilterProp='label'
      onChange={(v) => {
        if (isReadOnly) {
          return;
        }
        setInnerSearchMethod(v);
        const newInfo = METHOD_TO_INFO[v];
        const { queryType } = newInfo;
        onChange({
          queryType: queryType,
          not: newInfo.not,
        });
      }}
    />
    { innerSearchMethod && <VersionHybridSelect
      style={{ flexGrow: 1 }}
      singleSelectMode={isVersionRange}
      value={value[searchMethodInfo.valueField]}
      treeData={treeData}
      onDropdownVisibleChange={onDropdownVisibleChange}
      onChangeWhenDropdownClosed={onChangeWhenDropdownClosed}
      onChange={(v) => {
        const { valueField, queryType, not } = searchMethodInfo;
        onChange({
          queryType,
          ...(not ? { not } : {}),
          [valueField]: v,
        });
      }}
    /> }
  </div>;
};

VersionWithRangeFilter.propTypes = {
  isReadOnly: PropTypes.bool,
  treeData: PropTypes.array.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func,
  onDropdownVisibleChange: PropTypes.func,
  onChangeWhenDropdownClosed: PropTypes.func,
};

export default VersionWithRangeFilter;
