import RestHelper from './RestHelper';

const userChannelRest = RestHelper.publish({
  getChannelTrend: {
    get: {
      method: 'get',
      uri: '/appId/{appId}/pid/{pid}/version/{version}/startDate/{startDate}/endDate/{endDate}/channelTrend',
    },
  },
  getRetentTrend: {
    get: {
      method: 'get',
      uri: '/appId/{appId}/pid/{pid}/version/{version}/startDate/{startDate}/endDate/{endDate}/dateScope/{dateScope}/retentTrend',
    },
  },
  channelData: {
    get: {
      method: 'get',
      uri: '/getChannelInfo/appId/{appId}/pid/{pid}/startDate/{startDate}/endDate/{endDate}/dateType/{dateType}',
    },
  },
});

export default userChannelRest;
