/** 检查当前用户的浏览器是否满足运行要求 */
export default function isBrowserSupported() {
  try {
    must(typeof Array.prototype.flat === 'function');  // chrome 69+
    must(typeof Object.fromEntries === 'function'); // chrome 73+, edge 79+
    must(typeof String.prototype.replaceAll === 'function') // chrome 86+
    return true;
  } catch(e) {
    return false;
  }
}

function must(condition) {
  if (!condition) {
    throw Error('must assert failed');
  }
}
