import React, { useState, useEffect, useMemo } from 'react';
import { useImmer } from 'use-immer';
import PropTypes, { array } from 'prop-types';
import RestHelper from 'utils/RestHelper';
import { Modal, Space, Input, Select, Table, message } from 'antd';
import { connect, useSelector } from 'react-redux';
import { ze } from 'utils/zhEn';
import { useTranslation } from 'react-i18next';
import _style from './style.scss';
import * as symbolTableActions from 'reducers/symbolTable/actions';
import { bindActionCreators } from 'redux';
import NoJumpNextPagination from 'components/antd-extension/NoJumpNextPagination';
import SearchIcon from 'svg/v2/newcs_dashboard_crashanalysis_issuedetail_tracelog_filt_search_icon.svg';

const StackSymbolicateModal = (props) => {
  const { TextArea } = Input;
  const { t } = useTranslation();
  const { reduxState, modalProps, actions } = props;

  const currentApp = reduxState.app.get('current').toJS();
  const { appId, platformId } = currentApp;
  const currentUser = reduxState.user.get('current').toJS();
  const [stack, setStack] = useState(undefined);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedUuid, setSelectedUuid] = useState(null);
  const [symbolTable, setSymbolTable] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [isShowError, showError] = useState(false);
  const pageSize = 20;

  const uploadSymbolSheetUrl = `${window.location.origin}/crash-reporting/preferences/dsyms/${appId}?pid=${platformId}`;
  const onlineSymbolicationUrl = `${window.location.origin}/crash-reporting/online-retrace/${appId}?pid=${platformId}`;

  useEffect(() => {
    fetchSymbolList();
  }, [searchValue, currentPage]);

  const fetchSymbolList = () => {
    actions.getList({
      appId: appId,
      platformId: platformId,
      type: 3,
      offset: pageSize*(currentPage-1),
      size: pageSize,
      status: '',
      search: searchValue,
    }).then((data) => {
      const infos = data.infos;
      const infosFilter = infos.filter(item => item.uuid);
      setSymbolTable(infosFilter.map(item => ({
        ...item,
        key: item.uuid,
      })))
      setTotalCount(data.totalCount);
    })
  }

  let finalModalProps = {
    ...modalProps,
    onOk: async () => {
      console.log("=== 选择的参数 ==", selectedUuid, stack);
      if (!selectedUuid || !stack) {
        showError(true);
        return;
      }
      modalProps.onOk && modalProps.onOk();
      await postSymbolicate();
      message.success('Successful');
      setTimeout(() => {
        window.open(onlineSymbolicationUrl, '_blank')
      }, 200)
    },
  }

  const postSymbolicate = async () => {
    const rsp = await RestHelper.post('/redir/reretrace/exceptionReRetrace/createPcRetraceTask', { appId, platformId, stack, mappingUuid: selectedUuid });
  }

  const symbolTableColumns = [
    {
      title: ze('版本', 'Version'),
      dataIndex: 'version',
      key: '1',
    },
    {
      title: 'UUID',
      dataIndex: 'uuid',
      key: '2',
    },
    {
      title: ze('符号表文件', 'Symbol File'),
      dataIndex: 'symbolPath',
      key: '3',
      render: (_, { soName, uploadedFilename }) => <div>
        {soName && <span>{uploadedFilename}</span>}
      </div>
    },
    {
      title: ze('上传时间', 'Upload Time'),
      dataIndex: 'uploadTime',
      key: '4',
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    type:'radio',
    onChange: (selectedRowKeys, selectedRows) => {
      console.log("=== 选择符号表 ===", selectedRows[0].symbolPath)
      setSelectedUuid(selectedRows[0].uuid);
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const onChangeSearchUuid = (event) => {
    setCurrentPage(1);
    setSearchValue(event.target.value);
  }

  return <Modal width={900}
    title={ze('堆栈还原', 'Stack Symbolicate')}
    open={modalProps.visible}
    {...finalModalProps}
    // okButtonProps={{ disabled: !!errorInfo, loading: modalProps.loading}}
  >
    <TextArea
      className={_style.log_symbolicate_textarea}
      placeholder={ze('堆栈', 'Stack')}
      style={{ height: 100, resize: 'none' }}
      onChange={(event) => setStack(event.target.value)}
    />
    {isShowError && !stack && <p style={{ fontSize: 12, color: 'red' }}>{ze("输入的堆栈不能为空", 'Stack cannot be empty')}</p>}
    <div style={{marginTop: 20}}>{ze('符号表', 'Symbol File')}: </div>
    {isShowError && !selectedUuid && <p style={{ fontSize: 12, color: 'red' }}>{ze("未选择符号表", 'Please select symbol file')}</p>}
    <div style={{marginTop: 10, width: '100%', display: 'flex', gap: 10, alignItems: 'center'}}>
      <Input
        style={{ flex: 1 }}
        type="search"
        prefix={<SearchIcon />}
        placeholder={t('SYMBOLMANAGE.searchUUIDPlaceholder')}
        // onChange={(v) => setUuid(uuid)}
        onPressEnter={(event) => onChangeSearchUuid(event)}
      />
      <a target='_blank' href={uploadSymbolSheetUrl}>{ze('上传符号表', 'Upload Symbol File')}</a>
    </div>
    <Table
      style={{ marginTop: 10 }}
      rowSelection={rowSelection}
      columns={symbolTableColumns}
      dataSource={symbolTable}
      pagination={false}
      scroll={{ y: 340 }}
    />
    <div style={{display: 'flex', justifyContent: 'end', marginTop: 10}}>
      <NoJumpNextPagination
        current={currentPage}
        pageSize={pageSize}
        total={totalCount}
        onChange={(page, pageSize) => {
          setCurrentPage(page);
        }}
        showSizeChanger={false}
        // onShowSizeChange={(current, size) => paginationShowSizeChange(current, size)}
      />
    </div>

  </Modal>;
};

StackSymbolicateModal.propTypes = {
  modalProps: PropTypes.object,
  reduxState: PropTypes.object,
  actions: PropTypes.object,
};

const mapStateToProps = state => ({
  reduxState: state,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...symbolTableActions,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(StackSymbolicateModal);
