import React, { useState, useEffect, useMemo } from 'react';
import { useImmer } from 'use-immer';
import PropTypes, { array } from 'prop-types';
import RestHelper from 'utils/RestHelper';
import {
  Form,
  Modal,
  Input,
  Select,
  Row,
  Col,
  Spin,
  Button,
  notification,
  AutoComplete,
  Divider,
  Radio,
  Checkbox,
  Space,
} from 'antd';
import { connect, useSelector } from 'react-redux';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { red } from '@ant-design/colors';
import isString from 'lodash/lang/isString';
import { isNotNullish } from 'utils/nullish';
import { MERGE_TYPE_IS_MERGE_ISSUE, MERGE_TYPE_IS_BE_MERGED_ISSUE } from 'utils/constants/merge-issue-type';
import { useCrashsightLocalStorage } from 'utils/useCrashSightLocalStorage';
import uniq from 'lodash/array/uniq';
import { getIssueDetailUrlPrefix } from 'utils/helper';
import { UserTypeEnum } from 'utils/constants/user-type-enum';
import { TapdTitleTemplateUtil } from 'components/exception/issue/TapdTitleTemplateEditor';
import { ze } from 'utils/zhEn';
import cloneDeep from 'lodash/lang/cloneDeep';
import scss from './TapdModal.scss';
import TapdEditBugCustomFieldsModal, { TapdEditBugCustomFieldsUtil } from 'components/exception/issue/TapdEditBugCustomFieldsModal';
import { useTranslation } from 'react-i18next';
import TapdModelEditTitleTemplateModel from 'components/exception/issue/TapdModelEditTitleTemplateModel';
import isArray from 'lodash/lang/isArray';
import { ExceptionCategoryUtil } from 'utils/exception-category';
import { EXCEPTION_TYPE_INT_ANDROID_JAVA, EXCEPTION_TYPE_INT_ANDROID_NATIVE } from 'utils/constants/exception-type-int';
import { isAndroid } from 'utils/platform';

const MergeIssuesModal = (props) => {
  const { t } = useTranslation();
  const { reduxState, issueInfoList, modalProps } = props;
  const modalVisible = modalProps.visible;
  const currentApp = reduxState.app.get('current').toJS();
  const { appId, platformId } = currentApp;
  const currentUser = reduxState.user.get('current').toJS();
  const [errorInfo, setErrorInfo] = useState('');

  const mergeIssueList = issueInfoList.filter(item => item.esMap?.mergeIssueType == MERGE_TYPE_IS_MERGE_ISSUE);
  const needToBeMergedIssueList = issueInfoList.filter(item => item.esMap?.mergeIssueType !== MERGE_TYPE_IS_MERGE_ISSUE);

  useEffect(() => {
    console.log('=== issueInfoList ===', issueInfoList);
  }, [modalVisible]);


  useEffect(() => {
    setErrorInfo('');
    if (!issueInfoList.every((item, index, array) => item.issueExceptionType === array[0].issueExceptionType)) {
      if (isAndroid(platformId)) {
        setErrorInfo(ze('所选择的问题中同时存在Native崩溃和Java崩溃，无法合并', 'The selected issues contains both Native Crash and Java Crash, cannot be merged.'));
      } else {
        setErrorInfo(ze('所选择的问题中同时存在APP崩溃和Extension崩溃，无法合并', 'The selected issues contains both APP Crash and Extension Crash, cannot be merged.'));
      }
    } else if (!(Number(issueInfoList[0]?.esMap?.issueExceptionType) === EXCEPTION_TYPE_INT_ANDROID_JAVA || Number(issueInfoList[0]?.esMap?.issueExceptionType) === EXCEPTION_TYPE_INT_ANDROID_NATIVE)) {
      setErrorInfo(ze('所选择的问题中存在非崩溃类型的异常问题，无法合并', 'The selected issues contains non-crash exception, cannot be merged.'));
    } else if (mergeIssueList.length > 1) {
      setErrorInfo(ze('所选择的问题中同时存在已合并问题，无法继续合并', 'The selected issues contains merged issue, cannot be merged.'));
    } else if (mergeIssueList.length === 1) {
      const fetchData = async () => {
        const rsp = await RestHelper.post('/redir/query/merge/getMergedIssueList', {
          appId,
          platformId,
          mergeIssueId: mergeIssueList[0].esMap?.issueId,
        });
        if (rsp.json.data && (rsp.json.data.length + needToBeMergedIssueList.length > 20)) {
          setErrorInfo(ze('待合并的问题数量超过20，无法合并', 'The number of issues to be merged exceeds 20, cannot be merged.'));
        }
      }
      fetchData();
    } else if (needToBeMergedIssueList.length > 20) {
      setErrorInfo(ze('待合并的问题数量超过20，无法合并', 'The number of issues to be merged exceeds 20, cannot be merged.'));
    } else {
      setErrorInfo('');
    }
  }, [issueInfoList])

  let finalModalProps = {
    ...modalProps,
    onOk: () => {
      if (errorInfo) {
        return;
      }
      modalProps.onOk && modalProps.onOk(needToBeMergedIssueList, mergeIssueList.length ? mergeIssueList[0].issueId : null);
    },
    confirmLoading: modalProps.loading,
    closable: !modalProps.loading,
    cancelButtonProps: { disabled: modalProps.loading },
    maskClosable: !modalProps.loading,
  }

  return (
    <Modal width={900}
      title={ze('问题合并', 'Merge Issues')}
      visible={modalVisible}
      {...finalModalProps}
      okButtonProps={{ disabled: !!errorInfo, loading: modalProps.loading}}
    >
      {errorInfo && <div style={{ color: 'red' }}>{errorInfo}</div>}
      {mergeIssueList && mergeIssueList.length > 0 && <div>{ze('已合并的问题', 'Merged issues')}</div>}
      <div style={{ maxHeight: 400, overflow: 'auto', marginBottom: 10 }}>
        {mergeIssueList && mergeIssueList.map((issueInfo, index) => <Space>
          <div style={{ width: 65, marginLeft: 20 }}>{ze('问题', 'Issue')}{index + 1}</div>
          <div style={{marginRight: 2}}>ID:</div>
          <div style={{width: 300, color: '#4371FD'}}>{issueInfo.issueId}</div>
          <div style={{marginRight: 2}}>{ t('issueCrashFilterKey.errorType') }</div>
          <div style={{color: '#4371FD'}}>{issueInfo.exceptionName}</div>
        </Space>)}
      </div>
      <div>{ ze('需要合并的问题', 'To be merged issues') }</div>
      <div style={{ maxHeight: 400, overflow: 'auto' }}>
        {needToBeMergedIssueList && needToBeMergedIssueList.map((issueInfo, index) => <Space>
          <div style={{width: 65, marginLeft: 20}}>{ze('问题', 'Issue')}{index + 1}</div>
          <div style={{marginRight: 2}}>ID:</div>
          <div style={{width: 300, color: '#4371FD'}}>{issueInfo.issueId}</div>
          <div style={{marginRight: 2}}>{ t('issueCrashFilterKey.errorType') }</div>
          <div style={{color: '#4371FD'}}>{issueInfo.exceptionName}</div>
        </Space>)}
      </div>
    </Modal>
  );
};

MergeIssuesModal.propTypes = {
  issueInfoList: PropTypes.Array,
  modalProps: PropTypes.object,
  reduxState: PropTypes.object,
};

const mapStateToProps = state => ({
  reduxState: state,
});

export default connect(mapStateToProps)(MergeIssuesModal);
