import { Map } from 'immutable';
import { DATE_OPTIONS } from 'utils/constants';
const initialSelectOptions = new Map({
  version: {
    options: [],
  },
  channelId: {
    options: [],
  },
  customVersion: {
    options: [
      { label: '昨天Top5版本', value: 'top5' },
      { label: '自定义版本', value: 'custom' },
    ],
  },
  date: DATE_OPTIONS,
});

// 搜索的参数
const initialSearchParams = new Map({
  start: 0,
  rows: 10,
  version: '-1', // 版本
  customVersion: 'top5',
  channel: '-1', // 渠道
  date: { date: 'last_7_day', startDate: '', endDate: '' },
});


// 初始化state
export const initialState = new Map({
  selectOptions: initialSelectOptions,
  searchParams: initialSearchParams,
  currentTab: '新增用户',
  dataList: {},
  totalAddUserAmount: 0,
  numFound: 0,
  totalPages: 0,
  top5Version: '',
});
