import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _style from 'components/commons/TimeSelect/style.scss';
import { withTranslation } from 'react-i18next';
import DateSelectIcon from 'svg/v2/newcs_dashboard_dateselect_icon.svg';

@withTranslation()
export default class TimeSelect extends Component {
  // shouldComponentUpdate(nextProps) {
  //   return nextProps.open !== this.props.open
  //     || nextProps.value !== this.props.value
  //     || nextProps.options !== this.props.options;
  // }
  handleSelect(e, option) {
    this.props.updateValue(option);
  }


  preHandleSelect(e, option) {
    this.props.toggleOpen();
    this.handleSelect(e, option);
  }

  l10n(text) {
    const timeRangeRegex = /^(\d+)\s*~\s*(\d+)$/;
    if (timeRangeRegex.test(text)) {
      const matchResult = text.match(timeRangeRegex);
      const [begin, end] = [matchResult[1], matchResult[2]];
      return this.props.t('timePicker.timeRange', { begin, end });
    } else if (this.props.i18n.exists(`timePicker.${text}`)) {
      return this.props.t(`timePicker.${text}`);
    } else {
      return text;
    }
  }

  renderType() {
    const { subSearch, tags, type } = this.props;
    if (subSearch) {
      return <div></div>;
    } else if (tags) {
      return <div></div>;
    } else if (type) {
      // return <div className={_style.select_type}>{type}</div>
      return null;
    }
    return null;
  }

  render() {
    const {
      options, callback, value, toggleOpen, singleDate, style,
      forcedLabel,
    } = this.props;
    // idKey && console.log(`TimeSelect: render() open=${open}`);
    return (
      <div className={_style.select_container}>
        <div className={`${_style.select_control} ${style && style.select_control}`} onClick={toggleOpen}>
          <span className={_style.select_value}>
            { forcedLabel || this.l10n(value)}
          </span>
          <DateSelectIcon style={{height: 12, width: 12, float: 'right', marginTop: 10, pointerEvents: 'none'}} />
        </div>
        {
          this.props.open && !singleDate
          && (
            <div className={_style.select_options}>
              {this.renderType() }
              <ul style={{marginBottom: '0px'}}>
                {options && options.map((option, index) => {
                  return callback && callback.key === option
                    ? <li key={index} className={value === option ? 'active' : ''} onClick={(e) => { this.handleSelect(e, option); callback.cb(); }}>{this.l10n(option)}</li>
                    : <li key={index} className={value === option ? 'active' : ''} onClick={(e) => this.preHandleSelect(e, option)}>{this.l10n(option)}</li>;
                }) }
              </ul>
            </div>
          )
        }
      </div>
    );
  }
}

// subSearch 下拉列表中的子搜索框（可选）
// tags      下拉列表中的tag列表（可选）
// options   包括default, 下拉列表中的选项
// type      时间、版本、类型、状态、处理人、标签
// value     调用者传过来的value，直接显示出来
TimeSelect.propTypes = {
  subSearch: PropTypes.bool,
  tags: PropTypes.object,
  type: PropTypes.string,
  callback: PropTypes.object,
  value: PropTypes.string,
  options: PropTypes.array.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  updateValue: PropTypes.func.isRequired,
  style: PropTypes.object,
  singleDate: PropTypes.any,
  forcedLabel: PropTypes.string,
};
