import React, { Component,useState} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as productActions from 'reducers/product/actions';
import { updateInfoOnAppList } from 'reducers/app/appActions';
import * as globalActions from 'reducers/global/globalActions';
import { updateOperatorRight } from 'reducers/user';
import { push, replace as replaceState } from 'connected-react-router';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import {UserTypeEnum} from "utils/constants/user-type-enum";
import { EnvUtil } from 'utils/env-util';
import { useTranslation } from 'react-i18next';
import reportEvent, { EVENT_ACTIONS } from 'utils/reportEvent';
import style from './index.scss';
function makeProductTabArr(isSuper, userType) {
  return [
    'PRODUCTBASE.information',
    'PRODUCTBASE.members',
    'PRODUCTBASE.versionManage',
    ...!EnvUtil.isChinaEnv() && UserTypeEnum.hasAdminPermission(userType) ? ['PRODUCTBASE.webConsoleConfiguration'] : [],
    ...UserTypeEnum.hasAdminPermission(userType) ? ['PRODUCTBASE.deleteProduct'] : [],
    ...UserTypeEnum.hasAdminPermission(userType) ? ['PRODUCTBASE.operationLogs'] : [],
    ...isSuper ? ['PRODUCTBASE.functionConfiguration','PRODUCTBASE.notification'] : [],
  ];
}

const ProductSubMenu = (props) => {
  const { currentUser,currentApp, currentTab} = props;
  const { appId, pid} = currentApp.toJS();
  const isSuper = !!currentUser.get('isSuper');
  const userType = currentUser.get('type');
  const { t } = useTranslation();

  let currentMenuKey;
  const urlCopy = new URL(window.location.href);
  if (urlCopy.pathname.startsWith('/product')) {
    currentMenuKey = currentTab || 'PRODUCTBASE.information';
  } else {
    currentMenuKey = null;
  }

  const url = `/product/apps/${appId}?pid=${pid}`;
  // const url = `/product/apps/${props.appId}?pid=${props.pid}`;
  const changeTab = (name, tabType) => {
    props.actions.changeCurTab(name)
    // 切换tab，上报pv
    reportEvent({
      action: EVENT_ACTIONS.OPEN,
      tp1: t(name) || name,
    })
  }

  return <div className={style.productSubMenu}>
    <Menu
      mode='inline'
      selectedKeys={[currentMenuKey]}
    >
      {makeProductTabArr(isSuper, userType).map(x => <Menu.Item
          key={x}
          onClick={() => changeTab(x)}
      ><Link to={url}>{t(x)}</Link></Menu.Item>)}
    </Menu>
  </div>
}

export default connect((state, router) => {
  return {
    currentApp: state.app.get('current'),
    currentUser: state.user.get('current'),
    memberList: state.product.get('memberList'),
    reduxState: state,
    app: state.product.get('app'),
    dailyConfig: state.product.get('dailyConfig'),
    currentTab: state.product.get('currentTab'),
    user: state.user.get('current'),
    permission: state.product.get('permission'),
    loading: state.global.get('loading'),
    isShowInvitated: state.product.get('isShowInvitated'),
    isDemoApp: state.global.get('isDemoApp'),
  };
}, (dispatch) => ({
  actions: bindActionCreators({
    ...productActions,
    ...globalActions,
    updateOperatorRight,
    push,
    replaceState,
    updateInfoOnAppList,
  }, dispatch),
  dispatch,
}))(ProductSubMenu);