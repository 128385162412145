import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Input, Radio, TreeSelect } from 'antd';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/lang/isEqual';
import { CsTreeSelect } from 'components/antd-extension/CsTreeSelect';
import { DownOutlined } from '@ant-design/icons';

const VersionHybridSelect = (props) => {
  const { t } = useTranslation();
  const {
    singleSelectMode: nullableSingleSelectMode,
    treeData: nullableTreeData,
    value: nullableValue,
    style,
    size,
    selectComponentSetting,
  } = props;

  const singleSelectMode = !!nullableSingleSelectMode;

  const treeData = nullableTreeData || [];
  const value = singleSelectMode ? nullableValue : (nullableValue || []);

  let {
    onChange,
    onDropdownVisibleChange,
    onChangeWhenDropdownClosed,
  } = props;

  onChange = onChange || (() => {});
  onDropdownVisibleChange = onDropdownVisibleChange || (() => {});
  onChangeWhenDropdownClosed = onChangeWhenDropdownClosed || (() => {});

  const [treeSelectSearchText, setTreeSelectSearchText] = useState('');

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [intermediateValue, setIntermediateValue] = useState(undefined);

  useEffect(() => {
    if (!isEqual(value, intermediateValue)) {
      setIntermediateValue(value);
    }
  }, [value]);

  return <CsTreeSelect
    size={size}
    style={style}
    showSearch={true}
    allowClear={true}
    treeData={treeData}
    treeCheckable={!singleSelectMode}
    showCheckedStrategy={'SHOW_PARENT'}
    placeholder={ t('EXCP_OVERVIEW.allVersion') }
    listHeight={500}
    treeDefaultExpandedKeys={ singleSelectMode ? [intermediateValue] : intermediateValue }
    value={ intermediateValue }
    showArrow={true}
    suffixIcon={<DownOutlined />}
    onChange={(v) => {
      if (dropdownOpen) {
        setIntermediateValue(v);
      } else {
        onChangeWhenDropdownClosed(v);
      }
      onChange(v);
    }}
    searchValue={treeSelectSearchText}
    onSearch={(v) => {
      setTreeSelectSearchText(v);
    }}
    onKeyDown={(e) => {
      if (e.key !== 'Enter' || singleSelectMode) {
        return;
      }
      // 可以通过按下回车键直接确认选择一个任意字串作为版本的逻辑
      const newItem = treeSelectSearchText;
      setTreeSelectSearchText('');
      if (newItem && !value.includes(newItem)) {
        const newValue = [...value, newItem];
        onChange(newValue);
        onChangeWhenDropdownClosed(newValue);
      }
    }}
    onDropdownVisibleChange={(open) => {
      setDropdownOpen(open);
      if (open) {
        setIntermediateValue(value);
      } else if (value !== intermediateValue) {
        onChangeWhenDropdownClosed(intermediateValue);
      }
      onDropdownVisibleChange(open);
    }}
    {...selectComponentSetting}
  />;
};

VersionHybridSelect.propTypes = {
  singleSelectMode: PropTypes.bool,
  treeData: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onChange: PropTypes.func,
  onDropdownVisibleChange: PropTypes.func,
  onChangeWhenDropdownClosed: PropTypes.func,
};

export default VersionHybridSelect;
