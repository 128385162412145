import React from 'react';
import PropTypes from 'prop-types';
import { ze } from 'utils/zhEn';
import { CustomKvUtil } from 'utils/custom-kv';
import { WebAppSettings } from 'utils/web-app-settings';
import { connect, useDispatch } from 'react-redux';
import PinnedIcon from 'svg/v2/newcs_dashboard_crashanalysis_issuedetail_tracedata_operate_yes_icon.svg';
import UnpinnedIcon from 'svg/v2/newcs_dashboard_crashanalysis_issuedetail_tracedata_operate_no_icon.svg';
import { upsertWebAppSettings } from 'reducers/app/appActions';
import { TapdTitleTemplateUtil } from 'components/exception/issue/TapdTitleTemplateEditor';
import { Tooltip} from 'antd';
import './index.scss'
const UserCustomDataTable = ({ issue, style, reduxState }) => {
  const dispatch = useDispatch();

  const appId = reduxState.app.get('current').get('appId');
  const settings = reduxState.app.get('appIdToWebAppSettings')[appId];

  const quickViewCustomKeyList = WebAppSettings.getQuickViewCustomKeyList(settings);
  const attachCustomKvList = issue.get('attachCustomKvList') || [];

  function changeCustomKeyIsQuickViewPinned(key, isPinned) {
    let newKeyList;
    if (isPinned && !quickViewCustomKeyList.includes(key)) {
      newKeyList = [...quickViewCustomKeyList, key];
    } else if (!isPinned && quickViewCustomKeyList.includes(key)) {
      newKeyList = quickViewCustomKeyList.filter(x => x !== key);
    } else {
      return;
    }
    dispatch(upsertWebAppSettings(appId, {
      [WebAppSettings.keys.QuickViewCustomKeyList]: newKeyList,
    }));
  }

  /**
   *  渲染用户key
   */
  function renderUserKey(attachCustomKvList) {
    let th = [{key: 'Key', value: 'Value', type: 'Type'}];
    let trs = [...attachCustomKvList];

    // sort
    trs.sort(function(a, b) {
      if (a.type === b.type) {
        return a.key > b.key ? 1: -1;
      } else {
        return a.type > b.type ? 1: -1;
      }
    });

    const trsElements = [];
    let res = th.concat(trs);
    res.forEach((item,i) => {
      if (i === 0) {
        trsElements.push((
          <tr key={i} className={style.headStyle}>
            <td style={{width: '16.6666%'}}>{item.key}</td>
            <td>{item.value}</td>
            <td style={{ width: '80px' }}>{item.type}</td>
            <td style={{ width: '80px' }}>{ ze('固定展示', 'Pinned') }</td>
          </tr>
        ));
        return;
      }

      const { keyWithoutPrefix, keyTypeLabel } = CustomKvUtil.getKeyInfoFromPrefixedCustomKey(item.keyWithType);
      const isQuickViewPinned = quickViewCustomKeyList.includes(item.keyWithType);

      trsElements.push((
        <tr key={i} className={style.headStyle}>
          <td style={{width: '16.6666%'}}>{ keyWithoutPrefix }</td>
          <td>{item.value}</td>
          <td style={{ width: '80px' }}>{ keyTypeLabel }</td>
          <td style={{ width: '80px', textAlign: 'center', paddingLeft: 0 }}>
            <Tooltip
              placement='top'
              overlayClassName='customkvTooltip'
              title={ isQuickViewPinned
                ? ze('取消固定，上报详情将取消展示该字段','Click to unpin the custom field from the report detail area.')
                : ze('点击固定，上报详情将展示该字段','Click to pin this custom field to the report detail area.')
            }>
                {/* <img
                  style={{ cursor: 'pointer' }}
                  onClick={() => changeCustomKeyIsQuickViewPinned(item.keyWithType, !isQuickViewPinned)}
                  src={isQuickViewPinned ? pinnedIcon : unpinnedIcon}
                  alt={isQuickViewPinned ? 'pinned' : 'unpinned'}
                /> */}
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => changeCustomKeyIsQuickViewPinned(item.keyWithType, !isQuickViewPinned)}
                >
                  {isQuickViewPinned ? <PinnedIcon /> : <UnpinnedIcon />}
                </span>
            </Tooltip>
          </td>
        </tr>
      ));
    })

    return (
      <tbody>
      { trsElements }
      </tbody>
    );
  }

  if (attachCustomKvList && attachCustomKvList.length > 0) {
    return (
      <div>
        <table className={style.data_table}>
          {renderUserKey(attachCustomKvList) }
        </table>
      </div>
    );
  } else {
    return (
      <div style={{ color: '#999', fontSize: '12px' }}>{ ze('无', 'No Custom Data')}</div>
    );
  }
};

UserCustomDataTable.propTypes = {
  issue: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired,
  reduxState: PropTypes.object,
};


const mapStateToProps = state => ({
  reduxState: state,
});

export default connect(mapStateToProps)(UserCustomDataTable);

