import { getStartEndDateFromStr } from 'utils/StringUtils';
import moment from 'moment';
import { isGameConsole, isMobile } from 'utils/platform';
import { EnvUtil } from 'utils/env-util';

export const ProductSummaryUtil = Object.freeze({
  // 获取是否支持带国家维度的大盘统计
  getIsRegionalDashboard: (platformId) => {
    // 海外环境都开放国家维度的统计，国内不开放。然后主机不支持大盘
    return !EnvUtil.isChinaEnv() && !isGameConsole(platformId);
  },

  // 点击按小时对比的时候，把日级时间范围保留终止日期转成单日
  convertDailyToHourlyDateObj(date, startDate, endDate) {
    const rangeInfo = getStartEndDateFromStr(date, startDate, endDate);
    if (rangeInfo.startDate !== rangeInfo.endDate) {
      startDate = rangeInfo.endDate;
      endDate = rangeInfo.endDate;
      date = 'custom';
    }
    return { date, startDate, endDate };
  },

  // 点击按日对比的时候，把单日的时间转成多日的时间
  convertHourlyToDailyDateObj(date, startDate, endDate, days) {
    const rangeInfo = getStartEndDateFromStr(date, startDate, endDate);
    endDate = rangeInfo.startDate;
    startDate = moment(rangeInfo.startDate).subtract(days, 'days').format('YYYYMMDD');
    date = 'custom';
    return { date, startDate, endDate };
  },

  convertToTrendDateObj({ date, startDate, endDate }) {
    return { date1: date, startDate1: startDate, endDate1: endDate };
  },

  convertToCompareDateObj({ date, startDate, endDate }) {
    return { date2: date, startDate2: startDate, endDate2: endDate };
  },
});
