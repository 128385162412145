import React from 'react';
import { EnvUtil } from 'utils/env-util';
import { CrashUtil } from 'utils/api/crash';
import { isAndroid } from 'utils/platform';

function makeIcon(filename) {
  const url = `https://${EnvUtil.getCdnHost()}/images/brand-icons/${filename}`;
  return <img
    style={{ height: '14px', maxWidth: '42px' }}
    src={url}
    alt={filename}
  />;
}

function makeLabelWithIcon(label, filename) {
  const icon = filename ? makeIcon(filename) : null;
  return <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
    { icon }
    <div>{ label }</div>
  </div>;
}

const DEVICE_KEY_TO_ICON = Object.freeze({
  samsung: 'samsung.svg',
  三星: 'samsung.svg',
  honor: 'honor.svg',
  荣耀: 'honor.svg',
  huawei: 'huawei.svg',
  华为: 'huawei.svg',
  poco: 'poco.svg',
  realme: 'realme.svg',
  redmi: 'redmi.svg',
  红米: 'redmi.svg',
  xiaomi: 'xiaomi.svg',
  小米: 'xiaomi.svg',
  lenovo: 'lenovo.svg',
  联想: 'lenovo.svg',
  zte: 'zte.svg',
  oppo: 'oppo.svg',
  vivo: 'vivo.svg',
  oneplus: 'oneplus.svg',
  一加: 'oneplus.svg',
  motorola: 'motorola.svg',
  摩托罗拉: 'motorola.svg',
  nubia: 'nubia.svg',
  努比亚: 'nubia.svg',
  infinix: 'infinix.svg',
  iqoo: 'iqoo.svg',
  itel: 'itel-mobile.svg',
  'lg ': 'lg-electronics.svg',
  'tecno': 'tecno.svg',
});

export const BrandIconUtil = Object.freeze({
  makePcCpuGpuLabelWithIcon(gpu) {
    const lowerGpu = (gpu || '').toLowerCase();
    if (lowerGpu.includes('amd')) {
      return makeLabelWithIcon(gpu, 'amd_v2.svg');
    } else if (lowerGpu.includes('nvidia')) {
      return makeLabelWithIcon(gpu, 'nvidia.svg');
    } else if (lowerGpu.includes('intel')) {
      return makeLabelWithIcon(gpu, 'intel.svg');
    } else {
      return makeLabelWithIcon(gpu);
    }
  },

  makeDeviceNameLabelWithIcon(platformId, originalModel, productName) {
    const formattedDeviceName = CrashUtil.formatDeviceName(originalModel, productName);
    if (!isAndroid(platformId)) {
      return formattedDeviceName;
    }
    const lowerName = (productName || originalModel || '').toLowerCase();
    const matchedEntry = Object.entries(DEVICE_KEY_TO_ICON)
      .find(([k, v]) => lowerName.includes(k));
    if (matchedEntry) {
      return makeLabelWithIcon(formattedDeviceName, matchedEntry[1]);
    } else {
      return formattedDeviceName;
    }
  },
});
