import XLSX from 'xlsx';
import isString from 'lodash/lang/isString';

function autoFormatCell(cell) {
  if (!isString(cell)) {
    return cell;
  }

  if (cell.endsWith('%')) {  // 百分比格式
    const percent = Number(cell.replace(/%$/, '').trim());
    if (Number.isFinite(percent)) {
      return {
        v: percent / 100,
        t: 'n',
        z: '0.00%',
      };
    } else {
      return cell;
    }
  }

  return cell;
}

function makeAutoFormattedExcelData(data) {
  if (!Array.isArray(data)) {
    return data;
  }

  return data.map((row) => {
    if (!Array.isArray(row)) {
      return row;
    }
    return row.map(cell => autoFormatCell(cell));
  });
}

export const XlsxUtil = Object.freeze({
  createExcelAndDownload(title, data) {
    const { utils } = XLSX;
    const ws_name = "sheet1";
    const wb= utils.book_new();

    const formattedData = makeAutoFormattedExcelData(data); // 自动转换单元格格式

    /* make worksheet */
    const ws = utils.aoa_to_sheet(formattedData);

    /* Add the worksheet to the workbook */
    XLSX.utils.book_append_sheet(wb, ws, ws_name);
    XLSX.writeFile(wb, title);
  },

  /**
   * @param {string} title - 文件名
   * @param {{ sheetName: string, data }[]} dataObjectList - 数据
   * */
  createMultipleSheetsExcelAndDownload(title, dataObjectList) {
    const { utils } = XLSX;
    const wb= utils.book_new();

    dataObjectList.forEach(({ sheetName, data }) => {
      const ws_name = sheetName;
      const formattedData = makeAutoFormattedExcelData(data); // 自动转换单元格格式
      /* make worksheet */
      const ws = utils.aoa_to_sheet(formattedData);
      /* Add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(wb, ws, ws_name);
    });

    XLSX.writeFile(wb, title);
  },
});
