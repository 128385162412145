import React from 'react';
import TipsIcon from 'svg/v2/newcs_dashboard_tips_new.svg';

const WrappedTipsIcon = (props) => {
  return (
    <span style={{ display: 'flex', alignItems:'center', width:'15px', ...props.style }}>
      <TipsIcon {...props} />
    </span>
  );
};

export default WrappedTipsIcon;
