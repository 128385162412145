import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import scss from './CsClampDiv.scss';
import { Button } from 'antd';
import { ze } from 'utils/zhEn';
import { GlobalResizeObserver } from 'utils/global-resize-observer';
import { isNullish } from 'utils/nullish';
import DefaultClampDiv from '../ClampDiv.jsx';
function isSafari() {
  return /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
}
const DEFAULT_LINE_HEIGHT = 1.5;
const CLAPM_STYLE = {
  overflow: 'hidden',
  display: '-webkit-box',
};
const ClampDiv = ({
  lineHeight,
  lineClamp,
  showButton,
  style,
  children,
}) => {
  if (isSafari()) {
    return <DefaultClampDiv
      lineHeight={lineHeight}
      lineClamp={lineClamp}
      showButton={showButton}
      style={style}
    >
      {children}
    </DefaultClampDiv>;
  }
  const [isClamped, setIsClamped] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [innerLineHeight, setInnerLineHeight] = useState(DEFAULT_LINE_HEIGHT);
  useEffect(() => {
    if (isNullish(lineHeight)) {
      setInnerLineHeight(DEFAULT_LINE_HEIGHT);
      return;
    }
    const lineHeightNumber = Number(lineHeight);
    if (!Number.isFinite(lineHeightNumber) || lineHeightNumber <= 0) {
      setInnerLineHeight(DEFAULT_LINE_HEIGHT);
      return;
    }
    setInnerLineHeight(lineHeightNumber);
  }, [lineHeight]);

  const refCallback = useCallback((node) => {
    if (!node) {
      GlobalResizeObserver.unobserve(node);
      return;
    }

    setIsClamped(calcIsClamped(node));
    GlobalResizeObserver.observe(node, (entry) => {
      setIsClamped(calcIsClamped(entry.target));
    });
  }, []);

  function calcIsClamped(dom) {
    if (!dom) {
      return false;
    }
    return dom.scrollHeight > dom.clientHeight;
  }

  const maxHeight = isExpanded ? undefined : `${innerLineHeight * lineClamp}em`;

  return <div className={scss.wrapper} style={style}>
    <div
      ref={refCallback}
      className={scss.content}
      style={{
        lineHeight: '16px',
        maxHeight,
        '--line-clamp': isExpanded ? 9999 : lineClamp,
        ...CLAPM_STYLE,
      }}
    >
      {(isClamped || isExpanded) && <div className={scss.floatDiv}>
        <Button
          size='small'
          className={scss.btn}
          type='link'
          style={{ visibility: showButton && (isClamped || isExpanded) ? 'visible' : 'hidden' }}
          onClick={() => setIsExpanded(!isExpanded)}
        >{ isExpanded ? ze('收起', 'Collapse') : ze('展开', 'Expand') }</Button>
      </div>}
      { children }
    </div>
  </div>;
};

ClampDiv.propTypes = {
  lineClamp: PropTypes.number,
};

export default ClampDiv;
