/**
 * Created by ellis on 23/11/2016.
 */
import React from 'react';
import PropTypes from 'prop-types';
import style from './style.scss';
import { Translation } from 'react-i18next'
import { PLATFORM_ID } from 'utils/constants';

const EmptyData = ({ uploadHandler, pid, dsymsSize }) => {
  return (
    <Translation>
      {
        (t, {i18n}) =>
        <div className={style.empty_data}>
        <div className={style.circle}>
          <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
            <path fill="#E8E8E8" fillRule="evenodd" d="M22.5 0H3.99C1.784 0 0 1.79 0 3.997v20.006c0 2.063 1.566 3.767 3.575 3.976L8 23l4.444 5H17v-7.996C17 16.136 20.126 13 23.996 13H24V4h3V0h-4.5zM20 40V19.997C20 17.79 21.784 16 23.99 16h7.566L36 21l4.425-4.98c2.01.21 3.575 1.914 3.575 3.977v20.006C44 42.21 42.216 44 40.01 44H17v-4h3zM6 33c0 6.075 4.925 11 11 11v-4c-3.866 0-7-3.134-7-7H6zm32-22c0-6.075-4.925-11-11-11v4c3.866 0 7 3.134 7 7h4zM2 33h12l-6-7-6 7zm28-22h12l-6 7-6-7zm-18.72 9.8c0 .12.08.2.2.2h2.4c.12 0 .2-.08.2-.2V7.6c0-.12-.08-.2-.2-.2h-2.24c-.14 0-.26.04-.36.12L9 9.16c-.1.08-.14.16-.14.28v2.48c0 .16.1.2.22.12l2.16-1.48h.04V20.8zm14.56 16c-.04.12 0 .2.14.2h2.62c.14 0 .22-.06.26-.2l.76-2.38h4.76l.78 2.38c.04.14.12.2.26.2h2.6c.14 0 .18-.08.14-.2l-4.6-13.2c-.04-.12-.12-.2-.26-.2h-2.52c-.14 0-.22.08-.26.2l-4.68 13.2zm4.6-4.88l1.54-4.76h.06l1.54 4.76h-3.14z" />
          </svg>
        </div>
        <p className={style.headline}>{ t("SYMBOLMANAGE.noSym") }</p>
        <p className={style.subline}>{ t("SYMBOLMANAGE.noSymTip") }</p>
        {
          pid === PLATFORM_ID.ANDROID && (
          <div>
            <a onClick={(e) => { e.preventDefault(); uploadHandler(3); }}>{ t("SYMBOLMANAGE.uploadSoSym") }</a>
            <a className={style.second} onClick={(e) => { e.preventDefault(); uploadHandler(1); }}>{ t("SYMBOLMANAGE.uploadMappingSym") }</a>
          </div>
          )
        }
        {
          pid === PLATFORM_ID.IOS && (
            <a onClick={(e) => { e.preventDefault(); uploadHandler(2); }}>{ t("SYMBOLMANAGE.uploadSym") }</a>
          )
        }
        {
          pid === PLATFORM_ID.PC && (
            <a onClick={(e) => { e.preventDefault(); uploadHandler(10); }}>{ t("SYMBOLMANAGE.uploadPdb") }</a>
          )
        }
        {
          pid === PLATFORM_ID.SWITCH && (
            <a onClick={(e) => { e.preventDefault(); uploadHandler(pid); }}>{ t("UPLOADSYMBOL.上传nss文件") }</a>
          )
        }
      </div>
      }
    </Translation>
  );
};

EmptyData.propTypes = {
  uploadHandler: PropTypes.func.isRequired,
  pid: PropTypes.number,
};

export default EmptyData;
