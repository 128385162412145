import * as CSV from 'csv-string';
import { isNullish } from 'utils/nullish';

export const CsvUtil = Object.freeze({
  singleLineParse(str) {
    if (isNullish(str)) {
      return [];
    }
    return (CSV.parse(String(str), ',') || [])[0];
  },

  singleLineStringify(itemList) {
    if (isNullish(itemList)) {
      return '';
    }
    return CSV.stringify(itemList, ',').trim();
  },
});
