import React from 'react';
import { Select } from 'antd';
import { convertIosModelToProductName, IOS_MODEL_TO_PRODUCT_NAME } from 'utils/model-convert/ios-model-convert';

const iosDeviceOptions = Object.entries(IOS_MODEL_TO_PRODUCT_NAME)
  .map(([k, v]) => ({
    label: convertIosModelToProductName(k, false, true),
    value: k,
  }))
  .filter(x => /^(iPhone|iPad|iPod).*/.test(x.value));

const IosDeviceSelect = ({
  value,
  onChange,
}) => {
  return <Select
    style={{ width: '100%' }}
    mode='multiple'
    autoClearSearchValue={false}
    allowClear={true}
    showSearch={true}
    optionFilterProp='label'
    options={iosDeviceOptions}
    value={value || []}
    onChange={v => onChange(v)}
  />
};

export default IosDeviceSelect;
