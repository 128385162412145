import { Map, fromJS } from 'immutable';
import moment from 'moment';

const yesterdayTime = moment().subtract(1, 'days').format('YYYY-MM-DD');
const limitNum = 5;

const initialSelectOptions = new Map({
  version: {
    options: [],
  },
  bundle: {
    options: [],
  },
  customBundle: {
    options: [
      { label: '昨天Top5 App', value: 'top5' },
      { label: '自定义App', value: 'custom' },
    ],
  },
});

const initialSearchParams = new Map({
  start: 0,
  rows: 10,
  bundle: null, // 没有默认值
  pageType: '崩溃',
  customBundle: 'top5',
  isOutOfDate: false,
  chartVersion: '-1',
  chartDate: { date: 'last_7_day', startDate: '', endDate: '' },
  version: '-1',
  date: { date: 'last_1_day', startDate: '', endDate: '' },
  // summaryListSearchTime : new Date(yesterdayTime + " 00:00:00").getTime()
});

export const initialState = fromJS({
  app_access_view: false,
  selectOptions: initialSelectOptions,
  searchParams: initialSearchParams,
  totalAccessUer: 0,
  limit: limitNum,
  yesterday: yesterdayTime,
  pri_type: 'app_access_view',
  numFound: 0,
  totalPages: 0,
  listHttpStatus: 200,
  chartHttpStatus: 200,
  topHttpStatus: 200,
  currentTab: 'SDK影响用户数',
  top5Bundle: [],
  top5DataList: {},
  sdkListArr: {}, // 崩溃详情的列表
  dataList: {},
});
