import React,{ useState, useEffect } from "react";
import { Table } from 'antd';
import { DataDetailUtil } from '../DataDetail';
import { useImmer } from 'use-immer';
import { ze } from 'utils/zhEn';
import ClampDiv from 'components/commons/ClampDiv';
import { VersionUtil } from 'utils/version-util';
import { CS_STYLES } from 'utils/constants/style-constants';
import { downloadAsFile } from 'utils/file-utils';
import CsDownloadButton from 'components/commons/CsDownloadButton/CsDownloadButton';

function getFileType(input) {
  if (!input || input.length === 0) {
      return "others";
  }

  const fileTypeMap = {
      "/sys": "system_file",
      "/vendor": "system_file",
      "/apex": "system_file",
      "/proc": "system_file",
      "socket:": "socket",
      "anon_inode:[eventfd]": "eventfd",
      "anon_inode:[eventpoll]": "eventfd",
      "/dmabuf:": "dmabuf",
      "/data": "file",
      "/storage": "file",
      "/dev/ashmem": "ashmem",
      "pipe:": "pipe",
      "/dev/mali": "gpu",
      "/dev/kgsl": "gpu",
      "/dev": "device"
  };

  for (let key in fileTypeMap) {
      if (input.startsWith(key)) {
          return fileTypeMap[key];
      }
  }

  return "others";
}

function makeValueBoxWithPercentageBar(text, percentage) {
  if (Number(percentage) === 0) {
    return '-';
  }
  return <div style={{ position: 'relative', display: 'flex', alignItems: 'center', gap: '12px' }}>
    <div style={{ position: 'absolute', height: '100%', width: '180px', backgroundColor: '#e9e7fb' }}/>
    <div style={{ position: 'absolute', height: '100%', width: `${percentage / 100 * 180}px`, backgroundColor: '#9187EB' }}/>
    <div style={{ width: '180px', paddingRight: '4px', textAlign: 'right', zIndex: 1 }}>{ percentage }%</div>
    <div>{ text }</div>
  </div>;
}

const FDInformation = ({issue, appId, pid}) => {
  const { crashDoc } = issue.toJS();
  const esMap = crashDoc.esMap || {};
  const { sdkVersion, expUid } = esMap;
  const { attachList } = issue.toJS() || {};

  const columns = [
    {
      title: ze('FD类型','FD type'),
      dataIndex: 'FDType',
      key: 'FDType',
    },
    {
      title: ze('数量','Count'),
      dataIndex: 'quantities',
      key: 'quantities',
    },
    {
      title: ze('占比','Percentage'),
      dataIndex: 'percentage',
      key: 'percentage',
      render:(percentage) => <div>{percentage}%</div>
    },
    {
      title: ze('详情','Details'),
      key: 'particulars',
      dataIndex: 'particulars',
      render: (_, { particulars,index}) => (
        <div>
          <ClampDiv
              lineClamp={3}
              key = {index}
              style={{ whiteSpace: 'pre-wrap', overflowWrap: 'anywhere', lineHeight: 'normal' }}
              showButton={!!itemIndexToIsHovering[index]}
          >{particulars}</ClampDiv>
        </div>
      ),
    },
  ];
  const [ data, setData ] = useState([]);
  const [ maxFDs, setMaxFDs] = useState(0)
  const [ currentFDs, setCurrentFDs] = useState(0)
  const [ availableFDs, setAvailableFDs] = useState(0)
  const [itemIndexToIsHovering, updateItemIndexToIsHovering] = useImmer({});

  const isSdkSupportFd = VersionUtil.compareCsSdkVersion(sdkVersion,'4.2.17') >= 0
    || '4.2.15-4.2.15(51)' === sdkVersion
    || '4.2.15-4.2.15(53)' === sdkVersion;

  const isNotSupported = !isSdkSupportFd && data.length === 0;

  useEffect(() => {
    reorganizationData()
  },[appId])

  function onClickDownload() {
    const data = DataDetailUtil.makeFdInfoTextLines(pid, attachList);
    const fileContent = data.join('\n') + '\n';
    downloadAsFile(`fd_info_${expUid}.txt`, fileContent);
  }

  const reorganizationData = () => {
    const data = DataDetailUtil.makeFdInfoTextLines(pid, attachList);
    const fileTypeCounts = {};
    const result = {};
    const fdTypeData = new Set();
    const regex = /(\w+)_FDs:(\d+)/;
    let maxFDs = 0;
    let currentFDs = 0;
    let availableFDs = 0;

    for (const line of data.slice(4)) {
      const [fileName, fileNumber] = line.split("::");
      const fileType = getFileType(fileName);
      fdTypeData.add(fileType);
      result[fileType] = result[fileType] ? result[fileType] + ", " + fileName : fileName;
      fileTypeCounts[fileType] = (fileTypeCounts[fileType] || 0) + parseFloat(fileNumber);
    }

    for (const line of data) {
      const match = line.match(regex);
      if (match) {
        const [_, fdType, fdValue] = match;
        switch(fdType) {
          case 'max':
            maxFDs = parseInt(fdValue);
            break;
          case 'current':
            currentFDs = parseInt(fdValue);
            break;
          case 'available':
            availableFDs = parseInt(fdValue);
            break;
        }
      }
    }

    setMaxFDs(maxFDs)
    setCurrentFDs(currentFDs)
    setAvailableFDs(availableFDs)


    const tableData = [...fdTypeData].map((item,index) => {
      return {
        index,
        FDType:item,
        quantities:fileTypeCounts[item],
        percentage:(fileTypeCounts[item] / currentFDs * 100).toFixed(2),
        particulars:result[item]
      }
    })

    setData(tableData);
  }
  if (isNotSupported) {
    return (
      <div style={{fontSize:'14px',textAlign:'center',color: CS_STYLES.PRIMARY_COLOR_RED }}>
        {ze('当前SDK版本不支持上传FD信息，需要更新到4.2.17或以上版本','The current SDK version does not support uploading FD information. Please update to version 4.2.17 or above.')}
      </div>
    )
  }

  return (
    <div>
      <div style={{display:'flex', alignItems: 'center', gap: '12px', marginBottom: '20px' }}>
        <div>{ ze('剩余FD占比', 'Proportion of unused FD count') }</div>
        { makeValueBoxWithPercentageBar(`${availableFDs} / ${maxFDs}`, maxFDs > 0 ? (availableFDs / maxFDs * 100).toFixed(2) : 0) }
        <div style={{ flexGrow: 1 }}/>
        <CsDownloadButton onClick={() => onClickDownload()} />
      </div>
      <Table columns={columns} dataSource={data}
             onRow = {record => {
               return {
                 onMouseEnter:(event) => {
                   updateItemIndexToIsHovering(draft => ({ ...draft, [record.index]: true }));
                 },
                 onMouseLeave:(event) => {
                   updateItemIndexToIsHovering(draft => ({ ...draft, [record.index]: false }));
                 }
               }
             }}
      />
    </div>
  )

  }

export default FDInformation;
