import i18n from 'i18next';
import { isAndroidOrHarmony, isMobile } from 'utils/platform';

/** 异常上报的大类（联网、崩溃、卡顿、错误、OOM等） */
export const ExceptionCategory = Object.freeze({
  /** 联网 */
  ACCESS: 'ACCESS',
  /** 崩溃 */
  CRASH: 'CRASH',
  /** 卡顿（字面上特指安卓系统的无响应，但这里指所有卡顿类型） */
  ANR: 'ANR',
  /** 错误 */
  ERROR: 'ERROR',
  /** OOM */
  OOM: 'OOM',
  /** 未知 */
  UNKNOWN: 'UNKNOWN',
});

export const ExceptionCategoryUtil = (function() {
  /** 将各种混乱的异常大类文本转换成统一的ExceptionCategory */
  function unify(chaosCategory) {
    if (!chaosCategory) {
      return ExceptionCategory.UNKNOWN;
    }
    if (ExceptionCategory[chaosCategory]) {
      return ExceptionCategory[chaosCategory];
    }
    switch (String(chaosCategory).toLowerCase()) {
      case 'access':
      case '联网': {
        return ExceptionCategory.ACCESS;
      }
      case 'crash':
      case 'crashes':
      case '崩溃': {
        return ExceptionCategory.CRASH;
      }
      case 'anr':
      case 'jank':
      case 'block':
      case 'blocks':
      case '卡顿': {
        return ExceptionCategory.ANR;
      }
      case 'error':
      case 'errors':
      case '错误': {
        return ExceptionCategory.ERROR;
      }
      case 'oom': {
        return ExceptionCategory.OOM;
      }
      default: {
        return ExceptionCategory.UNKNOWN;
      }
    }
  }

  /**
   * 将各种混乱的异常大类文本转换成统一的i18n文本
   * @param chaosCategory {string}
   * @param platformId {number}
   * @return {string}
   */
  function toI18n(chaosCategory, platformId) {
    if (!platformId) {
      console.error('toI18n, no platformId provided.');
    }
    const category = unify(chaosCategory);
    switch (category) {
      case ExceptionCategory.ACCESS: {
        return i18n.t('common.access');
      }
      case ExceptionCategory.CRASH: {
        return i18n.t('common.crash');
      }
      case ExceptionCategory.ANR: {
        return isAndroidOrHarmony(platformId) ? i18n.t('common.anr') : i18n.t('common.jank');
      }
      case ExceptionCategory.ERROR: {
        return i18n.t('common.error');
      }
      case ExceptionCategory.OOM: {
        return i18n.t('common.oom');
      }
      default: {
        return 'Unknown';
      }
    }
  }

  function fromExceptionType(exceptionType) {
    if (!exceptionType) {
      return ExceptionCategory.UNKNOWN;
    }
    if (exceptionType === 'Crash' || exceptionType === 'Native' || exceptionType === 'ExtensionCrash') {
      return ExceptionCategory.CRASH;
    } else if (exceptionType === 'ANR') {
      return ExceptionCategory.ANR;
    } else {
      return ExceptionCategory.ERROR;
    }
  }

  function isAccess(chaosCategory) {
    return unify(chaosCategory) === ExceptionCategory.ACCESS;
  }

  function isCrash(chaosCategory) {
    return unify(chaosCategory) === ExceptionCategory.CRASH;
  }

  function isAnr(chaosCategory) {
    return unify(chaosCategory) === ExceptionCategory.ANR;
  }

  function isError(chaosCategory) {
    return unify(chaosCategory) === ExceptionCategory.ERROR;
  }

  function isOom(chaosCategory) {
    return unify(chaosCategory) === ExceptionCategory.OOM;
  }

  function getAvailableOptions(pid) {
    const categories = isMobile(pid)
      ? [ExceptionCategory.CRASH, ExceptionCategory.ANR, ExceptionCategory.ERROR]
      : [ExceptionCategory.CRASH, ExceptionCategory.ERROR];
    return categories.map(x => ({ value: x, label: toI18n(x, pid) }));
  }

  return Object.freeze({
    unify,
    toI18n,
    fromExceptionType,
    isAccess,
    isCrash,
    isAnr,
    isError,
    isOom,
    getAvailableOptions,
  });
})();
