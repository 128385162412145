/**
 * Created by ellis on 24/11/2016.
 */
import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { PLATFORM_ID } from 'utils/constants';
import * as platformUtils from 'utils/platform';
import style from './style.scss';
import { useTranslation } from 'react-i18next';
import {SymbolFileTypeEnum} from "utils/constants/symbol-file-type-enum";
import { connect } from 'react-redux';
import { ze } from 'utils/zhEn';
import { Button, Divider } from 'antd';
import UploadIcon from 'svg/v2/newcs_dashboard_setting_alertsetting_managesymbolfiles_popup_upload_icon.svg';
import SuccessIcon from 'svg/v2/newcs_dashboard_setting_alertsetting_managesymbolfiles_popup_success_icon.svg';
import FailIcon from 'svg/v2/newcs_dashboard_setting_alertsetting_managesymbolfiles_popup_fail_icon.svg';
const UploadPanel = ({
  handleUpload, type, upperStyle, progress, closeWindow, tip, dsymsSize,
  reduxState,
}) => {
  const { t } = useTranslation();
  const { appId, platformId } = reduxState.app.get('current').toJS();

  function getPreUploadPanel() {
    let accept = '.zip';
    const symbolUploadUrl = `/crash-reporting/tutorial/${appId}?pid=${platformId}&step=symbol`;

    const symbolToolLinkDom = <a href={symbolUploadUrl} target="_blank" onClick={e => e.stopPropagation()}>{ t('SYMBOLTABLE.symbolTool') }</a>;
    let tipDom;

    if (type === SymbolFileTypeEnum.ANDROID_MAPPING) {
      accept = '.txt';
      tipDom = <div>
        <div>{ ze('支持 TXT 文件，最大不超过 1GB。','Support .txt file up to 1GB.') }</div>
        <div>{ ze('超过限制请使用', 'If the file exceeds the size limit, please use ') }{ symbolToolLinkDom }{ ze('上传。', ' to upload.') }</div>
      </div>;
    } else if (type === SymbolFileTypeEnum.PC_PDB) {
      accept = '.zip';
      const tips = ze('支持WinDbg可处理的exe、pdb等文件打包成的 zip 包。 Minidump格式的符号文件请使用流水线插件上传。','Support WinDbg processable zip packages of exe, pdb, etc. files. Please use the pipeline plugin to upload symbol files in Minidump format.');
      tipDom = <div>{ tips }</div>;
    } else if (type === PLATFORM_ID.SWITCH) {
      accept = '.zip';
      const tips = ze('上传ZIP压缩后的NSS文件。','Upload the ZIP-compressed NSS file.');
      tipDom = <div>{ tips }</div>;
    } else {
      tipDom = <React.Fragment>
        <div>
          <div>{ze('请上传 .stif 格式符号文件的 zip 压缩包。','Please upload a zip file of symbol files in .stif format.')}</div>
          <div>{ze('需要使用','You need to use ')}{ symbolToolLinkDom }{ze('制作。',' to make the zip file.')}</div>
          <div>{ze('最大不超过 1GB，超过限制请使用','Max file size is 1GB. If exceeded, please use ')}{ symbolToolLinkDom }{ze('或蓝盾流水线插件上传。',' to upload.')}</div>
        </div>
      </React.Fragment>;
    }

    return (
      <div className={upperStyle.popalert_main}>
        { type === 2 ? (
          <div className={style.tips}>
            <p>{ze('可通过终端命令快速查找指定 UUID 符号表文件','Terminal command to quickly find the specified UUID symbol table file')}</p>
            <p>mdfind &quot;com_apple_xcode_dsym_uuids == 21A1ED3E-CFF8-42E4-8AB2-52DD9A0726AC&quot;</p>
          </div>
        ) : ''}
        <Dropzone
          className={style.upload_area}
          onDrop={(files) => handleUpload(files)}
          accept={accept}
          multiple={false}>
          <UploadIcon />
          <p className={style.title} style={{ userSelect: 'none' }}>{ t('SYMBOLTABLE.点击添加文件或将文件拖拽至此处') }</p>
          <div style={{ paddingBottom: '20px' }}>{ tipDom }</div>
        </Dropzone>
      </div>
    );
  }

  function getUploadingPanel() {
    return (
      <div className={upperStyle.popalert_main}>
        {
          type === 2 ? (
          <div className={style.tips}>
            <p>{ze('可通过终端命令快速查找指定 UUID 符号表文件','Terminal command to quickly find the specified UUID symbol table file')}</p>
            <p>mdfind &quot;com_apple_xcode_dsym_uuids == 21A1ED3E-CFF8-42E4-8AB2-52DD9A0726AC&quot;</p>
          </div>
          ) : ''
        }
        <div className={style.upload_area}>
          <svg xmlns="http://www.w3.org/2000/svg" width="168" height="108" viewBox="0 0 168 108">
            <g fill="none" fillRule="evenodd" transform="translate(2 2)">
              <path fill="#FFF" d="M42.527 32.59C40.41 32.203 38.23 32 36 32 16.118 32 0 48.118 0 68s16.118 36 36 36h98c16.57 0 30-13.43 30-30 0-15.95-12.448-28.994-28.16-29.944C133.834 19.39 113.182 0 88 0 66.88 0 48.947 13.64 42.527 32.59z" />
              <path fill="#F4FAFF" d="M135.836 44c0 .02.003.037.004.056C151.552 45.006 164 58.05 164 74c0 16.57-13.43 30-30 30H36c-14.135 0-26.367-8.146-32.258-20H110c15.464 0 28-12.536 28-28 0-4.294-.967-8.363-2.695-12h.53z" />
              <path stroke="#42A5F5" strokeWidth="4" d="M42.527 32.59C40.41 32.203 38.23 32 36 32 16.118 32 0 48.118 0 68s16.118 36 36 36h98c16.57 0 30-13.43 30-30 0-15.95-12.448-28.994-28.16-29.944C133.834 19.39 113.182 0 88 0 66.88 0 48.947 13.64 42.527 32.59z" strokeLinejoin="round" />
              <g stroke="#CAE6FC" strokeWidth="2" transform="translate(116 62)" className={style.gear_s}>
                <path fill="#FFF" d="M10.8 0h2.4l.113 1.13c.11 1.102 1.02 2.336 2.045 2.76 1.02.424 2.537.2 3.4-.505l.88-.72 1.696 1.698-.72.878c-.7.86-.93 2.375-.505 3.402.42 1.02 1.65 1.934 2.76 2.045L24 10.8v2.4l-1.13.113c-1.102.11-2.336 1.02-2.76 2.045-.424 1.02-.2 2.537.505 3.4l.72.88-1.698 1.696-.878-.72c-.86-.7-2.375-.93-3.402-.505-1.02.42-1.934 1.65-2.045 2.76L13.2 24h-2.4l-.113-1.13c-.11-1.102-1.02-2.336-2.045-2.76-1.02-.424-2.537-.2-3.4.505l-.88.72-1.696-1.698.72-.878c.7-.86.93-2.375.505-3.402-.42-1.02-1.65-1.934-2.76-2.045L0 13.2v-2.4l1.13-.113c1.102-.11 2.336-1.02 2.76-2.045.424-1.02.2-2.537-.505-3.4l-.72-.88 1.698-1.696.878.72c.86.7 2.375.93 3.402.505 1.02-.42 1.934-1.65 2.045-2.76L10.8 0z" strokeLinejoin="round" />
                <circle cx="12" cy="12" r="4" fill="#F4FAFF" />
              </g>
              <g stroke="#CAE6FC" strokeWidth="2" transform="translate(13 41)" className={style.gear_m}>
                <path fill="#F8FCFF" d="M33.124 8.897c.285-.48.918-.68 1.43-.442l1.796.838c.505.235.767.848.58 1.38l-.73 2.092c-.368 1.048-.058 2.56.685 3.37l1.8 1.965c.744.813 2.21 1.254 3.282.98l2.164-.55c.542-.138 1.13.167 1.325.7l.678 1.86c.19.524-.057 1.143-.565 1.387l-2.012.97c-.993.477-1.837 1.762-1.885 2.86l-.114 2.662c-.048 1.1.677 2.45 1.64 3.022l1.907 1.132c.48.285.68.918.442 1.43l-.838 1.796c-.235.505-.848.767-1.38.58l-2.092-.73c-1.048-.368-2.56-.058-3.37.685l-1.965 1.8c-.813.744-1.254 2.21-.98 3.282l.55 2.164c.138.542-.167 1.13-.7 1.325l-1.86.678c-.524.19-1.143-.057-1.387-.565l-.97-2.012c-.477-.993-1.762-1.837-2.86-1.885l-2.662-.114c-1.1-.048-2.45.677-3.022 1.64l-1.132 1.907c-.285.48-.918.68-1.43.442l-1.796-.838c-.505-.235-.767-.848-.58-1.38l.73-2.092c.368-1.048.058-2.56-.685-3.37l-1.8-1.965c-.744-.813-2.21-1.254-3.282-.98l-2.164.55c-.542.138-1.13-.167-1.325-.7l-.678-1.86c-.19-.524.057-1.143.565-1.387l2.012-.97c.993-.477 1.837-1.762 1.885-2.86l.114-2.662c.048-1.1-.677-2.45-1.64-3.022l-1.907-1.132c-.48-.285-.68-.918-.442-1.43l.838-1.796c.235-.505.848-.767 1.38-.58l2.092.73c1.048.368 2.56.058 3.37-.685l1.965-1.8c.813-.744 1.254-2.21.98-3.282l-.55-2.164c-.138-.542.167-1.13.7-1.325l1.86-.678c.524-.19 1.143.057 1.387.565l.97 2.012c.477.993 1.762 1.837 2.86 1.885l2.662.114c1.1.048 2.45-.677 3.022-1.64l1.132-1.907z" />
                <circle cx="27" cy="27" r="8" fill="#FFF" transform="rotate(25 27 27)" />
              </g>
              <g stroke="#CAE6FC" strokeWidth="2" transform="translate(56 16)" className={style.gear_l}>
                <path fill="#FFF" d="M7.933 13.575c-.594-.932-.438-2.325.34-3.102l2.2-2.2c.78-.78 2.164-.937 3.102-.34l4.917 3.13c.932.592 2.515.73 3.528.31l2.453-1.016c1.016-.422 2.032-1.63 2.273-2.716l1.264-5.687C28.25.873 29.345 0 30.444 0h3.112c1.104 0 2.193.868 2.434 1.953l1.264 5.688c.24 1.08 1.26 2.297 2.273 2.717l2.453 1.016c1.016.422 2.59.285 3.528-.31l4.917-3.13c.932-.594 2.325-.438 3.102.34l2.2 2.2c.78.78.937 2.164.34 3.102l-3.13 4.917c-.592.932-.73 2.515-.31 3.528l1.016 2.453c.422 1.016 1.63 2.032 2.716 2.273l5.687 1.264c1.08.24 1.953 1.335 1.953 2.434v3.112c0 1.104-.868 2.193-1.953 2.434l-5.688 1.264c-1.08.24-2.297 1.26-2.717 2.273l-1.016 2.453c-.422 1.016-.285 2.59.31 3.528l3.13 4.917c.594.932.438 2.325-.34 3.102l-2.2 2.2c-.78.78-2.164.937-3.102.34l-4.917-3.13c-.932-.592-2.515-.73-3.528-.31l-2.453 1.016c-1.016.422-2.032 1.63-2.273 2.716l-1.264 5.687C35.75 63.127 34.655 64 33.556 64h-3.112c-1.104 0-2.193-.868-2.434-1.953l-1.264-5.688c-.24-1.08-1.26-2.297-2.273-2.717l-2.453-1.016c-1.016-.422-2.59-.285-3.528.31l-4.917 3.13c-.932.594-2.325.438-3.102-.34l-2.2-2.2c-.78-.78-.937-2.164-.34-3.102l3.13-4.917c.592-.932.73-2.515.31-3.528l-1.016-2.453c-.422-1.016-1.63-2.032-2.716-2.273L1.954 35.99C.873 35.75 0 34.655 0 33.556v-3.112c0-1.104.868-2.193 1.953-2.434l5.688-1.264c1.08-.24 2.297-1.26 2.717-2.273l1.016-2.453c.422-1.016.285-2.59-.31-3.528l-3.13-4.917z" />
                <circle cx="32" cy="32" r="12" fill="#F4FAFF" />
                <circle cx="32" cy="32" r="5" fill="#FFF" />
              </g>
            </g>
          </svg>
          <p className={style.title}>{ze('正在解析上传...','Parsing the upload...')}</p>
        </div>
      </div>
    );
  }

  function getFailedPanel() {
    let tips = ze('支持 ZIP 文件，最大不超过 100 MB，超过限制请使用','ZIP files are supported up to 100 MB, please use');
    if (type === 1) {
      tips = ze('支持 TXT 文件，最大不超过 100 MB，超过限制请使用','Support TXT files up to 100 MB, please use');
    }

    return (
      <div className={upperStyle.popalert_main}>
        { type === 2 ? (
          <div className={style.tips}>
            <p>{ze('可通过终端命令快速查找指定 UUID 符号表文件','Terminal command to quickly find the specified UUID symbol table file')}</p>
            <p>mdfind &quot;com_apple_xcode_dsym_uuids == 21A1ED3E-CFF8-42E4-8AB2-52DD9A0726AC&quot;</p>
          </div>
        ) : ''}
        <Dropzone
          className={style.upload_area}
          onDrop={(files) => handleUpload(files)}
          accept=".txt,.zip"
          multiple={false}>
          <FailIcon />
          {(progress === 3||progress ===-9) ? (
              <p className={style.title}>
                {ze('文件不合法，请','The file is not legal, please')}
                <a onClick={(e) => e.preventDefault()}>{ze('重新上传','Re-Upload')}</a>
              </p>
            )
            : (
              <p className={style.title}>
                {ze('服务异常，请稍后','Service exception, please wait or ')}
                <a onClick={(e) => e.preventDefault()}>{ze('再试','try again')}</a>
              </p>
            )}
        </Dropzone>
        {tip && <div className={style.upload_fail_tip}>{tip.tips}</div>}
        <div className={style.tail}>
          <Divider style={{margin:'20px 0'}}></Divider>
          <div style={{display:'flex',gap:'8px',justifyContent:'center'}}>
            <Button
              onClick={(e) => {
                e.preventDefault();
                closeWindow();}
              }
            >
              {ze('取消','Cancel')}
            </Button>
            <Button
              type='primary'
            ><Dropzone className={style.tryAgainButton}>{ze('再试','Try again')}</Dropzone></Button>
          </div>

        </div>
      </div>
    );
  }

  function getSucceededPanel() {
    return (
      <div className={upperStyle.popalert_main}>
        <div className={style.upload_area}>
          <SuccessIcon />
          <p className={style.title}>{ze('符号表文件经过处理后生效','Symbol table files are processed to take effect')}</p>
          <p className={style.sub1}>{ze('请稍后再查看状态','Please check the status later')}</p>
        </div>
        <div className={style.tail}>
            <Divider style={{margin:'20px 0'}}></Divider>
            <Button
              type="primary"
              onClick={(e) => {
                e.preventDefault();
                closeWindow();
              }}
            >
              {ze('确认','Confirmation')}
            </Button>
        </div>
      </div>
    );
  }

  let result = getPreUploadPanel();
  if (progress === 1) {
    result = getUploadingPanel();
  } else if (progress === 2) {
    result = getSucceededPanel();
  } else if (progress === 3 || progress === 4) {
    result = getFailedPanel();
  }
  return result;
};

UploadPanel.propTypes = {
  handleUpload: PropTypes.func.isRequired,
  type: PropTypes.number.isRequired,
  upperStyle: PropTypes.object.isRequired,
  progress: PropTypes.number.isRequired,
  closeWindow: PropTypes.func.isRequired,
  tip: PropTypes.object,
};

const mapStateToProps = state => ({
  reduxState: state,
});

export default connect(mapStateToProps)(UploadPanel);
