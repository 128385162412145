/**
 * Created by ellis on 23/11/2016.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import * as globalActions from 'reducers/global/globalActions';
import { Map as iMap } from 'immutable';
import { checkChannel, checkPlatform, checkVersion } from 'utils/Validate';
import { getCurrentTime } from 'utils/StringUtils';
import { isInArray, ravenPostError } from 'utils/helper';
import { SYMBOL_TIPS, PLATFORM_ID } from 'utils/constants';
import * as platformUtils from 'utils/platform';
import UploadPanel from './UploadPanel';
import SubForm from './SubForm';
import style from './style.scss';
import { withTranslation } from 'react-i18next'
import { isMobile, isPcOrLinux, isSwitch } from 'utils/platform';
import { SymbolFileTypeEnum, SymbolFileTypeUtil } from 'utils/constants/symbol-file-type-enum';
import {message, Modal, Spin} from 'antd';
import JSZip from "jszip";

@withTranslation()
class UploadPopAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUploadPanelSpinning: false,
      showSubForm: false,
      versionAlert: false,
      channelAlert: false,
      progress: 0, // 0 before upload, 1 uploading, 2 succeeded, 3 invalid file, 4 error
    };
    this.data = {
      uploadData: null,
    };
  }

  getType() {
    const { uploadPopAlertData } = this.props;
    const {
      type, pid,
    } = uploadPopAlertData.toJS();

    if (isPcOrLinux(pid)) {  // pc从外面拿到的type是null，目前只支持传windbg用的pdb
      return SymbolFileTypeEnum.PC_PDB;
    } else if (isSwitch(pid)) {
      return SymbolFileTypeEnum.NINTENDO_SWITCH_NSS;
    }
    return type;
  }

  // 执行上传请求
  upload(version, channel) {
    if (!this.data.uploadData) {
      message.error('参数错误,上传失败: uploadData is empty');
      return;
    }
    const {
      appId, pid, type, userId, appKey, file, uuid,
    } = this.data.uploadData.toJS();
    if (typeof appId !== 'string' || appId.length === 0) {
      message.error('参数错误,上传失败: invalid appId');
      return;
    }
    if (!checkPlatform(pid)) {
      message.error('参数错误,上传失败: invalid pid');
      return;
    }
    if (
        (pid === PLATFORM_ID.ANDROID && type !== 1 && type !== 3) ||
        (pid === PLATFORM_ID.IOS && type !== 2) ||
        (platformUtils.isPcOrGameConsole(pid) && type !== pid)
      ) {
      message.error('参数错误,上传失败: invalid type');
      return;
    }
    if ((type === 1 && !version) ||
        (platformUtils.isPcOrGameConsole(type) && !version)) {
      message.error('参数错误,上传失败: invalid version');
      return;
    }
    const fd = new FormData();
    fd.append('file', file);
    fd.append('app_id', appId);
    fd.append('pid', pid);
    fd.append('productVersion', version ? encodeURIComponent(version) : '');
    fd.append('symbolType', type);
    fd.append('channel', channel ? encodeURIComponent(channel) : '');
    fd.append('uuid', uuid || '');
    fd.append('arch', '');
    fd.append('soName', '');
    fd.append('operateUser', userId);
    fd.append('fileName', encodeURIComponent(file.name));
    fd.append('uploadTime', getCurrentTime('YYYY-MM-DD HH:mm:ss'));
    fd.append('operateType', '1');

    const xhr = new XMLHttpRequest();

    xhr.open('POST', `/openapi/file/upload/symbol?app_key=${appKey}&app_id=${appId}`, true);

    xhr.upload.onprogress = (event) => this.updateProgress(event);
    const that = this;
    xhr.onreadystatechange = function onreadystatechange() {
      if (parseInt(this.readyState) === 4) {
        if (parseInt(xhr.status) === 200) {
          const resp = JSON.parse(xhr.responseText);
          if (isInArray(['0', '40403', '40401', '40404', '40405', '40406', '40407', '40409', '40410', '40411', '40412', '40413', '40416', '50500', '50501', '50502'], resp.data.code)) {
            if (parseInt(resp.data.code) === 0) {
              that.uploadSucceed();
            } else {
              resp.data.code in SYMBOL_TIPS && that.props.actions.changeUploadPopAlertInfo(SYMBOL_TIPS[resp.data.code]);
              that.uploadFailed();
            }
          } else {
            that.uploadFailed(4);
          }
        } else {
          // xhr != 200
          if (xhr.status === 400) {
            that.props.actions.changeUploadPopAlertInfo({
              tips: '请求参数不合法',
              color: 'red',
            });
          }
          if (xhr.status === 401 || xhr.status === 403) {
            that.props.actions.changeUploadPopAlertInfo({
              tips: '您没权限上传符号表',
              color: 'red',
            });
          }
          if (xhr.status >= 500) {
            ravenPostError(new Error(`[自定义错误]upload sym fail! status: ${xhr.status}`));
          }
          that.uploadFailed(4);
        }
      } else {
        // fix me
        that.uploadFailed(4);
      }
    };
    this.uploadBegin();
    xhr.send(fd);
  }

  uploadFailed(progress = 3) {
    this.setState({ progress });
    this.data.uploadData = null;
  }

  uploadSucceed() {
    this.setState({ progress: 2 });
  }

  uploadBegin() {
    this.setState({ progress: 1 });
  }

  updateProgress(event) {
    console.info(event);
  }

  async validatePreUploadFile(file) {
    const { uploadPopAlertData, t } = this.props;
    const type = this.getType();
    const e = Error(t('SYMBOLMANAGE.uploadFailed'));

    // 测试服调试使用，允许上传带so的符号表文件
    const allowSoFileInTestEnv = window.location.hostname.includes('test.crashsight');

    if (type === SymbolFileTypeEnum.ANDROID_STIF
      || type === SymbolFileTypeEnum.IOS_STIF) {
      const zipFile = await JSZip.loadAsync(file);
      let hasStif = false;
      let hasNonStif = false;
      zipFile.forEach((relativePath, innerFile) => {
        const isStif = relativePath.toLowerCase().endsWith('.stif');
        const isSo = relativePath.toLowerCase().endsWith('.so');
        if (isStif || (allowSoFileInTestEnv && isSo)) {
          hasStif = true;
        } else {
          hasNonStif = true;
        }
      });

      if (!hasStif && !hasNonStif) {
        Modal.error({ content: 'Invalid zip file, content is empty' });
        throw e;
      }
      if (!hasStif) {
        Modal.error({ content: t('SYMBOLMANAGE.uploadErrorNoStif') });
        throw e;
      }
      if (hasNonStif) {
        Modal.error({ content: t('SYMBOLMANAGE.uploadErrorMustAllStif') });
        throw e;
      }
    }
  }

  async handleUpload(files) {
    const { uploadPopAlertData, t } = this.props;
    const {
      appId, pid, userId, appKey, uuid,
    } = uploadPopAlertData.toJS();

    const type = this.getType();

    if (!files || files.length <= 0) {
      message.error(type === 1 ? '请上传.txt文件' : '请上传.zip文件');
      return;
    }
    if (files.length > 1) {
      message.error('Cannot upload multiple files at once.');
      return;
    }

    const file = files[0];

    // 校验文件大小是否超过1G
    if (type !== 10 && file.size > 1024 * 1024 * 1024) {
      message.error(t('SYMBOLMANAGE.uploadErrorSizeLimit'));
      return;
    }

    // 检查文件具体格式
    try {
      this.setState({ isUploadPanelSpinning: true });
      await this.validatePreUploadFile(file);
    } catch (e) {
      message.error(e.message);
      return;
    } finally {
      this.setState({ isUploadPanelSpinning: false });
    }

    this.data.uploadData = iMap({
      appId, pid, type, userId, appKey, file, uuid,
    });

    this.setState({ showSubForm: true });
  }

  checkSubFormSubmit(version, channel) {
    const cv = checkVersion(version);
    this.setState({ versionAlert: !cv });
    if (!cv) {
      return;
    }
    if (channel) {
      const cc = checkChannel(channel);
      this.setState({ channelAlert: !cc });
      if (!cc) {
        return;
      }
    }
    this.setState({ showSubForm: false });
    this.upload(version, channel);
  }

  render() {
    const { uploadPopAlertData, dsymsSize } = this.props;
    const { tip, pid } = uploadPopAlertData.toJS();
    const type = this.getType();

    let title = this.props.t("UPLOADSYMBOL.titleUploadSuccess");
    if (this.state.progress !== 2) {
      if (SymbolFileTypeUtil.isMapping(type)) {
        title = this.props.t("UPLOADSYMBOL.titleUploadMap");
      } else if (type === 2) {
        title = this.props.t("UPLOADSYMBOL.titleUploadIos");
      } else if (type === 3) {
        title = this.props.t("UPLOADSYMBOL.titleUploadSo");
      } else if (type === 10 ) {
        title = this.props.t("UPLOADSYMBOL.titleUploadPdb");
      } else if (type === PLATFORM_ID.SWITCH ) {
        title = this.props.t("UPLOADSYMBOL.上传nss文件");
      }
    }

    return (
      <div className={style.popalert_mask}>
        <div className={classnames(style.popalert_content, this.state.showSubForm ? style.short : '')}>
          <div className={style.popalert_control}>
            <h1>{title}</h1>
            <span
              className={style.popalert_closebtn}
              onClick={() => {
                this.props.closeWindow();
              }} />
          </div>
          { this.state.showSubForm
            ? (
              <SubForm
                checkSubmit={(...params) => this.checkSubFormSubmit(...params)}
                cancelFunc={() => {
                  this.props.closeWindow();
                }}
                upperStyle={style}
                versionAlert={this.state.versionAlert}
                channelAlert={this.state.channelAlert}
                pid={pid}/>
            )
            : (
              <Spin spinning={this.state.isUploadPanelSpinning}>
                <UploadPanel
                  dsymsSize={dsymsSize}
                  type={type}
                  handleUpload={(files) => this.handleUpload(files)}
                  upperStyle={style}
                  tip={tip}
                  progress={this.state.progress}
                  closeWindow={() => {
                    this.props.closeWindow();
                  }} />
              </Spin>
            ) }

        </div>
      </div>
    );
  }
}

UploadPopAlert.propTypes = {
  closeWindow: PropTypes.func.isRequired,
  uploadPopAlertData: PropTypes.instanceOf(iMap).isRequired,
};

export default connect((state) => {
  return {
    uploadPopAlertData: state.global.get('uploadPopAlertData'),
    dsymsSize: state.global.get('dsymsSize'),
  };
}, (dispatch) => ({ actions: bindActionCreators(globalActions, dispatch) }))(UploadPopAlert);
