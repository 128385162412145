import RestHelper from 'utils/RestHelper';
export default RestHelper.publish({

  keywordTrend: {
    get: {
      method: 'get',
      uri: '/apps/pid/{pid}/appId/{appId}/keywordsTrend',
    },
  },

  keywordInfo: {
    get: {
      method: 'get',
      uri: '/appConfig/keyword/{appId}/{pid}',
    },
    delete: {
      method: 'delete',
      uri: '/appConfig/keyword/{appId}/{pid}/{id}',
    },
    put: {
      method: 'put',
      uri: '/appConfig/keyword',
    },
    post: {
      method: 'post',
      uri: '/appConfig/keyword',
    },
  },

});
