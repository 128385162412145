import React, { useState, useEffect, useMemo } from 'react';
import {
  Input,
  Modal,
  Spin,
  Divider,
  Table, Button, message, Checkbox,
} from 'antd';
import { ze } from 'utils/zhEn';

const IssueChartDownloadFieldSelectModal = ({
  visible,
  onVisibleChange,
  fieldOptions,
  onConfirm,
}) => {
  const [value, setValue] = useState([]);

  return <Modal
    title={ze('选择需要下载的字段', 'Select The Fields To Download')}
    width='480px'
    visible={visible}
    maskClosable={false}
    onCancel={() => {
      onVisibleChange(false);
      setValue([]);
    }}
    okText={ze('下载', 'Download')}
    okButtonProps={{
      disabled: value.length === 0,
    }}
    onOk={() => {
      onConfirm(value);
      onVisibleChange(false);
      setValue([]);
    }}
  >
    <Checkbox.Group
      value={value}
      onChange={v => setValue(v)}
    >
      { fieldOptions.map((x, i) => <div key={i} style={{ marginBottom: '2px' }}>
        <Checkbox value={x.value}>{ x.label }</Checkbox>
      </div>) }
    </Checkbox.Group>
  </Modal>
};

IssueChartDownloadFieldSelectModal.propTypes = {
};

export default IssueChartDownloadFieldSelectModal;
