import React, { useState, useEffect, useMemo } from 'react';
import { useImmer } from 'use-immer';
import PropTypes from 'prop-types';
import RestHelper from 'utils/RestHelper';
import { Button, Card, Spin, Tag, List, Modal, Input, Space, message, notification, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { ze } from 'utils/zhEn';
import { useDispatch, useSelector } from 'react-redux';
import { selectServerAppSettings } from 'utils/selectors/selectors';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { ServerAppSettings } from 'utils/server-app-settings';
import { upsertServerAppSettings } from 'reducers/app/appActions';
import { connect } from 'react-redux';
import { sleep } from 'utils/sleep';
import moment from 'moment';

const CommonSequence = (props) => {
  const { t } = useTranslation();
  const { appId, platformId, issueHash, reduxState } = props;

  const dispatch = useDispatch();
  const serverAppSettings = useSelector(state => selectServerAppSettings(state, appId));
  const commonSequenceCustomKeyList = serverAppSettings[ServerAppSettings.keys.commonSequenceCustomKeyList] || [];

  useDeepCompareEffect(() => {
    setEditingCustomKeyText(commonSequenceCustomKeyList);
  }, [commonSequenceCustomKeyList]);

  const [state, updateState] = useImmer({
    hasCommonSequence: false,
    updateTimeMillis: 0,
  });

  const [customKeyToCommonSequenceListEntries, setCustomKeyToCommonSequenceListEntries] = useState([]);

  const [modalVisible, setModalVisible] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [editingCustomKeyText, setEditingCustomKeyText] = useState('');
  const [loading, setLoading] = useState(false);
  const [calculating, setCalculating] = useState(false);
  const [ configuringSequenceDropdownData,setConfiguringSequenceDropdownData] = useState([])

  // ES里面seq相关的字段格式是类似
  // [[ '1;2;3;', 'a;b;c;' ]] 这样的
  // 需要修正成
  // [['1', '2', '3'], ['a', 'b', 'c']]
  const fixSequenceFormat = (seq) => {
    if (!seq || seq.length === 0) {
      return [];
    }
    // 只取第一组序列
    return seq[0].map(x => x.split(';').filter(y => y));
  };

  async function fetchCommonSequenceData() {
    const rsp = await RestHelper.post('/redir/query/data/queryCommonSequence', {
      appId,
      platformId,
      issueId: issueHash,
    }, { silentFailure: true });
    const { data } = rsp.json;
    if (!data) {
      return;
    }
    const { hasCommonSequence, commonSequenceResult } = data;
    const updateTimeMillis = (commonSequenceResult || {}).updateTimeMillis || 0;
    updateState((draft) => {
      draft.hasCommonSequence = hasCommonSequence;
      draft.updateTimeMillis = updateTimeMillis;
    });

    if (hasCommonSequence) {
      const commonResult = ((commonSequenceResult || {}).commonSequenceResult || {}).commonResult || {};
      const entries = Object.entries(commonResult)
        .filter(([k, v]) => k.endsWith('Seq'))
        .map(([k, v]) => [k, fixSequenceFormat(v)]);
      setCustomKeyToCommonSequenceListEntries(entries);
    } else {
      setCustomKeyToCommonSequenceListEntries([]);
    }

    return data;
  }

  const getListDomBySequence = (list, showArrows = false) => {
    const MAX_ITEMS = 2;

    const sortedAndCutOffList = [...list]
      .sort((a, b) => b.length - a.length)
      .filter((x, i) => i < MAX_ITEMS);
    return <List
      bordered
      dataSource={sortedAndCutOffList}
      renderItem={item => (<List.Item style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
        {item.map((x, i) => {
          return <React.Fragment key={i}>
            {showArrows && i !== 0 && <div style={{ margin: '0 3px', userSelect: 'none' }}>→</div>}
            <Tag color='processing' style={{ fontSize: '14px', margin: '3px 2px' }}>{x}</Tag>
          </React.Fragment>;
        })}
      </List.Item>)}
    />;
  };

  const isPropsLoaded = appId && platformId && issueHash;

  useEffect(() => {
    fetchCommonSequenceData();
  }, [isPropsLoaded]);

  async function upsertCommonSequenceCustomKeyList() {
    setModalLoading(true);
    await dispatch(upsertServerAppSettings(appId, { commonSequenceCustomKeyList:editingCustomKeyText }));
    setModalLoading(false);
  }

  async function calcIssueCommonSequence() {
    if (commonSequenceCustomKeyList.length === 0) {
      message.warn(ze('必须配置序列关键字后才可以计算。', 'You must configure common sequence keywords before calculate.'));
    }

    setLoading(true);
    setCalculating(true);
    message.success(ze('已触发计算,最近1000条的公共序列', 'Triggered computation, last 1000 common sequences'));
    await RestHelper.post('/redir/api/issue/calcIssueCommonSequence', {
      appId,
      platformId,
      issueId: issueHash,
    });

    // 轮询查询计算结果，根据updateTimeMillis是否更新判断计算是否成功
    const INTERVAL_MS = 5000;
    const MAX_POOLING_TRY = 30;
    const oldUpdateTimeMillis = state.updateTimeMillis;
    for (let i = 0; i < MAX_POOLING_TRY; i++) {
      await sleep(INTERVAL_MS);
      const data = await fetchCommonSequenceData();
      const { hasCommonSequence, commonSequenceResult } = data;
      const updateTimeMillis = (commonSequenceResult || {}).updateTimeMillis || 0;
      if (updateTimeMillis !== oldUpdateTimeMillis) {
        setCalculating(false);
        setLoading(false);
        return;
      }
    }

    notification.error({
      message: ze('公共序列计算超时，请联系管理员。', 'Calculation timeout, please contact the system admins.'),
      duration: 0,
    });
    setCalculating(false);
    setLoading(false);
  }

  async function onClickOpenModal() {
    setEditingCustomKeyText(commonSequenceCustomKeyList);
    const searchMomentBegin = moment().subtract(3, 'days'); // 初始化的时候，设置默认时间过滤范围为3天
    const searchMomentEnd = moment();
    const rsp = await RestHelper.mustPost('/redir/query/data/queryIssueOriginKeys', {
      appId: appId,
      platformId: platformId,
      issueHash: issueHash,
      beginMillis: searchMomentBegin ? searchMomentBegin.valueOf() : null,
      endMillis: searchMomentEnd ? searchMomentEnd.valueOf() : null,
    });
    const sequenceKeyOptions = (rsp.json.data || [])
      .map(item => String(item || '').replace(/^C03_/, ''))
      .filter(x => x && x.startsWith('S#'))
      .map(x => ({ label: x, value: x }));
    setConfiguringSequenceDropdownData(sequenceKeyOptions);
    setModalVisible(true);
  }

  const keywordConfigModal = <Modal
    visible={modalVisible}
    onCancel={() => setModalVisible(false)}
    onOk={async () => {
      await upsertCommonSequenceCustomKeyList();
      setModalVisible(false);
      message.success(ze('保存成功', 'Successfully saved.'));
    }}
    okButtonProps={{ disabled: modalLoading }}
    title={ze('配置序列关键字', 'Common Sequence Keywords Config')}
  >
    <Spin spinning={modalLoading}>
      <div>{ ze('每行一个自定义关键字，自定义关键字以S#开头。', 'Each line contains one custom keyword, and custom keywords must start with S#.') }</div>
      <Select
        mode="tags"
        style={{
          width: '100%',
        }}
        defaultValue={editingCustomKeyText}
        onChange={e => setEditingCustomKeyText(e)}
        options={configuringSequenceDropdownData}
      />
    </Spin>
  </Modal>;

  const commonSequenceNotCalculatedBody = <div style={{ color: '#999' }}>{ ze('公共序列未计算', 'Not calculated yet.') }</div>;

  const commonSequenceBodyItems = customKeyToCommonSequenceListEntries.map(([k, v], i) => {
    return <div key={i}>
      <div>{ k }</div>
      <div>{ getListDomBySequence(v) }</div>
    </div>;
  });

  const commonSequenceBody = <div>
    { commonSequenceBodyItems }
    { commonSequenceBodyItems.length === 0 && <div>{ ze('无公共序列（已计算）', 'No common sequences. (Calculated)') }</div> }
  </div>;

  return (<Card title={
    <Space>
      <div>{ t('behavior.公共序列') }</div>
      <Button
        disabled={loading}
        size='small'
        onClick={() => onClickOpenModal()}
      >{ ze('配置序列关键字', 'Common Sequence Keywords Config') }</Button>
      <Button
        disabled={ loading || editingCustomKeyText.length < 1 }
        size='small'
        onClick={() => calcIssueCommonSequence()}
      >{ ze('计算当前问题', 'Calculate Current Issue') }</Button>
    </Space>
  }>
    <Spin spinning={loading}>
      { calculating && <div style={{ color: '#999' }}>{ ze('计算中', 'Calculating') }</div> }
      { !calculating && (state.hasCommonSequence ? commonSequenceBody : commonSequenceNotCalculatedBody) }
      { keywordConfigModal }
    </Spin>
  </Card>);
};

CommonSequence.propTypes = {
  appId: PropTypes.string,
  platformId: PropTypes.string,
  issueHash: PropTypes.string,
};

const mapStateToProps = state => ({
  reduxState: state,
});

export default connect(mapStateToProps)(CommonSequence);
