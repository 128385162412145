import { fromJS, Record } from 'immutable';
import UserRest from 'utils/UserRest';
import { createAction, handleActions } from 'redux-actions';
import { getMemberList } from 'reducers/product/actions';
import { aegisSetConfig } from 'utils/aegis';
import { ThemeColorUtil } from 'utils/theme-color-util';

const Member = new Record({
  appId: null,
  platform: null,
  userId: '',
  newUserId: '',
  qqNickName: '',
  name: '',
  type: 1,
  email: '',
});
// ajax的参数
const initalUserParam = fromJS({
  userId: '',
  newUserId: '',
  nickname: '',
  phone: '',
  sex: '',
  birthdate: '',
  city: '',
  address: '',
  workingYears: '',
  position: '',
  email: '',
  logoUrl: '',
  validated: 0,
  signedAgreement: 0,
  realname: '',
  idCard: '',
});

const initialUserState = fromJS({
  current: {},
  userParams: initalUserParam,
  isUpdateFlag: true,
  isInTencentLocalNetwork: null,
  members: [],
  frontendWebVolatileConfig: {},
  appIdToPermissionList: {},
}).set('frontendWebVolatileConfig', {
  success: false,
  result: [],
});

export function actionGetUserInfo(){
  return (dispatch) => {
    return dispatch({
      rest: UserRest.user.getUserInfo,
    });
  };
}

export function getFrontendWebVolatileConfig() {
  return (dispatch) => {
    return dispatch({
      rest: UserRest.frontendWebVolatileConfig.post,
    });
  };
}

export function fetchPermissionListByAppId(appId) {
  return (dispatch) => {
    return dispatch({
      rest: UserRest.listPermission.get,
      data: { appId },
    });
  };
}

export function updateUserInfo() {
  return (dispatch, getState) => {
    const {
      nickname, email, userPreferences
    } = getState().user.get('userParams').toJS();

    return dispatch({
      rest: UserRest.user.update,
      data: {
        nickname,
        email,
        userPreferences
      },
    });
  };
}

export function updateUserInfoFields(obj) {
  // console.log('!!!updateUserInfoFields obj', obj);
  return (dispatch, getState) => {
    const userParams = getState().user.get('userParams').toJS();
    Object.assign(userParams, obj);
    // console.log('!!!updateUserInfoFields userParams', userParams);
    return dispatch({
      rest: UserRest.user.update,
      data: userParams,
    });
  };
}

/**
 * 获取城市选择器的数据
 */
export function getCityData() {
  return {
    rest: UserRest.city.get,
    data: {},
  };
}

export const changeUserParamOptions = createAction('RESET_USER_PARAM');

export const isUpdateFlag = createAction('IS_UPDATE_FLAG');

export const resetUserParam = createAction('RESET_FORM');

export const setIsInTencentLocalNetwork = createAction('SET_IS_IN_TENCENT_LOCAL_NETWORK');

/**
 * 获取应用的QQ成员信息
 */
export function getMembers() {
  return (dispatch, getState) => {
    const currentApp = getState().app.get('current');
    return dispatch({
      rest: UserRest.members.get,
      data: { appId: currentApp.get('appId'), pid: currentApp.get('pid') },
    });
  };
}

// 计算是否有运营权限
export function updateOperatorRight() {
  return (dispatch, getState) => {
    // let currentApp = getState().app.get('current').toJS();
    const { appId } = getState().app.get('current').toJS();
    const curUser = getState().user.get('current').toJS();
    if (curUser.isSuper === 1) {
      return dispatch({
        type: 'UPDATE_CURUSER_ROLE',
        payload: { type: 1, isOperator: 0 },
      });
    }
    if (appId) {
      return dispatch(getMemberList()).then((res) => {
        const curAppMemberList = res.members;
        let type = 2;
        let isOperator = 0;
        const curUserMembers = curAppMemberList.filter((mem) => mem.newUserId === curUser.newUserId);
        if (curUserMembers && curUserMembers.length > 0) {
          type = curUserMembers[0].type;
          isOperator = curUserMembers[0].isOperator;
        }
        return dispatch({
          type: 'UPDATE_CURUSER_ROLE',
          payload: { type, isOperator },
        });
      });
    }
    return Promise.resolve('OK');
  };
}

export function updateOperatorRightPromise() {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    return dispatch({
      rest: UserRest.members2.get,
      data: { appId, pid },
    });
  };
}

// 发送验证码
export function sendVeriticate(operationFrom, phoneNum) {
  return (dispatch, getState) => {
    const user = getState().user.get('current');
    const userId = user.get('newUserId');
    return dispatch({
      rest: UserRest.sendVeriticate.get,
      data: { userId, operationFrom, phoneNum },
    });
  };
}

// 验证验证码
export function veriticateCode(params) {
  return (dispatch) => {
    return dispatch({
      rest: UserRest.veriticateCode.get,
      data: params,
    });
  };
}

export default handleActions({
  GET_USER_INFO_SUCC: (state, action) => {
    const data = action.response;
    const { newUserId, userPreferences } = data || {};
    aegisSetConfig({
      uin: newUserId,
    });
    const { themeColor } = userPreferences || {};
    ThemeColorUtil.setThemeColor(themeColor);
    return state
      .update('current', (current) => current.merge(data))
      .set('userParams', state.get('userParams').merge(data));
  },

  USER_UPDATE_SUCC: (state) => state.update('current', (current) => current.merge(state.get('userParams'))).set('isUpdateFlag', true).set('userParams', state.get('userParams')),

  RESET_USER_PARAM: (state, action) => state.update('userParams', (params) => params.set(action.payload.name, action.payload.value)),

  SET_IS_IN_TENCENT_LOCAL_NETWORK: (state, action) => state.set('isInTencentLocalNetwork', action.payload),

  /**
   * 判断改变表单的值后,有没有提交保存,没有保存的话,还原原来的param
   */
  RESET_FORM: (state, action) => state.set('userParams', state.get('userParams').merge(action.payload)),

  IS_UPDATE_FLAG: (state, action) => state.set('isUpdateFlag', action.payload),

  /**
   * 成功获取QQ成员信息
   */
  MEMBERS_GET_SUCC: (state, action) => state.set('members', fromJS(action.response.members.map((item) => new Member(item))).filter((member) => !!member.get('userId'))),

  UPDATE_CURUSER_ROLE: (state, action) => {
    return state.update('current', (current) => current.merge(action.payload));
  },

  FRONTENDWEBVOLATILECONFIG_POST_SUCC: (state, action) => {
    return state.set('frontendWebVolatileConfig', action.json.data);
  },

  LISTPERMISSION_GET_SUCC: (state, action) => {
    const { appId } = action.params;
    let appIdToPermissionList = state.get('appIdToPermissionList') || Object.create(null);
    appIdToPermissionList = {
      ...appIdToPermissionList,
      [appId]: action.json.data,
    };
    return state.set('appIdToPermissionList', appIdToPermissionList);
  },
}, initialUserState);
