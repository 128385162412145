import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { ze } from 'utils/zhEn';
import FlamethrowerTree from './flamethrowerTree';
import { Switch } from 'antd';



const FlameGraph = (props) => {
  const {
    treeTackTrace,
    nativeLimitFormer,//旧的深度
    nativeLimitNewly,//当前深度
    javaLimitFormer,//旧的深度
    javaLimitNewly,//当前深度
    showNativeLimit,
    showJavaLimit,
  } = props;
  const [ currentState, setCurrentState ] = useState(false)
  //计算更改前当前页面是java还是native
  let former = showNativeLimit ? nativeLimitFormer : javaLimitFormer;//旧的
  //计算更改后当前页面是java还是native
  let newly = showNativeLimit ? nativeLimitNewly : javaLimitNewly;  //新的
  if (showJavaLimit) {
    // 如果treeTackTrace能匹配到".so"或者".dylib",则使用nativeLimitFormer否则使用javaLimitFormer
    if (/(\.so|\.dylib)/.test(treeTackTrace)) {
      former = nativeLimitFormer;
      newly = nativeLimitNewly;
    } else {
      former = javaLimitFormer;
      newly = javaLimitNewly;
    }
  }
  const newlyCopy = newly == 0 ? former : newly;
  const treeTackTraceData = JSON.parse(treeTackTrace);
  const svgRef = useRef();

  useEffect(() => {
    if(currentState){
      const svg = d3.select(svgRef.current);

      const treeLayout = d3.tree().size([800, 600]).separation(() => 1);
      const root = d3.hierarchy(treeTackTraceData);
      const treeData = treeLayout(root);

      const linkGenerator = d3.linkVertical()
        .x((d) => d.x)
        .y((d) => d.y);

      svg.selectAll('*').remove(); // 清空SVG中的内容，避免重复绘制

      svg.attr('width', '100%')
        .attr('viewBox', `0 0 1000 700`)
        .attr('preserveAspectRatio', 'xMidYMid meet');


      const g = svg.append('g')
        .attr('transform', 'translate(50, 50)'); // 居中显示

      g.selectAll('path')
        .data(treeData.links())
        .enter()
        .append('path')
        .attr('d', linkGenerator)
        .attr('fill', 'none')
        .attr('stroke', 'black');

      g.selectAll('circle')
        .data(treeData.descendants())
        .enter()
        .append('circle')
        .attr('cx', (d) => d.x)
        .attr('cy', (d) => d.y)
        .attr('r', 4);

      g.selectAll('text')
        .data(treeData.descendants())
        .enter()
        .append('text')
        .attr('x', (d) => d.x)
        .attr('y', (d) => d.y)
        .attr('dx', 5)
        .attr('dy', 3)
        .text((d) => `${(d.data.percentage == 100) ? '' : (d.data.percentage).toFixed(2) + '%'}`)
        .append('title')
        .text((d) => d.data.name);

      // 获取内容的边界框
      const contentBBox = g.node().getBBox();

      // 设置SVG元素的高度为内容的边界框大小
      svg.attr('height', contentBBox.height + 100);

      g.selectAll('path.star')
        .data(treeData.descendants().filter((d, i) => i > 0 && d.depth <= newlyCopy)) // 只选择除根节点外的其他节点进行绘制
        .enter()
        .append('path')
        .attr('class', 'star')
        .attr('d', d3.symbol().type(d3.symbolStar).size(40))
        .attr('transform', (d) => `translate(${d.x}, ${d.y})`)
        .style('fill', 'blue')
        .style('stroke', 'none');
    }
  }, [treeTackTraceData, currentState ]);

  const onChange = (checked) => {
    setCurrentState(checked)
  };

  return (
    <div style={{width:'100%', overflow: 'hidden'}}>
      <div style={{display:'flex',justifyContent:'space-between'}}>
        <FlamethrowerTree
          data={treeTackTraceData}
          currentDepth={newlyCopy}
        ></FlamethrowerTree>
        <div style={{display:'flex'}}>
          <div>{ze('打开缩略图','View Graph')}</div>
          <Switch defaultChecked={currentState} onChange={onChange}/>
        </div>
      </div>
      {currentState && <div style={{ display: 'flex', justifyContent: 'center'}}>
        <svg ref={svgRef} style={{ width: '100vw', height: '100%' }}></svg>
      </div>}
    </div>
  );
};

export default FlameGraph;
