import scss from 'styles/constant.scss';
import { ThemeColorUtil } from 'utils/theme-color-util';

export const CS_STYLES = Object.freeze({
  getPrimaryColor() {
    return ThemeColorUtil.getIsThemeColorBlue() ? scss.themeColorBluePrimaryColor : scss.themeColorOrangePrimaryColor;
  },

  /** @deprecated 这个值页面初始化的时候就固定下来了，如果主题色变更了，要使用getPrimaryColor()函数来拿正确的值 */
  PRIMARY_COLOR: ThemeColorUtil.getIsThemeColorBlue() ? scss.themeColorBluePrimaryColor : scss.themeColorOrangePrimaryColor,

  PRIMARY_COLOR_RED: scss.primaryColorRed,
  PRIMARY_COLOR_ORANGE: scss.primaryColorOrange,
  LINK_COLOR: scss.linkColor,
  LINK_HOVER_COLOR: scss.linkHoverColor,
  LINK_ACTIVE_COLOR: scss.linkActiveColor,
  MAIN_TEXT_COLOR: scss.mainTextColor,
  SUB_TEXT_COLOR: scss.subTextColor,
  MAIN_HEADER_HEIGHT: scss.mainHeaderHeight,
  LEFT_MENU_WIDTH: scss.leftMenuWidth,
  LEFT_SUB_MENU_WIDTH: scss.leftSubMenuWidth,
  COLLAPSED_LEFT_MENU_WIDTH: scss.collapsedLeftMenuWidth,
  FONT_MAIN: scss.fontMain,
  FONT_LIGHT: scss.fontLight,
  GLOBAL_NOTIFICATION: scss.isGlobalNotification,
});
