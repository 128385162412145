import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push as pushState } from 'connected-react-router';
import { selectApp, hasGray } from 'reducers/app/appActions';
import { getExceptionType } from 'utils/helper';
import { changeLeftMenuState } from 'reducers/global/globalActions';
import { updateOperatorRight } from 'reducers/user';
import route from 'route/workbench';
/**
 * 个人工作台
 * TODO
 * leftmenu
 */
class WorkBench extends Component {
  render() {
    const { url } = this.props;
    return (
      <div className="exception_page">
        {route({ match: { url } })}
      </div>
    );
  }
}

WorkBench.propTypes = {
  url: PropTypes.string,
  children: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
};

export default connect((state, router) => {
  return {
    url: router.match.url,
    pathname: router.location.pathname,
    user: state.user.get('current'),
    currentApp: state.app.get('current'),
    appList: state.app.get('appList'),
    leftMenuState: state.global.get('leftMenuState'),
  };
}, (dispatch) => ({
  actions: bindActionCreators({
    pushState,
    selectApp,
    hasGray,
    changeLeftMenuState,
    updateOperatorRight,
  }, dispatch),
}))(WorkBench);
