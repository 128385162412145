/**
 * Created by ellis on 24/11/2016.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import style from './style.scss';
import { PLATFORM_ID } from 'utils/constants';
import * as platformUtils from 'utils/platform';
import { ze } from 'utils/zhEn';
import {Button,Divider} from 'antd';
const SubForm = ({
  checkSubmit, cancelFunc, upperStyle, versionAlert, pid
}) => {
  let version = null;

  function checkSubFormSubmit() {
    checkSubmit((version.value || '').trim(), null);
  }

  return (
    <div className={style.sub_form}>
      <div className={classnames(upperStyle.popalert_main)} style={{minHeight:'200px'}}>
        <p>{ze('符号表文件信息','Symbol table file info')}</p>
        <div className={classnames(versionAlert ? style.alert : '')}>
          <label htmlFor="null">
            {ze('对应应用版本','Corresponds to the app version')}
            <span className={style.necessary}>*</span>
          </label>
          <input placeholder={ze("长度小于100",'Up to 100 characters')} ref={(target) => { version = target; }} />
          { versionAlert ? <span className={style.error}>{ze('版本输入不正确','Incorrect version input')}</span> : ''}
        </div>
      </div>
      <Divider style={{paddingTop:'0'}}></Divider>
      <div style={{display:'flex',gap:'16px',paddingTop:'0',paddingBottom:'20px',justifyContent:'center'}}>
        <Button onClick={()=>{cancelFunc();}}>{ze('取消','Cancel')}</Button>
        <Button type="primary" onClick={()=>{checkSubFormSubmit();}}>{ze('提交','Submit')}</Button>
      </div>
      {/* <div className={style.operation}>
        <a
          className={style.submit}
          onClick={(e) => {
            e.preventDefault();
            checkSubFormSubmit();
          }}>
          {ze('提交','Submit')}
        </a>
        <a
          className={style.cancel}
          onClick={(e) => {
            e.preventDefault();
            cancelFunc();
          }}>
          {ze('取消','Cancel')}
        </a>
      </div> */}
    </div>
  );
};

SubForm.propTypes = {
  checkSubmit: PropTypes.func.isRequired,
  cancelFunc: PropTypes.func.isRequired,
  upperStyle: PropTypes.object.isRequired,
  versionAlert: PropTypes.bool.isRequired,
  channelAlert: PropTypes.bool.isRequired,
  pid: PropTypes.number.isRequired,
};

export default SubForm;
