import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectFrontendWebVolatileConfigValueByKey } from 'utils/selectors/selectors';
import { FrontendWebVolatileConfigKeys } from 'utils/constants/frontend-web-volatile-config';
import { Alert, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ze } from 'utils/zhEn';
import { CS_STYLES } from 'utils/constants/style-constants';
import TopnotificationIcon from 'svg/v2/newcs_topnotification_icon.svg';
import DropdownCloseIcon from 'svg/v2/newcs_dashboard_dropdown_close.svg';

const GlobalNotificationBar = (props) => {
    const { reduxState, actions, onChangeVisible } = props;
    const { t } = useTranslation();
    const localStorageData = reduxState.global.get('localStorage').toJS();
    const isNonChromiumNoticeClosed = localStorageData.isNonChromiumNoticeClosed;
    const lastClosedGlobalNotificationInfo = localStorageData.lastClosedGlobalNotificationInfo || {};
    const retrieveMessage = lastClosedGlobalNotificationInfo.message || null;
    const retrieveExpireMillis = lastClosedGlobalNotificationInfo.expireMillis || null;

    const [currentMillis, setCurrentMillis] = useState(Date.now());

    const intervalId = setInterval(() => {
        setCurrentMillis(Date.now());
    }, 60000);

    const message = String(useSelector(state => selectFrontendWebVolatileConfigValueByKey(state, FrontendWebVolatileConfigKeys.GLOBAL_NOTIFICATION_MESSAGE)) || '').trim();
    const expireMillis = Number(useSelector(state => selectFrontendWebVolatileConfigValueByKey(state, FrontendWebVolatileConfigKeys.GLOBAL_NOTIFICATION_EXPIRE_MILLIS)) || 0);

    const isExpired = expireMillis > 0 && expireMillis < currentMillis;
    const isChrome =!!window.chrome;
    const isNonChromiumNoticeClosedTitle = ze('为了您更好的体验，请使用 Chrome 浏览器打开 CrashSight', 'For your better experience, Please open CrashSight in Chrome.');

    const isNotificationShowing = message && retrieveMessage!== message;

    let shouldShow;

    useEffect(() => {
      shouldShow = false;
    },[isExpired])

    if (isExpired && isNonChromiumNoticeClosed || (!isNotificationShowing && isNonChromiumNoticeClosed) || (isChrome &&!isNotificationShowing)) {
        shouldShow = false;
    } else {
        shouldShow = true;
    }

    // 如果是谷歌浏览器且应该显示通知，只显示消息，不显示非谷歌提示
    if (isChrome && shouldShow) {
        shouldShow = isNotificationShowing &&!isExpired;
    }

    onChangeVisible(shouldShow);

    const closeHandler = async () => {
        if (isNotificationShowing) {
            await actions.setPartialLocalStorage({ lastClosedGlobalNotificationInfo: { message, expireMillis: currentMillis } });
        } else {
            await actions.setPartialLocalStorage({ isNonChromiumNoticeClosed: true });
        }
    };

    return shouldShow? (
        <div
            style={{
                width: '100%',
                lineHeight: `${CS_STYLES.GLOBAL_NOTIFICATION}`,
                position: 'fixed',
                background: '#FEB98C',
                zIndex: 200,
                padding: '0 20px 0 67px',
                fontSize: '12px',
                color: '#393157',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <TopnotificationIcon style={{ width: '20px', height: '16px',color:'#FA6710' }} />
                <div>{shouldShow && isNotificationShowing &&!isExpired? message : isNonChromiumNoticeClosedTitle }</div>
            </div>
            <div
                style={{ display: 'flex', alignItems: 'center' }}
                onClick={() => closeHandler()}
            >
                <DropdownCloseIcon width="12" height="12" viewBox="0 0 7.996 7.996" style={{ color: '#fff' }} />
            </div>
        </div>
    ) : null;
};

export default GlobalNotificationBar;