import RestHelper from './RestHelper';
export default RestHelper.publish({
  search: {
    options: {// 获取高级搜搜的搜索参数
      method: 'get',
      uri: '/getSelectorDatas/appId/{appId}/pid/{pid}',
    },
    list: {// 高级搜索
      method: 'post',
      uri: '/api/advancedSearch',
    },
  },
});
