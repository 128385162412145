import React from 'react';
import { Input, message, Spin } from 'antd';
import style from './style.scss';
import { ze } from 'utils/zhEn'
import Patronize from 'svg/patronize.svg';
import PatronizeOpens from 'svg/patronizeOpens.svg';
import Tread from 'svg/tread.svg';
import TreadOpens from 'svg/treadOpens.svg';

export default class AiAnalysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aiAnalysisData:'',
      AiAnalyseResult:'',
      modernType:'',
    }
    this.textAreaRef = React.createRef();
  }

  childMethod = async() => {
    const { appId, issue } = this.props;
    let { crashDoc } = issue.toJS();
    const crashMap = crashDoc.crashMap || {};
    const params = {
      "appId": appId,
      "crashId": crashMap.crashId,
      "expThread":crashMap.threadName || '',
      "expName":crashMap.expName,
      "expAddr":crashMap.expAddr || '',
      "stack":crashMap.retraceCrashDetail,
      "stream": true,
    };
    const url = '/api/aiPilot/streamAnalyse';
    this.setState({aiAnalysisData:'',AiAnalyseResult:''});
      try {
        await RestHelper.postEventStream(url, params, {
          statusCodeToCallback: {
            500() {
              message.error(ze('用户后台正忙请稍后再试','The user background is busy, please try again later'));
            },
          },
          onmessage:(event) => {
            const data = event.data;
            if(data.includes('AiAnalyseResult')){
                const pattern = /\d+/;
                const match = pattern.exec(data);
                const AnalyseResult = parseInt(match[0],10);
                this.setState({AiAnalyseResult:AnalyseResult});
            } else {
                const dataCopy = data.replace(/\\n/g,'\n').replace(/"/g,'');
                setTimeout(() => {
                  this.setState(prevState => ({
                    aiAnalysisData:prevState.aiAnalysisData + dataCopy
                  }))
                },1000)
            }
          },
        });
      } catch (e) {
        message.error(e);
      }
  };

  likesAmount = async(value) => {
    const { appId, issue } = this.props;
    if(this.state.modernType == value){
      this.setState({modernType:''});
    } else {
      this.setState({modernType:value});
    }
    await RestHelper.post('/redir/api/aiResult/editAiResult', {
      id: this.state.AiAnalyseResult,
      appId,
      evaluation: this.state.modernType == value ? null : value
    });
  }

  render() {
    const { TextArea } = Input;

    return (
      <div>
          <Spin spinning={!this.state.aiAnalysisData}>
            <TextArea
              ref={this.textAreaRef}
              style={{margin:'10px 0'}}
              value={this.state.aiAnalysisData}
              placeholder={ze('AI分析','AIAnalyze')}
              autoSize={{
                  minRows: 8,
                  maxRows: 30,
              }}
              />
              <div className={style.likes}>
                <div style={{flex:1}}></div>
                {this.state.AiAnalyseResult && <div style={{display:'flex',alignItems:'center',fontSize:'14px'}}>
                  <div>
                    {ze('AI分析是否有帮助：','AIAnalyze if it helps:')}
                  </div>
                  <div style={{display:'flex'}} onClick={() => this.likesAmount('Helpful')}>
                    <div style={{cursor:'pointer'}}>{this.state.modernType == 'Helpful' ? <PatronizeOpens width='16' height='16' viewBox="0 0 32 32"/> : <Patronize width='16' height='16' viewBox="0 0 32 32" />}</div>
                    <span style={{margin:'0 10px'}}>{ze('是','yes')}</span>
                  </div>
                  <div style={{display:'flex'}} onClick={() => this.likesAmount('UnHelpful')}>
                    <div style={{cursor:'pointer'}}>{this.state.modernType == 'UnHelpful' ? <TreadOpens width='16' height='16' viewBox="0 0 32 32" /> : <Tread width='16' height='16' viewBox="0 0 32 32" />}</div>
                    <span  style={{margin:'0 10px'}}>{ze('否','not')}</span>
                  </div>
                </div>}
              </div>
          </Spin>
      </div>
    );
  }
}
