import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import scss from './ClampDiv.scss';
import { Button } from 'antd';
import { ze } from 'utils/zhEn';
import { GlobalResizeObserver } from 'utils/global-resize-observer';
import { isNullish } from 'utils/nullish';

const DEFAULT_LINE_HEIGHT = 1.5;
const ClampDiv = ({
  lineHeight,
  lineClamp,
  showButton,
  style,
  children,
}) => {
  const [isClamped, setIsClamped] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isShowExpand, setIsShowExpand] = useState(false);
  const [innerLineHeight, setInnerLineHeight] = useState(DEFAULT_LINE_HEIGHT);
  const floatRef = useRef(null);
  useEffect(() => {
    if (isNullish(lineHeight)) {
      setInnerLineHeight(DEFAULT_LINE_HEIGHT);
      return;
    }
    const lineHeightNumber = Number(lineHeight);
    if (!Number.isFinite(lineHeightNumber) || lineHeightNumber <= 0) {
      setInnerLineHeight(DEFAULT_LINE_HEIGHT);
      return;
    }
    setInnerLineHeight(lineHeightNumber);
  }, [lineHeight]);

  const refCallback = useCallback((node) => {
    if (!node) {
      GlobalResizeObserver.unobserve(node);
      return;
    }

    setIsClamped(calcIsClamped(node));
    setIsShowExpand(calcIsShowExpand(node));
    GlobalResizeObserver.observe(node, (entry) => {
      setIsClamped(calcIsClamped(entry.target));
      setIsShowExpand(calcIsShowExpand(entry.target));
    });
  }, []);

  function calcIsClamped(dom) {
    if (!dom) {
      return false;
    }
    return dom.scrollHeight > dom.clientHeight;
  }

  function calcIsShowExpand(dom) {
    if (!dom) {
      return false;
    }
    return dom.scrollHeight > dom.clientHeight;
    // return floatRef.current.clientHeight > dom.clientHeight;
  }

  const maxHeight = isExpanded ? undefined : `${innerLineHeight * lineClamp}em`;

  return <div className={scss.wrapper} style={style}>
    <div
      ref={refCallback}
      className={scss.content}
      style={{
        overflow: 'hidden',
        lineHeight: innerLineHeight,
        maxHeight,
      }}
    >
      <div className={scss.floatDiv}>
        <span style={{ visibility: (isClamped && !isExpanded) ? 'visible' : 'hidden' }}>...</span>
        <Button
          size='small'
          className={scss.btn}
          type='link'
          style={{ visibility: showButton && (isClamped || isExpanded) ? 'visible' : 'hidden' }}
          onClick={() => setIsExpanded(!isExpanded)}
        >{ isExpanded ? ze('收起', 'Collapse') : ze('展开', 'Expand') }</Button>
      </div>
      <span>{ children }</span>
    </div>
  </div>;
};

ClampDiv.propTypes = {
  lineClamp: PropTypes.number,
};

export default ClampDiv;
