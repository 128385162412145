import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Select, Button, Input, InputNumber, Modal, Form, Spin, Checkbox, Row, Col, Space, Tag, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { unactivePopAlert } from 'reducers/global/globalActions';
import { changeIssueState } from 'reducers/issue/issueActions';
import { connect } from 'react-redux';
import { green, orange } from '@ant-design/colors';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { isNotNullish } from 'utils/nullish';
import { ze } from 'utils/zhEn';
import RestHelper from 'utils/RestHelper';
import _style from './IssueEditStatusModal.scss';
import { CsSelect } from 'components/antd-extension/CsSelect';
import IssueStatusSelect from 'components/commons/IssueCrashFilter/IssueStatusSelect';

const IssueEditStatusModal = ({ reduxState, actions }) => {
  const { t } = useTranslation();

  const { popAlertData, popAlertStatus } = reduxState.global.toJS();
  const { userId: wetestUin } = reduxState.user.get('current').toJS();
  const {
    members,
    issueId,
    assigneeList,
  } = popAlertData || {};
  const currentApp = reduxState.app.get('current').toJS();
  const currentUser = reduxState.user.get('current').toJS();
  const selectOptions = reduxState.summary.get('selectOptions');
  const { processor } = selectOptions.toJS();
  const { appId } = currentApp;
  const { newUserId, isSuper, rtx } = currentUser;

  const [formInstance] = Form.useForm();
  const [formValue, setFormValue] = useState(makeDefaultFormValue());

  const [loading, setLoading] = useState(false);

  function makeDefaultFormValue() {
    return {
      processorList: [],
      status: null,
      note: '',
    }
  }

  function makeMemberOptions(members, assigneeList) {
    if (!members) {
      return [];
    }

    const memberWetestUinSet = new Set(members.map(x => x.value));
    const memberOptions = (members || [])
      .filter(x => x.value !== 'all')
      .map(x => ({ ...x, name: x.label }));

    const optionsNotInMembers = (assigneeList || [])
      .filter(x => !memberWetestUinSet.has(x.wetestUin))
      .map((x => {
        // 如果memberOptions是空的，有可能是网络问题没获取到成员，这时候就不提示已退出了，防止把所有处理人都标记成已退出
        const label = memberOptions.length > 0
          ? <Space>
              <div>{ x.name }</div>
              <Tooltip
                title={ze('此用户目前已经不在项目中', 'This user has left the app.')}
              ><Tag color='red'>{ ze('已退出', 'Left The App') }</Tag></Tooltip>
            </Space>
          : x.name;
        return {
          label,
          name: x.name,
          value: x.wetestUin,
        };
      }));

    return [
      ...memberOptions,
      ...optionsNotInMembers,
    ];
  }

  const memberOptions = makeMemberOptions(members, assigneeList);

  const isCurrentUserInMemberOptions = memberOptions.some(x => x.value === wetestUin);

  const processorListValue = Form.useWatch('processorList', formInstance)


  const getIssueAdditionalInfo = async() => {
    const rsp = await RestHelper.post('/redir/api/issue/getIssueAdditionalInfo',{
        appId,
        issueId,
      })
      return rsp.json.data;
  }

  useDeepCompareEffect(() => {
    if (!popAlertStatus) {
      return;
    }

    const { assigneeList, status } = popAlertData || {};

    let processorList = (assigneeList || []).map(x => x.wetestUin);
    if (processorList.length === 0 && isCurrentUserInMemberOptions) {
      processorList = [wetestUin];
    }

    getIssueAdditionalInfo().then(data => {
      const newFormValue = {
        processorList,
        status: isNotNullish(status) && !Number.isNaN(status) ? status : null,
        note: '',
        checkboxGroup:[
          ...!!(data || {}).notifySubscriber ? ['notifySubscriber'] : [],
          ...!!(data || {}).notifyProcessor ? ['notifyProcessor'] : [],
        ]
      };

      setFormValue(newFormValue);
      formInstance.setFieldsValue({...newFormValue});
    });

  }, [popAlertStatus, popAlertData]);

  const processorReorganization = ((processor || {}).options || []).filter(item => (processorListValue || []).includes(item.value));
  const filteredMembers = processorReorganization
                          .filter(item => !item.rtx)
                          .map(item => item.label);
  const processorState = filteredMembers && processorListValue && filteredMembers.length === processorListValue.length;

  return <Modal
    className={_style.statusModal}
    title={ze('变更问题处理状态','Change Issue Status')}
    width='620px'
    visible={popAlertStatus}
    onCancel={() => actions.unactivePopAlert()}
    onOk={() => formInstance.submit()}
    okButtonProps={{
      disabled: loading,
    }}
    maskClosable={false}
  >
    <Spin spinning={loading}>
      <Form
        form={formInstance}
        onValuesChange={(changedValues) => {
          setFormValue({
            ...formValue,
            ...changedValues,
          });
        }}
        labelCol={{ style: { width: '140px' } }}
        onFinish={async () => {
          setLoading(true);
          const checkboxGroup = formValue.checkboxGroup || [];
          const assigneeWithoutLocalUserIdList = memberOptions
            .filter(x => formValue.processorList.includes(x.value))
            .map(x => ({ wetestUin: x.value, name: x.name }));
          const processorListString = formValue.processorList.join(';');
          await actions.changeIssueState(issueId, formValue.status, processorListString, formValue.note, checkboxGroup, assigneeWithoutLocalUserIdList);
          setLoading(false);
          actions.unactivePopAlert();
        }}
      >
        <Form.Item label={ze('问题处理人','Assignees')} name='processorList' rules={[{ required: true }, { type: 'array', max: 5 }]}>
          <CsSelect
            style={{width: '100%'}}
            allowClear
            mode='multiple'
            options={memberOptions}
            showSearch={true}
            showArrow={true}
            placeholder={ze('问题处理人','Choose Assigness')}
            filterOption={ (input, option) => (option.label || '').toLowerCase().includes(input.toLowerCase()) }
          />
        </Form.Item>
        <Form.Item label={ze('问题状态','Issue Status')} name='status' rules={[{ required: true }]}>
          <IssueStatusSelect
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item label={ze('操作备注','Operation Remark')} name='note'>
          <Input placeholder={ze('可选，提交的备注将显示在问题详情备注区','Optional, submitted notes will be displayed in the issue details notes area')} />
        </Form.Item>
        {!!(isSuper || rtx) && <Form.Item label={ze('状态变更通知','Notification Received')} name="checkboxGroup">
          <Checkbox.Group style={{width:'100%'}}>
            <Row gutter={12}>
              <Col>
                <Checkbox
                  value="notifySubscriber"
                  disabled = {!rtx}
                >{ze('问题分配人(本人企微)','Assignor(WeCom)')}</Checkbox>
              </Col>
              <Col>
                <Checkbox value="notifyProcessor"
                  disabled = {processorState}
                >{ze('问题处理人(处理人企微)','Assignees(WeCom)')}</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>}
        {filteredMembers.length > 0 &&  <div style={{marginLeft:'150px',color:'#FA8C16'}}>
          {ze('您选择的处理人有以下几位不是企微用户，将不会收到通知:','The following assignees are not WeCom users and will not receive notifications:')}
          {filteredMembers.join(',  ')}
        </div>}
      </Form>
    </Spin>
  </Modal>
};

IssueEditStatusModal.propTypes = {
};

const mapStateToProps = state => ({
  reduxState: state,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    unactivePopAlert,
    changeIssueState,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(IssueEditStatusModal);
