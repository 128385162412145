import React, { useState, useEffect } from 'react';
import { CsNewTabs } from 'components/antd-extension/CsNewTabs';
import ReactEcharts from 'echarts-for-react';
import { isZh, ze } from 'utils/zhEn';
import moment from 'moment';
import scss from './NoticeDrawer.scss';

const NoticeDrawerEcharts = (prop) => {
  const { data } = prop;
  if (!data ||!data.metricsList) {
    return null;
  }

  const [names, setNames] = useState([]);
  const [timestamps, setTimestamps] = useState([]);
  const [values, setValues] = useState([]);
  const [selectedName, setSelectedName] = useState(null);
  const exceptionMetricsData = {
    crashDeviceRatePercentage: ze("设备崩溃率(%)", "Device Crash Rate (%)"),
    crashDevice: ze("崩溃影响设备数", "Crash Affected Devices"),
    accessDevice: ze("联网设备数", "Active Devices"),
    errorDeviceAllData: ze("错误影响设备数", "Error Affected Devices"),
    errorNumAllData: ze("错误发生次数", "Error Count"),
  }
  useEffect(() => {
    if (data.metricsList.length > 0) {
      const newNames = data.metricsList.map(item => ({ label: exceptionMetricsData[item.name], value: item.name }));
      setNames(newNames);
      if (newNames.length > 0) {
        setSelectedName(newNames[0].value);
      }
    }
  }, []);

  useEffect(() => {
    if (selectedName) {
      const selectedMetrics = data.metricsList.find(item => item.name === selectedName);
      if (selectedMetrics) {
        // YYYY-MM-DD
        const ts = selectedMetrics.timeSeries.map(item => moment((item.timestamp)*1000).format('HH:mm'));
        const vs = selectedMetrics.timeSeries.map(item => item.value);
        setTimestamps(ts);
        setValues(vs);
      }
    }
  }, [selectedName]);

  const timestampsLastValue = timestamps.length > 0 ? timestamps[timestamps.length - 1] : 0;
  const valuesLastValue = values.length > 0 ? values[values.length - 1] : 0;

  const option = {
    tooltip: {
      trigger: 'axis',
      formatter: (params) => {
          if (params.length > 0 && selectedName) {
              const selectedMetricLabel = exceptionMetricsData[selectedName];
              let tooltipText = `<div style="font-weight:bold;">${selectedMetricLabel}</div>`;
              let timeText = '';
              let valueText = '';
              params.forEach((item) => {
                  timeText += `${item.name}<br>`;
                  valueText += `${item.value}<br>`;
              });
              tooltipText += `<div>${ze('时间：','times:')}${timeText}</div><div>${ze('数值：','value:')}${valueText}</div>`;
              return tooltipText;
          }
          return '';
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
        type: 'category',
        data: timestamps,
        boundaryGap: false,
    },
    yAxis: {
        type: 'value',
    },
    series: [
        {
            data: values,
            type: 'line',
            symbol: 'circle',
            showSymbol: false,
            markLine: {
              data: [[
                {coord: [timestampsLastValue, 0] },
                {coord: [timestampsLastValue, valuesLastValue] },
                {
                  lineStyle:{
                    color:'red',
                    type:'solid'
                  }
                }
              ]]
            }
        },
    ],
};

  const tabsChange = (key) => {
    setSelectedName(key);
  };

  return (
    <div className={scss.noticeEcharts}>
      <div className={scss.tab}>
        <CsNewTabs options={names} value={selectedName} onChange={tabsChange}/>
      </div>
      <ReactEcharts option={option} style={{ marginTop: '10px' }}/>
    </div>
  );
};

export default NoticeDrawerEcharts;