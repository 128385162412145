import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import UserInfo from 'components/user/UserInfo';
import AccountBind from 'components/user/AccountBind';
import * as userActions from 'reducers/user';
import * as globalActions from 'reducers/global/globalActions';
import { isEmptyObject, generateSearch, parseSearch } from 'utils/helper';
import { push } from 'connected-react-router';
import style from './style.scss';
import { withTranslation} from 'react-i18next';
import { ze } from 'utils/zhEn';
import { Button, Input } from 'antd';
import CopyRight from 'components/commons/CopyRight';
import UserOpenapiKeyInfo from 'components/user/UserOpenapiKeyInfo/UserOpenapiKeyInfo';
import PlarformMembersIcon from 'svg/v2/newcs_myproject_platform_members_icon.svg';
import MyWallet from 'components/user/MyWallet/MyWallet';
import { EnvUtil } from 'utils/env-util';

/**
 * 个人信息页面
 */

@withTranslation()
class UserPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabItem: props.tag ? props.tag : 'userInfo', // 默认先加载userInfo
    };
  }

  componentDidMount() {
    const { t } = this.props;
    document.title = `${t('HEAD.info')}-CrashSight`;
  }

  // 点击加载那个tab
  handleClickTab(tabName) {
    const { location, actions } = this.props;
    this.setState({
      tabItem: tabName,
    });
    actions.push({
      pathname: location.pathname,
      search: generateSearch(location.search, {
        tag: tabName,
      }),
    });
  }

  renderUserOpenapiKeyTabContent() {
    const { currentUser } = this.props;
    const { userOpenapiKey } = currentUser;
    return <div>
      <Input.Group compact>
        <Input
          value={userOpenapiKey}
          readOnly
        />
        <Button>{ ze('重置', 'Reset') }</Button>
      </Input.Group>
    </div>;
  }

  // 渲染tab
  renderTab(currentUser, userParams, actions, isUpdateFlag) {
    let tabName = this.state.tabItem;
    const queryTab = this.props.tag;
    if (queryTab) {
      tabName = queryTab;
    }
    if (tabName === 'userInfo') { // 用户信息
      return !isEmptyObject(currentUser) && <UserInfo currentUser={currentUser} actions={actions} userParams={userParams} isUpdateFlag={isUpdateFlag} />;
    } else if (tabName === 'myWallet') {
      return !isEmptyObject(currentUser) && <MyWallet currentUser={currentUser} />;
    } else if (tabName === 'account') { // 帐号绑定
      return !isEmptyObject(currentUser) && <AccountBind currentUser={currentUser} actions={actions} />;
    } else if (tabName === 'userOpenapiKey') {
      return !isEmptyObject(currentUser) && <UserOpenapiKeyInfo currentUser={currentUser} actions={actions} />;
    } else {
      return null;
    }
  }

  render() {
    const {
      currentUser, userParams, actions, isUpdateFlag,
    } = this.props;

    const titleList = [
      {
        title: this.props.t('ACCOUNT.information'),
        key: 'userInfo',
      }, EnvUtil.isSupportUserAddFunds() && {
        title: this.props.t('ACCOUNT.myWallet'),
        key: 'myWallet',
      },{
        title: this.props.t('ACCOUNT.bind'),
        key: 'account',
      },{
        title: ze('OpenAPI密钥管理', 'OpenAPI Key'),
        key: 'userOpenapiKey',
      },
    ].filter(x => x);
    return (
      <div className="exception_page">
        <div className={style.main_content}>
          <div className={style.user_center_box}>
            <div className={style.tab_nav}>
              <div className={style.tab_title}>
                <PlarformMembersIcon width='14' height='14' viewBox='0 0 12 12'/>
                <div>{this.props.t('HEAD.info')}</div>
              </div>
              {titleList.map((item,index) => (
                <div className={this.state.tabItem === item.key ? (`${style.tab_item} ${style.active}`) : (style.tab_item)} onClick={(value) => { this.handleClickTab(item.key, value); }}>
                <div className={style.text}>
                  <div>{item.title}</div>
                </div>

              </div>
              ))}
            </div>
            <div className={style.tab_container}>
              {this.renderTab(currentUser, userParams, actions, isUpdateFlag)}
            </div>
          </div>
        </div>
        <CopyRight />
      </div>
    );
  }
}

UserPage.propTypes = {
  currentUser: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  userParams: PropTypes.object.isRequired,
  isUpdateFlag: PropTypes.bool.isRequired,
};

// 设置props
export default connect((state, router) => {
  const query = parseSearch(router.location);
  return {
    currentUser: state.user.get('current').toJS(),
    userParams: state.user.get('userParams'),
    isUpdateFlag: state.user.get('isUpdateFlag'),
    location: router.location,
    tag: query.tag,
  };
}, (dispatch) => ({
  actions: bindActionCreators({
    ...userActions,
    ...globalActions,
    push,
  }, dispatch),
  dispatch,
}))(UserPage);
