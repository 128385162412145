import { fromJS } from 'immutable';
import { createAction } from 'redux-actions';
import SdkAppRest from 'utils/SdkAppRest';
import { getStartEndDateFromStr, changeDateFormat } from 'utils/StringUtils';
import { transdate } from 'utils/helper';
import { hasDataNeedLoad } from 'utils/constants';

export const updateSearchParams = createAction('UPDATE_SEARCH_PARAMS');// 更新搜索参数

export const updatePageNum = createAction('UPDATE_PAEG_NUM');// 更新页数
/*
 *获取昨日概览数据
 */
export function getSummaryInfo() {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    return dispatch({
      rest: SdkAppRest.sdkSummary.summaryInfo,
      data: {
        appId,
        pid,
      },
    });
  };
}

/*
 *运营概览获取列表的接口
 */
export function getSummaryListInfo(version, type, paramType) {
  return (dispatch, getState) => {
    const dataList = fromJS(getState().sdkSummary.get('dataList'));
    // 如果没有数据需要加载，直接返回
    if (paramType === 'version' && !hasDataNeedLoad(dataList, version)) {
      return Promise.resolve();
    }
    const { appId, pid } = getState().app.get('current').toJS();
    const { date, chartDate } = getState().sdkSummary.get('searchParams').toJS();
    const tempDate = (type === 'chart' ? chartDate : date);// 需要区分是图表还是列表的时间
    const dateObj = getStartEndDateFromStr(tempDate.date, tempDate.startDate, tempDate.endDate);
    console.log('dateObj ===', dateObj);
    const startTime = changeDateFormat(dateObj.startDate, 'YYYY-MM-DD');
    const endTime = changeDateFormat(dateObj.endDate, 'YYYY-MM-DD');
    const startTimeStmp = transdate(`${startTime} 00:00:00`);
    const endTimeStmp = transdate(`${endTime} 00:00:00`);
    return dispatch({
      rest: SdkAppRest.sdkSummary.sdkList,
      data: {
        type,
        appId,
        pid,
        versions: encodeURIComponent(version),
        startDate: startTimeStmp,
        endDate: (type === 'chart' ? endTimeStmp : startTimeStmp),
      },
    });
  };
}

/**
 * 获取sdk版本的详情
 */
export function getSDKDetailInfo(version, type) {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    const { date } = getState().sdkSummary.get('searchParams').toJS();
    const dateObj = getStartEndDateFromStr(date.date, date.startDate, date.endDate);
    const searchTime = changeDateFormat(dateObj.startDate, 'YYYY-MM-DD');
    const timeStmp = transdate(`${searchTime} 00:00:00`);
    return dispatch({
      rest: SdkAppRest.sdkSummary.detailInfo,
      data: {
        appId,
        pid,
        date: timeStmp,
        version: encodeURIComponent(version),
        type,
      },
    });
  };
}

/**
 * 获取sdk版本
 */
export function getSDKVersions() {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    const yesterday = getState().sdkSummary.get('yesterday');
    const timeStmp = transdate(`${yesterday} 00:00:00`);
    dispatch({
      rest: SdkAppRest.sdkSummary.sdkVersions,
      data: {
        appId,
        pid,
        date: timeStmp,
      },
    });
  };
}

/**
 * 获取Top5SDK的数据
 */
export function getTopSDKVersions() {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    const limit = getState().sdkSummary.get('limit');
    const yesterday = getState().sdkSummary.get('yesterday');
    const timeStmp = transdate(`${yesterday} 00:00:00`);// 以这个时间去获取TopN的版本
    const { chartDate } = getState().sdkSummary.get('searchParams').toJS();
    const dateObj = getStartEndDateFromStr(chartDate.date, chartDate.startDate, chartDate.endDate);
    const startTime = changeDateFormat(dateObj.startDate, 'YYYY-MM-DD');
    const endTime = changeDateFormat(dateObj.endDate, 'YYYY-MM-DD');
    const startTimeStmp = transdate(`${startTime} 00:00:00`);
    const endTimeStmp = transdate(`${endTime} 00:00:00`);
    dispatch({
      rest: SdkAppRest.sdkSummary.topVersions,
      data: {
        appId,
        pid,
        limit,
        yesterday: timeStmp,
        startDate: startTimeStmp,
        endDate: endTimeStmp,
      },
    });
  };
}
