import React, { useEffect, useState } from 'react';
import { Input, Modal, Select } from 'antd';
import { FullscreenExitOutlined } from '@ant-design/icons';
import isEqual from 'lodash/lang/isEqual';
import { ConvertUtil } from 'utils/convert-util';

const EnlargeableInputTerms = ({
  value,
  onChange,
  description,
  style,
  ...props
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [innerTerms, setInnerTerms] = useState([]);
  const [innerText, setInnerText] = useState('');
  const [modalEditingText, setModalEditingText] = useState('');

  useEffect(() => {
    const v = value || [];
    if (!isEqual(innerTerms, v)) {
      setInnerTerms(v);
      setInnerText(v.join(', '));
    }
  }, [value]);

  function onClickEnlarge() {
    setModalVisible(true);
    setModalEditingText(innerText);
  }

  function onInnerTextValueChange(v) {
    v = v || '';
    setInnerText(v);
    const terms = ConvertUtil.splitAndTrimCommaSeparatedValues(v);
    setInnerTerms(terms);
    onChange(terms);
  }

  return <Input
    {...props}
    style={style}
    value={innerText}
    onChange={e => onInnerTextValueChange(e.target.value)}
    addonAfter={<div>
      <FullscreenExitOutlined
        onClick={onClickEnlarge}
      />
      <Modal
        title='编辑'
        visible={modalVisible}
        onCancel={() => { setModalVisible(false) }}
        onOk={() => {
          setModalVisible(false);
          onInnerTextValueChange(modalEditingText);
        }}
      >
        { description && <div>{ description }</div> }
        <Input.TextArea
          style={{ height: 'max(100px, 60vh)' }}
          value={modalEditingText}
          onChange={e => setModalEditingText(e.target.value)}
        />
      </Modal>
    </div>}
  />;
};

export default EnlargeableInputTerms;
