import React, { useEffect } from 'react';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { actionFetchFeatureTagOptions } from 'reducers/seniorSearch';
import { selectFeatureTagOptionsInfo } from 'utils/selectors/selectors';

const FeatureTagSelect = ({
  appId,
  isReadOnly,
  value,
  onChange,
}) => {
  const { hasFetched, options } = useSelector(selectFeatureTagOptionsInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!appId) {
      return;
    }
    if (!hasFetched) {
      dispatch(actionFetchFeatureTagOptions());
    }
  }, [appId]);

  return <Select
    style={{ width: '100%', fontSize: '14px' }}
    mode='multiple'
    options={options}
    value={value}
    onChange={(v) => {
      if (isReadOnly) {
        return;
      }
      onChange(v);
    }}
  />
}

export default FeatureTagSelect;
