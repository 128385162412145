import RestHelper from './RestHelper';
import { fetchSdkIntegratedApps } from 'reducers/summary/summaryActions';

const summaryRest = RestHelper.publish({
  trend: {
    getTrend: {
      method: 'post',
      uri: '/api/app/getTrend',
    },
  },
  system: {
    rate: {
      method: 'post',
      uri: '/redir/api/statResource/fetchFieldTopStats',
    },
    access: {
      method: 'post',
      uri: '/redir/api/statResource/fetchFieldTopStats',
    },
  },
  model: {
    rate: {
      method: 'post',
      uri: '/redir/api/statResource/fetchFieldTopStats',
    },
    access: {
      method: 'post',
      uri: '/redir/api/statResource/fetchFieldTopStats',
    },
  },
  app: {
    rate: {
      method: 'post',
      uri: '/redir/api/statResource/fetchFieldTopStats',
    },
    access: {
      method: 'post',
      uri: '/redir/api/statResource/fetchFieldTopStats',
    },
  },
  tag: {
    rate: {
      method: 'post',
      uri: '/redir/api/statResource/fetchFieldTopStats',
    },
    access: {
      method: 'post',
      uri: '/redir/api/statResource/fetchFieldTopStats',
    }
  },
  gpu: {
    rate: {
      method: 'post',
      uri: '/redir/api/statResource/fetchFieldTopStats',
    },
    access: {
      method: 'post',
      uri: '/redir/api/statResource/fetchFieldTopStats',
    }
  },
  cpu: {
    rate: {
      method: 'post',
      uri: '/redir/api/statResource/fetchFieldTopStats',
    },
    access: {
      method: 'post',
      uri: '/redir/api/statResource/fetchFieldTopStats',
    }
  },

  realTime: {
    getHourlyStat: {
      method: 'post',
      uri: '/api/app/getRealTimeHourlyStat',
    },
    getAppendMinutelyStat: {
      method: 'post',
      uri: '/api/app/getAppRealTimeTrendAppend',
    },
  },

  realTimeTopIssue: {
    fetch: {
      method: 'post',
      uri: '/redir/api/statResource/getTopIssueHourly',
    },
  },
  realTimeAppend: {
    fetch: {
      method: 'post',
      uri: '/api/app/fetchDailySummary',
    },
  },

  countryGroupConfig: {
    fetch: {
      method: 'post',
      uri: '/api/app/listCountryGroup',
    }
  },

  fetchSdkIntegratedApps: {
    fetch: {
      method: 'post',
      uri: '/redir/api/statResource/fetchSdkIntegratedApps',
    }
  },

  fetchSdkIntegratedAppVersions: {
    fetch: {
      method: 'post',
      uri: '/redir/api/statResource/fetchSdkIntegratedAppVersions',
    }
  },
});

export default summaryRest;
