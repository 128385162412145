import React from 'react';
import { CS_STYLES } from 'utils/constants/style-constants';

const BehaviorBarRanking = (props) => {
  const { data: originData, onClickBar, maxItems } = props;

  function calculatedWidth(maxValue,value){
    const result = (value/maxValue)*100;
    return (result + '%');
  }

  const data = (maxItems ? [...originData.slice(0, maxItems)] : [...originData])
    .sort((a, b) => b.value - a.value);

  // 率的最大值限制最小为3
  const maxValue = Math.max(3, Math.max(...data.map(item => item.value)));

  return <ul style={{width:'100%', height:'100%', fontSize:'14px'}}>
    {data.map((item, index) => (<li style={{paddingTop:'10px'}}>
      {(item.name && item.value > 0) && <div
        style={{ cursor: 'pointer' }}
        onClick={() => {
          if (typeof onClickBar === 'function') {
            onClickBar(item.name);
          }
        }}
      >
        <div>{item.name}</div>
        <div style={{display:'flex', alignItems:'center', width:'93%',height:'15px'}}>
          <div style={{display:'absolute', width:'100%',height:'100%',background:'#ededed',borderRadius:'20px',}}>
            <div style={{
              display: 'relative',
              width: calculatedWidth(maxValue,item.value),
              height: '100%',
              borderRadius: '20px',
              background: 'linear-gradient(90deg, #7C73F3, #A194FF)',
            }}/>
          </div>
          <div style={{width:'7%', paddingLeft:'10px'}}>{item.value}</div>
        </div>
      </div>}
    </li>) )}
  </ul>
}

export default BehaviorBarRanking;
