export function formatBytes(bytes, decimals = 2) {
  if (bytes <= 0) {
    return `${bytes} B`;
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['B', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function formatBytesAdvanced(bytes, decimals = 2) {
  if (bytes <= 0) {
    bytes = 0;
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const prefixes = ['B', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

  const binaryPrefixTier = bytes > 0
    ? Math.min(Math.floor(Math.log(bytes) / Math.log(k)), prefixes.length - 1)
    : 0;
  const binaryPrefix = prefixes[binaryPrefixTier];
  const numberPart = bytes > 0
    ? bytes / Math.pow(k, binaryPrefixTier)
    : 0;

  return {
    numberPart,
    binaryPrefixTier,
    binaryPrefix,
    formatted: `${parseFloat((numberPart).toFixed(dm))} ${binaryPrefix}`,
  };
}
