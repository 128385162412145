export const FrontendWebVolatileConfigKeys = Object.freeze({
  ISSUE_REGROUP_APP_NAME_WHITELIST: 'issueRegroupAppNameWhitelist',
  OOM_APP_NAME_WHITELIST: 'oomAppNameWhitelist',
  CUSTOM_FEATURE_APP_ID_WHITELIST: 'customFeatureAppIdWhitelist',
  GLOBAL_NOTIFICATION_MESSAGE: 'globalNotificationMessage',
  GLOBAL_NOTIFICATION_EXPIRE_MILLIS: 'globalNotificationExpireMillis',
  ADVANCED_SEARCH_EXTEND_DOWNLOAD_MAX_ISSUES_APP_ID_WHITELIST: 'ADVANCED_SEARCH_EXTEND_DOWNLOAD_MAX_ISSUES_APP_ID_WHITELIST',
  HAS_INTEGRATED_APPS_APP_ID_WHITELIST: 'HAS_INTEGRATED_APPS_APP_ID_WHITELIST',
  ALLOW_NON_OOM_OPTION_APP_ID_WHITELIST: 'ALLOW_NON_OOM_OPTION_APP_ID_WHITELIST',
  ENABLE_IGAME_SEARCH_FIELDS_APP_ID_WHITELIST: 'ENABLE_IGAME_SEARCH_FIELDS_APP_ID_WHITELIST',
  ENABLE_SEARCH_KEY_STACK_SO_NAME: 'ENABLE_SEARCH_KEY_STACK_SO_NAME',
  ENABLE_WATERMARK: 'ENABLE_WATERMARK',
});
