export const GlobalResizeObserver = (function() {
  const ATTR_NAME = 'data-cs-resize-obs';

  const attrValueToCallback = {};

  const o = new ResizeObserver((entries) => {
    for (const entry of entries) {
      const resizedElement = entry.target;
      const attrValue = resizedElement.getAttribute(ATTR_NAME);
      if (attrValue) {
        const callback = attrValueToCallback[attrValue];
        if (typeof callback === 'function') {
          callback(entry);
        }
      }
    }
  });

  return Object.freeze({
    /**
     * @param { Element } element
     * @param { (ResizeObserverEntry) => {} } callback
     */
    observe(element, callback) {
      if (!(element instanceof Element)) {
        console.error('GlobalResizeObserver, cannot observe non-Element.');
        return;
      }

      let attrValue = element.getAttribute(ATTR_NAME);
      if (!attrValue) {
        attrValue = String(Math.random());
        element.setAttribute(ATTR_NAME, attrValue);
      }

      attrValueToCallback[attrValue] = callback;
      o.observe(element);
    },

    /**
     * @param { Element } element
     */
    unobserve(element) {
      if (!(element instanceof Element)) {
        console.error('GlobalResizeObserver, cannot unobserve non-Element.');
        return;
      }
      const attrValue = element.getAttribute(ATTR_NAME);
      if (!attrValue) {
        console.error('GlobalResizeObserver, cannot unobserve element w/o ATTR_NAME.');
        return;
      }

      delete attrValueToCallback[attrValue];
      o.unobserve(element);
    }
  });
})();
