import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import _style from './style.scss';
import { Select } from 'antd';


const PAGE_DATA = [
  { label: '10', value: '10' },
  { label: '20', value: '20' },
  { label: '50', value: '50' },
];

const Pager = ({
  pageNum, rows, handlePageClick, handleChangeRows, initialSelected, style, showLog, forceSelected, isShowPageSelect,noPage,
  preText, nextText, showText
}) => {
  if (pageNum <= 0) {
    return (<div></div>);
  }

  showLog && console.log(`pager:: pageNum=${pageNum}, rows=${rows}`);
  return (
    <div className={`${_style.pager} ${(style && style.pager) || ''}`}>
      {
        isShowPageSelect && (
          <div className={_style.pagenum_select}>
            <label htmlFor="num">{ showText }</label>
            <Select
              size='small'
              options={PAGE_DATA}
              value={rows + ''}
              onChange={v => handleChangeRows(v)}
            />
          </div>
        )
      }
      <ReactPaginate
        previousLabel={ preText }
        nextLabel={ nextText }
        breakLabel={<a onClick={(e) => { e.preventDefault(); }}>...</a>}
        pageCount={pageNum}
        marginPagesDisplayed={noPage?-1:0}
        pageRangeDisplayed={noPage?-1:5}
        onPageChange={handlePageClick}
        containerClassName="pagination"
        subContainerClassName="pages pagination"
        initialSelected={initialSelected}
        activeClassName="active"
        forceSelected={forceSelected}
        rows={rows} />
    </div>
  );
};

Pager.propTypes = {
  pageNum: PropTypes.number.isRequired,
  rows: PropTypes.number.isRequired,
  handlePageClick: PropTypes.func.isRequired,
  handleChangeRows: PropTypes.func.isRequired,
  style: PropTypes.object,
  initialSelected: PropTypes.number,
  showLog: PropTypes.bool,
  forceSelected: PropTypes.number,
  isShowPageSelect: PropTypes.bool,
  noPage:PropTypes.bool,
  preText: PropTypes.string,
  nextText: PropTypes.string,
  showText: PropTypes.string
};

Pager.defaultProps = {
  preText: "上一页",
  nextText: "下一页",
  showText: "每页显示"
}

export default Pager;
