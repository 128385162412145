import React from 'react';
import PropTypes from 'prop-types';
import style from './CrashSightSubheader.scss';

const CrashSightSubheader = ({
  title,
  children,
}) => {
  return <div className={style.crashSightSubheader}>
    <div className={style.title}>{ title }</div>
    <div>{ children }</div>
  </div>;
};

CrashSightSubheader.props = {
  title: PropTypes.string,
};

export default CrashSightSubheader;
