import { isAndroid, isAndroidOrHarmony, isGameConsole, isIos, isMobile, PlatformUtil } from 'utils/platform';
import i18n from 'i18n';
import uniq from 'lodash/array/uniq';
import { ProductSummaryUtil } from 'components/exception/ProductSummaryPage/ProductSummaryUtil';
import { IssueCrashFilterUtil } from 'components/commons/IssueCrashFilter/IssueCrashFilter';
import { isNullish } from 'utils/nullish';
import { VersatileKeywordSearchMethod } from 'components/commons/IssueCrashFilter/VersatileKeywordSearchMethod';
import { EnvUtil } from 'utils/env-util';
import { PLATFORM_ID } from 'utils/constants';

export const LogicalOperator = Object.freeze({
  AND: 'AND',
  OR: 'OR',
});

export const QueryType = Object.freeze({
  TERM: 'TERM',
  TERM_CONTAINS: 'TERM_CONTAINS',
  TERM_WILDCARD: 'TERM_WILDCARD',
  TERMS: 'TERMS',
  TERMS_WILDCARD: 'TERMS_WILDCARD',
  /** 旧版基于SearchBean时候搜索的text格式 */
  TEXT_LEGACY_SEARCH: 'TEXT_LEGACY_SEARCH',
  TEXT_MATCH_PHRASE: 'TEXT_MATCH_PHRASE',
  TEXT_SIMPLE_QUERY_STRING: 'TEXT_SIMPLE_QUERY_STRING',
  RANGE: 'RANGE',
  RANGE_RELATIVE_DATETIME: 'RANGE_RELATIVE_DATETIME',
  NONEMPTY: 'NONEMPTY',
  // 一些特殊字段不满足以上通用的过滤逻辑，value格式也是特殊的
  CUSTOM: 'CUSTOM',
});

export const FieldName = Object.freeze({
  version: 'version',
  issueUploadTime: 'issueUploadTime',
  crashUploadTime: 'crashUploadTime',
  crashTime: 'crashTime',
  issueFirstUploadTime: 'issueFirstUploadTime',
  issueFirstCrashVersion: 'issueFirstCrashVersion',
  exceptionType: 'exceptionType',
  exceptionCategory: 'exceptionCategory',
  issueTag: 'issueTag',
  issueStatus: 'issueStatus',
  tapdBugStatus: 'tapdBugStatus',
  jiraBugStatus: 'jiraBugStatus',
  processor: 'processor',
  bundleId: 'bundleId',
  channelId: 'channelId',
  vmStatus: 'vmStatus',
  systemExitStatus: 'systemExitStatus',
  hasHackedModule: 'hasHackedModule',
  issueId: 'issueId',
  crashId: 'crashId',
  expUid: 'expUid',
  errorType: 'errorType',
  crashExpMessage: 'crashExpMessage',
  crashInfos: 'crashInfos',
  model: 'model', // 以前叫hardware
  appBit: 'appBit',
  iosModel: 'iosModel', // 以前叫modelList
  androidModel: 'androidModel',
  osVersion: 'osVersion',
  processName: 'processName',
  threadName: 'threadName',
  userSceneTag: 'userSceneTag',
  elapsedTime: 'elapsedTime',
  rom: 'rom',
  ram: 'ram', // 以前叫ramRange
  cpu: 'cpu',
  gpu: 'gpu',
  countryOrRegion: 'countryOrRegion', // 以前叫country
  userId: 'userId', // 以前叫contectInfo
  deviceId: 'deviceId', // 以前叫deviceIdList
  customKey: 'customKey',
  keyStackSoName: 'keyStackSoName',
  appSoNameWhitelist: 'appSoNameWhitelist', // iGame定制需求字段
  customLog: 'customLog',
  systemLog: 'systemLog',
  originalCrashStack: 'originalCrashStack',
  crashDetail: 'crashDetail', // 以前叫detail
  oomStatus: 'oomStatus',
  featureTag: 'featureTag',
  /** 当前存的是clickhouse的sub module id的内容 */
  serverEnv: 'serverEnv',
});

export const FieldCategory = Object.freeze({
  ISSUE: 'ISSUE',
  CRASH: 'CRASH',
  OOM: 'OOM',
});

export const CaseSensitivity = Object.freeze({
  SENSITIVE: 'SENSITIVE',
  INSENSITIVE: 'INSENSITIVE',
});

/**
 * @type {{ name: string, fieldCategoryList: string[], availableByPid: function, issueOnly: boolean }[]}
 * */
export const AllFilterFields = [{
  name: FieldName.version,
  fieldCategoryList: [FieldCategory.ISSUE, FieldCategory.CRASH, FieldCategory.OOM],
}, {
  name: FieldName.issueUploadTime,
  fieldCategoryList: [FieldCategory.ISSUE],
}, {
  name: FieldName.crashUploadTime,
  fieldCategoryList: [FieldCategory.CRASH, FieldCategory.OOM],
}, {
  name: FieldName.crashTime,
  fieldCategoryList: [FieldCategory.CRASH, FieldCategory.OOM],
}, {
  name: FieldName.issueFirstUploadTime,
  fieldCategoryList: [FieldCategory.ISSUE],
}, {
  name: FieldName.issueFirstCrashVersion,
  fieldCategoryList: [FieldCategory.ISSUE],
}, {
  name: FieldName.exceptionType,
  fieldCategoryList: [FieldCategory.ISSUE, FieldCategory.CRASH],
  isMultipleSelect: true,
  placeholder: i18n.t('exceptionTypeOptions.所有类型'),
  filterOptionProperty: 'exceptionTypeList',
},  {
  name: FieldName.exceptionCategory,
  fieldCategoryList: [FieldCategory.ISSUE, FieldCategory.CRASH],
  isMultipleSelect: true,
  placeholder: i18n.t('exceptionTypeOptions.所有类型'),
}, {
  name: FieldName.issueTag,
  fieldCategoryList: [FieldCategory.ISSUE],
  isMultipleSelect: true,
  placeholder: i18n.t('tagOptions.所有标签'),
  filterOptionProperty: 'tagList',
}, {
  name: FieldName.issueStatus,
  fieldCategoryList: [FieldCategory.ISSUE],
  isMultipleSelect: true,
  placeholder: i18n.t('statusOptions.所有状态'),
  filterOptionProperty: 'status',
}, {
  name: FieldName.tapdBugStatus,
  fieldCategoryList: [FieldCategory.ISSUE],
  placeholder: i18n.t('tapdBugStatusOptions.所有TAPD缺陷关联状态'),
}, {
  name: FieldName.jiraBugStatus,
  fieldCategoryList: [FieldCategory.ISSUE],
  placeholder: i18n.t('tapdBugStatusOptions.所有TAPD缺陷关联状态'),
}, {
  name: FieldName.processor,
  fieldCategoryList: [FieldCategory.ISSUE],
  placeholder: i18n.t('processorOptions.所有处理人'),
}, {
  name: FieldName.bundleId,
  fieldCategoryList: [FieldCategory.CRASH],
  availableByPid: pid => isMobile(pid),
  placeholder: i18n.t('bundleOptions.所有应用包名'),
}, {
  name: FieldName.channelId,
  fieldCategoryList: [FieldCategory.CRASH],
  availableByPid: pid => isMobile(pid),
  placeholder: i18n.t('channelOptions.全渠道'),
}, {
  name: FieldName.issueId,
  fieldCategoryList: [FieldCategory.CRASH],
  isVersatileKeywordSearch: true,
  defaultQueryType: QueryType.TERM,
}, {
  name: FieldName.expUid,
  fieldCategoryList: [FieldCategory.CRASH, FieldCategory.OOM],
  isVersatileKeywordSearch: true,
  defaultQueryType: QueryType.TERM,
}, {
  name: FieldName.userId,
  fieldCategoryList: [FieldCategory.CRASH, FieldCategory.OOM],
  isVersatileKeywordSearch: true,
  defaultQueryType: QueryType.TERM,
}, {
  name: FieldName.deviceId,
  fieldCategoryList: [FieldCategory.CRASH, FieldCategory.OOM],
  availableByPid: pid => !isGameConsole(pid),
  isVersatileKeywordSearch: true,
  defaultQueryType: QueryType.TERM,
}, {
  name: FieldName.errorType,
  fieldCategoryList: [FieldCategory.CRASH],
  isVersatileKeywordSearch: true,
}, {
  name: FieldName.crashExpMessage,
  fieldCategoryList: [FieldCategory.CRASH],
  isVersatileKeywordSearch: true,
}, {
  name: FieldName.androidModel,
  fieldCategoryList: [FieldCategory.CRASH, FieldCategory.OOM],
  availableByPid: pid => isAndroidOrHarmony(pid),
}, {
  name: FieldName.model,
  fieldCategoryList: [FieldCategory.CRASH, FieldCategory.OOM],
  availableByPid: pid => isMobile(pid),
  isVersatileKeywordSearch: true,
}, {
  name: FieldName.appBit,
  fieldCategoryList: [FieldCategory.CRASH],
  availableByPid: pid => isAndroidOrHarmony(pid),
}, {
  name: FieldName.iosModel,
  fieldCategoryList: [FieldCategory.CRASH, FieldCategory.OOM],
  availableByPid: pid => isIos(pid),
}, {
  name: FieldName.osVersion,
  fieldCategoryList: [FieldCategory.CRASH, FieldCategory.OOM],
  isVersatileKeywordSearch: true,
}, {
  name: FieldName.processName,
  fieldCategoryList: [FieldCategory.CRASH],
  isVersatileKeywordSearch: true,
}, {
  name: FieldName.threadName,
  fieldCategoryList: [FieldCategory.CRASH],
  isVersatileKeywordSearch: true,
}, {
  name: FieldName.elapsedTime,
  fieldCategoryList: [FieldCategory.CRASH],
}, {
  name: FieldName.vmStatus,
  fieldCategoryList: [FieldCategory.CRASH],
  availableByPid: pid => isAndroidOrHarmony(pid),
}, {
  name: FieldName.systemExitStatus,
  fieldCategoryList: [FieldCategory.CRASH],
  availableByPid: pid => isMobile(pid),
}, {
  name: FieldName.countryOrRegion,
  fieldCategoryList: [FieldCategory.CRASH],
  availableByPid: pid => ProductSummaryUtil.getIsRegionalDashboard(pid),
} ,{
  name: FieldName.ram,
  fieldCategoryList: [FieldCategory.OOM],
}, {
  name: FieldName.rom,
  fieldCategoryList: [FieldCategory.CRASH],
  availableByPid: pid => isAndroidOrHarmony(pid),
  isVersatileKeywordSearch: true,
  searchMethodWhitelist: [
    VersatileKeywordSearchMethod.CONTAINS,
    VersatileKeywordSearchMethod.NOT_CONTAINS,
  ],
}, {
  name: FieldName.cpu,
  fieldCategoryList: [FieldCategory.CRASH],
  availableByPid: pid => PlatformUtil.is(pid, PLATFORM_ID.PC),
  isVersatileKeywordSearch: true,
}, {
  name: FieldName.gpu,
  fieldCategoryList: [FieldCategory.CRASH],
  // PC是全量开放的，但是安卓要看enableAndroidSearchGpu是否开放。这个判断是放在IssueCrashFilterEx里面的
  availableByPid: pid => PlatformUtil.is(pid, PLATFORM_ID.ANDROID) || PlatformUtil.is(pid, PLATFORM_ID.PC),
  isVersatileKeywordSearch: true,
}, {
  name: FieldName.customKey,
  fieldCategoryList: [FieldCategory.CRASH],
}, {
  name: FieldName.keyStackSoName,
  fieldCategoryList: [FieldCategory.CRASH],
  isVersatileKeywordSearch: true,
}, {
  name: FieldName.appSoNameWhitelist,
  fieldCategoryList: [FieldCategory.CRASH],
  defaultValue: { queryType: QueryType.CUSTOM, not: true },
}, {
  name: FieldName.crashDetail,
  fieldCategoryList: [FieldCategory.CRASH],
  isVersatileTextSearch: true,
}, {
  name: FieldName.originalCrashStack,
  fieldCategoryList: [FieldCategory.CRASH],
  availableByPid: pid => isMobile(pid),
  isVersatileTextSearch: true,
}, {
  name: FieldName.crashInfos,
  fieldCategoryList: [FieldCategory.CRASH],
  isVersatileTextSearch: true,
}, /* {  // 目前不支持搜索systemLog和customLog
  name: FieldName.systemLog,
  fieldCategoryList: [FieldCategory.CRASH],
  isVersatileTextSearch: true,
}, {
  name: FieldName.customLog,
  fieldCategoryList: [FieldCategory.CRASH],
  isVersatileTextSearch: true,
},*/ {
  name: FieldName.oomStatus,
  fieldCategoryList: [FieldCategory.OOM],
}, {
  name: FieldName.userSceneTag,
  fieldCategoryList: [FieldCategory.CRASH],
  isVersatileKeywordSearch: true,
  defaultQueryType: QueryType.TERM,
  hasIsEmptyQueryType: true,
}, {
  name: FieldName.hasHackedModule,
  fieldCategoryList: [FieldCategory.CRASH],
  availableByPid: pid => isAndroid(pid),
}, {
  name: FieldName.serverEnv,
  fieldCategoryList: [FieldCategory.CRASH],
  isVersatileKeywordSearch: true,
  hasIsEmptyQueryType: true,
}, {
  name: FieldName.featureTag,
  fieldCategoryList: [FieldCategory.CRASH],
}, {
  name: FieldName.crashId,
  fieldCategoryList: [FieldCategory.CRASH],
  customLabel: 'Crash ID (超管)',
  isVersatileKeywordSearch: true,
  defaultQueryType: QueryType.TERM,
}].map(x => {
  return {
    ...x,
    issueOnly: x.fieldCategoryList.length === 1 && x.fieldCategoryList.includes(FieldCategory.ISSUE),
    oomOnly: x.fieldCategoryList.length === 1 && x.fieldCategoryList.includes(FieldCategory.OOM),
  };
});

export const FieldNameToFieldInfo = Object.freeze(Object.assign(Object.create(null), ...AllFilterFields.map(x => ({ [x.name]: x }))));




export const IssueCrashFilterExUtil = (function() {
  return Object.freeze({
    makeSearchConditionGroupFromSearchParamsDict(searchParamsDict) {
      if (!searchParamsDict) {
        return {};
      }
      let searchConditionGroup = searchParamsDict.searchConditionGroup || {};

      // 兼容旧的IssueCrashFilter的数据结构
      const filterData = IssueCrashFilterUtil.makeFilterDataFromQueryDict(searchParamsDict, true, true);
      const conditionsFromFilterData = IssueCrashFilterUtil.makeSearchConditionGroupConditionsFromFilterData(filterData);

      return {
        ...searchConditionGroup,
        conditions: [...(searchConditionGroup.conditions || []), ...conditionsFromFilterData],
      };
    },

    makeSearchParamsDictFromSearchConditionGroup(searchConditionGroup) {
      if (!searchConditionGroup) {
        return {};
      }

      const legacyFilterData = (searchConditionGroup.conditions || [])
        .filter(x => (x.field || '').startsWith('LEGACY_'))
        .map(x => {
          return { name: x.field.replace('LEGACY_', ''), value: x.legacyValue };
        });

      let legacyParamsDict = IssueCrashFilterUtil.makeQueryDictFromFilterData(legacyFilterData);
      for (let k in legacyParamsDict) { // 移除掉nullish和空字符串的值
        if (isNullish(legacyParamsDict[k]) || legacyParamsDict[k] === '') {
          delete legacyParamsDict[k];
        }
      }

      const conditionsWithoutLegacy = (searchConditionGroup.conditions || [])
        .filter(x => !(x.field || '').startsWith('LEGACY_'));

      let finalSearchConditionGroup = {
        ...searchConditionGroup,
        conditions: conditionsWithoutLegacy,
      };
      if (finalSearchConditionGroup.conditions.length === 0
        && (finalSearchConditionGroup.subgroups || []).length === 0) {
        finalSearchConditionGroup = null;
      }

      return {
        ...(finalSearchConditionGroup ? { searchConditionGroup: finalSearchConditionGroup } : {}),
        ...legacyParamsDict,
      }
    },

    /** 从一个scg中提取出所有涉及到的字段。（会递归拿取子scg的字段） */
    getAllFieldsFromSearchConditionGroup(searchConditionGroup) {
      const { conditions, subgroups } = searchConditionGroup || {};
      const shallowFields = (conditions || []).map(x => x.field);
      const deepFields = subgroups && subgroups.length > 0
        ? subgroups.map(x => this.getAllFieldsFromSearchConditionGroup(x)).flat()
        : [];
      return uniq([...shallowFields, ...deepFields]);
    },

    /** 根据app id判断哪些项目支持下拉选择bundleId。有的项目bundleId脏数据太多，获取选项界面会卡死，就不支持下拉了 */
    getIsBundleIdOptionsAvailable(appId) {
      const blacklist = process.env.REACT_APP_DISABLE_BUNDLE_ID_OPTIONS_APPS || [];
      return !blacklist.includes(appId);
    },
  });
})();
