/*
 *工具类js
 */

// 判断是否是空对象
export function isEmptyObject(value) {
  return Object.keys(value).length === 0;
}

// 检查数组中是否包含某一个值,用法如contains(["foo", "bar"], "baz"); // => false
export function isContains(arr, value) {
  // const contains = (() =>
  //   Array.prototype.includes
  //     ? (arr, value) => arr.includes(value)
  //     : (arr, value) => arr.some((el) => el === value))();
  // return contains(arr, value);
  return Array.prototype.includes ? arr.includes(value) : arr.some((el) => el === value);
}

// 操作localstorage
export function saveInLocal(key, value) {
  window.localStorage.setItem(key, value);
}

export function getFromLocal(key) {
  return window.localStorage.getItem(key);
}
