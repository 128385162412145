import React, {useState, useEffect} from "react";
import { useTranslation } from 'react-i18next';
import { parseSearch } from 'utils/helper';
import { bindActionCreators } from 'redux';
import * as symbolTableActions from 'reducers/symbolTable/actions';
import * as globalActions from 'reducers/global/globalActions';
import { connect } from 'react-redux';
import NoJumpNextPagination from 'components/antd-extension/NoJumpNextPagination.jsx';
import { Modal, Form, Input, Select, Space, Button, Table, Divider, Radio  } from 'antd';
import SearchIcon from 'svg/v2/newcs_dashboard_crashanalysis_issuedetail_tracelog_filt_search_icon.svg';
const OnlineRetraceModal = (props) => {
    const { t } = useTranslation();
    const {modalState, reduxState, CloseSubComponentPopup} = props;
    const currentApp = reduxState.app.get('current').toJS();
    const [totalCount,setTotalCount] = useState();
    const [current, setCurrent] = useState(1);
    const [pageSize,setPageSize] = useState(10);
    const { appId, platformId } = currentApp;
    const [ symbolTable, setSymbolTable ] = useState([]);
    const [ singleSymbolTable, setSingleSymbolTable ] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [searchValue,setSearchValue] = useState();
    const [form] = Form.useForm();


    const symbolTableColumns = [
        {
          title: '版本',
          dataIndex: 'version',
          key: '1',
        },
        {
          title: 'UUID',
          dataIndex: 'uuid',
          key: '2',
        },
        {
          title: '符号表文件',
          dataIndex: 'symbolPath',
          key: '3',
          render:(_,{soName,uploadedFilename}) => <div>
            { soName && <span>{ uploadedFilename }</span> }
        </div>
        },
        {
            title: '上传时间',
            dataIndex: 'uploadTime',
            key: '4',
        },
      ];
    const uploadSymbolSheetUrl = `${window.location.origin}/crash-reporting/preferences/dsyms/${appId}?pid=${platformId}`;
    const rowSelection = {
        selectedRowKeys,
        type:'radio',
        onChange: (selectedRowKeys, selectedRows) => {
            setSingleSymbolTable(selectedRows[0].symbolPath);
            setSelectedRowKeys(selectedRowKeys);
        },
      };
    const clotureModal = () => {
        form.resetFields();
        setSingleSymbolTable([]);
        setSymbolTable([]);
        setSelectedRowKeys([]);
        setSearchValue();
    }

    const OnlineRetraceModalList = (query) => {
        if(appId){
            props.actions.getList({
                appId:appId,
                platformId:platformId,
                type:3,
                offset:pageSize*(current-1),
                size:pageSize,
                status:'',
                search:searchValue,
                ...query,
            }).then((data) => {
                const infos = data.infos;
                const infosFilter = infos.filter(item => item.uuid);
                setSymbolTable(infosFilter.map(item => ({
                    ...item,
                    key:item.uuid,
                })))
                setTotalCount(data.totalCount);
            })
        }
    }

    useEffect(() => {
        if(appId){
            OnlineRetraceModalList({
                init:false,
            })
        }
    },[modalState])

    useEffect(() => {
        OnlineRetraceModalList({
            init:false,
        })
    },[pageSize,current])
    useEffect(() => {
        OnlineRetraceModalList({
            init:false,
        })
    },[searchValue])

    const handlePressEnterInput = (event) => {
        setCurrent(1);
        setSearchValue(event.target.value);
      }

    const paginationChange = (page,pageSize) => {
        setCurrent(page);
    }
    const paginationShowSizeChange = (current,size) => {
        setCurrent(1);
        setPageSize(size);
    }
    return <Modal
        title="在线还原"
        width='90vw'
        open={modalState}
        onCancel={() => {CloseSubComponentPopup('关闭');clotureModal()}}
        destroyOnClose={true}
        footer={[
            <Button onClick={() => {CloseSubComponentPopup('关闭');clotureModal()}}>
                取消
            </Button>,
            <Button type="primary" onClick={() => {CloseSubComponentPopup('确定',singleSymbolTable);clotureModal()}} disabled = {!selectedRowKeys.length > 0}>
                确定
            </Button>,
        ]}
    >
        <div>
            <div style={{padding:"10px",display:'flex'}}>
                <a target= '_blank' href={uploadSymbolSheetUrl} style={{paddingRight:'10px'}}>上传符号表</a>
                <Input
                type="search"
                prefix={<SearchIcon />}
                style={{width:'200px'}}
                placeholder={t('SYMBOLMANAGE.searchUUIDPlaceholder')}
                onPressEnter={(event) => handlePressEnterInput(event)} />
            </div>
            <Table
                rowSelection={rowSelection}
                columns={symbolTableColumns}
                dataSource={symbolTable}
                pagination={false}
                scroll={{
                    y: 340,
                }}/>
           <div style={{display:'flex',marginTop:'10px'}}>
            <div style={{flex:'1'}}></div>
           <NoJumpNextPagination
            current={current}
            pageSize={pageSize}
            total={totalCount}
            onChange={(page,pageSize) => paginationChange(page,pageSize)}
            onShowSizeChange={(current,size) => paginationShowSizeChange(current,size)}
            />
           </div>
        </div>
    </Modal>

};

export default connect((state, route) => {
    const { symbolType, status, search } = parseSearch(location);
    return {
      currentApp: state.app.get('current'),
      demoApp: state.app.get('current').get('demoApp'),
      currentUser: state.user.get('current'),
      selectOptions: state.symbolTable.get('selectOptions'),
      empty: state.symbolTable.get('empty'),
      selectedType: state.symbolTable.get('selectedType'),
      list: state.symbolTable.get('list'),
      loading: state.global.get('loading'),
      dsymsSize: state.global.get('dsymsSize'), // 新增的符号表大小控制。
      search,
      symbolType: +symbolType,
      status,
    };
  }, (dispatch) => ({
    actions: bindActionCreators({
      ...symbolTableActions,
      ...globalActions,
    }, dispatch),
  }))(OnlineRetraceModal);