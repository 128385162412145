import { createAction } from 'redux-actions';
import SolutionRest from 'utils/SolutionRest';

export const changeCurTab = createAction('CHANGE_CURTAB');// 更新tab

export const updateState = createAction('UPDATE_STATE_PARAMS');// 更新搜索参数

/**
 * 获取解决方案
 */
export function getNewSolutionInfo(issueId, crashHash) {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    return dispatch({
      rest: SolutionRest.solution.info,
      data: {
        appId,
        pid,
        issueId,
        crashHash,
      },
    });
  };
}

/*
*点赞
*/
export function submitCaseApprove(issueId, crashHash, caseId, versionId) {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    return dispatch({
      rest: SolutionRest.solution.caseApprove,
      data: {
        appId,
        pid,
        issueId,
        crashHash,
        caseId,
        versionId,
      },
    });
  };
}

// 提交方案
export function submitCaseSolution(issueId, crashHash, parentCaseId, parentVersionId, solution, exceptionType, exceptionMessage, stack, bundleId, crashType) {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    return dispatch({
      rest: SolutionRest.solution.caseSolution,
      data: {
        appId,
        pid,
        issueId,
        crashHash,
        parentCaseId,
        parentVersionId,
        solution: encodeURIComponent(solution),
        exceptionType,
        exceptionMessage,
        stack,
        bundleId,
        crashType,
      },
    });
  };
}
