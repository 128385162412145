import { Map } from 'immutable';
import moment from 'moment';
import { DEFAULT_TIME_ZONE } from 'utils/time-zone-util';

/**
 * @param {Map} searchParams
 * @returns {*}
 */
export function makeCrashListSearchParamsFromSearchParams(searchParams) {
  let params = searchParams.filter((value) => value !== '' && value !== 'all');
  if (params.get('version')) {
    params = params.set('version', decodeURIComponent(params.get('version')));
  }
  if (params.get('bundleId')) {
    params = params.set('bundleId', decodeURIComponent(params.get('bundleId')));
  }
  if (params.get('channelId')) {
    params = params.set('channelId', decodeURIComponent(params.get('channelId')));
  }
  return params;
}

export const CrashUtil = (function() {
  function momentFormatWithDefault(input, outputFormat, fallback) {
    if (!input) {
      return fallback;
    }
    const m = moment.tz(input, DEFAULT_TIME_ZONE); // 将后台默认时区的数据转成项目本地时区的
    if (!m.isValid()) {
      return `${fallback} (${DEFAULT_TIME_ZONE})`;
    }
    const millis = m.valueOf();
    return moment(millis).format(outputFormat);
  }

  return Object.freeze({
    /** 检查上报是否是先锋云游戏 */
    checkIsGameMatrix(appId, crashMap) {
      const lowerModel = (crashMap.modelOriginalName || '').toLowerCase();
      const lowerRom = (crashMap.rom || '').toLowerCase();
      if (lowerModel === 'gamematrix') {
        return true;
      }
      // 国内DNF云游戏判定规则
      if (appId === '1104294055' && lowerModel.includes('noh-an00') && !lowerRom.startsWith('huawei')) {
        return true;
      }
      return false;
    },

    /** 加工getCrashDoc接口里的crashMap */
    makeRefinedCrashMap(crashMap) {
      if (!crashMap) {
        return {};
      }
      const crashTimeFormatted = Number(crashMap.crashTimestamp) > 0
        ? moment(crashMap.crashTimestamp).format('YYYY-MM-DD HH:mm:ss')
        : momentFormatWithDefault(crashMap.crashTime, 'YYYY-MM-DD HH:mm:ss', crashMap.crashTime);

      const uploadTimeFormatted = Number(crashMap.uploadTimestamp) > 0
        ? moment(crashMap.uploadTimestamp).format('YYYY-MM-DD HH:mm:ss')
        : momentFormatWithDefault(crashMap.uploadTime, 'YYYY-MM-DD HH:mm:ss', crashMap.uploadTime);

      const { addCodeFrame, retraceCrashDetail } = crashMap;
      const crashStackLines = (retraceCrashDetail || '').split('\n');
      let firstDefinitiveStackIndex = Array.isArray(addCodeFrame) && addCodeFrame.length > 0
        ? Number((addCodeFrame[0] || '').split(',')[0]) || 0
        : 0;
      const firstDefinitiveStackLine = crashStackLines[firstDefinitiveStackIndex] || '';

      return {
        ...crashMap,
        crashTimeFormatted,
        uploadTimeFormatted,
        firstDefinitiveStackLine,
      }
    },

    formatDeviceName(originalModel, productName) {
      if (!originalModel || !productName) {
        return originalModel || productName || '';
      }
      if (originalModel.toLowerCase() !== productName.toLowerCase()) {
        return `${productName} (${originalModel})`;
      }
      return originalModel;
    },
  });
})();
