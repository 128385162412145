import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as platformUtils from 'utils/platform';
import { useTranslation } from 'react-i18next';
import { Button, Popconfirm, Select, Tag, Tooltip, Spin, Modal, Radio } from 'antd';
import { connect } from 'react-redux';
import { downloadAsFile } from 'utils/file-utils';
import { EyeOutlined, LoadingOutlined } from '@ant-design/icons';
import RestHelper from 'utils/RestHelper';
import { useZhEn } from 'utils/react-hooks/useZhEn';
import { isMobile, isPcOrLinux, isIos, isPc, PlatformUtil } from 'utils/platform';
import { ServerAppSettings } from 'utils/server-app-settings';
import { ReportDetailUtil } from 'components/exception/issue/ReportDetail/ReportDetail';
import { ExceptionCategoryUtil } from 'utils/exception-category';
import reportEvent, { EVENT_ACTIONS } from 'utils/reportEvent';
import StackIcon from 'svg/v2/newcs_dashboard_crashanalysis_issuedetail_stack_icon.svg';
import StackAi from 'svg/v2/newcs_dashboard_crashanalysis_issuedetail_stack_ai_icon.svg';
import CsSwapSelect from 'components/commons/CsSwapSelect/CsSwapSelect';
import { CS_STYLES } from 'utils/constants/style-constants';
import scss from './DataHead.scss';
import classnames from 'classnames';
import CsCollapseButton from 'components/commons/CsCollapseButton/CsCollapseButton';
import WrappedTipsIcon from 'components/antd-extension/WrappedTipsIcon.jsx';
import { DetailStackMemoryInfoUtil } from 'utils/detail-stack-memory-info-util';
import { isNotNullish } from 'utils/nullish';
import RetraceHistoryModal, { RetraceHistoryUtil } from 'components/exception/issue/DataHead/RetraceHistoryModal';

function noop(e) {
}

const MINIDUMP_STATUS = {
  PROCESS: 'PROCESS', // 还原中
  SUCCESS: 'SUCCESS', // 还原成功
  FAIL: 'FAIL', // 还原失败
};

const DataHead = ({
  exceptionName, stackType, handleStackTypeClick, handleReloadCrash, style, height, onClick, pid, threadName, exceptionMessage,
  stackContent, issue, exceptionType, user, isIssueRegroup, actions, reduxState,
  threadNameLabel,
  obfuscationFileInfo,
  shareId,symbolInfoLoading,isChildMethodExecuting,
  isMainThread,
  retraceLoading,
  retraceStatusQueryResult,
  isCollapsed,
  hideReretraceButton,
  stackTraceTypeSwapSelect,
}) => {
  const { t } = useTranslation();
  const { ze } = useZhEn();
  const { isSuper } = (user && user.toJS()) || {};
  const appId = reduxState.app.get('current').get('appId');

  const serverAppSettings = reduxState.app.get('appIdToServerAppSettings')[appId] || {};
  const enableErrorRetrace = serverAppSettings[ServerAppSettings.keys.enableErrorRetrace];
  const enableAiAnalyse = serverAppSettings[ServerAppSettings.keys.enableAiAnalyse];

  const isReretraceAvailableByExceptionType = (exceptionType || '').startsWith('crash')
    || ((exceptionType || '').startsWith('error') && enableErrorRetrace);

  // 重还原是否可用
  const isReretraceAvailable = !(platformUtils.isSwitch(pid))
    && !isIssueRegroup
    && !shareId
    && !hideReretraceButton
    && isReretraceAvailableByExceptionType;

  const shrinkFilePathOptions = [
    { label: ze('完整文件路径', 'Full File Path'), value: 'fullFilePath' },
    { label: ze('缩略文件路径', 'Short File Path'), value: 'shrunkFilePath' },
  ];

  const stackFilePathDisplayType = reduxState.global.get('localStorage').get('stackFilePathDisplayType') || 'fullFilePath';

  const [miniDumpDemonstrate,setMiniDumpDemonstrate] = useState();
  const [miniDumpModal,setMiniDumpModal] = useState(false);

  const [retraceHistoryModalVisible, setRetraceHistoryModalVisible] = useState(false);

  const isShowingMinidumpStack = issue?.get('isShowingMinidumpStack') || false;
  const detailStackMemoryInfoList = issue?.get('minidumpDetailStackMemoryInfoList') || [];
  const crashDoc = issue ? (issue.get('crashDoc') || {}) : {};
  const esMap = crashDoc.esMap || {};
  const crashMap = crashDoc.crashMap || {};
  const {
    issueId,
    crashId,
  } = crashMap;
  const {
    exceptionType: pcExpType,  // PC windbg的exceptionType没有写到expName上，所以要额外取一下
    hasMinidump,
    minidumpParseStatus,
    minidumpParseFailReason,
    crashMinidumpStackCosKey,
  } = esMap;

  const isCrash = ExceptionCategoryUtil.isCrash(exceptionType);
  const isIosPlatform = isIos(pid);

  const crashedAsmLine = isCrash && (isPcOrLinux(pid) || isShowingMinidumpStack)
    ? DetailStackMemoryInfoUtil.getCrashedAsmLine(detailStackMemoryInfoList)
    : '';

  // 移动端、Linux 根据 SIGSEGV 判断是否展示崩溃地址（注：历史原因导致PC项目也放开，因为PC项目没有 SIGSEGV，故不会受影响）
  const isMobileOrLinuxShowExpAddr = (PlatformUtil.isMobileOrMobileLike(pid) || isPcOrLinux(pid))
    && isCrash
    && (exceptionName || '').includes('SIGSEGV');
  // PC根据 EXCEPTION_ACCESS_VIOLATION_READ 等判断是否展示崩溃地址
  const isPcShowExpAddr = isPc(pid, appId)
    && isCrash
    && ['EXCEPTION_ACCESS_VIOLATION_READ', 'EXCEPTION_ACCESS_VIOLATION_WRITE'].includes(exceptionName);
  const isShowExpAddr = isMobileOrLinuxShowExpAddr || isPcShowExpAddr;



  let downloadMinidumpTooltip = ze('未触发过Minidump还原堆栈，或Minidump重还原请求尚未执行。', 'The minidump stack trace re-symbolication has not been triggered, or the minidump re-symbolication request has not been executed yet.');
  if (minidumpParseStatus === MINIDUMP_STATUS.PROCESS) {
    downloadMinidumpTooltip = ze('正在处理Minidump还原。', 'The minidump re-symbolication is processing.');
  } else if (minidumpParseStatus === MINIDUMP_STATUS.SUCCESS) {
    downloadMinidumpTooltip = ze('查看Minidump还原结果', 'View the minidump re-symbolication results');
  } else if (minidumpParseStatus === MINIDUMP_STATUS.FAIL) {
    downloadMinidumpTooltip = ze(`Minidump还原失败，错误码：${minidumpParseFailReason}`, `Minidump re-symbolication failed, error code: ${minidumpParseFailReason}`);
  }

  // props的exceptionName是issue的，只用于判断是否显示异常名字段，不用于展示内容，展示内容要取crash上的
  const crashExpName = isPcOrLinux(pid)
    ? (crashMap.expName || pcExpType || '')
    : (crashMap.expName || '');

  function getStackArr(line) { // crash的类型就需要分成四段.其他类型原样返回,还要区分下ios和安卓
    if ((parseInt(pid) === 2) || (parseInt(pid) === 4)) { // IOS
      const hexReg = /0[xX][0-9a-fA-F]+/;
      const HexadecimalArr = line.match(hexReg);// 取出16进制数
      const hexIndex = HexadecimalArr && HexadecimalArr.index;
      const addrStr = HexadecimalArr && HexadecimalArr[0] && line.substr(hexIndex + HexadecimalArr[0].length, line.length - 1);
      const addrArr = addrStr && addrStr.split(/\s[+]\s/);
      if (addrArr && addrArr.length > 0) {
        return addrArr[0];
      }
      return line;
    } if (parseInt(pid) === 1) {
      return line;
    }
    return [];
  }

  function getfirstStack(str) {
    if (str) {
      const arr = str.split('\n').filter(line => !!line)
        .map(line => getStackArr(line));
      return arr[0];
    }
    return '';
  }

  function onRetraceConfirm() {
    handleStackTypeClick('retraceAgain');
    reportEvent({
      action: EVENT_ACTIONS.CLICK,
      tp1: '重还原堆栈',
    });
  }

  function onRetraceMinidumpConfirm() {
    handleStackTypeClick('retraceMinidump');
  }

  async function restoreRetraceMinidumpConfirm(){
    const downloadUrl = `${crashMinidumpStackCosKey}.dmp.gz`;
    const data = await RestHelper.post('/api/crash/decompressInflateFile', {
      appId,
      platformId:pid,
      cosKey:downloadUrl,
      compressFormat: 'GZIP',
    });
    const { hasFile, contentBase64 } = data.json.data;
    if (hasFile) {
      const pcLogUint8Array = Uint8Array.from(window.atob(contentBase64), c => c.charCodeAt(0));
      const pcLogText = new TextDecoder().decode(pcLogUint8Array);
      setMiniDumpDemonstrate(pcLogText);
    }else{
      setMiniDumpDemonstrate('minidump还原堆栈结果为空');
    }
    setMiniDumpModal(true);
  }

  async function downloadRetraceMinidumpResult() {
    const rsp = await RestHelper.get('/api/app/getCosDownloadOrigin');
    const origin = rsp.json.data;
    const downloadUrl = `${origin}/${crashMinidumpStackCosKey}.dmp.gz`;
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', `${crashMinidumpStackCosKey}.dmp.gz`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const polishedExceptionMessage = useMemo(() => {
    return (exceptionMessage || '')
      .split('\n')
      .filter(x => x.trim())
      // 异常信息去除换行，https://tapd.woa.com/20421727/prong/stories/view/1020421727117317674
      // .join('\n');
  }, [exceptionMessage]);

  const {
    retraceStatus,
    lastRetraceStartTime,
    lastRetraceEndTime,
  } = retraceStatusQueryResult || {};

  let lastRetraceStatusDom;
  if (isNotNullish(retraceStatus) && retraceStatus !== 0) {
    const text = RetraceHistoryUtil.getTextByRetraceStatus(retraceStatus);
    const styleObject = RetraceHistoryUtil.getStyleObjectByRetraceStatus(retraceStatus);
    lastRetraceStatusDom = <div style={styleObject}>{ ze(`（${text}）`, `(${text})`) }</div>
  }

  const isRetraceProcessing = retraceLoading;

  return (
      <div
        className={classnames(style.data_head, scss.dataHead, !isMainThread ? scss.otherThread : '' )}
        style={{ alignItems: 'center' }}
        onClick={onClick || noop}
      >
        <div style={{ marginRight: '24px' }}><StackIcon /></div>
        { (!!exceptionName || !!exceptionMessage || !!threadName)
        && (
        <div style={{ flex: '1 1 0', display: 'flex', flexDirection: 'column', gap: '16px', overflowWrap:'break-word'}}>
          <div style={{ display: 'flex', gap: '16px' }}>
            <div style={{ display: 'flex', gap: '4px', alignItems:'center' }}>
              <div className={scss.fieldName}>{threadNameLabel}</div>
              <div className={scss.fieldValueNoWrap}>{threadName}</div>
            </div>
            {exceptionName && <div style={{ display: 'flex', gap: '4px', alignItems:'center' }}>
              <div className={scss.fieldName}>{t('REPORTDETAIL.expName')}: </div>
              <div className={scss.fieldValueNoWrap}>{ isIosPlatform && isCrash && polishedExceptionMessage ? `${crashExpName}(${polishedExceptionMessage})` : crashExpName }</div>
            </div>}
          </div>
          {exceptionMessage && <div>
            <div style={{ display: 'flex', gap: '4px' }}>
              <div className={scss.fieldName}>{ t('issueCrashFilterKey.crashExpMessage') }</div>
              <div className={scss.fieldValue}>{ polishedExceptionMessage }</div>
            </div>
          </div> }
          { isShowExpAddr && <div>
            <div style={{ display: 'flex', gap: '4px' }}>
              <div className={scss.fieldName}>{ ze('崩溃时访问地址', 'Accessed Address When Crashed') }:</div>
              <div className={scss.fieldValueNoWrap}>{ReportDetailUtil.renderExpAddr(crashMap.expAddr)}</div>
            </div>
          </div> }
          { crashedAsmLine && <div>
            <div style={{ display: 'flex', gap: '4px' }}>
              <div className={scss.fieldName}>{ ze('崩溃时执行的汇编', 'ASM Executed When Crashed') }:</div>
              <div className={scss.fieldValueNoWrap}>{ crashedAsmLine }</div>
            </div>
          </div> }
        </div>
        ) }
        { !isMainThread && stackContent && <CsCollapseButton
          isSmallSize={true}
          isCollapsed={isCollapsed}
          enableHover={true}
        /> }
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          { isMainThread && stackTraceTypeSwapSelect && <div style={{ display: 'flex', justifyContent: 'end' }}>
            { stackTraceTypeSwapSelect }
          </div> }
          <div style={{ display: 'flex', gap: '8px' }}>
            {
              isMainThread && isReretraceAvailable
              && <div onClick={(e) => e.stopPropagation()}>
                <Popconfirm
                  disabled={isRetraceProcessing}
                  title={t('ERRORSTACKTAB.重还原二次确认')}
                  onConfirm={onRetraceConfirm}
                >
                  <Button
                    disabled={isRetraceProcessing}
                    type='primary'
                    onClick={(e) => e.stopPropagation()}
                  >{isRetraceProcessing
                    ? <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}><LoadingOutlined/>{ t("ERRORSTACKTAB.restoreTip") }</div>
                    : (isMainThread ? t('ERRORSTACKTAB.restore') : t('ERRORSTACKTAB.restoreOtherStack'))}</Button>
                </Popconfirm>
              </div>
            }
            {
              isMainThread && <Select
                style={{ width: '150px' }}
                value={stackFilePathDisplayType}
                options={shrinkFilePathOptions}
                onChange={(v) => {
                  actions.setPartialLocalStorage({
                    stackFilePathDisplayType: v,
                  });
                }}
              />
            }
            {
              isReretraceAvailable
              && isMainThread && (
                <Select
                  style={{ width: '150px' }}
                  options={[
                    { label: t('ERRORSTACKTAB.parse'), value: 'retrace' },
                    { label: t('ERRORSTACKTAB.origin'), value: 'original' },
                  ]}
                  value={stackType === 'original' ? 'original' : 'retrace'}
                  onChange={v => { handleStackTypeClick(v)} }
                />
              )}
            {
              isMainThread && enableAiAnalyse && <div style={{ display: 'flex' }}>
                <Spin spinning={isChildMethodExecuting}>
                  <Button
                    style={{
                      width:'32px',
                      height:'32px',
                      paddingTop:'6px',
                    }}
                    className={scss.stackAi}
                    onClick={() => handleStackTypeClick('aiParsing')}
                    icon={<StackAi />}
                  >
                    {/* <span>{<StackAi />}</span> */}
                    {/* <span style={{marginLeft:'5px'}}><Tooltip title={ t('ERRORSTACKTAB.AI分析提示') }>
                    <div>
                      <WrappedTipsIcon />
                    </div>
                  </Tooltip></span> */}
                  </Button>
                </Spin>
              </div>
            }
          </div>
          { isMainThread && isNotNullish(retraceStatus) && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
            <div>{ ze('最近一次重试还原堆栈：', 'Recently re-symbolicated at: ') }{ lastRetraceStartTime || 'Unknown Time' }</div>
            { lastRetraceStatusDom }
            { /* <Button
              onClick={() => setRetraceHistoryModalVisible(true)}
              type='link'
              style={{ marginLeft: '12px', padding: 0, height: 'inherit' }}
            ><div>{ ze('查看历史', 'Show All') }</div></Button> */}
          </div> }
        </div>
        <RetraceHistoryModal
          visible={retraceHistoryModalVisible}
          onClose={() => setRetraceHistoryModalVisible(false)}
          appId={appId}
          issueId={issueId}
          crashId={crashId}
        />
      </div>
  );
};

DataHead.propTypes = {
  reduxState: PropTypes.object,
  style: PropTypes.object.isRequired,
  exceptionName: PropTypes.string,
  handleStackTypeClick: PropTypes.func,
  stackType: PropTypes.string,
  height: PropTypes.string,
  onClick: PropTypes.func,
  pid: PropTypes.string,
  threadName: PropTypes.string,
  exceptionMessage: PropTypes.string,
  stackContent: PropTypes.string,
  issue: PropTypes.object,
  exceptionType: PropTypes.string,
  user: PropTypes.object,
  threadNameLabel: PropTypes.string,
  actions: PropTypes.object,
  // 表示是否是堆栈重分类界面嵌入的lastReport，这个界面嵌入的lastReport有些功能是不提供的，同时额外提供指定堆栈功能
  isIssueRegroup: PropTypes.bool,
  // LGAME需求，混淆表信息
  obfuscationFileInfo: PropTypes.object,
  shareId: PropTypes.string,  // 分享链接的id，如果不为空表示是通过分享链接查看的
  symbolInfoLoading: PropTypes.string,
  isMainThread: PropTypes.bool,
  isCollapsed: PropTypes.bool,
  hideReretraceButton: PropTypes.bool,
};

const mapStateToProps = state => ({
  reduxState: state,
});

export default connect(mapStateToProps)(DataHead);
