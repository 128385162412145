import React from 'react';
import { Button, Pagination, PaginationProps } from 'antd';
import scss from 'components/antd-extension/NoJumpNextPagination.scss'
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
// import UnfoldLeftNormal from 'svg/v2/newcs_dashboard_crashanalysis_issuelist_unfold_left_normal.svg';
// import UnfoldRightNormal from 'svg/v2/newcs_dashboard_crashanalysis_issuelist_unfold_right_normal.svg';


/** 禁止往后快速跳页的antd Pagination */
const NoJumpNextPagination = ({
  ...props
}) => {
  const btnProps = props.size ? {size: props.size} : {}

  const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      return <Button {...btnProps} icon={<CaretLeftOutlined />}></Button>;
    }
    if (type === 'next') {
      return <Button {...btnProps} icon={<CaretRightOutlined/>}></Button>;
    }
    return originalElement;
  };

  return <Pagination
    className={scss.noJumpNextPagination}
    {...props}
    itemRender={itemRender}
  />;
};

export default NoJumpNextPagination;
