import { bindActionCreators } from 'redux';
import * as appActions from 'reducers/app/appActions';
import * as authActions from './auth/action';
import * as ftAnalyseActions from './ftAnalyse/action';
import * as globalActions from './global/globalActions';
import * as issueActions from './issue/issueActions';
import * as keywordActions from './keyWord/actions';
import * as productActions from './product/actions';
import * as sdkAppActions from './sdkApp/actions';
import * as sdkCrashVersionActions from './sdkCrashVersion/actions';
import * as sdkSummaryActions from './sdkSummary/actions';
import * as solutionActions from './solution/actions';
import * as summaryActions from './summary/summaryActions';
import * as symbolTableActions from './symbolTable/actions';
import * as userChannelActions from './userChannel/actions';
import * as userdataActions from './userdata/actions';
import * as userSummaryActions from './userSummary/userSummaryActions';
import * as userVersionActions from './userVersion/actions';
import * as crashActions from './crash';
import * as seniorSearchActions from './seniorSearch';
import * as symbolActions from './symbol';
import * as tagActions from './tag';
import * as userActions from './user';

export default function mapActions(dispatch) {
  const result = {
    actions: bindActionCreators({
      ...appActions,
      ...authActions,
      ...ftAnalyseActions,
      ...globalActions,
      ...issueActions,
      ...keywordActions,
      ...productActions,
      ...sdkAppActions,
      ...sdkCrashVersionActions,
      ...sdkSummaryActions,
      ...solutionActions,
      ...summaryActions,
      ...symbolTableActions,
      ...userChannelActions,
      ...userdataActions,
      ...userSummaryActions,
      ...userVersionActions,
      ...crashActions,
      ...seniorSearchActions,
      ...symbolActions,
      ...tagActions,
      ...userActions,
    }, dispatch),
  };

  console.log('mapActions: ', result);
  return result;
}
