import { Map, List } from 'immutable';

// 日报参数
export const dailyReportConfig = new Map({
  appId: '',
  platformId: '',
  minAccess: 200,
  receivers: 'all_admins',
  newUserIdReceivers: 'all_admins',
  enable: '',
  versions: '',
});

const initialConfigOptions = new Map({
  version: {
    options: [],
  },
  processorList: {
    options: [],
  },
  customVersion: {
    options: [
      { label: '最新联网版本TOP3', value: 'top3' },
      { label: '自定义版本', value: 'custom_version' },
    ],
  },
  customMember: {
    options: [
      { label: '所有管理员', value: 'all_admins' },
      { label: '所有成员', value: 'all_members' },
      { label: '自定义成员', value: 'custom_member' },
    ],
  },
});

const initialPermission = new Map({
  admin: new Map({
    manage1: new List([
      { label: 'PERMISSIONLIST.productManage', checked: true },
      { label: 'PERMISSIONLIST.informationEdit', checked: true },
      { label: 'PERMISSIONLIST.memberManage', checked: true },
      { label: 'PERMISSIONLIST.permissionEdit', checked: true },
      { label: 'PERMISSIONLIST.versionManage', checked: true },
      { label: 'PERMISSIONLIST.reportEdit', checked: true },
      { label: 'PERMISSIONLIST.productDelete', checked: true },
      { label: 'PERMISSIONLIST.webhookEdit', checked: true },
    ]),
    manage2: new List([
      { label: 'PERMISSIONLIST.crashModule', checked: true },
      { label: 'PERMISSIONLIST.alarmEdit', checked: true },
      { label: 'PERMISSIONLIST.symbolEdit', checked: true },
      { label: 'PERMISSIONLIST.tagManage', checked: true },
    ]),
    manage3: new List([
      { label: 'PERMISSIONLIST.testModule', checked: true },
      { label: 'PERMISSIONLIST.versionRelease', checked: true },
      { label: 'PERMISSIONLIST.versionEdit', checked: true },
      { label: 'PERMISSIONLIST.versionDelete', checked: true },
    ]),
    check1: new List([
      { label: 'PERMISSIONLIST.dataCheck', checked: true },
      { label: 'PERMISSIONLIST.dataCrash', checked: true },
      { label: 'PERMISSIONLIST.dataOperation', checked: true },
      // {label:'用户反馈数据',checked: true}
    ]),
  }),
  normal: new Map({
    manage1: new List([
      { label: 'PERMISSIONLIST.productManage', checked: false },
      // { label: '产品信息修改', checked: false },
      // { label: '产品成员管理', checked: false },
      // { label: '角色权限配置', checked: false },
      // { label: '版本管理', checked: true },
      // { label: '日报配置', checked: true },
      // { label: '删除产品', checked: false },
      { label: 'PERMISSIONLIST.webhookEdit', checked: true },
    ]),
    manage2: new List([
      { label: 'PERMISSIONLIST.crashModule', checked: false },
      { label: 'PERMISSIONLIST.alarmEdit', checked: true },
      { label: 'PERMISSIONLIST.symbolEdit', checked: true },
      { label: 'PERMISSIONLIST.tagManage', checked: true },
    ]),
    manage3: new List([
      { label: 'PERMISSIONLIST.testModule', checked: true },
      { label: 'PERMISSIONLIST.versionRelease', checked: true },
      { label: 'PERMISSIONLIST.versionEdit', checked: true },
      { label: 'PERMISSIONLIST.versionDelete', checked: true },
    ]),
    check1: new List([
      { label: 'PERMISSIONLIST.dataCheck', checked: false },
      { label: 'PERMISSIONLIST.dataCrash', checked: true },
      // { label: '运营统计数据', checked: false }
      // {label:'用户反馈数据',checked: false}
    ]),
  }),
  operator: new Map({
    manage1: new List([
      { label: 'PERMISSIONLIST.productManage', checked: false },
      // { label: '产品信息修改', checked: false },
      // { label: '产品成员管理', checked: false },
      // { label: '角色权限配置', checked: false },
      // { label: '版本管理', checked: true },
      // { label: '日报配置', checked: true },
      // { label: '删除产品', checked: false },
      { label: 'PERMISSIONLIST.webhookEdit', checked: true },
    ]),
    manage2: new List([
      { label: 'PERMISSIONLIST.crashModule', checked: true },
      { label: 'PERMISSIONLIST.alarmEdit', checked: true },
      { label: 'PERMISSIONLIST.symbolEdit', checked: true },
      { label: 'PERMISSIONLIST.tagManage', checked: true },
    ]),
    manage3: new List([
      { label: 'PERMISSIONLIST.testModule', checked: true },
      { label: 'PERMISSIONLIST.versionRelease', checked: true },
      { label: 'PERMISSIONLIST.versionEdit', checked: true },
      { label: 'PERMISSIONLIST.versionDelete', checked: true },
    ]),
    check1: new List([
      { label: 'PERMISSIONLIST.dataCheck', checked: true },
      { label: 'PERMISSIONLIST.dataCrash', checked: true },
      { label: 'PERMISSIONLIST.dataOperation', checked: true },
      // {label:'用户反馈数据',checked: true}
    ]),
  }),
});


// 初始化state
export const initialState = new Map({
  memberList: '',
  disableMemberInvitation: false,
  versionList: new List(),
  app: new Map(),
  versionSearchParams: new Map({
    rows: 10,
    start: 0,
    versionType: '1',
    versionKey: '',
  }),
  currentTab: '',
  dailyConfig: dailyReportConfig,
  dailyOptions: initialConfigOptions,
  permission: initialPermission,
  isIntegrate: false,
  isShowInvitated: false,
});
