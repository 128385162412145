import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Checkbox, DatePicker, Radio, Select, Space } from 'antd';
import moment from 'moment';
import { ze } from 'utils/zhEn';
import { isNullish } from 'utils/nullish';
import DateSelectIcon from 'svg/v2/newcs_dashboard_dateselect_icon.svg';
import _style from './SingleOrRangeDatePicker.scss';
const { RangePicker } = DatePicker;

const SingleOrRangeDatePicker = ({
  value,
  onChange: nullableOnChange,
  ...props
}) => {
  let momentRange = value;
  if (isNullish(value)) {
    momentRange = [null, null];
  } else if (!Array.isArray(value)) {
    momentRange = [value, value];
  }

  const onChange = nullableOnChange || (() => {});

  const [isRange, setIsRange] = useState(false);

  useEffect(() => {
    const [minDate, maxDate] = momentRange;
    if (moment.isMoment(minDate) && moment.isMoment(maxDate)) {
      setIsRange(!minDate.isSame(maxDate));
    } else {
      setIsRange(minDate !== maxDate);
    }
  }, momentRange);

  const isRangeCheckboxDom = <Checkbox
    checked={isRange}
    onChange={(e) => {
      const v = e.target.checked;
      setIsRange(v);
      if (!v) {
        const [minDate, maxDate] = momentRange;
        if (minDate !== maxDate || (moment.isMoment(minDate) && !minDate.isSame(maxDate))) {
          onChange([minDate, minDate]);
        }
      }
    }}
  >{ze('跨日期查询', 'Enable Selecting Date Range')}</Checkbox>;

  if (isRange) {
    return <RangePicker
      {...props}
      allowEmpty={[false, false]}
      value={momentRange}
      onChange={(dates) => {
        onChange(dates || [null, null]);
      }}
      suffixIcon={
        <DateSelectIcon style={{ width: '12px', height: '12px', cursor: 'text' }} />
      }
      renderExtraFooter={() => isRangeCheckboxDom}
    />;
  }
  return <DatePicker
      className={_style.datePicker}
      {...props}
      value={momentRange[0]}
      onChange={(date) => {
        onChange([date, date]);
      }}
      suffixIcon={
        <DateSelectIcon style={{ width: '12px', height: '12px', cursor: 'text' }} />
      }
      renderExtraFooter={() => isRangeCheckboxDom}
    />;
};

export default SingleOrRangeDatePicker;
