import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

// import {
//   SeniorSearchPage,
//   MmapPage,
//   OomPage,
//   FeatureConfigPage,
//   FeatureStatResultPage,
//   QueryExpUidPage,
// } from 'components/exception';
import Dashboard from './dashboard';
import Crashes from './crashes';
import Blocks from './blocks';
import Errors from './errors';
import Preferences from './preferences';
// import TutorialPage from 'containers/TutorialPage/TutorialPage';
// import OnlineRetracePage from 'components/exception/OnlineRetracePage';
// import OnlineSendCrashPage from 'components/exception/OnlineSendCrashPage';
// import VersionDashboardPage from 'components/exception/VersionDashboardPage/VersionDashboardPage';
// import DailyReportScreenshotPage from 'components/exception/DailyReportScreenshotPage/DailyReportScreenshotPage';
// import OomDashboard from 'components/workbench/SpaceElevator/OomDashboard';
// import TrendComparePage from 'components/exception/TrendComparePage';
import { LoadingPage } from '../index';

const SeniorSearchPage = React.lazy(() => import(/* webpackChunkName: "senior-search-page" */ 'components/exception/SeniorSearchPage/index'));
const MmapPage = React.lazy(() => import(/* webpackChunkName: "mmap-page" */ 'components/exception/MmapPage'));
const OomPage = React.lazy(() => import(/* webpackChunkName: "oom-page" */ 'components/exception/OomPage'));
const InsightPage = React.lazy(() => import(/* webpackChunkName: "insight-page" */ 'components/exception/InsightPage/InsightPage'));
const FeatureConfigPage = React.lazy(() => import(/* webpackChunkName: "feature-config-page" */ 'components/exception/FeatureConfigPage'));
const FeatureStatResultPage = React.lazy(() => import(/* webpackChunkName: "feature-stat-result-page" */ 'components/exception/FeatureStatResultPage'));
const QueryExpUidPage = React.lazy(() => import(/* webpackChunkName: "query-exp-uid-page" */ 'components/exception/QueryExpUidPage'));

const TutorialPage = React.lazy(() => import(/* webpackChunkName: "tutorial-page" */ 'containers/TutorialPage/TutorialPage'));
const OnlineRetracePage = React.lazy(() => import(/* webpackChunkName: "online-retrace-page" */ 'components/exception/OnlineRetracePage'));
const OnlineSendCrashPage = React.lazy(() => import(/* webpackChunkName: "online-send-crash-page" */ 'components/exception/OnlineSendCrashPage'));
const VersionDashboardPage = React.lazy(() => import(/* webpackChunkName: "version-dashboard-page" */ 'components/exception/VersionDashboardPage/VersionDashboardPage'));
const DailyReportScreenshotPage = React.lazy(() => import(/* webpackChunkName: "daily-report-screenshot-page" */ 'components/exception/DailyReportScreenshotPage/DailyReportScreenshotPage'));
const OomDashboard = React.lazy(() => import(/* webpackChunkName: "oom-dashboard" */ 'components/workbench/SpaceElevator/OomDashboard'));
const TrendComparePage = React.lazy(() => import(/* webpackChunkName: "trend-compare-page" */ 'components/exception/TrendComparePage'));

// LeftMenu点击侧边栏项目路由项的时候，会传一个随机的leftMenuClickTrigger过来
// 通过这个强制触发一下正文部分的组件重新渲染（触发组件的componentDidMount），解决切换tab时组件状态没重置的问题
// 因为通过其他途径（高级搜索编辑搜索条件等）修改route的时候没有这个字段，所以不能直接用leftMenuClickTrigger做key，而是要闭包引入一个外部变量记忆一下
let componentRandomKeyGeneratedByLeftMenuClickTrigger = null;

export default ({ match, leftMenuClickTrigger }) => {
  if (leftMenuClickTrigger) {
    componentRandomKeyGeneratedByLeftMenuClickTrigger = leftMenuClickTrigger;
  }
  return (<Suspense fallback={<LoadingPage/>}>
    <Switch>
      <Route path={`${match.url}/dashboard`} component={Dashboard}/>
      <Route path={`${match.url}/regional-dashboard`} component={Dashboard}/>
      <Route path={`${match.url}/version-dashboard`} component={TrendComparePage}/>
      <Route path={`${match.url}/daily-report-screenshot`} component={DailyReportScreenshotPage}/>
      <Route path={`${match.url}/crashes`} component={Crashes}/>
      <Route path={`${match.url}/blocks`} component={Blocks}/>
      <Route path={`${match.url}/errors`} component={Errors}/>
      <Route path={`${match.url}/client-report-id/:appId/:expUid`} component={QueryExpUidPage}/>
      <Route path={`${match.url}/mmap/:appId`} component={MmapPage}/>
      <Route path={`${match.url}/oom/:appId`} component={OomPage}/>
      <Route path={`${match.url}/advanced-search/:appId`} key={componentRandomKeyGeneratedByLeftMenuClickTrigger} component={SeniorSearchPage}/>
      <Route path={`${match.url}/preferences`} component={Preferences}/>
      <Route path={`${match.url}/insight/:appId`} component={InsightPage}/>
      <Route path={`${match.url}/feature-config/:appId`} component={FeatureConfigPage}/>
      <Route path={`${match.url}/feature-stat-result/:appId`} component={FeatureStatResultPage}/>
      <Route path={`${match.url}/tutorial/:appId`} component={TutorialPage}/>
      <Route path={`${match.url}/online-retrace/:appId`} component={OnlineRetracePage}/>
      <Route path={`${match.url}/online-send-crash/:appId`} component={OnlineSendCrashPage}/>
      <Route path={`${match.url}/model-oom/:appId`} component={OomDashboard}/>
      <Route path={`${match.url}/trend-compare/:appId`} component={TrendComparePage}/>
    </Switch>
  </Suspense>);
};
