import React from 'react';
import Icon from '@ant-design/icons';
import { Spin } from 'antd';
import style from './style.scss';
import loadingSvg from '../../../images/loading.svg';
import { CsLoading } from 'components/antd-extension/CsLoading';

const Loading = () => {
  // const antIcon = <Icon component={loadingSvg} style={{ fontSize: 48 }} spin />;

  // return (<div className={style.cs_loading_mask}>
  //   <div className={style.cs_loading_item}>
  //     <div className="loader"><div className="loader-inner line-scale"><div></div><div></div><div></div><div></div><div></div></div></div>
  //   </div>
  // </div>);
  return (
    <div className={style.cs_loading_mask}>
      <div className={style.loading_svg}>
        <Spin size="large" indicator={CsLoading} />
      </div>
    </div>
  );
};

export default Loading;
