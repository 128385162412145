import React, { useState, useEffect, useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useImmer } from 'use-immer';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import scss from './style.scss';
import CardHead from 'components/commons/CardHead';
import { Button, Col, DatePicker, Row, Select, Space, Spin, Switch, Table, Tooltip } from 'antd';
import moment from 'moment';
import { PAGE_TYPE_CONVERT } from 'utils/constants';
import groupBy from 'lodash/collection/groupBy';
import { useTranslation } from 'react-i18next';
import ReactECharts from 'echarts-for-react';
import { isNullish } from 'utils/nullish';
import { useZhEn } from 'utils/react-hooks/useZhEn';
import { DownloadOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import { XlsxUtil } from 'utils/xlsx-util';
import { COUNTRY_WITH_FLAG_LIST_OPTIONS } from 'components/exception/ProductSummaryPage/CountrySelect';
import { ze } from 'utils/zhEn';
import { selectCurrentAppDatePickerAvailableMomentRange } from 'utils/selectors/selectors';
import reportEvent, { EVENT_ACTIONS } from 'utils/reportEvent';
import BitIcon from 'svg/v2/newcs_dashboard_overview_32bit_icon.svg';
import CsDownloadButton from 'components/commons/CsDownloadButton/CsDownloadButton';
import CsCollapseButton from 'components/commons/CsCollapseButton/CsCollapseButton';
import { COLOR_LIST } from 'utils/constants/chart-options';
import { UpdateTimeTag } from './UpdateTimeTag';

const { RangePicker } = DatePicker;

const ModelBitTrend = (props) => {
  const { t } = useTranslation();
  const { ze } = useZhEn();

  const [
    datePickerMinAvailableMoment,
    datePickerMaxAvailableMoment
  ] = useSelector(selectCurrentAppDatePickerAvailableMomentRange);

  const { reduxState } = props;
  const currentApp = reduxState.app.get('current').toJS();
  const { appId, platformId } = currentApp;
  const { pageType, rankVersion } = reduxState.summary.get('selectOptions').toJS();
  const { subModuleId } = reduxState.summary.get('searchParams').toJS();
  const reportCategory = PAGE_TYPE_CONVERT[pageType];
  const versionOptions = rankVersion.options;

  const [isCollapsed, setIsCollapsed] = useState(true);

  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState(null);
  const [version, setVersion] = useState(null);
  const [minDate, setMinDate] = useState(moment().subtract(13, 'days').format('YYYY-MM-DD'));
  const [maxDate, setMaxDate] = useState(moment().format('YYYY-MM-DD'));
  const [dateList, setDateList] = useState([]);
  const [trendStatsList, setTrendStatsList] = useState([]);
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    if (!appId) {
      return;
    }
    fetchTrend();
  }, [appId, pageType]);

  function makeEmptySingleDayStats() {
    return {
      accessBit0: 0,
      accessBit32: 0,
      accessBit64: 0,
      exceptionBit0: 0,
      exceptionBit32: 0,
      exceptionBit64: 0,
    };
  }

  async function fetchTrend() {
    setLoading(true);
    const minMoment = moment(minDate);
    const maxMoment = moment(maxDate);
    const rsp = await RestHelper.mustPost('/redir/api/statResource/fetchModelBitTrend', {
      appId,
      version: version || '-1',
      country: country || '-1',
      startDate: minDate,
      endDate: maxDate,
      exceptionType: reportCategory,
    });
    /**
     * @type {{ date: string, fieldValue: string, exceptionDevices: number, accessDevices: number }[]}
     */
    let trendDataList = rsp.json.data;

    const dateToStats = trendDataList.reduce((acc, x) => {
      const date = moment(x.date).format('YYYY-MM-DD');
      if (isNullish(acc[date])) {
        acc[date] = makeEmptySingleDayStats();
      }
      const bit = Number(x.fieldValue);
      if (!Number.isFinite(bit)) {
        return acc;
      }
      acc[date][`accessBit${bit}`] = x.accessDevices;
      acc[date][`exceptionBit${bit}`] = x.exceptionDevices;
      return acc;
    }, Object.create(null));

    let trendStatsList = [];
    let dateList = [];
    for (let m = minMoment.clone(); m.isSameOrBefore(maxMoment); m.add(1, 'days')) {
      const date = m.format('YYYY-MM-DD');
      dateList.push(date);
      const singleDayStats = dateToStats[date] || makeEmptySingleDayStats();
      const accessAll = singleDayStats.accessBit0
        + singleDayStats.accessBit32
        + singleDayStats.accessBit64;
      const exceptionAll = singleDayStats.exceptionBit0
        + singleDayStats.exceptionBit32
        + singleDayStats.exceptionBit64;
      singleDayStats.accessPercentage = accessAll > 0
        ? (100 * singleDayStats.accessBit32 / accessAll).toFixed(2)
        : '0';
      singleDayStats.exceptionPercentage = exceptionAll > 0
        ? (100 * singleDayStats.exceptionBit32 / exceptionAll).toFixed(2)
        : '0';
      trendStatsList.push(singleDayStats);
    }

    setDateList(dateList);
    setTrendStatsList(trendStatsList);
    if (trendStatsList.length && isCollapsed) {
      setIsCollapsed(false);
    }

    const chartOptions = {
      color: COLOR_LIST,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line'
        },
      },
      grid: {
        left: 0,
        right: 0,
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        axisTick: {
          alignWithLabel: true,
        },
        data: dateList,
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: '{value} %',
        },
      },
      series: [{
        name: ze('异常占比(%)', 'Exception Devices Percentage (%)'),
        type: 'line',
        data: trendStatsList.map(x => x.exceptionPercentage),
      }, {
        name: ze('联网占比(%)', 'Active Devices Percentage (%)'),
        type: 'line',
        data: trendStatsList.map(x => x.accessPercentage),
      },],
    };
    setChartOptions(chartOptions);
    setLoading(false);
  }

  function downloadData(e) {
    e.stopPropagation()
    const header = [
      t('EXCP_OVERVIEW.日期'),
      ze('异常占比(%)', 'Exception Devices Percentage (%)'),
      ze('联网占比(%)', 'Active Devices Percentage (%)'),
    ];
    const rows = trendStatsList.map((item,index) => [
      dateList[index],
      item.exceptionPercentage + '%',
      item.accessPercentage + '%',
    ]);
    const data = [header,...rows];
    const filename = `${t('EXCP_OVERVIEW.modelBitTrendTitle')}.xlsx`;
    XlsxUtil.createExcelAndDownload(filename,data);
  }

  const chartDom = <ReactECharts
    option={chartOptions}
    style={{ width: '100%' }}
    notMerge={true}
  />

  if (subModuleId !== '-1') {
    return <CardHead title={t('EXCP_OVERVIEW.modelBitTrendTitle')} hideDivider={true} svgIcon={<BitIcon />}>
      <div>{ ze('暂不支持子场景下的查询', 'Currently not available when sub module ID is set.') }</div>
    </CardHead>;
  }

  return <Spin spinning={loading}>
    <CardHead
      title={t('EXCP_OVERVIEW.modelBitTrendTitle')}
      hideDivider={isCollapsed}
      svgIcon={<BitIcon />}
      onClick={() => {
        if (isCollapsed) {
          reportEvent({
            action: EVENT_ACTIONS.CLICK,
            tp1: '32位CPU占比',
          });
        }
        setIsCollapsed(!isCollapsed)
      }}
    >
      <div style={{display: 'flex', flexGrow: '1', alignItems: 'center', justifyContent: 'flex-end'}}>
        <UpdateTimeTag updateTime={moment().format('YYYY-MM-DD 00:00:00')} type='day' />
        <CsDownloadButton
          style={{ margin: '0px 24px'}}
          onClick={(e) => downloadData(e)}
        />
        {/* {
          !isCollapsed ? <UpOutlined onClick={() => setIsCollapsed(true)} />
            : <DownOutlined onClick={() => {
              setIsCollapsed(false);
              reportEvent({
                action: EVENT_ACTIONS.CLICK,
                tp1: '32位CPU占比',
              });
            }} />
        } */}
        <CsCollapseButton
          extraStyle={{ width: '14px', height: '14px' }}
          isCollapsed={isCollapsed}
          enableHover={true}
          onClick={(e) => {
            e.stopPropagation();
            if (isCollapsed) {
              reportEvent({
                action: EVENT_ACTIONS.CLICK,
                tp1: '32位CPU占比',
              });
            }
            setIsCollapsed(!isCollapsed)
          }}
        />
      </div>
      {/* <Row gutter={12} style={{ flexGrow: 1 }}>
        <Col>
          <RangePicker
            style={{ width: '240px' }}
            disabledDate={moment => moment && (moment.isBefore(datePickerMinAvailableMoment) || moment.isAfter(datePickerMaxAvailableMoment))}
            allowClear={false}
            allowEmpty={[false, false]}
            showToday={false}
            placeholder={['日期范围']}
            value={[moment(minDate), moment(maxDate)]}
            onChange={(dates) => {
              const [minDate, maxDate] = (dates || [null, null]).map(x => x && x.format('YYYY-MM-DD'));
              setMinDate(minDate);
              setMaxDate(maxDate);
            }}
          />
        </Col>
        { false && <Col>
          <Select
            allowClear={true}
            showSearch
            style={{ width: '200px' }}
            options={versionOptions}
            placeholder={t("common.所有版本")}
            value={version}
            onChange={v => setVersion(v)}
          />
        </Col> }
        <Col>
          <Select
            allowClear={true}
            style={{ width: '280px' }}
            options={COUNTRY_WITH_FLAG_LIST_OPTIONS}
            optionFilterProp='rawLabel'
            virtual={false}
            showSearch={true}
            placeholder={t('EXCP_OVERVIEW.全部国家地区')}
            maxTagCount='responsive'
            value={country}
            onChange={v => setCountry(v)}
          />
        </Col>
        <Col>
          <Button
            type='primary'
            onClick={() => {
              setIsCollapsed(false);
              fetchTrend();
            }}
          >{ t('common.查询') }</Button>
        </Col>
        <Col>
          <Button icon={<DownloadOutlined />} onClick={() => downloadData()}>
            { ze('下载数据', 'Download') }
        </Button>
        </Col>
        <Col flex='1'/>
        <Col>
          <Button
            type='link'
            onClick={() => {
              isCollapsed && reportEvent({
                action: EVENT_ACTIONS.CLICK,
                tp1: '32位CPU占比',
              })
              setIsCollapsed(!isCollapsed)
            }}
          >
            {isCollapsed ? t("EXCP_OVERVIEW.expand") : t("EXCP_OVERVIEW.collapse")}
          </Button>
        </Col>
      </Row> */}
    </CardHead>
    <div>
      { !isCollapsed && <>
        <Row gutter={10} style={{margin: '20px -5px'}}>
          <Col>
            <RangePicker
              style={{ width: '220px' }}
              disabledDate={moment => moment && (moment.isBefore(datePickerMinAvailableMoment) || moment.isAfter(datePickerMaxAvailableMoment))}
              allowClear={false}
              allowEmpty={[false, false]}
              showToday={false}
              placeholder={['日期范围']}
              value={[moment(minDate), moment(maxDate)]}
              onChange={(dates) => {
                const [minDate, maxDate] = (dates || [null, null]).map(x => x && x.format('YYYY-MM-DD'));
                setMinDate(minDate);
                setMaxDate(maxDate);
              }}
            />
          </Col>
          { false && <Col>
              <Select
                allowClear={true}
                showSearch
                style={{ width: '200px' }}
                options={versionOptions}
                placeholder={t("common.所有版本")}
                value={version}
                onChange={v => setVersion(v)}
              />
            </Col>
          }
          <Col>
            <Select
              allowClear={true}
              style={{ width: '220px' }}
              options={COUNTRY_WITH_FLAG_LIST_OPTIONS}
              optionFilterProp='rawLabel'
              virtual={false}
              showSearch={true}
              placeholder={t('EXCP_OVERVIEW.全部国家地区')}
              maxTagCount='responsive'
              value={country}
              onChange={v => setCountry(v)}
            />
          </Col>
          <Col>
            <Button
              type='primary'
              onClick={() => {
                setIsCollapsed(false);
                fetchTrend();
              }}
            >{ t('common.查询') }</Button>
          </Col>
        </Row>
        {chartDom}
      </> }
    </div>
  </Spin>;
}

ModelBitTrend.propTypes = {
  reduxState: PropTypes.object,
  actions: PropTypes.object,
};

const mapStateToProps = state => ({
  reduxState: state,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    // ...productActions,
    // ...appActions,
    // ...globalActions,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModelBitTrend);
